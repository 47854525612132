$.FlowLayoutSubjectGridFit = function(div) {
	$.extend(div, {
		calculateCellsToFit: function(definition, users, blockRecalculate, options) {
			options = $.extend(true, {
				isForcedRetry: false
			}, options);

			var cell = definition.cell;
			cell.ratio = this.getCellRatio(cell, users);

			var numUsers = users.length;
			let cells = $(this).find('.flowCell');

			// Handle manually hidden cells
			var manuallyHiddenCells = cells.filter('.hiddenCell.allowSelectingCell').not('.behindFrames');
			numUsers += manuallyHiddenCells.length;

			// Increment number of users by however many extra cells we need for large cells
			if(definition.largeCell) {
				// ex: if large cell is a 2x2, we are using 3 extra spaces
				var extraUsers = definition.largeCell.colSpan * definition.largeCell.rowSpan - 1;

				// Figure out how many teacher cells we are dealing with
				var largeCells = 0;
				if(definition.largeCell.require == 'teacher') {
					for (let i = 0; i < users.length; i++) {
						if(users[i]['Teacher Priority']) {
							largeCells++;
						} else {
							break;
						}
					}
				} else if(definition.largeCell.max) {
					largeCells = definition.largeCell.max;
				} else {
					largeCells = 1;
				}

				// Increment users by however many extra cells * number of large cells we are going to have
				numUsers += extraUsers * largeCells;

				// Try to detect if we have an object in the middle basically blocking the entire row
				if(definition.largeCell.col == 'center' && cells.length) {
					var firstRowCells = cells.eq(0).parent().children('.flowCell');
					var firstRowEmpty = firstRowCells.filter('.emptyCell');

					// All cells are empty so add all of the non-hidden ones
					if(firstRowCells.length == firstRowEmpty.length) {
						var firstRowHidden = firstRowCells.filter('.hiddenCell');
						var emptyButNotHidden = firstRowEmpty.length - firstRowHidden.length;

						numUsers += emptyButNotHidden;
					}
				}

				numUsers += cells.filter('.hiddenCell.tempHiddenCell').length;
			}

			// Want invisible cells every but last line where we just ran out of subjects to add
			var invisibleCells = $(this.rows.slice(0, this.rows.length - 1)).find('.flowCell').filter('.invisibleCell');
			if(invisibleCells.length) {
				numUsers += invisibleCells.length;
			}

			var lastGroupCount = 100000;
			if(definition.row && definition.row.groupBy && users.length) {
				var groupBy = definition.row.groupBy;
				var lastGroup = null;
				lastGroupCount = 0;
				users.forEach(function(matchUser) {
					var matchUserGroup = matchUser[groupBy];
					if(groupBy == 'Teacher Priority') {
						matchUserGroup = !!matchUserGroup;
						lastGroup = !!lastGroup;
					}

					if(lastGroup == matchUserGroup) {
						lastGroupCount++;
					} else {
						lastGroup = matchUserGroup;
						lastGroupCount = 1;
					}
				});

				// Find row with these last groups
				// Last row can sometimes be empty and real last row is above it
				for(var j = this.rows.length - 1; j >= 0; j--) {
					var row = this.rows[j];
					if(row.getFilledCells().length > 0) {
						if(row.groupByValue === lastGroup) {
							lastGroupCount += $(row).find('.flowCell.hiddenCell.behindFrame').length;
						}

						break;
					}
				}
			}

			// Need to user container instead of parent so we can get space minus bleed
			// Subtract 2 px for border on canvasMargins
			var canvasDimensions = this.getPixelDimensions();
			var pageHeight = canvasDimensions.height;
			if(definition.cell.wave) {
				var wavePercentage = parseInt(definition.cell.wave.replace('%', ''));
				var maxPageHeight = (100 - wavePercentage) / 100;

				pageHeight = pageHeight * maxPageHeight;
			}

			var pageWidth = canvasDimensions.width;
			var pageSize = pageHeight * pageWidth;
			var lines = definition.name ? 1 : 0;
			var lineMultiplier = 1;
			var namePadding = definition.name ? 3 : 0;
			if(definition.name) {
				if(definition.name.order2 || definition.name.teacherPrefixOrder2) {
					namePadding += 3;
					lineMultiplier += 0.6;
					lines++;
				}

				for(let i = 3; definition.name['order' + i] || definition.name['teacherPrefixOrder' + i]; i++) {
					namePadding += 3;
					lines++;
				}
			}

			var exampleCell = cells.eq(0);
			var flowFrames = this.getBlockingContent();
			// On the first run with frames on the page, may need to recalculate to see how many cells they take
			if(!exampleCell.length && flowFrames.length && !blockRecalculate) {
				this.calculateCellsToFit(definition, users, true);
				this.addCellsToLayout(definition);

				// Reget example cell
				exampleCell = $(this).find('.flowCell').eq(0);
			}

			// Clear paddings AFTER we do a possible recalculation
			cell.leftPadding = null;
			cell.rightPadding = null;
			cell.topPadding = null;
			cell.bottomPadding = null;

			if(exampleCell.length) {
				// Get frames on page
				var draggingFrame = $(this).data('dragging-frame');
				if(draggingFrame) {
					flowFrames = flowFrames.add(draggingFrame);
				}

				// Figure out how many current cells this takes
				let cells = $();
				flowFrames.each(function() {
					cells = cells.add(this.hiddenCells);
				});
				numUsers += cells.filter(function() {
					return this.getSubjectGrid() === div;
				}).length;
			}

			if(options.isForcedRetry) {
				numUsers += Math.max(options.isForcedRetry, Math.round(users.length / 20 * options.isForcedRetry));
			}

			// When we have 1 subject with crap right in the middle it can be hard to automatically get enough cells to get stuff around it, giving it a little nudge in the right direction
			if(users.length === 1) {
				if(numUsers === 2) {
					numUsers = 4;
				} else if(numUsers === 5) {
					numUsers = 8;
				}
			} else if(users.length === 2 && definition.row && definition.row.groupBy) {
				if(numUsers === 5) {
					numUsers = 6;
				}
			}
			numUsers = Math.max(numUsers, 1);

			// Calculate what the max number of columns we will start with to see what the check range should be
			let cellSize = pageSize / numUsers;
			var cellWidth = Math.sqrt(cellSize);
			var maxColumns = Math.floor(pageWidth / cellWidth);

			var maxCellSize, maxResult;
			for (let i = Math.min(-2, -maxColumns + 1); i <= Math.min(numUsers, 100); i++) {
				var result = this.getMaxCellSize(definition, cell, pageSize, pageWidth, pageHeight, numUsers, namePadding, lineMultiplier, lines, i, lastGroupCount);
				if(!result || ((result.cellWidth <= 0 || result.cellHeight <= 0) && maxCellSize)) {
					continue;
				}

				cellSize = result.cellWidth * result.cellHeight;
				if(!maxCellSize || cellSize > maxCellSize) {
					maxCellSize = cellSize;
					maxResult = result;
				}
			}

			if(!maxResult && !options.isForcedRetry) {
				return this.calculateCellsToFit(definition, users, blockRecalculate, $.extend(true, options, {
					isForcedRetry: 2
				}));
			}

			cell.width = maxResult.cellWidth;
			cell.height = maxResult.cellHeight;

			if(maxResult.leftRightPadding) {
				cell.leftPadding = maxResult.leftRightPadding;
				cell.rightPadding = maxResult.leftRightPadding;
			}
			if(maxResult.topBottomPadding) {
				cell.topPadding = maxResult.topBottomPadding;
				cell.bottomPadding = maxResult.topBottomPadding;
			}
			cell.insideVerticalPadding = maxResult.insideVerticalPadding;
			cell.outsideVerticalPadding = maxResult.outsideVerticalPadding;
			cell.scaledNameSize = maxResult.scaledNameSize;

			var isChange = this.horizontal != maxResult.columns || this.vertical != maxResult.rows;
			this.horizontal = maxResult.columns;
			this.vertical = maxResult.rows;
			if(definition.grid && definition.grid.horizontal) {
				definition.grid.horizontal = this.horizontal;
				definition.grid.vertical = this.vertical;
			}

			return isChange;
		},
		getCellRatio: function(cell, users) {
			if(cell.ratio === '*') {
				var ratios = {
					'0.8': 0
				};

				var page = this.flowLayout.getPage();
				var cropSelection = (page.getExtraProperty('subjectEffects') || {})['cropSelection'];
				if(cropSelection === 'primary crop') {
					cropSelection = null;
				}

				users.forEach(function(user) {
					var photo = user.yearbookPhoto;
					if(!photo || !photo.width || !photo.height) {
						return;
					}

					var photoWidth = photo.width;
					var photoHeight = photo.height;

					if(cropSelection) {
						var matchingCrop = photo.photo_crops.find(function(crop) {
							return crop.name.toLowerCase() === cropSelection;
						});
						if(matchingCrop) {
							photoWidth = photoWidth * matchingCrop.width;
							photoHeight = photoHeight * matchingCrop.height;
						}
					} else if(photo.yearbookCrop) {
						photoWidth = photoWidth * photo.yearbookCrop.width;
						photoHeight = photoHeight * photo.yearbookCrop.height;
					}

					// Only want two digits of precision
					var ratio = Math.round(photoWidth / photoHeight * 100) / 100;
					if(ratios[ratio]) {
						ratios[ratio]++;
					} else {
						ratios[ratio] = 1;
					}
				});

				var maxRatio = 0.8;
				var maxRatioUsers = 0;
				for(var ratio in ratios) {
					if(ratios[ratio] > maxRatioUsers) {
						maxRatio = parseFloat(ratio);
						maxRatioUsers = ratios[ratio];
					}
				}

				return maxRatio;
			} else {
				return cell.ratio;
			}
		},
		getMaxCellSize: function(definition, cell, pageSize, pageWidth, pageHeight, numUsers, namePadding, lineMultiplier, lines, inc, lastGroupCount) {
			// Get the maximum cellSize based on total size and number of users
			let cellSize = pageSize / numUsers;
			var cellPadding = cell.padding * this.ratio;
			var verticalCellPadding = cellPadding * namePadding;
			if(cell.primaryPose && cell.primaryPose.bottomPadding) {
				verticalCellPadding += cell.primaryPose.bottomPadding * this.ratio;
			}
			var horizontalCellPadding = cellPadding * 2;

			var startCellWidth;
			if(cell.nameHeightFix) {
				startCellWidth = Math.sqrt(cellSize);
			} else {
				var startCellHeight = Math.sqrt(cellSize) - verticalCellPadding;
				startCellWidth = startCellHeight * cell.ratio;
			}

			var columns = Math.floor(pageWidth / startCellWidth) + inc;
			if(definition.largeCell && definition.largeCell.col == 'center' && columns % 2 == 1) {
				// If we are centering largeCell, make sure even number of columns
				columns++;
			}
			if(lastGroupCount < columns) {
				numUsers += columns - lastGroupCount;
			}

			var rows = Math.ceil(numUsers / columns);

			// Should only happen on numUsers == 1 or 2
			if(rows <= 0 || columns <= 0) {
				rows = 1;
				columns = numUsers;
			}

			if(definition.rows) {
				rows = definition.rows;
				columns = Math.ceil(numUsers / rows);
			}

			var cellWidthMultiplier = 1;
			if(definition.cell.horizontalOverlap) {
				var horizontalOverlap = parseFloat(definition.cell.horizontalOverlap.replace('%', '')) / 100;

				// We don't want to have outside cells go outside their bounds, so subtract the space we actually saved from a single cells padding
				var singleSubjectMultiplier = (columns - 1) / columns;
				cellWidthMultiplier = 1 + (horizontalOverlap * singleSubjectMultiplier);
			}

			var cellHeightMultiplier = 1;
			if(definition.cell.verticalPadding == 'outside') {
				if(definition.cell.verticalOverlap) {
					var verticalOverlap = parseFloat(definition.cell.verticalOverlap.replace('%', '')) / 100;

					cellHeightMultiplier = 1 + (verticalOverlap / rows * (rows - 1));
				}
			}

			// Calculate out what the maximum sizes we could use based off this number of columns/rows
			var maxCellWidth = pageWidth / columns * cellWidthMultiplier;
			var minCellHeight = (maxCellWidth - horizontalCellPadding) / cell.ratio + horizontalCellPadding;
			var maxCellHeight = pageHeight * cellHeightMultiplier / rows - verticalCellPadding;
			var minCellWidth = (maxCellHeight - horizontalCellPadding) * cell.ratio + horizontalCellPadding;

			// Figure out which scenario is the maximum size and use it
			var cellWidth, cellHeight;
			if(maxCellWidth * minCellHeight > maxCellHeight * minCellWidth) {
				cellWidth = maxCellWidth;
				cellHeight = minCellHeight;
			} else {
				cellWidth = minCellWidth;
				cellHeight = maxCellHeight;
			}

			if(cellWidth < 0 || cellHeight < 0) {
				return null;
			}

			var scaledNameSize = this.getScaledNameSizeFromCellWidth(cell, cellWidth / this.ratio, lineMultiplier, numUsers);
			var verticalPaddingCheck = verticalCellPadding;
			if(cell.nameHeightFix) {
				verticalCellPadding = this.getEstimatedNameHeight(scaledNameSize * this.ratio, lines, cell);
				verticalPaddingCheck = verticalCellPadding + cellPadding;
			}

			var leftRightPadding, topBottomPadding;

			// Check to make sure that neither height or width go over the bounds
			// Add a super small number to get around floating point arithmetic issue.
			var widthOverflow = (cellWidth * columns - 0.00001) - pageWidth;
			var heightOverflow = ((cellHeight + verticalPaddingCheck) * rows - 0.00001) - pageHeight;

			// We added this widthOverflow > heightOverflow check only for Virtual Groups because we don't want to risk changing existing composites
			// Allowing on composites with more then 2 lines to get them working well
			if(widthOverflow > 0 && (((widthOverflow / columns) > (heightOverflow / rows) || (!definition.rows && lines <= 2)))) {
				// Too wide
				cellWidth = pageWidth / columns * cellWidthMultiplier;
				cellHeight = (cellWidth - horizontalCellPadding) / cell.ratio + horizontalCellPadding;

				if(cellWidth < 0 || cellHeight < 0) {
					return null;
				}

				// With modern layouts, we want to calculate the dynamic padding based on width
				scaledNameSize = this.getScaledNameSizeFromCellWidth(cell, cellWidth / this.ratio, lineMultiplier, numUsers);
				if(cell.nameHeightFix) {
					verticalCellPadding = this.getEstimatedNameHeight(scaledNameSize * this.ratio, lines, cell);
				}

				var extraHeight = pageHeight - ((cellHeight + verticalCellPadding) * rows);
				if(cell.padding !== this.flowLayout.DEFAULT_CELL_PADDING && extraHeight < 0 && !definition.cell.verticalOverlap) {
					return null;
				}
				// Divide by two to get what should be applied on each individual side
				var extraVerticalCellPadding = extraHeight / rows / 2 / this.ratio;
				topBottomPadding = cell.padding + extraVerticalCellPadding;
			} else if(heightOverflow > 0) {
				// Too tall
				cellHeight = (pageHeight / rows * cellHeightMultiplier) - verticalCellPadding;
				// We have to do this here instead of when defining verticalCellPadding since we also use it in the if statement to get here
				if(cell.nameHeightFix) {
					cellHeight += cellPadding;
				}
				cellWidth = (cellHeight - horizontalCellPadding) * cell.ratio + horizontalCellPadding;

				if(cellWidth < 0 || cellHeight < 0) {
					return null;
				}

				scaledNameSize = this.getScaledNameSizeFromCellWidth(cell, cellWidth / this.ratio, lineMultiplier, numUsers);
				if(cell.nameHeightFix) {
					var verticalCellPaddingDiff = verticalCellPadding - this.getEstimatedNameHeight(scaledNameSize * this.ratio, lines, cell);
					cellHeight += verticalCellPaddingDiff;
					cellWidth += (verticalCellPaddingDiff * cell.ratio);
				}

				// Figure out how much padding we want to add to each side
				var extraWidth = pageWidth - (cellWidth * columns);
				if(cell.padding !== this.flowLayout.DEFAULT_CELL_PADDING && extraWidth < 0 && !definition.cell.horizontalOverlap) {
					return null;
				}
				// Divide by two to get what should be applied on each individual side
				var extraHorizontalCellPadding = extraWidth / columns / 2 / this.ratio;
				leftRightPadding = cell.padding + extraHorizontalCellPadding;
			} else {
				return null;
			}

			if(scaledNameSize < 0 || (leftRightPadding < 0 && !definition.cell.horizontalOverlap) || (topBottomPadding < 0 && !definition.cell.verticalOverlap)) {
				return null;
			}

			var inchCellWidth = cellWidth / this.ratio;
			var inchCellHeight = cellHeight / this.ratio;

			if(definition.cell.horizontalOverlap) {
				let overlap = (inchCellWidth / cellWidthMultiplier) * (parseFloat(definition.cell.horizontalOverlap.replace('%', '')) / 100) / 2;
				leftRightPadding = -overlap;
			}

			var insideVerticalPadding, outsideVerticalPadding;
			if(definition.cell.verticalPadding == 'outside' || definition.cell.distributeSpacingOutsideLayout) {
				outsideVerticalPadding = (topBottomPadding || 0) * rows;

				if(definition.cell.verticalOverlap) {
					let overlap = (inchCellHeight / cellHeightMultiplier) * (parseFloat(definition.cell.verticalOverlap.replace('%', '')) / 100) / 2;
					insideVerticalPadding = -overlap;

					if(topBottomPadding) {
						var rowMultiplier = rows - 1;
						outsideVerticalPadding += overlap * rowMultiplier;
					}
				}
				topBottomPadding = null;
			}

			var outsideHorizontalPadding;
			if(definition.cell.horizontalPadding == 'outside' || definition.cell.distributeSpacingOutsideLayout) {
				outsideHorizontalPadding = (leftRightPadding || 0) * columns;
				leftRightPadding = null;
			}

			return {
				cellWidth: inchCellWidth,
				cellHeight: inchCellHeight,
				rows: rows,
				columns: columns,
				leftRightPadding: leftRightPadding || 0,
				topBottomPadding: topBottomPadding || 0,
				insideVerticalPadding: insideVerticalPadding,
				outsideVerticalPadding: outsideVerticalPadding,
				outsideHorizontalPadding: outsideHorizontalPadding,
				scaledNameSize: scaledNameSize
			};
		},
		getScaledNameSizeFromCellWidth: function(cell, cellWidth, lineMultiplier, numUsers) {
			// Scale font size based on how large the resulting cell is
			var scaledNameSize = cellWidth * cell.nameSize / 1.4;
			// Cut the difference a bit so it's not so extreme
			var nameSizeDiff = scaledNameSize - cell.nameSize;

			var nameSizeDiffMultipler = 4;
			if(numUsers >= 800) {
				nameSizeDiffMultipler = 3;
			} else if(numUsers >= 600) {
				nameSizeDiffMultipler = 3.5;
			}

			scaledNameSize = (cell.nameSize + nameSizeDiff / nameSizeDiffMultipler * 3) * lineMultiplier;

			return scaledNameSize;
		},
		getEstimatedNameHeight: function(nameSize, lines, cell) {
			var fontSizePx = $.convertToPx(nameSize, true) / $.FONT_MULTIPLIER;

			if(cell.studentLabelCSS && cell.studentLabelCSS.css['font-size-multiplier']) {
				var fontSizeMultiplier = cell.studentLabelCSS.css['font-size-multiplier'];
				fontSizePx += fontSizePx * fontSizeMultiplier;
			}

			var nameHeight = fontSizePx * 1.33 * lines;
			if(cell.primaryPose && cell.primaryPose.bottomPadding) {
				nameHeight += cell.primaryPose.bottomPadding * this.ratio;
			}

			return nameHeight;
		}
	});
};