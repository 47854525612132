$.FlowLayoutSet = function(wrapper, options) {
	var me = this;

	$.extend(this, {
		addPageLayout: function (page) {
			if (!page) {
				page = new $.FlowLayout({parent: this});
			}

			this.pages.push(page);
			page.setEditable(this.editable);
			if (this.wrapper) {
				$(this.wrapper).append(page);
			}
			page.parent = this;

			// Check style directly to see if page has been manually sized yet
			if (this.wrapper && !page.style.width) {
				// Set width to obey the ratio absolutely
				var parentWidth = $(this.wrapper).parent().width() - 60;
				var calcWidth = $(page).height() * ($.PAGE_WIDTH / $.PAGE_HEIGHT);
				// If this would be too wide, recalculate
				var newHeight;
				if ((calcWidth * this.pages.length) > parentWidth) {
					var oldWidth = calcWidth;
					calcWidth = parentWidth / this.pages.length;

					newHeight = $(page).height() * (calcWidth / oldWidth);
				}

				for (var i = 0; i < this.pages.length; i++) {
					$(this.pages[i]).width(calcWidth);
					if (newHeight) {
						$(this.pages[i]).height(newHeight);
					}
				}
			}

			for (i = 0; i < this.pages.length; i++) {
				if((i + 1) < this.pages.length) {
					this.pages[i].nextLayout = this.pages[i + 1];
				}

				if(i > 0) {
					this.pages[i].previousLayout = this.pages[i - 1];
				}
			}
		},

		getFirstVisibleLayout: function() {
			if(this.pages[0] && this.pages[0].getPage()) {
				return this.pages[0];
			} else if(this.pages[1] && this.pages[1].getPage()) {
				return this.pages[1];
			} else {
				return null;
			}
		},
		getFirstVisiblePage: function() {
			if(this.pages[0] && this.pages[0].getPage()) {
				return this.pages[0].getPage();
			} else if(this.pages[1] && this.pages[1].getPage()) {
				return this.pages[1].getPage();
			} else {
				return null;
			}
		},
		getFirstEmptyPage: function() {
			var firstPage = this.pages[0];
			var secondPage = this.pages[1];
			if (secondPage.getPage()) {
				// Go to next actual page
				firstPage.setPage(null);
				secondPage.setPage(null);

				// Logic to handle if first page is several pages down
				var total = this.pageSet.getTotalPages();
				if (total % 2 == 0) {
					return firstPage;
				} else {
					this.currentPage = total - 1;
					firstPage.setPage(this.getCurrentPages()[0]);
					return secondPage;
				}
			} else if (firstPage.getPage()) {
				return secondPage;
			} else {
				return firstPage;
			}
		},
		getNextPage: function (currentPage, skippedPages) {
			var currentPageIndex = this.pages.indexOf(currentPage);
			var newIndex = (currentPageIndex + 1 + skippedPages) % this.pages.length;

			// Try to update index for next page + skip however many pages needed
			var pageMoves = Math.floor((currentPageIndex + 1 + skippedPages) / this.pages.length);
			for (var i = 0; i < pageMoves; i++) {
				this.currentPage = this.currentPage + this.pages.length;
			}

			// If we did any page flips, update what is displayed
			if (pageMoves > 0) {
				this.updatePagesAfterChange();
			}

			return this.pages[newIndex];
		},
		getNextVisiblePage: function (currentPage) {
			var index = this.pages.indexOf(currentPage);
			if (index == -1 || index == (this.pages.length - 1)) {
				// Index not found or the last page, no next page
				return null;
			} else {
				return this.pages[index + 1];
			}
		},
		getPreviousPage: function (currentPage, skippedPages) {
			var currentPageIndex = this.pages.indexOf(currentPage);
			var newIndex = currentPageIndex - 1 - skippedPages;

			// Try to update index for previous page + skip however many pages needed
			var pageMoves = Math.floor(Math.abs(newIndex - 1) / this.pages.length);
			for(var i = 0; i < pageMoves; i++) {
				this.currentPage = this.currentPage - this.pages.length;
			}

			// If we wrap around, get what index it would be on the previous set
			if(newIndex < 0) {
				newIndex = Math.abs(newIndex) % this.pages.length;
			}

			return this.pages[newIndex];
		},
		getPreviousVisiblePage: function (currentPage) {
			var index = this.pages.indexOf(currentPage);
			if (index > 0) {
				return this.pages[index - 1];
			} else {
				// Index not found or 0, no previous page
				return null;
			}
		},
		getCurrentPage: function () {
			var firstPage = this.pages[0];
			var secondPage = this.pages[1];
			if (secondPage.getPage()) {
				return secondPage;
			} else {
				return firstPage;
			}
		},
		getPage: function (i) {
			return this.pages[i];
		},
		hasEmptyPage: function() {
			return !!this.pages.find(layout => layout.getPage() && layout.visible)
		},

		setLayout: function (definition) {
			this.getCurrentPage().setLayout(definition);
		},
		updatePageNumberPosition: function () {
			for (var i = 0; i < this.pages.length; i++) {
				this.pages[i].updatePageNumberPosition();
			}
		},
		updatePageNumberCSS: function (skipPage) {
			for (var i = 0; i < this.pages.length; i++) {
				var page = this.pages[i];
				if (page != skipPage && page.pageNumberDiv && $(page.pageNumberDiv).css('display') != 'none') {
					page.updatePageNumber();
				}
			}
		},
		updateTheme: function () {
			for (var i = 0; i < this.pages.length; i++) {
				var page = this.pages[i];
				if (page.visible !== false) {
					page.updateTheme();
				}
			}
		},
		setTheme: function (theme) {
			this.pageSet.setTheme(theme);

			for (var i = 0; i < this.pages.length; i++) {
				var layout = this.pages[i];
				layout.refreshPage();
			}
		},
		setEditable: function (editable) {
			if (editable === this.editable) {
				return;
			}

			this.editable = editable;

			// Update each page's editable property
			for (var i = 0; i < this.pages.length; i++) {
				this.pages[i].setEditable(editable);
			}
		},
		isEditable: function () {
			return this.editable;
		},
		goToNextPage: function() {
			var tmpPageOffset = this.tmpPageOffset;
			this.tmpPageOffset = 0;

			var startPage = this.currentPage;
			var page;
			if(tmpPageOffset > 0) {
				page = this.goToPage(this.currentPage);
			} else {
				page = this.goToPage(this.currentPage + this.pages.length);
			}

			// Require a page at least on page 0
			var layoutsWithPages = this.pages.filter(function(layout, index) {
				return !!me.getCurrentPageForLayoutIndex(index);
			});
			if(layoutsWithPages.length === 0) {
				page = this.goToPage(startPage);
			}

			return page;
		},
		goToPreviousPage: function() {
			var tmpPageOffset = this.tmpPageOffset;
			this.tmpPageOffset = 0;

			var startPage = this.currentPage;
			var page;
			if(tmpPageOffset < 0) {
				page = this.goToPage(this.currentPage);
			} else {
				page = this.goToPage(this.currentPage - this.pages.length);
			}

			// Require a page at least on page 0
			var layoutsWithPages = this.pages.filter(function(layout, index) {
				return !!me.getCurrentPageForLayoutIndex(index);
			});
			if(layoutsWithPages.length === 0) {
				page = this.goToPage(startPage);
			}
			
			return page;
		},
		goToFirstPage: function() {
			return this.goToPage(this.pageSet.getFirstPage());
		},
		goToLastPage: function() {
			return this.goToPage(this.pageSet.getLastPage());
		},
		goToPage: function(page, immediately) {
			this.tmpPageOffset = 0;
			// Get page number if we passed in a $.FlowPage instead of a page number
			var originalPage = page;
			if(page && page.getPageNumber) {
				var pageIndex = this.pageSet.getPageIndex(page);
				if(pageIndex === -1) {
					page = this.currentPage + this.pageOffset;
				} else {
					page = pageIndex;
				}
			}

			this.currentPage = page + this.pageOffset;
			if(this.currentPage % this.pages.length) {
				this.currentPage = this.currentPage - (this.currentPage % this.pages.length);
			}

			if(immediately) {
				this.updatePagesAfterChangeImmediately(originalPage);
			} else {
				this.updatePagesAfterChange();
			}
			return this.pages[(page + this.pageOffset) % this.pages.length];
		},
		updatePagesAfterChange: function (forcePageRefresh, onComplete) {
			var me = this;
			if((this.currentPage - this.pageOffset) >= this.pageSet.getLastPageNumber()) {
				this.currentPage -= this.pages.length;
			}

			window.setTimeout(function () {
				me.updatePagesAfterChangeImmediately(forcePageRefresh);

				if($.userEvents) {
					$.userEvents.stopGroupedEvents();
				}
				if(onComplete) {
					onComplete.call(me);
				}
			}, 10);
		},
		updatePagesAfterChangeImmediately: function(forcePageRefresh) {
			this.pages.forEach(function(layout) {
				delete layout.lastManualFontSize;
			});
			this.lastLoadedPageOffset = this.pageOffset;

			var pages = [];
			let firstVisiblePage = null;
			for (var i = 0; i < this.pages.length; i++) {
				var page = this.getCurrentPageForLayoutIndex(i);
				if(i > 0 && page && pages[i - 1] && (page.pageNumber - 2) > pages[i - 1].pageNumber && this.pageSet.wrapperPageSet) {
					console.warn('Fixing case where subset pages are displayed next to each other when they should not');
					page = null;
				}
				pages.push(page);

				var layout = this.pages[i];
				if(page) {
					if($.globalBugsnagInfo) {
						$.globalBugsnagInfo['$.flowLayoutCURRENT'] = {
							id: page.getId(),
							pageNumber: page.getPageNumber(),
							type: page.type
						};
					}

					$(layout).show();

					// We are going directly to overflow page, load rootPage first
					if (page.getRootPage && page.type == 'classOverflow' && !page.getKids()) {
						var rootPage = page.getRootPage();
						while(page != rootPage && rootPage && rootPage.getOverflowPage && rootPage.getPageNumber() < page.getPageNumber()) {
							// Side matters, so make sure the previous layouts get put in the right slot
							var useLayout = layout;
							var rootPageIndex = this.pageSet.getPageIndex(rootPage) - this.pageOffset;
							if(rootPageIndex >= 0 && i === 0) {
								useLayout = this.pages[rootPageIndex % this.pages.length];
							}

							useLayout.setPage(rootPage);
							rootPage = rootPage.getOverflowPage();
						}
					}

					layout.setPage(page, forcePageRefresh === true || page === forcePageRefresh || (page.getParentPage && page.getParentPage() === forcePageRefresh) ||
						(page.getRootPage && (page.getRootPage() === forcePageRefresh || (!page.blockRefreshingMatchingRootPages && forcePageRefresh && forcePageRefresh.getRootPage && page.getRootPage() === forcePageRefresh.getRootPage()))));

					// Handle child linkage for everything but classes which already do it separately
					if(page.type.indexOf('class') == -1 && page.getParentPage && i > 0) {
						var previousLayout = this.pages[i - 1];
						var previousPage = previousLayout.getPage();

						if(previousPage && page.getParentPage() === previousPage) {
							layout.parentLayout = previousLayout;
							previousLayout.childLayout = layout;
						}
					}

					layout.visible = true;
					if($.globalBugsnagInfo) {
						delete $.globalBugsnagInfo['$.flowLayoutCURRENT'];
					}
				} else {
					$(layout).hide();
					layout.visible = false;
					layout.setPage(null);
				}

				// If we removed this page mid operation, remove it
				if (page && this.pageSet.getPageIndex(page) == -1) {
					console.error('Removed page during set', page);
					i--;
				}

				if($.globalBugsnagInfo) {
					if(page) {
						layout = null;
						try {
							layout = JSON.stringify(page.getLayout());
						} catch(e) {
							console.error('Failed to parse json: ', e);
						}

						$.globalBugsnagInfo['$.flowLayout' + (i + 1)] = {
							id: page.getId(),
							pageNumber: page.getPageNumber(),
							type: page.type,
							layout: layout
						};
					} else {
						$.globalBugsnagInfo['$.flowLayout' + (i + 1)] = {
							id: 'null',
							currentPage: this.currentPage,
							pageOffset: this.pageOffset,
							tmpPageOffset: this.tmpPageOffset,
							index: i
						};
					}
				}

				if(!firstVisiblePage && page) {
					firstVisiblePage = page;
				}
			}

			if (this.onPageChange) {
				this.onPageChange(pages);
			}
			if(this.pageSet && this.pageSet.db) {
				this.pageSet.db.updateKeepAliveData({
					currentPage: firstVisiblePage ? firstVisiblePage.pageNumber : this.currentPage
				}, true);
			}
			this.updateCurrentUsersVisible();
		},

		getCurrentPageNumber: function () {
			if(this.pages[0] && this.pages[0].getPage()) {
				// If we are displaying back wrap, don't return it as the current page number
				if(this.getPageIndexForLayoutIndex(0) === -1 && this.wrapPages) {
					return this.pages[1].getPage().getPageNumber();
				} else {
					return this.pages[0].getPage().getPageNumber();
				}
			} else if(this.pages[1] && this.pages[1].getPage()) {
				return this.pages[1].getPage().getPageNumber();
			} else {
				return this.currentPage + this.pageSet.getFirstPageNumber();
			}
		},
		getCurrentPages: function () {
			var pages = [];
			var totalPages = this.pageSet.getTotalPages();
			if (this.currentPage > totalPages && (this.currentPage - this.pages.length) > 0) {
				this.currentPage -= this.pages.length;
			}

			for (var i = this.currentPage; (i - this.pageOffset) < totalPages && (i - this.currentPage - this.pageOffset) < this.pages.length; i++) {
				pages.push(this.pageSet.getPage(i - this.pageOffset));
			}

			return pages;
		},
		getCurrentPageForLayout: function (layout) {
			for(var i = 0; i < this.pages.length; i++) {
				if(this.pages[i] == layout) {
					return this.getCurrentPageForLayoutIndex(i);
				}
			}

			return null;
		},
		
		getCurrentPageForLayoutIndex: function(layoutIndex) {
			var pageIndex = this.getPageIndexForLayoutIndex(layoutIndex);
			if(pageIndex < 0 && this.wrapPages) {
				return this.pageSet.getPage(this.pageSet.getTotalPages() + pageIndex);
			} else if(pageIndex < this.pageSet.getTotalPages()) {
				return this.pageSet.getPage(pageIndex);
			} else if(this.wrapPages) {
				return this.pageSet.getPage(pageIndex - this.pageSet.getTotalPages());
			} else {
				return null;
			}
		},
		getPageIndexForLayoutIndex: function(layoutIndex) {
			return this.currentPage - this.pageOffset + layoutIndex - this.tmpPageOffset;
		},

		onConsumeEvent: function (event) {
			if (event.context[0] == 'pageSet' && event.context.length > 1) {
				if (event.context[1] == 'theme') {
					this.updateTheme();
				} else if(event.context[1] == 'pageNumberPosition') {
					this.updatePageNumberPosition();
				} else if(event.context[1] == 'pageNumberCSS') {
					this.updatePageNumberCSS();
				} else if(event.context.length >= 3 && event.context[1] === 'comments' && !$.isInit(event.args[0])) {
					var comment = event.args[1];
					var message = comment.author.name + ' added a comment: "' + comment.comment + '"';
					this.sendUserNotification('info', message);
				} else if (event.context[1] == 'layoutDimensions') {
					this.updatePagesAfterChange(true);
				}
			} else if (event.context[0] == 'page') {
				if(event.context.length == 2) {
					var forcePageRefresh = false;
					if($.CurrentUser && $.CurrentUser.userId !== event.userId) {
						var firstPageNumber;
						var pageOffsetChange = this.pageOffset - this.lastLoadedPageOffset;
						if(event.action == 'insert') {
							var pagesAdded = 1;
							if($.isArray(event.args[0])) {
								pagesAdded = event.args[0].length;
							}

							firstPageNumber = this.currentPage - this.pageOffset - this.tmpPageOffset + this.pageSet.getFirstPageNumber() - 1;
							if(event.args[1] < firstPageNumber) {
								if(pageOffsetChange < 0) {
									this.tmpPageOffset += pagesAdded;
								} else if(pageOffsetChange >= 0) {
									this.tmpPageOffset -= pagesAdded;
								} else if(firstPageNumber % 2 === 1 && this.currentPage > 0) {
									this.currentPage -= this.pages.length;
								}

								if(Math.abs(this.tmpPageOffset) >= this.pages.length) {
									let offsetMultiplier = 1;
									if(this.tmpPageOffset > 0) {
										offsetMultiplier = -1;
									}
									this.currentPage -= this.tmpPageOffset * offsetMultiplier;
									this.tmpPageOffset = this.tmpPageOffset % this.pages.length;
									this.currentPage += this.tmpPageOffset * offsetMultiplier;

									var pageData = event.args[0];
									if($.isArray(pageData)) {
										pageData = pageData[0];
									}
									var insertedPage = this.pageSet.getPageById(pageData.id);
									if(!insertedPage) {
										this.currentPage -= this.pages.length;
									}
								}

								forcePageRefresh = true;
							}
						} else if(event.action == 'remove') {
							var pagesRemoved = 1;
							if(event.extras && event.extras.pagesRemoved) {
								pagesRemoved = event.extras.pagesRemoved;
							}

							var firstPageNumberInc = 0;
							var pageSetFirstPageNumber = this.pageSet.getFirstPageNumber();
							if(pageSetFirstPageNumber > 1) {
								firstPageNumberInc = pageSetFirstPageNumber + 1;
							}

							firstPageNumber = this.currentPage - this.pageOffset - this.tmpPageOffset + firstPageNumberInc;
							if(event.args[1] < firstPageNumber) {
								if(pageOffsetChange < 0) {
									this.tmpPageOffset += pagesRemoved;
								} else if(pageOffsetChange === 0) {
									if(this.pageOffset === 0) {
										this.tmpPageOffset += pagesRemoved;
									} else {
										this.tmpPageOffset -= pagesRemoved;
									}
								} else if(pageOffsetChange > 0) {
									this.tmpPageOffset -= pagesRemoved;
								} else if(firstPageNumber % 2 === 1 && this.currentPage > 0) {
									this.currentPage -= this.pages.length;
								}

								if(this.tmpPageOffset >= this.pages.length) {
									this.currentPage -= this.tmpPageOffset;
									this.tmpPageOffset = this.tmpPageOffset % this.pages.length;
									this.currentPage += this.tmpPageOffset;

									let pageData = event.args[0];
									if($.isArray(pageData)) {
										pageData = pageData[0];
									}
									var removedPageWasInSet = this.pageSet.shouldPageExistInSet(pageData.id);
									if(!removedPageWasInSet) {
										this.currentPage += Math.floor(pagesRemoved / this.pages.length) * this.pages.length;
									}

									if(this.currentPage < 0) {
										this.currentPage = 0;
									}
								}

								// Can happen if removing 3 pages at once with user page assignments
								if(-this.tmpPageOffset >= this.pages.length) {
									this.tmpPageOffset = this.tmpPageOffset % this.pages.length;
								}

								forcePageRefresh = true;
							}
						} else if(event.action == 'moveBefore') {
							var pagesMoved = 1;
							if(event.extras && event.extras.pagesMoved) {
								pagesMoved = event.extras.pagesMoved;
							}

							firstPageNumberInc = 0;
							pageSetFirstPageNumber = this.pageSet.getFirstPageNumber();
							if(pageSetFirstPageNumber > 1) {
								firstPageNumberInc = pageSetFirstPageNumber + 1;
							}

							var beforeFirstPageNumber = this.currentPage - this.pageOffset - this.tmpPageOffset + this.pageSet.getFirstPageNumber() - pagesMoved;
							var afterFirstPageNumber = this.currentPage - this.pageOffset - this.tmpPageOffset + firstPageNumberInc;
							if(event.args[0] > beforeFirstPageNumber && event.args[1] <= beforeFirstPageNumber) {
								// Moves from after view to before view
								if(pageOffsetChange < 0) {
									this.tmpPageOffset += pagesMoved;
								} else if(pageOffsetChange >= 0) {
									this.tmpPageOffset -= pagesMoved;
								} else if(beforeFirstPageNumber % 2 === 1 && this.currentPage > 0) {
									this.currentPage -= this.pages.length;
								}

								if(Math.abs(this.tmpPageOffset) >= this.pages.length) {
									let offsetMultiplier = 1;
									if(this.tmpPageOffset > 0) {
										offsetMultiplier = -1;
									}

									this.currentPage -= this.tmpPageOffset * offsetMultiplier;
									this.tmpPageOffset = this.tmpPageOffset % this.pages.length;
									this.currentPage += this.tmpPageOffset * offsetMultiplier;

									let movedPage = this.pageSet.getPageById(event.context[1]);
									if(!movedPage) {
										this.currentPage -= Math.floor(pagesMoved / this.pages.length) * this.pages.length;
									}

									if(this.currentPage < 0) {
										this.currentPage = 0;
									}

									forcePageRefresh = true;
								}
							} else if(event.args[0] < afterFirstPageNumber && event.args[1] > afterFirstPageNumber) {
								// Moves from before view to after view
								if(pageOffsetChange <= 0) {
									this.tmpPageOffset += pagesMoved;
								} else if(pageOffsetChange > 0) {
									this.tmpPageOffset -= pagesMoved;
								}  else if(afterFirstPageNumber % 2 === 1 && this.currentPage > 0) {
									this.currentPage -= this.pages.length;
								}

								if(Math.abs(this.tmpPageOffset) >= this.pages.length) {
									let offsetMultiplier = 1;
									if(this.tmpPageOffset < 0) {
										offsetMultiplier = -1;
									}
									
									this.currentPage -= this.tmpPageOffset * offsetMultiplier;
									this.tmpPageOffset = this.tmpPageOffset % this.pages.length;
									this.currentPage += this.tmpPageOffset * offsetMultiplier;

									let movedPage = this.pageSet.getPageById(event.context[1]);
									if(!movedPage) {
										this.currentPage += Math.floor(pagesMoved / this.pages.length) * this.pages.length;
									}

									if(this.currentPage < 0) {
										this.currentPage = 0;
									}
								}

								// Can happen if removing 3 pages at once with user page assignments
								if(-this.tmpPageOffset >= this.pages.length) {
									this.tmpPageOffset = this.tmpPageOffset % this.pages.length;
								}
								
								forcePageRefresh = true;
							}
						}
					}

					let shouldRefresh = false || ($.CurrentUser && $.CurrentUser.userId === event.userId);

					// Check if one of the removed pages was displayed
					let pagesStillExisting = this.pages.filter(flowLayout => {
						if(!flowLayout.page) {
							return true;
						}

						return this.pageSet.pages.includes(flowLayout.page);
					});
					if(pagesStillExisting.length !== this.pages.length) {
						shouldRefresh = true;
					}

					// Check if pages are broken up due to moving or inserting a new page
					if(this.pages.length > 1 && this.pages[0].page && this.pages[1].page) {
						let indexes = this.pages.map(flowLayout => {
							let page = flowLayout.page;
							return this.pageSet.pages.indexOf(page);
						});
						if(indexes[1] !== indexes[0] + 1) {
							shouldRefresh = true;
						}
					}

					if(shouldRefresh) {
						this.updatePagesAfterChange();
					} else if(forcePageRefresh) {
						this.pages.forEach(flowLayout => {
							if(flowLayout) {
								flowLayout.refreshSimplePage();
							}
						});
					}
				} else {
					for (var i = 0; i < this.pages.length; i++) {
						var layout = this.pages[i];
						// Found a matching page
						var page = layout.getPage();
						if (page && page.id == event.context[1]) {
							layout.onConsumePageEvent(event);
						}
					}

					if(event.context.length >= 3 && event.context[2] === 'comments' && event.action === 'insert' && $.isPlainObject(event.args[0])) {
						comment = event.args[0];
						page = this.pageSet.getPageById(event.context[1]);
						if(page) {
							message = comment.author.name + ' added a comment to ' + page.getPageNumberDisplay() + ': "' + comment.comment + '"';
							this.sendUserNotification('info', message);
						}
					}
				}
			} else if(event.context[0] == 'subjects') {
				for(i = 0; i < this.pages.length; i++) {
					layout = this.pages[i];
					layout.onConsumeSubjectEvent(event);
				}
			} else if(event.context[0] === 'batches') {
				for(i = 0; i < this.pages.length; i++) {
					layout = this.pages[i];
					page = layout.getPage();
					if(page && page.getClass && page.getClass() && page.getClass().id === event.context[1]) {
						layout.onConsumePageEvent(event);
					}
				}

				if(event.context.length === 3 && event.context[2] === 'name') {
					var classButton = $('#class' + event.context[1]);
					if(classButton.length) {
						classButton.find('span').text(event.args[1]);
					}
				}
			} else if(event.context[0] === 'customDictionary') {
				this.updatePagesAfterChange(true);
			}
		},
		getActiveUsers: function() {
			if(!this.pageSet.db || !this.pageSet.db.onUpdateCurrentSelection || !this.pageSet.db.getActiveUsers) {
				return null;
			}

			return this.pageSet.db.getActiveUsers();
		},
		onUpdateCurrentSelection: function(user, currentSelection) {
			// Don't care if not editable anyways
			if(!this.editable) {
				return;
			}

			if(user.currentSelectionDivs) {
				// We want to make a copy since setOtherUserFocused will remove from the array as it executes
				$.merge([], user.currentSelectionDivs).forEach(function(currentSelectionDiv) {
					if (!currentSelection) {
						currentSelectionDiv.setOtherUserFocused(user, null);
					} else if (currentSelectionDiv.instance.id != currentSelection.id) {
						currentSelectionDiv.setOtherUserFocused(user, null);
					}
				});
			}

			if(currentSelection) {
				for (var i = 0; i < this.pages.length; i++) {
					var layout = this.pages[i];
					if (layout.visible) {
						layout.onUpdateCurrentSelection(user, currentSelection);
					}
				}
			}
			this.updateCurrentUsersVisible();
		},
		updateCurrentUsersVisible: function() {
			let activeUsers = this.getActiveUsers();
			if(!activeUsers) {
				return;
			}

			let visibleUsers = activeUsers.filter(user => {
				return this.pages.filter(layout => layout.visible && layout.page && layout.page.pageNumber === user.currentPage).length > 0;
			});
			if(visibleUsers.length) {
				if(!this.visibleUsersLabel) {
					this.visibleUsersLabel = $('<div class="otherUserLabelsBar"><div class="ui small label otherUserLabel green"></div></div>').appendTo(this.wrapper);
					let exampleLayout = this.getFirstVisibleLayout();
					if(exampleLayout && this.wrapper && this.wrapper.getBoundingClientRect) {
						let topDiff = exampleLayout.getBoundingClientRect().top - this.wrapper.getBoundingClientRect().top;
						$(this.visibleUsersLabel).css('top', 'calc(' + topDiff + 'px - 2.5em)');
					}
				}
				this.visibleUsersLabel.show();

				let visibleNames = visibleUsers.map(user => user.name).join(', ');
				this.visibleUsersLabel.find('.otherUserLabel').text('Currently viewing: ' + visibleNames)
			} else if(this.visibleUsersLabel) {
				this.visibleUsersLabel.hide();
			}
		},
		refreshTitles: function(skipPage) {
			for(var i = 0; i < this.pages.length; i++) {
				var page = this.pages[i];
				if(page != skipPage) {
					page.refreshTitle();
				}
			}
		},
		updateLabelCSS: function(skipPage) {
			for(var i = 0; i < this.pages.length; i++) {
				var page = this.pages[i];
				if(page != skipPage) {
					page.updateLabelCSS();
				}
			}
		},
		setLastCreatedText: function(lastCreatedText) {
			this.lastCreatedText = lastCreatedText;
		},
		getLastCreatedText: function() {
			return this.lastCreatedText;
		},
		setUserSettings: function(settings) {
			for(var i = 0; i < this.pages.length; i++) {
				this.pages[i].setUserSettings(settings);
			}
		},
		setForcedDPI: function(dpi) {
			for(var i = 0; i < this.pages.length; i++) {
				this.pages[i].setForcedDPI(dpi);
			}
		},
		setZoom: function(zoom) {
			this.pages.forEach(function(page) {
				page.setZoom(zoom);
			});

			this.updatePagesAfterChange(true);
		},
		getFocusedLayout: function() {
			// Return page we are highlighting content in
			for(var i = 0; i < this.pages.length; i++) {
				var page = this.pages[i];
				if(page.visible === false) {
					continue;
				}

				if(document.activeElement) {
					if($.contains(page, document.activeElement)) {
						return page;
					}
				}
			}

			// Or try to return last clicked on page
			for(i = 0; i < this.pages.length; i++) {
				page = this.pages[i];
				if(page.visible === false) {
					continue;
				}

				if(this.lastClickedLayout == page) {
					return page;
				}
			}

			// Otherwise return first visible page
			for(i = 0; i < this.pages.length; i++) {
				page = this.pages[i];
				if(page.visible === false) {
					continue;
				}

				return page;
			}

			return this.pages[0];
		},
		removeFrameByInstance: function(instance, save) {
			this.pages.forEach(function(layout) {
				if(layout.visible && layout.page) {
					layout.removeFrameByInstance(instance, save);
				}
			});
		},
		removeTextByInstance: function(instance, save) {
			this.pages.forEach(function(layout) {
				if(layout.visible && layout.page) {
					layout.removeTextByInstance(instance, save);
				}
			});
		},
		sendUserNotification: function(type, message) {
			if(!window.alertify) {
				return;
			}

			if(type === 'error') {
				window.alertify.error(message);
			} else {
				window.alertify.success(message);
			}
		},
		dragEventCandidSelectTarget(event, ui) {
			this.dragEventSelectTarget(event, function() {
				return $(this).hasClass('flowLayoutFrame') && $(this).hasClass('ui-droppable') && $(this).droppable('option').accept.call(this, ui.helper);
			});
		},
		dragEventSelectTarget(event, filter) {
			let position = {
				x: event.clientX,
				y: event.clientY
			};
			this.pages.forEach(layout => {
				let frame = layout.getTopContentElementAtPosition(position, filter);
				if(frame && !frame.movementLocked) {
					$(frame).addClass('dragTarget');
					$(frame).siblings('.dragTarget').removeClass('dragTarget');
				} else {
					$(layout).find('.dragTarget').removeClass('dragTarget');
				}
			});
		},
		dragEventEndTarget: function() {
			this.pages.forEach(layout => {
				$(layout).find('.dragTarget').removeClass('dragTarget');
			});
		},

		updateCustomUserLabels: function() {
			this.pages.forEach(layout => {
				layout.setupCustomUserLabel();
			});
		},

		destroy: function() {
			this.pages.forEach(function(page) {
				page.destroy();
			});
		},

		currentPage: 0,
		pageOffset: 0,
		tmpPageOffset: 0,
		pages: [],
		wrapper: wrapper,
		editable: true,
		classSizeAvailable: true,
		lastLoadedPageOffset: 0
	});

	if(options) {
		if(options.editable === false) {
			this.editable = false;
		}
		if(options.onPageChange) {
			this.onPageChange = options.onPageChange;
		}
		this.pageSet = options.pageSet;
		if(options.handleEvents && this.pageSet && this.pageSet.db && this.pageSet.db.userEvents) {
			this.pageSet.db.userEvents.addOnConsumeEventHandler(this.onConsumeEventHandler = function(event) {
				me.onConsumeEvent(event);
			});
			this.pageSet.db.onUpdateCurrentSelection = function(user, currentSelection) {
				me.onUpdateCurrentSelection(user, currentSelection);
			};
		}
		this.showLabels = options.showLabels;

		if(options.pageOffset) {
			this.pageOffset = options.pageOffset;
		}

		if(options.refreshOnWindowResize !== false) {
			window.addEventListener('resize', function() {
				me.updatePagesAfterChange(true);
			});
		}
	}
};