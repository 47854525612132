import '../../js/pages/book';
import '../../js/pages/candid-page';
import '../../js/pages/chapter-overflow-page';
import '../../js/pages/chapter-page';
import '../../js/pages/cover-page';
import '../../js/pages/inside-cover-page';
import '../../js/pages/layout-page';
import '../../js/pages/layout-set';
import '../../js/pages/placeholder-page';
import '../../js/pages/table-of-contents';
import '../../js/pages/table-of-contents-overflow';