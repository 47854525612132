<template>
	<v-layout justify-center align-center>
		<payment-receipt :order="order" :project="project" :user-invited="userInvited" v-if="order && !loading" />

		<v-alert
			type="error"
			:value="!!errorMessage"
			transition="scale-transition">

			{{ errorMessage }}
		</v-alert>

		<v-progress-circular indeterminate v-if="loading" style="margin-bottom: 2em"></v-progress-circular>
	</v-layout>
</template>

<script>
import axios from 'axios';
import PaymentReceipt from './components/PaymentReceipt';

export default {
	name: 'ReceiptPage',
	data() {
		return {
			loading: false,
			errorMessage: null,
			order: null,
			userInvited: false,
			project: null
		}
	},
	components: {
		PaymentReceipt
	},
	mounted() {
		this.loading = true;
		this.errorMessage = null;

		axios.get(this.baseUrl + 'api/v1/orders?orderId=' + this.$route.params.id).then((response) => {
			this.order = response.data.order;
			this.project = response.data.project;
			this.userInvited = response.data.userInvited;
			this.loading = false;
		}).catch((error) => {
			// eslint-disable-next-line
			console.error(error);

			if(error && error.message) {
				this.errorMessage = error.message;
			}
			this.loading = false;
		});
	}
}
</script>

<style scoped>
	
</style>