$.BookCoverPage = function(startSettings, side) {
	var obj = new $.BookCandidPage();
	obj._setLocked = obj.setLocked;
	obj._updatePageLabel = obj.updatePageLabel;

	$.extend(obj, {
		setLocked: function (locked) {
			this._setLocked(locked);
			this.updatePageLabel();
		},
		canThemeWhileLocked: function() {
			return $.getStudioSetting('coverLocked', false);
		},
		updatePageLabel: function () {
			if (this.locked) {
				var lockedMessage = 'Locked';
				if($.getStudioSetting('coverLocked', false)) {
					var popup = $.getStudioSetting('coverLockedMessage', '');
					if(popup) {
						var elem = $('<div>').text(popup);
						elem.linkify({
							target: '_blank'
						});

						this.pageLabelPopup = {
							html: elem.html(),
							hoverable: true
						};
					} else {
						this.pageLabelPopup = null;
					}
					lockedMessage = 'Locked by Studio';
				} else {
					this.pageLabelPopup = null;
				}

				var displayLabel = this.pageLabelDisplay + ' <span style="color: red">(' + lockedMessage + ')</span>';
				if(this.pageLabelPopup) {
					this.pageLabel = {
						display: displayLabel,
						popup: this.pageLabelPopup,
						forcePopup: true
					};

					if($.getStudioSetting('coverLocked', false) && this.pageSet && this.pageSet.getTheme() && this.pageSet.hasThemePart(this.getThemePartName())) {
						this.pageLabel.sidebar = [
							{
								name: 'Themes',
								icon: 'setting',
								popup: 'Change settings on theme',
								onClick: function (page, layout) {
									layout.openThemeSettings();
								}
							},
							{
								name: 'Clear Theme',
								icon: 'picture',
								popup: 'Clear theme on book',
								onClick: function (page, layout) {
									layout.clearTheme();
								}
							}
						];
						this.pageLabel.forceSidebar = true;
					}
				} else if($.userPermissions.editCoverLocked && this.pageSet && !this.pageSet.isSubmitted()) {
					var sidebar = [
						{
							name: 'Unlock Covers',
							icon: 'unlock',
							onClick: function(page, layout) {
								page.unlockCovers(layout);
							}
						}
					];

					if(obj.pageSet && obj.pageSet.renderSinglePage) {
						let previewOption = {
							name: 'Preview Cover',
							icon: 'location arrow',
							popup: 'Preview PDF of just the covers',
							onClick: function() {
								obj.renderCovers();
							}
						};
						if($.userPermissions.productionPDF) {
							$.extend(previewOption, {
								name: 'Render Cover',
								popup: 'Render a production copy of just the covers',
							});
						}
						sidebar.unshift(previewOption);
					}

					this.pageLabel = {
						display: displayLabel,
						popup: 'Options',
						forcePopup: true,
						forceSidebar: true,
						sidebar: sidebar
					};
				} else {
					this.pageLabel = displayLabel;
				}
			} else {
				this._updatePageLabel();
				if (this.pageLabel && this.pageLabel.sidebar) {
					this.pageLabel.popup = 'Options' + (this.getFreeMovementLocked() ? ' (Locked)' : '');
				} else {
					this.pageLabel.popup = '';
				}

				if(!$.getStudioSetting('coverLocked', false)) {
					var editSpreadLabel = 'Edit Spread';
					var editSpreadPopup = 'Enter design mode for the full cover spread';
					if($.flowLayoutSet && $.flowLayoutSet.wrapPages) {
						editSpreadLabel = 'Disable Spread';
						editSpreadPopup = null;
					}
					this.pageLabel.sidebar.push({
						name: editSpreadLabel,
						popup: editSpreadPopup,
						icon: 'edit',
						onClick: function() {
							$.flowLayoutSet.wrapPages = !$.flowLayoutSet.wrapPages;
							$.flowLayoutSet.updatePagesAfterChange(true);
						}
					});

					if(obj.pageSet && obj.pageSet.renderSinglePage) {
						let previewOption = {
							name: 'Preview Cover',
							icon: 'location arrow',
							popup: 'Preview PDF of just the covers',
							onClick: function() {
								obj.renderCovers();
							}
						};
						if($.userPermissions.productionPDF) {
							$.extend(previewOption, {
								name: 'Render Cover',
								popup: 'Render a production copy of just the covers',
							});
						}
						this.pageLabel.sidebar.push(previewOption);

						if($.userPermissions.submitYearbook) {
							this.pageLabel.sidebar.push({
								name: 'Submit Cover',
								icon: 'location arrow',
								popup: 'Finalize and Submit Cover',
								onClick: function (page, layout) {
									$.Confirm('Finalize and Submit Cover', 'Are you sure you want to submit the cover?', function() {
										page.submitCovers(layout);
									});
								}
							});
						}
					}
				}
			}
		},
		unlockCovers: function(layout) {
			$.ajax({
				url: 'ajax/saveProjectSettings.php',
				type: 'post',
				data: {
					jobId: $.getGETParams().jobId,
					settings: [
						{
							id: 2,
							value: false
						}
					]
				},
				error: function() {
					$.Alert('Error', 'Failed to save cover status');
				}
			});

			this.pageSet.setCoverLocked(false);
			layout.setEditable(true, true);
			layout.updateMovementLocked();
			layout.setLabel(this.getPageLabel());

			if ($.userEvents) {
				$.userEvents.addEvent({
					context: [this.pageSet, 'coverLocked'],
					action: 'update',
					args: [true, false],
					permanent: true
				});
			}
		},

		renderCovers: function() {
			var isProduction = !!$.userPermissions.productionPDF;
			var render = new $.PageRender({
				covers: this.pageSet.getCoverRenderTarget()
			}, {
				production: isProduction,
				batchName: 'Cover Preview',
				backgroundRender: !$.getGETParams().debugPrince && !$.getGETParams().debugPrinceCombo,
				onError: function () {
					$.Alert('Error', 'Failed to render covers');
				}
			});
			render.showPDFDialog();
		},
		submitCovers: function (layout) {
			var preview = new $.PageRender({
				covers: this.pageSet.getCoverRenderTarget()
			}, {
				production: true,
				completeAfterWorkerStarts: true,
				batchName: 'Covers',
				onError: function () {
					$('#pageLoading').removeClass('active');
					$.Alert('Error', 'Failed to render covers');
				}
			});

			var me = this;

			$('#pageLoading').addClass('active');
			preview.generatePDFFromPages(function () {
				$('#pageLoading').removeClass('active');
				$.ajax({
					url: 'ajax/saveProjectSettings.php',
					type: 'post',
					data: {
						jobId: $.getGETParams().jobId,
						settings: [
							{
								id: 2,
								value: true
							}
						]

					}
				});
				me.pageSet.setCoverLocked(true);
				layout.setEditable(true, false);
				layout.setLabel(me.getPageLabel());

				if ($.userEvents) {
					$.userEvents.addEvent({
						context: [me.pageSet, 'coverLocked'],
						action: 'update',
						args: [false, true],
						permanent: true
					});
				}

				$.Alert('Success', 'Book covers submitted for rendering.  You will receive an email when it is finished and ready to be downloaded.', null, {
					allowMultiple: true
				});
			});
		},
		getThemePartName: function() {
			return 'Cover';
		},
		shouldHideInsidePageBorder: function() {
			if(!$.flowLayoutSet) {
				return false;
			} else {
				return $.flowLayoutSet.wrapPages;
			}
		},
		fixSpineTextOnFirstLoad: function(textNode) {
			var textRect = textNode.getBoundingClientRect();
			var wrapperRect = textNode.wrapper.getBoundingClientRect();

			var leftDiff = textRect.left - wrapperRect.left;
			if(leftDiff < 0) {
				return;
			}

			// Want to scoot this over to be in the middle of both pages
			textNode.changeInstanceProperty('position', {
				left: textNode.instance.position.left - (leftDiff / textNode.ratio) - (textRect.width / 2 / textNode.ratio),
				top: textNode.instance.position.top
			});
			textNode.setupPosition(textNode.instance.position);
			textNode.checkOverflowToLinkedLayouts();
		},
		canToggleContentLocks: function() {
			return this.pageSet && this.pageSet.canToggleContentLocks();
		},

		layout: null,
		side: side,
		type: 'cover',
		pageLabelDisplay: (side == 'front' ? 'Front' : 'Back') + ' Cover',
		pageLabelPopup: '',
	}, startSettings);
	$.FlowCoverPageCommon(obj);

	obj.updatePageLabel();
	return obj;
};