$.FlowPageWithSubjects = function(settings) {
	var obj = new $.FlowPage();

	$.extend(obj, {
		getSubjects: function() {
			if(this.pageSet && this.pageSet.subjects) {
				return this.pageSet.subjects;
			} else {
				return null;
			}
		},
		hasPrimarySubject: function() {
			return true;
		}
	}, settings);

	return obj;
};