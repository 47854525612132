$.BookChapterOverflowPage = function(parentPage) {
	var page = new $.BookChapterPage();
	var getExtraProperty = page.getExtraProperty;
	var setExtraProperty = page.setExtraProperty;

	$.extend(page, {
		getTitle: function() {
			return null
		},

		setExtraProperty: function(name, value, options) {
			if(name === 'textLineHeight') {
				return setExtraProperty.apply(this.getRootPage(), arguments);
			} else {
				return setExtraProperty.apply(this, arguments);
			}
		},
		getExtraProperty: function(name, defaultValue) {
			if(name === 'textLineHeight') {
				return getExtraProperty.apply(this.getRootPage(), arguments);
			} else {
				return getExtraProperty.apply(this, arguments);
			}
		},
		type: 'chapter-overflow'
	});
	$.FlowPageOverflowModule(page, parentPage);
	page.updateRootPageProperties.removeItem('grayscale');

	return page;
};