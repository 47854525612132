<template>
	<div class="content ui form settings-builder frame-dynamic-graphics-editor">
		<frame-dynamic-graphics-editor-map v-for="(map, index) in photoFieldMap" :key="index" :map="map" :fields="fields" @removeMap="removeMap" />
		<div class="ui yellow button" @click="addMap">
			<i class="add icon"/>
			Add map
		</div>
	</div>
</template>

<script>
import FrameDynamicGraphicsEditorMap from './FrameDynamicGraphicsEditorMap.vue';

export default {
	props: ['photoFieldMap', 'fields'],
	methods: {
		addMap: function() {
			this.photoFieldMap.push({
				fieldMap: {
					Grade: ''
				},
				photo: null
			});
		},
		removeMap: function(map) {
			this.photoFieldMap.removeItem(map);
		}
	},
	components: {
		FrameDynamicGraphicsEditorMap
	}
};
</script>

<style scoped>

</style>