$.FlowLayoutFrameUtils = {
	getToolByName: function(name) {
		var frame = this;
		var defaultSizeScaling = true;
		if($.defaultEffectSizeScaling !== undefined) {
			defaultSizeScaling = $.defaultEffectSizeScaling;
		}

		switch(name) {
			case 'stroke':
				return {
					icon: 'square outline',
					popup: 'Stroke',
					colorPicker: true,
					allowTransparency: false,
					settingsPicker: [
						{
							name: 'thickness',
							description: 'Thickness: %value%',
							type: 'inc/dec',
							range: [1, 6],
							value: this.instance.border ? this.instance.border.thickness : 1
						},
						{
							name: 'sizeScaling',
							description: 'Size scaling',
							type: 'checkbox',
							value: this.instance.border ? this.instance.border.sizeScaling : defaultSizeScaling,
							help: 'Scale stroke based on the size of the image'
						}
					],
					allowEmpty: true,
					getCurrentColor: function(selection) {
						return selection.border ? selection.border.color : 'transparent';
					},
					onChange: function(selection, color) {
						if(color && color != 'transparent') {
							if (selection.border) {
								selection.border.color = color;
							} else {
								selection.border = {
									color: color,
									thickness: 1
								};

								selection.border.sizeScaling = defaultSizeScaling;
							}

							frame.changeInstanceProperty('border', selection.border);
						} else {
							frame.changeInstanceProperty('border', null);
						}

						frame.applyFilters(selection);
					},
					onChangeSetting: function(section, name, value) {
						if(!section.border) {
							section.border = {
								color: 'rgb(0, 0, 0)',
								thickness: 1
							};
						}

						section.border[name] = value;
						frame.changeInstanceProperty('border', section.border);
						frame.applyFilters(section);
					},
					updateButtonColor: function (div, color) {
						if(color == 'transparent') {
							color = 'black';
							div.removeClass('active');
						} else {
							div.addClass('active');
						}

						div.find('i').css('color', color);
					}
				};
			case 'drop shadow':
				var defaultColor = this.defaultDropShadow ? this.defaultDropShadow.color : 'rgb(0, 0, 0)';
				var defaultDepth = this.defaultDropShadow ? this.defaultDropShadow.depth : 6;
				var defaultIntensity = this.defaultDropShadow ? this.defaultDropShadow.intensity : 4;
				var defaultRotation = this.defaultDropShadow ? this.defaultDropShadow.rotation : 315;

				return {
					icon: 'cube',
					popup: 'Drop Shadow',
					colorPicker: true,
					allowTransparency: false,
					settingsPicker: [
						{
							name: 'depth',
							description: 'Depth of %value%',
							type: 'inc/dec',
							range: [2, 20],
							value: this.instance.dropShadow ? this.instance.dropShadow.depth : defaultDepth
						},
						{
							name: 'intensity',
							description: 'Intensity of %value%',
							type: 'inc/dec',
							range: [1, 8],
							value: this.instance.dropShadow ? this.instance.dropShadow.intensity : defaultIntensity
						},
						{
							name: 'sizeScaling',
							description: 'Size scaling',
							type: 'checkbox',
							value: this.instance.dropShadow ? this.instance.dropShadow.sizeScaling : defaultSizeScaling,
							help: 'Scale drop shadow based on the size of the image'
						},
						{
							name: 'rotation',
							description: 'Rotation',
							type: 'positiveFloat',
							value: (this.instance.dropShadow && $.isInit(this.instance.dropShadow.rotation)) ? this.instance.dropShadow.rotation : defaultRotation,
							range: [0, 360],
							ignoreFocusHiddenInput: true,
							help: 'Change which direction the drop shadow is displayed'
						}
					],
					allowEmpty: true,
					getCurrentColor: function(selection) {
						return selection.dropShadow ? selection.dropShadow.color : 'transparent';
					},
					onChange: function(selection, color) {
						if(color && color != 'transparent') {
							var dropShadow = selection.dropShadow;
							if(dropShadow) {
								dropShadow.color = color;
							} else {
								dropShadow = {
									color: color,
									intensity: defaultIntensity,
									depth: defaultDepth,
									rotation: defaultRotation
								};
							}

							dropShadow.sizeScaling = defaultSizeScaling;

							frame.changeInstanceProperty('dropShadow', dropShadow);
						} else {
							frame.changeInstanceProperty('dropShadow', null);
						}

						frame.applyFilters(selection);
					},
					onChangeSetting: function(section, name, value) {
						if(!section.dropShadow) {
							section.dropShadow = {
								color: defaultColor,
								intensity: defaultIntensity,
								depth: defaultDepth,
								rotation: defaultRotation
							};
						}

						section.dropShadow[name] = value;
						frame.changeInstanceProperty('dropShadow', section.dropShadow);
						frame.applyFilters(section);
					},
					updateButtonColor: function (div, color) {
						if(color == 'transparent') {
							color = 'black';
							div.removeClass('active');
						} else {
							div.addClass('active');
						}

						div.find('i').css('color', color);
					},
					updateSettingsPicker: function(settingsPicker, selection) {
						let rotationField = settingsPicker.find('#settings-rotation');
						let rotationInput = rotationField.find('input');
						if($.fn.range && !rotationField.find('.ui.range').length) {
							let inputId = Math.round(Math.random() * 1000) + '-rotation';
							rotationInput.attr('id', inputId);
							let range = $('<div class="ui range">');
							range.insertBefore(rotationInput);
							range.range({
								start: (selection.dropShadow && $.isInit(selection.dropShadow.rotation)) ? selection.dropShadow.rotation : defaultRotation,
								min: 0,
								max: 361,
								input: '#' + inputId,
								onChange: (val, meta) => {
									rotationField.find('label').text('Rotation: ' + val + '°');
									if(!meta.triggeredByUser || !frame.instance.dropShadow || val === frame.instance.dropShadow.rotation) {
										return;
									}

									let dropShadow = frame.instance.dropShadow ? $.extend(true, {}, frame.instance.dropShadow) : {
										color: defaultColor,
										intensity: defaultIntensity,
										depth: defaultDepth,
										rotation: defaultRotation
									};
									dropShadow.rotation = val;
									frame.changeInstanceProperty('dropShadow', dropShadow);
									frame.applyFilters(frame.instance);
								}
							});
							
							rotationInput.on('keyup', function() {
								range.range('set value', this.value);
							});
							rotationInput.hide();
						}

						if(selection && selection.dropShadow && $.isInit(selection.dropShadow.rotation)) {
							rotationInput[0].value = selection.dropShadow.rotation;
							let range = rotationField.find('.ui.range');
							if(range && $.fn.range) {
								range.range('set value', selection.dropShadow.rotation);
							}
						}
					}
				};
			case 'invert': case 'grayscale':
				return {
					icon: name == 'invert' ? 'retweet' : 'grey square',
					popup: name == 'invert' ? 'Invert' : 'Grayscale',
					isActive: function(selection) {
						return selection[name];
					},
					onClick: function(selection) {
						frame.changeInstanceProperty(name, !selection[name]);
						frame.applyFilters(selection);
					}
				};
			case 'blur': case 'opacity': case 'saturate': case 'contrast': case 'brightness': case 'hue':
				var properties = $.extend(true, {}, this.toolSettingProperties[name]);

				properties.settingsPicker.name = name;
				properties.settingsPicker.value = this.instance[name] ? this.instance[name] : properties.defaultValue;

				return $.extend(properties, {
					onChange: function(selection, value) {
						frame.changeInstanceProperty(name, value);
						frame.applyFilters(selection);
					},
					isActive: function(selection) {
						return $.isInit(selection[name]) && selection[name] != properties.defaultValue;
					}
				});
			case 'crop':
				return {
					icon: 'crop',
					popup: 'Crop image',
					onClick: function() {
						frame.editCrop();
						return false;
					},
					isActive: function(selection) {
						return !!selection.crop;
					}
				};
			case 'crop pose':
				return {
					icon: 'crop',
					popup: 'Crop',
					onClick: function() {
						frame.editCrop();
						return false;
					},
					isActive: function(selection) {
						return !!selection.crop;
					}
				};
			case 'flip horizontal': case 'flip vertical':
				var propName = (name == 'flip horizontal') ? 'flipHorizontal' : 'flipVertical';
				return {
					icon: name.replace('flip', 'resize'),
					popup: name.replace('flip', 'Flip'),
					isActive: function(selection) {
						return selection[propName];
					},
					onClick: function(selection) {
						frame.changeInstanceProperty(propName, !selection[propName]);
						frame.applyFilters(selection);
					}
				};
			case 'tags':
				return {
					icon: 'tag',
					popup: 'Image Info & Tags',
					onClick: function() {
						frame.openTagEditor();
					}
				};
			case 'applyToAll':
				return {
					icon: 'edit',
					popup: 'Apply Effects To Entire Page',
					onClick: function(selection) {
						var page = this.wrapper.page;
						if(page) {
							page.copyCandidEffectsToOthers(this.instance.id, selection);
							this.wrapper.refreshFrames();
							$.setSingleTimeout.call(this, 'debounceApplyGroupBorder', () => {
								this.wrapper.postAddedContent();
							}, 1);
						}
					}
				};
			case 'copy':
				return this.getCopyContentButton();
			case 'remove':
				return {
					icon: 'remove',
					popup: 'Remove image',
					color: 'red',
					onClick: function() {
						this.removeContent(true);
					}
				};
			case 'replace':
				return {
					icon: 'file image',
					popup: 'Replace image',
					onClick: function() {
						this.requestPhotoReplacement();
					}
				};
			case 'edit versions':
				return {
					icon: 'id badge',
					popup: 'View versions',
					onClick: function() {
						this.openVersionPicker();
					}
				};
			case 'green screen background':
				var types = [
					'Background Off',
					'Project Background',
					'Ordered Background'
				];

				// For subject photos with green screen backgrounds, I don't think having ordered backgrounds show as an option makes sense
				if(frame.isSubjectField && !frame.isSubjectField()) {
					types.removeItem('Ordered Background');
				}

				return {
					title: 'Green Screen',
					dropdown: types,
					iconButton: 'tint',
					popup: 'Use background for green screen images',
					onChange: function(selection, change) {
						if(change === 'project background' || change === 'ordered background') {
							frame.changeInstanceProperty('useGreenScreenBackground', change);
						} else {
							frame.changeInstanceProperty('useGreenScreenBackground', false);
						}
						frame.applyFilters(selection);
					},
					updateLabel: function(selection, dropdown) {
						$(dropdown).removeClass('blue green');

						if(selection.useGreenScreenBackground === true || selection.useGreenScreenBackground === 'project background') {
							$(dropdown).addClass('blue');
							$(dropdown).attr('data-tooltip', 'Project background is used for green screen images');
						} else if(selection.useGreenScreenBackground === 'ordered background') {
							$(dropdown).addClass('green');
							$(dropdown).attr('data-tooltip', 'Ordered backgrounds are used for green screen images');
						} else {
							$(dropdown).attr('data-tooltip', 'Set a background for green screen images');
						}
					},
					isActive: function(selection) {
						return !!selection.useGreenScreenBackground;
					}
				};
			case 'pose selection':
				var poses = [
					'Primary Pose',
					'Yearbook Pose'
				];
				for(let i = 1; i <= 10; i++) {
					poses.push('Pose ' + i);
				}
				for(let i = 1; i <= 5; i++) {
					poses.push('Group Photo ' + i);
				}
				for(let i = 1; i <= 5; i++) {
					poses.push('Ordered Photo ' + i);
				}
				for(let i = 1; i <= 5; i++) {
					poses.push('Ordered Product ' + i);
				}

				return {
					title: 'Pose Selection',
					dropdown: poses,
					onChange: function(selection, change) {
						frame.changeInstanceProperty('poseSelection', change);
					},
					updateLabel: function(selection, dropdown) {
						var poseSelection = selection.poseSelection || 'Primary Pose';
						dropdown.setSelectedValue(poseSelection);
					}
				};
			case 'crop selection':
				var crops = [
					'Primary Crop',
					'No Crop'
				];

				var pageSet = frame.wrapper.getPageSet();
				if(pageSet) {
					$.merge(crops, pageSet.getSubjectPoseCropNames());
				}

				return {
					title: 'Crop Selection',
					dropdown: crops,
					onChange: function(selection, change) {
						frame.changeInstanceProperty('cropSelection', change);
					},
					updateLabel: function(selection, dropdown) {
						dropdown.setSelectedValue(selection.cropSelection || 'primary crop');
					}
				}
			case 'vertical crop alignment':
				var alignments = [
					'Start',
					'Center',
					'End'
				];

				return {
					dropdown: alignments,
					iconButton: 'counterclockwise rotated align left',
					popup: 'Vertical crop alignment',
					onChange: function(selection, change) {
						frame.changeInstanceProperty('verticalCropAnchor', change);
					},
					updateLabel: function(selection, dropdown) {
						var newAlignment = selection.verticalCropAnchor || 'start';
						dropdown.attr('data-tooltip', 'Vertical crop alignment: ' + newAlignment);

						var nextIcon = newAlignment;
						if(nextIcon === 'start') {
							nextIcon = 'right';
						} else if(nextIcon === 'end') {
							nextIcon = 'left';
						}
						dropdown.find('i').removeClass('left center right').addClass(nextIcon);

						if(newAlignment !== 'start') {
							dropdown.addClass('blue');
						} else {
							dropdown.removeClass('blue');
						}
					}
				}
			case 'horizontal crop alignment':
				alignments = [
					'Start',
					'Center',
					'End'
				];

				return {
					dropdown: alignments,
					iconButton: 'align center',
					popup: 'Horizontal crop alignment',
					onChange: function(selection, change) {
						frame.changeInstanceProperty('horizontalCropAnchor', change);
					},
					updateLabel: function(selection, dropdown) {
						var newAlignment = selection.horizontalCropAnchor || 'center';
						dropdown.attr('data-tooltip', 'Horizontal crop alignment: ' + newAlignment);

						var nextIcon = newAlignment;
						if(nextIcon === 'start') {
							nextIcon = 'left';
						} else if(nextIcon === 'end') {
							nextIcon = 'right';
						}
						dropdown.find('i').removeClass('left center right').addClass(nextIcon);

						if(newAlignment !== 'center') {
							dropdown.addClass('blue');
						} else {
							dropdown.removeClass('blue');
						}
					}
				}
			case 'color':
				return {
					icon: 'paint brush',
					popup: window.i18n.t('misc.color'),
					colorPicker: true,
					allowTransparency: false,
					allowEmpty: false,
					getCurrentColor: function(selection) {
						return selection.color;
					},
					onChange: function(selection, color) {
						frame.changeInstanceProperty('color', color);
						frame.setupShape();
					},
					updateButtonColor: function (div, color) {
						div.addClass('active');
						div.find('i').css('color', color);
					}
				};
			case 'caption':
				return {
					icon: 'font',
					popup: 'Show Caption',
					isActive: function() {
						return !!frame.instance.captionTextId;
					},
					onClick: function() {
						frame.toggleCaption();
					}
				};
			case 'split':
				var maxGrid = 10;
				return {
					icon: 'grid layout',
					popup: 'Split into parts',
					settingsPicker: [
						{
							name: 'columns',
							description: '%value% columns',
							type: 'inc/dec',
							range: [1, maxGrid],
							minDisplay: '1',
							maxDisplay: maxGrid,
							defaultValue: 1
						},
						{
							name: 'rows',
							description: '%value% rows',
							type: 'inc/dec',
							range: [1, maxGrid],
							minDisplay: '1',
							maxDisplay: maxGrid,
							defaultValue: 1
						},
						{
							name: 'padding',
							description: 'Padding: %value%',
							type: 'inc/dec',
							range: [2, 20],
							defaultValue: 2,
							inc: 2,
							displayMultiplier: 0.5
						}
					],
					onChange: function(selection, value, currentSettings) {
						this.splitIntoParts(currentSettings.columns || 1, currentSettings.rows || 1, currentSettings.padding || 2);
					}
				}
			case 'dynamic graphic':
				return {
					icon: 'th list',
					popup: 'Edit dynamic mappings',
					closeOnClick: true,
					onClick: function() {
						this.editDynamicGraphics();
					}
				};
			case 'edit dimensions':
				return {
					icon: 'pencil',
					popup: 'Edit position and size',
					closeOnClick: true,
					onClick: function() {
						this.editDimensions();
					}
				};
			default:
				return name;
		}
	},
	toolSettingProperties: {
		blur: {
			icon: 'low vision',
			popup: 'Blur',
			settingsPicker: {
				description: '%value%% Blur',
				type: 'inc/dec',
				range: [0, 10],
				inc: 0.5,
				minDisplay: 'No',
				displayMultiplier: 10
			},
			defaultValue: 0
		},
		opacity: {
			icon: 'eye',
			popup: 'Opacity',
			settingsPicker: {
				description: '%value%% Opacity',
				type: 'inc/dec',
				range: [5, 100],
				inc: 5
			},
			defaultValue: 100
		},
		saturate: {
			icon: 'spinner',
			popup: 'Saturation',
			settingsPicker: {
				description: '%value%% Saturation',
				type: 'inc/dec',
				range: [5, 200],
				inc: 5
			},
			defaultValue: 100
		},
		contrast: {
			icon: 'adjust',
			popup: 'Contrast',
			settingsPicker: {
				description: '%value%% Contrast',
				type: 'inc/dec',
				range: [10, 160],
				inc: 5,
				minDisplay: 'Min'
			},
			defaultValue: 100
		},
		brightness: {
			icon: 'sun',
			popup: 'Brightness',
			settingsPicker: {
				description: '%value%% Brightness',
				type: 'inc/dec',
				range: [10, 200],
				inc: 5,
				minDisplay: 'Min'
			},
			defaultValue: 100
		},
		hue: {
			icon: 'fire lightbulb',
			popup: 'Hue',
			settingsPicker: {
				description: '%value%\u00B0 Hue',
				type: 'inc/dec',
				range: [-180, 180],
				inc: 15
			},
			defaultValue: 0
		}
	},
	applyFilters: function(definition, options) {
		// Dragging a new element passes $(elem) as definition
		if(definition.context && definition.append) {
			return;
		}
		options = $.extend(true, {
			allowOpacity: true
		}, options);

		var outerBorder = '', outerWidth = '', outerHeight = '', outerTransform = [];
		var middleFilter = '', middleWidth = '', middleHeight = '', middlePadding = '';

		var width = parseFloat(this.style.width.replace('px', '').replace('pt', ''));
		var height = parseFloat(this.style.height.replace('px', '').replace('pt', ''));
		var maxSize = Math.max(width, height);

		this.maxExtraBottomRight = 0;
		if(definition.border) {
			var border = definition.border;
			var thickness = border.thickness;
			if(border.sizeScaling) {
				thickness = thickness * maxSize / 80;
			} else if(this.parent && this.parent.ratio) {
				thickness = thickness / 60 * this.parent.ratio;
			}

			if(this.maskedBorder) {
				var chunkThickness = (thickness / border.thickness).toFixed(2);

				switch(border.thickness) {
					case 1:
						middleFilter = $.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 1, border.color);
						break;
					case 2:
						middleFilter = $.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 1, border.color) +
							$.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 1, border.color);
						break;
					case 3:
						middleFilter = $.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 2, border.color) +
							$.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 1, border.color);
						break;
					case 4:
						middleFilter = $.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 2, border.color) +
							$.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 1, border.color) +
							$.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 1, border.color);
						break;
					case 5:
						middleFilter = $.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 2, border.color) +
							$.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 2, border.color) +
							$.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 1, border.color);
						break;
					case 6:
						middleFilter = $.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 2, border.color) +
							$.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 2, border.color) +
							$.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 1, border.color) +
							$.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 1, border.color);
						break;
					default:
						chunkThickness = chunkThickness / 6 * border.thickness;

						middleFilter = $.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 2, border.color) +
							$.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 2, border.color) +
							$.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 1, border.color) +
							$.FlowLayoutFrameUtils.getBorderDropShadow(chunkThickness, 1, border.color);
						break;
				}

				middleWidth = middleHeight = 'calc(100% - ' + (thickness * 2) + 'px)';
				middlePadding = thickness + 'px';
			} else {
				outerBorder = thickness + 'px solid ' + border.color;
				outerWidth = outerHeight = 'calc(100% - ' + (thickness * 2) + 'px)';
			}
		}

		var outerFilter = '';
		if(definition.dropShadow) {
			var dropShadow = definition.dropShadow;

			var depth = dropShadow.depth;
			let intensity = dropShadow.intensity;

			if(dropShadow.sizeScaling) {
				depth = depth * maxSize / 200;
				intensity = intensity * maxSize / 200;
			} else if(this.parent && this.parent.ratio) {
				depth = depth / 60 * this.parent.ratio;
				intensity = intensity / 60 * this.parent.ratio;
			}

			let xDepth = depth;
			let yDepth = depth;
			if(dropShadow.rotation !== null && dropShadow.rotation !== undefined) {
				let rotation = $.convertToRadians(dropShadow.rotation);
				// Math.sin(Math.PI / 4) = 0.7 but we want to multiply by 1 so / by it to get back to 100% size
				xDepth = Math.cos(rotation) * xDepth / Math.sin(Math.PI / 4);
				yDepth = -Math.sin(rotation) * yDepth / Math.sin(Math.PI / 4);
			}

			outerFilter += 'drop-shadow(' + xDepth + 'px ' + yDepth + 'px ' + intensity + 'px ' + dropShadow.color + ') ';
			this.maxExtraBottomRight = Math.max(this.maxExtraBottomRight, depth);
		}

		if (definition.flipHorizontal) {
			outerTransform.push('scaleX(-1)');
		}
		if (definition.flipVertical) {
			outerTransform.push('scaleY(-1)');
		}
		if(definition.transformEffects) {
			outerTransform.push(definition.transformEffects);
		}

		let innerFilter = '';
		if(definition.blur) {
			var blur = definition.blur;
			if(this.parent && this.parent.ratio) {
				blur = blur / 70 * this.parent.ratio;
			}

			innerFilter += 'blur(' + blur + 'px) ';
		}
		if(definition.brightness) {
			innerFilter += 'brightness(' + (definition.brightness  / 100) + ') ';
		}
		if(definition.contrast !== undefined && definition.contrast !== null && definition.contrast != 100) {
			innerFilter += 'contrast(' + definition.contrast + '%) ';
		}
		if(definition.grayscale) {
			innerFilter += 'grayscale(100%) ';
		}
		if(definition.hue) {
			innerFilter += 'hue-rotate(' + definition.hue + 'deg) ';
		}
		if(definition.invert) {
			innerFilter += 'invert(100%) ';
		}
		if(definition.opacity && definition.opacity != 100 && options.allowOpacity) {
			innerFilter += 'opacity(' + definition.opacity + '%) ';
		}
		if(definition.saturate && definition.saturate != 100) {
			innerFilter += 'saturate(' + definition.saturate + '%) ';
		}
		if(definition.sepia) {
			innerFilter += 'sepia(' + definition.sepia + '%) ';
		}

		var outerWrapper = $(this).find('.imageOuterStyleWrapper');
		outerWrapper.css({
			'filter': outerFilter,
			'-webkit-filter': outerFilter,
			'transform': outerTransform.join(' ')
		});
		if(this.maskedBorder) {
			$(this).find('.imageMiddleStyleWrapper').css({
				'filter': middleFilter,
				'-webkit-filter': middleFilter,
				width: middleWidth,
				height: middleHeight,
				padding: middlePadding
			});
		} else {
			outerWrapper.css({
				border: outerBorder,
				width: outerWidth,
				height: outerHeight
			});
		}

		let innerWrapper = $(this).find('.imageInnerStyleWrapper');
		innerWrapper.css({
			'filter': innerFilter,
			'-webkit-filter': innerFilter
		})
	},
	getBorderDropShadow: function(base, multiplier, color) {
		var chunkThickness = Math.max(1, base * multiplier);
		return 'drop-shadow(' + chunkThickness + 'px 0px 0 ' + color + ') drop-shadow(0px ' + chunkThickness + 'px 0 ' + color + ') drop-shadow(-' + chunkThickness + 'px 0px 0 ' + color + ') drop-shadow(0px -' + chunkThickness + 'px 0 ' + color + ')';
	},
	addRoundedCornerMask: function(mask) {
		var roundedId = 'Mask-1';
		if($.GlobalRoundedId) {
			roundedId = $.GlobalRoundedId;
		}

		if(mask['clip-path'] && mask['clip-path'].indexOf(roundedId + '-') != -1) {
			var maskId = mask['clip-path'].replace(/"/ig, '').replace('url(#', '').replace(')', '');
			var aspectRatio = parseFloat(maskId.replace(roundedId + '-', '').replace('_', '.'));

			$.FlowLayoutFrameUtils.addRoundedCornerMaskForRatio(maskId, aspectRatio);
		}
	},
	addRoundedCornerMaskForRatio: function(maskId, aspectRatio) {
		// Create new mask if not exists
		if(!$('#' + maskId).length) {
			var rx = 0.1;
			var ry = 0.1;
			if(aspectRatio > 1) {
				rx = rx / aspectRatio;
			} else if(aspectRatio < 1) {
				ry = ry * aspectRatio;
			}

			var newClipPath = '<clipPath id="' + maskId + '" clipPathUnits="objectBoundingBox"><rect x="0" y="0" width="1" height="1" rx="' + rx.toFixed(3) + 'px" ry="' + ry.toFixed(3) + 'px"/></clipPath>';
			$.FlowLayoutFrameUtils.appendToGlobalSVGDefinition(newClipPath);
		}
	},
	appendToGlobalSVGDefinition: function(defs) {
		if(!$.isArray(defs)) {
			defs = [defs];
		} else if(defs.length === 0) {
			return;
		}
	
		var svg, globalSvg = $('#globalMaskDefinitions');
		if(globalSvg.length) {
			svg = globalSvg[0].outerHTML;
	
			// Curse you IE!
			if(!svg) {
				svg = $('<div>').append(globalSvg.clone())[0].innerHTML;
			}
		} else {
			// Do NOT add display: none as it breaks Alpha Masks in FF
			svg = '<svg width="0" height="0" id="globalMaskDefinitions"><defs></defs></svg>';
		}
	
		var endDefIndex = svg.indexOf('</defs>');
		var startSvg = svg.substr(0, endDefIndex);
		var endSvg = svg.substr(endDefIndex);
	
		defs.forEach(function(def) {
			startSvg += def;

			if(def.indexOf('<rect x="0" y="0" width="1" height="1" ry=".15px" rx=".2px"/>') !== -1 && !$.GlobalRoundedId) {
				var quotedStrings = def.match(/".*?"/);
				if(quotedStrings && quotedStrings.length) {
					$.GlobalRoundedId = quotedStrings[0].replace(/"/g, '');
				}
			}
		});
		svg = startSvg + endSvg;
	
		if(globalSvg.length) {
			globalSvg.replaceWith(svg);
		} else {
			$('body').append(svg);
		}
	},
	checkIfFieldMapMatches: function(getDynamicFieldRep, fieldMap) {
		for(var name in fieldMap) {
			var value = fieldMap[name];

			// Check if simple match
			var repValue = getDynamicFieldRep(name);
			if(!repValue && name.toTitleCase && name !== name.toTitleCase()) {
				repValue = getDynamicFieldRep(name.toTitleCase());
			}

			// Normally we only have one value that we are checking.  If we get an array (like package skus), then we should only require one to match
			var repValues = $.isArray(repValue) ? repValue : [repValue];
			var repMatches = repValues.filter(function(repValue) {
				return $.FlowLayoutFrameUtils.checkIfFieldRepMatchesMap(name, repValue, value);
			});
			if(repMatches.length === 0) {
				return false;
			}
		}

		return true;
	},
	checkIfFieldRepMatchesMap: function(name, repValue, value) {
		if(repValue === null || repValue === undefined) {
			repValue = '';
		} else if(typeof repValue !== 'string') {
			repValue = repValue + '';
		}
		if(/(order packages|order package skus)/i.test(name)) {
			if(/\(\d{1,2}\) /.test(repValue) && !/\(\d{1,2}\)/.test(value)) {
				repValue = repValue.replace(/\(\d{1,2}\)/, '');
			}
		}
		repValue = repValue.toLowerCase().trim();
		value = value.toLowerCase().trim();

		if(!repValue || !value || repValue !== value) {
			// Check if we are doing Grade: 09 -> 9.jpg or 9 -> 09.jpg
			if(/grade/i.test(name) && repValue === ('0' + value) || ('0' + repValue) === value) {
				return true;
			}

			return false;
		}

		return true;
	},
	getDynamicFieldCrop: function(instance, photoWidth, photoHeight, dynamicCrop) {
		let imgCrop = {
			width: '',
			height: '',
			top: '',
			left: ''
		};

		// If image has it's own raw crop, take it into account when trying to fit in image box
		var cropWidth = 1;
		var cropHeight = 1;
		var cropLeft = 0;
		var cropTop = 0;
		if(dynamicCrop) {
			photoWidth *= dynamicCrop.width;
			photoHeight *= dynamicCrop.height;

			cropWidth = (1 / dynamicCrop.width);
			cropHeight = (1 / dynamicCrop.height);
			imgCrop.width = cropWidth * 100 + '%';
			imgCrop.height = cropHeight * 100 + '%';

			cropLeft = -(dynamicCrop.x * cropWidth);
			imgCrop.left = cropLeft * 100 + '%';
			cropTop = -(dynamicCrop.y * cropHeight);
			imgCrop.top = cropTop * 100 + '%';
		}

		var frameAspectRatio = instance.width / instance.height;
		var nativeAspectRatio = photoWidth / photoHeight;

		var verticalAnchor = instance.verticalCropAnchor || 'start';
		var horizontalAnchor = instance.horizontalCropAnchor || 'center';

		// Roughly equal
		// eslint-disable-next-line no-empty
		if(Math.abs(frameAspectRatio - nativeAspectRatio) <= 0.01) {
			
		}
		// Too tall
		else if(nativeAspectRatio > frameAspectRatio) {
			var widthResize = (nativeAspectRatio / frameAspectRatio) * cropWidth;

			var leftAdjust;
			if(dynamicCrop) {
				var newCropWidth = 1 / widthResize;
				let incrementCropX = (dynamicCrop.width - newCropWidth) / 2;
				var newCropX = parseFloat(dynamicCrop.x) + incrementCropX;
				// Center
				leftAdjust = newCropX * widthResize;
				// Left
				cropLeft = -(dynamicCrop.x * widthResize);
			} else {
				// Is this accurate, or should we be using the improved version for crops?
				leftAdjust = (widthResize - 1) / 2;
			}

			var left = '';
			if(horizontalAnchor === 'center') {
				left = -(leftAdjust * 100) + '%';
			} else if(horizontalAnchor === 'end') {
				left = -(leftAdjust * 100 * 2) + '%';
			} else if(cropLeft) {
				left = (cropLeft * 100) + '%';
			}

			imgCrop.width = (widthResize * 100) + '%';
			imgCrop.left = left;
		}
		// Too wide
		else {
			var heightResize = (frameAspectRatio / nativeAspectRatio) * cropHeight;

			var topAdjust;
			if(dynamicCrop) {
				var newCropHeight = 1 / heightResize;
				let incrementCropY = (dynamicCrop.height - newCropHeight) / 2;
				var newCropY = parseFloat(dynamicCrop.y) + incrementCropY;
				// Center
				topAdjust = newCropY * heightResize;
				// Top
				cropTop = -(dynamicCrop.y * heightResize);
			} else {
				// Is this accurate, or should we be using the improved version for crops?
				topAdjust = (heightResize - 1) / 2;
			}

			var top = '';
			if(verticalAnchor === 'center') {
				top = -(topAdjust * 100) + '%';
			} else if(verticalAnchor === 'end') {
				top = -(topAdjust * 100 * 2) + '%';
			} else if(cropTop) {
				top = (cropTop * 100) + '%';
			}

			imgCrop.height = (heightResize * 100) + '%';
			imgCrop.top = top;
		}

		return imgCrop;
	},
	getSubjectPose: function(instance, subject) {
		if(!subject.photos) {
			return null;
		}

		var subjectPose = subject.yearbookPhoto;

		if(instance.poseSelection == 'yearbook pose') {
			if(subject.poseFlags.yearbook) {
				subjectPose = subject.photos.filter(function(photo) {
					return photo.id == subject.poseFlags.yearbook;
				})[0];
			}
		} else if(instance.poseSelection && instance.poseSelection.indexOf('pose ') === 0) {
			let poseNumber = parseInt(instance.poseSelection.replace('pose ', ''));
			subjectPose = subject.photos.filter(function(photo) {
				return !photo.hidden && photo.category !== 'group_category';
			})[poseNumber - 1];
		} else if(instance.poseSelection && instance.poseSelection.indexOf('group photo') === 0) {
			let poseNumber = parseInt(instance.poseSelection.replace('group photo ', ''));
			subjectPose = subject.photos.filter(function(photo) {
				return photo.category === 'group_category';
			})[poseNumber - 1];
		} else if(instance.poseSelection && (instance.poseSelection.indexOf('ordered photo') === 0 || instance.poseSelection.indexOf('ordered product') === 0)) {
			if(!subject.orders || !subject.orders.length) {
				return null;
			}

			let poseNumber = parseInt(instance.poseSelection.replace('ordered photo ', '').replace('ordered product ', ''));
			let orderedPhotos = this.getOrderedPhotos(subject);
			let orderedPhotosWithPhotos = orderedPhotos.filter(function(orderedPhoto) {
				return !!orderedPhoto.photo_id;
			});
			let orderedPhoto = orderedPhotosWithPhotos[poseNumber - 1];
			if(orderedPhoto) {
				var photoId;
				if(instance.poseSelection.indexOf('ordered photo') === 0) {
					photoId = orderedPhoto.source_photo_id || orderedPhoto.photo_id;
				} else {
					photoId = orderedPhoto.photo_id;
				}
				var subjectPhoto = subject.photos.filter(function(photo) {
					return photo.id == photoId;
				})[0];

				if(subjectPhoto) {
					subjectPhoto = $.extend(true, {
						background_photo_id: orderedPhoto.background_photo_id,
						background_photo: orderedPhoto.background_photo
					}, subjectPhoto);
				} else if(photoId === orderedPhoto.photo_id) {
					subjectPhoto = {
						id: orderedPhoto.photo_id,
						upload_file_name: orderedPhoto.original_file_name,
						background_photo_id: orderedPhoto.background_photo_id,
						background_photo: orderedPhoto.background_photo
					};
				} else {
					subjectPhoto = {
						id: photoId,
						background_photo_id: orderedPhoto.background_photo_id,
						background_photo: orderedPhoto.background_photo
					};
				}

				return subjectPhoto;
			} else if(poseNumber === 1) {
				var firstBackgroundPhoto = orderedPhotos.find(function(orderedPhoto) {
					return !!orderedPhoto.background_photo_id;
				});
				if(firstBackgroundPhoto) {
					return $.extend(true, {
						background_photo_id: firstBackgroundPhoto.background_photo_id,
						background_photo: firstBackgroundPhoto.background_photo
					}, subjectPose);
				} else {
					return subjectPose;
				}
			} else {
				return null;
			}
		}

		if(subject.orders && subjectPose && instance.useGreenScreenBackground === 'ordered background') {
			let orderedPhotos = this.getOrderedPhotos(subject);
			let orderedPhoto = orderedPhotos.find(function(photo) {
				return photo.photo_id == subjectPose.id;
			}) || orderedPhotos.find(function(photo) {
				return !!photo.background_photo_id;
			});

			if(orderedPhoto && orderedPhoto.background_photo_id) {
				subjectPose = $.extend(true, {
					background_photo_id: orderedPhoto.background_photo_id,
					background_photo: orderedPhoto.background_photo
				}, subjectPose);
			}
		}

		return subjectPose;
	},
	getOrderedPhotos: function(subject) {
		if(!subject.orders || !subject.orders.length) {
			return [];
		}

		var order = subject.orders[0];
		var orderedProducts = order.ordered_packages.reduce(function(orderedProducts, orderedPackage) {
			return $.merge(orderedProducts, orderedPackage.ordered_products);
		}, []);
		if($.OrderedProductId) {
			orderedProducts = orderedProducts.filter(function(orderedProduct) {
				return orderedProduct.id === $.OrderedProductId;
			});
		}

		return orderedProducts.reduce(function (orderedPhotos, orderedProduct) {
			var orderedProductPhotos = (orderedProduct.ordered_photos || []);

			return $.merge(orderedPhotos, orderedProductPhotos);
		}, []);
	}
};

$.appendToGlobalSVGDefinition = function(defs) {
	$.FlowLayoutFrameUtils.appendToGlobalSVGDefinition(defs);
};