$.registerEvents = function(obj, eventNames) {
	eventNames.forEach(function(eventName) {
		$.registerEvent(obj, eventName);
	});
};
$.registerEvent = function(obj, eventName) {
	var handlersName = '__' + eventName + '_handlers';
	obj[handlersName] = [];

	var titleCaseEventName = eventName.charAt(0).toUpperCase() + eventName.slice(1);
	// Can use registerOnFocusChanged or registerFocusChangedHandler
	obj['register' + titleCaseEventName + 'Hander'] = obj['registerOn' + titleCaseEventName] = function(event) {
		obj[handlersName].push(event);
	};
	obj['on' + titleCaseEventName] = function() {
		var args = arguments;
		obj[handlersName].forEach(function(event) {
			event.apply(obj, args);
		});
	};
};