$.MAX_PAGE_MARGIN = 1.0;

$.FlowLayoutMargins = function(options) {
	var div = document.createElement('div');

	$.extend(div, {
		setRatio: function(ratio) {
			this.ratio = ratio;
		},
		setMaxMargins: function(maxMargins) {
			this.maxInchMargins = maxMargins;
		},
		setMargins: function(margins) {
			this.margins = $.extend(true, {}, margins);

			// Migrate old margins logic to new method
			if(this.margins.horizontal) {
				this.margins.left = this.margins.horizontal;
				this.margins.right = this.margins.horizontal;
				delete this.margins.horizontal;
			}

			if(this.margins.vertical) {
				this.margins.top = this.margins.vertical;
				this.margins.bottom = this.margins.vertical;
				delete this.margins.vertical;
			}

			// Go off side when we see gutter/outside
			if($.isInit(this.margins.gutter)) {
				if(this.side == 'left') {
					this.margins.right = this.margins.gutter;
				} else if(this.side == 'right') {
					this.margins.left = this.margins.gutter;
				}

				delete this.margins.gutter;
			}
			if($.isInit(this.margins.outside)) {
				if(this.side == 'left') {
					this.margins.left = this.margins.outside;
				} else if(this.side == 'right') {
					this.margins.right = this.margins.outside;
				}

				delete this.margins.outside;
			}

			this.updateMargins();
		},
		updateMargins: function(save) {
			this.updateMarginsDisplay();
			this.updateHandlerPopups();

			if(save) {
				if(this.onChangeMargins) {
					var margins = $.extend(true, {}, this.margins);
					if(this.saveGutter) {
						if(this.side == 'left') {
							margins.outside = margins.left || 0;
							margins.gutter = margins.right || 0;
						} else if(this.side == 'right') {
							margins.outside = margins.right || 0;
							margins.gutter = margins.left || 0;
						}

						delete margins.left;
						delete margins.right;
					}

					this.onChangeMargins(margins);
				}
			}
		},
		updateMarginsDisplay: function() {
			if(!this.ratio) {
				$.fireErrorReport(null, 'Margins missing ratio', null, {
					throwException: 'Margins missing ratio'
				});
			}

			var css = {
				width: '',
				height: '',
				marginLeft: '',
				marginRight: '',
				marginTop: '',
				marginBottom: ''
			};

			if (this.margins) {
				// Get css for this margin object
				var borderWidth = this.getBorderWidth() * 2;

				var subtractWidth = 0;
				var subtractHeight = 0;
				if($.isInit(this.margins.left)) {
					var left = this.margins.left * this.ratio;
					subtractWidth += left;
					css.marginLeft = left + 'px';
				}
				if($.isInit(this.margins.right)) {
					var right = this.margins.right * this.ratio;
					subtractWidth += right;
					css.marginRight = right + 'px';
				}

				if($.isInit(this.margins.top)) {
					var top = this.margins.top * this.ratio;
					subtractHeight += top;
					css.marginTop = top + 'px';
				}
				if($.isInit(this.margins.bottom)) {
					var bottom = this.margins.bottom * this.ratio;
					subtractHeight += bottom;
					css.marginBottom = bottom + 'px';
				}

				if(subtractWidth) {
					css.width = 'calc(100% - ' + (subtractWidth + borderWidth) + 'px)';
				}
				if(subtractHeight) {
					css.height = 'calc(100% - ' + (subtractHeight + borderWidth) + 'px)';
				}
			}

			$(this).css(css);
		},
		getBorderWidth: function() {
			return parseFloat(window.getComputedStyle(this).borderRightWidth.replace('px', ''));
		},
		setEditable: function(editable) {
			if(this.editable == editable) {
				return;
			}

			this.editable = editable;

			if(editable) {
				this.setupHandlers();
			} else {
				$(this).find('.pageMarginHandles').each(function() {
					if($(this).hasClass('hasPopup')) {
						// Popup isn't actually being cleaned up here without us doing it manually
						let html = $(this).popup('get popup');
						if(html) {
							$(html).remove();
						}
						$(this).popup('destroy');
						$(this).removeClass('hasPopup');
					}
				});
				$(this).find('.pageMarginHandles').remove();
			}
		},
		setupHandlers: function() {
			this.setupHandler('left');
			this.setupHandler('right');
			this.setupHandler('top');
			this.setupHandler('bottom');
		},
		setupHandler: function(side) {
			var direction;
			if(side == 'left' || side == 'right') {
				direction = 'vertical';
			} else {
				direction = 'horizontal';
			}

			var handler = $('<div class="pageMarginHandles ' + direction + 'MarginHandles ' + side + 'MarginHandle">').appendTo(this);
			this.setupHandlerDraggable(handler, side);
			this.setupHandlerPopup(handler, side);
		},
		setupHandlerDraggable: function(handler, side) {
			var axis;
			if(side == 'left' || side == 'right') {
				axis = 'x';
			} else {
				axis = 'y';
			}
			var mouseProp = 'page' + axis.toUpperCase();
			var marginSide = 'margin' + side.toTitleCase();

			var oppositeSide, popupIncrement = 'plus', currentMarginIncrement = 'plus';
			if(side == 'left') {
				oppositeSide = 'right';
				popupIncrement = 'minus';
			} else if(side == 'right') {
				oppositeSide = 'left';
				currentMarginIncrement = 'minus'
			} else if(side == 'top') {
				oppositeSide = 'bottom';
				popupIncrement = 'minus';
			} else if(side == 'bottom') {
				oppositeSide = 'top';
				currentMarginIncrement = 'minus';
			}

			handler.draggable({
				axis: axis,
				start: function (e) {

					$(this).data('startPosition', $(this).position());
					$(this).data('startMouse', e[mouseProp]);
					$(this).data('dragging', true);
					if ($(this).popup('is hidden')) {
						$(this).popup('show');
					}
				},
				drag: function (e, ui) {
					var startPosition = $(this).data('startPosition');

					// Changes the margins on canvasMargins to match
					var diff = e[mouseProp] - $(this).data('startMouse');
					if (diff != 0) {
						var currentMargin = $(div).getFloatStyle(marginSide);
						if (currentMarginIncrement == 'plus') {
							currentMargin += diff;
						} else {
							currentMargin -= diff;
						}
						currentMargin = Math.max(0, currentMargin);
						currentMargin = Math.min(currentMargin, div.maxInchMargins * div.ratio);
						ui.position.left = startPosition.left + diff;

						var newMargins = div.margins;
						newMargins[side] = currentMargin / div.ratio;
						div.updateMargins(true);

						$(this).data('startPosition', $(this).position());
						$(this).data('startMouse', e[mouseProp]);

						if ($(this).popup('is visible')) {
							var popup = $(this).data('popupElem');
							if (popup) {
								var popupPosition = popup.getFloatStyle(oppositeSide);
								if(popupIncrement == 'plus') {
									popupPosition += diff;
								} else {
									popupPosition -= diff;
								}
								popup.css(oppositeSide, popupPosition + 'px');
							}
						}
					}
				},
				stop: function () {
					var currentMargin = $(div).getFloatStyle(marginSide);
					// We can't drag down to below 3 due to width, so if at 3 user was probably trying to remove it altogether
					if (currentMargin < 3) {
						var newMargins = div.margins;
						newMargins[side] = 0;

						div.updateMargins(true);
					}

					this.style = null;
					$(this).data('dragging', false);
					if ($(this).data('popupUnfocused')) {
						$(this).popup('hide');
						$(this).data('popupUnfocused', false);
					}

					if(div.wrapper && div.wrapper.onFlowStop) {
						div.wrapper.onFlowStop();
					}
				},
				containment: $(this.container)
			});
		},
		setupHandlerPopup: function(handler, side) {
			var popup = {
				preserve: true,
				on: 'manual',
				onShow: function (elem) {
					$(elem).data('popupElem', this);
				},
				// Popup thinks this is being removed and auto destroys the margins
				observeChanges: false
			};
			if(side == 'left' || side == 'right') {
				popup.html = $('<span class="' + this.popupPrefix + 'marginHorizontalPopups">');
			} else {
				popup.html = $('<span class="' + this.popupPrefix + 'marginVerticalPopups">');
			}

			if(side == 'left') {
				popup.position = 'left center';
			} else if(side == 'right') {
				popup.position = 'right center';
			} else if(side == 'top') {
				popup.position = 'top center';
			} else if(side == 'bottom') {
				popup.position = 'bottom center';
			}

			handler.popup(popup).addClass('hasPopup').hover(function () {
				if ($(this).popup('is hidden')) {
					$(this).popup('show');
				}
			}, function () {
				if (!$(this).data('dragging')) {
					$(this).popup('hide');
				} else {
					$(this).data('popupUnfocused', true);
				}
			});
		},
		updateHandlerPopups: function () {
			var left = 0, right = 0, top = 0, bottom = 0;
			if(this.margins) {
				if(this.margins.left > 0) {
					left = this.margins.left;
				}
				if(this.margins.right > 0) {
					right = this.margins.right;
				}
				if(this.margins.top > 0) {
					top = this.margins.top;
				}
				if(this.margins.bottom > 0) {
					bottom = this.margins.bottom;
				}
			}

			$('.' + this.popupPrefix + 'marginHorizontalPopups').text('Horizontal Margins: ' + $.getDisplayUnit(left) + ' x ' + $.getDisplayUnit(right) + '');
			$('.' + this.popupPrefix + 'marginVerticalPopups').text('Vertical Margins: ' + $.getDisplayUnit(top) + ' x ' + $.getDisplayUnit(bottom) + '');
		},

		destroy: function() {
			$(this).find('.pageMarginHandles').each(function() {
				if($(this).hasClass('hasPopup')) {
					// Popup isn't actually being cleaned up here without us doing it manually
					let html = $(this).popup('get popup');
					if(html) {
						$(html).remove();
					}
					$(this).popup('destroy');
					$(this).removeClass('hasPopup');
				}
			});
		},

		maxInchMargins: $.MAX_PAGE_MARGIN,
		popupPrefix: 'general',
		editable: false,
		side: 'left'
	}, options);

	if(options.addClass) {
		div.className = options.addClass;
	}
	if(options.appendTo) {
		$(options.appendTo).append(div);

		if(options.appendTo.ratio) {
			div.ratio = options.appendTo.ratio;
		}
	}

	return div;
};