$.BookTableOfContents = function(startSettings) {
	var page = new $.BookCandidPage();
	var _updateTextThemeStyles = page.updateTextThemeStyles;

	$.extend(page, {
		setOverflowChapters: function(pages) {
			if(pages.length) {
				if(!this.overflowPage) {
					this.overflowPage = new $.BookTableOfContentsOverflow(this);
					this.pageSet.addPage(this.overflowPage, this.pageNumber - 1);
				}

				this.overflowPage.setChapters(pages);
			} else if(this.overflowPage) {
				this.pageSet.removePage(this.overflowPage);
				this.overflowPage = null;
			}
		},
		getLockedTexts: function() {
			// Background renderer!
			if(this.pageSet.pages.length === 1 && this.pageText) {
				return this.pageText;
			}

			var chapters = [];
			
			var textMargins = 0.5;
			var startTextTop = this.getTitleInchHeight();
			var textTop = startTextTop;

			var labelStyle = this.getLabelStyle();
			var fontSize = 18;
			if(labelStyle && labelStyle['font-size']) {
				fontSize = parseFloat(labelStyle['font-size'].replace('pt', ''));
			} else {
				labelStyle['font-size'] = fontSize + 'pt';
			}

			var requiredHeight = fontSize / 72 * 1.5;

			var layoutDimensions = this.pageSet.getInnerDimensions();
			var chapterTextWidth = layoutDimensions.width - (textMargins * 2) - 1;
			var averageLetterLength = this.getAverageLetterLength(labelStyle.fontFamily || 'open sans', fontSize);
			var maxLetters = Math.floor(chapterTextWidth / averageLetterLength);

			var pageNumberFontSize = this.pageSet.getPageNumberFontSize();
			var pageNumberPadding = this.pageSet.getPageNumberPadding();
			var pageNumberHeight = (pageNumberFontSize / 72 * 1.33) + (pageNumberPadding * 2);
			var pageBottom = layoutDimensions.height - pageNumberHeight - 0.125;

			var pages = [];
			var me = this;
			this.pageSet.pages.forEach(function(page) {
				if(page.type === 'table-of-contents') {
					return;
				}

				var title = page.getTitleText();
				if(title) {
					var lines = me.splitTextByMaxCharacters(title, maxLetters);
					var lineHeight = requiredHeight * lines.length;
					if((textTop + lineHeight) >= pageBottom) {
						pages.push(chapters);
						chapters = [];
						textTop = startTextTop;
					}

					chapters.push({
						id: page.id + '-label',
						type: 'label',
						position: {
							left: textMargins,
							top: textTop
						},
						lines: lines.map(function(text) {
							return $.extend({
								text: text
							}, labelStyle);
						})
					});

					chapters.push({
						id: page.id + '-page',
						type: 'label',
						position: {
							right: textMargins,
							top: textTop
						},
						lines: $.extend({
							text: (page.getPageNumber() - $.PAGE_OFFSET) + ''
						}, labelStyle)
					});
					textTop += lineHeight;
				}
			});
			pages.push(chapters);

			this.setOverflowChapters(pages.slice(1));
			this.savePageContents(pages[0]);
			return pages[0];
		},
		splitTextByMaxCharacters: function(text, maxCharacters) {
			var lines = [];
			var TAB_CHARACTERS = '      ';

			while(text.length > maxCharacters) {
				var startLength = text.length;
				for(var i = maxCharacters; i >= 0; i--) {
					if(text[i] === ' ') {
						var line = text.substr(0, i);
						if(lines.length) {
							line = TAB_CHARACTERS + line;
						}

						lines.push(line);
						text = text.substr(i + 1);
						break;
					}

					if(i === 0) {
						lines.push(text.substr(0, maxCharacters));
						text = text.substr(maxCharacters);
					}
				}

				if(text.length === startLength) {
					break;
				}
			}

			if(lines.length) {
				text = TAB_CHARACTERS + text;
			}
			lines.push(text);

			return lines;
		},
		getAverageLetterLength: function(fontFamily, fontSizePt) {
			var testText = 'Test this out with some real textg';
			var fontSizePx = $.convertToPx(fontSizePt, true);

			var div = document.createElement('span');
			div.className = 'preLoadFonts';
			div.innerHTML = testText;
			div.style.fontFamily = '"' + fontFamily + '"';
			div.style.fontSize = fontSizePx + 'px';
			$('body').append(div);

			var width = div.getBoundingClientRect().width;
			var averageLengthPx = width / testText.length;
			$(div).remove();

			var averageLength = averageLengthPx / $.PRODUCTION_RATIO;
			// console.log('averageLength: ' + averageLength + ' (' + document.fonts.check(fontSizePx + 'px ' + fontFamily) + ')');
			return averageLength;
		},

		getLockedOptions: function(type) {
			if(type == 'label') {
				return [
					'font-family',
					'font-size',
					'text-colors'
				];
			} else {
				return [
					'font-family',
					'text-colors'
				];
			}
		},
		getLockedSettings: function() {
			return {
				defaultFontSizes: [12, 14, 16, 18, 20, 22, 24, 28]
			};
		},
		setLockedFontStyle: function(instance, name, value) {
			this.setLabelStyle(name, value);
			if(name === 'font-size' || name === 'fontFamily') {
				return 'forceRefresh';
			} else {
				return 'label';
			}
		},
		setLabelStyle: function(name, value) {
			this.jsonSubPropertyChange('extras', 'labelStyle', name, value);
		},
		getLabelStyle: function() {
			var labelStyle = this.getExtraProperty('labelStyle');
			return $.extend({}, labelStyle);
		},
		refreshLockedTextsOnMove: function() {
			return true;
		},

		savePageContents: function(texts) {
			if(!this.pageText || !$.arrayEquals(texts, this.pageText, null, true)) {
				// Want to save a pristine copy before actual display starts changing stuff
				this.jsonReplace('pageText', texts.map(function(text) {
					return $.extend(true, {}, text);
				}));
			}
		},

		updateTextThemeStyles: function(oldStyles, newStyles) {
			_updateTextThemeStyles.apply(this, arguments);

			if(oldStyles.randomText || newStyles.randomText) {
				var newStyle = this.updateTextObjectThemeStyles(this.extras.labelStyle || {}, oldStyles.randomText, newStyles.randomText);
				if(newStyle) {
					this.setExtraProperty('labelStyle', newStyle);
				}
			}
		},

		type: 'table-of-contents'
	}, startSettings);
	$.FlowPageOverflowRootModule(page);

	return page;
};