$.FlowLayoutGridLines = function(options) {
	var div = document.createElement('div');
	div.className = 'flowLayoutGridLines';

	$.extend(div, {
		setLineDistance: function(unit, quantity) {
			this.unit = unit;
			this.quantity = quantity;
		},
		refreshLines: function() {
			if(this.enabled) {
				this.clear();
				this.displayLines();
			}
		},
		displayLines: function() {
			var outerWidth = this.flowLayout.inchWidth;
			var outerHeight = this.flowLayout.inchHeight;
			var bleed = this.flowLayout.inchBleed;
			var margins = this.flowLayout.getPageMargins();

			var startLeft = bleed.left;
			var innerWidth = outerWidth - bleed.left - bleed.right;
			if(margins) {
				if(margins.left) {
					startLeft += margins.left;
					innerWidth -= margins.left;
				}
				if(margins.right) {
					innerWidth -= margins.right;
				}

				if($.isInit(margins.outside)) {
					if(this.flowLayout.side == 'Right') {
						innerWidth -= margins.outside;
					} else {
						startLeft += margins.outside;
						innerWidth -= margins.outside;
					}
				}

				if($.isInit(margins.gutter)) {
					if(this.flowLayout.side == 'Right') {
						startLeft += margins.gutter;
						innerWidth -= margins.gutter;
					} else {
						innerWidth -= margins.gutter;
					}
				}

				if(margins.horizontal) {
					startLeft += margins.horizontal;
					innerWidth -= margins.horizontal * 2;
				}
			}

			var startTop = bleed.top;
			var innerHeight = outerHeight - bleed.top - bleed.bottom;
			if(margins) {
				if(margins.top) {
					startTop += margins.top;
					innerHeight -= margins.top;
				}
				if(margins.bottom) {
					innerHeight -= margins.bottom;
				}

				if(margins.vertical) {
					startTop += margins.vertical;
					innerHeight -= margins.vertical * 2;
				}
			}

			var unitWidth = this.unitWidth || this.unitQuantity;
			this.createLines(true, unitWidth, innerWidth, startLeft, startTop, innerHeight);

			var unitHeight = this.unitHeight || this.unitQuantity;
			this.createLines(false, unitHeight, innerHeight, startTop, startLeft, innerWidth);
		},
		getInchQuantity: function(quantity) {
			// We want to convert all units into inches since that is what everything else is using
			if(this.unit == 'cm') {
				return quantity / 2.54;
			} else {
				return quantity;
			}
		},
		createLines: function(vertical, unitSize, mainInchMax, mainStart, otherStart, otherInchMax) {
			var inchSizes;
			if($.isArray(unitSize)) {
				inchSizes = unitSize.map(function(unit) {
					return div.getInchQuantity(unit);
				});
			} else {
				inchSizes = [this.getInchQuantity(unitSize)];
			}
			var totalInches = inchSizes.reduce(function(total, inchSize) {
				return total + inchSize;
			}, 0);
			for(var i = 1; (i * totalInches) < mainInchMax; i++) {
				var setInchSize = 0;
				inchSizes.forEach(function(inchSize, index) {
					if(index > 0) {
						setInchSize += inchSize;
					}
					
					$(div).append(div.createLine(vertical, i * totalInches + mainStart - setInchSize, otherStart, otherInchMax));
				});
			}
		},
		createLine: function(vertical, position, start, size) {
			var line = document.createElement('div');
			line.className = 'flowLayoutGridLine';
			if(this.alignmentTarget) {
				line.className += ' flowAlignTarget';
			}

			position *= this.ratio;
			start *= this.ratio;
			size *= this.ratio;

			var css = {};
			if(vertical) {
				css.left = position;
				css.top = start;
				css.height = size;
				css.borderLeftColor = this.color;

				$(line).addClass('verticalGridLine');
			} else {
				css.top = position;
				css.left = start;
				css.width = size;
				css.borderTopColor = this.color;

				$(line).addClass('horizontalGridLine');
			}

			$(line).css(css);

			return line;
		},
		getLines: function() {
			return $(this).find('.flowLayoutGridLine');
		},
		getVerticalLines: function() {
			return $(this).find('.flowLayoutGridLine.verticalGridLine');
		},
		getHorizontalLines: function() {
			return $(this).find('.flowLayoutGridLine.horizontalGridLine');
		},
		clear: function() {
			this.getLines().remove();
		},
		setRatio: function(ratio) {
			this.ratio = ratio;

			if(this.enabled) {
				this.refreshLines();
			}
		},
		setEnabled: function(enabled) {
			if(enabled === this.enabled) {
				return;
			}

			this.enabled = enabled;
			if(enabled) {
				this.displayLines();
			} else {
				this.clear();
			}
		},
		setSettings: function(settings) {
			if(settings) {
				$.extend(this, settings);

				if(this.enabled) {
					this.refreshLines();
				} else {
					this.clear();
				}
			} else {
				this.setEnabled(false);
			}
		},

		// Overridable
		unit: 'inches',
		unitQuantity: 0.5,
		unitWidth: null,
		unitHeight: null,
		color: 'blue',
		enabled: false,
		ratio: options.flowLayout.ratio,
		alignmentTarget: false
	}, options);

	$(div).appendTo(div.flowLayout);

	return div;
};