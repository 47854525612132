$.BookTableOfContentsOverflow = function(parentPage) {
	var page = new $.BookTableOfContents();

	$.extend(page, {
		setChapters: function(pages) {
			this.savePageContents(pages[0]);

			this.setOverflowChapters(pages.slice(1));
		},
		getLockedTexts: function() {
			return this.pageText;
		},
		getTitle: function() {
			return null
		},
		type: 'table-of-contents-overflow'
	});
	$.FlowPageOverflowModule(page, parentPage);

	return page;
};