$.FlowLayoutSubjectGridEditable = function(options) {
	var div = new $.FlowLayoutSubjectGrid(options);
	$(div).addClass('flowPageCanvasEditable');

	var destroy = div.destroy;
	$.extend(div, {
		setEditable: function(editable) {
			this.editable = editable;

			if(editable) {
				if(!this.hoverEventHandler) {
					$(this).hover(this.hoverEventHandler = function(e) {
						div.hoveredInside = true;
						div.setResizable(true);
					}, this.hoverLeaveEventHandler = function() {
						div.hoveredInside = false;
						if(!this.resizingHandle) {
							$.setSingleTimeout.call(this, 'delayedHoverLeaveEvent', function() {
								div.setResizable(false);
							}, 200);
						}
					});
				}
				
				this.setMovable(true);
			} else {
				if(this.hoverEventHandler) {
					$(this).off('mouseenter', this.hoverEventHandler);
					$(this).off('mousleave', this.hoverLeaveEventHandler);
					this.hoverEventHandler = null;
					this.hoverLeaveEventHandler = null;
				}
				if($(this).hasClass('flowLayoutResizable')) {
					this.setResizable(false);
				}

				this.setMovable(false);
			}
		},
		setMovable: function(movable) {
			if(movable) {
				if(!$(this).hasClass('ui-draggable')) {
					$(this).draggable({
						start: function(e, ui) {
							$(div).find('.flowRow').hide();
						},
						stop: function(event, ui) {
							div.removeAlignmentLines();
							$.setSingleTimeout.call(div, 'delayedResizeEvent', function() {
								this.saveResized();
								this.flowLayout.onFlowChange(this, null, true);
							}, 0);
						},
						drag: function(event, ui) {
							$(this).css({
								left: ui.position.left,
								top: ui.position.top
							});

							div.snapToAlignmentLines(ui.position);
							$(div).css('cursor', '');
							$.setSingleTimeout.call(div, 'delayedResizeEvent', function() {
								this.saveResized(false);
							}, 0);
						}
					});
				}
			} else {
				if($(this).hasClass('ui-draggable')) {
					$(this).draggable('destroy');
				}
			}
		},

		setCustomSize: function(grid, gridIndex) {
			this.grid = grid;
			this.gridIndex = gridIndex;

			if(typeof grid.width === 'string') {
				var percentageWidth = parseInt(grid.width.replace('%', '')) / 100.0;
				var inchWidth = this.flowLayout.inchWidth - this.flowLayout.inchBleed.left - this.flowLayout.inchBleed.right - (this.flowLayout.canvasMargins.margins.left || 0) - (this.flowLayout.canvasMargins.margins.right || 0);
				grid.width = inchWidth * percentageWidth;
			}
			if(typeof grid.height === 'string') {
				var percentageHeight = parseInt(grid.height.replace('%', '')) / 100.0;
				var inchHeight = this.flowLayout.inchHeight - this.flowLayout.inchBleed.top - this.flowLayout.inchBleed.bottom - (this.flowLayout.canvasMargins.margins.top || 0) - (this.flowLayout.canvasMargins.margins.bottom || 0);
				grid.height = inchHeight * percentageHeight;
			}

			$(this).css({
				position: 'absolute',
				left: ((grid.left * this.ratio) - 1) + 'px',
				top: ((grid.top * this.ratio) - 1) + 'px',
				width: ((grid.width * this.ratio)) + 'px',
				height: ((grid.height * this.ratio)) + 'px'
			});

			$(this).find('.flowRow').show();
		},
		getPixelDimensions: function() {
			return {
				width: $(this).getFloatStyle('width'),
				height: $(this).getFloatStyle('height')
			};
		},
		saveResized: function(updateDisplay) {
			var page = this.flowLayout.getPage();
			var oldLayout = page.getLayout();
			var layout = $.extend(true, {}, oldLayout);
			var grid = $.extend(true, {}, this.grid, {
				left: ($(this).getFloatStyle('left') + 1) / this.ratio,
				top: ($(this).getFloatStyle('top') + 1) / this.ratio,
				width: $(this).getFloatStyle('width') / this.ratio,
				height: $(this).getFloatStyle('height') / this.ratio
			});

			if(!$.objectEquals(layout.separateGrids[this.gridIndex], grid, ['studentLabelCSS'])) {
				layout.separateGrids[this.gridIndex] = grid;
				page.setLayout(layout);

				if($.userEvents) {
					$.userEvents.addEvent({
						context: [page, 'layout'],
						action: 'update',
						args: [oldLayout, layout]
					});
				}
			}

			if(updateDisplay !== false) {
				this.flowLayout.addUsers();
			}
		},
		saveCurrentPosition: function() {
			$.setSingleTimeout.call(this, 'delayedResizeEvent', function() {
				this.saveResized();
			}, 50);
		},
		getSecondaryFocusedElements: function() {
			return [];
		},
		removeAlignmentLines: function() {

		},

		hideOverlap: function(mainCells) {
			var original = $();
			if(this.hiddenCells) {
				original = this.hiddenCells;
			}

			var page = this.flowLayout.getPage();
			var contentOverlapSubjectLabels = !page || page.doesContentOverlapSubjectLabels();

			this.hiddenCells = $(this).overlaps(mainCells, {
				includeBaseLabelHeight: !contentOverlapSubjectLabels,
				ratio: this.ratio
			});
			this.hiddenCells.each(function () {
				$(this).data('behindFrames').push(div);
			});
			this.hiddenCells.addClass('hiddenCell behindFrame');

			var diff;
			if(original.length) {
				diff = original.not(this.hiddenCells).get();
				$(diff).each(function () {
					if($(this).hasClass('hiddenCell') && $.contains(document.documentElement, this)) {
						var frames = $(this).data('behindFrames');
						for(var i = 0; i < frames.length; i++) {
							if(frames[i] == div) {
								frames.splice(i, 1);
								i--;
							}
						}

						if(frames.length == 0) {
							$(this).removeClass('hiddenCell behindFrame');
						}
					}
				});
				diff = $.merge(diff, this.hiddenCells.not(original).get());
			}
		},
		removeHidden: function() {
			if(this.hiddenCells) {
				var me = this;
				var length = this.hiddenCells.length;
				this.hiddenCells.each(function () {
					if($(this).hasClass('hiddenCell')) {
						var frames = $(this).data('behindFrames');
						if(!frames) {
							return;
						}

						for(var i = 0; i < frames.length; i++) {
							if(frames[i] == me) {
								frames.splice(i, 1);
								i--;
							}
						}

						if(frames.length == 0) {
							$(this).removeClass('hiddenCell').removeClass('behindFrame');
						}
					}
				});
				this.hiddenCells = null;
				return length;
			} else {
				return 0;
			}
		},

		destroy: function() {
			destroy.apply(this, arguments);

			if(this.hoverEventHandler) {
				$(this).off('mouseenter', this.hoverEventHandler);
				$(this).off('mousleave', this.hoverLeaveEventHandler);
				this.hoverEventHandler = null;
				this.hoverLeaveEventHandler = null;
			}
			if($(this).hasClass('flowLayoutResizable')) {
				this.setResizable(false);
			}
		},

		editable: false
	}, options);
	$.FlowLayoutResizable(div, $.extend(true, {}, options, {
		useScrollWheel: false
	}));
	div.registerOnResize(function(e) {
		$(this).find('.flowRow').hide();

		$.setSingleTimeout.call(this, 'delayedResizeEvent', function() {
			this.saveResized(false);
		}, 50);
	});
	div.registerOnStopResize(function() {
		$.setSingleTimeout.call(this, 'delayedResizeEvent', function() {
			this.saveResized();
			this.flowLayout.onFlowChange(this, null, true);

			if(!this.hoveredInside) {
				this.setResizable(false);
			}
		}, 0);
	});
	$.FlowLayoutAlignmentLines(div);
	$.extend(div, {
		wrapper: div.flowLayout,
		containInParent: true
	});

	if(div.editable) {
		div.setEditable(true);
	}

	return div;
};