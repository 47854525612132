$.getProxyAjax = function(origAjax) {
	// Make sure we don't accidentally do this twice
	if(origAjax.attemptNumber) {
		return origAjax;
	}

	var defaultTimeout = 10000;
	if(origAjax.type == 'DELETE') {
		defaultTimeout = 15000;
	}

	origAjax.attemptNumber = 1;
	var proxyAjax = $.extend({
		timeout: defaultTimeout,
		maxRetries: 3,
		retryTimeouts: (origAjax.type == 'GET' || origAjax.type == 'PATCH' || origAjax.url.indexOf('get') != -1 || origAjax.url.indexOf('cache') != -1)
	}, origAjax, {
		error: function(jqXHR) {
			if((jqXHR.status >= 500 || (proxyAjax.retryTimeouts && jqXHR.statusText == 'timeout')) && origAjax.attemptNumber < proxyAjax.maxRetries && !$.disableAjaxErrorReporting) {
				jqXHR.retryingError = true;
				proxyAjax.retryingError = true;

				window.setTimeout(function() {
					if(proxyAjax.actualSettings) {
						$.ajax(proxyAjax.actualSettings);
					} else {
						$.ajax(proxyAjax);
					}
				}, 250 * origAjax.attemptNumber);
				origAjax.attemptNumber++;
			} else if(origAjax.error) {
				origAjax.error.apply(this, arguments);

				if(jqXHR.responseText && jqXHR.responseText.indexOf('Offline for Maintenance') != -1) {
					window.location.reload();
				}
			}
		}
	});

	return proxyAjax;
};
$.proxyAjax = function(origAjax) {
	return $.ajax($.getProxyAjax(origAjax));
};

const cachedAjaxRequests = {};
const cachedAjaxResponses = {};
$.getCachedAjax = function(origAjax) {
	let key = origAjax.url + '-' + JSON.stringify(origAjax.data);
	if(cachedAjaxResponses[key]) {
		return {
			function: (handlers) => {
				if(origAjax.success) {
					origAjax.success.call(this, cachedAjaxResponses[key]);
				}

				handlers.onComplete();
			}
		};
	} else if(cachedAjaxRequests[key]) {
		let responseHandlers = null;
		let succcessResponse = null;
		let errorResponse = null;
		let responseContext = null;
		cachedAjaxRequests[key].extraRequests.push({
			success: function() {
				// ExecutionChain started this before API request finished
				if(responseHandlers) {
					responseHandlers.onComplete.apply(this, arguments);
				}
				// API request finished before this was actually started
				else {
					succcessResponse = arguments;
					responseContext = this;
				}
			},
			error: function() {
				if(responseHandlers) {
					responseHandlers.onError.apply(this, arguments);
				} else {
					errorResponse = arguments;
					responseContext = this;
				}
			}
		});
		return {
			function: function(handlers) {
				if(succcessResponse) {
					if(origAjax.success) {
						origAjax.success.apply(responseContext, succcessResponse);
					}

					handlers.onComplete.apply(responseContext, succcessResponse);
				} else if(errorResponse) {
					if(origAjax.error) {
						origAjax.error.apply(responseContext, errorResponse);
					}

					handlers.onError.apply(responseContext, errorResponse);
				} else {
					responseHandlers = {
						onComplete: function() {
							if(origAjax.success) {
								origAjax.success.apply(this, arguments);
							}

							handlers.onComplete();
						},
						onError: function() {
							if(origAjax.error) {
								origAjax.error.apply(this, arguments);
							}

							handlers.onError();
						}
					};
				}
			}
		};
	}

	let proxyAjax = $.extend({}, origAjax, {
		success: function(data) {
			cachedAjaxResponses[key] = data;

			if(origAjax.success) {
				origAjax.success.apply(this, arguments);
			}

			if(cachedAjaxRequests[key]) {
				cachedAjaxRequests[key].extraRequests.forEach(request => {
					if(request.success) {
						request.success.apply(this, arguments);
					}
				});

				delete cachedAjaxRequests[key];
			}
		},
		error: function() {
			if(origAjax.error) {
				origAjax.error.apply(this, arguments);
			}

			if(cachedAjaxRequests[key]) {
				cachedAjaxRequests[key].extraRequests.forEach(request => {
					if(request.error) {
						request.error.apply(this, arguments);
					}
				});

				delete cachedAjaxRequests[key];
			}
		}
	});

	cachedAjaxRequests[key] = {
		extraRequests: []
	};

	return proxyAjax;
};
$.cachedAjax = function(origAjax) {
	let proxyAjax = $.getCachedAjax(origAjax);
	if(proxyAjax?.url) {
		$.ajax(proxyAjax);
	} else if(proxyAjax?.function) {
		proxyAjax.function({
			onComplete: () => {}
		});
	}
};

$.getDataTablesPlicAPI = function(options) {
	var plicAjax = $.getPlicAPI(options);
	delete plicAjax.success;
	plicAjax.dataSrc = options.dataSrc;

	return plicAjax;
};
$.plicAPI = function(options) {
	$.ajax($.getPlicAPI(options));
};

$.getPlicAPI = function(options) {
	return $.getAuthAPI({
		urlPrefix: $.PlicUrl + 'api/',
		urlSuffix: '.json',
		token: $.PlicToken,
		accept: $.PlicAccept
	}, options);
};

$.captureLifeAPI = function(options) {
	$.ajax($.getCaptureLifeAPI(options));
};
$.getCaptureLifeAPI = function(options) {
	return $.getAuthAPI({
		urlPrefix: 'https://api.capturelife.com/',
		token: $.CaptureLifeToken
	}, options);
};

$.magistoAPI = function(options) {
	$.ajax($.getMagistoAPI(options));
};
$.getMagistoAPI = function(options) {
	return $.getAuthAPI({
		urlPrefix: 'https://www.magisto.com/api/'
	}, options);
};

$.getAuthAPI = function(api, options) {
	if(!api) {
		throw 'API required';
	}
	if(!options) {
		throw 'Options required';
	}
	if(!options.method) {
		throw 'Method required';
	}

	var type = options.type;
	var params = options.params;
	if(!type) {
		if(params) {
			type = 'POST';
		} else {
			type = 'GET';
		}
	}

	var url = api.urlPrefix + options.method;
	if(api.urlSuffix) {
		url += api.urlSuffix;
	}

	var headers = {
		'Cache-Control': 'no-cache'
	};
	if(api.token) {
		headers['Authorization'] = 'Bearer ' + api.token;
	}
	if(options.accept) {
		headers['Accept'] = options.accept;
	} else if(api.accept) {
		headers['Accept'] = api.accept;
	}
	if($.plicSlug) {
		headers['X-Plic-App'] = $.plicSlug;
	}

	var ajaxParams = {
		url: url,
		data: params,
		type: type,
		dataType: api.dataType ? api.dataType : 'json',
		success: function(data) {
			if(options.success) {
				options.success(data);
			}
		},
		error: function(jqXHR) {
			if(options.error) {
				options.error(jqXHR);
			}
		},
		headers: headers
	};
	if(options.maxRetries) {
		ajaxParams.maxRetries = options.maxRetries;
	}
	if(options.timeout) {
		ajaxParams.timeout = options.timeout;
	}

	return $.getProxyAjax(ajaxParams);
};

$.getPlicThumbnail = function(photo, options) {
	if(!photo) {
		return null;
	} else if(photo.existingUrl) {
		return photo.existingUrl;
	}

	var photoId;
	if(photo.photo) {
		photoId = photo.photo;
	} else if(photo.id) {
		photoId = photo.id;
	} else {
		photoId = photo;
	}

	if(!$.isInit(photoId) || typeof photoId === 'object') {
		$.fireErrorReport(null, 'Attempting to generate thumbnail for null photoId', 'Attempting to generate thumbnail for null photoId', {
			photo: photo,
			throwException: 'invalid thumbnail'
		});

		return null;
	} else if(!$.isValidUUID(photoId)) {
		// Do not short circuit execution until we are sure there aren't any valid cases of this
		$.fireErrorReport(null, 'Attempting to generate thumbnail for invalid photoId', 'Attempting to generate thumbnail for invalid photoId', {
			photo,
			photoId
		});
	}

	var url = '/ajax/getPhoto.php?id=' + photoId;

	// Add in any custom params
	if(options) {
		if(typeof options == 'string') {
			url += '&' + options;
		} else {
			for (var i in options) {
				url += '&opts[' + i + ']=' + options[i];
			}
		}
	}

	if(photo.cached_url) {
		url += '&cached_url=' + encodeURIComponent(photo.cached_url);
	} else if(photo.presigned_get) {
		url += '&cached_url=' + encodeURIComponent(photo.presigned_get);
	} else if(photo.photo_name) {
		url += '&photo_name=' + encodeURIComponent(photo.photo_name);
	} else if(photo.upload_file_name) {
		url += '&photo_name=' + encodeURIComponent(photo.upload_file_name);
	} else if(photo.original_file_name) {
		url += '&photo_name=' + encodeURIComponent(photo.original_file_name);
	}

	if(typeof photo.photoVersion != 'undefined') {
		url += '&photoVersion=' + photo.photoVersion;
	} else if(typeof photo.version_id != 'undefined') {
		url += '&photoVersion=' + photo.version_id;
	}
	if(photo.forceRefresh) {
		url += '&forceRefresh=true';
	}

	return url;
};
$.getPlicDownloadUrl = function(photo) {
	return $.getPlicThumbnail(photo, 'downloadOrig');
};
$.getThumbProperties = function(props) {
	return $.extend({}, $.getDefaultThumbProperties(), props);
};
$.getDefaultThumbProperties = function() {
	return {
		op: 'resize,rotate',
		deg: 'auto',
		mode: 'clip',
		filter: 'bilinear',
		q: '90'
	};
};