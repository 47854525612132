$.loadAlbum = function(options) {
	var albumIds = options.albumId;
	if(!$.isArray(albumIds)) {
		albumIds = [albumIds];
	}

	var chain;
	var photos = [];
	if(options.chain) {
		chain = options.chain;
		chain.addOnCompleteHandler(function(stats) {
			if(stats.errors) {
				if(options.error) {
					options.error();
				}
			} else {
				$.cachePhotos(options, photos);
			}
		});
	} else {
		chain = new $.ExecutionChain(function(stats) {
			if(stats.errors) {
				if(options.error) {
					options.error();
				}
			} else {
				$.cachePhotos(options, photos);
			}
		});
	}

	var params = {
		filter: {
			verified: true
		}
	};
	if(options.includeAllCrops) {
		params.include_all_crops = true;
	}

	albumIds.forEach(function(albumId) {
		loadSpecificAlbum(albumId);
	});

	if(!options.chain) {
		chain.done();
	}

	function loadSpecificAlbum(albumId) {
		var PAGE_COUNT = 500;
		chain.add($.getPlicAPI({
			method: 'albums/' + albumId + '/photos',
			params: $.extend({
				limit: PAGE_COUNT,
				page: 1
			}, params),
			type: 'GET',
			success: function (data) {
				$.merge(photos, data.photos.filter(function(photo) {
					return photo.hidden !== true;
				}));

				for(var i = 1; i < data.page.total_pages; i++) {
					chain.add($.getPlicAPI({
						method: 'albums/' + albumId + '/photos',
						params: $.extend({
							limit: PAGE_COUNT,
							page: i + 1
						}, params),
						type: 'GET',
						success: function(data) {
							$.merge(photos, data.photos.filter(function(photo) {
								return photo.hidden !== true;
							}));
						}
					}));
				}
			}
		}));
	}

	return chain;
};