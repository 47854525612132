<template>
	<v-flex sm12 md8 lg4>
		<v-card class="elevation-10">
			<v-list two-line>
				<h2><v-icon>receipt</v-icon> Order Details</h2>

				<order-account-info :order="order" />

				<v-list-item>
					<v-list-item-content>
						<v-list-item-title><b>Book:</b> &nbsp;{{ project.jobName }}</v-list-item-title>
						<v-list-item-subtitle>{{ order.quantity }} copies of a {{ order.pagesCount }} page book</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>

				<v-list-item v-if="order.shippingInformation">
					<v-list-item-content>
						<v-list-item-title><b>Shipping Information:</b></v-list-item-title>
						<v-list-item-subtitle>{{ order.shippingInformation.addressLine1 }}</v-list-item-subtitle>
						<v-list-item-subtitle v-if="order.shippingInformation.addressLine2">{{ order.shippingInformation.addressLine2 }}</v-list-item-subtitle>
						<v-list-item-subtitle>{{ order.shippingInformation.city }}, {{ order.shippingInformation.state }} {{ order.shippingInformation.zip }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>

				<v-list-item>
					<v-list-item-content>
						<v-list-item-title><b>Status:</b> {{ status }}</v-list-item-title>
						<v-list-item-subtitle v-if="order.totalPaid > 0">Total Paid: {{ this.order.totalPaid | money }}</v-list-item-subtitle>
						<v-list-item-subtitle v-if="order.state === 'partial'">Estimated Total: {{ this.order.orderTotal | money }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-btn color="primary" class="back-button" v-if="showBackButton" @click="back">Back</v-btn>
		</v-card>

		<v-alert
			type="success"
			:value="this.order.state !== 'paid'"
			transition="scale-transition"
			class="success">

			<template v-if="this.order.state === 'partial'">We have received your down payment and created your new account.  </template>

			<template v-if="userInvited">You should receive an email from PLIC Memories any minute now with instructions on how to get started!  After you set your new password, you will be redirected to login to start on your book.</template>
			<template v-else-if="order.email">
				You already have an account.  Please click login below to get started.
				<p/>
				<v-btn color="primary" :to="loginLink">Login</v-btn>
			</template>
		</v-alert>
	</v-flex>
</template>

<script>
import OrderAccountInfo from '../../components/OrderAccountInfo';

export default {
	name: 'PaymentReceipt',
	props: ['order', 'project', 'userInvited'],
	computed: {
		status() {
			if(this.order.state === 'partial') {
				return 'Down payment';
			} else if(this.order.state === 'paid') {
				return 'Paid';
			} else if(this.order.state === 'open') {
				return 'No payment';
			} else {
				return 'Unkown';
			}
		},
		showBackButton() {
			return this.$route.path.includes('checkout');
		},
		loginLink() {
			return `/landing/login?username=${this.order.email}`;
		}
	},
	methods: {
		back() {
			window.location = window.location.origin;
		}
	},
	components: {
		OrderAccountInfo
	}
};
</script>

<style scoped>
	.v-list-item {
		text-align: left;
	}

	.success {
		margin-top: 1em;
	}

	.back-button {
		margin-bottom: 1em;
	}
</style>