import filesize from 'filesize';

export default function checkIsValidImage(upload) {
	if(typeof upload.file.size === 'string') {
		upload.file.size = parseInt(upload.file.size);
	}

	if(upload.file.size > this.imageRequirements.maxSize) {
		upload.rejectReason = window.i18n.t('uploader.errors.imageTooBig', {
			maxFileSize: filesize(this.imageRequirements.maxSize)
		})
	} else if(upload.file.size < 12 || upload.file.name.indexOf('._') === 0 || upload.file.name.includes('.crdownload')) {
		upload.rejectReason = window.i18n.t('uploader.errors.empty');
	}

	return !upload.rejectReason;
}