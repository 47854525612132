<template>
	<div class="three fields frame-dynamic-graphics-editor-map">
		<!-- eslint-disable -->
		<template v-for="(value, field) in map.fieldMap">
			<frame-dynamic-graphics-editor-map-field :field="field" :fields="fields" @updateFieldName="updateFieldName" />
			<div class="field valueField">
				<label>Value</label>
				<input type="text" v-bind:value="value" v-on:input="updateFieldValue(field, $event.target.value)" />
			</div>
		</template>
		<div class="field photoField" ref="photoFieldWrapper">
			<label>&nbsp;</label>
			<img :src="photoUrl" @click="changePhoto" class="photo-preview" v-if="!photoLoading && map.photo" />
			<button-uploader label="Upload Photo" :start-album-id="albumId" color="teal" :style="uploaderButtonCSS" addClasses="uploader-button" @uploads-finished="uploadFinished" @uploads-started="uploadStarted" @upload-failed="uploadFailed" :drop-target="photoFieldWrapper" />
			<div class="ui error visible message" v-if="errorMessage">{{ errorMessage }}</div>
			<div class="ui red icon button" data-tooltip="Click to remove" @click="removeMap" style="float: right">
				<i class="remove icon" />
			</div>
		</div>
	</div>
</template>

<script>
import FrameDynamicGraphicsEditorMapField from './FrameDynamicGraphicsEditorMapField.vue';

export default {
	props: ['map', 'fields'],
	data: function() {
		return {
			photoLoading: false,
			fieldMaps: {},
			albumId: $.globalGraphicsAlbum.id,
			errorMessage: null,
			photoFieldWrapper: null
		};
	},
	computed: {
		photoUrl: function() {
			if(this.map.photo.existingUrl) {
				return this.map.photo.existingUrl;
			} else {
				return $.getPlicThumbnail({
					id: this.map.photo.id,
					photo_name: this.map.photo.name
				}, {
					w: 300
				});
			}
		},
		uploaderButtonCSS: function() {
			if(!this.map.photo || this.photoLoading) {
				return {};
			} else {
				return {
					display: 'none'
				};
			}
		}
	},
	methods: {
		removeMap: function() {
			this.$emit('removeMap', this.map);
		},
		changePhoto: function() {
			$(this.$el).find('.uploader-button').click();
		},
		updateFieldName: function(originalField, value) {
			// Vue is weird about the renaming here where it doesn't actively update the field passed
			var field = originalField;
			if($.isInit(this.fieldMaps[field])) {
				field = this.fieldMaps[field];
			}
			if(field == value) {
				return;
			}

			this.map.fieldMap[value] = this.map.fieldMap[field];
			delete this.map.fieldMap[field];
			this.fieldMaps[originalField] = value;
		},
		updateFieldValue: function(field, value) {
			// Vue is weird about the renaming here where it doesn't actively update the field passed
			if($.isInit(this.fieldMaps[field])) {
				field = this.fieldMaps[field];
			}

			this.map.fieldMap[field] = value;
		},
		uploadStarted: function() {
			this.photoLoading = true;
			this.errorMessage = null;
		},
		uploadFinished: function(uploads) {
			let upload = uploads[0];

			this.map.photo = {
				id: upload.photo.id,
				name: upload.photo.upload_file_name,
				photoWidth: upload.photo.width,
				photoHeight: upload.photo.height
			};
			this.photoLoading = false;
		},
		uploadFailed: function(upload, errorMessage) {
			this.errorMessage = errorMessage;
			this.photoLoading = false;
		}
	},
	mounted: function() {
		this.photoFieldWrapper = this.$refs.photoFieldWrapper;
	},
	components: {
		FrameDynamicGraphicsEditorMapField
	}
};
</script>

<style scoped>
.photoField {
	position: relative;
}
.photo-preview {
	height: 10em;
	cursor: pointer;
}
</style>