$.LayoutPage = function(settings) {
	var obj = new $.FlowPage($.extend({
		id: 'layout',
		type: 'layout',
		getTitle: function() {
			return null;
		},
		setTitle: function() {},
		updatePageLabel: function () {
			var sidebar = [];

			if (this.theme) {
				sidebar.push({
					name: 'Backgrounds',
					icon: 'setting',
					popup: 'Change settings on backgrounds',
					onClick: function (page, layout) {
						layout.openThemeSettings();
					}
				});

				sidebar.push({
					name: 'Remove Background',
					icon: 'picture',
					onClick: function (page, layout) {
						layout.clearBackground();
					}
				});
			}

			if(sidebar.length) {
				this.pageLabel = {
					display: '',
					popup: 'Options',
					sidebar: sidebar
				};
			} else {
				this.pageLabel = null;
			}
		},
		getLayout: function() {
			if(this.layout.grid && this.layout.grid.bleed && $.isPlainObject(this.layout.grid.bleed)) {
				this.layout.grid.bleedOutsideDimensions = true;
			}

			return this.layout;
		},
		canToggleContentLocks: function() {
			return true;
		},

		getPageMargins: null,
		setPageMargins: null
	}, settings));

	if($.isArray(obj.layout.images)) {
		obj.setCandidArray(obj.layout.images);
	} else if($.isPlainObject(obj.layout.images)) {
		obj.candids = obj.layout.images;
	}
	if($.isArray(obj.layout.texts)) {
		obj.setTextArray(obj.layout.texts);
	} else if($.isPlainObject(obj.layout.texts)) {
		obj.texts = obj.layout.texts;
	}

	if(obj.layout.theme) {
		obj.theme = obj.layout.theme;
	}

	// Delete so $.FlowLayout doesn't auto add them from layout definition
	delete obj.layout.images;
	delete obj.layout.texts;
	delete obj.layout.theme;

	return obj;
};
