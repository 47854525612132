$.extend(true, window.locales.en, {
	management: {
		goToProjectButton: 'Go to Book'
	},
	projects: {
		name: 'Book Name',
		project: 'Book',
		projectInformation: 'Basic book information',
		projects: 'Books',
		projectSettings: 'Book Settings',
		projectSettingsDescription: 'Advanced book settings'
	},
	organizations: {
		name: 'Customer Name',
		newOrganization: 'Create new customer',
		organization: 'Customer',
		organizationChoice: 'Choose your customer'
	}
});