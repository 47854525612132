$.getChangelog = function() {
	var changes = [
		{
			date: '02/08/2021',
			title: 'New uploader and Instagram integration',
			content: 'We are rolling out a new uploader with some major improvements such as being able to preview the images you selected and uploading from Google Drive and Instagram. ' +
				'<p/><img src="css/images/changelog/new-uploader.png" style="width: 100%"/><p/>'
		}
	];
	
	if($.userPermissions.editStudioSettings) {
		if($.userPermissions.manageStoreSettings) {
			$.merge(changes, [
				{
					date: '07/02/2020',
					title: 'Black and White pages',
					content: 'Your customers can now mark individual pages as black and white. ' +
						'Under the Content tab on the left sidebar, there are two new options for B&W vs color. ' +
						'Under Studio/Lab Settings -> Store Settings you can change the B&W per page cost to be different than a color page. ' +
						'When your customers checkout, the price will be automatically calculated based on how many color vs B&W pages they have in their book. '
				}
			]);
		}

		$.merge(changes, [
			{
				date: '07/29/2021',
				title: 'Project Setting: Project Type',
				content: 'There is a option under Project Settings for setting the type of a book. ' +
					'You can tag clip art, backgrounds, and themes to show up for specific types. ' +
					'You can also set the default Project Type for customers who register through your storefront. '
			},
			{
				date: '04/19/2021',
				title: 'Customer book store',
				content: 'Your customers can now distribute store links so that extended family can purchase copies of the book for themselves. ' +
					'On the Books tab there is a new cart button next to the order status. ' +
					'Clicking on it will open up the Store for that particular book project. ' +
					'These orders will show up on the Orders tab to manage. ' +
					'Under Studio/Lab Settings -> Store Settings you can set the minimum number of copies required for these orders. ' +
					'<p/><img src="css/images/changelog/customer-store.png" style="width: 100%"/><p/>'
			},
			{
				date: '03/25/2021',
				title: 'Orders tab',
				content: 'There is a new tab to view all orders and whether they are fully paid or not.'
			},
			{
				date: '02/16/2021',
				title: 'Customizable Book Templates',
				content: 'You can now customize the starting book template for each Book Format. ' +
					'Go to Studio/Lab Settings -> Book Formats, and then click on the person icon to the right. ' +
					'This will open a sample book with that size which you can edit to your hearts content. ' +
					'Next time one of your customers starts a new book, they will see this as their starting template instead of the built in one. ' +
					'Please note that the templates are per Book Format and do NOT apply to all Book Formats automatically. ' +
					'<p/><img src="css/images/changelog/customized-book-templates.jpg" style="width: 100%"/><p/>'
			},
			{
				date: '07/31/2020',
				title: 'Locking content in layouts',
				content: 'You can now lock an image or text node from being edited when they use one of your layouts. ' +
					'When designing a layout, there is a new toolbar option to lock the item. ' +
					'When a customer drags that layout onto a page, they will not longer be able to edit it or move it. '
			}
		]);
	} else {
		$.merge(changes, [
			{
				date: '07/02/2020',
				title: 'Black and White pages',
				content: 'You can now mark individual pages as black and white. ' +
					'Under the Content tab on the left sidebar, there are two new options for B&W vs color. '
			}
		]);
	}

	return changes;
};