import Vue from 'vue';

Vue.mixin({
	computed: {
		baseUrl() {
			return process.env.VUE_APP_MAIN_APP_LOCATION || '/';
		},
		logoUrl() {
			return this.baseUrl + 'api/v1/branding-logo?' + this.customerQueryAppend;
		},
		largeLogoUrl() {
			return this.baseUrl + 'api/v1/branding-logo?w=800&' + this.customerQueryAppend;
		},
		customerQueryAppend() {
			if(this?.$route.params.customer) {
				return `customer=${this.$route.params.customer}`;
			} else if(this?.$route.query?.customer) {
				return `customer=${this.$route.query.customer}`;
			} else if(this?.$router.customer) {
				return `customer=${this.$router.customer}`;
			} else if(['receipt', 'checkout'].includes(this?.$route.name)) {
				return `order=${this.$route.params.id}`;
			} else if(['store'].includes(this?.$route.name)) {
				return `projectId=${this.$route.params.id}`;
			} else {
				return '';
			}
		}
	}
})