import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

// Required polyfills
require('intersection-observer-polyfill/dist/IntersectionObserver.global');


export default new Vuetify({
	icons: {
		iconfont: 'md',
	},
	theme: {
		themes: {
			light: {
				primary: '#0091D2',
				secondary: '#00BCD4'
			}
		}
	}
});