$.dynamicGlobalVariables = [
	'Date', 'Long Date', 'Short Date', 'Year', 'Month', 'Time', 'Military Time',
	'Season', 'Long Season', 'Compact Long Season', 'Short Season', 'Compact Short Season', 'Compact Season'
];
$.dynamicStaticGlobalVariables = {};
$.getDynamicGlobalVariables = function(field, values) {
	if(field == 'Date' || field == 'Long Date') {
		return moment().format('MMMM D, YYYY');
	} else if(field == 'Short Date') {
		return moment().format('M/D/YYYY');
	} else if(field == 'Year') {
		return moment().format('YYYY');
	} else if(field == 'Month') {
		return moment().format('MMMM');
	} else if(field == 'Time') {
		return moment().format('hh:mm:ss A');
	} else if(field == 'Military Time') {
		return moment().format('kk:mm:ss');
	} else if(['Season', 'Long Season', 'Compact Long Season', 'Short Season', 'Compact Short Season', 'Compact Season'].indexOf(field) !== -1) {
		var startYear, endYear;
		if(values && values.season) {
			startYear = moment(values.season.period_start).year();
			endYear = moment(values.season.period_end).year();
		} else if($.currentActiveSeason) {
			startYear = moment($.currentActiveSeason.period_start).year();
			endYear = moment($.currentActiveSeason.period_end).year();
		} else if($.projectData && $.projectData.season) {
			startYear = moment($.projectData.season.period_start).year();
			endYear = moment($.projectData.season.period_end).year();
		} else {
			var year = moment().year();
			var month = moment().month();
			if(month <= 6) {
				year--;
			}

			startYear = year;
			endYear = year + 1;
		}

		if(field == 'Season' || field == 'Long Season') {
			return startYear + ' - ' + endYear;
		} else if(field == 'Compact Long Season') {
			return startYear + '-' + endYear;
		} else if(field == 'Short Season') {
			return (startYear % 100).toFixed(0) + ' - ' + (endYear % 100).toFixed(0);
		} else if(field == 'Compact Short Season') {
			return (startYear % 100).toFixed(0) + '-' + (endYear % 100).toFixed(0);
		} else if(field == 'Compact Season') {
			return startYear + '-' + (endYear % 100).toFixed(0);
		}
	}
};

$.combinedSubjectFields = {
	'name': '%first name% %last name%'
}