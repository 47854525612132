$.BookDB = function(pageSet) {
	var obj = new $.ComposerDBQueue({
		loadUrl: 'ajax/getBook.php',
		saveUrl: 'ajax/saveBook.php',
		postData: {
			jobId: pageSet.jobId,
			composer: true
		},
		pageSet: pageSet,
		onlyComments: false,
		getSocket: function() {
			var url = 'wss?composer=' + pageSet.jobId + '&token=' + $.PlicToken + '&type=job';
			if($.LoggedInAsUser) {
				url += '&originalToken=' + $.OriginalUserToken;
			}

			return url;
		},
		userEvents: $.userEvents,
		loadSubjects: false,
		compressSaves: true
	});

	var _loadPages = obj.loadPages;
	$.extend(obj, {
		loadPages: function() {
			_loadPages.apply(this, arguments);

			if(this.pageSet.theme && this.pageSet.theme.register) {
				this.pageSet.updateTextThemeStyles({}, this.pageSet.theme);

				this.pageSet.setProperty('theme', $.extend(true, {}, this.pageSet.theme, {
					register: false
				}), true, true);
			}
		},
		loadSinglePage: function (definition, previousPage) {
			var page;
			switch (definition.type) {
				case 'cover':
					page = new $.BookCoverPage(null, previousPage == null ? 'front' : 'back');
					if (this.pageSet.coverLocked) {
						page.setLocked(true);
					}
					break;
				case 'insideCover':
					page = new $.BookInsideCoverPage();
					break;
				case 'candid':
					page = new $.BookCandidPage();
					break;
				case 'empty': case 'generic':
					if (definition.texts && (definition.texts.length || $.getObjectCount(definition.texts) > 0) ||
						definition.candids && (definition.candids.length || $.getObjectCount(definition.candids) > 0)) {
						page = new $.BookCandidPage();
					} else {
						page = new $.BookPlaceholder();
					}
					break;
				case 'table-of-contents':
					page = $.BookTableOfContents();
					break;
				case 'table-of-contents-overflow':
					page = $.BookTableOfContentsOverflow(previousPage);
					break;
				case 'chapter':
					page = $.BookChapterPage();
					break;
				case 'chapter-overflow':
					page = $.BookChapterOverflowPage(previousPage);
					break;
			}

			if(definition.type == 'cover') {
				// PHP likes to do blank arrays for {}
				if(($.isArray(definition.layout) && !definition.layout.length) || !$.isInit(definition.layout)) {
					definition.layout = {};
				}

				if($.isPlainObject(definition.layout)) {
					if (definition.layout.grid) {
						delete definition.layout.grid;
					}
				}
			}

			if(page) {
				if (definition.theme) {
					page.theme = definition.theme;
				}

				this.loadStandardPageData(definition, page);
				if(page.layout == null) {
					page.layout = {};
				}

				if($.isInit(definition.pageText)) {
					page.pageText = definition.pageText;
				}
			}

			return page;
		},
		getEntirePageData: function(page) {
			return {
				id: page.id,
				candids: page.candids,
				layout: page.layout ? page.layout : null,
				pageNumber: page.pageNumber,
				texts: page.texts,
				theme: page.theme ? page.theme : null,
				type: page.type,
				extras: page.extras ? page.extras : null,
				comments: page.comments,
				pageText: page.pageText
			};
		},
		formatLastPageSeen: function(page) {
			if(page < 2) {
				return 'the front cover';
			} else if(page > (this.pageSet.getTotalPages() - 2)) {
				return 'the back cover';
			} else {
				return 'page ' + Math.max(1, (page - 2));
			}
		}
	});

	return obj;
};