$.SubjectManagementAddCard = function(list, job) {
	var div = $('<div class="ui card subjectAddCard">')[0];

	$.extend(div, {
		startAdd: function () {
			this.overlay.removeClass('active');
			this.header.find('.exampleHeader').hide();

			this.uploadButton = $('<a class="ui teal icon disabled button"><i class="upload icon"></i> Portrait</a>');
			this.header.append(this.uploadButton);

			this.firstNameBox = $('<div class="ui fluid labeled input field subjectAddProperty" style="margin: 0;" name="First Name"><div class="ui label">First Name</div><input type="text" placeholder="First Name" name="firstName" style="display: inherit;"></div>');
			this.lastNameBox = $('<div class="ui fluid labeled input field subjectAddProperty" style="margin: 0;" name="Last Name"><div class="ui label">Last Name</div><input type="text" placeholder="Last Name" name="lastName" style="display: inherit;"></div>');
			this.content.append(this.firstNameBox).append(this.lastNameBox);

			var fieldValues = this.list.fieldValues;
			var formRules = {
				firstName: {
					identifier: 'firstName',
					rules: [
						{
							type: 'empty'
						}
					]
				},
				lastName: {
					identifier: 'lastName',
					rules: [
						{
							type: 'empty'
						}
					]
				}
			};

			this.saveBoxes = [this.firstNameBox, this.lastNameBox];
			for(var i = 0; i < job.fieldSet.editableFields.length; i++) {
				var jobField = job.fieldSet.editableFields[i];
				if(list) {
					if(jobField.ifInData) {
						if(!list.parent.showFields[jobField.name]) {
							continue;
						}
					} else if(list.parent.hideFields[jobField.name]) {
						continue;
					}
				}
				var fieldNameLower = jobField.name.toLowerCase();
		
				if(jobField.group) {
					var dropdownField = $('<div class="ui fluid labeled input field subjectAddProperty" style="margin: 0;">').attr('name', jobField.name);
					$('<div class="ui label">').text(jobField.name).appendTo(dropdownField);

					var dropdown = $('<div class="ui search selection fluid dropdown" tabindex="0">');
					var startVal = this.list.getCommonFieldValue(jobField.name);
					if (!startVal) {
						if(fieldValues[jobField.name].length == 1) {
							startVal = fieldValues[jobField.name][0];
						} else {
							startVal = '';
						}
					}
					$('<input type="hidden"/>').attr('name', fieldNameLower).attr('value', startVal).appendTo(dropdown);
					$('<div class="text">').text(jobField.name).appendTo(dropdown);
					$('<i class="dropdown icon">').appendTo(dropdown);

					var dropdownOptions = $('<div class="menu" tabindex="-1">');
					for (var j = 0; j < fieldValues[jobField.name].length; j++) {
						var fieldValue = fieldValues[jobField.name][j];
						var item = $('<div class="item">');
						if (!$.isInit(fieldValue) || fieldValue === '') {
							item.html('&nbsp;');
						} else {
							item.text(fieldValue);
						}
						dropdownOptions.append(item);
					}

					dropdown.append(dropdownOptions);
					dropdown.dropdown({
						allowAdditions: true
					});
					dropdownField.append(dropdown);

					this[fieldNameLower.replace(' ', '') + 'Box'] = dropdownField;
					this.content.append(dropdownField);
					this.saveBoxes.push(dropdownField);

					if(fieldValues[jobField.name].length) {
						formRules[fieldNameLower] = {
							identifier: fieldNameLower,
							rules: [
								{
									type: 'empty'
								}
							]
						}
					}
				} else {
					var fieldBox = $('<div class="ui fluid labeled input field subjectAddProperty" style="margin: 0;"></div>').attr('name', jobField.name);
					$('<div class="ui label"></div>').text(jobField.name).appendTo(fieldBox);
					$('<input type="text" placeholder="First Name" name="firstName" style="display: inherit;" />')
						.attr('placeholder', jobField.name)
						.attr('name', fieldNameLower)
						.appendTo(fieldBox);
					this.saveBoxes.push(fieldBox);
					
					this.content.append(fieldBox);
				}
			}

			// Add form validations
			this.content.form({
				fields: formRules
			});

			// Edit buttons
			var me = this;
			this.buttons = $('<div class="ui two bottom attached buttons">');
			$('<div class="ui negative button editButton">Cancel</div>').click(function () {
				me.stopAdd();
			}).appendTo(this.buttons);
			$('<div class="ui positive button editButton">Add</div>').click(function () {
				if (!me.content.form('validate form')) {
					return false;
				}

				me.saveSubject();
			}).appendTo(this.buttons);
			$(this).append(this.buttons);

			this.avatar.on('click', function () {
				me.uploadAvatar();
			});

			this.initUploader();
		},
		initUploader: function() {
			if(this.uploadButtonVue) {
				return;
			}

			div.uploadButtonVue = new Vue({
				data: function() {
					return {
						color: 'teal'
					};
				},
				render: function(createElement) {
					return createElement('button-uploader', {
						props: {
							label: 'Portrait',
							startAlbumId: list.getSubjectAlbum(),
							addClasses: 'uploadButton',
							color: this.color
						},
						on: {
							'uploads-started': function() {
								// Starting upload has async converting tasks before getting here that could cause a delay
								if(!div.uploadButtonVue) {
									return;
								}

								div.uploadButtonVue.color = 'blue';
							},
							'uploads-finished': function(uploads) {
								// They can click cancel while it is still uploading
								if(!div.uploadButtonVue) {
									return;
								}
								var upload = uploads[0];
								var newSubjectPhoto = upload.photo;
								newSubjectPhoto.photoCdnUrl = $.getPlicThumbnail(newSubjectPhoto, {
									w: 100
								});
								if(!newSubjectPhoto.photo_crops) {
									newSubjectPhoto.photo_crops = [];
								}

								div.oldSubjectPhoto = div.avatar.attr('src');
								div.avatar.LoadImage(newSubjectPhoto.photoCdnUrl);
								div.subjectPhoto = newSubjectPhoto;

								div.uploadButtonVue.color = 'blue';

								if(newSubjectPhoto.width && newSubjectPhoto.height) {
									var issues = $.SubjectManagement.getSubjectIssues({
										yearbookPhoto: {
											width: newSubjectPhoto.width,
											height: newSubjectPhoto.height
										}
									});

									var uploadIssuesDetected = false;
									issues.forEach(function(issue) {
										if(issue.indexOf('Low resolution') != -1) {
											uploadIssuesDetected = issue;
											return false;
										}
									});

									if(uploadIssuesDetected) {
										div.uploadButtonVue.color = 'yellow';
										$.Alert('Warning', uploadIssuesDetected);
									}
								}
							}
						},
						mounted: function() {
							$(this.$el).hide();
						}
					});
				},
				vuetify: window.Vuetify
			}).$mount(this.uploadButton[0]);
		},
		stopAdd: function () {
			this.overlay.addClass('active');
			this.content.find('.exampleHeader').show();

			this.avatar.off('click').attr('src', '/css/images/no-photo.jpg');
			this.subjectPhoto = null;
			$(this.uploadButtonVue.$el).remove();
			this.uploadButtonVue = null;
			$(this).find('.subjectAddProperty').remove();
			this.buttons.remove();
		},
		uploadAvatar: function () {
			$(this.uploadButtonVue.$el).find('input').click();
		},
		saveSubject: function () {
			// Create subject for passing to card
			var subject = {
				'Teacher Priority': 0
			};
			this.saveBoxes.forEach(function(saveBox) {
				var dropdown = $(saveBox).find('.dropdown .text');
				var value;
				if(dropdown.length) {
					value = dropdown.text();

					// Do not save a no-item dropdown default label as the new value...
					if(saveBox.attr('name') === value) {
						value = '';
					}
				} else {
					value = saveBox.find('input').val();
				}
				if(value.length == 1 && value.charCodeAt(0) === 160) {
					value = '';
				}

				subject[saveBox.attr('name')] = value;
			});

			if(this.studentIdBox) {
				subject['Student ID'] = this.studentIdBox.find('input').val();
			}

			// Get properties to pass to Plic
			var labelMap = this.job.fieldLabelMap;
			var properties = {};
			for (var label in subject) {
				properties[labelMap[label]] = subject[label];
			}

			for(var i = 0; i < job.fieldSet.editableFields.length; i++) {
				var jobField = job.fieldSet.editableFields[i];
				if(!jobField.group) {
					continue;
				}
				var value = subject[jobField.name];

				// Make sure value exists in global list
				if(value) {
					list.parent.addToFieldValues(jobField.name, value);
				}
			}

			if(this.subjectPhoto) {
				$.SubjectManagement.populateSubjectWithPrimaryPhoto(subject, this.subjectPhoto);
			}
			var newCard = new $.SubjectManagementCard(subject, {
				job: this.job,
				batch: this.list.currentBatch,
				fieldValues: this.list.fieldValues,
				individualizedOption: this.list.individualizedOption,
				editable: true,
				list: this.list
			});
			this.list.resortSubject(newCard, false);
			newCard.startLoading();

			var me = this;
			var newBatch = this.list.currentBatch;
			$.plicAPI({
				method: 'projects/' + this.job.plicProjectId + '/subjects',
				type: 'POST',
				params: {
					subject: {
						properties: properties
					},
					photo_id: subject.photo
				},
				accept: 'application/vnd.plic.io.v1+json',
				success: function (data) {
					subject.id = data.subject.id;
					$(newCard).attr('id', 'subject' + subject.id);

					var index = me.list.getSubjectSortedPosition(newBatch.subjects, newCard.subject);
					newBatch.subjects.splice(index, 0, newCard.subject);
					// Required for Trash
					me.list.parent.updateBatchDivCount(newBatch);

					if (newBatch.id != -1) {
						me.list.parent.batchSaveBatchOrders([$.SubjectManagement.getAppSpecificBatchData(newBatch)]);
					}

					if ($(me).isAttached()) {
						me.list.subjectIndex++;
					}
					newCard.stopLoading();

					// Show the card that we just added
					$('html, body').animate({
						scrollTop: $(newCard).offset().top - 100
					}, 400);
					$(newCard).flashRed();

					newCard.openSubjectPhotosDialogIfNecessary();
				},
				error: function () {
					$.Alert('Error', 'Failed to add new subject');
					$(newCard).remove();
				}
			});

			this.stopAdd();
		}
	});

	if(!list) {
		throw new Error('No list defined for add card');
	} else if(!job) {
		throw new Error('No job passed to add card');
	}

	div.content = $('<div class="ui content form"><div class="header"><img class="ui massive image subjectAvatar" src="css/images/image-placeholder.png" style="cursor: pointer" /><span class="exampleHeader">John Doe</span></div></div>');
	div.header = div.content.find('.header');
	div.avatar = div.header.find('img');
	div.avatar.attr('src', '/css/images/no-photo.jpg');
	$(div).append(div.content);

	div.overlay = $('<div class="ui active inverted dimmer"><div class="content"><div class="center"><div class="ui huge circular positive icon button"><i class="plus icon"></i></div></div></div></div>');
	div.overlay.find('.positive.button').click(function() {
		div.startAdd();
	});
	$(div).append(div.overlay);

	div.list = list;
	div.job = job;

	return div;
};