<template>
	<v-list-item>
		<v-list-item-content>
			<v-list-item-title><b>Order #:</b> {{ order.orderNumber }}</v-list-item-title>
			<v-list-item-subtitle>Account: {{ order.firstName }} {{ order.lastName }}</v-list-item-subtitle>
			<v-list-item-subtitle>Email: {{ order.email }}</v-list-item-subtitle>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
export default {
	name: 'OrderAccountInfo',
	props: {
		order: null
	}
};
</script>