$.FlowLayoutCropMarks = function(options) {
	var div = document.createElement('div');
	div.className = 'flowLayoutCropMarks';

	$.extend(div, {
		init: function() {
			this.topLeftLeftMark = $('<div class="topLeftLeftCropMark">').appendTo(this);
			this.topLeftTopMark = $('<div class="topLeftTopCropMark">').appendTo(this);

			this.topRightRightMark = $('<div class="topRightRightCropMark">').appendTo(this);
			this.topRightTopMark = $('<div class="topRightTopCropMark">').appendTo(this);

			this.bottomLeftLeftMark = $('<div class="bottomLeftLeftCropMark">').appendTo(this);
			this.bottomLeftTopMark = $('<div class="bottomLeftBottomCropMark">').appendTo(this);

			this.bottomRightRightMark = $('<div class="bottomRightRightCropMark">').appendTo(this);
			this.bottomRightTopMark = $('<div class="bottomRightBottomCropMark">').appendTo(this);
		},
		setRatio: function(ratio) {
			this.ratio = ratio;
		},
		setBleed: function(actualBleed) {
			$(this).css({
				width: $(this.flowLayout).getFloatStyle('width') - actualBleed.left - actualBleed.right,
				height: $(this.flowLayout).getFloatStyle('height') - actualBleed.top - actualBleed.bottom,
				marginLeft: actualBleed.left,
				marginRight: actualBleed.right,
				marginTop: actualBleed.top,
				marginBottom: actualBleed.bottom
			});
		},
		destroy: function() {
			$(this).remove();
		},

		ratio: options.flowLayout.ratio
	}, options);
	div.init();

	return div;
};