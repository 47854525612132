import { plicV1Axios } from '../../utils/axios';

let requirements = null;
let requirementPromise = null;
export default async function loadRequirements() {
	return new Promise((resolve, reject) => {
		if(requirements) {
			resolve(requirements);
		} else {
			if(!requirementPromise || window.jasmine) {
				requirementPromise = plicV1Axios.get(`photos/upload-requirements.json`);
			}
			
			requirementPromise.then(response => {
				requirements = response.data.requirements;
				requirementPromise = null;
		
				resolve(requirements);
			}).catch((error) => {
				requirementPromise = null;
				
				reject(error);
			});
		}
	});
}

// Only used for testing at the moment
export function clearRequirementsCache() {
	requirements = null;
}