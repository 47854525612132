// eslint-disable-next-line
$.Alert = Alert = function(title, text, onDismiss, extraOptions) {
	var options = {
		onHide: function() {
			if(onDismiss) {
				onDismiss();
			}
		},
		onHidden: function() {
			$(this).remove();
		}
	};

	if(extraOptions) {
		options = $.extend(options, extraOptions);
	}

	var headerIcon = '';
	if(extraOptions && extraOptions.headerIcon) {
		headerIcon = '<i class="' + extraOptions.headerIcon + ' icon"></i> ';
	}

	var content = $('<div class="content">');
	if(text instanceof $) {
		content.append(text);
	} else if(extraOptions && extraOptions.allowHtml) {
		content.html(text);
	} else {
		content.text(text);
	}

	var modal = $('<div class="ui modal"><i class="close icon"></i></div>');
	if(extraOptions && extraOptions.modalSize) {
		modal.addClass(extraOptions.modalSize);
	} else {
		modal.addClass('small');
	}

	return modal
		.append($('<div class="header">').text(title).prepend(headerIcon))
		.append(content)
		.append('<div class="actions"><div class="ui positive button">' + i18n.t('misc.close') + '</div></div>')
		.modal(options)
		.modal('show');
};
$.AlertSuccess = function(text, onDismiss, extraOptions) {
	if(!extraOptions) {
		extraOptions = {};
	}

	if(!extraOptions.headerIcon) {
		extraOptions.headerIcon = 'green checkmark';
	}

	$.Alert('Success', text, onDismiss, extraOptions);
};
$.AlertWarning = function(text, onDismiss, extraOptions) {
	if(!extraOptions) {
		extraOptions = {};
	}

	if(!extraOptions.headerIcon) {
		extraOptions.headerIcon = 'warning';
	}

	$.Alert('Warning', text, onDismiss, extraOptions);
};
$.Confirm = function(title, message, onConfirm, onCancel) {
	var buttonClicked = false;
	$('<div class="ui small modal">')
		.append('<i class="close icon">')
		.append($('<div class="header">').text(title))
		.append($('<div class="content">').text(message))
		.append('<div class="actions"><div class="ui negative button">' + i18n.t('misc.cancel') + '</div><div class="ui positive button">' + i18n.t('misc.ok') + '</div></div>')
		.modal({
			onApprove: function() {
				if(onConfirm && !buttonClicked) {
					onConfirm();
					buttonClicked = true;
				}
			},
			onDeny: function() {
				if(onCancel && !buttonClicked) {
					onCancel();
					buttonClicked = true;
				}
			},
			onHidden: function() {
				$(this).remove();
			}
		}).modal('show');
};
$.ConfirmCheckbox = function(title, message, onConfirm, onCancel) {
	var description = $('<div class="description">')
		.append($('<p>').text(message).append(' <b>' + i18n.t('misc.permanentAction') + '</b>'))
		.append('<div class="ui checkbox confirmCheckbox"><input type="checkbox" name="confirmCheckbox"><label>' + i18n.t('misc.confirmAction') + '</label></div>');

	var content = $('<div class="image content">')
		.append('<div class="image"><i class="yellow warning sign icon"></i></div>')
		.append(description);

	var buttonClicked = false;
	$('<div class="ui small modal">')
		.append('<i class="close icon">')
		.append($('<div class="header">').text(title))
		.append(content)
		.append('<div class="actions"><div class="ui deny button">' + i18n.t('misc.cancel') + '</div><div class="ui positive button disabled">' + i18n.t('misc.ok') + '</div></div>')
		.modal({
			onVisible: function() {
				var confirmButton = $(this).find('.actions .positive');
				$(this).find('.confirmCheckbox').checkbox({
					onChecked: function() {
						confirmButton.removeClass('disabled');
					},
					onUnchecked: function() {
						confirmButton.addClass('disabled');
					}
				});
			},
			onApprove: function() {
				if(onConfirm && !buttonClicked) {
					onConfirm();
					buttonClicked = true;
				}
			},
			onDeny: function() {
				if(onCancel && !buttonClicked) {
					onCancel();
					buttonClicked = true;
				}
			},
			onHidden: function() {
				$(this).remove();
			}
		}).modal('show');
};
$.ConfirmDelete = function(title, message, onConfirm, onCancel, options) {
	options = $.extend(true, {}, options);

	var description = $('<div class="description">')
		.append($('<p>').text(message).append(' <b>' + i18n.t('misc.permanentAction') + '</b>'))
		.append('<div class="ui checkbox deleteConfirmCheckbox"><input type="checkbox" name="deleteConfirmCheckbox"><label>' + i18n.t('misc.confirmDelete') + '</label></div>');

	var content = $('<div class="image content">')
		.append('<div class="image"><i class="red warning sign icon"></i></div>')
		.append(description);


	var buttonClicked = false;
	$('<div class="ui small modal">')
		.append('<i class="close icon">')
		.append($('<div class="header">').append('<i class="trash icon">').append(document.createTextNode(title)))
		.append(content)
		.append('<div class="actions"><div class="ui deny button">' + i18n.t('misc.cancel') + '</div><div class="ui approve red right labeled icon button disabled"><i class="trash icon"></i>' + i18n.t('misc.delete') + '</div></div>')
		.modal({
			allowMultiple: options.allowMultiple === true,
			onVisible: function() {
				var deleteButton = $(this).find('.actions .approve.red');
				$(this).find('.deleteConfirmCheckbox').checkbox({
					onChecked: function() {
						deleteButton.removeClass('disabled');
					},
					onUnchecked: function() {
						deleteButton.addClass('disabled');
					}
				});
			},
			onApprove: function() {
				if(onConfirm && !buttonClicked) {
					onConfirm();
				}
				buttonClicked = true;
			},
			onDeny: function() {
				if(onCancel && !buttonClicked) {
					onCancel();
				}
				buttonClicked = true;
			},
			onHidden: function() {
				$(this).remove();
			}
		}).modal('show');
};

$.DisplayMessage = function(elem, title, text, removeOthers, type) {
	if(removeOthers) {
		$(elem).find('.message').remove();
	}
	if(!type) {
		type = 'warning';
	}

	var message = $('<div class="ui floating message" style="display: inherit">')
		.addClass(type);
	$('<i class="close icon"></i>').click(function() {
		$(this).parent().hide();
	}).appendTo(message);

	$('<div class="header">')
		.text(title)
		.appendTo(message);
	$('<p>')
		.text(text)
		.appendTo(message);
	message.appendTo(elem);
};

$.EditNameModal = function(title, fieldName, currentName, onComplete, options) {
	options = $.extend(true, {}, options);

	var form = $('<form class="ui content form">');
	$('<div class="field">')
		.append('<label>' + fieldName + '</label>')
		.append($('<input type="text" id="editName" name="editName" />').attr('placeholder', currentName).val(currentName))
		.appendTo(form);

	var buttonClicked = false;
	var dialog = $('<div class="ui small modal"><i class="close icon"></i><div class="header">' + title + '</div></div>')
		.append(form)
		.append('<div class="actions"><div class="ui negative button">' + i18n.t('misc.cancel') + '</div><div class="ui positive button">' + i18n.t('misc.ok') + '</div></div>')
		.modal({
			closable: false,
			allowMultiple: options.allowMultiple === true,
			onVisible: function() {
				dialog.find('.form').form({
					fields: {
						subAlbum: {
							identifier: 'editName',
							rules: [
								{type: 'empty'},
								{type: 'maxLength[250]'}
							]
						}
					}
				}).submit(function() {
					dialog.find('.positive.button').click();
					return false;
				});
			},
			onApprove: function() {
				// Validate that category is non empty
				if(!dialog.find('.form').form('validate form') || buttonClicked) {
					return false;
				}

				var name = dialog.find('#editName').val();
				onComplete(name);
				buttonClicked = true;
			},
			onHidden: function() {
				$(this).remove();
			}
		}).modal('show');
};

$.PresentOptions = function(title, message, options, settings) {
	if(!settings) {
		settings = {};
	}

	// Check if we have a cached change to use
	if (settings.rememberChoice && $.userExtras && $.userExtras[settings.rememberChoice.parent]) {
		var cachedChoice = $.userExtras[settings.rememberChoice.parent][settings.rememberChoice.id];

		if(cachedChoice) {
			for(let i = 0; i < options.length; i++) {
				let option = options[i];
				if(option.id == cachedChoice) {
					option.onClick();
					return;
				}
			}
		}
	}

	var buttons = $('<div class="actions">');
	for (let i = 0; i < options.length; i++) {
		let option = options[i];
		var button = $('<div class="ui button">').text(option.title);
		if (option.addClass) {
			button.addClass(option.addClass);
		}
		button.data('option', option)

		buttons.append(button);
	}

	var extraContent = $();
	if (settings.rememberChoice) {
		var choice = $('<div class="ui checkbox">');
		choice.append('<input type="checkbox">');
		choice.append('<label>Don\'t ask me again</label>');
		choice.checkbox();
		extraContent = extraContent.add('<p/>').add(choice);
	}

	$('<div class="ui small modal">')
		.append('<i class="close icon">')
		.append($('<div class="header">').text(title))
		.append($('<div class="content">').text(message).append(extraContent))
		.append(buttons)
		.modal({
			onApprove: function (button) {
				onButtonClicked(button);
			},
			onDeny: function (button) {
				onButtonClicked(button);
			},
			onHidden: function () {
				$(this).remove();
			}
		}).modal('show');

	function onButtonClicked(button) {
		var option = button.data('option');
		option.onClick();

		if(choice && choice.checkbox('is checked')) {
			if(!$.userExtras[settings.rememberChoice.parent]) {
				$.userExtras[settings.rememberChoice.parent] = {};
			}

			$.userExtras[settings.rememberChoice.parent][settings.rememberChoice.id] = option.id;

			$.ajax({
				url: 'ajax/saveUserExtra.php',
				dataType: 'json',
				data: {
					name: settings.rememberChoice.parent,
					value: $.userExtras[settings.rememberChoice.parent]
				},
				type: 'POST'
			});
		}
	}
};

$.SettingsBuilderDialog = function(settings, options) {
	if(!options || !options.title) {
		throw 'Invalid options passed to $.SettingsBuilderDialog';
	}

	var modal = $('<div class="ui modal"><i class="close icon"></i> <div class="header">' + options.title + '</div></div>');
	var content = $('<div class="content"></div>').appendTo(modal);
	modal.append('<div class="actions"><div class="ui negative button">' + i18n.t('misc.cancel') + '</div><div class="ui positive button">' + i18n.t('misc.ok') + '</div></div>');
	if(options.required) {
		modal.find('.close.icon').remove();
		modal.find('.actions .negative.button').remove();
	}

	var form = $('<div class="ui form">');
	var settingsForm = form.SettingsBuilder(settings, {
		onSettingChange: options.onSettingChange,
		groupInFields: options.groupInFields,
		onSubmit: function() {
			modal.find('.positive.button').click();
		}
	});
	modal[0].settingsForm = settingsForm;
	content.append(form);
	if(options.onCreateContent) {
		options.onCreateContent(content, form);
	}

	var autoFocus = true;
	if(form.find('.field').eq(0).hasClass('dropdownField')) {
		autoFocus = false;
	}

	var buttonClicked = false;
	modal.modal({
		onApprove: function() {
			var changes = settingsForm.getChanges();
			if(!changes || buttonClicked) {
				return false;
			}

			if(changes.length || modal.applyOnEmptyChanges) {
				settingsForm.applyChanges(changes);

				if (options.onSettingsApplied) {
					options.onSettingsApplied(settingsForm.currentSettings, changes);
				}
			}
			buttonClicked = true;
		},
		onHidden: function() {
			$(this).remove();

			if(options.onSettingsHidden) {
				options.onSettingsHidden(settingsForm.currentSettings);
			}
		},
		onShow: function() {
			if(options.onShow) {
				options.onShow(content);
			}
		},
		observeChanges: true,
		closable: false,
		autofocus: autoFocus
	});

	if(!options.noShow) {
		modal.modal('show');
	}

	return modal;
};
