import $ from 'jquery';
if(!window.$) {
	window.$ = window.jQuery = $;
}

import '../../../js/pages/cover-page-common';
import '../../../js/pages/error-page';
import '../../../js/pages/flow-page-class-set';
import '../../../js/pages/flow-page-class';
import '../../../js/pages/flow-page-overflow';
import '../../../js/pages/flow-page-set';
import '../../../js/pages/flow-page-sub-set';
import '../../../js/pages/flow-page-with-subjects';
import '../../../js/pages/flow-page';
import '../../../js/pages/flow-version-page';

import '../../../js/css-bundle';