$.ExecutionChain = function(onComplete, options) {
	var obj = new Object;

	$.extend(obj, {
		add: function (exe, args, front) {
			if (!args) {
				args = [];
			}

			if (typeof exe == 'function' && this.runningExecute < this.concurrentExecute ||
				typeof exe != 'function' && this.runningAjax.length < this.concurrentAjax) {
				this.execute(exe, args);
			} else {
				if (front) {
					this.stack.unshift(exe);
					this.argsStack.unshift(args);
				} else {
					this.stack.push(exe);
					this.argsStack.push(args);
				}
			}
			this.total++;
		},
		remove: function(exe) {
			var index = this.stack.indexOf(exe);
			if(index !== -1) {
				this.stack.splice(index, 1);
				this.argsStack.splice(index, 1);
			}
		},
		done: function () {
			this.complete = true;

			if (this.running === 0) {
				this.runOnComplete();
			}
		},
		execute: function (exe, args) {
			var obj = this;
			if (typeof exe == 'function') {
				window.setTimeout(function () {
					exe.apply(obj, args);
					obj.runningExecute--;
					obj.onExecuteComplete();
				}, 10);
				this.runningExecute++;
			} else if(exe instanceof Image) {
				exe.addEventListener('load', function() {
					obj.runningAjax.removeItem(exe);
					obj.onExecuteComplete();
				});
				exe.addEventListener('error', function() {
					obj.errorCount++;
					obj.runningAjax.removeItem(exe);
					obj.onExecuteComplete();
				});
				exe.src = args[0];

				this.runningAjax.push(exe);
			} else if(exe && exe.url) {
				var ajax = exe;

				var ajaxResponse;
				var chainAjax = $.getProxyAjax($.extend({}, ajax, {
					success: function () {
						var passArgs = arguments;
						if (args) {
							passArgs = $.merge(passArgs, args);
						}

						if (ajax.success) {
							ajax.success.apply(this, passArgs);
						}

						obj.runningAjax.removeItem(ajaxResponse);
						obj.onExecuteComplete();
					},
					error: function () {
						if(obj.enabled) {
							if (ajax.error) {
								ajax.error.apply(this, arguments);

								if (ajax.retryingError) {
									ajax.retryingError = false;
									return;
								}
							}

							obj.errorCount++;
						}

						obj.runningAjax.removeItem(ajaxResponse);
						obj.onExecuteComplete();
					}
				}));
				ajax.actualSettings = chainAjax;
				ajaxResponse = $.ajax(chainAjax);

				this.runningAjax.push(ajaxResponse);
			} else if(exe && exe.function) {
				window.setTimeout(function () {
					exe.function.call(obj, {
						onComplete: function() {
							obj.runningExecute--;
							obj.onExecuteComplete();
						},
						onError: function() {
							obj.errorCount++;
							obj.runningExecute--;
							obj.onExecuteComplete();
						},
						args: args
					});
				}, 10);
				this.runningExecute++;
			} else {
				this.running++;
				this.onExecuteComplete();
				return;
			}

			this.running++;
		},
		onExecuteComplete: function() {
			this.running--;

			this.completeCount++;
			if(this.onProgress) {
				this.onProgress(this.completeCount, this.completeCount + this.stack.length);
			}

			if(this.stack.length > 0 && this.enabled) {
				this.execute(this.stack.shift(), this.argsStack.shift());
			} else if(this.running <= 0 && this.complete === true && this.enabled === true) {
				this.runOnComplete();
			}
		},
		addOnCompleteHandler: function(onCompleteHandler) {
			if(!$.isArray(this.onComplete)) {
				this.onComplete = [this.onComplete];
			}

			// NOTE: Important that new ones run before main handler for a Books loading categories!
			this.onComplete.unshift(onCompleteHandler);
		},
		runOnComplete: function() {
			var completionHandlers;
			if($.isArray(this.onComplete)) {
				completionHandlers = this.onComplete;
			} else {
				completionHandlers = [this.onComplete];
			}

			for(var i = 0; i < completionHandlers.length; i++) {
				var completionHandler = completionHandlers[i];
				completionHandler.call(this, {
					complete: this.completeCount,
					errors: this.errorCount
				});

				if(completionHandlers.length > 1) {
					completionHandlers.removeItem(completionHandler);
					i--;
				}

				if(this.running > 0) {
					return;
				}
			}
		},
		abort: function() {
			this.cancel();
		},
		cancel: function() {
			this.enabled = false;

			for(var i = 0; i < this.runningAjax.length; i++) {
				var ajax = this.runningAjax[i];

				if(ajax.abort) {
					ajax.abort();
				}
			}
		},

		onComplete: onComplete,
		running: 0,
		runningExecute: 0,
		runningAjax: [],
		stack: [],
		argsStack: [],
		complete: false,
		concurrentAjax: 5,
		concurrentExecute: 1,
		completeCount: 0,
		errorCount: 0,
		total: 0,
		enabled: true,
		isExecutionChain: true
	}, options);

	return obj;
};
