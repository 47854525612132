$.BOOK_STATUSES = {
	'In Work': 1,
	'Submitted': 2,
	'Proof Out': 3,
	'Printed': 4,
	'Shipped': 5
};

$.Book = function(settings) {
	var obj = new $.FlowPageSet(settings);

	$.extend(obj, {
		load: function (events) {
			var me = this;
			this.db.load($.extend({
				onLoadGlobals: function (data) {
					me.status = data.status;
					if(me.status == 'Rework') {
						me.status = 'In Work';
					} else if(me.status == 'Submitted') {
						me.db.setOnlyCommentsAllowed(true);
					} else if(me.status == 'Locked') {
						me.db.setOnlyCommentsAllowed(true);
					} else if(!me.status && !$.getGETParams().bookFormatId) {
						$.plicAPI({
							method: 'projects/' + $.PlicProjectId + '/workflow-actions',
							params: {
								workflow_action: {
									plic_app_id: $.plicSlug,
									action: 'book_opened'
								}
							}
						});
					}
				}
			}, events));
		},
		onLoadGlobalData: function(data) {
			if(data.theme) {
				this.theme = data.theme;
			}
			if(data.pageNumberPosition) {
				this.pageNumberPosition = data.pageNumberPosition;
			}
			if(data.pageNumberCSS) {
				this.pageNumberCSS.css = data.pageNumberCSS;
			}
			if($.getProjectSetting('pageNumberHidden', false)) {
				this.getPageNumberPosition = null;
			}
			this.status = data.status;
			this.coverLocked = $.getProjectSetting('coverLocked', false);
			this.order = data.order;

			if(data.bookFormat) {
				$.bookFormat = data.bookFormat;
				this.layoutDimensions = data.bookFormat.definition;
			}
		},

		setPageNumberPosition: function (pageNumberPosition) {
			if (pageNumberPosition != this.pageNumberPosition && this.db) {
				this.db.queueChange({
					scope: 'yearbook',
					name: 'pageNumberPosition',
					value: pageNumberPosition
				});

				if(this.userEvents) {
					this.userEvents.addEvent({
						context: [this, 'pageNumberPosition'],
						action: 'update',
						args: [this.pageNumberPosition, pageNumberPosition]
					});
				}
			}

			this.pageNumberPosition = pageNumberPosition;
		},
		getPageNumberPosition: function () {
			return this.pageNumberPosition;
		},
		setPageNumberCSSStyles: function(styles) {
			var oldStyles = $.extend(true, {}, this.pageNumberCSS.css);
			this.pageNumberCSS.css = styles;

			obj.db.queueChange({
				scope: 'yearbook',
				name: 'pageNumberCSS',
				value: JSON.stringify(styles)
			});

			if(obj.userEvents) {
				obj.userEvents.addEvent({
					context: [obj, 'pageNumberCSS', 'css'],
					action: 'update',
					args: [oldStyles, styles]
				});
			}
		},
		getPageNumberCSS: function () {
			return this.pageNumberCSS;
		},
		getPageNumberFontSize: function() {
			var pageNumberCSS = this.getPageNumberCSS();
			var defaultPageNumberFont = this.getDefaultPageNumberFont();
			var fontSize = defaultPageNumberFont;
			if (pageNumberCSS.css['font-size']) {
				fontSize = parseFloat(pageNumberCSS.css['font-size'].replace('pt', ''));
			}

			return fontSize;
		},
		getPageNumberPadding: function() {
			// We get this again for legacy reasons -> there really is no point in doing a $.convertToPt
			var pageNumberCSS = this.getPageNumberCSS();
			var defaultPageNumberFont = this.getDefaultPageNumberFont();
			var fontSize = defaultPageNumberFont;
			if (pageNumberCSS.css['font-size']) {
				fontSize = $.convertToPt(parseFloat(pageNumberCSS.css['font-size'].replace('px', '').replace('pt', '')));
			}

			// We want margins that shrink as we go away from default size
			// As we get bigger there is a larger implicit margin based on font
			// As we get smaller we don't as large of a margin
			var divider = fontSize / this.getDefaultPageNumberFont();
			if (divider > 1) {
				divider = 1 - Math.log(divider);
			}
			return this.getDefaultPageNumberMargins() * divider;
		},
		getDefaultPageNumberFont: function() {
			return 8;
		},
		// Created new function because that old one is not tested and having trouble tracking everywhere it is used
		getDefaultPageNumberFontSize: function() {
			return 11;
		},
		getDefaultPageNumberMargins: function() {
			return 0.10;
		},

		getStatus: function () {
			if (this.status) {
				return this.status;
			} else {
				return 'In Work';
			}
		},
		setStatus: function (status) {
			if (status != this.status && this.db) {
				this.db.queueChange({
					scope: 'yearbook',
					name: 'status',
					value: $.BOOK_STATUSES[status]
				});

				if(this.userEvents) {
					this.userEvents.addEvent({
						context: [this, 'status'],
						action: 'update',
						args: [this.status, status]
					});
				}

				if(status == 'Submitted') {
					$.plicAPI({
						method: 'projects/' + $.PlicProjectId + '/workflow-actions',
						params: {
							workflow_action: {
								plic_app_id: $.plicSlug,
								action: 'book_submitted'
							}
						},
						success: function (data) {

						}
					});
				}
			}

			this.status = status;
		},
		setCoverLocked: function (coverLocked) {
			this.coverLocked = coverLocked;

			for(var i = 0; i < this.pages.length; i++) {
				var page = this.pages[i];
				if(page && (page.type == 'cover' || page.type == 'insideCover')) {
					page.setLocked(coverLocked);
				}
			}
		},
		getCoverLocked: function () {
			return this.coverLocked;
		},
		getCoreBookPageSet: function() {
			return new $.FlowPageSubSet(this, this.getCoreBookPages());
		},
		getCoreBookPages: function() {
			return this.pages.slice(2, this.pages.length - 2);
		},
		getSubmissionRenderTargets: function() {
			var renderTargets = {
				book: {
					pageSet: this.getCoreBookPageSet()
				}
			};

			if (!this.getCoverLocked()) {
				if ($.getStudioSetting('separateCoverRenders', false)) {
					renderTargets.frontCover = {
						pageSet: new $.FlowPageSubSet(this, [this.getFrontCoverPage()]),
						batchName: 'Front Cover'
					};

					renderTargets.backCover = {
						pageSet: new $.FlowPageSubSet(this, [this.getBackCoverPage()]),
						batchName: 'Back Cover'
					};

					renderTargets.book.waitFor = ['frontCover', 'backCover'];
				} else {
					renderTargets.cover = this.getCoverRenderTarget();
					renderTargets.book.waitFor = 'cover';
				}
			}

			return renderTargets;
		},
		getCoverRenderTarget: function() {
			if(this.pages.length < 4) {
				throw 'At least 4 pages are required.  Found ' + this.pages.length;
			}

			var layoutsPerPage = this.getLayoutsPerPage();
			var coverPages;
			if(layoutsPerPage > 1) {
				if($.getProjectSetting('allowContentInInsideCovers', false)) {
					coverPages = [this.getBackCoverPage(), this.getBackInsideCoverPage(), this.getFrontInsideCoverPage(), this.getFrontCoverPage()];
				} else {
					coverPages = this.getOutsideCoverPages();
				}
			} else {
				coverPages = this.getCoverPages();
			}

			return {
				pageSet: new $.FlowPageSubSet(this, coverPages),
				batchName: 'Covers',
				layoutsPerPage: layoutsPerPage
			};
		},
		getFrontCoverRenderTarget: function() {
			if(this.pages.length < 4) {
				throw 'At least 4 pages are required.  Found ' + this.pages.length;
			}

			return {
				pageSet: new $.FlowPageSubSet(this, [this.pages[0]]),
				batchName: 'Front Cover'
			};
		},
		getCoverPageSet: function() {
			return new $.FlowPageSubSet(this, this.getCoverPages());
		},
		getCoverPages: function () {
			return [this.getFrontCoverPage(), this.getFrontInsideCoverPage(), this.getBackInsideCoverPage(), this.getBackCoverPage()];
		},
		getOutsideCoverPageSet: function() {
			return new $.FlowPageSubSet(this, this.getOutsideCoverPages());
		},
		getOutsideCoverPages: function() {
			return [this.getBackCoverPage(), this.getFrontCoverPage()];
		},
		getFrontCoverPage: function() {
			return this.pages[0];
		},
		getFrontInsideCoverPage: function() {
			return this.pages[1];
		},
		getBackCoverPage: function() {
			return this.pages[this.pages.length - 1];
		},
		getBackInsideCoverPage: function() {
			return this.pages[this.pages.length - 2];
		},
		getLayoutsPerPage: function() {
			return 2;
		},
		getSpineLength: function() {
			var dimensions = this.getLayoutDimensions();
			if(dimensions) {
				return dimensions.spine;
			} else {
				return 0.25;
			}
		},
		removeEmptyPage: function() {
			for(var i = this.pages.length - 1; i >= 0; i--) {
				var page = this.pages[i];

				if(page.type == 'empty') {
					this.removePageCascade(page);
					return true;
				}
			}

			return false;
		},
		generateProductionPdf: function() {
			var render = new $.PageRender(this.getSubmissionRenderTargets(), {
				pageOffset: 1,
				production: true,
				completeAfterWorkerStarts: true,
				batchName: 'Proof',
				backgroundRender: true,
				onError: function () {
					$('#pageLoading').removeClass('active');
					$.Alert('Error', 'Failed to generate proof');
				}
			});

			$('#pageLoading').addClass('active');
			render.generatePDFFromPages(function () {
				$('#pageLoading').removeClass('active');
				$.Alert('Proof submitted', 'You will receive the proof by email when it is done generating', null, {
					headerIcon: 'trophy'
				});
			});

			return render;
		},
		renderSinglePage: function(page) {
			var isProduction = !!$.userPermissions.productionPDF;
			var render = new $.PageRender($.FlowPageSubSet(this, [page]), {
				pageOffset: (page.getPageNumber() % 2),
				production: isProduction,
				batchName: 'Page ' + (page.getPageNumber() - $.PAGE_OFFSET),
				backgroundRender: true,
				onError: function () {
					$.Alert('Error', 'Failed to render page');
				}
			});
			render.showPDFDialog();

			return render;
		},
		isSubmitted: function() {
			return this.status == 'Submitted';
		},
		showErrorIfPagesNotCorrect: function() {
			if(this.getPageLimit() > 0 && (this.getTotalPages() - 4) > this.getPageLimit()) {
				$.Alert('Error', 'You are past your allocated page limit and will not be able to submit this book.  You need to either remove candid pages or fit more subjects onto each class page.');
				return true;
			} else if(this.requireMultipleOfFour() && this.getTotalPages() % 4 != 0) {
				$.Alert('Error', 'Pages must be a multiple of 4');
				return true;
			} else if(!this.requireMultipleOfFour() && this.getTotalPages() % 2 != 0) {
				$.Alert('Error', 'Pages must be a multiple of 2');
				return true;
			} else if(this.getTotalPages() <= 4) {
				$.Alert('Error', 'You cannot submit an empty book');
				return true;
			}
			
			return false;
		},
		requireMultipleOfFour: function() {
			var layoutDimensions = this.getLayoutDimensions();
			if(layoutDimensions) {
				return layoutDimensions.requireMultiplesOfFour !== false && layoutDimensions.requireMultiplesOfFour !== 0;
			} else {
				return true;
			}
		},

		pageNumberPosition: 'center bottom',
		pageNumberCSS: new $.CSSBundle(function(name, value, oldValue) {
			obj.db.queueChange({
				scope: 'yearbook',
				name: 'pageNumberCSS',
				value: JSON.stringify(this.css)
			});

			if(obj.userEvents) {
				var oldObj = {};
				oldObj[name] = oldValue;
				var newObj = {};
				newObj[name] = value;

				obj.userEvents.addEvent({
					context: [obj, 'pageNumberCSS', 'css'],
					action: 'update',
					args: [oldObj, newObj]
				});
			}
		}),
		// Make all themes load for all book formats for now
		getLayoutAspectRatio: function() {
			return null;
		},
		canToggleContentLocks: function() {
			return false;
		},

		jobId: $.getGETParams().jobId,
		status: 0,
		pageMargins: {},
		bleedMask: true
	});

	obj.db = new $.BookDB(obj);

	return obj;
};