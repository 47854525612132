$.BookCandidPage = function(startSettings) {
	var obj = new $.FlowPage();
	obj._setFreeMovementLocked = obj.setFreeMovementLocked;
	var getLayout = obj.getLayout;

	$.extend(obj, {
		getLayout: function() {
			var layout = getLayout.apply(this, arguments);

			if(layout && layout.grid) {
				delete layout.grid;
			}

			return layout;
		},
		setFreeMovementLocked: function (locked) {
			this._setFreeMovementLocked(locked);

			this.lockOption.name = locked ? 'Unlock' : 'Lock';
		},
		updatePageLabel: function () {
			if(this.pageNumber == 3) {
				this.pageLabelDisplay = 'Title Page';
			} else if(this.type == 'cover') {
				// Don't change
			} else if(this.type == 'insideCover') {
				this.pageLabelDisplay = 'Inside Cover';
			} else {
				this.pageLabelDisplay = '';
			}

			var sidebar = [
				this.lockOption = {
					name: this.getFreeMovementLocked() ? 'Unlock' : 'Lock',
					icon: 'lock',
					onClick: function (page, layout) {
						var locked = !page.getFreeMovementLocked();
						page.setFreeMovementLocked(locked);

						$(this).html('<i class="' + (locked ? 'unlock' : 'lock') + ' icon"></i>' + (locked ? 'Unlock' : 'Lock'));
						layout.updateMovementLocked();
						layout.updateLabel();
					}
				}
			];

			// This block is duplicated for Classes!
			if (this.theme) {
				sidebar.push({
					name: 'Backgrounds',
					icon: 'setting',
					popup: 'Change settings on backgrounds',
					onClick: function (page, layout) {
						layout.openThemeSettings();
					}
				});

				sidebar.push({
					name: 'Revert Background',
					icon: 'picture',
					popup: 'Revert background back to theme',
					onClick: function (page, layout) {
						layout.clearBackground();
					}
				});
			} else if (this.pageSet && this.pageSet.getTheme() && this.pageSet.hasThemePart(this.getThemePartName())) {
				sidebar.push({
					name: 'Themes',
					icon: 'setting',
					popup: 'Change settings on theme',
					onClick: function (page, layout) {
						layout.openThemeSettings();
					}
				});
				sidebar.push({
					name: 'Clear Theme',
					icon: 'picture',
					popup: 'Clear theme on book',
					onClick: function (page, layout) {
						layout.clearTheme();
					}
				});
			}

			// If there are empty frames, add option to fill them
			var empty = false;
			for(var i in this.candids) {
				if (this.candids[i] && !this.candids[i].photo && !this.candids[i].shape) {
					empty = true;
					break;
				}
			}
			if (empty) {
				sidebar.push({
					name: 'Random Fill',
					icon: 'photo',
					popup: 'Fill empty frames with random candids',
					onClick: window.fillEmptyFrames
				});
			}

			sidebar.push({
				name: 'Save Layout',
				icon: 'paint brush',
				popup: 'Save this layout for use on other pages',
				onClick: function() {
					obj.saveCandidLayoutTemplate();
				}
			});

			if(this.type != 'cover' && this.type != 'insideCover' && obj.pageSet && obj.pageSet.renderSinglePage) {
				var previewOption = {
					name: 'Preview Page',
					icon: 'location arrow',
					popup: 'Preview PDF of just this page',
					onClick: function() {
						obj.pageSet.renderSinglePage(obj);
					}
				};
				if($.userPermissions.productionPDF) {
					$.extend(previewOption, {
						name: 'Render Page',
						popup: 'Render a production copy of just this page',
					});
				}
				
				sidebar.push(previewOption);
			}

			this.pageLabel = {
				display: this.pageLabelDisplay,
				popup: this.pageLabelPopup + (this.getFreeMovementLocked() ? ' (Locked)' : ''),
				sidebar: sidebar,
				icon: this.getFreeMovementLocked() ? 'lock' : 'setting'
			};
		},
		isCommentsEditable: function () {
			return false;
		},
		saveCandidLayoutTemplate: function() {
			$.SettingsBuilderDialog([
				{
					id: 'name',
					type: 'text',
					description: 'Layout Name',
					defaultValue: 'My Layout'
				},
				{
					id: 'saveWithCandids',
					type: 'checkbox',
					description: 'Save with candids'
				}
			], {
				title: 'Save Layout Template',
				onSettingsApplied: function(settings) {
					obj.getCustomerLayoutCategory(function(category) {
						var layout = obj.createLayoutTemplate({
							stripCandids: !settings.saveWithCandids
						});
						obj.createCandidLayoutTemplate(category, layout, settings.name);
					}, function() {
						$.Alert('Error', 'Failed to save layout template');
					});
				}
			});
		},
		createCandidLayoutTemplate: function(category, layout, layoutName) {
			$.ajax({
				url: 'ajax/createLayout.php',
				data: {
					categoryId: category.id,
					layoutName: layoutName,
					definition: JSON.stringify(layout)
				},
				type: 'POST',
				dataType: 'json',
				success: function(data) {
					// If already cached, add this layout to the cache
					$.layoutCategories.addItemToCategoriesCache(category, data.layout);

					// Force reload
					$.layoutCategories.categories.currentCategory = null;
					$.layoutCategories.slider.currentType = null;
					$.layoutCategories.setCategory(category);
				},
				error: function() {
					$.Alert('Error', 'Failed to save layout template');
				}
			});
		},
		getCustomerLayoutCategory: function(onComplete, onError) {
			var categories = $.layoutCategories.getCurrentCategories();
			var candidLayoutCategory = categories.getMatch({}, 'name', 'Candid Layouts');

			for(var i = 0; i < candidLayoutCategory.subCategories.length; i++) {
				var subCategory = candidLayoutCategory.subCategories[i];

				if(subCategory.load.posts.customerLayout) {
					onComplete(subCategory);
					return;
				}
			}

			var bookFormatIds = null;
			if($.bookFormat && $.bookFormat.formatId) {
				bookFormatIds = [$.bookFormat.formatId];
			}

			// Otherwise we need to create it
			$.ajax({
				url: 'ajax/createLayoutCategory.php',
				data: {
					orgId: $.OrgId,
					name: 'Your Layouts',
					bookFormatIds: bookFormatIds,
					returnDuplicate: true
				},
				type: 'POST',
				dataType: 'json',
				success: function(data) {
					var parentCategoryDiv = $($.layoutCategories).find('#layoutCategoryOptions').children(':contains("Candid Layouts")').children('.menu');

					var category = data.category;
					category.load = {
						url: 'getLayouts.php',
						posts: {
							type: 2,
							customerLayout: true,
							category: category.id
						},
						createNode: window.createLayout,
						onBeforeFirstLoad: function (data) {
							return data.results.sort(function (a, b) {
								if (a.title == b.title) {
									return 0;
								} else {
									return a.title < b.title ? -1 : 1;
								}
							});
						}
					};

					$.layoutCategories.addCategoryAlphabetical(category, parentCategoryDiv);
					onComplete(data.category);
				},
				error: function() {
					onError();
				}
			});
		},
		getThemePartName: function() {
			return 'Content';
		},
		getThemeStylePartName: function() {
			if(this.pageNumber === 3) {
				return 'Cover';
			} else {
				return this.getThemePartName();
			}
		},
		shouldAddCaptionsAutomatically: function(image, photo) {
			return !!photo.autoAddCaption;
		},

		type: 'candid',
		pageLabelDisplay: '',
		pageLabelPopup: 'Options',
		commentsEditable: true,
		layout: {}
	}, startSettings);

	obj.updatePageLabel();

	return obj;
};

$.YearbookCandidPage = $.BookCandidPage;