$.SubjectImageDialog = function(options) {
	options = $.extend({
		title: 'Crop Subject',
		cropName: 'Yearbook 8 x 10',
		subjectPhoto: 'yearbookPhoto',
		subjectCrop: 'yearbookCrop',
		cancelable: true,
		aspectRatio: 0.8,
		startCrop: undefined
	}, options);

	var modal = $('<div class="ui modal">')[0];
	if(options.cancelable) {
		$(modal).append('<i class="close icon"/>');
	}

	$.extend(modal, {
		initImage: function() {
			if(this.startCrop === undefined) {
				this.startCrop = this.subject[this.subjectPhoto][this.subjectCrop];
			}

			this.img = $('<img>').attr('src', this.getUrl()).css({
				height: '60vh'
			}).on('error', function() {
				modal.hide();
				$.Alert('Error', 'Failed to load image for cropping');
			})[0];

			var wrapper = this.wrapper = $('<div class="ui">').css({
				display: 'inline-block',
				'line-height': 0
			});
			wrapper.append(this.img).appendTo(this.content);

			this.loader = $('<div class="ui inverted dimmer"><div class="ui loader"></div></div>').appendTo(wrapper);
			this.loader.addClass('active');
		},
		initImageCrop: function() {
			this.cropper = new Cropper(this.img, {
				aspectRatio: this.aspectRatio,
				checkCrossOrigin: false,
				// Causing issues with some school firewalls when sending an xhr request that got redirected
				checkOrientation: false,
				guides: false,
				background: false,
				autoCropArea: 1,
				rotatable: false,
				zoomable: false,
				viewMode: 1,
				minCropBoxWidth: 40,
				minCropBoxHeight: 40,
				ready: function() {
					modal.loader.removeClass('active');

					if(!modal.firstRunComplete) {
						var existingCrop = modal.getScaledCrop();
						if(existingCrop) {
							modal.cropper.setCropBoxData(existingCrop);
						}
					}

					if(window.rotateImage && !modal.firstRunComplete && modal.onSavePhotoVersion) {
						var buttonBar = $('<div class="ui rotateButtons" style="margin-top: 1em;"></div>').appendTo(modal.wrapper);
						$('<div class="ui icon button" style="margin-top: 1em;" data-tooltip="Rotate left"><i class="undo icon"></i></div>').click(function() {
							modal.rotateImage(false);
						}).appendTo(buttonBar);
						$('<div class="ui icon button" style="margin-top: 1em;" data-tooltip="Rotate right"><i class="repeat icon"></i></div>').click(function() {
							modal.rotateImage(true);
						}).appendTo(buttonBar);
						modal.wrapper.append(' ');
					}

					if(modal.firstRunComplete) {
						if(modal.onCropReloadReady) {
							modal.onCropReloadReady.call(modal);
						}
					} else if(modal.onCropReady) {
						modal.onCropReady.call(modal);
					}
					modal.firstRunComplete = true;
				}
			});

			if(this.onInitImageCrop) {
				this.onInitImageCrop();
			}
		},
		getPhoto: function() {
			return this.subject[this.subjectPhoto];
		},
		getUrl: function() {
			var url;
			if(this.getPhoto()) {
				url = $.getPlicThumbnail(this.getPhoto(), {
					w: 600
				});
			} else if(this.subject.photo) {
				url = $.getPlicThumbnail(this.subject.photo, {
					w: 600
				});
			} else {
				url = 'css/images/error_placeholder.png';
			}

			return url;
		},
		getFullResUrl: function() {
			return $.getPlicDownloadUrl(this.subject.photo);
		},
		applyUserCrop: function() {
			if(!this.cropper || !this.cropper.ready) {
				return false;
			}

			var crop = this.getPercentCrop();

			if(this.onBeforeSave) {
				this.onBeforeSave.call(this, crop);
			}

			var photo = this.getPhoto();
			var newPhoto = null;
			var chain = new $.ExecutionChain(function(status) {
				if(modal.onAfterSave) {
					modal.onAfterSave.call(this);
				}
				if(!photo.photo_crops) {
					photo.photo_crops = [];
				}

				if(status.errors) {
					return;
				}

				if(!modal.startCrop || (photo[modal.subjectCrop] && modal.startCrop.id === photo[modal.subjectCrop].id)) {
					if(modal.cropName === 'Yearbook 8 x 10') {
						photo[modal.subjectCrop] = cropData.photo_crop;
					}

					if(!modal.startCrop) {
						photo.photo_crops.push(cropData.photo_crop);
					}
				}
				if(modal.startCrop) {
					var matchingCrop = photo.photo_crops.find(function(crop) {
						return crop.id === modal.startCrop.id;
					});
					if(matchingCrop) {
						$.extend(true, matchingCrop, cropData.photo_crop);
					}
				}

				if(modal.onSaveCrop) {
					modal.onSaveCrop.call(this, cropData.photo_crop);
				}

				if(newPhoto) {
					$.extend(true, photo, newPhoto);

					if(modal.onSavePhotoVersion) {
						modal.onSavePhotoVersion(newPhoto);
					}
				}
			});

			var cropData = null;
			var plicParams = {
				success: function(data) {
					cropData = data;
				},
				error: function() {
					$.Alert('Error', 'Failed to save crop');
				}
			};

			if(this.startCrop) {
				$.extend(plicParams, {
					method: 'photo-crops/' + this.startCrop.id,
					params: {
						photo_crop: {
							x: crop.left,
							y: crop.top,
							width: crop.width,
							height: crop.height
						}
					},
					type: 'PUT'
				});
			} else {
				$.extend(plicParams, {
					method: 'photos/' + this.subject.photo + '/photo-crops',
					params: {
						photo_crop: {
							name: this.cropName,
							x: crop.left,
							y: crop.top,
							width: crop.width,
							height: crop.height
						}
					},
					type: 'POST'
				});
			}
			chain.add($.getPlicAPI(plicParams));

			if(this.currentVersionImage && this.rotations !== 0) {
				chain.add($.getPlicAPI({
					method: 'photos/' + photo.id + '/reupload',
					success: function(data) {
						var formData = new FormData();
						var post = data.presigned_post;

						for(var id in post.fields) {
							formData.set(id, post.fields[id]);
						}
						formData.append('file', modal.currentVersionImage);
						
						chain.add({
							url: post.url,
							data: formData,
							type: 'POST',
							processData: false,
							contentType: false,
							success: function() {
								chain.add($.getPlicAPI({
									method: 'photos/' + photo.id + '/verify',
									type: 'PATCH',
									success: function(data) {
										newPhoto = data.photo;
										newPhoto.width = modal.currentVersionImage.imageWidth;
										newPhoto.height = modal.currentVersionImage.imageHeight;
									},
									error: function() {
										$.Alert('Error', 'Failed to verify new photo version');
									}
								}));
							},
							error: function() {
								$.Alert('Error', 'Failed to POST new version to S3');
							}
						});
					},
					error: function() {
						$.Alert('Error', 'Failed to create reupload record');
					}
				}));
			}

			chain.done();
		},
		getPercentCrop: function() {
			var rawCrop = this.cropper.getCropBoxData();
			var container = this.cropper.getContainerData();
			// When working with rotations, it isn't obeying the autoCropArea: 1 param.  This might be fine to apply to all crops, but afraid it will break something
			if(this.currentVersionImage && this.rotations !== 0) {
				container = this.cropper.getCanvasData();
			}

			return this.sanitizeRoundingCrop({
				left: $.reduceFloatPrecision(rawCrop.left / container.width),
				top: $.reduceFloatPrecision(rawCrop.top / container.height),
				width: $.reduceFloatPrecision(rawCrop.width / container.width),
				height: $.reduceFloatPrecision(rawCrop.height / container.height)
			});
		},
		sanitizeRoundingCrop: function(crop) {
			// According to js 0.05937502 + 0.2340824 = 1
			// PLIC throws an error because it is actually 1.00000002

			var left = new Decimal(crop.left);
			var width = new Decimal(crop.width);
			var top = new Decimal(crop.top);
			var height = new Decimal(crop.height);

			var leftDiff = left.plus(width).minus(1);
			if(leftDiff.greaterThan(0)) {
				crop.left = left.minus(leftDiff).minus(0.00000001).toNumber();
			}

			var topDiff = top.plus(height).minus(1);
			if(topDiff.greaterThan(0)) {
				crop.top = top.minus(topDiff).minus(0.00000001).toNumber();
			}

			crop.left = Math.max(0, crop.left);
			crop.top = Math.max(0, crop.top);

			return crop;
		},
		getScaledCrop: function() {
			if(this.startCrop) {
				var container = this.cropper.getContainerData();

				return {
					left: this.startCrop.x * container.width,
					top: this.startCrop.y * container.height,
					width: this.startCrop.width * container.width,
					height: this.startCrop.height * container.height
				};
			}
		},

		rotateImage: function(rotateRight) {
			modal.loader.addClass('active');

			var url;
			if(this.currentVersionImage) {
				url = URL.createObjectURL(this.currentVersionImage);
			} else {
				url = this.getFullResUrl();
			}

			window.rotateImage(url, rotateRight).then(function(rotatedImage) {
				modal.loader.removeClass('active');
				modal.cropper.replace(URL.createObjectURL(rotatedImage));
				modal.currentVersionImage = rotatedImage;
				
				if(rotateRight) {
					modal.rotations++;
				} else {
					modal.rotations--;
				}
			}).catch(function(e) {
				modal.loader.removeClass('active');
				$.Alert('Error', 'Failed to rotate image: ' + e.message);
			});
		},

		show: function() {
			$(this).modal('show');
		},
		hide: function() {
			$(this).modal('hide');
		},

		rotations: 0
	}, options);

	var header = $('<div class="header">');
	header.text(options.title);
	if(options.icon) {
		header.prepend('<i class="icon ' + options.icon + '">')
	}
	$(modal).append(header);

	var content = $('<div class="content">').css({
		'text-align': 'center'
	});
	modal.content = content;
	$(modal).append(content);
	modal.initImage();

	var buttons = $('<div class="actions">');
	if(modal.cancelable) {
		buttons.append('<div class="ui negative button">Cancel</div>');
	}
	buttons.append('<div class="ui positive button">OK</div>');
	$(modal).append(buttons);

	$(modal).modal({
		onVisible: function() {
			modal.initImageCrop();
		},
		onHidden: function() {
			$(this).remove();
		},
		onApprove: function() {
			return modal.applyUserCrop();
		},
		closable: modal.cancelable
	});
	return modal;
};