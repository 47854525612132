export default function createCanvas(width, height, options = {}) {
	let contextOptions = {
		willReadFrequently: options.willReadFrequently !== false
	};

	let canvas, context;
	if(window.OffscreenCanvas) {
		canvas = new OffscreenCanvas(width, height);
		context = canvas.getContext('2d', contextOptions);
	} else {
		canvas = document.createElement('canvas');
		canvas.width = width;
		canvas.height = height;
		context = canvas.getContext('2d', contextOptions);
	}

	return {
		canvas,
		context
	};
}