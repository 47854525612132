<template>
	<div class="field fieldField">
		<label>Field</label>
	</div>
</template>

<script>
export default {
	props: ['field', 'fields'],
	methods: {
		updateFieldName: function(field, value) {
			this.$emit('updateFieldName', field, value);
		}
	},
	mounted: function() {
		var fields = $.merge([], this.fields).filter(function(field) {
			return !!field && field !== 'photo_assignment' && field !== 'null';
		});
		if(fields.indexOf(this.field) === -1) {
			fields.push(this.field);
		}
		fields.caseInsensitiveSort();

		var me = this;
		this.$el.appendChild(this.fieldDropdown = $.pickDataDropdown({
			search: true,
			items: fields,
			defaultValue: this.field,
			setValueString: true,
			dropdownSettings: {
				forceSelection: false
			},
			onChange: function(value) {
				me.$emit('updateFieldName', me.field, value);
			}
		}));
	}
};
</script>

<style scoped>

</style>