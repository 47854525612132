$.fn.equals = function(compareTo) {
	if (!compareTo || this.length != compareTo.length) {
		return false;
	}
	for (var i = 0; i < this.length; ++i) {
		if (this[i] !== compareTo[i]) {
			return false;
		}
	}
	return true;
};

$.arrayEquals = function(mainObj, compareTo, exceptions, recursive) {
	if (!mainObj || !compareTo || mainObj.length != compareTo.length) {
		return false;
	}
	for (var i = 0; i < mainObj.length; ++i) {
		var start = mainObj[i];
		var end = compareTo[i];

		if($.isArray(start) && $.isArray(end)) {
			if(!$.arrayEquals(start, end, exceptions, recursive)) {
				return false;
			}
		} else if($.isPlainObject(start) && $.isPlainObject(end)) {
			if(!$.objectEquals(start, end, exceptions, recursive)) {
				return false;
			}
		} else if (start !== end) {
			return false;
		}
	}
	return true;
};

$.objectEquals = function(mainObj, compareTo, exceptions, recursive) {
	return $(mainObj).objectEquals(compareTo, exceptions, recursive);
};
$.fn.objectEquals = function(compareTo, exceptions, recursive) {
	var obj = this[0];
	if(!$.objectEqualsImpl(obj, compareTo, exceptions, recursive)) {
		return false;
	}

	// TODO: This should be optimized to only do the ones in compareTo but not in obj
	if(!$.objectEqualsImpl(compareTo, obj, exceptions, recursive)) {
		return false;
	}

	return true;
};
$.objectEqualsImpl = function(mainObj, compareTo, exceptions, recursive) {
	if(compareTo === undefined || compareTo === null) {
		return false;
	}

	for(var i in mainObj) {
		if(exceptions && (exceptions === i || exceptions.indexOf(i) != -1)) {
			continue;
		}

		var start = mainObj[i];
		var end = compareTo[i];
		if(recursive && $.isPlainObject(start) && $.isPlainObject(end)) {
			if(!$(start).objectEquals(end, exceptions, recursive)) {
				return false;
			}
		} else if(recursive && $.isArray(start) && $.isArray(end)) {
			if(!$.arrayEquals(start, end, exceptions, recursive)) {
				return false;
			}
		} else if(start !== end) {
			return false;
		}
	}

	return true;
};
$.stripCommonProperties = function(stripObject, matchObject) {
	stripObject = $.extend(true, {}, stripObject);

	for(var prop in stripObject) {
		if(stripObject[prop] === matchObject[prop]) {
			delete stripObject[prop];
		}
	}

	return stripObject;
};

$.fn.getClosestElement = function(offset) {
	var el = null, elOffset, x = offset.left, y = offset.top, distance, dx, dy, minDistance;
	this.each(function() {
		elOffset = $(this).position();

		if (
			(x >= elOffset.left)  && (x <= elOffset.right) &&
			(y >= elOffset.top)   && (y <= elOffset.bottom)
		) {
			el = $(this);
			return false;
		}

		var offsets = [[elOffset.left, elOffset.top], [elOffset.right, elOffset.top], [elOffset.left, elOffset.bottom], [elOffset.right, elOffset.bottom]];
		for(var off in offsets) {
			dx = offsets[off][0] - x;
			dy = offsets[off][1] - y;
			distance = Math.sqrt((dx*dx) + (dy*dy));
			if (minDistance === undefined || distance < minDistance) {
				minDistance = distance;
				el = $(this);
			}
		}
	});

	return el;
};

$.isInit = function(val) {
	return typeof val != 'undefined' && val !== null;
};

$.fn.isAttached = function(elem) {
	if(!elem) {
		elem = document.body;
	}
	if(!elem || !elem.contains) {
		return false;
	}

	return elem.contains(this[0]);
};

$.fn.findSelf = function(selector) {
	return this.find(selector).add($(this).filter(selector));
};

$.fn.getFloatStyle = function(style) {
	if(!this[0]) {
		return 0;
	}

	var elemStyle = this[0].style[style];
	if(typeof elemStyle != 'undefined' && elemStyle !== '') {
		return parseFloat(elemStyle.replace('px', '').replace('pt', ''));
	} else {
		return 0;
	}
};
$.fn.getPercentStyle = function(style) {
	if(!this[0]) {
		return 0;
	}

	var elemStyle = this[0].style[style];
	if(typeof elemStyle != 'undefined' && elemStyle !== '') {
		return parseFloat(elemStyle.replace('%', ''));
	} else {
		return 0;
	}
};
$.fn.getFloatAttribute = function(attribute) {
	if(!this[0]) {
		return 0;
	}

	var elemAttribute = this[0].getAttribute(attribute);
	if(typeof elemAttribute != 'undefined' && elemAttribute !== '' && elemAttribute !== null) {
		return parseFloat(elemAttribute.replace('px', '').replace('pt', ''));
	} else {
		return 0;
	}
};
$.fn.getPrefixedStyle = function(style) {
	if(!this[0]) {
		return 0;
	}

	var styles = this[0].style;
	if(styles['-webkit-' + style]) {
		return styles['-webkit-' + style];
	} else  {
		return styles[style];
	}
};

$.fn.copyStylesTo = function(elems) {
	if(!elems.length) {
		elems = $(elems);
	}

	var styles = this[0].style;
	for (var style in styles) {
		if (style != 'cssText' && styles[style] && typeof styles[style] == 'string') {
			for(var i = 0; i < elems.length; i++) {
				elems[i].style[style] = styles[style];
			}
		}
	}
};

$.fn.isRecursiveElem = function(type) {
	if(this[0].nodeName.toLowerCase() == type) {
		return true;
	} else if(this[0].childNodes.length == 1) {
		var child = this[0].childNodes[0];
		if(child.nodeName.toLowerCase() == type) {
			return true;
		} else {
			return $(child).isRecursiveElem(type);
		}
	} else {
		return false;
	}
};

$.fn.recursiveEach = function(func) {
	$(this).each(function() {
		func.apply(this, arguments);

		$(this).children().recursiveEach(func);
	});
};

$.getObjectCount = function(object, options) {
	if(!options) {
		options = {};
	}

	var size = 0, key;
	for (key in object) {
		// eslint-disable-next-line
		if(object.hasOwnProperty(key)) {
			if(options.includeUndefined !== false || object[key] !== undefined) {
				size++;
			}
		}
	}

	return size;
};

$.fn.flashRed = function() {
	this.css('background-color', 'red').animate({
		'background-color': ''
	}, 1000);
};
$.fn.shakeAnimation = function() {
	// This is to get the animation to display a second time
	$(this).stopAnimation();
	this[0].offsetWidth;

	$(this).css({
		'animation-duration': '1s',
		'animation-fill-mode': 'both',
		'animation-name': 'shake'
	});
};
$.fn.stopAnimation = function() {
	$(this).css({
		'animation-duration': '',
		'animation-fill-mode': '',
		'animation-name': ''
	});
};

$.fn.convertCalcToPx = function(prop, parentRect) {
	if(!parentRect) {
		parentRect = this[0].getBoundingClientRect();
	}

	var value = this[0].style[prop];
	if(value && value.indexOf('calc') != -1) {
		// TODO: Should look for both + and -
		var subtractValue = parseFloat(value.replace('calc(100% - ', '').replace('px)', ''));
		if(!isNaN(subtractValue)) {
			this[0].style[prop] = (parentRect[prop] - subtractValue) + 'px';
		}
	}
};

$.getFunctionArguments = function(func) {
	var fnStr = func.toString().replace(/((\/\/.*$)|(\/\*[\s\S]*?\*\/))/mg, '');
	var result = fnStr.slice(fnStr.indexOf('(')+1, fnStr.indexOf(')')).match(/([^\s,]+)/g);
	if(result === null)
		result = [];
	return result;
}