$.PreLoadImagesUtils = {
	forPageSet: function(pageSet) {
		var images = [];

		for(let i = 0; i < pageSet.pages.length; i++) {
			var page = pageSet.pages[i];
			$.merge(images, $.PreLoadImagesUtils.forPage(page));
		}

		for(let i = 0; i < pageSet.classes.length; i++) {
			var classObj = pageSet.classes[i];
			if(!classObj.active) {
				$.merge(images, $.PreLoadImagesUtils.forClassObj(classObj));
			}
		}

		$.PreLoadImages(images);
	},
	forPage: function(page) {
		var images = [];

		if(page.candids) {
			for(var id in page.candids) {
				var candid = page.candids[id];
				var image = $.PreLoadImagesUtils.forCandid(candid);
				if(image) {
					images.push(image);
				}
			}
		}

		if(page.classObj) {
			$.merge(images, $.PreLoadImagesUtils.forClassObj(page.classObj));

			if(page.extraClasses) {
				for(let i = 0; i < page.extraClasses.length; i++) {
					$.merge(images, $.PreLoadImagesUtils.forClassObj(page.extraClasses[i]));
				}
			}
		}

		return images;
	},
	forCandid: function(candid) {
		if(candid.photo) {
			if(candid.existingUrl) {
				return {
					url: candid.existingUrl
				};
			} else {
				var width = 300;
				if(candid.width > 5) {
					width = 750;
				} else if(candid.width > 3) {
					width = 450;
				}

				return {
					url: $.getPlicThumbnail({
						id: candid.photo,
						name: candid.photo_name
					}, {
						w: width
					})
				};
			}
		}
	},
	forClassObj: function(classObj) {
		var images = [];
		if(!classObj.subjects || !classObj.subjects.length) {
			return images;
		}

		for(let i = 0; i < classObj.subjects.length; i++) {
			var subject = classObj.subjects[i];
			var image = $.PreLoadImagesUtils.forSubject(subject);
			if(image) {
				images.push(image);
			}
		}

		return images;
	},
	forSubject: function(subject) {
		if(subject.photo) {
			var url;
			if (subject.photoCdnUrl) {
				url = subject.photoCdnUrl;
			} else {
				url = $.getPlicThumbnail(subject.photo, {
					w: 200
				});
			}

			return {
				url: url
			};
		}
	},
	executionChain: null,
	images: {}
};