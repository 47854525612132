<template>
	<div>
		<v-alert :type="messageType" class="text-left">
			<div class="text-h6">{{ i18n('uploader.uploadFinished') }}</div>
			<div>{{ messageContents }}</div>
		</v-alert>

		<v-simple-table v-if="failedUploads.length" class="text-left">
			<template v-slot:default>
				<thead>
					<tr>
						<th>{{ i18n('misc.filename') }}</th>
						<th>{{ i18n('misc.error') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="upload in failedUploads" :key="upload.id">
						<td>{{ upload.description }}</td>
						<td class="red--text">{{ upload.error }}</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>

		<p/>
		<v-btn color="primary" raised x-large @click="completeButton.action" v-if="completeButton">{{ completeButton.text }}</v-btn>

		<p/>
		<v-btn class="stop-button" color="error" raised large @click="stop" v-if="stopButton">{{ stopButton }}</v-btn>
	</div>
</template>

<script>
export default {
	name: 'UploadComplete',
	props: {
		completeMessage: {
			default() {
				return window.i18n.t('uploader.completeMessage');
			}
		},
		completeButton: null,
		uploads: null,
		stopButton: null
	},
	computed: {
		messageType() {
			if(this.failedUploads.length) {
				return 'error';
			} else {
				return 'success';
			}
		},
		messageContents() {
			if(this.failedUploads.length) {
				let validUploads = this.uploads.filter((upload) => {
					return !upload.skipUpload;
				});

				return this.i18n('uploader.failedUploadsMessage', {
					failedImages: validUploads.length - this.failedUploads.length,
					allImages: validUploads.length
				});
			} else {
				return this.completeMessage;
			}
		},
		failedUploads() {
			return this.uploads.filter((upload) => {
				return !!upload.error;
			});
		}
	},
	methods: {
		startOver() {
			this.$emit('start-over');
		},
		stop() {
			this.$emit('stop');
		}
	}
};
</script>