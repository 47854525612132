
let jszipModule = null;
export default {
	computed: {
		acceptedFiles() {
			return this.acceptedFileExtensions.map((extension) => {
				return '.' + extension;
			}).join(', ');
		},
		acceptedFileMimeTypes() {
			let mimeTypes = [...this.imageRequirements.validTypes];

			if(this.acceptedFileExtensions.includes('webp')) {
				mimeTypes.push('image/webp');
			}
			if(this.acceptedFileExtensions.includes('heic')) {
				mimeTypes.push('image/heic');
				mimeTypes.push('image/heif');
			}

			return mimeTypes.join(',');
		}
	},
	methods: {
		// Dropped file handling
		droppedFiles(event) {
			if(event.dataTransfer.items && event.dataTransfer.items.length && event.dataTransfer.items[0].webkitGetAsEntry) {
				let entries = Array.from(event.dataTransfer.items).map(item => item.webkitGetAsEntry());
				this.recursivelyReadFilesFromDataTransfer(entries).then((files) => {
					this.filesSelected(files);
				});
			} else {
				this.filesSelected(event.dataTransfer.files);
			}
		},
		recursivelyReadFilesFromDataTransfer: async function(entries) {
			let files = [];
			for(let i = 0; i < entries.length; i++) {
				let entry = entries[i];
				if(!entry) {
					continue;
				}

				if(entry.isFile) {
					files.push(await this.getFileFromEntry(entry));
				} else if(entry.isDirectory) {
					let reader = entry.createReader();
					let entries = await this.readFilesFromReader(reader);
					while(entries.length > 0) {
						files.push(...await this.recursivelyReadFilesFromDataTransfer(entries));
						entries = await this.readFilesFromReader(reader);
					}
				}
			}

			return files;
		},
		readFilesFromReader: async function(reader) {
			return await new Promise((resolve, reject) => {
				reader.readEntries(resolve, reject);
			});
		},
		getFileFromEntry: async function(entry) {
			return new Promise((resolve, reject) => {
				entry.file((file) => resolve(file), (error) => reject(error));
			});
		},


		// Standard file selection
		loadSelectedFiles: async function(files) {
			this.loadingMessage = null;
			files = Array.from(files);

			let withoutHiddenFiles = files.filter((file) => {
				return file.name.indexOf('._') !== 0 && file.name !== 'Thumbs.db';
			});
			if(withoutHiddenFiles.length) {
				files = withoutHiddenFiles;
			}

			let zipFiles = files.filter((file) => {
				let index = file.name.toLowerCase().indexOf('.zip');
				return index > 0 && index === file.name.length - 4;
			});
			for(let i = 0; i < zipFiles.length; i++) {
				let file = zipFiles[i];

				try {
					this.loadingMessage = 'Reading contents of zip file';
					if(!jszipModule) {
						jszipModule = await import(/* webpackChunkName: "jszip" */ 'jszip');
					}
					let JSZip = jszipModule.default;
					let zip = await JSZip.loadAsync(file);
					let zipObjects = Object.values(zip.files);
					for(let j = 0; j < zipObjects.length; j++) {
						this.loadingMessage = 'Reading contents of zip file: ' + (j + 1) + ' out of ' + zipObjects.length;
						let zipObject = zipObjects[j];
						if(zipObject.dir) {
							continue;
						}
						let zipBlob = await zipObject.async('blob');

						let fileType = '';
						if(zipObject.name.toLowerCase().endsWith('.jpg') || zipObject.name.toLowerCase().endsWith('.jpeg')) {
							fileType = 'image/jpeg';
						} else if(zipObject.name.toLowerCase().endsWith('.png')) {
							fileType = 'image/png';
						}

						let zipFile = new File([zipBlob], zipObject.name.replace(/^.*[\\/]/, ''), {
							type: fileType
						});

						files.push(zipFile);
					}
				} catch(e) {
					console.error('Failed to unzip due to ', e.message);
				}

				files.splice(files.indexOf(file), 1);
			}

			return files;
		}
	}
};