$.UserEventButtons = function(settings) {
	var buttons = $('<div id="sidebarUndoButtons" class="ui icon buttons">')[0];

	$.extend(buttons, {
		init: function() {
			this.initDisplay();

			this.userEvents.registerOnEventAdded(function() {
				buttons.updateButtons();
			});
			this.userEvents.registerOnEventUndone(function() {
				buttons.updateButtons();
			});
			this.userEvents.registerOnEventRedone(function() {
				buttons.updateButtons();
			});
		},
		initDisplay: function() {
			this.undoButton = $('<div class="ui icon blue disabled button undoButton"><i class="undo icon"></i></div>').attr('data-tooltip', i18n.t('composer.undo.undo'));
			this.undoButton.click(function() {
				buttons.userEvents.undoLastEvent();
			});

			this.redoButton = $('<div class="ui icon blue disabled button redoButton"><i class="repeat icon"></i></div>').attr('data-tooltip', i18n.t('composer.undo.redo'));
			this.redoButton.click(function() {
				buttons.userEvents.redoLastEvent();
			});

			$(this).append(this.undoButton).append(this.redoButton);
		},
		updateButtons: function() {
			if(this.userEvents.hasEventsToUndo()) {
				this.undoButton.removeClass('disabled');
			} else {
				this.undoButton.addClass('disabled');
			}

			if(this.userEvents.hasEventsToRedo()) {
				this.redoButton.removeClass('disabled');
			} else {
				this.redoButton.addClass('disabled');
			}
		},
		destroy: function() {
			$(buttons).remove();
		}
	}, settings);
	buttons.init();

	return buttons;
};