$.runTutorial = function(name, tutorial, manualRun) {
	if(tutorial) {
		// Already ran once
		if($.userExtras['tutorials'] && $.userExtras['tutorials'][name] && !$.userExtras['debugTutorials'] && !manualRun) {
			return;
		}

		for(var i = 0; i < tutorial.steps.length; i++) {
			var step = tutorial.steps[i];
			if(step.backupTarget && !$(step.target).length) {
				step.target = step.backupTarget;
			}
		}

		window.setTimeout(function () {
			hopscotch.startTour(tutorial, 0);

			if(!manualRun) {
				if(!$.userExtras['tutorials']) {
					$.userExtras['tutorials'] = {};
				}

				$.userExtras['tutorials'][name] = true;
				$.ajax({
					url: 'ajax/tutorialRead.php',
					dataType: 'json',
					data: {
						tutorial: name
					},
					type: 'POST'
				});
			}
		}, 10);
	}
};