$.FlowLayoutSVGEdge = function(options, instance) {
	var me = $.FlowLayoutSVG(options, instance);
	me._setEditable = me.setEditable;

	$.extend(me, {
		setEditable: function(editable) {
			this._setEditable(editable);
			this.setSnappable(editable);

			if(editable) {
				$(this).css('cursor', 'move');
			}
		},
		setSnappable: function(snappable) {
			if(snappable) {
				$(this).draggable({
					containment: 'parent',
					snap: '.flowPageContainer',
					snapMode: 'inner',
					snapTolerance: 40,
					iframeFix: true,
					start: function () {
						$(me).css({
							left: '',
							right: '',
							bottom: '',
							top: ''
						});
					},
					stop: function (event, ui) {
						var parent = ui.helper.parent();
						var mouse = ui.helper.position();

						var widthRight = parent.width() / 8 * 5;
						var widthCenter = parent.width() / 8 * 3;
						var height = parent.height() / 2;

						var left, top;
						if (mouse.left > widthRight) {
							left = 'right';
						} else if (mouse.left > widthCenter) {
							left = 'center';
						} else {
							left = 'left';
						}

						if(!me.snapPositions.top) {
							top = 'bottom';
						} else if(!me.snapPositions.bottom) {
							top = 'top';
						} else if (mouse.top > height || !me.snapPositions.top) {
							top = 'bottom';
						} else {
							top = 'top';
						}

						// Mirror picked side if doing from right page
						if (me.mirrorSides && left != 'center') {
							left = (left == 'right') ? 'left' : 'right';
						}
						var position = left + ' ' + top;
						if(position === 'right bottom') {
							position = 'center bottom';
						}

						if(me.onSnapChange) {
							me.onSnapChange(position);
						}
						this.lastDragTime = new Date().getTime();
					}
				}).css('cursor', 'move');
			} else {
				if($(this).hasClass('ui-draggable')) {
					$(this).draggable('destroy').css('cursor', 'default');
				}
			}
		},
		snapToPosition: function(position) {
			$(this).css('position', '');
			if(position == 'left bottom') {
				$(this).css({
					left: '0px',
					right: '',
					bottom: '0px',
					top: '',
					'-webkit-transform': '',
					transform: ''
				});
			} else if(position == 'right bottom') {
				$(this).css({
					left: '',
					right: '0px',
					bottom: '0px',
					top: '',
					'-webkit-transform': '',
					transform: ''
				});
			} else if(position == 'left top') {
				$(this).css({
					left: '0px',
					right: '',
					bottom: '',
					top: '0px',
					'-webkit-transform': '',
					transform: ''
				});
			} else if(position == 'right top') {
				$(this).css({
					left: '',
					right: '0px',
					bottom: '',
					top: '0px',
					'-webkit-transform': '',
					transform: ''
				});
			} else if(position == 'center bottom' && this.editable) {
				// We want to use easy page positioning for preview, but prince has trouble with this
				$(this).css({
					left: '50%',
					right: '',
					'-webkit-transform': 'translateX(-50%)',
					transform: 'translateX(-50%)',
					bottom: '0',
					top: '',
					width: '',
					height: ''
				});
			} else {
				// Clear any previous stuff
				$(this).css({
					left: '',
					right: '',
					bottom: '',
					top: '',
					'-webkit-transform': '',
					transform: ''
				});

				$(this).position({
					of: this.container,
					at: position,
					my: position
				});
			}

			if(position.indexOf('bottom') == -1) {
				$(this).find('.flowTextToolbar').css({
					top: '',
					bottom: '-2em'
				});
			} else {
				$(this).find('.flowTextToolbar').css({
					top: '-2em',
					bottom: ''
				});
			}
		},
		getEditToolsDistance: function() {
			return 10;
		}
	});

	if(me.editable) {
		me.setSnappable(true);
	}

	return me;
};