<template>
	<div>
		<v-subheader class="text-left headline">{{ i18n('uploader.uploadStatus') }}</v-subheader>

		<template v-if="errorMessage">
			<v-alert type="error" :value="true">{{ errorMessage }}</v-alert>
			<v-btn color="primary" raised x-large @click="startOver">{{ i18n('uploader.startOver') }}</v-btn>
		</template>
		<template v-else>
			<v-progress-linear :value="currentProgress" height="25">
				<template>
					{{ currentProgressPercent }}
				</template>
			</v-progress-linear>
			<div v-if="bitrate">{{ bitrateDisplay }}</div>

			<p/>
			<v-simple-table v-if="displayedUploads.length">
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">{{ i18n('misc.filename') }}</th>
							<th class="text-left">{{ i18n('misc.status') }}</th>
						</tr>
					</thead>
					<tbody>
						<upload-file-row
							v-for="upload in displayedUploads"
							:key="upload.id"
							:upload="upload"
							:album-id="albumId"
							:tags="tags"
							:photo-category="photoCategory"
							:album-import-id="albumImportId"
							v-on:upload-finished="uploadFinished"
							v-on:uploaded-bytes="uploadedBytes" />
					</tbody>
				</template>
			</v-simple-table>
		</template>
	</div>
</template>

<script>
import UploadFileRow from './UploadFileRow';
import { logAxiosError } from '../utils/axios';
import filesize from 'filesize';

export default {
	name: 'UploadProgress',
	props: {
		maxConcurrentUploads: null,
		isVisible: null,
		uploads: null,
		albumId: null,
		tags: null,
		photoCategory: null,
		createImportEvent: null,
		finishImportEvent: null
	},
	data() {
		return {
			albumImportId: null,
			errorMessage: null,
			uploadedInLastSecond: [],
			cancelled: false
		};
	},
	computed: {
		validUploads() {
			if(this.cancelled) {
				return [];
			}

			return this.uploads.filter((upload) => {
				return !upload.rejectReason && !upload.skipUpload;
			});
		},

		currentProgress() {
			let progress = this.validUploads.reduce((total, upload) => {
				if(upload.uploaded || upload.error) {
					total++;
				} else {
					total += upload.progress / 100;
				}

				return total;
			}, 0);
			
			return (progress / Math.max(1, this.validUploads.length)) * 100;
		},
		currentProgressPercent() {
			return this.currentProgress.toFixed(1) + '%';
		},

		uploaded() {
			return this.validUploads.filter((upload) => {
				return upload.uploaded || !!upload.error;
			});
		},
		uploading() {
			return this.validUploads.filter((upload) => {
				return upload.uploading;
			});
		},
		waitingUploads() {
			return this.validUploads.filter((upload) => {
				return !upload.uploaded && !upload.uploading && !upload.error && upload.waitFor.filter(waitFor => !waitFor.uploaded && !waitFor.error).length === 0;
			});
		},

		displayedUploads() {
			return this.validUploads.filter((upload) => {
				return upload.uploading || upload.error;
			});
		},
		bitrate() {
			// We want stuff that happened about 1 second ago, but to stay constant for an entire second
			const now = (new Date()).getTime() / 1000;
			const roundedNow = parseInt(now.toFixed(1));
			let uploadedInLastSecond = this.uploadedInLastSecond.filter((pair) => {
				let diff = roundedNow - pair.timestamp;

				return diff <= 1 && diff >= 0;
			});

			if(this.uploadedInLastSecond.length) {
				let bytes = uploadedInLastSecond.reduce((total, pair) => {
					return total + pair.bytes;
				}, 0);

				return filesize(bytes) + '/s';
			} else {
				return null;
			}
		},
		bitrateDisplay() {
			return this.i18n('uploader.uploadRate', {
				bitrate: this.bitrate
			})
		}
	},
	watch: {
		isVisible(value) {
			if(value) {
				this.errorMessage = null;
				this.startUploading();
			}
		}
	},
	methods: {
		startUploading() {
			this.uploadedInLastSecond = [];
			this.createImportEvent().then((albumImportId) => {
				this.albumImportId = albumImportId;
				this.addFilesToUploadQueue();
				if(this.uploaded.length === this.validUploads.length) {
					this.finishUploading();
				}
			}).catch((error) => {
				this.handleError(this.i18n('uploader.errors.albumImportStart'), error);
			});

			window.onbeforeunload = () => {
				if(this.uploading.length) {
					return 'An upload is in progress. If you leave right now, some images will not have finished uploading.'
				}
			}
		},
		addFilesToUploadQueue() {
			while(this.waitingUploads.length && this.uploading.length < this.maxConcurrentUploads) {
				this.startUpload(this.waitingUploads[0]);
			}
		},
		startUpload(upload) {
			upload.uploading = true;
		},
		uploadFinished(upload) {
			this.$emit('upload-finished', upload);
			this.addFilesToUploadQueue();

			if(this.uploaded.length === this.validUploads.length && this.albumImportId) {
				this.finishUploading();
			}
		},
		finishUploading() {
			this.finishImportEvent().then(() => {
				this.$emit('next-step');
				this.$emit('all-uploads-finished');
			}).catch((error) => {
				this.handleError(this.i18n('uploader.errors.albumImportFinish'), error);
			});

			window.onbeforeunload = null;
		},
		handleError(errorMessage, error) {
			this.errorMessage = errorMessage;
			console.error(errorMessage, error);

			if(error.response?.status !== 404) {
				logAxiosError(errorMessage, error);
			}
		},
		uploadedBytes(bytes) {
			const now = (new Date()).getTime() / 1000;
			this.uploadedInLastSecond.push({
				bytes,
				timestamp: now
			});

			// Constantly filter older events out
			this.uploadedInLastSecond = this.uploadedInLastSecond.filter((pair) => {
				return now - pair.timestamp < 3;
			});
		},
		startOver() {
			this.$emit('start-over');
		},
		cancel() {
			if(this.isVisible) {
				this.cancelled = true;
				this.uploadFinished();
			}
		}
	},
	components: {
		UploadFileRow
	},
	mounted() {
		if(this.isVisible) {
			this.startUploading();
		}
	},
	destroy() {
		window.onbeforeunload = null;
	}
};
</script>

<style scoped>
	
</style>