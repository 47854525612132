$.FlowPageOverflowModule = function(page, parentPage, overflowPage) {
	var _getExtraProperty = page.getExtraProperty;
	var _setExtraProperty = page.setExtraProperty;

	$.FlowPageOverflowRootModule(page, overflowPage);
	$.extend(page, {
		getRootPage: function() {
			var rootPage = this;
			while(rootPage.parentPage) {
				rootPage =  rootPage.parentPage;
			}
			return rootPage;
		},
		getParentPage: function() {
			return this.parentPage;
		},
		setExtraProperty: function(name, value) {
			var rootPage = this.getRootPage();
			if(this.updateRootPageProperties.indexOf(name) !== -1 && rootPage !== this) {
				return rootPage.setExtraProperty(name, value);
			} else {
				return _setExtraProperty.apply(this, arguments);
			}
		},
		getExtraProperty: function(name, defaultValue) {
			// If we are doing individual page rendering, this can be loaded with no rootPage
			var rootPage = this.getRootPage();
			if(this.updateRootPageProperties.indexOf(name) !== -1 && rootPage !== this) {
				return rootPage.getExtraProperty(name, defaultValue);
			} else {
				return _getExtraProperty.apply(this, arguments);
			}
		},
		
		updateRootPageProperties: ['grayscale']
	});

	if(page.getSubjects) {
		var _getSubjects = page.getSubjects;
		$.extend(page, {
			getSubjects: function() {
				if(this.parentPage) {
					return this.parentPage.getSubjects();
				} else {
					return _getSubjects.apply(this, arguments);
				}
			}
		});
	}

	if(parentPage) {
		page.parentPage = parentPage;
		page.parentPage.overflowPage = page;
	}
};

$.FlowPageOverflowRootModule = function(page, overflowPage) {
	$.extend(page, {
		setOverflowPage: function(overflowPage) {
			this.overflowPage = overflowPage;
		},
		getOverflowPage: function() {
			return this.overflowPage;
		}
	});

	if(overflowPage) {
		page.overflowPage = overflowPage;
		page.overflowPage.parentPage = page;
	}
};