$.FlowLayoutSVGPage = function(options, instance) {
	var me = new $.FlowLayoutSVGFree($.extend({}, options, {
		useOuterWrapper: false
	}));

	var _onUserTypes = me.onUserTypes;
	var _renderLines = me.renderLines;
	var _postRenderLines = me.postRenderLines;
	var _emptyLines = me.emptyLines;
	var _updateLines = me.updateLines;
	var _insertLine = me.insertLine;
	var _removeLine = me.removeLine;
	var _findWordWrapText = me.findWordWrapText;
	var _addStyleToSelection = me.addStyleToSelection;
	$.extend(me, {
		onUserTypes: function(e) {
			if(e.keyCode == 37 && this.selectionPosition === 0 && this.selectionLine === 0 && !this.selectionLength && this.wrapper.page.parentPage) {
				this.goToPage(this.wrapper.page.parentPage, true);
			} else if(e.keyCode == 8 && this.selectionPosition === 0 && this.selectionLine === 0 && !this.selectionLength && this.wrapper.page.parentPage) {
				var newPage = this.wrapper.page.parentPage;
				if(this.getCurrentLineLength() === 0) {
					this.instance.lines.splice(0, 1);
					this.changeInstanceProperty('lines', this.instance.lines);

					if(this.getLineCount() === 0) {
						this.wrapper.getPageSet().removePage(this.wrapper.page);
					}
				}

				this.goToPage(newPage, true);
			} else if(e.keyCode == 39 && this.selectionLine === this.getLastLineIndex() && this.selectionPosition === this.getCurrentLineLength() && !this.selectionLength && this.wrapper.page.overflowPage) {
				this.goToPage(this.wrapper.page.overflowPage);
			} else {
				// Do not want to try to do any page wrapping in the middle of a big wall of text paste
				try {
					this.skipCheckForPageOverflowTyping = true;
					this.shouldCheckForPageOverflowTyping = false;
					this.insertedLines = {};
					this.deletedLines = {};
					_onUserTypes.apply(this, arguments);
				} finally {
					this.skipCheckForPageOverflowTyping = false;
					delete this.insertedLines;
					delete this.deletedLines;
				}

				try {
					if(this.shouldCheckForPageOverflowTyping) {
						this.checkForPageOverflow();
					}
				} finally {
					this.shouldCheckForPageOverflowTyping = false;
				}
			}
		},
		updateLinesInUserTypes: function() {
			var startSkipCheckContentOverlap = this.skipCheckContentOverlap;
			this.skipCheckContentOverlap = true;
			this.forceFixAlignedTextNodes = true;
			this.updateLines();
			this.skipCheckContentOverlap = startSkipCheckContentOverlap;
			this.forceFixAlignedTextNodes = false;
		},
		noWordWrapInUserTypes: function() {
			this.checkContentOverlap(this.cachedTextNodes, this.cachedObjectAttributes);	
		},
		renderLines: function() {
			if(this.cachedTextNodes) {
				this.cachedOverrideWordWrapRightPositions = this.cachedTextNodes.arrayOfProperties('overrideWordWrapRightPosition');
				this.cachedIncrementX = this.cachedTextNodes.arrayOfProperties('cachedIncrementX');
			}

			_renderLines.apply(this, arguments);
		},
		postRenderLines: function(textNodes, objectAttributes) {
			_postRenderLines.apply(this, arguments);

			this.checkContentOverlap(textNodes, objectAttributes);

			if(this.instance.lines && this.instance.lines[0] && this.instance.lines[0].wordWrap) {
				this.instance.lines.forEach(function(line) {
					delete line.wordWrap;
				});
				this.recalculateWordWrapText(undefined, false, true);
				this.recalculateReverseWordWrap();
			}

			if((!this.skipCheckContentOverlap && !this.skipCheckWordWrapInContentOverlap) || this.forceFixAlignedTextNodes) {
				this.fixAlignedTextNodes(this.cachedTextNodes);
			}
		},
		checkContentOverlap: function(textNodes, objectAttributes, options) {
			options = $.extend(true, {
				redoFromWrap: false,
				redoFromUnwrap: false
			}, options);

			var removeInsertLines = false;
			if(!this.insertedLines) {
				this.insertedLines = {};
				this.deletedLines = {};
				removeInsertLines = true;
			}

			var page = this.getPage();
			var cachedOverrideWordWrapRightPositions;
			if(this.skipCheckContentOverlap) {
				if(this.cachedIncrementX) {
					this.updatedIncrementXInSkip = true;

					cachedOverrideWordWrapRightPositions = this.hardCachedOverrideWordWrapRightPositions || this.cachedOverrideWordWrapRightPositions;
					this.cachedIncrementX.forEach(function(cachedIncrementX, index) {
						if(index >= textNodes.length) {
							return;
						}
						
						var textNode = textNodes[index];
						if(cachedIncrementX) {
							var startingX = $(textNode).getFloatAttribute('x');
							textNode.setAttribute('x', startingX + cachedIncrementX);
							textNode.cachedIncrementX = cachedIncrementX;
						}
						
						if(cachedOverrideWordWrapRightPositions && cachedOverrideWordWrapRightPositions[index]) {
							textNode.overrideWordWrapRightPosition = cachedOverrideWordWrapRightPositions[index];
						}
					});
				}
				return;
			} else if(this.updatedIncrementXInSkip) {
				// This should only be needed from redoCheckContentOverlap combined with setting the overlap while skipped from above
				textNodes.forEach(function(textNode) {
					if(textNode.cachedIncrementX) {
						textNode.setAttribute('x', $(textNode).getFloatAttribute('x') - textNode.cachedIncrementX);
						delete textNode.cachedIncrementX;
						delete textNode.overrideWordWrapRightPosition;
					} else if(textNode.overrideWordWrapRightPosition) {
						delete textNode.overrideWordWrapRightPosition;
					}
				});
			}
			this.updatedIncrementXInSkip = false;

			var contents = me.wrapper.getMovableContent();
			textNodes.forEach(function(textNode) {
				textNode.cachedBoundingClientRect = $.extend(true, {}, textNode.cachedBoundingClientRect);
			});

			var checkWordWrapped = false, redoCheckContentOverlap = false;
			var editorHeightIncrease = 0;
			var editorWidthIncrease = 0;
			var extraPadding = 0.25 * this.ratio;
			var wordWrapBottomPosition = this.getWordWrapBottomPosition();
			var wrapperRect = this.wrapper.container.getBoundingClientRect();
			if(this.getBoundingClientRect().width > (wrapperRect.width + 4)) {
				checkWordWrapped = true;
			}

			contentLoop:
			for(var contentIndex = 0; contentIndex < contents.length; contentIndex++) {
				var content = contents[contentIndex];
				var contentRect = $.extend(true, {}, content.getBoundingClientRect());
				if(content.maxExtraBottomRight) {
					contentRect.height += content.maxExtraBottomRight;
					contentRect.width += content.maxExtraBottomRight;
				} else if(content.instance && content.instance.captionImageId) {
					var captionImage = this.wrapper.getFrame(content.instance.captionImageId);
					if(captionImage && captionImage.maxExtraBottomRight) {
						contentRect.width += captionImage.maxExtraBottomRight;
					}
				}

				var contentTop = contentRect.y;
				var contentBottom = contentRect.y + contentRect.height;
				// console.log('content: ' + contentTop + ' => ' + contentBottom);
				var contentLeft = contentRect.x;
				var contentRight = contentLeft + contentRect.width;
				// console.log('content: ' + contentLeft + ' => ' + contentRight);

				var contentPercentLeft = (contentLeft - objectAttributes.svgEditorRect.left) / me.ratio / me.wrapper.inchContentSize.width;
				var contentPercentRight = (contentRight - objectAttributes.svgEditorRect.left) / me.ratio / me.wrapper.inchContentSize.width;
				var contentIsMoreRight = (1 - contentPercentRight) > contentPercentLeft;
				var otherContentPercentLeft = 0;
				var otherContentPercentRight = 0;
				for(var textIndex = 0; textIndex < textNodes.length; textIndex++) {
					var textNode = textNodes[textIndex];
					var textTop = textNode.cachedBoundingClientRect.y;
					var textBottom = textTop + textNode.cachedBoundingClientRect.height;
					// console.log('text' + textIndex + ': ' + textTop + ' => ' + textBottom);
					var textLeft = textNode.cachedBoundingClientRect.x;
					var textRight = textLeft + textNode.cachedBoundingClientRect.width;
					// console.log('text ' + textIndex + ': ' + textLeft + ' => ' + textRight);

					// We are going to wrap onto the next page anyways
					if(wordWrapBottomPosition < textBottom) {
						break;
					}
					
					var contentTopInText = contentTop > textTop && contentTop < textBottom;
					var textTopInContent = textTop > contentTop && textTop < (contentBottom - 1);
					if(contentTopInText || textTopInContent) {
						// If something is already taking up one side, assume it is enough to take up that whole side
						if(textNode.cachedIncrementX) {
							otherContentPercentRight = textNode.cachedIncrementX / me.ratio / me.wrapper.inchContentSize.width;
						}
						if(textNode.overrideWordWrapRightPosition) {
							otherContentPercentLeft = (textNode.overrideWordWrapRightPosition - objectAttributes.svgEditorRect.left) / me.ratio / me.wrapper.inchContentSize.width;
						}

						// Skip lines that takes up most of the width of the page
						var contentBlocksWholeLine = (contentPercentLeft < 0.2 && contentPercentRight > 0.8) ||
							(otherContentPercentRight && !contentIsMoreRight && (contentPercentLeft - otherContentPercentRight) < 0.2) ||
							(otherContentPercentLeft && contentIsMoreRight && (otherContentPercentLeft - contentPercentRight) < 0.2);
						if(contentBlocksWholeLine) {
							var incrementHeight = contentRect.height;
							if(contentTop > textTop) {
								incrementHeight += contentTop - textTop;
							} else if(contentTop < textTop) {
								// Top of content is past where the top of the text that it is blocking
								incrementHeight -= textTop - contentTop;
							}

							editorHeightIncrease += incrementHeight;
							this.shouldCheckForPageOverflow = true;
							if(me.incrementTextNodesHeight(textNodes, textIndex, incrementHeight)) {
								contentIndex = -1;
								checkWordWrapped = false;
								continue contentLoop;
							}
						}
						else {
							// Content is more on the left then the right
							if(contentIsMoreRight) {
								var incrementWidth = contentRect.width + $(content).getFloatStyle('left') + extraPadding;
								var checkTextLeft = textLeft;
								var checkTextRight = textRight;
								var totalIncrementWidth = incrementWidth;
								if(textNode.cachedIncrementX < incrementWidth) {
									checkTextLeft -= textNode.cachedIncrementX;
									checkTextRight -= textNode.cachedIncrementX;
									incrementWidth -= textNode.cachedIncrementX;
								}

								// Only increment line if we are overlapping, but either way need to record where it blocks the text from going further right
								if((checkTextRight > contentLeft && checkTextRight < contentRight) || (contentLeft >= (checkTextLeft - 1) && contentRight < checkTextRight) ||
									(contentRight > checkTextLeft && contentRight < checkTextRight)) {
									textNode.setAttribute('x', $(textNode).getFloatAttribute('x') + incrementWidth);
									textNode.cachedBoundingClientRect.x += incrementWidth;
									editorWidthIncrease = Math.max(editorWidthIncrease, totalIncrementWidth);
								}

								textNode.cachedIncrementX = totalIncrementWidth;
							}
							// Content is more on the right
							else {
								textNode.overrideWordWrapRightPosition = Math.min(contentLeft - extraPadding, textNode.overrideWordWrapRightPosition || 1000000000000);
							}
							
							checkWordWrapped = true;
						}
					}
				}
			}

			if(editorHeightIncrease) {
				this.svgEditor.setAttribute('height', $(this.svgEditor).getFloatAttribute('height') + editorHeightIncrease);
			}

			if(this.skipCheckWordWrapInContentOverlap || !this.editable) {
				return;
			}

			var startSkipCheckForPageOverflow, startSkipCheckContentOverlap, startSkipCheckWordWrapInContentOverlap;
			if(this.cachedOverrideWordWrapRightPositions) {
				var newOverrideWordWrapRightPositions = this.cachedTextNodes.arrayOfProperties('overrideWordWrapRightPosition');
				var newIncrementX = this.cachedTextNodes.arrayOfProperties('cachedIncrementX');

				this.lastWordWrapRightPosition = this.getWordWrapRightPosition();
				cachedOverrideWordWrapRightPositions = this.cachedOverrideWordWrapRightPositions;
				var cachedIncrementX = this.cachedIncrementX;
				this.fixCachedPositionsFromInsertedLines(cachedOverrideWordWrapRightPositions, cachedIncrementX);

				if(this.shouldCheckReverseWordWrap(cachedOverrideWordWrapRightPositions, newOverrideWordWrapRightPositions, cachedIncrementX, newIncrementX)) {
					this.manualLastWordWrapRightPosition = cachedOverrideWordWrapRightPositions;

					startSkipCheckForPageOverflow = this.skipCheckForPageOverflow;
					startSkipCheckContentOverlap = this.skipCheckContentOverlap;
					try {
						this.skipCheckForPageOverflow = this.skipCheckContentOverlap = true;
						if(this.recalculateReverseWordWrap()) {
							redoCheckContentOverlap = true;
							options.redoFromUnwrap = true;
						}
					} finally {
						this.skipCheckContentOverlap = startSkipCheckContentOverlap;
						this.skipCheckForPageOverflow = startSkipCheckForPageOverflow;
					}

					if(!this.insertedLines || redoCheckContentOverlap) {
						checkWordWrapped = false;
					}
				}
				delete this.hardCachedOverrideWordWrapRightPositions;
			} else if(textNodes[0] && textNodes[0].line && textNodes[0].line.recalculateReverseWordWrap) {
				startSkipCheckForPageOverflow = this.skipCheckForPageOverflow;
				startSkipCheckContentOverlap = this.skipCheckContentOverlap;
				try {
					this.skipCheckForPageOverflow = this.skipCheckContentOverlap = true;

					// Set each line we want to recalculate to check any reverse of word wrap, and skip other lines
					this.lastWordWrapRightPosition = this.getWordWrapRightPosition();
					this.manualLastWordWrapRightPosition = textNodes.map(function(textNode) {
						return textNode.line.recalculateReverseWordWrap ? 1 : null;
					});
					textNodes.forEach(function(textNode) {
						delete textNode.line.recalculateReverseWordWrap;
					});

					if(this.recalculateReverseWordWrap()) {
						redoCheckContentOverlap = true;
						checkWordWrapped = false;
					} else {
						// Save the deletion of the recalculate check
						this.changeInstanceProperty('lines', this.instance.lines);
					}
				} finally {
					this.skipCheckContentOverlap = startSkipCheckContentOverlap;
					this.skipCheckForPageOverflow = startSkipCheckForPageOverflow;
				}
			}

			if(checkWordWrapped) {
				startSkipCheckForPageOverflow = this.skipCheckForPageOverflow;
				startSkipCheckWordWrapInContentOverlap = this.skipCheckWordWrapInContentOverlap;
				try {
					this.skipCheckForPageOverflow = true;
					this.skipCheckWordWrapInContentOverlap = true;

					var startSelectionPosition = this.selectionPosition;
					var startSelectionLine = this.selectionLine;
					var startSelectionLength = this.selectionLength;
					startSkipCheckContentOverlap = this.skipCheckContentOverlap;
					if(this.findWordWrapText(0, 0, this.getInstanceLength())) {
						redoCheckContentOverlap = true;
						this.skipCheckContentOverlap = true;
						this.updateLines();
					}

					this.selectionPosition = startSelectionPosition;
					this.selectionLine = startSelectionLine;
					this.selectionLength = startSelectionLength;
				} finally {
					this.skipCheckForPageOverflow = startSkipCheckForPageOverflow;
					this.skipCheckWordWrapInContentOverlap = startSkipCheckWordWrapInContentOverlap;
					this.skipCheckContentOverlap = startSkipCheckContentOverlap;

					if(!this.skipCheckForPageOverflow && this.shouldCheckForPageOverflow) {
						this.checkForPageOverflow();
					}
				}
			}
			
			if(redoCheckContentOverlap) {
				this.checkContentOverlap(this.cachedTextNodes, objectAttributes, options);
			} else if(this.shouldCheckForPageOverflow || page.getExtraProperty('forceCheckForPageOverflow')) {
				this.checkForPageOverflow();
			}

			if(removeInsertLines) {
				delete this.insertedLines;
				delete this.deletedLines;
			}
		},
		incrementTextNodesHeight: function(textNodes, textIndex, incrementHeight) {
			var startOver = false;
			for(var i = textIndex; i < textNodes.length; i++) {
				var textNode = textNodes[i];
				textNode.setAttribute('y', $(textNode).getFloatAttribute('y') + incrementHeight);
				textNode.cachedBoundingClientRect.y += incrementHeight;
				textNode.cachedBoundingClientRect.bottom += incrementHeight;

				if(textNode.cachedIncrementX) {
					textNode.setAttribute('x', $(textNode).getFloatAttribute('x') - textNode.cachedIncrementX);
					textNode.cachedBoundingClientRect.x -= textNode.cachedIncrementX;
					delete textNode.cachedIncrementX;
					startOver = true;
				}
				if(textNode.overrideWordWrapRightPosition) {
					delete textNode.overrideWordWrapRightPosition;
					startOver = true;
				}
			}

			return startOver;
		},

		fixCachedPositionsFromInsertedLines: function(cachedOverrideWordWrapRightPositions, cachedIncrementX) {
			if(!this.insertedLines) {
				return;
			}

			var i;
			for(var newLineIndex in this.insertedLines) {
				var newLines = this.insertedLines[newLineIndex];

				for(i = 0; i < newLines; i++) {
					cachedOverrideWordWrapRightPositions.splice(newLineIndex, 0, null);
					cachedIncrementX.splice(newLineIndex, 0, null);
				}
			}

			for(var deletedLineIndex in this.deletedLines) {
				var deletedLines = this.deletedLines[deletedLineIndex];

				cachedOverrideWordWrapRightPositions.splice(deletedLineIndex, deletedLines);
				cachedIncrementX.splice(deletedLineIndex, deletedLines);
			}

			this.insertedLines = {};
			this.deletedLines = {};
		},
		shouldCheckReverseWordWrap: function(oldRightPositions, newRightPositions, oldIncrementX, newIncrementX) {
			// When the image is on the left, we want to update to allow reverse wrap to only hit what was previously done
			var i;
			for(i = 0; i < Math.min(oldRightPositions.length, newRightPositions.length, oldIncrementX.length, newIncrementX.length); i++) {
				if(!oldRightPositions[i] && !newRightPositions[i] && oldIncrementX[i] && (!newIncrementX[i] || newIncrementX[i] < oldIncrementX[i])) {
					oldRightPositions[i] = this.lastWordWrapRightPosition - oldIncrementX[i];
				} else if(oldRightPositions[i] && newIncrementX[i] < oldIncrementX[i]) {
					oldRightPositions[i] -= oldIncrementX[i] - newIncrementX[i];
				}
			}

			for(i = 0; i < Math.min(oldRightPositions.length, newRightPositions.length); i++) {
				if(this.debouncedRightPositionDiff[i]) {
					// Want to delete cache either way, but only change old position if we have one
					if(oldRightPositions[i]) {
						oldRightPositions[i] -= this.debouncedRightPositionDiff[i];
					}
					delete this.debouncedRightPositionDiff[i];
				}
			}

			// If we went from having a right position from the image to not, then we should reverse the wrap
			// We should also update if right position has increased a lot so we have more room then before
			for(i = 0; i < Math.min(oldRightPositions.length, newRightPositions.length); i++) {
				var rightPositionDiff = newRightPositions[i] - oldRightPositions[i];
				if(oldRightPositions[i]) {
					if(!newRightPositions[i] || rightPositionDiff > 10) {
						return true;
					} else if(rightPositionDiff > 0) {
						if(this.debouncedRightPositionDiff[i]) {
							this.debouncedRightPositionDiff[i] += rightPositionDiff;
						} else {
							this.debouncedRightPositionDiff[i] = rightPositionDiff;
						}
					}
				}
			}

			return false;
		},
		fixAlignedTextNodes: function(textNodes) {
			// TODO: Figure out a good way to only run this alignment once for performance reasons
			var wordWrapRightPosition;
			for(var i = 0; i < textNodes.length; i++) {
				var textNode = textNodes[i];
				if(!textNode.cachedIncrementX && !textNode.overrideWordWrapRightPosition) {
					continue;
				}

				var line = textNode.line;
				switch(line.align) {
					case 'center':
						// TODO: Implement
						break;
					case 'right':
						// TODO: Implement
						break;
					case 'justify':
						var leadingTextSpace = $(textNode).getFloatAttribute('x');
						if(textNode.overrideWordWrapRightPosition) {
							wordWrapRightPosition = this.getWordWrapRightPosition(wordWrapRightPosition);
							if(wordWrapRightPosition > textNode.overrideWordWrapRightPosition) {
								leadingTextSpace += wordWrapRightPosition - textNode.overrideWordWrapRightPosition;
							}
						}
						this.justifyTextNode(textNode, leadingTextSpace);
						break;
				}
			}
		},

		updateLines: function() {
			_updateLines.apply(this, arguments);
			this.checkForPageOverflow();
		},
		checkForPageOverflow: function() {
			if(this.skipCheckForPageOverflow) {
				this.shouldCheckForPageOverflow = true;
				return;
			}
			if(this.skipCheckForPageOverflowTyping) {
				this.shouldCheckForPageOverflowTyping = true;
				return;
			}
			if(this.wrapper.parent.skipCheckForPageOverflow) {
				return;
			}

			// If we switched pages don't try to overflow!
			var page = this.wrapper.getPage();
			if(this.instance.pageId !== page.id) {
				return;
			}

			this.shouldCheckForPageOverflow = false;

			var wordWrapBottomPosition = this.getWordWrapBottomPosition();
			var lastNode = this.cachedTextNodes[this.cachedTextNodes.length - 1];

			if(wordWrapBottomPosition < lastNode.cachedBoundingClientRect.bottom && this.cachedTextNodes.length > 1) {
				var textNodes = [lastNode];
				for(var i = this.cachedTextNodes.length - 2; i >= 0; i--) {
					var textNode = this.cachedTextNodes[i];
					if(wordWrapBottomPosition < textNode.cachedBoundingClientRect.bottom) {
						textNodes.unshift(textNode);
					} else {
						break;
					}
				}

				this.moveLinesToNextPage(textNodes);
			}

			if(page.getExtraProperty('forceCheckForPageOverflow')) {
				page.setExtraProperty('forceCheckForPageOverflow', false);
			}
		},
		moveLinesToNextPage: function(textNodes) {
			$(textNodes).remove();
			textNodes.forEach(function(textNode) {
				var index = me.instance.lines.indexOf(textNode.line);
				if(index === -1) {
					for(var i = 0; i < me.instance.lines.length; i++) {
						if($.objectEquals(me.instance.lines[i], textNode.line, [], true)) {
							index = i;
						}
					}
				}
				if(index === -1) {
					console.error('Not actually removing the line!');
					return;
				}

				me.cachedTextNodes.splice(index, 1);
				me.instance.lines.splice(index, 1);
			});
			this.changeInstanceProperty('lines', this.instance.lines);

			var page = this.wrapper.getPage();
			var lines = textNodes.arrayOfProperties('line');

			// Mark lines to be recalculated so we don't have bogus word wrap
			lines.forEach(function(line) {
				line.recalculateReverseWordWrap = true;
			});

			page.addLinesToOverflowPage(lines);

			if(this.focused && this.selectionLine >= this.getLineCount()) {
				this.goToPage(page.overflowPage, lines.length - 1);
			} else {
				this.updateOverflowDisplay();
			}
		},
		goToPage: function(newPage, focusOnLine) {
			this.setFocused(false);
			this.flushDelayedSaveQueue();
			var layout;
			try {
				this.wrapper.parent.skipCheckForPageOverflow = true;
				layout = this.wrapper.parent.goToPage(newPage, true);
			
				var newFocus = $(layout).find('.flowLayoutChapterText')[0];
				newFocus.setFocused(true);
				if(focusOnLine === true) {
					newFocus.selectionLine = newPage.pageText.length - 1;
					newFocus.selectionPosition = newFocus.getCurrentLineLength();
					newFocus.updateCursorPosition();
				} else {
					if(focusOnLine) {
						newFocus.selectionLine = focusOnLine;
						newFocus.selectionPosition = newFocus.getCurrentLineLength();
						newFocus.updateCursorPosition();
					}

					delete this.wrapper.parent.skipCheckForPageOverflow;
					newFocus.checkForPageOverflow();
				}
			} finally {
				delete this.wrapper.parent.skipCheckForPageOverflow;
			}
		},
		getWordWrapBottomPosition: function() {
			var parentNode = this.parentNode;
			if (this.wordWrapTextBounds) {
				parentNode = this.wordWrapTextBounds;
			}
			if(!parentNode) {
				return null;
			}

			var pageNumberHeight = this.wrapper.getPageNumberHeight();
			return parentNode.getBoundingClientRect().bottom - pageNumberHeight;
		},

		emptyLines: function() {
			_emptyLines.apply(this, arguments);

			// Without this the cached rects are wrong because FF starts the text boxes centered in SVG without an explicit size
			me.svgEditor.setAttribute('width', 0);
			me.svgEditor.setAttribute('height', 0);
		},

		insertLine: function(newLine, newLineIndex, render) {
			var linesTextNodes = _insertLine.apply(this, arguments);

			// During typing we want to keep track of these
			if(this.insertedLines) {
				this.insertedLines[newLineIndex] = (this.insertedLines[newLineIndex] || 0) + 1;
			}

			if(render) {
				var textNode = linesTextNodes[0];
				var nextTextNode = textNode.nextSibling;
				if(nextTextNode && $(nextTextNode).hasClass('clone')) {
					nextTextNode = nextTextNode.nextSibling;
				}
				
				while(nextTextNode && $.trim(nextTextNode.textContent)) {
					// Remove the increment before inheriting whatever the next lines increment is
					if(textNode.cachedIncrementX) {
						textNode.setAttribute('x', $(textNode).getFloatAttribute('x') - textNode.cachedIncrementX);
					}

					textNode.overrideWordWrapRightPosition = nextTextNode.overrideWordWrapRightPosition;
					textNode.cachedIncrementX = nextTextNode.cachedIncrementX;
					if(textNode.cachedIncrementX) {
						textNode.setAttribute('x', $(textNode).getFloatAttribute('x') + textNode.cachedIncrementX);
					}

					textNode = nextTextNode;
					nextTextNode = textNode.nextSibling;
					if(nextTextNode && $(nextTextNode).hasClass('clone')) {
						nextTextNode = nextTextNode.nextSibling;
					}
				}
			}

			return linesTextNodes;
		},
		removeLine: function(deletedLineIndex) {
			var value = _removeLine.apply(this, arguments);

			// During typing we want to keep track of these
			if(this.deletedLines) {
				this.deletedLines[deletedLineIndex] = (this.deletedLines[deletedLineIndex] || 0) + 1;
			}
			
			if(this.wrapper.page.takeLinesFromOverflowPage(this.instance, 1)) {
				this.updateOverflowDisplay();
			}

			return value;
		},
		updateOverflowDisplay: function() {
			this.wrapper.parent.updatePagesAfterChange(this.wrapper.page.overflowPage);
		},
		findWordWrapText: function() {
			var startSkipCheckForPageOverflow = this.skipCheckForPageOverflow;
			this.skipCheckForPageOverflow = true;
			try {
				return _findWordWrapText.apply(this, arguments);
			} finally {
				this.skipCheckForPageOverflow = startSkipCheckForPageOverflow;
			}
		},
		addStyleToSelection: function(selections, name, value) {
			// Try to add text style to the next pages as well that match
			if(!$.isArray(selections)) {
				selections = [selections];
			}
			var currentValue;
			if(selections[0].parts) {
				currentValue = selections[0].parts[0][name];
			} else {
				currentValue = selections[0][name];
			}
			var isSelectingSection = this.selectionLength > 0;
			var page = this.wrapper.getPage();
			if(!isSelectingSection && page.overflowPage) {
				if(page.overflowPage.addStyleToPageTextRecursively(name, currentValue, value)) {
					this.updateOverflowDisplay();
				}
			}

			_addStyleToSelection.apply(this, arguments);
		},

		wordWrapRightPadding: 2,
		debouncedRightPositionDiff: [],
		defaultFontSize: 12
	});

	$(me).removeClass('flowAlignTarget').addClass('flowLayoutChapterText');
	if(instance) {
		me.setInstance(instance);
	}

	return me;
};