$.BookChapterPage = function(startSettings) {
	var page = new $.BookCandidPage();
	var _updateRandomTextThemeStyles = page.updateRandomTextThemeStyles;

	$.extend(page, {
		resetBlankTitle: function(title) {
			if(title) {
				this.title = title;
			}

			this.applyDefaultTitleStyle();
		},
		getLockedTexts: function() {
			var textTop = this.getTitleInchHeight();

			var defaultFontSize = 12;
			var defaultStyles = {
				'font-size': defaultFontSize + 'pt',
				'align': 'justify'
			};
			var themeStylePart = this.getThemeStylePart();
			if(themeStylePart && themeStylePart.randomText) {
				$.extend(true, defaultStyles, themeStylePart.randomText);
			}

			var textLines = this.pageText || [];
			if(!textLines.length && !this.parentPage) {
				textLines.push({
					text: ''
				});
			}
			var lines = textLines.map(function(line) {
				if(typeof line === 'string') {
					line = {
						text: line
					};
				}

				return $.extend(true, {}, defaultStyles, line);
			});

			return [
				{
					id: page.id + '-text',
					pageId: page.id,
					type: 'label',
					position: {
						left: 0,
						top: textTop
					},
					lines: lines,
					editable: true,
					svgConstructor: $.FlowLayoutSVGPage,
					useParentWidth: true,
					lineHeight: this.getExtraProperty('textLineHeight') || $.DEFAULT_LINE_HEIGHT
				}
			];
		},
		setLockedTextProperty: function(id, name, value) {
			if($.isArray(name)) {
				name.forEach(function(name, index) {
					page.setLockedTextProperty(id, name, value[index]);
				});
			} else if(name === 'lines') {
				this.jsonReplace('pageText', $.extend(true, [], value));
			} else if(name === 'lineHeight') {
				this.setExtraProperty('textLineHeight', value);
				this.setForceCheckForPageOverflow();
			}
		},

		getLockedOptions: function() {
			return [
				'styles',
				'align-all',
				'font-family',
				'font-size',
				'text-colors',
				'line-height',
				{
					addClass: 'icon',
					group: ['stroke', 'drop shadow']
				}
			];
		},
		getLockedSettings: function() {
			var fontSizes = $.merge([], $.DefaultFontSizes);
			for(var i = 0; i < fontSizes.length; i++) {
				if(fontSizes[i] > 40) {
					fontSizes.splice(i, fontSizes.length - i);
					break;
				}
			}

			return {
				defaultFontSizes: fontSizes
			};
		},
		setLockedFontStyle: function(instance, name, value) {
			this.setLabelStyle(name, value);
			if(name == 'font-size') {
				return 'forceRefresh';
			} else {
				return 'label';
			}
		},
		getLabelStyle: function() {
			return {};
		},
		refreshLockedTextsOnMove: function() {
			return true;
		},

		addLinesToOverflowPage: function(lines) {
			if(!this.overflowPage) {
				this.overflowPage = new $.BookChapterOverflowPage(this);
				this.pageSet.addPage(this.overflowPage, this.pageNumber - 1);
			}

			var overflowLines = $.merge([], this.overflowPage.pageText);
			var i;
			for(i = lines.length - 1; i >= 0; i--) {
				overflowLines.splice(0, 0, lines[i]);
			}

			var recursiveOverflowLines;
			if(this.overflowPage.overflowPage) {
				recursiveOverflowLines = overflowLines.splice(overflowLines.length - lines.length, lines.length);
			}

			this.overflowPage.setLockedTextProperty('id', 'lines', overflowLines);

			if(recursiveOverflowLines) {
				this.overflowPage.addLinesToOverflowPage(recursiveOverflowLines);
			} else {
				this.overflowPage.setExtraProperty('forceCheckForPageOverflow', true);
			}
		},
		takeLinesFromOverflowPage: function(instance, count) {
			if(!this.overflowPage) {
				return false;
			}

			var pageText = this.pageText;
			var overflowLines = $.merge([], this.overflowPage.pageText);
			var lines = overflowLines.splice(0, count);
			lines.forEach(function(line) {
				pageText.push(line);

				if(instance) {
					instance.lines.push(line);
				}
			});

			if(overflowLines.length === 0) {
				this.pageSet.removePage(this.overflowPage);
			} else {
				this.overflowPage.setLockedTextProperty('id', 'lines', overflowLines);
				this.overflowPage.takeLinesFromOverflowPage(null, count);
			}

			return lines.length > 0;
		},
		updateRandomTextThemeStyles: function(oldStyles, newStyles) {
			_updateRandomTextThemeStyles.apply(this, arguments);

			if(oldStyles || newStyles) {
				var newLines = this.updateTextObjectThemeStyles(this.pageText, oldStyles, newStyles);
				if(newLines) {
					if(newLines[0]) {
						newLines[0].wordWrap = true;
					}
					
					this.jsonReplace('pageText', newLines);
				}
			}
		},

		addStyleToPageTextRecursively: function(name, oldValue, newValue) {
			var saveChanges = false;
			var lines = $.extend(true, [], this.pageText);
			if(name === 'lineHeight') {
				return !!this.overflowPage;
			}

			loop1:
			for(var i = 0; i < lines.length; i++) {
				var line = lines[i];

				var parts = line.parts ? line.parts : [line];
				if(name === 'align') {
					parts = [line];
				}

				for(var j = 0; j < parts.length; j++) {
					var part = parts[j];

					if(part[name] == oldValue || part[name] === undefined) {
						saveChanges = true;
						part[name] = newValue;
					} else {
						break loop1;
					}
				}
			}

			if(saveChanges) {
				this.jsonReplace('pageText', lines);

				if(this.overflowPage) {
					this.overflowPage.addStyleToPageTextRecursively(name, oldValue, newValue);
				}

				return true;
			} else {
				return false;
			}
		},
		setForceCheckForPageOverflow: function() {
			if(this.overflowPage) {
				this.overflowPage.setExtraProperty('forceCheckForPageOverflow', true);
				this.overflowPage.setForceCheckForPageOverflow();
			}
		},

		type: 'chapter',
		pageText: [],
		blockRefreshingMatchingRootPages: true
	}, startSettings);
	$.FlowPageOverflowRootModule(page);

	return page;
};