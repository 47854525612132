$.FlowLayoutSVGFree = function(options, instance) {
	var me = $.FlowLayoutSVG(options);
	me._updateLines = me.updateLines;
	me._setEditable = me.setEditable;
	me._setFocused = me.setFocused;

	var setInstance = me.setInstance;
	var refreshInstance = me.refreshInstance;
	var changeSecondaryInstanceProperty = me.changeSecondaryInstanceProperty;

	$.extend(me, {
		setInstance: function(instance) {
			if(instance && !instance.schema) {
				instance.schema = this.maxSchema;
			}

			if(this.hasDynamicText(instance) && !this.focused && instance.schema >= 2) {
				this.focused = true;
				this.notUserFocused = true;
				setInstance.apply(this, arguments);
				var startWidth = this.getBoundingClientRect().width;

				this.focused = false;
				this.notUserFocused = false;
				setInstance.apply(this, arguments);

				var endWidth = this.getBoundingClientRect().width;
				var sizeDifference = (endWidth - startWidth) / 2;
				var left = $(this).getFloatStyle('left');
				$(this).css('left', left - sizeDifference);
			} else {
				setInstance.apply(this, arguments);
			}
		},
		refreshInstance: function() {
			refreshInstance.apply(this, arguments);
			this.updateRelativeElements();
			this.updateLockedTooltip();
			this.updateOtherTooltip();

			if(this.focused || this.secondaryFocused || $(this).hasClass('flowLayoutSecondaryFocused')) {
				if(this.resizable) {
					if(this.instance.locked) {
						this.setResizable(false);
					} else {
						this.setResizable(true);
					}
				}
				if(this.movable) {
					if(this.instance.locked) {
						this.lockedMovement = true;
					} else {
						this.lockedMovement = false;
					}
					this.setMovable(this.movable);
				}
			} else {
				if(this.resizable) {
					this.setResizable(false);
				}
			}
			if(this.focused && this.textEditable && this.setCursorEnabled) {
				if(this.instance.locked) {
					this.setCursorEnabled(false);
				} else if(!this.notUserFocused) {
					this.setCursorEnabled(true);
				}
			}
		},
		updateLines: function() {
			this._updateLines.apply(this, arguments);

			if(this.instance.manualSize) {
				var manualSize = this.instance.manualSize;
				var updateSize = false;

				var svgHeight = this.renderedHeight;
				var manualHeight = $(this).getFloatStyle('height');
				if(svgHeight > manualHeight && !this.isFreelyResized()) {
					if(!this.instance.collageTitle) {
						$(this).css('height', '');
						delete manualSize.height;
					} else {
						manualSize.height = svgHeight / this.ratio;
					}
					updateSize = true;
				}

				/*var svgWidth = this.renderedWidth;
				 var manualWidth = $(this).getFloatStyle('width');
				 if(svgWidth > manualWidth) {
				 $(this).css('width', '');
				 delete manualSize.width;
				 updateSize = true;
				 }*/

				if(updateSize) {
					this.updateProperty('manualSize', manualSize);
				}
			}

			this.wrapper.onFlowChange(this);
		},
		// For legacy implementation compat
		setMovementLocked: function(movementLocked) {
			if (this.movementLocked == movementLocked) {
				return;
			}
			this.movementLocked = movementLocked;
			this.setEditable(!movementLocked);
		},
		setMovable: function(movable) {
			this.movable = movable;

			if(this.editable && !this.lockedMovement && movable) {
				if(!$(this).hasClass('flowLayoutMovable')) {
					$(this).addClass('flowLayoutMovable');

					$(this).draggable({
						// containment: 'parent',
						start: function (event, ui) {
							if(this.ignoreNextDrag) {
								this.ignoreNextDrag = false;
								return false;
							}
							// Opening the spread breaks this movement - user has to start again.  This probably isn't only fixable after migrating away from jQuery UI
							if(this.wrapper?.checkIfContentShouldTriggerWrap?.(this.instance.id)) {
								return false;
							}

							var left = $(this).getFloatStyle('left');
							var top = $(this).getFloatStyle('top');

							var clickData = $(this).data('uiDraggable').offset.click;
							clickData.left += (ui.originalPosition.left - left);
							clickData.top += (ui.originalPosition.top - top);

							$(this).data('startPosition', {
								left: left,
								top: top
							});
							if($.userEvents) {
								$.userEvents.startGroupedEvents();
							}
						},
						stop: function (event, ui) {
							if(this.instance.manualSize) {
								if(!this.instance.manualSize.width) {
									$(this).css('width', '');
								}

								if(!this.instance.manualSize.height) {
									$(this).css('height', '');
								}
							} else {
								$(this).css({
									width: '',
									height: ''
								});
							}

							var positionSaved = false;
							var removed = false;
							if(this.parentNode) {
								var myRect = this.getBoundingClientRect();
								var parentRect = this.getParentRect();
								// Completely invisible
								removed = this.removeIfMovedToOtherPage(myRect, parentRect, true);

								// Should not be allowed to rest in margins
								if(this.dontAllowStopInMargins) {
									if(removed && this.linkedOverflowInstance) {
										var linkedInstance = this.linkedOverflowInstance.instance;
										var linkedLayout = this.linkedOverflowInstance.layout;
										var linkedElem = linkedLayout.getContent(linkedInstance.id);
										if(linkedElem) {
											var linkedParentRect = linkedElem.getParentRect();
											var linkedRect = linkedElem.getBoundingClientRect();
											if(linkedRect.left < linkedParentRect.left) {
												// Move out of left margins
												var left = 0;
												var top = $(linkedElem).css('top');
												$(linkedElem).animate({
													left: left + 'px',
													top: top + 'px'
												});
		
												linkedElem.saveCurrentPosition({
													position: linkedElem.getPositionFromCss({
														left: left,
														top: top
													})
												});
											} else if(linkedRect.right > linkedParentRect.right) {
												// Move out of right margins
												left = linkedParentRect.width - linkedRect.width - 2;
												top = $(linkedElem).css('top');
												$(linkedElem).animate({
													left: left + 'px',
													top: top + 'px'
												});
		
												linkedElem.saveCurrentPosition({
													position: linkedElem.getPositionFromCss({
														left: left,
														top: top
													})
												});
											}
										}
									}
									// We need to check bottom/top as well just in case there is another bug that allows them to be sometimes dragged off edge
									else if(!removed && (myRect.left < parentRect.left || myRect.right > parentRect.right || myRect.top < parentRect.top || myRect.bottom > parentRect.bottom) && (myRect.width < parentRect.width)) {
										// Revert back to previous position
										var start = $(this).data('startPosition');

										$(this).animate({
											left: start.left + 'px',
											top: start.top + 'px'
										});

										this.saveCurrentPosition({
											position: this.getPositionFromCss(start)
										});
										this.removeOverflowFromLayout();
										positionSaved = true;
									}
								}
							}

							if(!removed) {
								if(!positionSaved) {
									this.saveCurrentPosition();
								}

								this.stopMoving();
							}

							this.lastDragTime = new Date().getTime();

							if($.userEvents) {
								$.userEvents.stopGroupedEvents();
							}
						},
						drag: function (event, ui) {
							this.movingContent(ui.position);
							this.updateEditToolbar();
							this.saveCurrentPosition();
						}
					});
				}
			} else {
				if($(this).hasClass('flowLayoutMovable')) {
					$(this).removeClass('flowLayoutMovable');

					if ($(this).hasClass('ui-draggable')) {
						$(this).draggable('destroy');
					}
				}
			}
		},
		removeIfMovedToOtherPage: function(myRect, parentRect, recursive) {
			var removed;
			var secondaryElements = this.getSecondaryFocusedElements();
			if(this.linkedOverflowInstance && this.isContentHidden(myRect, parentRect)) {
				// If we copied an instance over to next page, get rid of this version
				this.wrapper.removeText(this);
				removed = true;
			}

			if(recursive) {
				secondaryElements.forEach(function(secondaryElement) {
					if(secondaryElement.removeIfMovedToOtherPage) {
						secondaryElement.removeIfMovedToOtherPage(secondaryElement.getBoundingClientRect(), parentRect);
					}
				});
			}

			return removed;
		},
		showPosition: function(x, y) {
			x += this.getDynamicSizeDiff();
			$(this).css({
				left: x,
				top: y
			});
		},
		getPositionFromCurrent: function() {
			var x = $(this).getFloatStyle('left') + 1 - (this.extraOffset || 0);
			var y = $(this).getFloatStyle('top') + 1 - (this.extraOffset || 0);
			x -= this.getDynamicSizeDiff();

			return {
				x: x / this.ratio,
				y: y / this.ratio
			};
		},
		getDynamicSizeDiff: function(requireFocused) {
			if(this.hasQRCode()) {
				return 0;
			}

			if(typeof requireFocused == 'undefined') {
				requireFocused = false;
			}

			var width = 0;
			if(this.focused == requireFocused && this.hasDynamicText() && this.instance.schema >= 2 && (!this.instance.manualSize || !this.instance.manualSize.width)) {
				var startWidth = this.svgEditor.getBoundingClientRect().width;

				this.focused = !requireFocused;
				this.refreshInstance();

				var endWidth = this.svgEditor.getBoundingClientRect().width;
				width = (endWidth - startWidth) / 2;

				this.focused = requireFocused;
				this.refreshInstance();
			}

			return width;
		},

		setFocused: function(focused, userClicked) {
			var startWidth;
			if(this.shouldRefreshOnFocus()) {
				startWidth = this.getBoundingClientRect().width;
			}
			if(this.editable && focused && userClicked) {
				var myRect = this.getBoundingClientRect();
				var parentRect = this.getParentRect();
				if(this.isContentHidden(myRect, parentRect)) {
					this.fixHiddenContent(myRect, parentRect);
				}
			}
			this._setFocused(focused, userClicked);
			if(startWidth) {
				var endWidth = this.getBoundingClientRect().width;
				var sizeDifference = (endWidth - startWidth) / 2;
				var left = $(this).getFloatStyle('left');
				$(this).css('left', left - sizeDifference);

				if(this.instance.schema == 1) {
					this.changeInstanceProperty(['schema', 'position'], [2, {
						left: (left - sizeDifference + 1 - this.extraOffset) / this.ratio,
						top: this.instance.position.top
					}]);
				}
			}

			if(focused) {
				if(this.resizable && !this.instance.locked && userClicked) {
					this.setResizable(true);
				}
				if(this.movable && !this.instance.locked && userClicked) {
					if($(this).hasClass('flowLayoutMovable')) {
						$(this).draggable('disable');
					}
				}
				if(this.rotatable) {
					this.setRotable(true);
				}
			} else {
				if(this.resizable) {
					this.setResizable(false);
				}
				if(this.movable) {
					if($(this).hasClass('flowLayoutMovable')) {
						$(this).draggable('enable');
					}
				}
				if(this.rotatable) {
					this.setRotable(false);
				}
			}
		},
		onSetSecondaryFocused: function(focused, linkedInstance) {
			if(focused) {
				if(this.resizable) {
					this.setResizable(true);
				}
				if(this.rotatable) {
					this.setRotable(true);
				}

				if(linkedInstance) {
					this.setCursorEnabled(true);
				}
			} else {
				if(this.resizable) {
					this.setResizable(false);
				}
				if(this.rotatable) {
					this.setRotable(false);
				}

				if(linkedInstance) {
					this.setCursorEnabled(false);
				}
			}
		},
		getResizableWidth: function() {
			if(this.isFreelyResized()) {
				return this.minimumBoxBounds;
			} else {
				return this.getMaxWordWidth();
			}
		},
		getMaxWordWidth: function() {
			// This happens when using Apply To All or any function which refreshes the instance
			if(!$(this.svgEditor).isAttached()) {
				return this.minimumBoxBounds;
			}

			var maxWidth = 0;
			$(this.svgEditor).find('tspan').each(function() {
				var textContent = this.textContent;
				var words = textContent.split(/\s/g);

				if(words.length > 1) {
					try {
						for(var i = 0; i < words.length; i++) {
							var word = words[i];
							this.textContent = word;
							maxWidth = Math.max(maxWidth, this.getComputedTextLength());
						}
					} finally {
						this.textContent = textContent;
					}
				} else {
					maxWidth = Math.max(maxWidth, this.getComputedTextLength());
				}
			});

			return Math.max(this.minimumBoxBounds, maxWidth);
		},
		getResizableHeight: function() {
			if(this.isFreelyResized()) {
				return this.minimumBoxBounds;
			} else {
				return this.renderedHeight;
			}
		},
		recalculateManualSize: function() {
			var borderThickness = this.getBorderThickness();
			var manualSize = {
				width: $(this).getFloatStyle('width') - (borderThickness * 2),
				height: $(this).getFloatStyle('height') - (borderThickness * 2)
			};

			var isAutoSized = this.isFreelyResized();
			manualSize.width = manualSize.width / this.ratio;
			if(manualSize.height <= Math.ceil(this.renderedHeight) && !isAutoSized) {
				delete manualSize.height;
				$(this).css('height', '');
			} else {
				manualSize.height = manualSize.height / this.ratio;
			}

			this.updateProperty('manualSize', manualSize);
		},
		setEditable: function(editable) {
			if(editable && this.instance && this.instance.locked) {
				if(!this.canToggleLock || Math.max(this.instance.locked.userRank, 3) < $.UserRank || (this.instance.locked === true && $.UserRank > 3)) {
					editable = false;
				}
			}

			this._setEditable(editable);
			this.lockedMovement = (this.instance && !!this.instance.locked);
			this.setMovable(this.movable);
		},
		getEditToolsDistance: function() {
			return this.getRotatableToolDistance();
		},
		changeSecondaryInstanceProperty: function(name, value) {
			changeSecondaryInstanceProperty.apply(this, arguments);

			if(name == 'position') {
				return;
			}

			this.withLinkedElem(function(layout, elem) {
				elem.changeInstanceProperty(name, value, false);
				elem.updateCursorPositionToMatch(me);
				elem.refreshInstance();
			});
		},

		contentType: 'text-free',
		movable: true,
		movementLocked: false,
		resizable: true,
		rotatable: true,
		wordWrapText: true,
		containInParent: true,
		minimumBoxBounds: 10,
		multipleSelect: true,
		multipleSelectProperties: [
			'transform',
			'locked'
		]
	}, options);
	$(me).addClass('flowFreeText flowAlignTarget');

	$.FlowLayoutResizable(me, {
		handlesOutsideBounds: true
	});
	me.registerOnStartResize(function(e) {
		this.lastWordWrapRightPosition = this.getWordWrapRightPosition();

		this.minWidth = this.getResizableWidth();
		this.minHeight = this.getResizableHeight();

		if($.userEvents) {
			$.userEvents.startGroupedEvents();
		}
	});
	me.registerOnResize(function(e) {
		this.wrapper.onFlowChange(this);

		$.setSingleTimeout.call(this, 'resizeCalculateSize', function() {
			this.recalculateManualSize();
			this.refreshInstance();
			this.recalculateWordWrapText(undefined, undefined, true);
			this.recalculateReverseWordWrap();
			this.lastWordWrapRightPosition = this.getWordWrapRightPosition();
			this.checkOverflowToLinkedLayouts();

			// this.minWidth = this.getResizableWidth();
			this.minHeight = this.getResizableHeight();
		}, 10);
	});
	me.registerOnStopResize(function(e) {
		$.clearSingleTimeout.call(this, 'resizeCalculateSize');
		this.recalculateManualSize();

		this.wrapper.onFlowStop(this);
		this.refreshInstance();
		this.recalculateWordWrapText(undefined, undefined, true);
		this.recalculateReverseWordWrap();
		this.checkOverflowToLinkedLayouts();

		if(this.linkedOverflowInstance && this.isContentHidden()) {
			// If we copied an instance over to next page, get rid of this version
			this.wrapper.removeText(this);
		}

		delete this.lastWordWrapRightPosition;

		if($.userEvents) {
			$.userEvents.stopGroupedEvents();
		}
	});

	me.registerOnStartRotate(function(event) {
		if(this.textEditable) {
			this.setCursorEnabled(false);
		}
	});
	me.registerOnRotate(function(event) {
		this.changeInstanceProperty('transform', this.style.transform);
	});
	me.registerOnStopRotate(function() {
		if(this.textEditable) {
			this.setCursorEnabled(true);
		}
	});

	if(instance) {
		me.setInstance(instance);
	}
	if(me.editable) {
		me.setEditable(true);
	}
	// Don't need to call this if also editable since already called in setEditable
	else if(me.movable) {
		me.setMovable(true);
	}

	return me;
};