$.fn.LoadImage = function(url, options) {
	var elem = this[0];

	options = $.extend({
		url: url,
		retryTimes: 2,
		retryDelay: function(retryCount) {
			return 250 + Math.pow(2, retryCount) * 500 + Math.floor(Math.random() * 500);
		},
		errorSrc: 'css/images/error_placeholder.png',
		delay: 1,
		debug: false
	}, options ? options : {});

	if(options.debug) {
		// eslint-disable-next-line
		console.time('loaded ' + url);
	}

	var loadImage = function(retryCount) {
		$(elem).off('load error').on('load', function() {
			$(elem).off('error').off('load');

			if(options.debug) {
				// eslint-disable-next-line
				console.timeEnd('loaded ' + url);
			}

			if(options.onComplete) {
				options.onComplete();
			}
		}).on('error', function() {
			$(elem).off('error').off('load');

			if(retryCount < options.retryTimes) {
				var retryDelay = options.retryDelay(retryCount + 1);
				console.warn('Retrying to load image in ' + retryDelay + 'ms (' + retryCount + '): ' + url);
				window.setTimeout(function() {
					loadImage(retryCount + 1);
				}, retryDelay);

				if(options.onRetry) {
					options.onRetry({
						retryCount: retryCount + 1,
						retryDelay: retryDelay
					});
				}
			} else if(options.errorSrc) {
				elem.src = options.errorSrc;

				if(options.onFailed) {
					options.onFailed();
				}
			} else {
				if(!options.leaveSrcOnError) {
					$(elem).removeAttr('src');
				}

				if(options.onFailed) {
					options.onFailed();
				}
			}
		});

		elem.src = url;
		elem.delayedLoadOptions = null;
	};

	$.CancelPreLoadImage(url);
	if(elem.delayedLoadImageHandler) {
		window.clearTimeout(elem.delayedLoadImageHandler);
	}

	elem.delayedLoadImageFunction = function(extras) {
		elem.delayedLoadImageHandler = null;
		elem.delayedLoadImageFunction = null;
		$.extend(true, options, extras);

		loadImage(0);
	};
	elem.delayedLoadImageHandler = window.setTimeout(elem.delayedLoadImageFunction, options.delay);
	elem.delayedLoadOptions = options;

	return this;
};
$.fn.CancelImageLoad = function() {
	var elem = this[0];
	if(elem && elem.delayedLoadImageHandler) {
		window.clearTimeout(elem.delayedLoadImageHandler);
		elem.delayedLoadImageHandler = null;
		elem.delayedLoadImageFunction = null;
	}
};
$.fn.FlushImageLoadQueue = function() {
	this.each(function() {
		if(this.delayedLoadImageHandler) {
			window.clearTimeout(this.delayedLoadImageHandler);
			this.delayedLoadImageFunction({
				errorSrc: null,
				leaveSrcOnError: true
			});
		}
	});
};
$.fn.GetImageLoad = function() {
	var elem = this[0];
	if(elem) {
		return elem.delayedLoadOptions;
	}
};