<template>
	<div class="ui button headerButton icon labeled dropdown moveToPicker" :style="dropdownStyle" :class="dropdownClasses" @click="openRootMenu" >
		<i class="folder icon" />
		<span class="dropdown-label">{{ i18n('photoPicker.moveToAlbumButton') }}</span>

		<dropdown-menu
			v-for="menu in recursiveMenus" :key="menu.id"
			:items="menu.items"
			:depth="menu.depth"
			:is-visible="visibility[menu.depth] && visibility[menu.depth].id == menu.id"
			:top="(visibility[menu.depth] && visibility[menu.depth].id == menu.id) ? visibility[menu.depth].top : 0"
			:selected-id="null"
			@select-item="selectItem"
			@hovered="hoveredItem" />
	</div>
</template>

<script>
import DropdownMenu from './CategoryDropdownMenu.vue';

export default {
	props: ['items', 'selectedItem', 'dropdownClasses', 'dropdownStyle'],
	data: () => ({
		visibility: {}
	}),
	computed: {
		recursiveMenus() {
			let items = [...this.items];
			if(this.selectedItem) {
				items.removeMatch(this.selectedItem, 'id');
			}
			let menus = [
				{
					id: 'root',
					depth: 0,
					items: items
				}
			];
			recursivelyAddMenus(menus, items, this.selectedItem, 1);

			return menus;
		}
	},
	methods: {
		documentClickHandler(event) {
			if(!this.isAttachedTo(event.target, this.$el)) {
				this.clearVisibility();
			}
		},
		openRootMenu(event) {
			let itemDepth = event.target.getAttribute('depth');
			if(itemDepth) {
				let itemId = event.target.id;
				Vue.set(this.visibility, itemDepth, {
					id: itemId,
					top: event.target.offsetTop - event.target.parentNode.scrollTop
				});
			} else if(this.visibility[0]) {
				this.clearVisibility();
			} else {
				Vue.set(this.visibility, 0, {
					id: 'root',
					top: 0
				});
			}
		},

		isAttachedTo(elem, root) {
			return root.contains(elem);
		},
		clearVisibility() {
			for(let id in this.visibility) {
				this.visibility[id] = null;
			}
		},

		selectItem(item) {
			this.$emit('select-item', item);
		},

		hoveredItem(item, depth) {
			if(item.subCategories) {
				let target = this.$el.querySelector('#' + item.buttonId);
				Vue.set(this.visibility, depth, {
					id: item.buttonId,
					top: target.offsetTop - target.parentNode.scrollTop
				});
			} else {
				if(this.visibility[depth]) {
					this.visibility[depth] = null;
				}
			}
		}
	},
	mounted() {
		document.addEventListener('click', this.documentClickHandler);
	},
	destroyed() {
		document.removeEventListener('click', this.documentClickHandler);
	},
	components: { DropdownMenu }
};

function recursivelyAddMenus(menus, items, selectedItem, depth) {
	for(let i = 0; i < items.length; i++) {
		let item = items[i];
		if(item.subCategories?.length) {
			let subItems = [...item.subCategories];
			if(selectedItem) {
				subItems.removeMatch(selectedItem, 'id');
				if(!subItems.length) {
					items.removeItem(item);
					i--;
					continue;
				}
			}

			menus.push({
				id: item.buttonId,
				depth,
				items: subItems
			});

			recursivelyAddMenus(menus, subItems, depth + 1);
		}
	}
}
</script>

<style scoped>
.dropdown-label {
	flex-grow: 1;
}
</style>