// Add Semantic form rules
$.MAX_SAFE_DB_INT = 2147483647;
$.fn.form.settings.rules.floatRange = function(text, range) {
	if(!text || !range || isNaN(text)) {
		return false;
	}

	try {
		var parts = range.split('-');
		var start = parseFloat(parts[0]);
		var end = parseFloat(parts[1]);

		var i = parseFloat(text);
		return i >= start && i <= end;
	} catch(e) {
		return false;
	}
};
$.fn.form.settings.rules.floatRangeWithEmpty = function(text, range) {
	if(!text) {
		return true;
	} else if(!range || isNaN(text)) {
		return false;
	}

	try {
		var parts = range.split('-');
		var start = parseFloat(parts[0]);
		var end = parseFloat(parts[1]);

		var i = parseFloat(text);
		return i >= start && i <= end;
	} catch(e) {
		return false;
	}
};

$.fn.form.settings.rules.intRange = function(text, range) {
	if(!text || !range || isNaN(text)) {
		return false;
	}

	try {
		var parts = range.split('-');
		var start = parseInt(parts[0]);
		var end = parseInt(parts[1]);

		var i = parseInt(text);
		return i >= start && i <= end;
	} catch(e) {
		return false;
	}
};

$.fn.form.settings.rules.intRangeWithEmpty = function(text, range) {
	if(!text) {
		return true;
	} else if(!range || isNaN(text)) {
		return false;
	}

	try {
		var parts = range.split('-');
		var start = parseInt(parts[0]);
		var end = parseInt(parts[1]);

		var i = parseInt(text);
		return i >= start && i <= end;
	} catch(e) {
		return false;
	}
};

$.fn.form.settings.rules.positiveInt = function(text) {
	if(!text) {
		return false;
	}

	try {
		if(isNaN(text)) {
			return false;
		} else {
			let int = parseInt(text);
			return int >= 0 && int < $.MAX_SAFE_DB_INT;
		}
	} catch(e) {
		return false;
	}
};
$.fn.form.settings.rules.positiveIntWithEmpty = function(text) {
	if(!text) {
		return true;
	}

	try {
		if(isNaN(text)) {
			return false;
		} else {
			let int = parseInt(text);
			return int >= 0 && int < $.MAX_SAFE_DB_INT;
		}
	} catch(e) {
		return false;
	}
};

$.fn.form.settings.rules.positiveFloat = function(text) {
	if(!text) {
		return false;
	}

	try {
		if(isNaN(text)) {
			return false;
		} else {
			return parseFloat(text) >= 0;
		}
	} catch(e) {
		return false;
	}
};
$.fn.form.settings.rules.positiveFloatWithEmpty = function(text) {
	if(!text) {
		return true;
	}

	try {
		if(isNaN(text)) {
			return false;
		} else {
			return parseFloat(text) >= 0;
		}
	} catch(e) {
		return false;
	}
};

$.fn.form.settings.rules.json = function(text) {
	if(!text) {
		return false;
	}

	try {
		JSON.parse(text);
		return true;
	} catch(e) {
		return false;
	}
};

$.fn.form.settings.rules.visibleEmpty = function(text) {
	if(!$(this).is(':visible') && ($(this).attr('type') != 'hidden' || !$(this).parent().is(':visible'))) {
		return true;
	} else if(!text) {
		return false;
	} else {
		return true;
	}
};

$.fn.form.settings.rules.email = function(text) {
	if(!text) {
		return true;
	}

	// Copied from Semantic UI source
	var regex = new RegExp("^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$");
	return regex.test(text);
};

$.fn.form.settings.rules.filename = function(text) {
	if(!text) {
		return false;
	}

	// eslint-disable-next-line
	return /^[0-9a-zA-Z\^\&\'\@\{\}\[\]\,\$\=\!\-\#\(\)\.\%\+\~\_ ]+$/.test(text);
};

$.fn.form.settings.rules.commaDelimited = function(text, testerName) {
	if(!text) {
		return true;
	}
	var tester = $.fn.form.settings.rules[testerName];
	if(!tester) {
		throw 'No tester for ' + tester;
	}

	var parts = text.split(',');
	for(var i = 0; i < parts.length; i++) {
		if(!tester(parts[i])) {
			return false;
		}
	}

	return true;
};

// Make url valid if nothing passed in
if(!$.fn.form.settings.rules._url) {
	$.fn.form.settings.rules._url = $.fn.form.settings.rules.url;
	$.fn.form.settings.rules.url = function(text) {
		if(!text) {
			return true;
		}

		return $.fn.form.settings.rules._url.apply(this, arguments);
	};
}

$.fn.form.settings.rules.rangeValidation = function(text) {
	var fields = $(this).parent().parent();
	var inputs = fields.find('input');

	var lowerBound = inputs.eq(0).val();
	var upperBound = inputs.eq(1).val();
	var result = inputs.eq(2).val();

	// Allow everything to be empty
	if(!lowerBound && !upperBound && !result) {
		return true;
	}

	// We shouldn't have any individual fields which are invalid
	var lowerBoundValid = $.fn.form.settings.rules.positiveInt(lowerBound);
	var upperBoundValid = $.fn.form.settings.rules.positiveInt(upperBound);
	var resultValid = $.fn.form.settings.rules.positiveFloat(result);
	if(this[0] === inputs[0] && !lowerBoundValid) {
		return false;
	} else if(this[0] === inputs[1] && !upperBoundValid) {
		return false;
	} else if(this[0] === inputs[2] && !resultValid) {
		return false;
	}

	if(lowerBoundValid && upperBoundValid && (this[0] == inputs[0] || this[0] == inputs[1])) {
		// Want range to go lower => upper
		lowerBound = parseInt(lowerBound);
		upperBound = parseInt(upperBound);
		if(lowerBound > upperBound) {
			return false;
		}

		// Check if previous and next ranges are sequential
		if(this[0] == inputs[0]) {
			var previousFields = fields.prev();
			if (previousFields.length && previousFields.hasClass('fields')) {
				var previousUpperRange = previousFields.find('input').eq(1).val();
				var previousUpperRangeValid = $.fn.form.settings.rules.positiveInt(previousUpperRange);
				if (previousUpperRangeValid && parseInt(previousUpperRange) > lowerBound) {
					return false;
				}
			}
		}

		if(this[0] == inputs[1]) {
			var nextFields = fields.next();
			if (nextFields.length && nextFields.hasClass('fields')) {
				var nextLowerRange = nextFields.find('input').eq(0).val();
				var nextLowerRangeValid = $.fn.form.settings.rules.positiveInt(nextLowerRange);
				if (nextLowerRangeValid && parseInt(nextLowerRange) < upperBound) {
					return false;
				}
			}
		}
	}

	return true;
};