$.SubjectManagementCard = function(subject, options) {
	if(!$.isInit(subject)) {
		throw new Error('No subject defined');
	}

	var div = document.createElement('div');
	div.className = 'ui card subjectCard';
	$.extend(div, {
		setLabelsVisible: function (visible) {
			if (this.labelsVisible == visible) {
				return;
			}
			this.labelsVisible = visible;

			var hide, show;
			if (visible) {
				hide = '.subjectEdit, .editButton, .uploadButton, .cropButton';
				show = '.subjectLabel, .startButton, .taggedPhotosButton, .issuesMark';
				$(this).removeClass('editing');
			} else {
				hide = '.subjectLabel, .startButton, .taggedPhotosButton, .issuesMark';
				show = '.subjectEdit, .editButton, .uploadButton';

				if(this.subject.yearbookPhoto) {
					show += ', .cropButton';
				}
				$(this).addClass('editing');
			}

			// Change over to inputs
			$(this).find(hide).removeClass('visible').addClass('transition hidden').css('display', 'none');

			$(this).find(show).transition('vertical flip in', 500, function () {
				// Focus on first input
				// firstInput.focus();
				$(this).removeClass('transition');
			});
			$(this).find('.notInitialized').removeClass('notInitialized').each(function () {
				$(this).data('initFunction')();
			});

			if ($(this).hasClass('blue')) {
				var cardSet = $(this).siblings('.blue');
				if (cardSet.length) {
					cardSet.each(function () {
						this.setLabelsVisible(visible);
					});
				} else {
					$(this).removeClass('blue');
				}
			}

			if (this.editable && this.list && this.list.parent && this.list.parent.replacePhotoAssignment) {
				if (visible) {
					if (this.oldSubjectPhoto) {
						this.avatar.attr('src', this.oldSubjectPhoto);
					}
				} else {
					if (this.newSubjectPhoto) {
						this.avatar.attr('src', this.newSubjectPhoto.photoCdnUrl);
					}

					this.initUploader();
				}
			}
		},
		initUploader: function() {
			if(this.uploadButtonVue) {
				return;
			}

			div.uploadButtonVue = new Vue({
				data: function() {
					return {
						color: 'teal'
					};
				},
				render: function(createElement) {
					return createElement('button-uploader', {
						props: {
							label: 'Portrait',
							startAlbumId: options.list.getSubjectAlbum(),
							addClasses: 'uploadButton',
							color: this.color
						},
						on: {
							'uploads-started': function() {
								div.uploadButtonVue.color = 'blue';
							},
							'uploads-finished': function(uploads) {
								var upload = uploads[0];
								var photoId = upload.photo.id;
	
								if(photoId == div.subject.photo) {
									$.Alert('Warning', 'You are attempting to upload the same photo which is already assigned to this subject');
									div.uploadButtonVue.color = 'yellow';
								} else {
									var newSubjectPhoto = upload.photo;
									newSubjectPhoto.photoCdnUrl = $.getPlicThumbnail(newSubjectPhoto, {
										w: div.previewSize
									});
									if(!newSubjectPhoto.photo_crops) {
										newSubjectPhoto.photo_crops = [];
									}
	
									div.oldSubjectPhoto = div.avatar.attr('src');
									div.avatar.LoadImage(newSubjectPhoto.photoCdnUrl);
									div.newSubjectPhoto = newSubjectPhoto;
	
									div.uploadButtonVue.color = 'blue';
	
									if(newSubjectPhoto.width && newSubjectPhoto.height) {
										var issues = $.SubjectManagement.getSubjectIssues({
											yearbookPhoto: {
												width: newSubjectPhoto.width,
												height: newSubjectPhoto.height
											}
										});
	
										var uploadIssuesDetected = false;
										issues.forEach(function(issue) {
											if(issue.indexOf('Low resolution') != -1) {
												uploadIssuesDetected = issue;
												return false;
											}
										});
	
										if(uploadIssuesDetected) {
											div.uploadButtonVue.color = 'yellow';
											$.Alert('Warning', uploadIssuesDetected);
										}
									}
								}
							}
						},
						mounted: function() {
							$(this.$el).hide();
						}
					});
				},
				vuetify: window.Vuetify
			}).$mount(div.uploadButton[0]);
		},
		setStaffLabel: function (subject) {
			if (!subject) {
				subject = this.subject;
			}

			this.setStaff(subject['Teacher Priority']);
			if(subject['Teacher Priority']) {
				this.setOnAll(subject['On All']);
			}
		},
		setStaff: function (staff) {
			if (staff) {
				this.staffLabel.removeClass('hidden');
				if (this.batchLabel) {
					this.batchLabel.removeClass('teal').addClass('red');
				}
			} else {
				this.staffLabel.addClass('hidden');
				if (this.batchLabel) {
					this.batchLabel.removeClass('red').addClass('teal');
				}
			}
		},
		setOnAll: function (onAll) {
			// Do a different color for staff which are supposed to be on all composites
			if (onAll) {
				if (!this.staffLabel.hasClass('black')) {
					this.staffLabel.removeClass('red').addClass('black');
					if (this.batchLabel) {
						this.batchLabel.removeClass('red').addClass('black');
					}

					if (this.staffLabel.popup('exists')) {
						this.staffLabel.popup('destroy');
					}
					this.staffLabel.addClass('hasPopup').popup({
						content: 'Staff in all batches',
						observeChanges: false
					});
				}
			} else {
				if (!this.staffLabel.hasClass('red') || !this.staffLabel.hasClass('hasPopup')) {
					this.staffLabel.removeClass('black').addClass('red');
					if (this.batchLabel) {
						this.batchLabel.removeClass('black').addClass('red');
					}

					if (this.staffLabel.popup('exists')) {
						this.staffLabel.popup('destroy');
					}
					this.staffLabel.addClass('hasPopup').popup({
						content: 'Staff',
						observeChanges: false
					});
				}
			}
		},
		openOptionsDialog: function () {
			var form = $('<div class="ui content form">');
			form.append('<h4 class="ui dividing header">Staff Options</h4>')
				.append('<div class="field"><div class="ui toggle checkbox staffCheckbox"><input type="checkbox" name="staff"><label>Staff</label></div></div>')
				.append('<div class="ui three fields" style="margin-bottom: 1em"><div class="field staffPriorityBox"><div class="ui right labeled input"><input type="text" placeholder="1" /><div class="ui label">Staff Priority</div></div></div></div>');
			if (this.individualizedOption) {
				form.append('<div class="field"><div class="ui toggle checkbox individualizedCheckbox"><input type="checkbox" name="allComposites"><label>Include On Individualized Elements</label></div></div>');
			}
			form.append('<div class="field"><div class="ui toggle checkbox allCompositesCheckbox"><input type="checkbox" name="individualized"><label>Display In All</label></div></div>');

			var settings = form.SettingsBuilder([
				{
					name: 'Prefix',
					description: 'Staff Prefix',
					type: 'dropdown',
					placeholder: 'Mr, Mrs, etc...',
					value: this.subject['Prefix'],
					options: ['', 'Mr.', 'Mrs.', 'Ms.', 'Miss', 'Dr.', 'Coach', 'Bro.', 'Brother', 'Pastor', 'Rev.', 'Sr.', 'Sister', 'Fr.', 'Father', 'Msgr.'],
					help: 'When a Prefix is specified the subject label will default to <Prefix> <Last Name> (ie: "Mr. Madina" instead of "Fred Madina")',
					customRule: '',
					search: true,
					allowAdditions: true,
					dropdownSettings: {
						allowAdditions: true,
						forceSelection: false
					}
				},
				{
					type: 'section',
					description: 'General Options',
					settings: [
						{
							name: 'Title',
							description: 'Title',
							type: 'text',
							placeholder: 'Principal, Advisor, Trumpet player, etc...',
							value: this.subject['Title'],
							help: 'Titles will only show on specific layouts such as "Names Under With Title"',
							customRule: ''
						}
					]
				}
			]);

			var staffCheckbox = form.find('.staffCheckbox');
			var allCompositesCheckbox = form.find('.allCompositesCheckbox');
			var individualizedCheckbox = form.find('.individualizedCheckbox');
			var staffPriorityBox = form.find('.staffPriorityBox');
			var staffPrefixBox = form.find('.dropdownField:has([name="Prefix"])');
			var staffBoxes = $(allCompositesCheckbox).add(individualizedCheckbox).add(staffPriorityBox).add(staffPrefixBox);

			let me = this;
			$('<div class="ui modal"><i class="close icon"></i><div class="header">Subject Options</div></div>')
				.append(form)
				.append('<div class="actions"><div class="ui negative button">Cancel</div><div class="ui positive button">OK</div></div>')
				.modal({
					closable: false,
					onShow: function () {
						staffCheckbox.checkbox({
							onChange: function () {
								var checked = staffCheckbox.hasClass('checked');
								me.setStaff(checked);
								if (checked) {
									staffBoxes.find('input').removeAttr('disabled');
									staffPriorityBox.find('input').val(me.subject['Teacher Priority'] ? me.subject['Teacher Priority'] : 1);

									staffPrefixBox.removeClass('disabled');
								} else {
									staffBoxes.find('input').attr('disabled', 'true');
									staffPrefixBox.addClass('disabled');
								}

								$(me).siblings('.blue').each(function () {
									this.setStaff(checked);
								});
							}
						});
						allCompositesCheckbox.checkbox({
							onChange: function () {
								var checked = allCompositesCheckbox.hasClass('checked');
								me.setOnAll(checked);
							}
						});
						individualizedCheckbox.checkbox();
						staffPriorityBox.find('input').keydown(function (event) {
							// Allow special chars + arrows
							if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 || (event.keyCode == 65 && event.ctrlKey === true) || (event.keyCode >= 35 && event.keyCode <= 39)) {
								return;
							} else {
								// If it's not a number stop the keypress
								if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105 )) {
									event.preventDefault();
								}
							}
						});

						if (me.subject['Teacher Priority']) {
							staffCheckbox.checkbox('check');
							staffPriorityBox.find('input').val(me.subject['Teacher Priority']);
						} else {
							staffBoxes.find('input').attr('disabled', 'true');
							staffPrefixBox.addClass('disabled');
						}

						if (me.subject['Individualize Staff']) {
							individualizedCheckbox.checkbox('check');
						}
						if (me.subject['On All']) {
							allCompositesCheckbox.checkbox('check');
						}
					},
					onApprove: function () {
						$(this).data('closeHandled', true);
						var changes = {};
						var multiEditChanges = {};
						if (staffCheckbox.hasClass('checked')) {
							var teacher = staffPriorityBox.find('input').val();
							// If user leaves blank, just set to default value of 1
							if (!teacher) {
								teacher = 1;
							} else {
								teacher = parseInt(teacher);
								if (isNaN(teacher)) {
									teacher = 1;
								}
							}
							if (me.subject['Teacher Priority'] != teacher) {
								multiEditChanges['Teacher Priority'] = changes['Teacher Priority'] = teacher;
							}
						} else if (me.subject['Teacher Priority']) {
							multiEditChanges['Teacher Priority'] = changes['Teacher Priority'] = 0;
						}

						if (individualizedCheckbox.hasClass('checked')) {
							if (!me.subject['Individualize Staff']) {
								changes['Individualize Staff'] = true;
							}
						} else if (me.subject['Individualize Staff']) {
							changes['Individualize Staff'] = false;
						}

						if (allCompositesCheckbox.hasClass('checked')) {
							if (!me.subject['On All']) {
								changes['On All'] = true;
							}
						} else if (me.subject['On All']) {
							changes['On All'] = false;
						}

						var settingChanges = settings.getChanges();
						if(settingChanges === false) {
							return false;
						}

						for (let i = 0; i < settingChanges.length; i++) {
							var change = settingChanges[i];
							changes[change.id] = change.value;
						}

						var notJustWhitespace = ['Prefix', 'Title'];
						for (let i = 0; i < notJustWhitespace.length; i++) {
							var field = notJustWhitespace[i];
							if (changes[field]) {
								if ($.trim(changes[field]) === '') {
									changes[field] = '';
								}
							}
						}

						me.saveChanges(changes);

						if($.getObjectCount(multiEditChanges) > 0) {
							$(me).siblings('.blue').each(function () {
								this.saveChanges(multiEditChanges);
							});
						}
					},
					onDeny: function () {
						$(this).data('closeHandled', true);
						me.setStaffLabel();

						$(me).siblings('.blue').each(function () {
							this.setStaffLabel();
						});
					},
					onHide: function () {
						if(!$(this).data('closeHandled')) {
							me.setStaffLabel();

							$(me).siblings('.blue').each(function () {
								this.setStaffLabel();
							});
						}
					},
					onHidden: function () {
						$(this).remove();
					}
				}).modal('show');
		},
		applyChanges: function () {
			var changes = this.getChanges();
			this.saveChanges(changes);
		},
		getChanges: function() {
			var changes = {};
			$(this).find('.blue.label').each(function () {
				var parent = $(this).data('parent');
				let name = parent.data('name');

				var newValue;
				var input = parent.data('input');
				if (input.hasClass('dropdown')) {
					newValue = input.find('.text').text();
				} else {
					newValue = input.val();
				}
				changes[name] = newValue;
			});
			if (this.newSubjectPhoto) {
				changes['photo_assignment'] = this.newSubjectPhoto.id;
			}

			return changes;
		},
		addPropertiesToTemplate: function (newProperties, onComplete) {
			var chain = new $.ExecutionChain(onComplete);

			var highestPosition = this.job.template.subject_fields.reduce(function(highestPosition, templateField) {
				return Math.max(highestPosition, templateField.arrangement_position || 0);
			}, 0);

			let me = this;
			for (let i = 0; i < newProperties.length; i++) {
				var prop = newProperties[i];
				chain.add($.getPlicAPI({
					method: 'project-templates/' + this.job.template.id + '/subject-fields',
					params: {
						subject_field: {
							label: prop,
							auto_suggest_enabled: false,
							arrangement_position: highestPosition + 1 + i
						}
					},
					type: 'POST',
					accept: 'application/vnd.plic.io.v1+json',
					success: function (data) {
						var field = data.subject_field;
						me.job.template.subject_fields.push(field);

						var fieldKey = field.field_key;
						if(!fieldKey) {
							fieldKey = field.label;
						}

						me.job.fieldLabelMap[fieldKey] = field.id;
						me.job.fieldIdMap[field.id] = fieldKey;
					}
				}));
			}

			chain.done();
		},
		saveChanges: function (changes) {
			if (!$.getObjectCount(changes)) {
				return;
			}

			let me = this;
			this.startLoading();

			var labelMap = this.job.fieldLabelMap;
			var plicProperties = {};
			var perSubjectProperties = {};
			var perBatchProperties = {};
			var newProperties = [];
			for (var field in changes) {
				if(['photo', 'photo_assignment'].indexOf(field) != -1) {
					continue;
				}

				if($.SubjectManagement.PER_BATCH_PROPERTIES.indexOf(field) != -1) {
					perBatchProperties[field] = changes[field];
				} else {
					if (!labelMap[field]) {
						newProperties.push(field);
					}
					plicProperties[labelMap[field]] = changes[field];
					perSubjectProperties[field] = changes[field];
				}
			}

			if (newProperties.length) {
				this.addPropertiesToTemplate(newProperties, function (data) {
					if (data.errors) {
						me.stopLoading();
						$.Alert('Error', 'Failed to save changes');
					} else {
						me.saveChanges(changes);
					}
				});
				return;
			}

			var startSubject = $.extend(true, {}, this.subject);
			var chain = new $.ExecutionChain(function(stats) {
				if(stats.errors) {
					me.stopLoading();
					$.Alert('Error', 'Failed to save changes');
				} else {
					var moveToBatch = false;
					var autoCompleteFields = me.job.autoCompleteFields || $.SubjectManagement.AUTO_COMPLETE_FIELDS;
					for (let i = 0; i < autoCompleteFields.length; i++) {
						var field = autoCompleteFields[i];
						var oldValue = me.subject[field];
						var newValue = changes[field];

						var currentBatchName = me.batch.name;
						if(me.batch.id == -1) {
							currentBatchName = '';
						}

						// Old field matched current batch
						if(oldValue == currentBatchName || (field === 'Teacher' && currentBatchName.indexOf(oldValue + ' - ') === 0 && oldValue.length > 1)) {
							for (var j = 0; j < me.job.batches.length; j++) {
								var newBatch = me.job.batches[j];

								// New field matches another batch, move it!
								if(newValue == newBatch.name || newBatch.name.indexOf(newValue + ' - ') === 0) {
									if (newBatch != me.subject.batch && newBatch != me.batch) {
										moveToBatch = newBatch;
									}
									break;
								}
							}

							if(moveToBatch) {
								break;
							}
						}

						// Make sure value exists in global list
						if(newValue) {
							me.list.parent.addToFieldValues(field, newValue);
						}
					}

					// Update labels
					$(me).find('.blue.label').each(function () {
						var parent = $(this).data('parent');
						if (!parent) {
							return;
						}

						var newValue;
						var input = parent.data('input');
						if (input.hasClass('dropdown')) {
							newValue = input.find('.text').text();
						} else {
							newValue = input.val();
						}
						parent.find('.subjectLabelValue').text(newValue);
						parent.data('init', newValue);

						$(this).removeClass('blue');
					});

					var changeTypes = $.merge(['First Name', 'Last Name', 'Grade', 'Teacher', 'Home Room', 'Teacher Priority', 'Individualize Staff', 'Prefix', 'Title', 'Student ID'], autoCompleteFields);
					for (let i = 0; i < changeTypes.length; i++) {
						var changeType = changeTypes[i];
						if (typeof changes[changeType] != 'undefined') {
							me.subject[changeType] = changes[changeType];

							if($.SubjectManagement.PER_BATCH_PROPERTIES.indexOf(changeType) == -1) {
								me.updateSubjectProperties(changeType, changes[changeType]);
							}
						}
					}

					if($.getObjectCount(perSubjectProperties) > 0) {
						let startProperties = {};
						for(let name in perSubjectProperties) {
							startProperties[name] = startSubject[name];
						}

						me.list.parent.userEvents.addEvent({
							action: 'update',
							args: [startProperties, perSubjectProperties],
							context: ['subjects', me.subject.id]
						});
					}

					if($.getObjectCount(perBatchProperties) > 0) {
						let startProperties = {};
						for(let name in perBatchProperties) {
							startProperties[name] = startSubject[name];
						}

						me.list.parent.userEvents.addEvent({
							action: 'update',
							args: [startProperties, perBatchProperties],
							context: ['batches', me.list.currentBatch.id, 'subjects', me.subject.id],
							extras: {
								idProp: 'id'
							}
						});
					}

					if (typeof changes['On All'] != 'undefined') {
						me.subject['On All'] = changes['On All'];
						me.list.parent.editOnAll(me.subject, me.batch);
					}

					if (moveToBatch) {
						var currentBatch = me.batch;
						var startIndex = me.batch.subjects.indexOfMatch(me.subject, 'id');
						var currentSubjectIds;
						if (me.list.searching || !$(me).isAttached()) {
							me.batch.subjects.removeItem(me.subject);
							currentSubjectIds = $.SubjectManagement.getAppSpecificSubjectsData(me.batch.subjects);
						} else {
							$(me).remove();
							currentSubjectIds = me.list.updateBatchSubjects();
						}

						var index = me.list.getSubjectSortedPosition(newBatch.subjects, me.subject);
						var batches = [];
						if(me.batch.id == -1) {
							var batchLabel = $(me.list.parent.batchList).find('#batch-1');
							batchLabel.find('.label').text(me.batch.subjects.length);
						} else {
							batches.push({
								batchId: me.batch.id,
								subjects: currentSubjectIds
							});
						}

						// Check to make sure not already in that batch via copy!
						if (newBatch.subjects.indexOfMatch(me.subject, 'id') == -1) {
							newBatch.subjects.splice(index, 0, me.subject);

							batches.push($.SubjectManagement.getAppSpecificBatchData(newBatch));
						}

						me.list.parent.batchSaveBatchOrders(batches);
						if(me.batchLabel) {
							$(me.batchLabel).text(moveToBatch.name);
						}
						if(!me.list.searching) {
							me.list.subjectIndex--;
						}
						me.batch = moveToBatch;

						me.list.parent.userEvents.addEvent({
							action: 'remove',
							args: [$.extend(true, {}, subject), startIndex],
							context: ['batches', currentBatch.id, 'subjects'],
							extras: {
								idProp: 'id'
							}
						});
						me.list.parent.userEvents.addEvent({
							action: 'insert',
							args: [$.extend(true, {}, subject), index - 1],
							context: ['batches', moveToBatch.id, 'subjects'],
							extras: {
								idProp: 'id'
							}
						});

						me.stopLoading();
					} else {
						if (me.list && (typeof changes['Teacher Priority'] != 'undefined' || $.isInit(changes['Last Name']) || $.isInit(changes['First Name'])) && me.batch.id !== -1) {
							if (me.list.currentBatch == me.batch && !me.list.searching) {
								me.list.resortSubject(me, me.list.currentBatch.id != -1);
							} else if (me.batch.subjects) {
								var oldIndex = me.batch.subjects.indexOfMatch(me.subject, 'id');
								me.batch.subjects.removeMatch(me.subject, 'id');
								var newIndex = me.list.getSubjectSortedPosition(me.batch.subjects, me.subject);
								var newIndexSubject = me.batch.subjects[newIndex];
								me.batch.subjects.splice(newIndex, 0, me.subject);

								me.list.parent.batchSaveBatchOrders([$.SubjectManagement.getAppSpecificBatchData(me.batch)]);
								if(me.batch.id != -1) {
									me.list.parent.userEvents.addEvent({
										action: 'moveBefore',
										context: ['batches', me.batch.id, 'subjects'],
										args: [me.subject.id, newIndexSubject ? newIndexSubject.id : -1, oldIndex],
										extras: {
											idProp: 'id'
										}
									});
								}
							}
						}
						me.stopLoading();
					}

					me.updateIssuesMark();
					me.list.parent.updateSubjectIssuesButton();
				}
			});

			if(changes.photo_assignment) {
				chain.add($.getPlicAPI({
					method: 'subject-photo-assignments',
					params: {
						subject_photo_assignment: {
							subject_id: this.subject.id,
							photo_id: changes.photo_assignment
						},
						is_primary: true
					},
					type: 'POST',
					success: function() {
						var oldSubjectPhoto = me.subject.yearbookPhoto;
						me.subject.photos.push(me.newSubjectPhoto);
						$.SubjectManagement.populateSubjectWithPrimaryPhoto(me.subject, me.newSubjectPhoto);
						me.updateSubjectProperties(['photo', 'photoCdnUrl', 'yearbookPhoto', 'yearbookCrop'], me.subject);
						me.avatar.attr('src', me.newSubjectPhoto.photoCdnUrl);

						me.newSubjectPhoto = null;
						me.oldSubjectPhoto = null;
						if(me.uploadButtonVue) {
							me.uploadButtonVue.color = 'teal';
						}
						me.openSubjectPhotosDialogIfNecessary();

						me.list.parent.userEvents.addEvent({
							action: 'update',
							args: [
								{
									photo: oldSubjectPhoto ? oldSubjectPhoto.id : null,
									photoCdnUrl: oldSubjectPhoto ? oldSubjectPhoto.cdn_url : null,
									yearbookPhoto: oldSubjectPhoto ? oldSubjectPhoto : null,
									yearbookCrop: oldSubjectPhoto ? oldSubjectPhoto.yearbookCrop : null
								},
								{
									photo: me.subject.photo,
									photoCdnUrl: me.subject.yearbookPhoto.photoCdnUrl,
									yearbookPhoto: me.subject.yearbookPhoto,
									yearbookCrop: me.subject.yearbookPhoto.yearbookCrop
								}
							],
							context: ['subjects', me.subject.id]
						});
					}
				}));

				if($.primarySubjectPoseFlag) {
					var poseFlags = {};
					poseFlags[$.primarySubjectPoseFlag] = changes.photo_assignment;

					chain.add($.getPlicAPI({
						method: 'subjects/' + this.subject.id,
						type: 'PATCH',
						params: {
							subject: {
								pose_flags: poseFlags
							}
						}
					}));
				}

				delete changes.photo_assignment;
			}

			if($.getObjectCount(plicProperties) > 0) {
				chain.add($.getPlicAPI({
					method: 'projects/' + this.job.plicProjectId + '/subjects/' + this.subject.id,
					params: {
						subject: {
							properties: plicProperties,
							merge_properties: true
						}
					},
					type: 'PUT',
					accept: 'application/vnd.plic.io.v1+json'
				}));
			}

			if(($.getObjectCount(perBatchProperties) > 0 || $.getObjectCount(plicProperties) > 0) && this.batch.id != -1) {
				chain.add({
					url: 'ajax/saveBatchEntry.php',
					data: {
						subjectId: this.subject.id,
						batchId: this.batch.id,
						properties: JSON.stringify(perBatchProperties)
					},
					type: 'POST'
				})
			}

			chain.done();
		},
		updateSubjectProperties: function(names, values) {
			if(!$.isArray(names)) {
				names = [names];
				values = [values];
			}

			var updateSubjects = [];

			var jobSubject = this.job.subjects.getMatch(this.subject, 'id');
			if(jobSubject != null) {
				updateSubjects.push(jobSubject);
			}

			var resortBatches = [];
			for(let i = 0; i < this.job.batches.length; i++) {
				var batch = this.job.batches[i];

				var subjectMatch = batch.subjects.getMatch(this.subject, 'id');
				if(subjectMatch != null) {
					updateSubjects.push(subjectMatch);

					if(batch.id !== -1 && batch.id !== this.batch.id && batch.subjects) {
						resortBatches.push(batch);
					}
				}
			}

			var unmatchedSubject = this.job.unmatchedSubjects.getMatch(this.subject, 'id');
			if(unmatchedSubject != null) {
				updateSubjects.push(unmatchedSubject);
			}

			for(let i = 0; i < updateSubjects.length; i++) {
				var subject = updateSubjects[i];

				for(var j = 0; j < names.length; j++) {
					let name = names[j];
					var value;
					if($.isArray(values)) {
						value = values[j];
					} else if($.isPlainObject(values)) {
						value = values[name];
					}

					subject[name] = value;
				}
			}

			// Only re-sort each batch if we are changing one of these special properties
			var shouldResortBatches = false;
			names.forEach(function(field) {
				if($.SubjectManagement.RESORT_PROPERTIES.indexOf(field) !== -1) {
					shouldResortBatches = true;
				}
			});

			// Go through and update each batch that isn't in the trash and not the batch we are viewing/editing
			if(shouldResortBatches) {
				let me = this;
				resortBatches.forEach(function(batch) {
					var subjectMatch = batch.subjects.getMatch(me.subject, 'id');
					if(subjectMatch) {
						batch.subjects.removeItem(subjectMatch);
						var index = me.list.getSubjectSortedPosition(batch.subjects, subjectMatch);
						batch.subjects.splice(index, 0, subjectMatch);
						me.list.parent.batchSaveBatchOrders([$.SubjectManagement.getAppSpecificBatchData(batch)]);
					}
				});
			}
		},
		setVisibleProperty: function (name, value) {
			var field = $(this).find('.subjectProperty[name="' + name + '"]');
			field.find('.subjectLabelValue').text(value);
			field.find('.subjectEdit .dropdown .text').text(value);
		},
		startPhotoUpload: function () {
			if(this.uploadButtonVue) {
				$(this.uploadButtonVue.$el).find('input').click();
			}
		},
		getTaggedPhotos: function() {
			if(!this.subject.photos) {
				this.subject.photos = [];
			}

			var photos = [];
			for(let i = 0; i < this.subject.photos.length; i++) {
				var photo = this.subject.photos[i];
				if(photo.category == 'candid_category') {
					photos.push(photo);
				}
			}
			return photos;
		},
		updateTaggedPhotosButton: function() {
			if(this.tagButton) {
				this.tagButton.prev().remove();
				this.tagButton.remove();
			}

			var taggedPhotos = div.getTaggedPhotos();
			if(taggedPhotos.length) {
				var tagButton = $('<a class="ui yellow label taggedPhotosButton" data-tooltip="View images this subject is tagged in">').append('<i class="tag icon">').append(taggedPhotos.length);
				this.tagButton = tagButton;
				tagButton.click(function () {
					div.openPhotosTaggedIn();
					return false;
				});
				this.headerContent.append('<br>').append(tagButton);
			}
		},
		openPhotosTaggedIn: function() {
			var photos = this.getTaggedPhotos();

			var dialog = $.PhotoPickerDialog({
				title: 'Tagged Photos',
				photoPicker: {
					selection: false,
					searchable: false,
					emptyPhotosMessage: 'This subject is not tagged in any photos',
					photos: photos
				}
			});
			dialog.show();
		},
		openSubjectPhotosDialogIfNecessary: function() {
			var issues = $.SubjectManagement.getSubjectIssues(this.subject, null, {
				ratio: this.batch.aspectRatio || 0.8
			});
			if(issues.indexOf('Incorrect aspect ratio (Must be 8 x 10)') != -1) {
				this.openSubjectPhotosDialog(true);
			}
		},
		openSubjectPhotosDialog: function(requireCrop) {
			var startCrop = null;
			var cropName = 'Yearbook 8 x 10';
			if(this.subject.yearbookPhoto) {
				if(this.subject.yearbookPhoto.yearbookCrop) {
					startCrop = this.subject.yearbookPhoto.yearbookCrop;
				}

				if(this.batch.cropSelection) {
					var cropSelection = this.batch.cropSelection;
					startCrop = this.subject.yearbookPhoto.photo_crops.find(function(crop) {
						return crop.name.toLowerCase() === cropSelection;
					}) || null;

					cropName = this.batch.cropSelection;
				}

				if(startCrop) {
					startCrop = $.extend(true, {}, startCrop);
				}
			}

			var oldVersionId = div.subject.yearbookPhoto.version_id;
			var oldVersionIds = $.merge([], div.subject.yearbookPhoto.version_ids || []);
			var dialog = $.SubjectImageDialog($.extend(true, {
				subject: div.subject,
				onBeforeSave: function() {
					div.startLoading();
				},
				onAfterSave: function() {
					div.stopLoading();
				},
				onSaveCrop: function(crop) {
					div.subject.yearbookCrop = div.subject.yearbookPhoto.yearbookCrop = crop;
					div.updateIssuesMark();
					div.setUserCrop();

					div.list.parent.userEvents.addEvent({
						context: ['subjects',div.subject.id, 'yearbookPhoto', 'yearbookCrop'],
						action: 'update',
						args: [
							startCrop,
							div.subject.yearbookPhoto.yearbookCrop
						],
						permanent: requireCrop
					});
				},
				onSavePhotoVersion: function(newPhoto) {
					$.extend(true, div.subject.yearbookPhoto, newPhoto);
					delete subject.photoCdnUrl;

					var url = $.getPlicThumbnail(subject.yearbookPhoto, {
						w: div.previewSize
					});

					div.avatar.LoadImage(url);
					div.updateIssuesMark();
					div.setUserCrop();

					div.list.parent.userEvents.addEvent({
						context: ['subjects', div.subject.id, 'yearbookPhoto'],
						action: 'update',
						args: [
							{
								version_id: oldVersionId,
								version_ids: oldVersionIds
							},
							{
								version_id: newPhoto.version_id,
								version_ids: newPhoto.version_ids
							}
						],
						permanent: true
					});
				},
				aspectRatio: this.batch.aspectRatio || 0.8,
				cancelable: !requireCrop,
				startCrop: startCrop,
				cropName: cropName
			}, this.subjectImageDialogOptions));
			dialog.show();

			return dialog;
		},
		setUserCrop: function() {
			var img = this.avatar;

			var crop = null;
			if(this.subject.yearbookPhoto) {
				if(this.subject.yearbookPhoto.yearbookCrop) {
					crop = this.subject.yearbookPhoto.yearbookCrop;
				}

				if(this.batch.cropSelection) {
					var cropSelection = this.batch.cropSelection;
					crop = this.subject.yearbookPhoto.photo_crops.find(function(crop) {
						return crop.name.toLowerCase() === cropSelection;
					}) || null;
				}
			}

			if(crop) {
				var width, height, left, top;
				var cropWidth = (1 / crop.width);
				var cropHeight = (1 / crop.height);
				width = cropWidth * 100 + '%';
				height = cropHeight * 100 + '%';
				left = -(crop.x * cropWidth) * 100 + '%';
				top = -(crop.y * cropHeight) * 100 + '%';

				img.css({
					width: width,
					height: height,
					left: left,
					top: top
				});

				if(this.subject.yearbookPhoto.width) {
					var imageWidth = this.subject.yearbookPhoto.width;
					var imageHeight = this.subject.yearbookPhoto.height;
					if(crop) {
						imageWidth = imageWidth * crop.width;
						imageHeight = imageHeight * crop.height;
					}

					var aspectRatio = imageWidth / imageHeight;
					img.parent().css({
						width: (2.5 * aspectRatio) + 'em'
					});
				}
			} else {
				img.css({
					width: '',
					height: '',
					left: '',
					top: ''
				});
			}
		},
		updateIssuesMark: function() {
			// Remove the old one
			if(this.issuesMark) {
				$(this.issuesMark).remove();
			}

			var crop = undefined;
			if(this.batch.cropSelection && this.subject.yearbookPhoto) {
				var cropSelection = this.batch.cropSelection;
				crop = this.subject.yearbookPhoto.photo_crops.find(function(crop) {
					return crop.name.toLowerCase() === cropSelection;
				});
			}
			var issuesHTML = $.SubjectManagement.getSubjectIssuesPopup(this.subject, null, {
				subjects: this.job ? this.job.subjects : null,
				ratio: this.batch.aspectRatio || 0.8, 
				crop: crop
			});
			if(issuesHTML) {
				this.issuesMark = $('<i class="warning large red circle icon issuesMark" style="margin-left: 0.2em"></i>').popup({
					html: issuesHTML
				}).appendTo(this.headerContent);
			}
		},
		recreateAutoCompleteOptions: function(field, values) {
			var propertyField = $(this).find('.subjectProperty[name="' + field + '"]');
			var dropdown = propertyField.find('.ui.search.dropdown');
			var menu = dropdown.find('.menu');

			menu.empty();
			values.forEach(function(value) {
				var item = $('<div class="item">');
				if(!$.isInit(value) || value === '') {
					item.html('&nbsp;');
				} else {
					item.text(value);
				}
				menu.append(item);
			});
		},

		onConsumeSubjectEvent: function(event) {
			if(event.context[0] === 'subjects' && event.context.length === 2) {
				var changes = event.args[1];
				for(var field in changes) {
					if(['yearbookPhoto', 'yearbookCrop', 'photo'].indexOf(field) !== -1) {
						continue;
					}

					var newValue = changes[field];

					var subjectProperty = $(this).find('.subjectProperty[name="' + field + '"]');
					if(field === 'photoCdnUrl') {
						this.avatar.LoadImage(this.subject.photoCdnUrl);
					} else if(subjectProperty) {
						subjectProperty.find('.subjectLabelValue').text(newValue);
						subjectProperty.data('init', newValue);

						var input = subjectProperty.data('input');
						if (input.hasClass('dropdown')) {
							input.dropdown('set text', newValue);
						} else {
							input.val(newValue);
						}
					} else {
						console.warn('Uknown subject property change: ' + field);
					}
				}
			} else if(event.context[0] === 'batches' && event.context.length === 4 && event.context[2] === 'subjects') {
				// Most per batch properties involve staff labels
				this.setStaffLabel();
			} else if(event.context[0] === 'subjects' && event.context.length === 4 && event.context[3] === 'yearbookCrop') {
				this.setUserCrop();
			}
		},

		startLoading: function () {
			this.loader.addClass('active');
		},
		stopLoading: function () {
			this.loader.removeClass('active');
		},
		isLoading: function() {
			return $(this.loader).hasClass('active');
		},
		destroy: function() {
			$(this).find('.hasPopup').each(function() {
				$(this).removeClass('hasPopup').popup('destroy');
			});
		},

		subjectImageDialogOptions: {},
		previewSize: 100
	});

	if($.isInit(subject.id)) {
		$(div).attr('id', 'subject' + subject.id);
	}
	div.subject = subject;
	$(div).data('subject', subject);
	div.staffLabel = $('<a class="ui tiny corner label red staffLabel"><i class="asterisk icon"></i></a>');
	$(div).append(div.staffLabel);

	if($.fancybox && options.list && options.list.parent && (options.list.parent.viewFullResolution || options.list.parent.downloadFullResolution)) {
		div.photoZoomLabel = document.createElement('a');
		div.photoZoomLabel.className = 'ui tiny corner left label';
		var photoZoomIcon = document.createElement('i');
		photoZoomIcon.className = 'zoom icon';
		div.photoZoomLabel.appendChild(photoZoomIcon);

		$(div.photoZoomLabel).click(function () {
			var photos = [];
			var titles = [];
			for(let i = 0; i < div.batch.subjects.length; i++) {
				var otherSubject = div.batch.subjects[i];
				photos.push(otherSubject.photo);
				titles.push(otherSubject['First Name'] + ' ' + otherSubject['Last Name'])
			}

			$.viewFullResolutionImage(photos, titles, {
				download: options.list.parent.downloadFullResolution,
				startPhoto: subject.photo
			});
			return false;
		}).one('mouseenter', function() {
			$(div.photoZoomLabel).popup({
				content: 'Click to view ' + (options.download ? 'and download ' : '') + 'full resolution image',
				observeChanges: false
			}).addClass('hasPopup').trigger('mouseenter');
		});

		$(div).append(div.photoZoomLabel);
	}

	var url;
	if(subject.photoCdnUrl) {
		url = subject.photoCdnUrl;
	} else if(subject.photo) {
		url = $.getPlicThumbnail(subject.photo, {
			w: div.previewSize
		});
	} else {
		url = '/css/images/no-photo.jpg';
	}

	var avatar = $('<img>');
	avatar.LoadImage(url);
	var avatarWrapper = $('<div class="ui massive image subjectAvatar">').append(avatar);
	if(options.movable !== false && options.editable) {
		div.defaultAvatarPopup = 'Drag from here to a batch';

		avatarWrapper.one('mouseenter', function() {
			avatarWrapper.draggable({
				helper: 'clone',
				zIndex: 10000,
				revert: 'invalid'
			}).addClass('hasPopup').popup({
				content: div.defaultAvatarPopup,
				observeChanges: false
			}).trigger('mouseenter');
		});
	}
	div.avatar = avatar;

	if(subject.yearbookPhoto && subject.yearbookPhoto.chroma_key === 'processed') {
		if(options.job && options.job.projectDetails && options.job.projectDetails.background_photo_id) {
			let backgroundPhoto = $('<img>');
			backgroundPhoto.LoadImage('ajax/getPhoto.php?id=' + options.job.projectDetails.background_photo_id + '&opts[w]=200');
			avatarWrapper.prepend(backgroundPhoto);
		}
	}

	div.batch = options.batch;
	div.setUserCrop();

	var uploadButton = '';
	if(options.list && options.list.parent && options.list.parent.replacePhotoAssignment) {
		uploadButton = $('<a class="ui teal icon button uploadButton" style="display: none;" data-tooltip="Upload new portrait"><i class="upload icon"></i> Portrait</a>');
		div.uploadButton = uploadButton;
	}

	div.cropButton = $('<a class="ui icon button cropButton" style="display: none;" data-tooltip="Crop image"><i class="crop icon"></i></a>').click(function() {
		div.openSubjectPhotosDialog();
	});

	var content = $('<div class="content">');
	var header = $('<div class="header">')
		.append(avatarWrapper)
		.appendTo(content);
	div.headerContent = $('<div class="content">')
		.append(uploadButton)
		.append(div.cropButton)
		.append(div.firstNameLabel = createSwitchableLabel('First Name', subject['First Name'])).append(' ')
		.append(div.lastNameLabel = createSwitchableLabel('Last Name', subject['Last Name']))
		.appendTo(header);
	$('<div class="description">')
	// Semantic 2.0 screws up fields in headers like these
		.append(div.firstNameLabel.find('.subjectEdit').detach())
		.append(div.lastNameLabel.find('.subjectEdit').detach())
		.appendTo(content);

	if(!options.job.fieldSet) {
		options.job.fieldSet = $.extend(true, {}, $.SubjectManagement.DEFAULT_PROJECT_FIELD_SETS);
	}
	options.job.fieldSet.editableFields.forEach(function(jobField) {
		if(options.list) {
			if(jobField.ifInData) {
				if(!options.list.parent.showFields[jobField.name]) {
					return;
				}
			} else if(options.list.parent.hideFields[jobField.name]) {
				return;
			}
		}

		$('<div class="description">').append(createSwitchableLabel(jobField.name, subject[jobField.name], {
			appendName: jobField.label || true,
			dropdown: options.job.fieldValues[jobField.name] || null
		})).appendTo(content);
	});

	div.job = options.job;
	div.plicProjectId = options.plicProjectId;
	div.labelsVisible = true;
	div.individualizedOption = options.individualizedOption ? options.individualizedOption : false;
	div.list = options.list;
	div.editable = options.editable;

	div.updateIssuesMark();
	div.updateTaggedPhotosButton();

	// If passed in batch, add [Batch Name] to name
	if(options.batchLabel) {
		div.batchLabel = $('<a class="ui teal right ribbon label subjectBatchRibbon staffLabel">')
			.text(options.batch.name);
		content.append(div.batchLabel);
	}
	$(div).append(content);

	if(options.editable) {
		var buttons = $('<div class="ui two bottom attached buttons">');

		// Start buttons
		$('<div class="ui button startButton">Edit</div>').click(function () {
			div.setLabelsVisible(false);
		}).appendTo(buttons);
		var optionsButton = $('<div class="ui button startButton">Options</div>').click(function () {
			div.openOptionsDialog();
		}).appendTo(buttons);

		if(options.batch && options.batch.id == -1) {
			optionsButton.off('click').attr('data-tooltip', 'Please move to a batch before editing advanced options');
		}

		// Edit buttons
		$('<div class="ui negative button editButton" style="display: none;">Cancel</div>').click(function () {
			div.setLabelsVisible(true);

			// Can't be in setLabelsVisible since Save also calls that
			if(div.oldSubjectPhoto) {
				div.newSubjectPhoto = null;
				div.oldSubjectPhoto = null;
			}

			if(div.uploadButtonVue) {
				div.uploadButtonVue.color = 'teal';
			}
		}).appendTo(buttons);
		$('<div class="ui positive button editButton" style="display: none;">Save</div>').click(function () {
			div.setLabelsVisible(true);
			div.applyChanges();

			$(div).siblings('.blue').each(function () {
				this.applyChanges();
			});

			if(div.uploadButtonVue) {
				div.uploadButtonVue.color = 'teal';
			}
		}).appendTo(buttons);
		div.buttons = buttons;
		$(div).append(buttons);
	}

	// Add loader
	div.loader = $('<div class="ui inverted dimmer"><div class="ui loader"></div></div>');
	$(div).append(div.loader);

	// Set initial state
	div.setStaffLabel();

	return div;

	function createSwitchableLabel(name, value, options) {
		var label = document.createElement('span');
		label.className = 'subjectLabelValue';
		label.textContent = value;

		var div, inputDiv;
		if(options && options.dropdown) {
			var inputLabel = $('<div class="text">');
			inputLabel.text($.isInit(value) ? value : '');

			inputDiv = $('<div class="ui fluid search selection dropdown notInitialized">');
			inputDiv.append(inputLabel).append('<i class="dropdown icon"></i><div class="menu"></div>');
			var menu = inputDiv.find('.menu')[0];

			var fields = options.dropdown;
			for(let i = 0; i < fields.length; i++) {
				var item = document.createElement('div');
				item.className = 'item';
				var fieldValue = fields[i];
				if(!$.isInit(fieldValue) || fieldValue === '') {
					item.innerHTML = '&nbsp;';
				} else {
					item.textContent = fieldValue;
				}
				menu.appendChild(item);
			}

			inputDiv.data('initFunction', function() {
				var firstRun = true;
				inputDiv.dropdown({
					allowAdditions: true,
					onChange: function(value, text) {
						// Means this is the initial set, ignore
						if(firstRun && text == div.data('init')) {
							firstRun = false;
							return;
						}

						if(text != div.data('init')) {
							div.find('.label').addClass('blue');
						} else {
							div.find('.label').removeClass('blue');
						}

						if(inputDiv.data('ignoreChange')) {
							inputDiv.data('ignoreChange', false);
						} else {
							var card = $(div).closest('.subjectCard');
							if (card.hasClass('blue')) {
								var cardSet = card.siblings('.blue');
								cardSet.each(function () {
									var property = $(this).find('.subjectProperty[name="' + name + '"]');
									var dropdown = property.find('.dropdown');
									dropdown.data('ignoreChange', true);
									dropdown.dropdown('set selected', text);
								});
							}
						}
					}
				});
			});
		} else {
			inputDiv = $('<input style="display: inherit;" type="text" />');
			inputDiv.attr('name', name);
			inputDiv.val(value);
			inputDiv.keyup(function() {
				if(this.value != div.data('init')) {
					nameLabel.addClass('blue');
				} else {
					nameLabel.removeClass('blue');
				}
			});
		}

		var subjectLabel = document.createElement('span');
		subjectLabel.className = 'subjectLabel';
		var labelName = name;
		if(options && options.appendName) {
			if(typeof options.appendName === 'string') {
				labelName = options.appendName;
			}
			subjectLabel.textContent = labelName + ': ';
		}
		subjectLabel.appendChild(label);

		var subjectEdit = $('<div class="ui fluid labeled input subjectEdit" style="display: none;">');
		var nameLabel = $('<div class="ui label">').append(labelName).appendTo(subjectEdit);
		subjectEdit.append(inputDiv);

		div = $('<span class="subjectProperty">');
		div.attr('name', name);
		div.data('name', name);
		div.data('init', value);
		div.append(subjectLabel).append(subjectEdit);
		div.data('input', inputDiv);
		nameLabel.data('parent', div);

		return div;
	}
};