$.FlowCoverPageCommon = function(obj) {
	var _setLayout = obj.setLayout;

	$.extend(obj, {
		setLayout: function(layout) {
			// Want to only save changes to stuff besides grid
			var oldLayout = $.extend(true, {}, this.layout);
			var newLayout = $.extend(true, {}, layout);
			delete oldLayout.grid;
			delete newLayout.grid;

			if(!$.objectEquals(oldLayout, newLayout)) {
				_setLayout.apply(this, arguments);
			}
		},
		getCoverFormat: function() {
			var viewingVersion = this.viewingVersion || this.versionId;
			if(viewingVersion) {
				var coverFormat = (this.pageSet.alternativeCoverFormats || []).find(function(format) {
					return format.formatId === viewingVersion;
				});
				if(coverFormat) {
					return coverFormat;
				}
			}

			return null;
		},
		getLayoutDimensions: function() {
			var coverFormat = this.getCoverFormat();
			if(coverFormat) {
				return coverFormat.definition;
			} else {
				return this.pageSet.getLayoutDimensions();
			}
		},
		getLayoutDimensionProperty: function(name) {
			var layoutDimensions = this.getLayoutDimensions();
			if(layoutDimensions) {
				return layoutDimensions[name];
			} else {
				return null;
			}
		},
		getLayout: function(options) {
			var layout = $.extend(true, {}, this.layout);
			delete layout.grid;

			var dimensions = this.getLayoutDimensions();
			if(dimensions && this.pageSet.getLayoutsPerPage() > 1) {
				var side = this.getSide();
				var bleed = this.getPageBleed(dimensions, side);
				var safeSpace = this.getPageSafeSpace(dimensions, side);

				var cropWidth = dimensions.cropWidth;
				var cropHeight = dimensions.cropHeight;
				if(dimensions.coverCropWidth) {
					cropWidth = dimensions.coverCropWidth;
				}
				if(dimensions.coverCropHeight) {
					cropHeight = dimensions.coverCropHeight;
				}

				// Do separate bleed calculation since 11 + 0.2 + 0.2 = 11.399999 but 11 + 0.4 = 11.4
				var bleedWidth = bleed.left + bleed.right;
				var width = cropWidth + bleedWidth;
				var bleedHeight = bleed.top + bleed.bottom;
				var height = cropHeight + bleedHeight;

				bleed.left += safeSpace.left;
				bleed.right += safeSpace.right;
				bleed.top += safeSpace.top;
				bleed.bottom += safeSpace.bottom;

				if(dimensions.spineType == 'pageRanges' && dimensions.coverOutputWidth) {
					var coverOutputWidth = parseFloat(dimensions.coverOutputWidth);
					var currentOutputWidth = width * 2;

					var totalWidthDiff = coverOutputWidth - currentOutputWidth;
					var pageWidthDiff = totalWidthDiff / 2;
					width += pageWidthDiff;
				}

				$.extend(layout, {
					grid: {
						bleed: bleed,
						width: width,
						height: height,
						safeSpace: safeSpace
					}
				});

				if(options && options.includeWhiteSpace) {
					var whiteSpace = this.pageSet.getWhiteSpace(side);
					if(side == 'Right') {
						whiteSpace.left = 0;
					} else {
						whiteSpace.right = 0;
					}

					Object.keys(whiteSpace).forEach(side => {
						layout.grid.bleed[side] += whiteSpace[side];
					});
					layout.grid.width += whiteSpace.left + whiteSpace.right;
					layout.grid.height += whiteSpace.top + whiteSpace.bottom;

					layout.grid.whiteSpace = whiteSpace;
				}
			}

			return layout;
		},
		getSpineWidth: function(dimensions) {
			var spine = $.PAGE_BLEED;
			if(dimensions) {
				var totalPages = Math.max(this.pageSet.getHighestPageNumber(), this.pageSet.getTotalPages()) - 4;
				var totalSpine;
				if(!dimensions.spineType || dimensions.spineType == 'hardCoded') {
					totalSpine = dimensions.spine;
					if(!$.isInit(totalSpine)) {
						totalSpine = 0.25;
					}
				} else if(dimensions.spineType == 'divideBy') {
					totalSpine = dimensions.spine;
					if(dimensions.pagesDividedBy) {
						totalSpine += (totalPages / dimensions.pagesDividedBy);
					}
					// This is to get rid of calculations like 8.750000000000000000001
					totalSpine = parseFloat(totalSpine.toFixed(5));
				} else if(dimensions.spineType == 'pageRanges') {
					var ranges = dimensions.spineRange;
					if(typeof ranges == 'string') {
						ranges = JSON.parse(ranges);
					}

					var lastRange = ranges.length ? ranges[0] : null;
					for(var i = 0; i < ranges.length; i++) {
						var range = ranges[i];
						if(range[0] > range[1]) {
							var tmp = range[0];
							range[0] = range[1];
							range[1] = tmp;
						}

						// We have gone too far, end early
						if(totalPages < range[0]) {
							break;
						}

						lastRange = range;
						if(totalPages < range[1]) {
							break;
						}
					}

					if(lastRange) {
						totalSpine = lastRange[2];
					} else {
						totalSpine = 0;
					}
				}

				spine = totalSpine / 2;
			}

			return spine;
		},
		getPageBleed: function(dimensions, side) {
			var spine = this.getSpineWidth(dimensions);
			var bleed = this.pageSet.getBleedFromDimensions(side);

			// Update spine
			if(side == 'Right') {
				bleed.left = spine;
			} else {
				bleed.right = spine;
			}

			// Update coverBleed if we have any
			if(dimensions.coverBleed) {
				bleed.top = dimensions.coverBleed;
				bleed.bottom = dimensions.coverBleed;

				if(side == 'Right') {
					bleed.right = dimensions.coverBleed;
				} else {
					bleed.left = dimensions.coverBleed;
				}
			}

			// Update per side cover bleed if we have it
			if(dimensions.outsideCoverBleed) {
				if(side == 'Right') {
					bleed.right = dimensions.outsideCoverBleed;
				} else {
					bleed.left = dimensions.outsideCoverBleed;
				}
			}

			if(dimensions.topCoverBleed) {
				bleed.top = dimensions.topCoverBleed;
			}
			if(dimensions.bottomCoverBleed) {
				bleed.bottom = dimensions.bottomCoverBleed;
			}

			return bleed;
		},
		getPageSafeSpace: function(dimensions, side) {
			var safeSpace = this.pageSet.getSafeSpace(side);

			if(dimensions.outsideCoverSafeSpace) {
				if(side == 'Right') {
					safeSpace.right = dimensions.outsideCoverSafeSpace;
				} else {
					safeSpace.left = dimensions.outsideCoverSafeSpace;
				}
			}
			if(dimensions.gutterCoverSafeSpace) {
				if(side == 'Right') {
					safeSpace.left = dimensions.gutterCoverSafeSpace;
				} else {
					safeSpace.right = dimensions.gutterCoverSafeSpace;
				}
			}

			if(dimensions.topCoverSafeSpace) {
				safeSpace.top = dimensions.topCoverSafeSpace;
			}
			if(dimensions.bottomCoverSafeSpace) {
				safeSpace.bottom = dimensions.bottomCoverSafeSpace;
			}

			return safeSpace;
		},
		getOverrideHideAllBleed: function() {
			let side = this.getSide();
			if(side === 'Left') {
				return {
					left: true,
					top: true,
					bottom: true
				};
			} else {
				return {
					right: true,
					top: true,
					bottom: true
				};
			}
		},
		isFrontCover: function() {
			return (this.rootPage || this).pageNumber <= 2;
		},
		getSide: function() {
			return this.isFrontCover() ? 'Right' : 'Left';
		},
		getPageReference: function() {
			return this.isFrontCover() ? 'Front Cover' : 'Back Cover';
		},

		doesAllowContentCompletelyInBleed: function() {
			return this.getLayoutDimensionProperty('allowTextInSpine');
		},

		doesOverflowTextToLinkedLayouts: function() {
			return this.getLayoutDimensionProperty('allowTextInSpine');
		},
		doesAllowTypingOffEdge: function() {
			return !this.isFrontCover();
		},

		getCustomBleedSettings: function() {
			var customBleedSettings = {};

			if(this.getLayoutDimensionProperty('allowTextInSpine')) {
				var customBleedSetting = {
					color: '#ffffff00',
					tooltip: 'Spine of the book.  Content can go in this space.'
				};

				if(this.isFrontCover()) {
					customBleedSettings.left = customBleedSetting;
				} else {
					customBleedSettings.right = customBleedSetting;
				}
			}

			return customBleedSettings;
		},

		duplicateLinkedLayoutsInBleed: false
	});
};