<template>
	<div v-if="errorMessage">
		<v-alert type="error" >{{ errorMessage }}</v-alert>
		<v-btn color="error" raised @click="cancel">Cancel</v-btn>
	</div>
	<v-progress-circular v-else-if="loading" indeterminate />
	<div v-else>
		<v-btn color="error" raised @click="cancel">Cancel</v-btn>
		<v-btn color="primary" raised @click="selectFiles" class="continue-button">Continue</v-btn>
	</div>
</template>

<script>
import isDataFile from './utils/is-data-file';


const SCOPES = 'https://www.googleapis.com/auth/drive.readonly';
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];

export default {
	props: ['acceptedFileMimeTypes', 'allowMultiple', 'allowFolderUploads'],
	data() {
		return {
			loading: true,
			errorMessage: null,
			files: [],
			selectedFiles: [],
			accessToken: null
		};
	},
	computed: {
		selectedFileIds() {
			return this.selectedFiles.map(file => file.id);
		}
	},
	methods: {
		selectFiles() {
			this.$emit('filesSelected', this.selectedFiles);
			this.$emit('returnToMain');
		},
		cancel() {
			this.$emit('returnToMain');
		},
		initGoogleAuth() {
			this.loading = true;
			let tokenClient = window.google.accounts.oauth2.initTokenClient({
				client_id: process.env.VUE_APP_GOOGLE_API_CLIENT_ID,
				scope: SCOPES,
				callback: (response) => {
					if(response.error !== undefined) {
						this.errorMessage = response.error;
					} else {
						this.accessToken = window.gapi.cachedAccessToken = response.access_token;
						this.openPicker();
					}
				}
			});

			tokenClient.requestAccessToken({prompt: 'consent'});
		},
		initDriveClient() {
			window.gapi.client.init({
				apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
				discoveryDocs: DISCOVERY_DOCS
			}).then(() => {
				this.openPicker();
			}).catch(error => {
				this.errorMessage = error;
			});
		},
		openPicker() {
			if(!this.accessToken || !window.gapi.client?.drive) {
				return;
			}

			let docsView = new window.google.picker.DocsView();
			docsView.setMimeTypes(this.acceptedFileMimeTypes);
			docsView.setIncludeFolders(true);
			// This makes it so ONLY shared drives are visible
			// docsView.setEnableDrives(true);
			if(this.allowFolderUploads) {
				docsView.setSelectFolderEnabled(true);
			}

			let pickerBuilder = new window.google.picker.PickerBuilder();
			if(this.allowMultiple) {
				pickerBuilder.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED);
			}
			pickerBuilder.enableFeature(window.google.picker.Feature.SUPPORT_DRIVES);

			this.picker = pickerBuilder
				.setAppId(process.env.VUE_APP_GOOGLE_API_CLIENT_ID)
				.setOAuthToken(this.accessToken)
				.addView(docsView)
				.setCallback((data) => {
					if(data.action === 'picked') {
						this.onFilesPicked(data.docs);
					} else if(data.action === 'cancel') {
						this.cancel();
					}
				})
				.build();
			this.picker.setVisible(true);
		},
		onFilesPicked(docs) {
			this.selectedFiles = docs.filter((doc) => {
				return doc.name !== 'Adjustments.plist' && doc.mimeType !== 'application/vnd.google-apps.folder';
			}).map((doc) => {
				return createFileFromDoc(doc, this.accessToken);
			});

			let selectedFolders = docs.filter((doc) => {
				return doc.mimeType === 'application/vnd.google-apps.folder';
			});
			if(selectedFolders.length > 0) {
				this.loadFolders(selectedFolders);
			} else {
				this.selectFiles();
			}
		},
		loadFolders(folderDocs) {
			let requests = folderDocs.map((folderDoc) => {
				return {
					folderId: folderDoc.id
				};
			});
			this.loadFolderRequests(requests);
		},
		loadFolderRequests(folderRequests) {
			if(folderRequests.length) {
				let { folderId, nextPageToken } = folderRequests.pop();
				let request = window.gapi.client.drive.files.list({
					pageSize: 1000,
					includeItemsFromAllDrives: true,
					supportsAllDrives: true,
					q: "'" + folderId + "' in parents",
					fields: 'nextPageToken, files(id, name, mimeType, thumbnailLink, size, webContentLink, webViewLink)',
					pageToken: nextPageToken
				});


				request.execute((response) => {
					let childrenDocs = response.files || [];
					childrenDocs.forEach((childDoc) => {
						if(childDoc.mimeType === 'application/vnd.google-apps.folder') {
							folderRequests.push({
								folderId: childDoc.id
							});
						} else {
							this.selectedFiles.push(createFileFromDoc(childDoc, this.accessToken));
						}
					});

					if(response.nextPageToken) {
						folderRequests.push({
							folderId : folderId,
							pageToken: response.nextPageToken
						});
					}

					this.loadFolderRequests(folderRequests);
				});
			} else {
				this.checkForDownloadFiles();
			}
		},
		checkForDownloadFiles() {
			let downloadFiles = this.selectedFiles.filter((file) => {
				return isDataFile(file);
			});
			// We will add these back as raw Blobs that can be client side parsed
			this.selectedFiles = this.selectedFiles.filter((file) => {
				return !isDataFile(file);
			})

			this.downloadFiles(downloadFiles);
		},
		downloadFiles(files) {
			if(files.length) {
				let file = files.pop();
				window.gapi.client.drive.files.get({
					'fileId': file.id,
					'alt': 'media'
				}).then((response) => {
					if(response?.body) {
						this.selectedFiles.push(new File([response.body], file.name, {
							type: 'text/plain'
						}));

						this.downloadFiles(files);
					} else {
						this.errorMessage = 'Attempting to download ' + file.name + ' but got an empty response';
					}
				}).catch((error) => {
					this.errorMessage = error.error;
				});
			} else {
				this.selectFiles();
			}
		}
	},
	mounted() {
		if(window.gapi.cachedAccessToken) {
			this.accessToken = window.gapi.cachedAccessToken;
			this.openPicker();
		} else if(this.accessToken) {
			this.openPicker();
		} else if(window.gapi.picker && window.google?.accounts?.oauth2) {
			this.initGoogleAuth();
			this.initDriveClient();
		} else {
			// According to https://developers.google.com/identity/oauth2/web/guides/migration-to-gis#gapi-asyncawait this shouldn't load gapi.auth2 anymore but it still does...
			window.gapi.load('client', () => {
				window.gapi.load('picker', () => {
					this.initGoogleAuth();
					this.initDriveClient();
				});
			});
		}
	}
};

function createFileFromDoc(doc, authToken) {
	return {
		id: doc.id,
		name: doc.name,
		type: doc.mimeType,
		downloadUrl: doc.url || doc.thumbnailLink,
		// Should only exist for recursive folder lookups
		viewUrl: doc.thumbnailLink,
		size: doc.sizeBytes || doc.size,
		source: 'google-drive',
		authToken
	};
}
</script>

<style scoped>
	.file-list-item {
		text-align: left;
	}

	.continue-button {
		margin-left: 0.5em;
	}
</style>