<template>
	<v-alert v-if="errorMessage" type="error" >{{ errorMessage }}</v-alert>
	<div v-else-if="loading">
		<v-progress-circular indeterminate />

		<v-alert v-if="loadingMessage" type="info" >{{ loadingMessage }}</v-alert>
	</div>
	<pick-from-google-drive v-else-if="pickingFromGoogleDrive" @returnToMain="returnToMain" :accepted-file-mime-types="acceptedFileMimeTypes" @filesSelected="filesSelected" :allow-multiple="allowMultiple" :allow-folder-uploads="allowFolderUploads" />
	<pick-from-instagram v-else-if="pickingFromInstagram" @returnToMain="returnToMain" :image-requirements="imageRequirements" @filesSelected="filesSelected" :allow-multiple="allowMultiple" :popup="instagramUsePopup" />
	<div v-else @drop.prevent="droppedFiles" @dragover.prevent>
		<component :is="chooseFileHeaderComponent" v-if="chooseFileHeaderComponent" :extra-step-props="extraStepProps" :album-id="albumId" />

		<v-btn class="upload-button select-files" color="primary" raised x-large @click="pickFiles" v-if="allowFileUploads"><v-icon left>cloud_upload</v-icon> {{ selectFilesButton || i18n('uploader.uploadPhotos') }}</v-btn>
		<v-btn class="upload-button select-folder" color="primary" raised x-large @click="pickFolder" v-if="allowFolderUploads && supportsFolderUpload"><v-icon left>folder_open</v-icon> {{ selectFolderButton }}</v-btn>
		<p/>
		<v-btn color="primary" raised @click="pickFromGoogleDrive" v-if="supportsGoogleDriveComp"><v-icon left>insert_drive_file</v-icon> {{ i18n('uploader.googleDrive') }}</v-btn>
		<v-btn class="instagram-button" v-if="supportsInstagram" @click="pickFromInstagram" color="primary" raised><v-icon left>insert_drive_file</v-icon> {{ i18n('uploader.instagram') }}</v-btn>
		<p/>
		<div>{{ computedChooseFileMessage }}</div>

		<template v-if="stopButton">
			<p/>
			<br/>
			<v-btn class="stop-button" color="error" raised @click="stop">{{ stopButton }}</v-btn>
		</template>

		<input ref="fileInput" type="file" :multiple="allowMultiple" :accept="acceptedFiles" class="hiddenInput" @change="selectedFiles"/>
		<input ref="folderInput" type="file" :multiple="allowMultiple" directory mozdirectory webkitdirectory :accept="acceptedFiles" class="hiddenInput" @change="selectedFiles"/>
	</div>
</template>

<script>
import PickFromGoogleDrive from './PickFromGoogleDrive';
import PickFromInstagram from './PickFromInstagram';
import { logAxiosError } from '../utils/axios';
import loadRequirements from './utils/load-requirements';
import chooseFiles from './mixins/choose-files';
import filesize from 'filesize';
import queryString from 'query-string';
import { validate as validateUUID } from 'uuid';

export default {
	props: {
		acceptedFileExtensions: null,
		chooseFileMessage: null,
		chooseFileHeaderComponent: null,
		imageRequirements: null,
		allowMultiple: null,
		isVisible: null,
		albumId: null,
		stopButton: null,
		selectFilesButton: null,
		selectFolderButton: null,
		allowFileUploads: null,
		allowFolderUploads: null,
		instagramUsePopup: null,
		initialDropEvent: null,
		checkValidUploaderFunction: null,
		extraStepProps: null
	},
	data() {
		let queryParams = queryString.parse((window.testLocation ?? window.location).href);

		return {
			loading: true,
			loadingMessage: null,
			errorMessage: null,
			pickingFromGoogleDrive: false,
			pickingFromInstagram: !!queryParams.instagramToken,
			supportsGoogleDrive: !!process.env.VUE_APP_GOOGLE_API_KEY,
			supportsInstagram: !!process.env.VUE_APP_FACEBOOK_API_INSTAGRAM_APP_ID,
			supportsFolderUpload: true
		};
	},
	mixins: [chooseFiles],
	computed: {
		computedChooseFileMessage() {
			return this.chooseFileMessage.replace('{{maxFileSize}}', filesize(this.imageRequirements.maxSize));
		},
		supportsGoogleDriveComp() {
			return this.supportsGoogleDrive && window.gapi;
		}
	},
	watch: {
		isVisible(value) {
			// Only needed if user clicks back and then re-selects same image again
			if(value) {
				if(this.$refs.fileInput) {
					this.$refs.fileInput.value = '';
				}
				if(this.$refs.folderInput) {
					this.$refs.folderInput.value = '';
				}
			}
		}
	},
	methods: {
		pickFiles() {
			this.$refs.fileInput.click();
		},
		pickFolder() {
			this.$refs.folderInput.click();
		},
		
		selectedFiles(event) {
			this.filesSelected(event.target.files);
		},
		filesSelected: async function(files) {
			this.loading = true;
			files = await this.loadSelectedFiles(files);

			this.$emit('set-files', files);
			this.$emit('next-step');
			this.loading = false;
		},
		pickFromGoogleDrive() {
			this.pickingFromGoogleDrive = true;
		},
		pickFromInstagram() {
			this.pickingFromInstagram = true;
		},
		returnToMain() {
			this.pickingFromGoogleDrive = false;
			this.pickingFromInstagram = false;
		},
		stop() {
			this.$emit('stop');
		},
		error(errorMessage, error, logBugsnag = false) {
			this.loading = false;
			this.errorMessage = errorMessage;
			console.error(errorMessage, error);

			if(logBugsnag) {
				logAxiosError(errorMessage, error);
			}
		}
	},
	mounted() {
		loadRequirements().then(requirements => {
			this.imageRequirements.validTypes = requirements.valid_content_types;
			this.imageRequirements.maxSize = requirements.max_size;

			if(this.checkValidUploaderFunction) {
				return this.checkValidUploaderFunction();
			}
		}).then(() => {
			this.loading = false;
			if(this.onLoadUploadRequirements) {
				this.onLoadUploadRequirements();
			}
		}).catch((error) => {
			let errorMessage = this.i18n('uploader.errors.loadRequirements');
			if(error.response?.data?.reason) {
				errorMessage = error.response.data.reason;
			}
			this.error(errorMessage, error);
		});

		if(!this.albumId || (this.albumId !== 'stub' && !validateUUID(this.albumId))) {
			this.errorMessage = 'Invalid album.  Ask your contact to generate a new link.';
		}

		if(this.initialDropEvent?.dataTransfer) {
			this.droppedFiles(this.initialDropEvent);
		}

		let tmpInput = document.createElement('input')
		tmpInput.type = 'file'
		this.supportsFolderUpload = typeof tmpInput.webkitdirectory != 'undefined';
	},
	components: {
		PickFromGoogleDrive,
		PickFromInstagram
	}
};
</script>

<style scoped>
	.hiddenInput {
		display: none;
	}

	.select-folder, .instagram-button {
		margin-left: 0.5em;
	}
</style>