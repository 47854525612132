<template>
	<div v-if="errorMessage">
		<v-alert type="error" >{{ errorMessage }}</v-alert>
		<v-btn color="error" raised @click="cancel">Cancel</v-btn>
	</div>
	<v-progress-circular v-else-if="loading" indeterminate />
	<div v-else>
		<v-data-iterator
			:items="images"
			:items-per-page="itemsPerPage"
			:footer-props="{ 'show-current-page': true, 'next-icon': 'chevron_right', 'prev-icon': 'chevron_left' }"
			class="image-grid"
			:hide-default-footer="disableImagePagination">

			<template v-slot:default="props">
				<v-row class="justify-center">
					<v-col lg="3" md="4" sm="6" v-for="image in props.items" :key="image.id" class="preview-col" >
						<v-img :src="image.media_url" style="max-width: 100%" min-height="12em" max-height="30em" @click="toggleImage(image)" />
						<v-btn x-large color="green" light icon fab class="checkmark" v-if="image.selected" @click="toggleImage(image)"><v-icon class="checkmark-icon">check_circle</v-icon></v-btn>
					</v-col>
				</v-row>
			</template>
		</v-data-iterator>

		<v-btn color="error" raised @click="cancel">Cancel</v-btn>
		<v-btn color="primary" raised @click="selectFiles" class="continue-button" :disabled="selectedFiles.length <= 0">Continue {{ selectedText }}</v-btn>
	</div>
</template>

<script>
import { client } from '../utils/axios';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
const IMAGE_TYPE = 'IMAGE';
const ALBUM_TYPE = 'CAROUSEL_ALBUM';

export default {
	props: {
		imageRequirements: null,
		allowMultiple: null,
		popup: null
	},
	data() {
		let queryParams = queryString.parse((window.testLocation ?? window.location).href);

		return {
			loading: true,
			errorMessage: null,
			images: [],
			accessToken: queryParams.instagramToken
		};
	},
	computed: {
		itemsPerPage() {
			if(this.$vuetify.breakpoint.smAndDown) {
				return 4;
			} else if(this.$vuetify.breakpoint.md) {
				return 6;
			} else {
				return 8;
			}
		},
		disableImagePagination() {
			return this.images.length <= this.itemsPerPage;
		},
		selectedFiles() {
			return this.images.filter((image) => {
				return image.selected;
			});
		},
		selectedText() {
			if(this.selectedFiles.length === 0) {
				return '';
			} else {
				return '(' + this.selectedFiles.length + ' selected)';
			}
		},
		instagramLoginHref() {
			let redirectUrl = window.location.origin.replace('http://', 'https://').replace('127.0.0.1', 'localhost') + '/instagramConnect.php';
			let url = 'https://api.instagram.com/oauth/authorize/?client_id=' + process.env.VUE_APP_FACEBOOK_API_INSTAGRAM_APP_ID + '&redirect_uri=' + redirectUrl + '&response_type=code&scope=user_profile,user_media';
			if(!this.popup) {
				url += '&state=' + btoa(encodeURIComponent(window.location.href));
			}

			return url;
		}
	},
	methods: {
		selectFiles() {
			this.$emit('filesSelected', this.selectedFiles.map((file) => {
				return {
					id: file.id,
					name: file.id + '.jpg',
					type: 'image/jpeg',
					downloadUrl: file.media_url,
					viewUrl: file.media_url,
					size: 1000,
					source: 'instagram'
				}
			}));
			this.$emit('returnToMain');
		},
		cancel() {
			this.$emit('returnToMain');
		},
		initAPI() {
			if(this.popup) {
				let popup = window.open(this.instagramLoginHref, 'Instagram login-' + uuidv4(), 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000');
				// Broadcast channel works better and is faster, but is not supported by Safari
				if(window.BroadcastChannel) {
					let broadcastChannel = new window.BroadcastChannel('instagram-oauth');
					broadcastChannel.onmessage = (event) => {
						this.accessToken = event.data;
						this.getPhotos();
						popup.close();
					};
				} else {
					let interval = setInterval(() => {
						try {
							if(popup.closed) {
								clearInterval(interval);
								if(!this.accessToken) {
									this.errorMessage = 'Popup closed without logging into Instagram';
								}
								return;
							}

							let location = popup.location.href;
							if(location.indexOf('oken') !== -1) {
								let queryParams = queryString.parse(location);

								this.accessToken = queryParams.accessToken;
								this.getPhotos();
								clearInterval(interval);
								popup.close();
							}
						} catch(e) {
							if(e.message && e.message.indexOf('Blocked a frame with origin') !== -1) {
								// Ignoring since this just means the popup is still on Instagram domain
							} else {
								console.error('Failed to get response from popup: ', e);
							}
						}
					}, 400);
				}
			} else {
				let location = this.instagramLoginHref;
				window.location.href = location;
				window.location.href = location;
			}
		},
		getPhotos() {
			this.loading = true;
			client.get('https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,children{media_type,media_url}&access_token=' + this.accessToken).then(({ data }) => {
				this.images = data.data.reduce((images, media) => {
					if(media.media_type === IMAGE_TYPE) {
						images.push(media);
					} else if(media.media_type === ALBUM_TYPE) {
						images.push(...media.children.data.filter((child) => {
							return child.media_type === IMAGE_TYPE;
						}));
					}

					return images;
				}, []).map((image) => {
					image.selected = false;
					return image;
				});
				this.loading = false;
			}).catch((error) => {
				console.error(error);
				if(error?.response?.data) {
					this.errorMessage = error.response.data.error.message;
				} else {
					this.errorMessage = error;
				}
			});
		},
		onFilesPicked(docs) {
			this.selectedFiles = docs.map((doc) => {
				return {
					id: doc.id,
					name: doc.name,
					type: doc.mimeType,
					downloadUrl: doc.url,
					size: doc.sizeBytes
				};
			});

			this.selectFiles();
		},
		toggleImage(image) {
			image.selected = !image.selected;
		}
	},
	mounted() {
		if(this.accessToken) {
			this.getPhotos();
		} else {
			this.initAPI();
		}
	}
};
</script>

<style scoped>
	.continue-button {
		margin-left: 0.5em;
	}

	.image-grid:deep(.v-data-footer__select) {
		display: none;
	}

	.preview-col {
		position: relative;
	}
	.checkmark {
		position: absolute;
		top: 0;
		right: 0;
	}

	.checkmark-icon {
		background-color: white;
	}
</style>