$.bookComposerTutorial = {
	id: 'bookComposerTutorial',
	steps: [
		{
			target: '#rightPage',
			title: 'Composer',
			content: 'Welcome to the composer.  Here you will be able to create your book.',
			placement: 'right',
			xOffset: 'center',
			yOffset: 'center'
		},
		{
			target: '.composer-page-navigation-button:eq(1)',
			title: 'Page buttons',
			content: 'Use these buttons to navigate around your book.',
			placement: 'top',
			yOffset: 0,
			arrowOffset: 'left'
		},
		{
			target: '.main-sidebar-wrapper .mdc-tab-bar .mdc-tab:eq(0)',
			title: 'Design Elements',
			content: 'Here you have some basic content you can drag onto your page.',
			placement: 'right'
		},
		{
			target: '.main-sidebar-wrapper .mdc-tab-bar .mdc-tab:eq(1)',
			title: 'Layouts',
			content: 'Next you can drag various layouts onto your pages to get a cool look!',
			placement: 'right'
		},
		{
			target: '.main-sidebar-wrapper .mdc-tab-bar .mdc-tab:eq(2)',
			title: 'Candids',
			content: 'As you work through your book you can add your own candid images via the Candid tab.',
			placement: 'right'
		},
		{
			target: '.composer-page-number-wrapper > .ui.button',
			title: 'Finish Up',
			content: 'When you are done with your Book, click Preview and then Finalize and Submit to submit your Book for printing!',
			placement: 'top',
			yOffset: 0,
			arrowOffset: 'left'
		}
	],
	showPrevButton: true,
	scrollTopMargin: 100
};

$.pageLadderTutorial = {
	id: 'pageLadderTutorial',
	steps: [
		{
			target: '#pageLadder',
			title: 'Book Preview',
			content: 'In this Book Preview you can move around, delete, and navigate to different sections of the Book',
			placement: 'right',
			xOffset: 'center',
			yOffset: 'center'
		},
		{
			target: '#pageLadder .pageThumbnail:eq(2)',
			title: 'Page Thumbnails',
			content: 'Hover over a thumbnail for more options.  You can move the page around by dragging and dropping it where you want it to go.',
			placement: 'right'
		},
		{
			target: '#pageLadder .pageThumbnail:eq(2) .pageThumbnailTitle',
			title: 'Page Title',
			content: 'Edit the page titles to storyboard what each page is going to be for.',
			placement: 'right'
		},
		{
			target: '#pageLadder .page-ladder-new-page-button',
			title: 'Add Pages',
			content: 'Click on the plus button in the lower right corner to add additional pages.  This button will only be visible when you are under your page limit',
			placement: 'left'
		},
		{
			target: '.pageLadderModal .actions .positive.button',
			title: 'Preview PDF',
			content: 'Click the Preview PDF button to generate a preview of what the Book will look like when it is printed.',
			placement: 'top'
		},
		{
			target: '.pageLadderModal .actions .orange.button',
			title: 'Submit PDF',
			content: 'When you are all done and ready to submit your Book to your Publisher, click on Finalize and Submit.  Don\'t press this unless you are really done!',
			placement: 'top'
		}
	],
	showPrevButton: true,
	scrollTopMargin: 100
};