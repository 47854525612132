$.DataModel = function(settings) {
	var obj = new Object;

	$.extend(obj, {
		getProperty: function(name) {
			return this[name];
		},
		setProperty: function() {
			this.propertyChange.apply(this, arguments);
		},
		propertyChange: function (name, value, forceUpdate, sendEvent, isPrivate, options) {
			options = $.extend(true, {}, options);

			var save = true;
			if (typeof value == 'object' && value !== null) {
				save = false;
				// eslint-disable-next-line
				for (var i in value) {
					save = true;
					break;
				}
			}

			var oldValue = this[name];
			if (save) {
				save = value != oldValue;
			}

			this[name] = value;

			if ((save && (this.type != 'classOverflow' || name != 'layout')) || forceUpdate) {
				var saveValue = value;
				if(options.saveValue) {
					if(saveValue) {
						saveValue = saveValue[options.saveValue];
					}

					if(oldValue) {
						oldValue = oldValue[options.saveValue];
					}
				}

				var obj = {};
				obj[name] = saveValue;

				if (this.db) {
					this.db.queueChange({
						scope: this.changeScope,
						name: this.id,
						value: obj
					});
				}

				if(sendEvent && $.userEvents) {
					this.queueEvent({
						context: [this, name],
						action: 'update',
						args: [oldValue, saveValue],
						private: isPrivate
					}, options);
				}
			}
		},
		getExtraProperty: function (name, defaultValue) {
			if (!this.extras || typeof this.extras[name] === 'undefined') {
				return defaultValue;
			} else {
				return this.extras[name];
			}
		},
		setExtraProperty: function(name, value, options) {
			this.extraPropertyChange(name, value, options);
		},
		extraPropertyChange: function (name, value, options) {
			this.jsonPropertyChange('extras', name, value, options);
		},
		deleteExtraProperty: function(name) {
			this.jsonPropertyRemove('extras', name);
		},
		arrayPropertyPush: function (name, value, saveValue, options) {
			if(!options) {
				options = {};
			}
			this[name].push(value);

			if(this.db) {
				if(this.id) {
					this.db.queueChange({
						scope: this.changeScope,
						name: this.id,
						subName: name,
						value: [saveValue ? saveValue : value]
					});
				} else {
					this.db.queueChange({
						scope: this.changeScope,
						name: name,
						value: [saveValue ? saveValue : value]
					}, undefined, {
						forcePartialUpdates: true
					});
				}
			}

			this.queueEvent({
				context: [this, name],
				action: 'insert',
				args: [saveValue ? saveValue : value, this[name].length - 1]
			}, options);
		},
		arrayPropertyRemove: function(name, index, saveProperty, options) {
			var startValue = this[name].splice(index, 1)[0];
			if(saveProperty && startValue) {
				startValue = startValue[saveProperty];
			}

			if (this.db) {
				// TODO: Differential update
				var obj = {};
				// Make sure we are saving another array to changes list so differentials don't break stuff
				if(saveProperty) {
					obj[name] = this[name].arrayOfProperties(saveProperty);
				} else {
					obj[name] = $.merge([], this[name]);
				}

				this.db.queueChange({
					scope: this.changeScope,
					name: this.id,
					value: obj
				});
			}

			this.queueEvent({
				context: [this, name],
				action: 'remove',
				args: [startValue, index]
			}, options);
		},
		arrayPropertyChange: function (name, saveProperty) {
			var obj = {};
			if(saveProperty) {
				obj[name] = this[name].arrayOfProperties(saveProperty);
			} else {
				obj[name] = $.merge([], this[name]);
			}

			if(this.db) {
				if(this.id) {
					this.db.queueChange({
						scope: this.changeScope,
						name: this.id,
						value: obj
					});
				} else {
					this.db.queueChange({
						scope: this.changeScope,
						name: name,
						value: obj[name]
					});
				}
			}

			// TODO: This is consumable but not reversable due to not knowing what the start args are
			this.queueEvent({
				context: [this, name],
				action: 'update',
				args: [undefined, this[name]],
				permanent: true
			});
		},
		jsonPropertyChange: function (prop, name, value, options) {
			options = $.extend(true, {}, options);

			var start = this[prop][name];
			if (start) {
				if ($.isArray(start)) {
					start = $.merge([], start);
				} else if (typeof start == 'object') {
					start = $.extend(true, {}, start);
				}
			}

			if(this[prop] && this[prop].__ob__) {
				Vue.set(this[prop], name, value);
			} else {
				this[prop][name] = value;
			}

			if(options.save !== false) {
				this.jsonQueuePropertyChange(prop, name);

				this.queueEvent({
					context: [this, prop, name],
					action: 'update',
					args: [start, value]
				}, options);
			}
		},
		jsonReplace: function(prop, value) {
			var oldValue = this[prop];
			this[prop] = value;

			var obj = {};
			obj[prop] = value;

			if (this.db) {
				this.db.queueChange({
					scope: this.changeScope,
					name: this.id,
					value: obj
				});
			}

			if($.userEvents) {
				$.userEvents.addEvent({
					context: [this, prop],
					action: 'replace',
					args: [oldValue, value]
				});
			}
		},
		jsonClear: function(prop) {
			var oldValue = this[prop];
			this[prop] = {};

			if (this.db) {
				var obj = {};
				obj[prop] = {};

				this.db.queueChange({
					scope: this.changeScope,
					name: this.id,
					value: obj
				});
			}

			if ($.userEvents) {
				$.userEvents.addEvent({
					context: [this, prop],
					action: 'clear',
					args: oldValue
				});
			}
		},
		jsonSubPropertyChange: function (prop, name, subName, value, options) {
			if(!options) {
				options = {};
			}

			var obj = this[prop][name];
			if($.isArray(obj)) {
				obj = this[prop][name] = {};
			}
			// If we don't have an object here, it's important that it not be sent as an update so it doesn't end up as an array
			else if(!$.isInit(this[prop][name])) {
				obj = {};
				if(obj && obj.__ob__) {
					Vue.set(obj, subName, value);
				} else {
					obj[subName] = value;
				}

				this.jsonPropertyChange(prop, name, obj);
				return;
			}

			var start = {}, end = {};
			if($.isArray(subName)) {
				for(var i = 0; i < subName.length; i++) {
					var subValue = this.cloneObject(value[i]);

					var subNamePart = subName[i];
					start[subNamePart] = obj[subNamePart];
					if(obj && obj.__ob__) {
						Vue.set(obj, subNamePart, subValue);
					} else {
						obj[subNamePart] = subValue;
					}
					end[subNamePart] = subValue;
				}
			} else {
				value = this.cloneObject(value);

				start[subName] = obj[subName];
				if(obj && obj.__ob__) {
					Vue.set(obj, subName, value);
				} else {
					obj[subName] = value;
				}
				end[subName] = value;
			}

			if(options.mergeSub) {
				this.jsonQueueSubPropertyChange(prop, name, subName);
			} else {
				this.jsonQueuePropertyChange(prop, name);
			}

			if ($.userEvents) {
				var addEvent = true;
				if($.isPlainObject(start) && $.isPlainObject(end) && $.objectEquals(start, end, null, true) && start !== end) {
					addEvent = false;
				}

				if(addEvent) {
					$.userEvents.addEvent($.extend({
						context: [this, prop, name],
						action: 'update',
						args: [start, end]
					}, options.event));
				}
			}
		},
		jsonSubPropertyDeepChange: function (prop, name, subNames, value, options) {
			if(!options) {
				options = {};
			}

			var obj = this[prop][name];
			// If we don't have an object here, it's important that it not be sent as an update so it doesn't end up as an array
			if($.isArray(obj) || !$.isInit(this[prop][name])) {
				obj = this[prop][name] = {};
			}

			for(var i = 0; i < subNames.length - 1; i++) {
				var subName = subNames[i];
				if(!$.isInit(obj[subName]) || $.isArray(obj[subName])) {
					obj[subName] = {};
				}

				obj = obj[subName];
			}
			obj[subNames[subNames.length - 1]] = this.cloneObject(value);

			if(options.mergeSub) {
				this.jsonQueueSubPropertyChange(prop, name, subNames[0]);
			} else {
				this.jsonQueuePropertyChange(prop, name);
			}

			// TODO: Implement events
		},
		jsonPropertyRemove: function (prop, name, options) {
			var oldValue = this[prop][name];

			if(this[prop] && this[prop].__ob__) {
				Vue.delete(this[prop], name);
			} else {
				delete this[prop][name];
			}

			if (this.db) {
				var obj = {};
				obj[name] = null;

				this.db.queueChange({
					scope: this.changeScope,
					name: this.id,
					subName: prop,
					masterValue: this[prop],
					value: obj
				});
			}

			this.queueEvent({
				context: [this, prop, name],
				action: 'update',
				args: [oldValue, null]
			}, options);
		},
		jsonQueuePropertyChange: function (prop, name) {
			var obj = {};
			obj[name] = this[prop][name];

			if(this.db) {
				if(this.id) {
					this.db.queueChange({
						scope: this.changeScope,
						name: this.id,
						subName: prop,
						masterValue: this[prop],
						value: obj
					});
				} else {
					this.db.queueChange({
						scope: this.changeScope,
						name: prop,
						value: obj
					}, undefined, {
						forcePartialUpdates: true
					});
				}
			}
		},
		jsonQueueSubPropertyChange: function (prop, name, subName) {
			var obj = {};
			obj[name] = {};
			obj[name][subName] = this[prop][name][subName];

			if(this.db) {
				if(this.id) {
					this.db.queueChange({
						scope: this.changeScope,
						name: this.id,
						subName: prop,
						subMerge: subName,
						masterValue: this[prop],
						value: obj
					});
				} else {
					this.db.queueChange({
						scope: this.changeScope,
						name: prop,
						value: obj
					}, undefined, {
						forcePartialUpdates: true
					});
				}
			}
		},

		queueEvent: function(event, options) {
			if(!$.userEvents) {
				return;
			}
			options = $.extend({}, options);

			if(options.permanent) {
				event.permanent = true;
			}
			if(options.private) {
				event.private = true;
			}
			if(options.stripDuplicates === false) {
				event.stripDuplicates = options.stripDuplicates;
			}
			if(options.extras) {
				event.extras = options.extras;
			}

			$.userEvents.addEvent(event);
		},

		cloneObject: function(obj) {
			if ($.isArray(obj)) {
				obj = $.merge([], obj);

				for(var i = 0; i < obj.length; i++) {
					obj[i] = this.cloneObject(obj[i]);
				}

				return obj;
			} else if (typeof obj == 'object' && obj !== null) {
				return $.extend(true, {}, obj);
			} else {
				return obj;
			}
		},

		changeScope: 'pages'
	}, settings);

	return obj;
};