import { s3Client, plicV1Axios, plicAxios, logAxiosError, shouldRetryAll } from '../../utils/axios';
import ExifReader from 'exifreader';
plicV1Axios.defaults.raxConfig.httpMethodsToRetry = ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'POST'];
import Decimal from 'decimal.js';
import createCanvas from '../../utils/create-canvas';

export default {
	methods: {
		startUpload(upload) {
			if(upload.startUpload) {
				upload.startUpload(this);
				return;
			}

			let filename = upload.file.name;
			if(filename.length >= 254) {
				let filenameParts = filename.split('.');
				let baseFilename = filenameParts.slice(0, filenameParts.length - 1).join('.');
				filename = baseFilename.substr(0, 240) + '.' + filenameParts[filenameParts.length - 1];

				// File.name is read-only, this allows us to change it so we can use it both in creating photo and uploading to S3 as form data
				Object.defineProperty(upload.file, 'name', {
					value: filename
				});
			}
			let photoDetails = {
				upload_file_name: upload.file.name,
				upload_content_type: upload.file.type,
				upload_file_size: upload.file.size
			};
			if(this.tags && this.tags.length) {
				photoDetails.tags = this.tags;
			}
			if(this.photoCategory) {
				photoDetails.category = upload.photoCategory ?? this.photoCategory;
			}
			if(upload.cropData) {
				let x = new Decimal(upload.cropData.CropL);
				let y = new Decimal(upload.cropData.CropT);
				let width = new Decimal(upload.cropData.CropW);
				let height = new Decimal(upload.cropData.CropH);

				let xDiff = x.plus(width).minus(1);
				if(xDiff.greaterThan(0)) {
					x = Decimal.max(0, x.minus(xDiff));
				}

				let yDiff = y.plus(height).minus(1);
				if(yDiff.greaterThan(0)) {
					y = Decimal.max(0, y.minus(yDiff));
				}

				photoDetails.photo_crop = {
					name: 'AHS Crop',
					x,
					y,
					width,
					height
				};
			}

			let alreadyRotated = false;
			new Promise((resolve) => {
				let img = new Image();
				img.onload = () => {
					photoDetails.width = img.width;
					photoDetails.height = img.height;

					document.body.appendChild(img);
					let computedStyle = getComputedStyle(img);
					alreadyRotated = computedStyle['image-orientation'] === 'from-image';
					document.body.removeChild(img);

					try {
						if(this.isImageTransparentPng(upload.file, img)) {
							photoDetails.chroma_key = 'processed';
						}
					} catch(error) {
						let uploadData = this.getBugsnagUploadData(upload);
						console.error('Failed to read png transparency: ' + error.message, uploadData);

						if(window.bugsnagClient) {
							// Really a regular error - sometimes we just catch everything but still want this to be logged
							window.bugsnagClient.notify(error, (event) => {
								event.errors[0].errorMessage = event.groupingHash = 'Failed to read png transparency';
								event.addMetadata('message', error.message);
								event.addMetadata('upload', uploadData);
							});
						}
					} finally {
						resolve();
					}

					// Explicitly make sure this is unloaded from memory
					URL.revokeObjectURL(img.src);
				};
				img.onerror = (error) => {
					console.error('failed to load image: ', error);
					resolve();

					// Explicitly make sure this is unloaded from memory
					URL.revokeObjectURL(img.src);
				};

				if(upload.file.downloadUrl) {
					resolve();
				} else {
					img.src = URL.createObjectURL(upload.file);
				}
			}).then(() => {
				// If we don't have any dimensions to rotate anyways, skip
				if(!photoDetails.width || !photoDetails.height || upload.file.name.toLowerCase().endsWith('.png') || alreadyRotated) {
					return;
				}

				return this.readExifTags(upload).then((tags) => {
					if(tags.Orientation && tags.Orientation.value && (tags.Orientation.value >= 5 && tags.Orientation.value <= 8)) {
						let swap = photoDetails.width;
						photoDetails.width = photoDetails.height;
						photoDetails.height = swap;
					}
				});
			}).then(() => {
				return plicV1Axios.post(`albums/${this.albumId}/photos.json`, {
					albumId: this.albumId,
					photo: photoDetails,
					merge_duplicate: true,
					album_import_id: this.albumImportId
				});
			}).then((data) => {
				let photo = data.data.photo;
				upload.photo = photo;
				
				if(photo.presigned_post) {
					upload.isNewPhoto = true;
					this.uploadFileToS3(upload, photo.presigned_post);
				} else {
					this.finishUpload(upload);
				}
			}).catch((error) => {
				this.failedUpload(upload, this.i18n('uploader.errors.uploadPLIC'), error, true);
			});
		},
		isImageTransparentPng(file, img) {
			if(!file.name.toLowerCase().endsWith('.png')) {
				return false;
			}
			let imgWidth = Math.min(512, img.width);
			let imgHeight = Math.min(512, img.height);

			let { context } = createCanvas(imgWidth, imgHeight);
			context.drawImage(img, 0, 0);
			let imgData = context.getImageData(0, 0, imgWidth, imgHeight);
			let pixels = imgData.data;
			
			for(let i = 0; i < pixels.length; i += 4) {
				if(pixels[i + 3] < 255) {
					return true;
				}
			}

			return false;
		},
		readExifTags(upload) {
			return new Promise((resolve) => {
				try {
					const reader = new FileReader();

					reader.onload = function (readerEvent) {
						try {
							const tags = ExifReader.load(readerEvent.target.result);

							// The MakerNote tag can be really large. Remove it to lower memory usage
							delete tags['MakerNote'];

							resolve(tags);
						} catch (error) {
							console.error('Failed to get EXIF data for file: ', error);
							resolve({});
						}
					};

					// We only need the start of the file for the Exif info.
					reader.readAsArrayBuffer(upload.file.slice(0, 128 * 1024));
				} catch(error) {
					console.error('Failed to read file for EXIF data');
					resolve({});
				}
			});
		},

		uploadFileToS3(upload, presigned_post) {
			if(upload.file.downloadUrl) {
				this.uploadFileToS3Url(upload, presigned_post);
			} else {
				this.uploadFileToS3Direct(upload, presigned_post);
			}
		},
		uploadFileToS3Direct(upload, presigned_post) {
			let formData = new FormData();
			for(var id in presigned_post.fields) {
				formData.append(id, presigned_post.fields[id]);
			}
			formData.append('file', upload.file);

			upload.progress = 1;
			s3Client.post(presigned_post.url, formData, {
				onUploadProgress: (event) => {
					upload.progress = Math.max(1, Math.min(upload.maxDefaultPercentage ?? 99, (event.loaded / event.total) * 100));

					if(this.uploaded !== undefined) {
						let uploaded = event.loaded - this.uploaded;
						this.$emit('uploaded-bytes', uploaded);
						this.uploaded = event.loaded;
					}
				}
			}).then(() => {
				this.verifyUpload(upload);
			}).catch((error) => {
				this.failedUpload(upload, this.i18n('uploader.errors.uploadS3'), error);
			});
		},
		uploadFileToS3Url(upload, presigned_post) {
			upload.progress = 5;

			let fileClone = { ...upload.file };
			delete fileClone.authToken;
			let params = {
				presignedPost: presigned_post,
				file: fileClone,
				authToken: upload.file.authToken
			};

			s3Client.post('/api/v1/upload-url-to-s3', params).then(() => {
				this.verifyUpload(upload);
			}).catch((error) => {
				this.failedUpload(upload, this.i18n('uploader.errors.uploadS3'), error);
			});
		},
		verifyUpload(upload) {
			upload.progress = upload.maxDefaultPercentage ?? 99;
			plicAxios.patch(`photos/${upload.photo.id}/verify.json`, null, {
				raxConfig: {
					shouldRetry: shouldRetryAll
				}
			}).then((data) => {
				let photo = data.data?.photo;
				if(photo) {
					upload.photo = photo;
				}

				upload.progress = 100;
				this.finishUpload(upload);
			}).catch((error) => {
				this.failedUpload(upload, this.i18n('uploader.errors.verifyPLIC'), error, true);
			});
		},
		failedUpload(upload, errorMessage, error, logBugsnag = false, extras = {}) {
			upload.error = this.errorMessage = errorMessage;
			upload.uploading = false;
			console.error(errorMessage, error);
			this.onFailedUpload(upload, errorMessage, error);

			// Do not log 404's since this happens constantly with someone deleting an album while someone else is uploading to it
			if(logBugsnag && error.response?.status !== 404) {
				logAxiosError(errorMessage, error, {
					upload: this.getBugsnagUploadData(upload),
					...extras
				});
			}
		},
		getBugsnagUploadData(upload) {
			return {
				...upload,
				file: upload.file ? {
					name: upload.file.name,
					webkitRelativePath: upload.file.webkitRelativePath,
					size: upload.file.size,
					type: upload.file.type,
					lastModified: upload.file.lastModified,
					constructor: upload.file.constructor?.name
				} : null
			};
		},
		finishUpload(upload) {
			if(upload.onPhotoUploaded) {
				upload.onPhotoUploaded(() => {
					this.finishUploadComplete(upload);
				}, (errorMessage, error) => {
					this.failedUpload(upload, errorMessage, error, true);
				});
			} else {
				this.finishUploadComplete(upload);
			}
		},
		finishUploadComplete(upload) {
			upload.progress = 100;
			upload.uploaded = true;
			upload.uploading = false;

			this.onFinishUploadComplete(upload);
		}
	}
};