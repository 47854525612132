$.FlowLayoutResizable = function(me, options) {
	$.extend(me, {
		setResizable: function(enabled) {
			if(enabled) {
				if($(this).hasClass('flowLayoutResizable')) {
					return;
				}

				$(this).addClass('flowLayoutResizable');
				this.initResizableHandles();
			} else {
				if(!$(this).hasClass('flowLayoutResizable')) {
					return;
				}

				$(this).removeClass('flowLayoutResizable');
				this.destroyResizableHandlers();
			}
		},
		initResizableHandles: function() {
			$('<div class="resizableHandle northWestHandle subtractWidthHandle subtractHeightHandle">').appendTo(this);
			$('<div class="resizableHandle northHandle verticalHandle subtractHeightHandle">').appendTo(this);
			$('<div class="resizableHandle northEastHandle subtractHeightHandle">').appendTo(this);
			$('<div class="resizableHandle eastHandle horizontalHandle">').appendTo(this);
			$('<div class="resizableHandle southEastHandle">').appendTo(this);
			$('<div class="resizableHandle southHandle verticalHandle">').appendTo(this);
			$('<div class="resizableHandle southWestHandle subtractWidthHandle">').appendTo(this);
			$('<div class="resizableHandle westHandle horizontalHandle subtractWidthHandle">').appendTo(this);

			$(this).find('.resizableHandle').on('mousedown touchstart', function(event) {
				me.startResizeEvent(this, event);

				return false;
			});

			if(this.useScrollWheel) {
				this.initScrollListener();
			}
			this.updateResizableHandles();
		},
		updateResizableHandles: function(rotation) {
			if(this.getRotation) {
				if(!$.isInit(rotation)) {
					rotation = this.getRotation();
				}

				if((rotation >= 45 && rotation <= 135) || (rotation >= 225 && rotation <= 315)) {
					$(this).addClass('rotated90deg');
				} else {
					$(this).removeClass('rotated90deg');
				}
			}
		},
		destroyResizableHandlers: function() {
			$(this).children('.resizableHandle').remove();

			// Remove end event handlers
			if(this.mouseMoveHandler) {
				$('body').off('mousemove touchmove', this.mouseMoveHandler);
				this.mouseMoveHandler = null;
			}

			if(this.mouseUpHandler) {
				$('body').off('mouseup touchend', this.mouseUpHandler);
				this.mouseUpHandler = null;
			}
			this.resizingHandle = null;
			this.destroyScrollListener();
		},

		startResizeEvent: function(handle, event) {
			if(event.metaKey) {
				event.ctrlKey = true;
			}

			this.resizingHandle = handle;
			this.fixTouchEventPositions(event);
			this.resizingStartClientX = event.clientX;
			this.resizingStartClientY = event.clientY;

			this.initResizeProperties();
			this.getSecondaryFocusedElementsForResize().forEach(function(elem) {
				elem.initResizeProperties();
			});

			if(this.mouseMoveHandler) {
				$('body').off('mousemove touchmove', this.mouseMoveHandler);
				this.mouseMoveHandler = null;
			}
			$('body').on('mousemove touchmove', this.mouseMoveHandler = function(event) {
				// Turn this off if we somehow missed the mouseup event
				if(!event.buttons && event.type === 'mousemove') {
					me.stopResizeEvent(event);
					return false;
				}
				me.handleResizeEvent(event, me.resizingHandle);

				return false;
			});
			$('body').one('mouseup touchend', this.mouseUpHandler = function(event) {
				me.mouseUpHandler = null;
				me.stopResizeEvent(event);
				return false;
			});

			this.onStartResize(event);
			this.getSecondaryFocusedElementsForResize().forEach(function(elem) {
				elem.onStartResize(event);
			});

			this.initResizeToolip();
		},
		initResizeProperties: function() {
			this.resizingStartX = $(this).getFloatStyle('left') || 0;
			this.resizingStartY = $(this).getFloatStyle('top') || 0;
			
			this.resizingStartWidth = $(this).getFloatStyle('width') || this.renderedWidth;
			this.resizingStartHeight = $(this).getFloatStyle('height') || this.renderedHeight;

			this.resizingStartRect = this.getBoundingClientRect();
		},
		initResizeToolip: function() {
			if(!this.showDisplayTooltips) {
				return;
			}

			this.resizeTooltip = $('<div class="flowLayoutDisplayTooltip flowLayoutResizeTooltip">Test</div>');
			$('body').append(this.resizeTooltip);

			this.updateResizeTooltip(this.resizingStartWidth, this.resizingStartHeight);
		},

		stopResizeEvent: function(event) {
			var lastResizeTime = this.lastResizeTime = new Date().getTime();
			this.resizingHandle = null;
			this.onStopResize(event);
			this.getSecondaryFocusedElementsForResize().forEach(function(elem) {
				elem.lastResizeTime = lastResizeTime;
				elem.onStopResize(event);
			});
			
			this.removeAlignmentLines();
			if(this.mouseMoveHandler) {
				$('body').off('mousemove touchmove', this.mouseMoveHandler);
				this.mouseMoveHandler = null;
			}

			this.stopResizeToolip();
		},
		stopResizeToolip: function() {
			if(this.resizeTooltip) {
				$(this.resizeTooltip).remove();
				this.resizeTooltip = null;
			}
		},

		handleResizeEvent: function(event, resizingHandle) {
			this.fixTouchEventPositions(event);
			let { xDiff, yDiff } = this.getResizeEventDiffs(event, resizingHandle);
			
			var lastResizeTime = this.lastResizeTime = new Date().getTime();
			event.resizingTarget = this;
			this.updateSizeFromResizeEvent(event, resizingHandle, xDiff, yDiff);
			this.getSecondaryFocusedElementsForResize().forEach(function(elem) {
				elem.lastResizeTime = lastResizeTime;
				elem.updateSizeFromResizeEvent(event, resizingHandle, xDiff, yDiff);
			});

			this.checkResizeAlignmentLines(event, resizingHandle);
			this.updateResizeTooltip($(this).getFloatStyle('width'), $(this).getFloatStyle('height'));

			if(this.updateEditToolbar) {
				$.setSingleTimeout.call(this, 'debounce_updateEditToolbar', () => {
					if($(this).isAttached()) {
						this.updateEditToolbar();
					}
				}, 10);
			}
		},
		getResizeEventDiffs: function(event, resizingHandle) {
			let xDiff = event.clientX - this.resizingStartClientX;
			let yDiff = event.clientY - this.resizingStartClientY;
			
			if(this.getRotation) {
				let rotation = this.getRotation();
				if(rotation) {
					let rotatedPoint = this.rotatePoint(xDiff, yDiff, rotation);
					xDiff = rotatedPoint.x;
					yDiff = rotatedPoint.y;
				}
			}

			if(this.isSubtractWidthHandle(resizingHandle)) {
				xDiff = -xDiff;
			}
			if(this.isSubtractHeightHandle(resizingHandle)) {
				yDiff = -yDiff;
			}

			// Side handles limit to only move on one axis
			if($(resizingHandle).hasClass('verticalHandle')) {
				xDiff = 0;
			} else if($(resizingHandle).hasClass('horizontalHandle')) {
				yDiff = 0;
			}

			return {
				xDiff,
				yDiff
			};
		},
		checkResizeAlignmentLines: function(event, resizingHandle) {
			if(this.alignmentLinesEnabled || this.containInParent) {
				var resizingSide;
				if(resizingHandle) {
					resizingSide = resizingHandle.className.toLowerCase();
				} else {
					resizingSide = '';
				}
				this.snapToAlignmentLines({
					left: $(this).getFloatStyle('left'),
					top: $(this).getFloatStyle('top')
				}, null, {
					uiIdentifier: 'size',
					noCenterAlignment: true,
					preferVerticalSide: (resizingSide.indexOf('west') === -1) ? 'Max' : 'Min',
					preferHorizontalSide: (resizingSide.indexOf('north') === -1) ? 'Max' : 'Min',
					ignoreVerticalSides: (resizingSide.indexOf('west') === -1) ? ['Min', 'MinMax'] : ['Max', 'MaxMin'],
					ignoreHorizontalSides: (resizingSide.indexOf('north') === -1) ? ['Min', 'MinMax'] : ['Max', 'MaxMin'],
					forceContainInParent: this.dontAllowStopInMargins,
					onSnapToAlignmentLine: function(horizontalAlignment, verticalAlignment) {
						this.fixResizeFromAlignmentLine(event, resizingHandle, horizontalAlignment, verticalAlignment);
						this.getSecondaryFocusedElementsForResize().forEach(function(elem) {
							elem.fixResizeFromAlignmentLine(event, resizingHandle, horizontalAlignment, verticalAlignment);
						});
					}
				});
			}
		},
		isSubtractWidthHandle: function(resizingHandle) {
			return $(resizingHandle).hasClass('subtractWidthHandle');
		},
		isSubtractHeightHandle: function(resizingHandle) {
			return $(resizingHandle).hasClass('subtractHeightHandle');
		},
		updateSizeFromResizeEvent: function(event, resizingHandle, xDiff, yDiff, options = {}) {
			var newWidth = Math.max(this.resizingStartWidth + xDiff, this.minWidth);
			var newHeight = Math.max(this.resizingStartHeight + yDiff, this.minHeight);

			var lockedAspectRatio = this.lockedAspectRatio;
			if(lockedAspectRatio) {
				if($(resizingHandle).hasClass('verticalHandle')) {
					newWidth = newHeight * lockedAspectRatio;
				} else if($(resizingHandle).hasClass('horizontalHandle')) {
					newHeight = newWidth / lockedAspectRatio;
				} else {
					// Make sure we can drag from east handle to make smaller
					var maxDimension = Math.max(newWidth, newHeight);
					if(lockedAspectRatio > 1) {
						newWidth = maxDimension;
						newHeight = newWidth / lockedAspectRatio;
					} else {
						newHeight = maxDimension;
						newWidth = newHeight * lockedAspectRatio;
					}
				}
			}

			var css = {
				width: newWidth,
				height: newHeight
			};

			var updatePosition = false;
			var rotation;
			if(this.getRotation && (rotation = this.getRotation())) {
				var widthDiff = newWidth - this.resizingStartWidth;
				var heightDiff = newHeight - this.resizingStartHeight;

				var oldPosition = this.rotateRect(this.resizingStartX, this.resizingStartY, this.resizingStartWidth, this.resizingStartHeight, rotation);
				var newPosition = this.rotateRect(this.resizingStartX, this.resizingStartY, newWidth, newHeight, rotation);
				if($(resizingHandle).hasClass('subtractWidthHandle')) {
					newPosition.x -= widthDiff;
				}
				if($(resizingHandle).hasClass('subtractHeightHandle')) {
					newPosition.y -= heightDiff;
				}

				////////////////// NOTE: This keeps the bounding box left/top the same, but not the top left of the visible box area
				xDiff = oldPosition.x - newPosition.x;
				yDiff = oldPosition.y - newPosition.y;


				// NOTE: This logic is horrible.  It seems like it should be simple rotation math and should be handled without a bunch of if blocks for each individual case, but I could never get the math to work.
				// Needing a bunch of * cos but without * sin, and only on multi angle rotation and not single direction rotations is particularly weird
				// Feel free to fix if you understand the math better than I do...
				var radians = (Math.PI / 180) * rotation;
				var cos = Math.cos(radians);
				if($(resizingHandle).hasClass('westHandle')) {
					xDiff = -xDiff;

					if(rotation <= 90) {
						yDiff = -yDiff;
					} else if(rotation <= 180) {
						xDiff = xDiff - widthDiff * cos;
						yDiff = -yDiff;
					} else if(rotation < 270) {
						xDiff = xDiff - widthDiff * cos;
					}
				} else if($(resizingHandle).hasClass('eastHandle')) {
					// eslint-disable-next-line
					if(rotation <= 90) {
						
					} else if(rotation > 90 && rotation <= 180) {
						xDiff = xDiff + widthDiff * cos;
					} else if(rotation < 270) {
						xDiff = xDiff + widthDiff * cos;
						yDiff = -yDiff;
					} else {
						yDiff = -yDiff;
					}
				}
				
				if($(resizingHandle).hasClass('southHandle')) {
					if(rotation < 180) {
						xDiff = -xDiff;
					}

					if(rotation > 90 && rotation < 270) {
						yDiff = yDiff + heightDiff * cos;
					}
				}
				if($(resizingHandle).hasClass('northHandle')) {
					yDiff = -yDiff;

					// eslint-disable-next-line
					if(rotation <= 90) {

					} else if(rotation > 90 && rotation <= 180) {
						yDiff = yDiff - heightDiff * cos;
					} else if(rotation < 270) {
						xDiff = -xDiff;
						yDiff = yDiff - heightDiff * cos;
					} else {
						xDiff = -xDiff;
					}
				}

				if($(resizingHandle).hasClass('southEastHandle')) {
					if(rotation <= 90) {
						xDiff = -widthDiff - xDiff + (widthDiff * cos);
					} else if(rotation <= 180) {
						xDiff = xDiff + widthDiff * cos;
						yDiff = yDiff + heightDiff * cos;
					} else if(rotation < 270) {
						xDiff = xDiff - widthDiff;
						yDiff = yDiff + heightDiff * cos;
					} else {
						yDiff = -heightDiff - yDiff + (heightDiff * cos);
					}
				}
				if($(resizingHandle).hasClass('northEastHandle')) {
					if(rotation <= 90) {
						yDiff = yDiff - heightDiff - (heightDiff * cos);
					} else if(rotation <= 180) {
						xDiff = xDiff + widthDiff * cos;
						yDiff = yDiff - heightDiff;
					} else if(rotation < 270) {
						xDiff = xDiff - widthDiff;
						yDiff = yDiff - heightDiff;
					} else {
						xDiff = -xDiff - widthDiff + (widthDiff * cos);
						yDiff = -yDiff;
					}
				}
				if($(resizingHandle).hasClass('northWestHandle')) {
					if(rotation <= 90) {
						xDiff = xDiff - widthDiff - (widthDiff * cos);
						yDiff = -yDiff;
					} else if(rotation <= 180) {
						xDiff = xDiff - widthDiff;
						yDiff = yDiff - heightDiff;
					} else if(rotation < 270) {
						xDiff = xDiff - widthDiff;
						yDiff = yDiff - heightDiff;
					} else {
						xDiff = -xDiff;
						yDiff = yDiff - heightDiff - (heightDiff * cos);
					}
				}
				if($(resizingHandle).hasClass('southWestHandle')) {
					if(rotation < 180) {
						xDiff = -xDiff;
						yDiff = -yDiff - heightDiff + (heightDiff * cos);
					} else if(rotation <= 180) {
						xDiff = xDiff - widthDiff;
						yDiff = yDiff - heightDiff;
					} else if(rotation < 270) {
						xDiff = xDiff - widthDiff;
						yDiff = yDiff + heightDiff * cos;
					} else {
						xDiff = xDiff - widthDiff - (widthDiff * cos);
					}
				}

				css.left = this.resizingStartX + xDiff;
				css.top = this.resizingStartY + yDiff;

				updatePosition = true;
			} else {
				if($(resizingHandle).hasClass('subtractWidthHandle')) {
					xDiff = this.resizingStartWidth - newWidth;
					css.left = this.resizingStartX + xDiff;
					updatePosition = true;
				}
				if($(resizingHandle).hasClass('subtractHeightHandle')) {
					yDiff = this.resizingStartHeight - newHeight;
					css.top = this.resizingStartY + yDiff;
					updatePosition = true;
				}
			}

			$(this).css(css);

			if(options.skipResizeEvent !== true) {
				this.onResize(event, resizingHandle);
			}
			if(this.applyGroupBorders) {
				this.applyGroupBorders();
			}
			if(updatePosition) {
				this.saveCurrentPosition({
					propogate: false
				});
			}
		},
		fixResizeFromAlignmentLine: function(event, resizingHandle, horizontalAlignment, verticalAlignment) {
			// Check if we should be modifying the position for north/west handles
			var savePosition = false;
			var width, height, newWidth, newHeight;
			if($(resizingHandle).hasClass('subtractWidthHandle') && verticalAlignment) {
				var left = $(this).getFloatStyle('left');
				var newLeft = left + verticalAlignment.uiDiff;

				width = $(this).getFloatStyle('width');
				newWidth = width - verticalAlignment.uiDiff * 2;

				$(this).css({
					left: newLeft,
					width: newWidth
				});
				savePosition = true;
			}
			if($(resizingHandle).hasClass('subtractHeightHandle') && horizontalAlignment) {
				var top = $(this).getFloatStyle('top');
				var newTop = top + horizontalAlignment.uiDiff;

				height = $(this).getFloatStyle('height');
				newHeight = height - horizontalAlignment.uiDiff * 2;

				$(this).css({
					top: newTop,
					height: newHeight
				});
				savePosition = true;
			}

			// Check if we have a bad aspect ratio from changing size
			if(this.lockedAspectRatio) {
				if(horizontalAlignment && verticalAlignment && horizontalAlignment.containWithin && verticalAlignment.containWithin) {
					var horizontalHeight = $(this).getFloatStyle('height');
					var horizontalWidth = horizontalHeight * this.lockedAspectRatio;
					var verticalWidth = $(this).getFloatStyle('width');
					var verticalHeight = verticalWidth / this.lockedAspectRatio;

					if(horizontalWidth < verticalWidth) {
						$(this).css('width', horizontalWidth);
					} else {
						$(this).css('height', verticalHeight);
					}
				} else if(horizontalAlignment) {
					height = $(this).getFloatStyle('height');
					newWidth = height * this.lockedAspectRatio;
					$(this).css('width', newWidth);
				} else if(verticalAlignment) {
					width = $(this).getFloatStyle('width');
					newHeight = width / this.lockedAspectRatio;
					$(this).css('height', newHeight);
				}
			}

			this.onResize(event, resizingHandle);
			if(savePosition) {
				this.saveCurrentPosition({
					propogate: false
				});
			}
		},
		updateResizeTooltip: function(width, height) {
			if(this.resizeTooltip && this.getCachedRect) {
				var rect = this.getCachedRect();
				var parentOffset = $(this.parentNode).offset();

				$(this.resizeTooltip)
					.text(this.wrapper.getDisplayUnit(width / this.ratio) + ' x ' + this.wrapper.getDisplayUnit(height / this.ratio) + '')
					.css({
						left: rect.x + parentOffset.left,
						top: rect.y + parentOffset.top + rect.height + 4,
						width: rect.width
					});
			}
		},

		initScrollListener: function() {
			window.addEventListener('wheel', this.globalScrollListener = function(event) {
				if(me.hiddenInput && document.activeElement != me.hiddenInput[0]) {
					return;
				}

				var amount = 10;
				if(event.shiftKey) {
					amount = 1;
				}

				if(me.allowCropResizing && me.isNormalImage && me.isNormalImage() && event.ctrlKey) {
					if(event.deltaY < 0) {
						me.changeZoomByFixedAmount(event, amount);
					} else {
						me.changeZoomByFixedAmount(event, -amount);
					}
				}
				// Turning off scroll wheel resizing since it seemed to cause more issues than it was worth - caused issues with text boxes getting bigger than they should be easily
				/*else {
					if(event.deltaY < 0) {
						me.resizeByFixedAmount(event, -amount);
					} else {
						me.resizeByFixedAmount(event, amount);
					}
				}*/

				event.preventDefault();
				return false;
			}, {
				passive: false
			});
		},
		resizeByFixedAmount: function(event, amount) {
			this.onStartResize(event);
			this.getSecondaryFocusedElementsForResize().forEach(function(elem) {
				elem.onStartResize(event);
			});

			this.initResizeProperties();
			this.getSecondaryFocusedElementsForResize().forEach(function(elem) {
				elem.initResizeProperties();
			});

			this.updateSizeFromResizeEvent(event, null, amount, amount);
			this.getSecondaryFocusedElementsForResize().forEach(function(elem) {
				elem.updateSizeFromResizeEvent(event, null, amount, amount);
			});
			this.checkResizeAlignmentLines(event);

			var lastResizeTime = this.lastResizeTime = new Date().getTime();
			this.onStopResize(event);
			this.removeAlignmentLines();
			this.getSecondaryFocusedElementsForResize().forEach(function(elem) {
				elem.lastResizeTime = lastResizeTime;
				elem.onStopResize(event);
			});
		},
		getSecondaryFocusedElementsForResize: function() {
			return this.getSecondaryFocusedElements();
		},
		fixTouchEventPositions: function(event) {
			if(event.originalEvent && event.originalEvent.touches && event.originalEvent.touches.length) {
				event.clientX = event.originalEvent.touches[0].clientX;
				event.clientY = event.originalEvent.touches[0].clientY;
			}
		},
		destroyScrollListener: function() {
			if(this.globalScrollListener) {
				window.removeEventListener('wheel', this.globalScrollListener);
			}
		},
		changeZoomByFixedAmount: function(event, amount) {
			amount = amount / 100;
			var crop = this.instance.crop || {
				left: 0,
				top: 0,
				width: 1,
				height: 1
			};

			var newWidth = Math.max(1, crop.width + crop.width * amount);
			var newHeight = Math.max(1, crop.height + crop.height * amount);

			var widthDiff = newWidth - crop.width;
			var heightDiff = newHeight - crop.height;
			var maxLeft = 1 - newWidth;
			var maxTop = 1 - newHeight;
			this.updateProperty('crop', {
				width: newWidth,
				height: newHeight,
				left: Math.min(0, Math.max(maxLeft, crop.left - widthDiff / 2)),
				top: Math.min(0, Math.max(maxTop, crop.top - heightDiff / 2)),
				percentage: true
			});
			this.applyCrop();
			this.updateToolbarDisplay();
		},

		lockedAspectRatio: false,
		minWidth: 10,
		minHeight: 10,
		useScrollWheel: true,
		allowCropResizing: false
	}, options);

	$.registerEvents(me, [
		'startResize',
		'resize',
		'stopResize'
	]);

	if(me.handlesOutsideBounds) {
		$(me).addClass('handlesOutsideBounds');
	}
};