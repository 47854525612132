/* eslint-disable */
// Unpacked by browser - Webpack is throwing up on trying to pass this instead of window as global
(function(g, r, i) {
    function xa(a, b, h) {
        var l, e, p, q, ya, s, ga, ha, oa, pa, ma, za;
        function I(c) {
            var a = 0
              , qa = J.length
              , na = 0;
            d.autoScale && fb();
            n.old = g.extend({}, n);
            C = K[d.navigation.horizontal ? "width" : "height"]();
            Ia = Y[d.navigation.horizontal ? "width" : "height"]();
            H = L[d.navigation.horizontal ? "outerWidth" : "outerHeight"]();
            J.length = 0;
            n.start = 0;
            n.end = Math.max(H - C, 0);
            if (M) {
                a = v.length;
                P = L.children(d.navigation.slideSelector);
                v.length = 0;
                var b = parseInt(L.css(d.navigation.horizontal ? "paddingLeft" : "paddingTop"), 10) || 0, h = parseInt(L.css(d.navigation.horizontal ? "paddingRight" : "paddingBottom"), 10) || 0, e = "border-box" === g(P).css("boxSizing"), k = "none" !== P.css("float"), l = 0, q = P.length - 1, p;
                H = 0;
                var s, r, ha, ya, u, ga, pa, A, oa, y, ma, z, B, w, F, za;
                P.each(function(c, a) {
                    s = g(a);
                    r = ib(s);
                    ha = rb(r);
                    ya = r.size || d.navigation.slideSize;
                    u = parseInt(s.css(d.navigation.horizontal ? "marginLeft" : "marginTop"), 10) || 0;
                    ga = parseInt(s.css(d.navigation.horizontal ? "marginRight" : "marginBottom"), 10) || 0;
                    A = uc(s, ya);
                    pa = A + u + ga;
                    oa = !u || !ga;
                    y = {};
                    y.element = a;
                    y.options = r;
                    y.type = ha;
                    y.captions = [];
                    y.ID = r.ID && Oa(r.ID) || c;
                    y.size = oa ? A : pa;
                    y.half = y.size / 2;
                    y.start = H + (oa ? u : 0);
                    y.center = y.start - Math.round(C / 2 - y.size / 2);
                    y.end = y.start - C + y.size;
                    y.isParallax = 0;
                    g("." + m + "Caption", s).each(function(c, a) {
                        ma = g(a);
                        z = ib(ma);
                        B = rb(z);
                        w = vc(ma);
                        F = ma.data(m + "styles");
                        za = {
                            element: a,
                            type: B,
                            options: z,
                            animation: w
                        };
                        F && N && d.autoScale && ma.css(sb(g.extend({}, F, w[w.length - 1] && w[w.length - 1].style || {}), Xa, jb));
                        z.parallaxLevel && (y.isParallax = 1);
                        if (z.cover || z.video || z.source)
                            y.hasCaptionMediaEnabled = 1;
                        y.captions.push(za);
                        ma = z = B = w = F = za = null
                    });
                    ya && (s[0].style[d.navigation.horizontal ? "width" : "height"] = A + "px");
                    c || (H += b);
                    H += pa;
                    d.navigation.horizontal || k || ga && u && 0 < c && (H -= Math.min(u, ga));
                    c === q && (y.end += h,
                    H += h,
                    l = oa ? ga : 0);
                    g.isArray(r.cover) && (na = 1);
                    v.push(y);
                    p = y;
                    s = r = ha = ya = u = ga = pa = A = oa = y = null
                });
                L[0].style[d.navigation.horizontal ? "width" : "height"] = (e ? H : H - b - h) + "px";
                H -= l;
                (e = v.length) ? (n.start = v[0][Aa ? "center" : "start"],
                n.end = Aa ? p.center : C < H ? p.end : n.start) : n.start = n.end = 0
            }
            n.center = Math.round(n.end / 2 + n.start / 2);
            g.extend(t, tb(void 0));
            T[0] && 0 < Ia && (d.scrollBar.dynamicHandle ? (Ba = n.start === n.end ? Ia : Math.round(Ia * C / H),
            Ba = O(Ba, d.scrollBar.minHandleSize, Ia),
            T[0].style[d.navigation.horizontal ? "width" : "height"] = Ba + "px") : Ba = T[d.navigation.horizontal ? "outerWidth" : "outerHeight"](),
            D.end = Ia - Ba,
            Ja || wa());
            if (0 < C) {
                var Q = n.start
                  , G = "";
                if (M)
                    for (var E = 0; E < e; E++)
                        y = v[E],
                        Aa ? J.push(y.center) : y.start + y.size > Q && Q <= n.end && (Q = y.start,
                        J.push(Q),
                        Q += C,
                        Q > n.end && Q < n.end + C && J.push(n.end));
                else
                    for (; Q - C < n.end; )
                        J.push(Q),
                        Q += C;
                Q = J.length;
                if (R[0] && qa !== Q) {
                    for (E = 0; E < Q; E++)
                        G += d.pages.pageBuilder.call(f, E);
                    Ya = R.html(G).children();
                    Ya.eq(t.activePage).addClass(d.classes.activeClass)
                }
            }
            0 < e && (!f.initialized || f.initialized && Za) && Va();
            t.slideElementSize = H;
            t.frameSize = C;
            t.scrollbarSize = Ia;
            t.handleSize = Ba;
            M ? (f.initialized ? (t.activeSlide >= e || 0 === a && 0 < e && f.initialized && Za) && kb(t.activeSlide >= e ? e - 1 : 0, !a, c) : (kb(d.startAt, 1),
            f[ra ? "toCenter" : "toStart"](d.startAt, 1)),
            ia(ra && e ? v[t.activeSlide].center : O(n.destination, n.start, n.end), c)) : f.initialized ? ia(O(n.destination, n.start, n.end)) : ia(d.startAt, 1);
            if (!f.initialized || N && na || f.initialized && Za)
                Sb(),
                N || wc();
            d.autoResize && Tb(O(t.activeSlide, t.firstSlide, t.lastSlide), 1);
            f.initialized && ub();
            x("load")
        }
        function ia(c, a, e) {
            if (M && k.released && !e) {
                e = tb(c);
                var b = c > n.start && c < n.end;
                ra ? (b && (c = v[e.centerSlide].center),
                Aa && d.navigation.activateMiddle && kb(e.centerSlide)) : b && (c = v[e.firstSlide].start)
            }
            k.init && k.slideElement && d.dragging.elasticBounds ? c > n.end ? c = n.end + (c - n.end) / 6 : c < n.start && (c = n.start + (c - n.start) / 6) : c = O(c, n.start, n.end);
            ya = sa.now();
            s = 0;
            ga = n.current;
            ha = c;
            oa = c - n.current;
            pa = k.tweese || k.init && !k.slideElement;
            ma = !pa && (a || k.init && k.slideElement || !d.speed);
            k.tweese = 0;
            c !== n.destination && (n.destination = c,
            x("change"),
            Ja || G(),
            d.autoResize && Tb(t.activeSlide, a));
            lb();
            g.extend(t, tb(void 0));
            cb();
            Ya[0] && z.page !== t.activePage && (z.page = t.activePage,
            Ya.removeClass(d.classes.activeClass).eq(t.activePage).addClass(d.classes.activeClass),
            x("activePage", z.page))
        }
        function G() {
            Ja ? (ma ? n.current = ha : pa ? (za = ha - n.current,
            .1 > Math.abs(za) ? n.current = ha : n.current += za * (k.released ? d.dragging.swingSpeed : d.dragging.syncSpeed)) : (s = Math.min(sa.now() - ya, d.speed),
            n.current = ga + oa * jQuery.easing[d.easing](s / d.speed, s, 0, 1, d.speed)),
            ha === n.current ? (n.current = ha,
            k.tweese = Ja = 0) : Ja = ta(G),
            x("move"),
            Z ? L[0].style[Z] = Ka + (d.navigation.horizontal ? "translateX" : "translateY") + "(" + -n.current + "px)" : L[0].style[d.navigation.horizontal ? "left" : "top"] = -Math.round(n.current) + "px",
            !Ja && k.released && (Sb(),
            x("moveEnd")),
            wa()) : (Ja = ta(G),
            k.released && x("moveStart"))
        }
        function wa() {
            T.length && (D.current = n.start === n.end ? 0 : ((k.init && !k.slideElement ? n.destination : n.current) - n.start) / (n.end - n.start) * D.end,
            D.current = O(Math.round(D.current), D.start, D.end),
            z.hPos !== D.current && (z.hPos = D.current,
            Z ? T[0].style[Z] = Ka + (d.navigation.horizontal ? "translateX" : "translateY") + "(" + D.current + "px)" : T[0].style[d.navigation.horizontal ? "left" : "top"] = D.current + "px"))
        }
        function Va() {
            for (var c = "", a = 0, b = v.length, na; a < b; a++)
                na = v[a],
                na = na.options.thumbnail || na.options.cover || 1,
                vb.push(na),
                E[0] && na && (c += d.thumbnails.thumbnailBuilder.call(f, a, na));
            E[0] && (E.html(c).children(),
            d.thumbnails.thumbnailNav && (U ? U.destroy() : (g.extend(!0, mb, {
                moveBy: d.moveBy,
                speed: "undefined" !== typeof d.thumbnails.speed ? d.thumbnails.speed : d.speed,
                easing: d.easing,
                startAt: d.startAt,
                navigation: {
                    horizontal: d.thumbnails.horizontal,
                    navigationType: d.thumbnails.thumbnailNav,
                    slideSize: d.thumbnails.thumbnailSize,
                    activateOn: d.thumbnails.activateOn
                },
                scrolling: {
                    scrollBy: d.thumbnails.scrollBy
                },
                dragging: {
                    mouseDragging: d.thumbnails.mouseDragging,
                    touchDragging: d.thumbnails.touchDragging,
                    swingSpeed: d.dragging.swingSpeed,
                    elasticBounds: d.dragging.elasticBounds
                }
            }),
            U = new xa(E.parent(),mb,{
                active: function(c, a) {
                    f.activate(a)
                }
            })),
            d.thumbnails.preloadThumbnails ? wb(vb).done(function() {
                U.init();
                U.reload()
            }) : (U.init(),
            U.reload())))
        }
        function fb() {
            var c = A.width() / Ub.width;
            jb = c;
            K.height(Ub.height * c)
        }
        function j() {
            B.speed && n.current !== (0 < B.speed ? n.end : n.start) || f.stop();
            Vb = k.init ? ta(j) : 0;
            B.now = sa.now();
            B.pos = n.current + (B.now - B.lastTime) / 1E3 * B.speed;
            ia(k.init ? B.pos : Math.round(B.pos));
            k.init || n.current !== n.destination || x("moveEnd");
            B.lastTime = B.now
        }
        function Na(c, a, d) {
            "boolean" === Ca(a) && (d = a,
            a = i);
            a === i ? ia(n[c], d) : ra && "center" !== c || (a = f.getPosition(a)) && ia(a[c], d, !ra)
        }
        function $(c) {
            return "undefined" !== Ca(c) ? Da(c) ? 0 <= c && c < v.length ? c : -1 : P.index(c) : -1
        }
        function xb(c) {
            return $(Da(c) && 0 > c ? c + v.length : c)
        }
        function kb(c) {
            var a = $(c)
              , b = t.activeSlide;
            if (!M || 0 > a)
                return !1;
            if (z.active !== a) {
                c = v[a];
                N || (f.progressElapsed = 0);
                !d.cycling.loop && a >= v.length - 1 && f.pause();
                P.eq(t.activeSlide).removeClass(d.classes.activeClass);
                P.eq(a).addClass(d.classes.activeClass);
                v[t.activeSlide] && v[t.activeSlide].isParallax && (A.off("." + V),
                aa.off(Wb));
                if (c.isParallax)
                    if (l = c.captions.filter(function(c, a, d) {
                        return !!c.options.parallaxLevel
                    }),
                    !Pa)
                        A.on(Xb, function(c) {
                            ua(yb);
                            var a = A.offset()
                              , S = a.top
                              , b = a.left
                              , qa = c.isTrigger ? 0 : c.pageX - b
                              , f = c.isTrigger ? 0 : c.pageY - S;
                            A.off(Yb).on(Zb, function(c) {
                                e = c.pageX - b - qa;
                                p = c.pageY - S - f;
                                $b()
                            }).one(Yb, function() {
                                A.off(Zb);
                                d.parallax.revert && xc()
                            })
                        }).trigger(Xb);
                    else {
                        if (yc)
                            aa.on(Wb, function(c) {
                                c = c.originalEvent;
                                if ("undefined" !== typeof c && null !== c.beta && null !== c.gamma) {
                                    switch (ac) {
                                    case 0:
                                        e = c.gamma;
                                        p = c.beta;
                                        break;
                                    case 180:
                                        e = -c.gamma;
                                        p = -c.beta;
                                        break;
                                    case -90:
                                        e = -c.beta;
                                        p = c.gamma;
                                        break;
                                    case 90:
                                        e = c.beta,
                                        p = -c.gamma
                                    }
                                    e *= 8;
                                    p *= 8;
                                    $b()
                                }
                            })
                    }
                else
                    q = p = e = l = void 0;
                N || zb(z.active);
                z.active = t.activeSlide = a;
                cb();
                !N && La && Ab();
                $a && clearTimeout($a);
                if (c.captions.length && !N)
                    f.one("slideLoaded", function(c, f) {
                        f === a && ($a = setTimeout(function() {
                            Bb(a);
                            clearTimeout($a)
                        }, 0 > b ? 0 : d.speed + 20))
                    });
                d.deeplinking.linkID && !N && !Qa && f.initialized && zc(a);
                N || Ac(a);
                x("active", a)
            }
            return a
        }
        function tb(c) {
            c = O(Da(c) ? c : n.destination, n.start, n.end);
            for (var a = {}, d = Aa ? 0 : C / 2, b = 0, f = J.length; b < f; b++) {
                if (c >= n.end || b === J.length - 1) {
                    a.activePage = J.length - 1;
                    break
                }
                if (c <= J[b] + d) {
                    a.activePage = b;
                    break
                }
            }
            if (M) {
                for (var f = b = d = !1, e = 0, g = v.length; e < g; e++)
                    if (!1 === d && c <= v[e].start + v[e].half && (d = e),
                    !1 === f && c <= v[e].center + v[e].half && (f = e),
                    e === g - 1 || c <= v[e].end + v[e].half) {
                        b = e;
                        break
                    }
                a.firstSlide = Da(d) ? d : 0;
                a.centerSlide = Da(f) ? f : a.firstSlide;
                a.lastSlide = Da(b) ? b : a.centerSlide
            }
            return a
        }
        function cb() {
            var c = n.destination <= n.start
              , a = n.destination >= n.end
              , b = c ? 1 : a ? 2 : 3;
            z.slideElementPosState !== b && (z.slideElementPosState = b,
            ja.prop("disabled", c).add(ka)[c ? "addClass" : "removeClass"](d.classes.disabledClass),
            la.prop("disabled", a).add(ba)[a ? "addClass" : "removeClass"](d.classes.disabledClass));
            z.fwdbwdState !== b && k.released && (z.fwdbwdState = b,
            ka.prop("disabled", c),
            ba.prop("disabled", a));
            M && (c = 0 === t.activeSlide,
            a = t.activeSlide >= v.length - 1,
            b = c ? 1 : a ? 2 : 3,
            z.slidesButtonState !== b && (z.slidesButtonState = b,
            Ea[c ? "addClass" : "removeClass"](d.classes.disabledClass).prop("disabled", c),
            Fa[a ? "addClass" : "removeClass"](d.classes.disabledClass).prop("disabled", a)))
        }
        function B(c, a, d) {
            c = xb(c);
            a = xb(a);
            if (-1 < c && -1 < a && c !== a && (!d || a !== c - 1) && (d || a !== c + 1)) {
                P.eq(c)[d ? "insertAfter" : "insertBefore"](v[a].element);
                var b = c < a ? c : d ? a : a - 1
                  , e = c > a ? c : d ? a + 1 : a
                  , f = c > a;
                c === t.activeSlide ? z.active = t.activeSlide = d ? f ? a + 1 : a : f ? a : a - 1 : t.activeSlide > b && t.activeSlide < e && (z.active = t.activeSlide += f ? 1 : -1);
                I()
            }
        }
        function db(c, a) {
            for (var d = 0, b = ca[c].length; d < b; d++)
                if (ca[c][d] === a)
                    return d;
            return -1
        }
        function lb() {
            k.released && !f.isPaused && f.resume()
        }
        function eb(c) {
            return Math.round(O(c, D.start, D.end) / D.end * (n.end - n.start)) + n.start
        }
        function qb() {
            k.history[0] = k.history[1];
            k.history[1] = k.history[2];
            k.history[2] = k.history[3];
            k.history[3] = k.delta
        }
        function gb(c) {
            k.released = 0;
            k.source = c;
            k.slideElement = "slideElement" === c
        }
        function hb(c) {
            if (!(k.init || La || bc(c.target))) {
                var a = "touchstart" === c.type
                  , b = c.data.source
                  , e = "slideElement" === b;
                if ("handle" !== b || d.scrollBar.dragHandle && D.start !== D.end)
                    if (!e || (a ? d.dragging.touchDragging : d.dragging.mouseDragging && 2 > c.which))
                        a || va(c, 1),
                        gb(b),
                        k.init = 1,
                        k.$source = g(c.target),
                        k.touch = a,
                        k.pointer = a ? c.originalEvent.touches[0] : c,
                        k.initX = k.pointer.pageX,
                        k.initY = k.pointer.pageY,
                        k.initPos = e ? n.current : D.current,
                        k.initPage = t.activePage,
                        k.start = sa.now(),
                        k.time = 0,
                        k.path = 0,
                        k.delta = 0,
                        k.locked = 0,
                        k.history = [0, 0, 0, 0],
                        k.pathToLock = e ? a ? aa.width() / r.outerWidth * 10 : 10 : 0,
                        k.initLoc = k[d.navigation.horizontal ? "initX" : "initY"],
                        k.deltaMin = e ? -k.initLoc : -D.current,
                        k.deltaMax = e ? document[d.navigation.horizontal ? "width" : "height"] - k.initLoc : D.end - D.current,
                        Ra.on(a ? cc : dc, ec),
                        f.pause(1),
                        (e ? L : T).addClass(d.classes.draggedClass),
                        x("moveStart"),
                        e && (fc = setInterval(qb, 10))
            }
        }
        function ec(c) {
            k.released = "mouseup" === c.type || "touchend" === c.type;
            k.pointer = k.touch ? c.originalEvent[k.released ? "changedTouches" : "touches"][0] : c;
            k.pathX = k.pointer.pageX - k.initX;
            k.pathY = k.pointer.pageY - k.initY;
            k.path = Math.sqrt(Math.pow(k.pathX, 2) + Math.pow(k.pathY, 2));
            k.delta = O(d.navigation.horizontal ? k.pathX : k.pathY, k.deltaMin, k.deltaMax);
            if (!k.locked && k.path > k.pathToLock)
                if (k.locked = 1,
                d.navigation.horizontal ? Math.abs(k.pathX) < Math.abs(k.pathY) : Math.abs(k.pathX) > Math.abs(k.pathY))
                    k.released = 1;
                else if (k.slideElement)
                    k.$source.on(da, Cb);
            k.released ? (k.touch || va(c),
            Jb(),
            d.dragging.releaseSwing && k.slideElement && (k.swing = (k.delta - k.history[0]) * d.dragging.swingSync,
            k.delta += k.swing,
            k.tweese = 10 < Math.abs(k.swing))) : !k.locked && k.touch || va(c);
            ia(k.slideElement ? k.tweese && d.dragging.onePage ? J[O(0 > k.delta ? k.initPage + 1 : k.initPage - 1, 0, J.length - 1)] : Math.round(k.initPos - k.delta) : eb(k.initPos + k.delta))
        }
        function Jb() {
            clearInterval(fc);
            Ra.off(k.touch ? cc : dc, ec);
            (k.slideElement ? L : T).removeClass(d.classes.draggedClass);
            setTimeout(function() {
                k.$source.off(da, Cb)
            });
            f.resume(1);
            n.current === n.destination && k.init && x("moveEnd");
            k.init = 0
        }
        function bc(c) {
            return ~g.inArray(c.nodeName, Bc) || g(c).is(d.dragging.interactive)
        }
        function gc() {
            f.stop();
            Ra.off("mouseup." + u, gc)
        }
        function Ma(c) {
            va(c);
            switch (this) {
            case ba[0]:
            case ka[0]:
                f.moveBy(ba.is(this) ? d.moveBy : -d.moveBy);
                Ra.on("mouseup." + u, gc);
                break;
            case Ea[0]:
                f.prev();
                break;
            case Fa[0]:
                f.next();
                break;
            case ja[0]:
                f.prevPage();
                break;
            case la[0]:
                f.nextPage();
                break;
            case Sa[0]:
                f.toggleFullScreen()
            }
        }
        function Kb(c) {
            F.curDelta = c.wheelDelta ? -c.wheelDelta / 120 : (c.detail || c.deltaY) / 3;
            if (!M)
                return F.curDelta;
            Db = sa.now();
            F.last < Db - F.resetTime && (F.delta = 0);
            F.last = Db;
            F.delta += F.curDelta;
            1 > Math.abs(F.delta) ? F.finalDelta = 0 : (F.finalDelta = Math.round(F.delta / 1),
            F.delta %= 1);
            return F.finalDelta
        }
        function Lb(c) {
            d.scrolling.scrollBy && n.start !== n.end && (va(c, 1),
            f.slideBy(d.scrolling.scrollBy * Kb(c.originalEvent)))
        }
        function Mb(c) {
            d.scrollBar.clickBar && c.target === Y[0] && (va(c),
            ia(eb((d.navigation.horizontal ? c.pageX - Y.offset().left : c.pageY - Y.offset().top) - Ba / 2)))
        }
        function Nb(c) {
            if (d.navigation.keyboardNavBy)
                switch (c.which) {
                case d.navigation.horizontal ? 37 : 38:
                    va(c);
                    f["pages" === d.navigation.keyboardNavBy ? "prevPage" : "prev"]();
                    break;
                case d.navigation.horizontal ? 39 : 40:
                    va(c),
                    f["pages" === d.navigation.keyboardNavBy ? "nextPage" : "next"]()
                }
        }
        function Eb(c) {
            c = g(this);
            if (c.hasClass(m + "Close"))
                Ab();
            else {
                var a = c.parent()[0];
                c = $(a);
                La && Ab();
                a = g(a);
                if (-1 !== c)
                    var d = v[c];
                else {
                    var b = a.parent()
                      , d = ib(b)
                      , d = {
                        type: rb(d),
                        options: d
                    };
                    b.addClass(m + "Media")
                }
                a.children().hide();
                b = Cc({
                    type: d.type,
                    options: d.options
                });
                d = g('<a class="' + m + "Icon " + m + 'Close"></a>');
                a.prepend(b).prepend(d);
                d.bind(da, Eb);
                -1 !== c && (A.addClass(m + "Media"),
                zb(t.activeSlide));
                f.pause(3);
                La = b
            }
            return !1
        }
        function Ob() {
            N && (N = clearTimeout(N));
            x("beforeResize");
            N = setTimeout(function() {
                f.reload(!0);
                U && U.reload(!0);
                ac = "undefined" !== typeof r.orientation ? r.orientation : aa.height() > aa.width() ? 0 : 90;
                x("resize");
                N = clearTimeout(N)
            }, 100)
        }
        function Pb() {
            var c = t.activeSlide
              , a = 0 < v[c].captions.length;
            hc() ? (ea && (ea = ua(ea)),
            a && (f.progressElapsed = 0,
            zb(c),
            x("moveEnd"))) : ((d.cycling.loop || !d.cycling.loop && c !== v.length - 1) && lb(),
            a && setTimeout(function() {
                Bb(c)
            }, 10))
        }
        function Qb() {
            w.isFullScreen() || f.exitFullScreen()
        }
        function Rb(c) {
            bc(this) ? c.stopPropagation() : this.parentNode === L[0] && f.activate(this)
        }
        function Dc() {
            this.parentNode === R[0] && f.activatePage(Ya.index(this))
        }
        function Ec(c) {
            if (d.cycling.pauseOnHover)
                f["mouseenter" === c.type ? "pause" : "resume"](2)
        }
        function x(c, a) {
            if (ca[c]) {
                Fb = ca[c].length;
                for (Ga = Gb.length = 0; Ga < Fb; Ga++)
                    Gb.push(ca[c][Ga]);
                for (Ga = 0; Ga < Fb; Ga++)
                    Gb[Ga].call(f, c, a)
            }
        }
        function uc(c, a) {
            var b = parseInt, e;
            a ? -1 !== a.indexOf("%") ? (e = a.replace("%", ""),
            e = parseInt(C / 100 * e)) : e = a : e = c[d.navigation.horizontal ? "outerWidth" : "outerHeight"]();
            return b(e)
        }
        function Ac(c) {
            var a = $(c);
            c = v[a];
            var d = c.element
              , b = d.hasAttribute(m + "slideloaded");
            x("beforeSlideLoad", a);
            if (b)
                return x("slideLoaded", a),
                !1;
            var e = g(d)
              , f = function() {
                var c = Fc(d);
                if (0 >= c.length)
                    return d.setAttribute(m + "slideloaded", "1"),
                    x("slideLoaded", a),
                    !1;
                var b = ab(e);
                wb(c).done(function() {
                    bb(b);
                    d.setAttribute(m + "slideloaded", "1");
                    f = b = c = null;
                    x("slideLoaded", a)
                })
            };
            if (c.hasCaptionMediaEnabled) {
                c = c.captions.filter(function(c, a, d) {
                    return c.options.cover || c.options.video || c.options.source
                });
                for (var h = c.length, b = 0, k = c.length, l, q, p, n, t, s, r; b < k; b++) {
                    l = c[b];
                    q = l.element;
                    p = g('<div class="' + m + 'LayerContainer"></div>');
                    n = l.options.cover;
                    t = l.type;
                    s = n || nb(l.options.video) && l.options.video;
                    l = g.isPlainObject(s) || "video" == ic(s);
                    r = nb(s);
                    p.prependTo(q);
                    if (r) {
                        var u = ab(e);
                        jc(r.oembed, function(c) {
                            bb(u);
                            c && (s = c[r.inJSON],
                            r.replace && (s = s.replace(r.replace.from, r.replace.to)),
                            ob({
                                cover: s,
                                slideEl: e,
                                insertEl: p,
                                callback: function() {
                                    h--;
                                    h <= k && f()
                                }
                            }))
                        })
                    } else
                        ob({
                            cover: s,
                            isVideo: l,
                            slideEl: e,
                            insertEl: p,
                            callback: function() {
                                h--;
                                h <= k && f()
                            }
                        });
                    "image" !== t && (q = kc(p, t),
                    "link" !== t && q.bind(da, Eb))
                }
            } else
                f()
        }
        function Gc(c, a) {
            for (var d = [], b = 0, e = v.length, f, h, g; b < e; b++)
                f = v[b],
                h = f.start,
                g = h + f.size,
                (h >= c && g <= a || g >= c && h <= c || h <= a && g >= a) && d.push(f);
            return d
        }
        function ob(c) {
            g("> ." + m + "Cover, > ." + m + "LayerCover", c.insertEl).remove();
            if (!c.cover)
                return !1;
            g.isPlainObject(c.cover) && c.cover.poster && Pa && (c.isVideo = 0,
            c.cover = c.cover.poster);
            if (c.isVideo && !Pa) {
                var a = ab(c.slideEl);
                c.slide && x("beforeCoverLoad", c.index);
                var d = [];
                if (g.isPlainObject(c.cover))
                    c.cover.poster && (c.cover.poster = null),
                    g.each(c.cover, function(c, a) {
                        d.push({
                            name: "source",
                            type: W[c],
                            src: a
                        })
                    });
                else
                    var b = c.cover
                      , e = Hb(b, "EXTENSION")
                      , e = g.isPlainObject(e) ? null : e.toLowerCase()
                      , d = [{
                        name: "source",
                        type: W[e],
                        src: b
                    }];
                var f = g(Ta("video", {
                    autoplay: "autoplay",
                    muted: "muted",
                    loop: "loop",
                    controls: !1
                }, d)).addClass(m + "CoverImage").hide()
                  , b = g('<div class="' + m + 'Cover"></div>').append(f);
                c.insertEl.prepend(b);
                x("coverInserted", b[0]);
                f.one("loadedmetadata", function() {
                    var d = f[0];
                    f.show().data({
                        naturalWidth: d.videoWidth,
                        naturalHeight: d.videoHeight
                    });
                    ub(c.slide);
                    bb(a);
                    x("coverLoaded", c.index)
                })
            } else
                a = ab(c.slideEl),
                c.slide && x("beforeCoverLoad", c.index),
                wb(c.cover).done(function(d) {
                    c.slide && x("coverLoaded", c.index);
                    var b = g('<div class="' + (m + (c.slide ? "Cover" : "LayerCover")) + '"><img src="' + c.cover + '" class="' + (m + (c.slide ? "CoverImage" : "LayerCoverImage")) + '" ondragstart="return false" /></div>')
                      , e = g("img", b);
                    bb(a);
                    e.data({
                        naturalWidth: d[0].width,
                        naturalHeight: d[0].height
                    });
                    c.insertEl.prepend(b);
                    c.callback && c.callback.call(this, e);
                    c.slide && x("coverInserted", b[0]);
                    c.slide && ub(c.slide)
                })
        }
        function Sb() {
            var c, a;
            switch (d.preloadMode) {
            case "all":
                c = 0;
                a = H;
                break;
            case "nearby":
                c = O(n.current - C, n.start, n.end);
                a = O(n.current + 2 * C - 5, n.start, n.end + 2 * C);
                break;
            case "instant":
                c = O(n.current + 5, n.start, n.end),
                a = O(n.current + C - 5, n.start, n.end + C)
            }
            Gc(c, a).forEach(function(c, a) {
                if ("content" === c.type)
                    return !0;
                var d = c.element
                  , b = g(d)
                  , e = d.hasAttribute(m + "processed")
                  , f = d.getAttribute(m + "lastcover");
                if (e && !N)
                    return !0;
                e = c.options.cover;
                if (g.isArray(e)) {
                    a = 0;
                    for (var h = e.length, l; a < h; a++)
                        if (l = e[a],
                        l[1] && r.matchMedia(l[1]).matches) {
                            e = l[0];
                            break
                        }
                    g.isArray(e) && (e = e[0][0])
                }
                var S = e || nb(c.options.video) && c.options.video
                  , e = g.isPlainObject(S) || "video" == ic(S)
                  , k = nb(S)
                  , h = g.isPlainObject(S) ? Oa(JSON.stringify(S)) : S;
                if (f === h)
                    return !0;
                if (k) {
                    var q = ab(b);
                    jc(k.oembed, function(a) {
                        bb(q);
                        a && (S = a[k.inJSON],
                        k.replace && (S = S.replace(k.replace.from, k.replace.to)),
                        ob({
                            cover: S,
                            slide: c,
                            slideEl: b,
                            insertEl: b,
                            index: $(d)
                        }))
                    })
                } else
                    ob({
                        cover: S,
                        isVideo: e,
                        slide: c,
                        slideEl: b,
                        insertEl: b,
                        index: $(d)
                    });
                d.setAttribute(m + "processed", "1");
                d.setAttribute(m + "lastcover", h)
            })
        }
        function wc() {
            for (var c = 0, a = v.length, d, b; c < a; c++)
                d = v[c],
                "content" !== d.type && (b = g(d.element),
                d = d.options.icon || d.type,
                g("." + m + lc(d), b)[0] || "content" === d || "image" === d || (b = kc(b, d),
                "link" !== d && b.bind(da, Eb)))
        }
        function kc(c, a) {
            var d = m + lc(a);
            if (!g("." + d, c).length) {
                d = g('<a class="' + m + "Icon " + d + '"></a>');
                if ("link" === a) {
                    var b = $(c)
                      , b = v[b]
                      , e = b.options.link.url && Ua(b.options.link.url) || r.location.href
                      , b = g.extend(!0, {
                        href: e,
                        target: b.options.link.target || null
                    }, b.options.link);
                    b.url && (b.url = null);
                    d[g.fn.attr ? "attr" : "prop"](b)
                }
                c.append(d);
                return d
            }
        }
        function Ab() {
            var c = La.parent();
            La.remove();
            La = null;
            g("." + m + "Close", c).unbind(da).remove();
            c.hasClass(m + "LayerContainer") ? c.parent().removeClass(m + "Media") : A.removeClass(m + "Media");
            c.children().show();
            if (d.cycling.loop || !d.cycling.loop && index !== v.length - 1)
                f.isPaused = 0,
                lb();
            $a || Bb(t.activeSlide)
        }
        function Cc(c) {
            var a = c.type;
            c = c.options;
            var b = c.mp4 || c.video || c.source, e;
            x("beforeGenerateMedia");
            switch (a) {
            case "video":
                var f = Hb(b, "EXTENSION")
                  , h = c.videoFrame
                  , a = c.mp4;
                e = c.webm;
                var l = c.ogv
                  , k = a || e || l || 0
                  , q = mc(b)
                  , f = g.isPlainObject(f) ? null : f.toLowerCase();
                if (/^(avi|mov|mpg|mpeg|mp4|webm|ogv|3gp|m4v)$/i.test(f) || h || a || e || l)
                    if (h || k && d.videoFrame)
                        b = h || d.videoFrame,
                        f = [],
                        a && f.push({
                            type: W.mp4,
                            src: Ua(a)
                        }),
                        e && f.push({
                            type: W.webm,
                            src: Ua(e)
                        }),
                        l && f.push({
                            type: W.ogv,
                            src: Ua(l)
                        }),
                        0 < f.length && (b += (Hc(b, "QUERY") ? "&" : "?") + m.toLowerCase() + "videos=" + Oa(JSON.stringify(f)),
                        c.cover && (b += "&" + m.toLowerCase() + "cover=" + Oa(Ua(c.cover)))),
                        e = g(Ta("iframe", {
                            src: b,
                            scrolling: "no"
                        }));
                    else if (pb(W[f]) || a && pb(W.mp4) || e && pb(W.webm) || l && pb(W.ogv)) {
                        var p = [{
                            name: "source",
                            type: a && W.mp4 || W[f],
                            src: b
                        }];
                        e && p.push({
                            name: "source",
                            type: W.webm,
                            src: e
                        });
                        l && p.push({
                            name: "source",
                            type: W.ogv,
                            src: l
                        });
                        c.HTML5Video && g.each(c.HTML5Video, function(c, a) {
                            p.push(g.extend({}, {
                                name: c
                            }, a))
                        });
                        e = g(Ta("video", {}, p))
                    } else
                        throw "Video not supported!";
                else if (q)
                    e = g(Ta(q.type, {
                        src: q.source,
                        scrolling: "no"
                    }));
                else
                    throw "Video not supported!";
                break;
            case "iframe":
                e = g(Ta("iframe", {
                    src: b
                }));
                break;
            case "flash":
                e = g(Ta("embed", {
                    src: b,
                    flashvars: c.flashvars || null
                }))
            }
            x("mediaGenerated", e[0]);
            return e
        }
        function ub(c) {
            for (var a = c ? [c] : v, b = 0, e = a.length, f, h, l, k, q, p; b < e; b++)
                c = a[b],
                "content" !== c.type && (f = g(c.element),
                h = g("." + m + "CoverImage", f),
                h[0] && (l = (c.options.viewport || d.viewport).toLowerCase(),
                k = h.data(),
                c = f.width(),
                q = f.height(),
                f = c,
                p = q,
                "fit" === l ? (p = nc(f, p, k.naturalWidth, k.naturalHeight),
                f = p.width,
                p = p.height) : "fill" === l ? (p = f / k.naturalWidth * k.naturalHeight,
                p < q && (f = q / k.naturalHeight * k.naturalWidth,
                p = q)) : "center" === l && (nc(f, p, k.naturalWidth, k.naturalHeight, 1),
                f = k.naturalWidth,
                p = k.naturalHeight),
                l = (q > p ? q - p : -(p - q)) / 2,
                c = (c > f ? c - f : -(f - c)) / 2,
                h.css({
                    width: f,
                    height: p,
                    marginTop: l,
                    marginLeft: c
                })))
        }
        function Tb(c, a) {
            var b = $(c)
              , b = g(v[b].element)[d.navigation.horizontal ? "outerHeight" : "outerWidth"]()
              , e = {};
            e[d.navigation.horizontal ? "height" : "width"] = b;
            K.stop().animate(e, a ? 0 : d.speed, d.easing)
        }
        function Bb(c) {
            c = $(c);
            c = v[c];
            c = c.captions;
            if (0 < X.length)
                return !1;
            for (var a = 0, b = c.length, e, f, h; a < b; a++) {
                e = c[a];
                f = g(e.element);
                h = f.data(m + "styles");
                X[a] = {
                    frames: e.animation.length,
                    timeOut: null
                };
                e = {
                    position: "absolute"
                };
                f.show().addClass(d.classes.showedLayersClass).css(e);
                if (!h) {
                    h = f;
                    for (var l = {}, k = 0, p = Xa.length, q = void 0, n = void 0; k < p; k++)
                        q = Xa[k],
                        (n = parseInt(h.css(q), 10) || 0) && (l[q] = n);
                    h = l;
                    f.data(m + "styles", h)
                }
                d.autoScale && (e = g.extend({}, e, sb(h, Xa, jb)),
                f.css(e));
                oc(f, a)
            }
        }
        function zb(c) {
            c = $(c);
            if (v[c] && v[c].captions.length) {
                var a = P.eq(c).find("." + m + "Caption");
                a.msStop(!0);
                f.one("moveEnd", function() {
                    a.removeAttr("style").removeClass(d.classes.showedLayersClass)
                });
                c = 0;
                for (var b = X.length; c < b; c++)
                    clearTimeout(X[c].timeOut);
                X = []
            }
        }
        function oc(c, a, b) {
            var d = v[t.activeSlide].captions[a]
              , e = d.options
              , f = b && e.startAtOnRepeat || 0;
            f && d.animation.length === X[a].frames && (X[a].frames = d.animation.length - f);
            var h = d.animation.length - X[a].frames
              , l = d.animation[h] || {}
              , k = l.style && g.extend({}, l.style, sb(l.style, Xa, jb)) || {};
            X[a].timeOut = setTimeout(function() {
                c.msStop().msAnimate(k, l.speed || 0, l.easing || "swing", function() {
                    X[a].frames--;
                    if (0 < X[a].frames || e.loop)
                        e.loop && 0 === X[a].frames && (X[a].frames = d.animation.length,
                        b = 1),
                        oc(c, a, b)
                })
            }, b && e.dontDelayOnRepeat && h === f ? 0 : l.delay || 0)
        }
        function $b() {
            var c = d.parallax;
            q = [];
            for (var a = 0, b = l.length; a < b; a++) {
                var f = l[a]
                  , h = f.element
                  , k = f.options.parallaxLevel
                  , f = f.options.parallaxAxises || c;
                q[a] = {};
                f.x && (q[a].x = parseInt((c.invertX ? -e : e) / 100 * k),
                h.style.marginLeft = q[a].x + "px");
                f.y && (q[a].y = parseInt((c.invertY ? -p : p) / 100 * k),
                h.style.marginTop = q[a].y + "px");
                f.z && Ka && (q[a].x = parseInt((c.invertX ? -e : e) / 100 * k),
                q[a].y = parseInt((c.invertY ? -p : p) / 100 * k),
                q[a].rX = -q[a].y / 2 % 180,
                q[a].rY = q[a].x / 2 % 180,
                h.style[Z] = "rotateX(" + q[a].rX + "deg) rotateY(" + q[a].rY + "deg)")
            }
        }
        function xc() {
            var c = d.parallax.revertDuration, a, b, e, f, h, k, g, p, n, m = function(s) {
                fa.start || (fa.start = fa.lastTime = s);
                fa.now = s;
                a = fa.now - fa.start;
                Math.min(s - fa.start, c);
                b = a - (fa.now - fa.lastTime);
                s = 0;
                for (var t = l.length; s < t; s++)
                    e = l[s],
                    f = e.element,
                    h = e.options.parallaxAxises || d.parallax,
                    h.x && (k = q[s].x - q[s].x / c * b,
                    f.style.marginLeft = k + "px"),
                    h.y && (g = q[s].y - q[s].y / c * b,
                    f.style.marginTop = g + "px"),
                    h.z && Ka && (p = q[s].rX - q[s].rX / c * b,
                    n = q[s].rY - q[s].rY / c * b,
                    f.style[Z] = "rotateX(" + p + "deg) rotateY(" + n + "deg)");
                fa.lastTime = fa.now;
                a < c ? yb = ta(m) : fa = {}
            };
            yb = ta(m)
        }
        function zc(a) {
            a = v[a];
            Qa = 1;
            r.location.hash = d.deeplinking.linkID.toLowerCase() + d.deeplinking.separator + a.ID.toLowerCase();
            Qa = 0
        }
        function pc(a) {
            var b = r.location.hash.replace("#", "")
              , e = b.toLowerCase().split(d.deeplinking.separator);
            if (!Qa) {
                a && (Qa = 1);
                if (e[0] === d.deeplinking.linkID.toLowerCase()) {
                    for (var h = b = 0, k = v.length; h < k; h++)
                        e[1] == v[h].ID && (b = h);
                    e = b;
                    f.initialized ? f.activate(e) : (d.startRandom = 0,
                    d.startAt = e)
                } else
                    a && 0 === b.length && f.activate(d.startAt);
                a && (Qa = 0)
            }
        }
        var f = this
          , d = g.extend(!0, {}, xa.defaults, b)
          , K = g(a)
          , A = g(a).parent()
          , L = K.children().eq(0)
          , Ib = ib(K)
          , Ub = d.autoScale && (Ib.height && {
            width: Ib.width,
            height: Ib.height
        } || {
            width: A.width(),
            height: A.height()
        }) || null
          , C = 0
          , jb = 1
          , H = 0
          , n = {
            current: 0,
            start: 0,
            center: 0,
            end: 0,
            destination: 0
        }
          , P = 0
          , v = []
          , t = {
            activeSlide: -1,
            firstSlide: 0,
            centerSlide: 0,
            lastSlide: 0,
            activePage: 0
        }
          , Aa = "forceCentered" === d.navigation.navigationType
          , ra = "centered" === d.navigation.navigationType || Aa
          , M = "basic" === d.navigation.navigationType || ra || Aa
          , Y = g(d.scrollBar.scrollBarSource).eq(0)
          , T = Y.children().eq(0)
          , Ia = 0
          , Ba = 0
          , D = {
            start: 0,
            end: 0,
            current: 0
        }
          , R = d.pages.pagesBar && g(d.pages.pagesBar) || {}
          , Ya = 0
          , J = []
          , E = d.thumbnails.thumbnailsBar && g(d.thumbnails.thumbnailsBar) || {}
          , vb = []
          , U = null
          , mb = {}
          , qc = d.scrolling.scrollSource && g(d.scrolling.scrollSource) || K
          , Ic = d.dragging.dragSource && g(d.dragging.dragSource) || K
          , k = {
            released: 1
        }
          , ba = g(d.buttons.forward)
          , ka = g(d.buttons.backward)
          , Ea = g(d.buttons.prev)
          , Fa = g(d.buttons.next)
          , ja = g(d.buttons.prevPage)
          , la = g(d.buttons.nextPage)
          , Sa = g(d.buttons.fullScreen)
          , Za = 0
          , Qa = 0
          , ca = {}
          , z = {};
        za = ma = pa = oa = ha = ga = s = ya = void 0;
        var B = {}
          , fa = {};
        q = p = e = l = void 0;
        var F = {
            last: 0,
            delta: 0,
            resetTime: 200
        }, Ja = 0, fc = 0, ea = 0, Vb = 0, N = 0, $a = 0, yb = 0, X = [], La = null, V = Jc(u), Ga, Fb, Kc = "resize." + V + " orientationchange." + V, Lc = "hashchange." + V, Mc = "keydown." + V, Xb = "mouseenter." + V, Yb = "mouseleave." + V, Zb = "mousemove." + V, Wb = "deviceorientation." + V, Nc = rc + "." + V;
        f.initialized = 0;
        f.options = d;
        f.frame = K[0];
        f.slideElement = L[0];
        f.slides = v;
        f.position = n;
        f.relative = t;
        f.pages = J;
        f.thumbnails = vb;
        f.handlePosition = D;
        f.isFullScreen = 0;
        f.isPaused = 0;
        f.progressElapsed = 0;
        f.uniqId = V;
        f.reload = I;
        f.getPosition = function(a) {
            if (M)
                return a = $(a),
                -1 !== a ? v[a] : !1;
            var b = L.find(a).eq(0);
            return b[0] ? (a = d.navigation.horizontal ? b.offset().left - L.offset().left : b.offset().top - L.offset().top,
            b = b[d.navigation.horizontal ? "outerWidth" : "outerHeight"](),
            {
                start: a,
                center: a - C / 2 + b / 2,
                end: a - C + b,
                size: b
            }) : !1
        }
        ;
        f.moveBy = function(a) {
            B.speed = a;
            !k.init && B.speed && n.current !== (0 < B.speed ? n.end : n.start) && (B.lastTime = sa.now(),
            B.startPos = n.current,
            gb("button"),
            k.init = 1,
            x("moveStart"),
            ua(Vb),
            j())
        }
        ;
        f.stop = function() {
            "button" === k.source && (k.init = 0,
            k.released = 1)
        }
        ;
        f.prev = function(a) {
            f.activate(t.activeSlide - 1, a)
        }
        ;
        f.next = function(a) {
            f.activate(t.activeSlide + 1, a)
        }
        ;
        f.prevPage = function(a) {
            f.activatePage(t.activePage - 1, a)
        }
        ;
        f.nextPage = function(a) {
            f.activatePage(t.activePage + 1, a)
        }
        ;
        f.slideBy = function(a, b) {
            if (a)
                if (M)
                    f[ra ? "toCenter" : "toStart"](O((ra ? t.centerSlide : t.firstSlide) + d.scrolling.scrollBy * a, 0, v.length));
                else
                    ia(n.destination + a, b)
        }
        ;
        f.slideTo = function(a, b) {
            ia(a, b)
        }
        ;
        f.toStart = function(a, b) {
            Na("start", a, b)
        }
        ;
        f.toEnd = function(a, b) {
            Na("end", a, b)
        }
        ;
        f.toCenter = function(a, b) {
            Na("center", a, b)
        }
        ;
        f.getIndex = $;
        f.activate = function(a, b) {
            var e = kb(a);
            d.navigation.smart && !1 !== e && (ra ? f.toCenter(e, b) : e >= t.lastSlide ? f.toStart(e, b) : e <= t.firstSlide ? f.toEnd(e, b) : lb())
        }
        ;
        f.activatePage = function(a, b) {
            Da(a) && (N || (f.progressElapsed = 0),
            !d.cycling.loop && a >= J.length - 1 && f.pause(),
            ia(J[O(a, 0, J.length - 1)], b))
        }
        ;
        f.resume = function(a) {
            if (d.cycling.cycleBy && d.cycling.pauseTime && ("slides" !== d.cycling.cycleBy || v[0]) && !(a < f.isPaused)) {
                f.isPaused = 0;
                ea ? ea = ua(ea) : x("resume");
                var b = v[t.activeSlide].options.pauseTime || d.cycling.pauseTime, e = sa.now(), h, k = function() {
                    h = sa.now();
                    f.progressElapsed += h - e;
                    e = h;
                    x("progress", O(f.progressElapsed / b * 1, 0, 1));
                    if (f.progressElapsed >= b) {
                        switch (d.cycling.cycleBy) {
                        case "slides":
                            f.activate(t.activeSlide >= v.length - 1 ? 0 : t.activeSlide + 1);
                            break;
                        case "pages":
                            f.activatePage(t.activePage >= J.length - 1 ? 0 : t.activePage + 1)
                        }
                        k = null
                    } else
                        ea = ta(k)
                };
                ea = ta(k)
            }
        }
        ;
        f.pause = function(a) {
            a < f.isPaused || (f.isPaused = a || 100,
            ea && (ea = ua(ea),
            x("pause")))
        }
        ;
        f.toggleCycling = function() {
            f[ea ? "pause" : "resume"]()
        }
        ;
        f.enterFullScreen = function() {
            f.isFullScreen || (A.addClass(d.classes.isInFullScreen),
            w.supportsFullScreen ? w.requestFullScreen(A[0]) : aa.triggerHandler("resize"),
            f.isFullScreen = 1,
            x("enterFullScreen"))
        }
        ;
        f.exitFullScreen = function() {
            f.isFullScreen && (A.removeClass(d.classes.isInFullScreen),
            w.supportsFullScreen ? w.cancelFullScreen(A[0]) : aa.triggerHandler("resize"),
            f.isFullScreen = 0,
            x("exitFullScreen"))
        }
        ;
        f.toggleFullScreen = function() {
            f[f.isFullScreen ? "exitFullScreen" : "enterFullScreen"]()
        }
        ;
        f.set = function(a, b) {
            g.isPlainObject(a) ? g.extend(!0, d, a) : d.hasOwnProperty(a) && (d[a] = b);
            U && (g.extend(!0, mb, {
                moveBy: d.moveBy,
                speed: "undefined" !== typeof d.thumbnails.speed ? d.thumbnails.speed : d.speed,
                easing: d.easing,
                startAt: d.startAt,
                navigation: {
                    horizontal: d.thumbnails.horizontal,
                    navigationType: d.thumbnails.thumbnailNav,
                    slideSize: d.thumbnails.thumbnailSize,
                    activateOn: d.thumbnails.activateOn
                },
                scrolling: {
                    scrollBy: d.thumbnails.scrollBy
                },
                dragging: {
                    mouseDragging: d.thumbnails.mouseDragging,
                    touchDragging: d.thumbnails.touchDragging,
                    swingSpeed: d.dragging.swingSpeed,
                    elasticBounds: d.dragging.elasticBounds
                }
            }),
            U.set(mb));
            I()
        }
        ;
        f.add = function(a, b) {
            var d = g(a);
            M ? ("undefined" !== Ca(b) && v[0] ? v.length && d.insertBefore(v[b].element) : d.appendTo(L),
            d.addClass(m + "Slide"),
            b <= t.activeSlide && (z.active = t.activeSlide += d.length)) : L.append(d);
            Za = 1;
            I();
            Za = 0
        }
        ;
        f.remove = function(a) {
            if (M) {
                if (a = xb(a),
                -1 < a) {
                    P.eq(a).remove();
                    var b = a === t.activeSlide && !(Aa && d.navigation.activateMiddle);
                    if (a < t.activeSlide || t.activeSlide >= v.length - 1)
                        z.active = --t.activeSlide;
                    I();
                    b && f.activate(t.activeSlide)
                }
            } else
                g(a).remove(),
                I()
        }
        ;
        f.moveAfter = function(a, b) {
            B(a, b, 1)
        }
        ;
        f.moveBefore = function(a, b) {
            B(a, b)
        }
        ;
        f.one = function(a, b) {
            function d() {
                b.apply(f, arguments);
                f.off(a, d)
            }
            f.on(a, d)
        }
        ;
        f.on = function(a, b) {
            if ("object" === Ca(a))
                for (var d in a) {
                    if (a.hasOwnProperty(d))
                        f.on(d, a[d])
                }
            else if ("function" === Ca(b)) {
                d = a.split(" ");
                for (var e = 0, h = d.length; e < h; e++)
                    ca[d[e]] = ca[d[e]] || [],
                    -1 === db(d[e], b) && ca[d[e]].push(b)
            } else if ("array" === Ca(b))
                for (d = 0,
                e = b.length; d < e; d++)
                    f.on(a, b[d])
        }
        ;
        f.off = function(a, b) {
            if (b instanceof Array)
                for (var d = 0, e = b.length; d < e; d++)
                    f.off(a, b[d]);
            else
                for (var d = a.split(" "), e = 0, h = d.length; e < h; e++)
                    if (ca[d[e]] = ca[d[e]] || [],
                    "undefined" === Ca(b))
                        ca[d[e]].length = 0;
                    else {
                        var k = db(d[e], b);
                        -1 !== k && ca[d[e]].splice(k, 1)
                    }
        }
        ;
        f.destroy = function() {
            qc.add(T).add(Y).add(R).add(ba).add(ka).add(Ea).add(Fa).add(ja).add(la).add(Sa).add(g("." + m + "Icon", K)).unbind("." + u);
            Ra.add(aa).unbind("." + V);
            Ea.add(Fa).add(ja).add(la).removeClass(d.classes.disabledClass);
            P[0] && (P.removeAttr("style").removeClass(m + "Slide").eq(t.activeSlide).removeClass(d.classes.activeClass),
            g("." + m + "Cover, ." + m + "Icon", P).remove());
            R[0] && R.empty();
            E[0] && E.empty();
            var b = [];
            !d.buttons.forward && ba[0] && b.push(ba[0]);
            !d.buttons.backward && ka[0] && b.push(ka[0]);
            !d.buttons.prev && Ea[0] && b.push(Ea[0]);
            !d.buttons.next && Fa[0] && b.push(Fa[0]);
            !d.buttons.prevPage && ja[0] && b.push(ja[0]);
            !d.buttons.nextPage && la[0] && b.push(la[0]);
            !d.buttons.fullScreen && Sa[0] && b.push(Sa[0]);
            !d.pages.pagesBar && R[0] ? b.push(R[0]) : R[0] && R.empty();
            !d.thumbnails.thumbnailsBar && E[0] ? b.push(E[0]) : E[0] && E.empty();
            g(b).remove();
            K.unbind("." + u);
            A.removeClass(d.navigation.horizontal ? d.classes.horizontalClass : d.classes.verticalClass).removeClass(u);
            L.add(T).css(Z || (d.navigation.horizontal ? "left" : "top"), Z ? "none" : 0).removeClass(m + "SlideElement " + m + "ScrollbarHandle");
            g.removeData(a, u);
            K.add(Y).removeClass(m + "Frame " + m + "MouseDraggable " + m + "TouchDraggable " + m + "Scrollbar");
            v.length = J.length = 0;
            z = {};
            f.initialized = 0;
            x("destroy");
            return f
        }
        ;
        f.init = function() {
            if (!f.initialized) {
                Va();
                f.on(h);
                var a = L.add(T);
                K.css("overflow", "hidden").addClass(m + "Frame");
                Z || "static" !== K.css("position") || K.css("position", "relative");
                Z ? Ka && a.css(Z, Ka) : ("static" === Y.css("position") && Y.css("position", "relative"),
                a.css({
                    position: "absolute"
                }));
                d.dragging.mouseDragging && !Pa && K.addClass(m + "MouseDraggable");
                d.dragging.touchDragging && Pa && K.addClass(m + "TouchDraggable");
                L.addClass(m + "SlideElement");
                Y.addClass(m + "Scrollbar");
                T.addClass(m + "ScrollbarHandle");
                d.commands.thumbnails && !E[0] && (E = g("<ul></ul>"),
                A.append(g('<div class="' + m + 'Thumbnails"></div>').html(E)));
                d.commands.pages && !R[0] && (R = g('<ul class="' + m + 'Pages"></ul>'),
                A.append(R));
                d.commands.buttons && M && !la[0] && (la = g('<a class="' + m + "Buttons " + m + 'Next"></a>'),
                A.prepend(la));
                d.commands.buttons && M && !ja[0] && (ja = g('<a class="' + m + "Buttons " + m + 'Prev"></a>'),
                A.prepend(ja));
                !d.commands.buttons || M || ba[0] || (ba = g('<a class="' + m + "Buttons " + m + 'Next"></a>'),
                A.prepend(ba));
                !d.commands.buttons || M || ka[0] || (ka = g('<a class="' + m + "Buttons " + m + 'Prev"></a>'),
                A.prepend(ka));
                I();
                if (U)
                    f.on("active", function(a, b) {
                        U.activate(b)
                    });
                d.deeplinking.linkID && pc();
                P.addClass(m + "Slide");
                d.startAt = d.startRandom ? Math.floor(Math.random() * v.length) : d.startAt;
                f.activate(d.startAt, 1);
                if (ba[0])
                    ba.on(sc, Ma);
                if (ka[0])
                    ka.on(sc, Ma);
                if (Ea[0])
                    Ea.on(da, Ma);
                if (Fa[0])
                    Fa.on(da, Ma);
                if (ja[0])
                    ja.on(da, Ma);
                if (la[0])
                    la.on(da, Ma);
                if (Sa[0])
                    Sa.on(da, Ma);
                qc.on(Oc, Lb);
                if (Y[0])
                    Y.on(da, Mb);
                if (M && d.navigation.activateOn)
                    K.on(d.navigation.activateOn + "." + u, "*", Rb);
                if (R[0] && d.pages.activateOn)
                    R.on(d.pages.activateOn + "." + u, "*", Dc);
                Ic.on(tc, {
                    source: "slideElement"
                }, hb);
                if (T[0])
                    T.on(tc, {
                        source: "handle"
                    }, hb);
                Ra.bind(Mc, Nb).bind(Nc, Pb);
                aa.bind(Kc, Ob).bind(Lc, pc);
                w.supportsFullScreen && aa.bind(w.fullScreenEventName + "." + V, Qb);
                K.on(Pc, Ec);
                K.on("scroll." + u, Qc);
                A.addClass(d.navigation.horizontal ? d.classes.horizontalClass : d.classes.verticalClass).addClass(Pa ? d.classes.isTouchClass : "").addClass(u);
                if (d.cycling.cycleBy)
                    f[d.cycling.startPaused ? "pause" : "resume"]();
                f.initialized = 1;
                x("initialize");
                return f
            }
        }
    }
    function Ca(a) {
        return null === a ? String(a) : "object" === typeof a || "function" === typeof a ? Object.prototype.toString.call(a).match(/\s([a-z]+)/i)[1].toLowerCase() || "object" : typeof a
    }
    function rb(a) {
        var b = "content"
          , h = a.cover
          , l = a.source
          , e = a.mp4 || a.webm || a.ogv || a.video;
        if (a.type)
            return a.type;
        if (mc(l))
            return "video";
        h && (b = "image");
        l && (b = "iframe");
        e && (b = "video");
        a.link && (b = "link");
        return b
    }
    function ib(a) {
        return (a = a.data(u.toLowerCase())) && eval("({" + a + "})") || {}
    }
    function vc(a) {
        return (a = a.data(m.toLowerCase() + "animation")) && eval("([" + a + "])") || {}
    }
    function sb(a, b, h) {
        var l = {};
        g.each(a, function(a, g) {
            if (-1 === b.indexOf(a))
                return !0;
            l[a] = g * h
        });
        return l
    }
    function va(a, b) {
        a.preventDefault();
        b && a.stopPropagation()
    }
    function Cb(a) {
        va(a, 1);
        g(this).off(a.type, Cb)
    }
    function Qc() {
        this.scrollTop = this.scrollLeft = 0
    }
    function Da(a) {
        return ("number" === typeof a || "string" === typeof a) && "" !== a && !isNaN(a)
    }
    function O(a, b, h) {
        return a < b ? b : a > h ? h : a
    }
    function ab(a) {
        var b = g('<div class="' + m + "Icon " + m + 'Loader"></div>')
          , h = g("." + m + "Loader", a);
        h[0] ? b = h : a.prepend(b);
        a = (a = b[0].getAttribute(m + "instances")) && parseInt(a) || 0;
        a++;
        b[0].setAttribute(m + "instances", a);
        return b
    }
    function bb(a) {
        var b = a[0].getAttribute(m + "instances")
          , b = b && parseInt(b) || 1;
        b--;
        a[0].setAttribute(m + "instances", b);
        if (0 >= b)
            return a.remove()
    }
    function nc(a, b, h, l, e) {
        e || (e = a ? b ? Math.min(a / h, b / l) : a / h : b / l);
        return {
            width: Math.round(h * e),
            height: Math.round(l * e),
            ratio: e
        }
    }
    function mc(a) {
        for (var b = null, h = 0, l = cb.length, e; h < l; h++)
            if (e = cb[h],
            e.reg.test(a)) {
                a = a.split(e.split);
                b = {
                    source: e.url.replace(/\{id\}/g, a[e.index]),
                    type: e.iframe && "iframe" || "flash"
                };
                break
            }
        return b
    }
    function nb(a) {
        for (var b = null, h = 0, l = db.length, e; h < l; h++)
            if (e = db[h],
            e.reg.test(a)) {
                b = g.extend(!0, {}, e, {});
                b.oembed = Ua(e.oembed.replace(/\{URL\}/g, a), a);
                break
            }
        return b
    }
    function Ta(a, b, h) {
        var l;
        b = b || {};
        h = h || {};
        switch (a) {
        case "video":
            l = document.createElement("video");
            b = g.extend(!0, Jb, b);
            break;
        case "iframe":
            l = document.createElement("iframe");
            b = g.extend(!0, Kb, b);
            break;
        case "flash":
            l = document.createElement("embed");
            b = g.extend(!0, Lb, b);
            break;
        default:
            l = document.createElement(a)
        }
        eb(l, b);
        g.each(h, function(a, b) {
            if (!b.name)
                return !0;
            var h = document.createElement(b.name);
            b.name = null;
            eb(h, b);
            l.appendChild(h)
        });
        return l
    }
    function eb(a, b) {
        g.each(b, function(b, l) {
            if (!Da(l) && !l)
                return !0;
            Mb(a, b, l)
        })
    }
    function Mb(a, b, h) {
        var l = g.isPlainObject(h) ? function() {
            var a = ""
              , b = 0;
            g.each(h, function(h, l) {
                0 !== b && (a += "&");
                a += h + "=" + Oa(l);
                b++
            });
            return a
        }() : h;
        a.setAttribute(b, l)
    }
    function wb(a) {
        function b() {
            l++;
            l === a.length && e(h)
        }
        var h = []
          , l = 0
          , e = function() {};
        a = "object" !== typeof a ? [a] : a;
        for (var g = 0; g < a.length; g++)
            h[g] = new Image,
            h[g].onload = b,
            h[g].onerror = b,
            h[g].src = a[g];
        return {
            done: function(a) {
                e = a || e
            }
        }
    }
    function Fc(a) {
        var b = "", h = [], l, e, p;
        g("*:not(script, style, ." + m + "Cover, ." + m + "CoverImage, ." + m + "LayerCover, ." + m + "LayerCoverImage, ." + m + "Loader)", a).each(function() {
            l = this;
            e = g(l);
            "img" === l.nodeName.toLowerCase() && l.hasAttribute("src") ? b = e.prop("src") : "none" !== e.css("background-image") && (b = e.css("background-image"));
            if (-1 === b.indexOf("gradient")) {
                var a = b
                  , a = a.replace(/url\(\"/g, "")
                  , a = a.replace(/url\(/g, "")
                  , a = a.replace(/\"\)/g, "");
                b = a = a.replace(/\)/g, "");
                p = b.split(", ");
                for (var a = 0, m = p.length; a < m; a++)
                    0 < p[a].length && !p[a].match(/^(data:)/i) && -1 === h.indexOf(p[a]) && (I.msie && 9 > I.version && Math.random(),
                    h.push(p[a]))
            }
        });
        return h
    }
    function pb(a) {
        var b = document.createElement("video");
        return !(!b.canPlayType || !b.canPlayType(a).replace(/no/, ""))
    }
    function jc(a, b) {
        a = Nb.replace(/\{URL\}/g, Oa(a));
        var h = document.documentMode || I.version
          , h = I.msie && 10 > h;
        g.ajax({
            url: a,
            dataType: h ? "jsonp" : "json",
            cache: !h
        }).success(function(a) {
            b(a)
        }).error(function() {
            b(!1)
        });
        h && (mightySliderCallback = function(a) {
            b(a)
        }
        )
    }
    function Oa(a) {
        a = (a + "").toString();
        return encodeURIComponent(a).replace(/!/g, "%21").replace(/'/g, "%27").replace(/\(/g, "%28").replace(/\)/g, "%29").replace(/\*/g, "%2A")
    }
    function lc(a) {
        a += "";
        return a.charAt(0).toUpperCase() + a.substr(1)
    }
    function Va(a, b) {
        var h = a.replace(/^.*[\/\\]/g, "");
        "string" === typeof b && h.substr(h.length - b.length) === b && (h = h.substr(0, h.length - b.length));
        return h
    }
    function Hc(a, b) {
        for (var h = "source scheme authority userInfo user pass host port relative path directory file query fragment".split(" "), l = /^(?:([^:\/?#]+):)?(?:\/\/()(?:(?:()(?:([^:@]*):?([^:@]*))?@)?([^:\/?#]*)(?::(\d*))?))?()(?:(()(?:(?:[^?#\/]*\/)*)()(?:[^?#]*))(?:\?([^#]*))?(?:#(.*))?)/.exec(a), e = {}, g = 14; g--; )
            l[g] && (e[h[g]] = l[g]);
        if (b)
            return e[b.replace("URL_", "").toLowerCase()];
        e.source = null;
        return e
    }
    function Ua(a, b) {
        function h(a) {
            var b = [];
            a.replace(/^(\.\.?(\/|$))+/, "").replace(/\/(\.(\/|$))+/g, "/").replace(/\/\.\.$/, "/../").replace(/\/?[^\/]*/g, function(a) {
                "/.." === a ? b.pop() : b.push(a)
            });
            return b.join("").replace(/^\//, "/" === a.charAt(0) ? "/" : "")
        }
        function g(a) {
            return (a = String(a).replace(/^\s+|\s+$/g, "").match(/^([^:\/?#]+:)?(\/\/(?:[^:@]*(?::[^:@]*)?@)?(([^:\/?#]*)(?::(\d*))?))?([^?#]*)(\?[^#]*)?(#[\s\S]*)?/)) ? {
                href: a[0] || "",
                protocol: a[1] || "",
                authority: a[2] || "",
                host: a[3] || "",
                hostname: a[4] || "",
                port: a[5] || "",
                pathname: a[6] || "",
                search: a[7] || "",
                hash: a[8] || ""
            } : null
        }
        a = g(a || "");
        b = g(b || r.location.href);
        return a && b ? (a.protocol || b.protocol) + (a.protocol || a.authority ? a.authority : b.authority) + h(a.protocol || a.authority || "/" === a.pathname.charAt(0) ? a.pathname : a.pathname ? (b.authority && !b.pathname ? "/" : "") + b.pathname.slice(0, b.pathname.lastIndexOf("/") + 1) + a.pathname : b.pathname) + (a.protocol || a.authority || a.pathname ? a.search : a.search || b.search) + a.hash : null
    }
    function Hb(a, b) {
        var h = ""
          , g = ""
          , e = 0
          , p = {}
          , q = 0
          , m = 0
          , s = q = !1
          , r = !1;
        if (!a)
            return !1;
        b || (b = "ALL");
        var u = {
            DIRNAME: 1,
            BASENAME: 2,
            EXTENSION: 4,
            FILENAME: 8,
            ALL: 0
        };
        for (g in u)
            u.ALL |= u[g];
        if ("number" !== typeof b) {
            b = [].concat(b);
            for (m = 0; m < b.length; m++)
                u[b[m]] && (e |= u[b[m]]);
            b = e
        }
        g = function(a) {
            a += "";
            var b = a.lastIndexOf(".") + 1;
            return b ? b !== a.length ? a.substr(b) : "" : !1
        }
        ;
        if (b & u.DIRNAME) {
            var w = w(a);
            p.dirname = w === a ? "." : w
        }
        b & u.BASENAME && (!1 === q && (q = Va(a)),
        p.basename = q);
        b & u.EXTENSION && (!1 === q && (q = Va(a)),
        !1 === s && (s = g(q)),
        !1 !== s && (p.extension = s));
        b & u.FILENAME && (!1 === q && (q = Va(a)),
        !1 === s && (s = g(q)),
        !1 === r && (r = q.slice(0, q.length - (s ? s.length + 1 : !1 === s ? 0 : 1))),
        p.filename = r);
        q = 0;
        for (h in p)
            q++;
        return 1 === q ? p[h] : p
    }
    function ic(a) {
        a = Hb(a, "EXTENSION");
        a = g.isPlainObject(a) ? null : a.toLowerCase();
        return 0 <= fb.image.indexOf(a) ? "image" : 0 <= fb.flash.indexOf(a) ? "flash" : 0 <= fb.video.indexOf(a) ? "video" : "iframe"
    }
    function Jc(a, b) {
        "undefined" === typeof a && (a = "");
        var h, g = function(a, b) {
            a = parseInt(a, 10).toString(16);
            return b < a.length ? a.slice(a.length - b) : b > a.length ? Array(1 + (b - a.length)).join("0") + a : a
        }, e = {};
        e.uniqidSeed || (e.uniqidSeed = Math.floor(123456789 * Math.random()));
        e.uniqidSeed++;
        h = a + g(parseInt((new Date).getTime() / 1E3, 10), 8);
        h += g(e.uniqidSeed, 5);
        b && (h += (10 * Math.random()).toFixed(8).toString());
        return h
    }
    function Ob(a, b, h) {
        var g = 0
          , e = 0
          , p = 0
          , q = {
            dev: -6,
            alpha: -5,
            a: -5,
            beta: -4,
            b: -4,
            RC: -3,
            rc: -3,
            "#": -2,
            p: 1,
            pl: 1
        }
          , g = function(a) {
            a = ("" + a).replace(/[_\-+]/g, ".");
            a = a.replace(/([^.\d]+)/g, ".$1.").replace(/\.{2,}/g, ".");
            return a.length ? a.split(".") : [-8]
        }
          , m = function(a) {
            return a ? isNaN(a) ? q[a] || -7 : parseInt(a, 10) : 0
        };
        a = g(a);
        b = g(b);
        e = Math.max(a.length, b.length);
        for (g = 0; g < e; g++)
            if (a[g] !== b[g])
                if (a[g] = m(a[g]),
                b[g] = m(b[g]),
                a[g] < b[g]) {
                    p = -1;
                    break
                } else if (a[g] > b[g]) {
                    p = 1;
                    break
                }
        if (!h)
            return p;
        switch (h) {
        case ">":
            return 0 < p;
        case ">=":
            return 0 <= p;
        case "<=":
            return 0 >= p;
        case "==":
            return 0 === p;
        case "!=":
            return 0 !== p;
        case "<":
            return 0 > p;
        default:
            return null
        }
    }
    var u = "mightySlider", m = "mS", cb = [{
        reg: /youtu\.be\//i,
        split: "/",
        index: 3,
        iframe: 1,
        url: "https://www.youtube.com/embed/{id}?autoplay=1&fs=1&rel=0&enablejsapi=1&wmode=opaque"
    }, {
        reg: /youtube\.com\/watch/i,
        split: "=",
        index: 1,
        iframe: 1,
        url: "https://www.youtube.com/embed/{id}?autoplay=1&fs=1&rel=0&enablejsapi=1&wmode=opaque"
    }, {
        reg: /vimeo\.com\//i,
        split: "/",
        index: 3,
        iframe: 1,
        url: "https://player.vimeo.com/video/{id}?hd=1&autoplay=1&show_title=1&show_byline=1&show_portrait=0&color=&fullscreen=1&api=1"
    }, {
        reg: /metacafe\.com\/watch/i,
        split: "/",
        index: 4,
        url: "http://www.metacafe.com/fplayer/{id}/.swf?playerVars=autoPlay=yes"
    }, {
        reg: /dailymotion\.com\/video/i,
        split: "/",
        index: 4,
        url: "http://www.dailymotion.com/swf/video/{id}?additionalInfos=0&autoStart=1"
    }, {
        reg: /gametrailers\.com/i,
        split: "/",
        index: 5,
        url: "http://www.gametrailers.com/remote_wrap.php?mid={id}"
    }, {
        reg: /collegehumor\.com\/video\//i,
        split: "video/",
        index: 1,
        url: "http://www.collegehumor.com/moogaloop/moogaloop.jukebox.swf?autostart=true&fullscreen=1&use_node_id=true&clip_id={id}"
    }, {
        reg: /collegehumor\.com\/video:/i,
        split: "video:",
        index: 1,
        url: "http://www.collegehumor.com/moogaloop/moogaloop.swf?autoplay=true&fullscreen=1&clip_id={id}"
    }, {
        reg: /ustream\.tv/i,
        split: "/",
        index: 4,
        url: "http://www.ustream.tv/flash/video/{id}?loc=%2F&autoplay=true&vid={id}&disabledComment=true&beginPercent=0.5331&endPercent=0.6292&locale=en_US"
    }, {
        reg: /twitvid\.com/i,
        split: "/",
        index: 3,
        url: "http://www.twitvid.com/player/{id}"
    }, {
        reg: /v\.wordpress\.com/i,
        split: "/",
        index: 3,
        url: "http://s0.videopress.com/player.swf?guid={id}&v=1.01"
    }, {
        reg: /google\.com\/videoplay/i,
        split: "=",
        index: 1,
        url: "http://video.google.com/googleplayer.swf?autoplay=1&hl=en&docId={id}"
    }, {
        reg: /vzaar\.com\/videos/i,
        split: "/",
        index: 4,
        url: "http://view.vzaar.com/{id}.flashplayer?autoplay=true&border=none"
    }], Nb = "http://mightyslider.com/getJSON.php?url={URL}", db = [{
        reg: /vimeo\.com\//i,
        oembed: "https://vimeo.com/api/oembed.json?url={URL}",
        inJSON: "thumbnail_url"
    }, {
        reg: /youtube\.com\/watch/i,
        oembed: "https://www.youtube.com/oembed?url={URL}&format=json",
        inJSON: "thumbnail_url",
        replace: {
            from: "hqdefault.jpg",
            to: "maxresdefault.jpg"
        }
    }, {
        reg: /dailymotion\.com\/video/i,
        oembed: "http://www.dailymotion.com/services/oembed?format=json&url={URL}",
        inJSON: "url"
    }, {
        reg: /500px\.com\/photo\/([0-9]+)/i,
        oembed: "{URL}/oembed.json",
        inJSON: "thumbnail_url",
        replace: {
            from: "3.jpg",
            to: "5.jpg"
        }
    }, {
        reg: /flickr\.com\/photos\/([^\/]+)\/([0-9]+)/i,
        oembed: "https://www.flickr.com/services/oembed?url={URL}&format=json",
        inJSON: "url"
    }, {
        reg: /instagram\.com\/p\//i,
        oembed: "http://api.instagram.com/oembed?url={URL}",
        inJSON: "url"
    }, {
        reg: /deviantart\.com\/p\//i,
        oembed: "http://backend.deviantart.com/oembed?url={URL}",
        inJSON: "url"
    }], W = {
        avi: "video/msvideo",
        mov: "video/quicktime",
        mpg: "video/mpeg",
        mpeg: "video/mpeg",
        mp4: "video/mp4",
        webm: "video/webm",
        ogv: "video/ogg",
        "3gp": "video/3gpp",
        m4v: "video/x-m4v"
    }, fb = {
        flash: "swf",
        image: "bmp gif jpeg jpg png tiff tif jfif jpe",
        video: "avi mov mpg mpeg mp4 webm ogv 3gp m4v"
    }, Gb = [], Bc = ["INPUT", "SELECT", "BUTTON", "TEXTAREA"], Db, Jb = {
        width: "100%",
        height: "100%",
        preload: "preload",
        autoplay: "autoplay",
        controls: "controls"
    }, Kb = {
        width: "100%",
        height: "100%",
        frameborder: 0,
        webkitAllowFullScreen: !0,
        mozallowfullscreen: !0,
        allowFullScreen: !0
    }, Lb = {
        width: "100%",
        height: "100%",
        bgcolor: "#000000",
        quality: "high",
        play: !0,
        loop: !0,
        menu: !0,
        wmode: "transparent",
        scale: "showall",
        allowScriptAccess: "always",
        allowFullScreen: !0,
        fullscreen: "yes"
    }, Xa = "width height fontSize fontSize top left paddingTop paddingLeft paddingBottom paddingRight".split(" "), aa = g(r), Ra = g(document), da = "click." + u + " tap." + u, sc = "touchstart." + u + " mousedown." + u, Oc = g.fn.mousewheel ? "mousewheel." + u : "DOMMouseScroll." + u + " mousewheel." + u, tc = "touchstart." + u + " mousedown." + u, dc = "mousemove." + u + " mouseup." + u, cc = "touchmove." + u + " touchend." + u, Pc = "mouseenter." + u + " mouseleave." + u, ta = r.requestAnimationFrame, ua = r.cancelAnimationFrame || r.cancelRequestAnimationFrame, I, Z, Ka, sa, w, rc, hc, Pa = !!("ontouchstart"in r), ac = "undefined" !== typeof r.orientation ? r.orientation : aa.height() > aa.width() ? 0 : 90, yc = !!r.DeviceOrientationEvent;
    (function() {
        var a, b;
        a = navigator.userAgent;
        a = a.toLowerCase();
        b = /(chrome)[ \/]([\w.]+)/.exec(a) || /(webkit)[ \/]([\w.]+)/.exec(a) || /(opera)(?:.*version|)[ \/]([\w.]+)/.exec(a) || /(msie) ([\w.]+)/.exec(a) || 0 > a.indexOf("compatible") && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(a) || [];
        a = b[1] || "";
        b = b[2] || "0";
        I = {};
        a && (I[a] = !0,
        I.version = b);
        I.chrome ? I.webkit = !0 : I.webkit && (I.safari = !0)
    }
    )();
    (function() {
        for (var a = ["ms", "moz", "webkit", "o"], b = 0, h = 0, g = a.length; h < g && !ua; ++h)
            ta = (ua = r[a[h] + "CancelAnimationFrame"] || r[a[h] + "CancelRequestAnimationFrame"]) && r[a[h] + "RequestAnimationFrame"];
        ua || (ta = function(a) {
            var h = +new Date
              , g = Math.max(0, 16 - (h - b));
            b = h + g;
            return r.setTimeout(function() {
                a(h + g)
            }, g)
        }
        ,
        ua = function(a) {
            clearTimeout(a)
        }
        )
    }
    )();
    (function() {
        function a(a) {
            for (var e = 0, g = b.length, q; e < g; e++)
                if (q = b[e] ? b[e] + a.charAt(0).toUpperCase() + a.slice(1) : a,
                h.style[q] !== i)
                    return q
        }
        var b = ["", "webkit", "moz", "ms", "o"]
          , h = document.createElement("div");
        Z = a("transform");
        Ka = a("perspective") ? "translateZ(0) " : ""
    }
    )();
    (function() {
        for (var a = ["", "webkit", "moz", "ms", "o"], b, h; (h = a.pop()) != i; )
            if (b = (h ? h + "H" : "h") + "idden",
            "boolean" === typeof document[b]) {
                rc = h + "visibilitychange";
                break
            }
        hc = function() {
            return document[b]
        }
    }
    )();
    Array.prototype.filter || (Array.prototype.filter = function(a) {
        if (void 0 === this || null === this)
            throw new TypeError;
        var b = Object(this)
          , h = b.length >>> 0;
        if ("function" != typeof a)
            throw new TypeError;
        for (var g = [], e = 2 <= arguments.length ? arguments[1] : void 0, p = 0, q; p < h; p++)
            p in b && (q = b[p],
            a.call(e, q, p, b) && g.push(q));
        return g
    }
    );
    Array.prototype.forEach || (Array.prototype.forEach = function(a) {
        if (void 0 === this || null === this)
            throw new TypeError;
        var b = Object(this)
          , g = b.length >>> 0;
        if ("function" !== typeof a)
            throw new TypeError;
        for (var l = 2 <= arguments.length ? arguments[1] : void 0, e = 0; e < g; e++)
            e in b && a.call(l, b[e], e, b)
    }
    );
    Date.now || (Date.now = function() {
        return (new Date).getTime()
    }
    );
    (function() {
        "undefined" === typeof r.performance && (r.performance = {});
        if (!r.performance.now) {
            var a = Date.now();
            r.performance.now = function() {
                return Date.now() - a
            }
        }
        sa = r.performance
    }
    )();
    r.matchMedia || (r.matchMedia = function() {
        var a = r.styleMedia || r.media;
        if (!a) {
            var b = document.createElement("style")
              , g = document.getElementsByTagName("script")[0]
              , l = null;
            b.type = "text/css";
            b.id = "matchmediajs-test";
            g.parentNode.insertBefore(b, g);
            l = "getComputedStyle"in r && r.getComputedStyle(b, null) || b.currentStyle;
            a = {
                matchMedium: function(a) {
                    a = "@media " + a + "{ #matchmediajs-test { width: 1px; } }";
                    b.styleSheet ? b.styleSheet.cssText = a : b.textContent = a;
                    return "1px" === l.width
                }
            }
        }
        return function(b) {
            return {
                matches: a.matchMedium(b || "all"),
                media: b || "all"
            }
        }
    }());
    (function() {
        w = {
            supportsFullScreen: !1,
            isFullScreen: function() {
                return !1
            },
            requestFullScreen: function() {},
            cancelFullScreen: function() {},
            fullScreenEventName: "",
            prefix: ""
        };
        var a = ["webkit", "moz", "o", "ms", "khtml"];
        if ("undefined" != typeof document.cancelFullScreen)
            w.supportsFullScreen = !0;
        else
            for (var b = 0, g = a.length; b < g; b++)
                if (w.prefix = a[b],
                "undefined" != typeof document[w.prefix + "CancelFullScreen"]) {
                    w.supportsFullScreen = !0;
                    break
                }
        w.supportsFullScreen && (w.fullScreenEventName = w.prefix + "fullscreenchange",
        w.isFullScreen = function() {
            switch (this.prefix) {
            case "":
                return document.fullScreen;
            case "webkit":
                return document.webkitIsFullScreen;
            default:
                return document[this.prefix + "FullScreen"]
            }
        }
        ,
        w.requestFullScreen = function(a) {
            return "" === this.prefix ? a.requestFullScreen() : a[this.prefix + "RequestFullScreen"]()
        }
        ,
        w.cancelFullScreen = function(a) {
            return "" === this.prefix ? document.cancelFullScreen() : document[this.prefix + "CancelFullScreen"]()
        }
        )
    }
    )();
    var j = g.fn.animate, Pb = g.fn.stop, G, Na, qb, Qb = function(a, b) {
        "function" === typeof a && this.each(a);
        b()
    }, gb = {
        overwrite: 1,
        delay: 1,
        useFrames: 1,
        runBackwards: 1,
        easeParams: 1,
        yoyo: 1,
        immediateRender: 1,
        repeat: 1,
        repeatDelay: 1,
        autoCSS: 1
    }, Rb = function(a, b) {
        for (var g in gb)
            gb[g] && a[g] !== i && (b[g] = a[g])
    }, wa = {}, hb = function() {
        var a = r.GreenSockGlobals || r, b, g;
        if (G = a.TweenMax || a.TweenLite)
            b = (G.version + ".0.0").split("."),
            g = !(0 < Number(b[0]) && 7 < Number(b[1])),
            a = a.com.greensock,
            Na = a.plugins.CSSPlugin,
            wa = a.easing.Ease.map || {};
        G && Na && !g || (G = null,
        !qb && r.console && (r.console.log("The jquery.gsap.js plugin requires the TweenMax (or at least TweenLite and CSSPlugin) JavaScript file(s)." + (g ? " Version " + b.join(".") + " is too old." : "")),
        qb = !0))
    };
    g.fn.msAnimate = function(a, b, h, l) {
        a = a || {};
        if (hb && (hb(),
        !G || !Na) || !0 === a.skipGSAP || "object" === typeof b && "function" === typeof b.step || null != a.scrollTop || null != a.scrollLeft)
            return j.call(this, a, b, h, l);
        var e = g.speed(b, h, l), p = {
            ease: wa[e.easing] || (!1 === e.easing ? wa.linear : wa.swing)
        }, q = this, m = "object" === typeof b ? b.specialEasing : null, s, r, u;
        for (r in a) {
            s = a[r];
            s instanceof Array && wa[s[1]] && (m = m || {},
            m[r] = s[1],
            s = s[0]);
            if ("toggle" === s || "hide" === s || "show" === s)
                return j.call(this, a, b, h, l);
            p[-1 === r.indexOf("-") ? r : g.camelCase(r)] = s
        }
        if (m) {
            u = [];
            for (r in m)
                s = u[u.length] = {},
                Rb(p, s),
                s.ease = wa[m[r]] || p.ease,
                -1 !== r.indexOf("-") && (r = g.camelCase(r)),
                s[r] = p[r];
            0 === u.length && (u = null)
        }
        a = function(a) {
            if (u)
                for (var b = u.length; -1 < --b; )
                    G.to(q, g.fx.off ? 0 : e.duration / 1E3, u[b]);
            b = e.old;
            a = "function" === typeof a ? a : null;
            if ((b = "function" === typeof b ? b : null) || a)
                p.onComplete = a ? Qb : q.each,
                p.onCompleteScope = q,
                p.onCompleteParams = a ? [b, a] : [b];
            G.to(q, g.fx.off ? 0 : e.duration / 1E3, p)
        }
        ;
        !1 !== e.queue ? q.queue(e.queue, a) : a();
        return q
    }
    ;
    g.fn.msStop = function(a, b) {
        Pb.call(this, a, b);
        if (G) {
            if (b)
                for (var g = G.getTweensOf(this), l = g.length, e; -1 < --l; )
                    e = g[l].totalTime() / g[l].totalDuration(),
                    0 < e && 1 > e && g[l].seek(g[l].totalDuration());
            G.killTweensOf(this)
        }
        return this
    }
    ;
    (function() {
        function a(a) {
            a = a || location.href;
            return "#" + a.replace(/^[^#]*#?(.*)$/, "$1")
        }
        var b = document, h, l = g.event.special, e = b.documentMode, p = "onhashchange"in r && (e === i || 7 < e);
        g.fn.hashchange = function(a) {
            return a ? this.bind("hashchange", a) : this.trigger("hashchange")
        }
        ;
        g.fn.hashchange.delay = 50;
        l.hashchange = g.extend(l.hashchange, {
            setup: function() {
                if (p)
                    return !1;
                g(h.start)
            },
            teardown: function() {
                if (p)
                    return !1;
                g(h.stop)
            }
        });
        h = function() {
            function e() {
                var b = a()
                  , h = G(m);
                b !== m ? (w(m = b, h),
                g(r).trigger("hashchange")) : h !== m && (location.href = location.href.replace(/#.*/, "") + h);
                l = setTimeout(e, g.fn.hashchange.delay)
            }
            var h = {}, l, m = a(), u = function(a) {
                return a
            }, w = u, G = u;
            h.start = function() {
                l || e()
            }
            ;
            h.stop = function() {
                l && clearTimeout(l);
                l = i
            }
            ;
            I.msie && !p && function() {
                var l, m;
                h.start = function() {
                    l || (m = (m = g.fn.hashchange.src) && m + a(),
                    l = g('<iframe tabindex="-1" title="empty"/>').hide().one("load", function() {
                        m || w(a());
                        e()
                    }).attr("src", m || "javascript:0").insertAfter("body")[0].contentWindow,
                    b.onpropertychange = function() {
                        try {
                            "title" === event.propertyName && (l.document.title = b.title)
                        } catch (a) {}
                    }
                    )
                }
                ;
                h.stop = u;
                G = function() {
                    return a(l.location.href)
                }
                ;
                w = function(a, e) {
                    var h = l.document
                      , m = g.fn.hashchange.domain;
                    a !== e && (h.title = b.title,
                    h.open(),
                    m && h.write('<script>document.domain="' + m + '"\x3c/script>'),
                    h.close(),
                    l.location.hash = a)
                }
            }();
            return h
        }()
    }
    )();
    r.mightySlider = xa;
    xa.author = "iProDev (Hemn Chawroka). (www.iprodev.com)";
    xa.version = "2.0.0";
    xa.releaseDate = "April 2014";
    g.fn.mightySlider = function(a, b) {
        if (Ob(g.fn.jquery, "1.7", ">=")) {
            var h, l;
            if (!g.isPlainObject(a)) {
                if ("string" === Ca(a) || !1 === a)
                    h = !1 === a ? "destroy" : a,
                    l = Array.prototype.slice.call(arguments, 1);
                a = {}
            }
            return this.each(function(e, m) {
                var q = g.data(m, u);
                q || h ? q && h && q[h] && q[h].apply(q, l) : g.data(m, u, (new xa(m,a,b)).init())
            })
        }
        throw "The jQuery version that was loaded is too old. mightySlider requires jQuery 1.7+";
    }
    ;
    xa.defaults = {
        moveBy: 300,
        speed: 300,
        easing: "swing",
        startAt: 0,
        startRandom: 0,
        viewport: "fill",
        autoScale: 0,
        autoResize: 0,
        videoFrame: null,
        preloadMode: "nearby",
        navigation: {
            horizontal: 1,
            navigationType: "forceCentered",
            slideSelector: null,
            smart: 1,
            activateOn: null,
            activateMiddle: 1,
            slideSize: 0,
            keyboardNavBy: null
        },
        deeplinking: {
            linkID: null,
            scrollTo: 0,
            separator: "/"
        },
        scrolling: {
            scrollSource: null,
            scrollBy: 0
        },
        dragging: {
            dragSource: null,
            mouseDragging: 1,
            touchDragging: 1,
            releaseSwing: 1,
            swingSync: 7.5,
            swingSpeed: .1,
            elasticBounds: 1,
            syncSpeed: .5,
            onePage: 0,
            interactive: null
        },
        scrollBar: {
            scrollBarSource: null,
            dragHandle: 1,
            dynamicHandle: 1,
            minHandleSize: 50,
            clickBar: 1
        },
        pages: {
            pagesBar: null,
            activateOn: null,
            pageBuilder: function(a) {
                return "<li>" + (a + 1) + "</li>"
            }
        },
        thumbnails: {
            thumbnailsBar: null,
            horizontal: 1,
            preloadThumbnails: 1,
            thumbnailNav: "basic",
            activateOn: "click",
            scrollBy: 1,
            mouseDragging: 1,
            touchDragging: 1,
            thumbnailSize: 0,
            thumbnailBuilder: function(a, b) {
                return '<li><img src="' + b + '" /></li>'
            }
        },
        commands: {
            thumbnails: 0,
            pages: 0,
            buttons: 0
        },
        buttons: {
            forward: null,
            backward: null,
            prev: null,
            next: null,
            prevPage: null,
            nextPage: null,
            fullScreen: null
        },
        cycling: {
            cycleBy: null,
            pauseTime: 5E3,
            loop: 1,
            pauseOnHover: 0,
            startPaused: 0
        },
        parallax: {
            x: 1,
            y: 1,
            z: 0,
            invertX: 1,
            invertY: 1,
            revert: 1,
            revertDuration: 200
        },
        classes: {
            isTouchClass: "isTouch",
            draggedClass: "dragged",
            activeClass: "active",
            disabledClass: "disabled",
            verticalClass: "vertical",
            horizontalClass: "horizontal",
            showedLayersClass: "showed",
            isInFullScreen: "isInFullScreen"
        }
    }
}
)(jQuery, window);