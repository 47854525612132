$.UserActivityModule = function(obj, settings) {
	$.extend(obj, {
		getActiveUsers: function() {
			return this.activeUsers;
		},
		getCurrentUserIds: function() {
			return $.merge(this.activeUsers.map(function(user) {
				return user.userId;
			}), [$.CurrentUser.userId]);
		},
		setActiveUsers: function (activeUsers) {
			this.activeUsers = activeUsers;
			if (activeUsers && activeUsers.length) {
				if (this.usersLoggedInLabels) {
					$(this.usersLoggedInLabels).empty();

					for(var i = 0; i < activeUsers.length && i < 30; i++) {
						var user = activeUsers[i];
						user.color = this.activeUserColors[i % this.activeUserColors.length];
						var label = $('<a class="ui empty circular label userActivityLabel">')[0];
						label.user = user;
						$(label).attr('id', 'userActivityLabel-' + user.userId);
						label.updatePopup = function () {
							var user = this.user;
							if($(this).hasClass('hasPopup')) {
								$(this).popup('destroy')
							}

							var popup = {};
							if($.isInit(user.currentPage) && obj.showLastSeen) {
								popup.title = user.name;
								popup.content = 'Last seen on ' + obj.formatLastPageSeen(user.currentPage);
							} else {
								popup.content = user.name;
							}
							$(this).popup(popup).addClass('hasPopup');
						};

						$(label).addClass(user.color);
						label.updatePopup();

						$(this.usersLoggedInLabels).append(label);

						if(this.onUpdateCurrentSelection) {
							this.onUpdateCurrentSelection(user, user.currentSelection);
						}
					}
					$(this.usersLoggedInLabels).show();

					var labelsCount = $(this.usersLoggedInLabels).children().length;
					var labelsWidth = Math.ceil(labelsCount / 3) * 1.2;
					$(this.usersLoggedInLabels).css('width', Math.max(2.4, labelsWidth) + 'em');
					if(labelsCount > 4) {
						$(this.usersLoggedInLabels).css('height', '3.6em');
					} else {
						$(this.usersLoggedInLabels).css('height', '');
					}

					this.startActiveUsersRefresh();
				}
			} else {
				if (this.usersLoggedInLabels) {
					$(this.usersLoggedInLabels).hide();
					this.stopActiveUsersRefresh();
				}
			}
		},
		formatLastPageSeen: function(page) {
			if(isNaN(page)) {
				return page;
			} else {
				var pageOffset = $.PAGE_OFFSET ? $.PAGE_OFFSET : 0;
				return 'page ' + (page - pageOffset);
			}
		},

		updateUserActivity: function (user) {
			if (!this.activeUsers) {
				return;
			}

			var match = this.activeUsers.getMatch(user, 'userId');
			if (match) {
				match.lastActive = moment().toISOString();
				user = $.extend(match, {
					currentPage: user.currentPage,
					currentSelection: user.currentSelection ? user.currentSelection : null
				});

				var label = $('#userActivityLabel-' + user.userId)[0];
				if (label) {
					label.updatePopup();
				}
			} else {
				this.activeUsers.push(user);
				this.setActiveUsers(this.activeUsers);

				if(window.alertify) {
					window.alertify.success(user.name + ' just started working on this ' + this.activitySubjectLabel);
				}
			}

			if(this.onUpdateCurrentSelection) {
				this.onUpdateCurrentSelection(user, user.currentSelection);
			}
		},
		startActiveUsersRefresh: function () {
			var me = this;
			this.stopActiveUsersRefresh();

			this.activeUsersTimer = window.setTimeout(function () {
				me.activeUsersTimer = null;

				$(me.usersLoggedInLabels).find('.userActivityLabel').each(function () {
					var user = this.user;

					var lastActive = new Date(user.lastActive).getTime();
					var now = new Date().getTime();
					var secondsAgo = Math.floor((now - lastActive) / 1000);
					if (secondsAgo < me.maxActiveTime) {
						this.updatePopup();
					} else {
						me.activeUsers.removeItem(user);
						if(me.onUpdateCurrentSelection) {
							me.onUpdateCurrentSelection(user, null);
						}
						me.setActiveUsers(me.activeUsers);
					}
				});

				me.startActiveUsersRefresh();
			}, this.activeUsersTimeRefresh);
		},
		stopActiveUsersRefresh: function () {
			if (this.activeUsersTimer) {
				window.clearTimeout(this.activeUsersTimer);
				this.activeUsersTimer = null;
			}
		},
		onConsumeSocketMessage: function(msg) {
			if(msg.keepAlive && msg.keepAlive.userId != $.CurrentUser.userId) {
				this.updateUserActivity(msg.keepAlive);
			} else if(msg.activeUsers) {
				this.setActiveUsers(msg.activeUsers);
			}

			if(msg.events && this.userEvents) {
				this.userEvents.consumeEvents(msg.events);
			}
		},
		setUsersLoggedInLabel: function(usersLoggedInLabels) {
			this.usersLoggedInLabels = usersLoggedInLabels;
			this.setupUsersLoggedInLabel();
		},
		setupUsersLoggedInLabel: function() {
			$(this.usersLoggedInLabels).addClass('usersLoggedInLabels').hide();
			this.activeUsers = [];
	
			this.onSocketMessage = function(msg) {
				obj.onConsumeSocketMessage(msg);
			};
		},

		activeUsersTimeRefresh: 30000,
		maxActiveTime: 45,
		showLastSeen: true,
		activeUserColors: ['green', 'pink', 'blue', 'red', 'yellow', 'orange', 'olive', 'teal', 'violet', 'purple', 'brown', 'grey', 'black'],
		activitySubjectLabel: 'Book',
		activeUsers: []
	}, settings);

	if(obj.usersLoggedInLabels) {
		obj.setupUsersLoggedInLabel();
	}
};