$.FlowLayoutCell = function(definition, ratio, extras) {
	var wrapper = document.createElement('div');
	wrapper.className = 'flowCell emptyCell';
	if(definition.largeCell) {
		$(wrapper).addClass('largeCell');
	}
	if(definition.hidden) {
		$(wrapper).addClass('hiddenCell');
	}

	var _setEditable = null;
	$.extend(wrapper, {
		setUser: function (user, forceUpdate) {
			// If setting to null and already null nothing to do
			if (user == this.user && forceUpdate !== true) {
				if(user) {
					// Should check if this is actually needed.  If it is might be better to fix root issue since this is slow
					// this.updateLabelCSS();
				}
				return;
			}

			var noUserBefore = !this.user;
			this.user = user;

			if (user) {
				if (noUserBefore) {
					this.img.style.display = '';
					$(this).removeClass('emptyCell');
				}
				this.setupUserImage(user);

				if(_setEditable && noUserBefore) {
					_setEditable.call(this, this.cellEditable);
					$(wrapper).css('cursor', 'pointer');
				}
			} else {
				this.img.style.display = 'none';
				$(this).addClass('emptyCell');

				if(_setEditable && !$(this).hasClass('allowSelectingCell')) {
					_setEditable.call(this, false);
				}
			}

			this.setUserLabel(user);
			if(forceUpdate) {
				this.updateLabelCSS();
			}
			this.setUserTexts(user);
			this.setUserCrop(user);
			this.updateSubjectImagePosition();
			this.updateSubjectImageSize();
			this.updateWavePosition();
		},
		refreshUser: function() {
			this.setUser(this.user, true);
		},
		setupUserImage: function(user) {
			var url = this.getImageUrlForUser(user);
			$(this.img).LoadImage(url);
			this.setupUserBackgroundImage(user);
		},
		setupUserBackgroundImage: function(user) {
			var backgroundPhotoUrl = this.getSubjectBackgroundUrl(user);
			this.setUserBackgroundImage(backgroundPhotoUrl);
		},
		getSubjectBackgroundUrl: function(user) {
			if(user.yearbookPhoto && user.yearbookPhoto.chroma_key !== 'processed') {
				return null;
			}
			var subjectEffects = this.wrapper.getSubjectEffects() || {};
			this.lastUseGreenScreenBackground = $.isInit(subjectEffects.useGreenScreenBackground) ? subjectEffects.useGreenScreenBackground : 'project background';
			if(subjectEffects.useGreenScreenBackground === false) {
				return null;
			}
			
			if(user.orders && subjectEffects.useGreenScreenBackground === 'ordered background' && user.yearbookPhoto) {
				var orderedPhotos = $.FlowLayoutFrameUtils.getOrderedPhotos(user);

				// We want to grab the photo that exactly matches the photo we are displaying
				// But for prepay orders which had the PNG assigned AFTER the order, but we are displaying the original PNG and not the rendered photo, we need to key off of source_photo_id
				var orderedPhoto = orderedPhotos.find(function(photo) {
					return photo.photo_id == user.yearbookPhoto.id || photo.source_photo_id == user.yearbookPhoto.id;
				});
	
				if(orderedPhoto && orderedPhoto.background_photo_id) {
					return $.getPlicThumbnail(orderedPhoto.background_photo_id, {
						w: 200
					});
				}
			}

			var pageSet = this.wrapper.getPageSet();
			if(!pageSet) {
				return null;
			}

			if(pageSet.projectBackgroundCdnUrl) {
				return pageSet.projectBackgroundCdnUrl;
			} else {
				return $.getPlicThumbnail(pageSet.projectBackgroundId, {
					w: 200
				});
			}
		},
		setUserBackgroundImage: function(backgroundPhotoUrl) {
			if(!backgroundPhotoUrl) {
				if(this.backgroundImage) {
					$(this.backgroundImage).remove();
					this.backgroundImage = null;
				}
				return;
			}

			if(!this.backgroundImage) {
				this.backgroundImage = document.createElement('img');
				$(this.backgroundImage).addClass('greenScreenBackground');
				$(this).find('.imageInnerStyleWrapper').prepend(this.backgroundImage);
			}
			$(this.backgroundImage).LoadImage(backgroundPhotoUrl);
		},
		getImageUrlForUser: function(user) {
			var url;
			if(this.wrapper.forcedDPI && user.yearbookPhoto) {
				var imageDimensions = this.inchWidth * this.wrapper.forcedDPI;

				if(imageDimensions < 200) {
					if(user.photoCdnUrl) {
						return user.photoCdnUrl;
					} else {
						imageDimensions = 200;
					}
				} else if(this.user.yearbookPhoto.width) {
					imageDimensions = Math.min(imageDimensions, this.user.yearbookPhoto.width);
				}

				return $.getPlicThumbnail(this.user.yearbookPhoto, {
					w: Math.round(imageDimensions)
				});
			} else if(user.photoCdnUrl) {
				url = user.photoCdnUrl;
			} else if(user.yearbookPhoto) {
				var photo = $.extend(true, {}, user.yearbookPhoto);
				if(this.refreshPhotoVersion) {
					photo.photoVersion = photo.version_id;
					photo.forceRefresh = true;
				}

				url = $.getPlicThumbnail(photo, {
					w: 200
				});
			} else if(user.photo) {
				url = $.getPlicThumbnail(user.photo, {
					w: 200
				});
			} else {
				url = '/css/images/no-photo.jpg';
			}

			return url;
		},
		setUserCrop: function(user) {
			var img = $(this.img);

			var crop = this.getUserSelectedCrop(user);
			if(crop) {
				var width, height, left, top;
				var cropWidth = (1 / crop.width);
				var cropHeight = (1 / crop.height);
				width = cropWidth * 100 + '%';
				height = cropHeight * 100 + '%';
				left = -(crop.x * cropWidth) * 100 + '%';
				top = -(crop.y * cropHeight) * 100 + '%';

				img.css({
					width: width,
					height: height,
					left: left,
					top: top
				});
			} else {
				if(user && user.yearbookPhoto && this.definition.resizeWrongAspectRatio) {
					var photoRatio = user.yearbookPhoto.width / user.yearbookPhoto.height;
					var boxRatio = this.innerWidth / this.innerHeight;

					if(!$.isWithinDiff(photoRatio, boxRatio, 0.01)) {
						if(boxRatio > photoRatio) {
							var newWidth = this.innerHeight * photoRatio;
							let sideMargins = (this.innerWidth - newWidth) / 2;

							$(this.imgWrapper).css({
								width: newWidth + 'px',
								marginLeft: sideMargins + 'px',
								marginRight: sideMargins + 'px'
							});
						} else {
							var newHeight = this.innerWidth / photoRatio;
							let sideMargins = (this.innerHeight - newHeight) / 2;

							$(this.imgWrapper).css({
								height: newHeight + 'px',
								marginTop: sideMargins + 'px',
								marginBottom: sideMargins + 'px'
							});
						}
					} else {
						$(this.imgWrapper).css({
							width: this.innerWidth + 'px',
							height: this.innerHeight + 'px',
							marginTop: '',
							marginBottom: ''
						});
					}
				}

				img.css({
					width: '',
					height: '',
					left: '',
					top: ''
				});
			}

			this.checkMinimumResolution();
		},
		getUserCropSelection: function() {
			var page = this.wrapper.getPage();
			if(!page) {
				return null;
			}

			var cropSelection = this.instance.cropSelected  || (page.getExtraProperty('subjectEffects') || {})['cropSelection'];
			if(cropSelection !== 'primary crop') {
				return cropSelection
			} else {
				return null;
			}
		},
		getUserSelectedCrop: function(user) {
			if(!user) {
				return null;
			}

			var crop = null;
			if(user.yearbookPhoto && user.yearbookPhoto.yearbookCrop) {
				crop = user.yearbookPhoto.yearbookCrop;
			}

			if(user.yearbookPhoto) {
				var cropSelection = this.getUserCropSelection();
				if(cropSelection) {
					crop = user.yearbookPhoto.photo_crops.find(function(crop) {
						return crop.name.toLowerCase() === cropSelection;
					}) || null;
				}
			}

			return crop;
		},
		setUserLabel: function(user) {
			if (this.label) {
				if(user) {
					this.label.style.display = '';

					var instance = this.getUserLabelInstance(user);
					this.label.setInstance(instance, false);
				} else {
					this.label.style.display = 'none';
				}
			}
		},
		getUserLabelInstance: function(user) {
			var lines = [];
			if (user['Teacher Priority'] > 0 && this.teacherPrefixOrder && (user['Prefix'] || this.teacherPrefixOrder.toLowerCase().indexOf('%prefix%') === -1)) {
				lines.push({
					text: this.setupFieldOrder(user, this.teacherPrefixOrder)
				});

				for(let i = 2; this['teacherPrefixOrder' + i]; i++) {
					let secondLine = this.setupFieldOrder(user, this['teacherPrefixOrder' + i]);

					if(secondLine) {
						if(this.definition.name) {
							lines.push({
								text: secondLine
							});
						} else {
							lines[0].text += ' ' + secondLine;
						}
					}
				}
			} else {
				lines.push({
					text: this.setupFieldOrder(user, this.nameOrder)
				});

				for(let i = 2; this['nameOrder' + i]; i++) {
					// We don't want to show blank second lines for cases like background color
					let secondLine = this.setupFieldOrder(user, this['nameOrder' + i]);
					if(secondLine) {
						if(this.definition.name) {
							lines.push({
								text: secondLine
							});
						} else {
							lines[0].text += ' ' + secondLine;
						}
					}
				}
			}

			var transform = '';
			if(this.largeCell) {
				var page = this.wrapper.getPage();
				if(page) {
					transform = page.getExtraProperty('largeCellTransform') || '';
				}
			}

			return {
				lines: lines,
				transform: transform
			};
		},
		setupFieldOrder: function (user, name) {
			var firstNameRegex = '%first%';
			var firstName = user['First Name'];
			if(!firstName) {
				firstName = '';
				firstNameRegex = /( ?)%first%( ?)/;
			}

			var lastNameRegex = '%last%';
			var lastName = user['Last Name'];
			if(!lastName) {
				lastName = '';
				lastNameRegex = /( ?)%last%( ?)/;
			}

			name = name.replace(firstNameRegex, firstName).replace(lastNameRegex, lastName);
			for (let i in user) {
				// Ignore non-user editable fields
				// Skip Grade so we can re-use renameGradeToWord logic
				if (['id', 'photo', 'photoCdnUrl', 'Grade'].indexOf(i) != -1) {
					continue;
				}

				var replaceValue = $.isInit(user[i]) ? (user[i] + '') : '';

				if (this.autoTitleCase) {
					replaceValue = replaceValue.replace(/(^| )(\w)/g, function (x) {
						return x.toUpperCase();
					});
				}

				var regex = new RegExp('%' + i + '%', 'ig');
				name = name.replace(regex, replaceValue);
			}

			// TODO: Encapsulate all of the above logic into this so we are only doing one replace
			if(name.indexOf('%') !== -1) {
				name = this.label.getDynamicTextWithRep(name, {}, function(field) {
					return wrapper.wrapper.getDynamicFieldRep.call(wrapper.wrapper, field, user, {
						allowBlank: false
					});
				});
			}

			return $.trim(name);
		},
		getUser: function () {
			return this.user;
		},
		updateLabelCSS: function() {
			if(this.label && this.label.instance && this.user) {
				if (this.labelCSS) {
					for(var style in this.labelCSS.css) {
						var value = this.getLabelCSSValue(style, this.labelCSS.css[style]);
						this.label.addStyleToEntireInstance(style, value, false);
					}
				}

				// This is still needed for two lines with different lengths!
				if(this.definition.nameAlign) {
					var side = this.definition.nameAlign;
					if(side == 'inside') {
						// Side layouts
						if(this.wrapper.definition.row && this.wrapper.definition.row.position === 'outside') {
							if(this.wrapper.side == 'Right') {
								side = 'left';
							} else {
								side = 'right';
							}
						} else {
							if(this.wrapper.side == 'Right') {
								side = 'right';
							} else {
								side = 'left';
							}
						}
					} else if(side == 'outside') {
						if(this.wrapper.definition.row && this.wrapper.definition.row.position === 'outside') {
							if(this.wrapper.side == 'Right') {
								side = 'right';
							} else {
								side = 'left';
							}
						} else {
							if(this.wrapper.side == 'Right') {
								side = 'left';
							} else {
								side = 'right';
							}
						}
					}

					// These standard under labels are significantly slower to calculate center alignment for and are already centered anyways
					if(side === 'center' && this.definition.name === 'bottom' && (!this.wrapper.definition.row || !this.wrapper.definition.row.position) && this.cellLabel.style.width && !this.label.instance.manualSize && !this.nameOrder2 && !this.teacherPrefixOrder2 && (!this.overflowLabel || this.overflowLabel.indexOf('wrap') === -1)) {
						side = 'left';
					}

					this.label.addStyleToEntireInstance('align', side, false);
				}

				if (this.overrideFontSize) {
					this.startFontSize = this.overrideFontSize + 'pt';
					this.label.addStyleToEntireInstance('font-size', this.overrideFontSize + 'pt', false);
				} else {
					this.startFontSize = (this.labelCSS.css['font-size'] || this.defaultFontSize);
				}

				this.label.refreshInstance();
				this.updateLabelManualSize();
			}
		},
		setLabelCSS: function(name, value) {
			if(this.user && this.label && this.label.instance) {
				this.label.addStyleToEntireInstance(name, value);

				if(name == 'fontFamily') {
					this.label.addStyleToEntireInstance('font-size', this.startFontSize);
					this.updateLabelManualSize();
				} else if(name == 'font-size-multiplier') {
					var newFontSize = this.getLabelCSSValue('font-size', this.labelCSS.css['font-size'] || this.defaultFontSize);
					this.label.addStyleToEntireInstance('font-size', newFontSize);
					this.updateLabelManualSize();
				}
			}
		},
		getLabelCSSValue: function(name, value) {
			if(name == 'font-size' && this.labelCSS.css['font-size-multiplier']) {
				var fontSizeMultiplier = parseFloat(this.labelCSS.css['font-size-multiplier']);
				value = (parseFloat(value.replace('pt', '').replace('px', '')) * (1 + fontSizeMultiplier)) + 'pt';
			}
			
			return value;
		},
		updateLabelManualSize: function () {
			// Check if label overflows bounds
			// Keep trying in small increments until no longer overflowing.  Only try so many times.
			if(this.user) {
				this.standardFontSize = this.getCurrentFontSize();
				var fontSize = null;

				var label = this.label;
				var testFunction;
				if(this.overflowLabel == 'resize' || (this.overflowLabel && this.overflowLabel.indexOf('wrap') !== -1)) {
					testFunction = function () {
						// NOTE: We are not doing height check for side labels because bolded "quintessential" ends up resizing all of the fonts differently during render only!
						return $(label).doesTextOverflow(!label.rowLabel);
					};
				}

				if(this.definition.size === 'fit' && this.labelCSS.css['font-size-multiplier']) {
					$(this).addClass('hasFontMultiplier');
				} else if(testFunction) {
					// Try wrapping before resizing
					var newLine;
					if(this.overflowLabel == 'wrap' && testFunction() && label.instance.lines.length == 1) {
						var line = label.instance.lines[0];

						newLine = $.extend(true, {}, label.instance.lines[0]);
						if(this.user['First Name'] && this.user['Last Name'] && line.text.indexOf(this.user['First Name']) === 0 && line.text.indexOf(this.user['Last Name']) === (this.user['First Name'].length + 1)) {
							newLine.text = line.text.substr(this.user['First Name'].length + 1);
							line.text = line.text.substr(0, this.user['First Name'].length);
						} else {
							var words = line.text.split(' ');

							line.text = words[0];
							newLine.text = words.slice(1).join(' ');
						}
						label.instance.lines.push(newLine);
						this.label.refreshInstance();
					} else if(this.overflowLabel.indexOf('wrapLines') !== -1 && testFunction()) {
						var wrapTillLines = parseInt(this.overflowLabel.replace('wrapLines', ''));
						if(label.instance.lines.length < wrapTillLines) {
							var longestLine = label.instance.lines[0];
							var longestLineIndex = 0;
							label.instance.lines.forEach(function(line, index) {
								if(line.text.length > longestLine.text.length) {
									longestLine = line;
									longestLineIndex = index;
								}
							});

							var texts = this.splitTextForWrapping(longestLine.text);
							if(texts) {
								newLine = $.extend(true, {}, longestLine);
								longestLine.text = texts[0];
								newLine.text = texts[1];

								label.instance.lines.splice(longestLineIndex + 1, 0, newLine);
								this.label.refreshInstance();
							}
						}
					}

					let shrinkWhitespace = $.getProjectSetting('shrinkCellWhitespace', 'false');
					let maxShrinkWhitespace = parseInt($.getProjectSetting('maxShrinkCellWhitespace', '20')) / 100;
					if(shrinkWhitespace && !isNaN(maxShrinkWhitespace) && maxShrinkWhitespace > 0) {
						if(testFunction()) {
							fontSize = this.standardFontSize;
							let labelHeight = $(label).getFloatStyle('height') || label.clientHeight;
							let heightSizeMultiplier = labelHeight / $(label.svgEditor).getFloatAttribute('height');
							if(heightSizeMultiplier < 1) {
								fontSize = fontSize * heightSizeMultiplier;
								this.label.addStyleToEntireInstance('font-size', fontSize + 'pt');
							}

							let labelWidth = $(label).getFloatStyle('width') || label.clientWidth;
							let scrollWidth = $(label.svgEditor).getFloatAttribute('width');

							let widthSizeMultiplier = (labelWidth / scrollWidth) + maxShrinkWhitespace;
							if(widthSizeMultiplier < 1) {
								fontSize = fontSize * widthSizeMultiplier;
								this.label.addStyleToEntireInstance('font-size', fontSize + 'pt', false);
							}
							this.label.instance.manualSize = {
								width: labelWidth / this.ratio
							};

							let addToEntireInstance = true;
							if(this.label.cachedTextNodes.length > 1) {
								let lineWidths = this.label.cachedTextNodes.filter(node => !node.clonedFrom).map(textNode => textNode.cachedBoundingClientRect.width);
								let linesOverWidth = lineWidths.filter(lineWidth => lineWidth > labelWidth);

								if(linesOverWidth.length < lineWidths.length) {
									lineWidths.forEach((lineWidth, lineIndex) => {
										if(lineWidth > labelWidth) {
											this.label.instance.lines[lineIndex].align = 'justify';
										}
									});

									this.label.refreshInstance();
									addToEntireInstance = false;
								}
							} else if(this.label.cachedTextNodes[0].cachedBoundingClientRect.width < labelWidth) {
								// For some fonts like Pacifico we can be too tall and registering as needing to justify when we don't really need to
								addToEntireInstance = false;
								delete this.label.instance.manualSize;
							}

							if(addToEntireInstance) {
								this.label.addStyleToEntireInstance('align', 'justify');
							}
						}
					} else {
						for(let i = 0; i < 14 && testFunction(); i++) {
							// Don't use jquery since we want pt
							if(!fontSize) {
								fontSize = this.standardFontSize;
							}

							var labelWidth = $(label).getFloatStyle('width') || label.clientWidth;
							var labelHeight = $(label).getFloatStyle('height') || label.clientHeight;
							var multiplier = Math.min(labelWidth / $(label.svgEditor).getFloatAttribute('width'), labelHeight / $(label.svgEditor).getFloatAttribute('height'), 0.95);
							fontSize = fontSize * multiplier;

							this.label.addStyleToEntireInstance('font-size', fontSize + 'pt');
						}
					}

					$(this).removeClass('hasFontMultiplier');
				}

				this.manualFontSize = fontSize;
			}
		},
		splitTextForWrapping: function(text) {
			var midPoint = Math.ceil(text.length / 2);
			
			var wordBreakCharacter = midPoint;
			while(wordBreakCharacter < text.length && text[wordBreakCharacter] != ' ') {
				wordBreakCharacter++;
			}

			if(wordBreakCharacter < text.length) {
				return [
					text.substr(0, wordBreakCharacter),
					text.substr(wordBreakCharacter + 1)
				];
			}

			while(wordBreakCharacter > 0 && text[wordBreakCharacter] != ' ') {
				wordBreakCharacter--;
			}

			if(wordBreakCharacter > 0) {
				return [
					text.substr(0, wordBreakCharacter),
					text.substr(wordBreakCharacter + 1)
				];
			}

			return null;
		},
		getCurrentFontSize: function() {
			// Don't use jquery since we want pt
			var fontSize = parseFloat((this.labelCSS.css['font-size'] || this.defaultFontSize).replace('pt', ''));
			if(this.overrideFontSize) {
				fontSize = this.overrideFontSize;
			} else if(this.labelCSS.css['font-size-multiplier']) {
				fontSize = fontSize * (1 + this.labelCSS.css['font-size-multiplier']);
			}

			return fontSize;
		},
		setLargeCell: function (largeCell, hiddenSpan) {
			var nameLabelHeightMultiplier = 1;
			if (largeCell) {
				this.colSpan = largeCell.colSpan;
				this.rowSpan = largeCell.rowSpan;
				this.hiddenSpan = hiddenSpan;

				// Hide everything on current row except first
				this.changeOverlapCells(true);
				$(this).addClass('largeCell');
				this.largeCell = true;

				// Increase font size for large cell
				if (largeCell.nameSize) {
					if (largeCell.nameSize.indexOf('x') == 0) {
						var fontMultiplier = parseFloat(largeCell.nameSize.substr(1));
						this.overrideFontSize = this.nameSize * fontMultiplier * $.PRODUCTION_RATIO / $.FONT_MULTIPLIER;
						nameLabelHeightMultiplier = fontMultiplier;
					} else {
						this.overrideFontSize = largeCell.nameSize * $.PRODUCTION_RATIO / $.FONT_MULTIPLIER;
					}
				}

				// If we have extra padding for hidden cells, need to add to this cell
				var extraLeftPadding = 0;
				if(this.leftPadding) {
					extraLeftPadding = this.leftPadding * (this.colSpan - 1) - this.padding;
				}

				//  If we have a hidden cell, make sure to add it's left margin + half width to left padding to keep centered
				if (hiddenSpan && hiddenSpan.colSpan) {
					var rect = this.getBoundingClientRect();
					var style = window.getComputedStyle(this);
					extraLeftPadding += (rect.width / 2 + parseFloat(style['margin-left'].replace('px', ''))) * hiddenSpan.colSpan;
					if (this.leftPadding) {
						extraLeftPadding += this.leftPadding * hiddenSpan.colSpan;
					}
				}

				if (extraLeftPadding) {
					$(this.imgWrapper).css('marginLeft', extraLeftPadding);

					if(this.label && this.label.directlyOnCell) {
						$(this.label).css('marginLeft', extraLeftPadding);
					}
				}

				// If this is supposed to be movable, set up drag!
				if (largeCell.movable && !$(this).hasClass('movable') && this.parent.editable) {
					var container = this.parent.container;
					var parentPos = $(container).offset();
					parentPos.right = parentPos.left + $(container).width();
					parentPos.bottom = parentPos.top + $(container).height();

					if ($(this).hasClass('subjectSwappable')) {
						$(this).removeClass('subjectSwappable');

						if($(this.imgWrapper).hasClass('ui-draggable')) {
							$(this.imgWrapper).draggable('disable').removeClass('ui-draggable-disabled ui-state-disabled');
						}
					}

					var axis = false;
					if(largeCell.col == 'center') {
						axis = 'y';
					}

					$(this).addClass('movable').draggable({
						containment: $(this.parent.container),
						axis: axis,
						scroll: false,
						revert: function() {
							// If another user changes layout while we are dragging this will fail
							if(!$(this).hasClass('largeCell') || !$(this).isAttached()) {
								return false;
							}

							var stepsMoved = this[0].getLargeCellStepsMoved(this[0].lastUIEvent);
							this.lastDragTime = new Date().getTime();
							return !stepsMoved.x && !stepsMoved.y;
						},
						revertDuration: $.isInit($.jasmineTestRevertDuration) ? $.jasmineTestRevertDuration : 200,
						start: function(event, ui) {
							this.draggingSnapTo = null;
							this.startDragPosition = ui.position;
							$(this).css('zIndex', 10);

							var nextLargeCells = $();
							var nextCell = this;
							while(nextCell.nextSibling && $(nextCell.nextSibling.nextSibling).hasClass('largeCell')) {
								nextLargeCells = nextLargeCells.add(nextCell.nextSibling.nextSibling);
								nextCell = nextCell.nextSibling.nextSibling;
							}

							var prevLargeCells = $();
							var prevCell = this;
							while(prevCell.previousSibling && $(prevCell.previousSibling.previousSibling).hasClass('largeCell')) {
								prevLargeCells = prevLargeCells.add(prevCell.previousSibling.previousSibling);
								prevCell = prevCell.previousSibling.previousSibling;
							}

							this.otherDragLargeCells = nextLargeCells.add(prevLargeCells);
							this.isInDrag = true;
						},
						drag: function(event, ui) {
							this.lastDragTime = new Date().getTime();
							this.lastUIEvent = ui;

							$(this).css({
								left: ui.position.left,
								top: ui.position.top
							});

							var myRect = this.getBoundingClientRect();
							this.parent.getCells().each(function() {
								if(this === wrapper || !this.nextSibling || !this.parentNode.nextSibling || $(this).hasClass('behindFrame')) {
									return;
								}

								var cellRect = this.getBoundingClientRect();
								if($.isWithinDiff(myRect.left, cellRect.left, 6) && $.isWithinDiff(myRect.top, cellRect.top, 6)) {
									var diffX = myRect.left - cellRect.left;
									var diffY = myRect.top - cellRect.top;

									ui.position.left -= diffX;
									ui.position.top -= diffY;
								}
							});

							this.otherDragLargeCells.each(function(index) {
								$(this).css({
									left: ui.position.left,
									top: ui.position.top,
									zIndex: 10
								});
							});
						},
						stop: function(event, ui) {
							// If another user changes layout while we are dragging this will fail
							if(!$(this).hasClass('largeCell') || !$(this).isAttached()) {
								return;
							}

							this.lastDragTime = new Date().getTime();
							$(this).add(this.otherDragLargeCells).css({
								left: '',
								right: '',
								top: '',
								bottom: '',
								zIndeX: ''
							});

							var stepsMoved = this.getLargeCellStepsMoved(ui);
							if(!stepsMoved.x && !stepsMoved.y) {
								this.isInDrag = false;
								return;
							}

							var page = this.parent.getPage();
							var largeCellPosition = this.getLargeCellPosition();
							var startCellPosition = $.extend(true, {}, largeCellPosition);

							// Get which position we are editing
							var editCellPosition = largeCellPosition;
							var classBreak;
							if(this.user && this.user.classBreak) {
								classBreak = this.user.classBreak;
							}
							var context = [page, 'largeCellPosition'];
							if(classBreak) {
								if(!largeCellPosition.extras) {
									largeCellPosition.extras = {};
								}
								var classId = classBreak.id;
								if(!largeCellPosition.extras[classId]) {
									largeCellPosition.extras[classId] = {
										col: largeCellPosition.col,
										row: largeCellPosition.row
									};
								}
								editCellPosition = largeCellPosition.extras[classId];
								startCellPosition = $.extend(true, {}, editCellPosition);

								context.push('extras');
								context.push(classId);
							}

							// Make sure first drag of main batch is independent from extra batches
							(page.extraClasses || []).forEach(function(extraBatch) {
								if(!largeCellPosition.extras) {
									largeCellPosition.extras = {};
								}
								var classId = extraBatch.id;
								if(!largeCellPosition.extras[classId]) {
									largeCellPosition.extras[classId] = {
										col: largeCellPosition.col,
										row: largeCellPosition.row
									};
								}
							});

							// Actually update cell positions
							if(editCellPosition.col === -1) {
								editCellPosition.col = this.row.cells.length - 2;
							}
							if(editCellPosition.col !== 'center') {
								editCellPosition.col += stepsMoved.x;
							}
							editCellPosition.row += stepsMoved.y;
							page.setLargeCellPosition(largeCellPosition);

							if($.userEvents) {
								$.userEvents.addEvent({
									context: context,
									action: 'update',
									args: [
										{
											col: startCellPosition.col,
											row: startCellPosition.row
										},
										{
											col: editCellPosition.col,
											row: editCellPosition.row
										}
									]
								});
							}

							var layout = this.parent.getLayout();
							if(layout && layout.cell && layout.cell.size == 'fit') {
								this.parent.hardRefreshFitLayout(layout);
							}
							this.parent.addUsers();
							this.lastDragTime = new Date().getTime();
							this.isInDrag = false;

							if(!$(this).hasClass('largeCell') && $(this).hasClass('movable')) {
								$(this).removeClass('movable').draggable('destroy');
							}
						}
					});
				}
				this.imgWrapper.rotatable = largeCell.rotatable;
				this.applyRotation(true);

				if(this.img && this.user && this.definition.width >= 1.25) {
					var width = 375;
					if(this.definition.width >= 1.5) {
						width = 450;
					}

					var url = $.getPlicThumbnail({
						id: this.user.photo,
						photo_name: this.user.photo_name
					}, {
						w: width
					});
					$(this.img).LoadImage(url);
				}
			} else {
				// Put extra right padding in left for large cells so it is centered
				if (this.rightPadding && this.largeCell) {
					$(this).css('marginLeft', this.leftPadding);
					$(this).css('marginRight', this.rightPadding);
				}

				// Reset everything back
				this.largeCell = false;
				this.changeOverlapCells(false);

				this.colSpan = 1;
				this.rowSpan = 1;
				$(this).removeClass('largeCell');

				if($(this).hasClass('movable') && !this.isInDrag) {
					$(this).removeClass('movable').draggable('destroy');
				}
				if (this.subjectSwappable && !$(this).hasClass('subjectSwappable') && !$(this).hasClass('movable')) {
					$(this).addClass('subjectSwappable');

					if($(this.imgWrapper).hasClass('ui-draggable')) {
						$(this.imgWrapper).draggable('enable');
					}
				}
				$(this.imgWrapper).css('marginLeft', '');
				$(this.label).css('marginLeft', '');

				// Reset font size
				this.overrideFontSize = null;
				this.imgWrapper.rotatable = false;
				if(this.setRotatable) {
					this.setRotatable(false);
				}
				this.applyRotation(false);
			}

			this.applyInnerDimensions();
			if (this.label && this.baseLabelHeight) {
				var labelHeight = this.baseLabelHeight * nameLabelHeightMultiplier;
				this.label.style.height = labelHeight + 'px';
			}
		},
		applyInnerDimensions: function() {
			// Setup what the sizes of inner imgs should be
			var innerWidth = this.inchWidth * this.ratio * this.colSpan - (this.padding * 2);
			var innerHeight = this.inchHeight * this.ratio * this.rowSpan - (this.padding * 2);

			if(this.imgWrapper.style.transform) {
				var radians = $.FlowLayoutRotatableUtils.getRotation(this.imgWrapper.style.transform, true);
				var rotatedWidth = innerHeight * Math.abs(Math.sin(radians)) + innerWidth * Math.abs(Math.cos(radians));
				// var rotatedHeight = innerHeight * Math.abs(Math.cos(radians)) + innerWidth * Math.abs(Math.sin(radians));
				var widthPercentage = rotatedWidth / innerWidth;
				var newWidth = innerWidth / widthPercentage;
				var newHeight = innerHeight / widthPercentage;

				// Also need to make sure that we move by half amount shrunk so we are still centered
				var widthDiff = innerWidth - newWidth;
				var heightDiff = innerHeight - newHeight;
				this.imgWrapper.style.left = (widthDiff / 2) + 'px';
				this.imgWrapper.style.top = (heightDiff / 2) + 'px';
				if(this.cellLabel) {
					var angle = $.FlowLayoutRotatableUtils.getRotation(this.imgWrapper.style.transform, false);
					if(angle > 180) {
						angle = 360 - angle;
					}
					
					var angleIncTop = angle / $.PRODUCTION_RATIO * this.ratio;
					this.cellLabel.style.left = (widthDiff / 2) + 'px';
					this.cellLabel.style.top = (heightDiff / 2 + angleIncTop) + 'px';
				}

				innerWidth = newWidth;
				innerHeight = newHeight;
			}

			this.innerWidth = innerWidth;
			this.innerHeight = innerHeight;
			if (this.cellLabel) {
				this.cellLabel.style.width = this.innerWidth + 'px';
			}
			this.imgWrapper.style.width = this.innerWidth + 'px';
			this.imgWrapper.style.height = this.innerHeight + 'px';
		},
		getLargeCellStepsMoved: function(ui) {
			var diffX = ui.position.left - this.startDragPosition.left;
			var diffY = ui.position.top - this.startDragPosition.top;

			var stepsMovedRight = Math.round(diffX / this.outerWidth);
			var stepsMovedDown = Math.round(diffY / this.outerHeight);

			var largeCellPosition = this.getLargeCellPosition();
			var editCellPosition = largeCellPosition;
			if(this.user && this.user.classBreak && largeCellPosition.extras && largeCellPosition.extras[this.user.classBreak.id]) {
				editCellPosition = largeCellPosition.extras[this.user.classBreak.id];
			}

			// Check if we are off edges
			var maxRightIndex = this.row.cells.length - (this.colSpan * (this.otherDragLargeCells.length + 1));
			if(editCellPosition.col === -1) {
				editCellPosition.col = maxRightIndex;
			}
			if(editCellPosition.col + stepsMovedRight > maxRightIndex) {
				stepsMovedRight = maxRightIndex - editCellPosition.col;
			} else if(editCellPosition.col + stepsMovedRight < 0) {
				stepsMovedRight = -editCellPosition.col;
			}

			var maxBottomIndex = this.row.parentNode.rows.length - this.rowSpan;
			if(editCellPosition.row + stepsMovedDown > maxBottomIndex) {
				stepsMovedDown = maxBottomIndex - editCellPosition.row;
			} else if(editCellPosition.row + stepsMovedDown < 0) {
				stepsMovedDown = -editCellPosition.row;
			}

			// Check if we are running into blocked cells
			var currentRowIndex = this.row.parentNode.rows.indexOf(this.row);
			var newRow = this.row.parentNode.rows[currentRowIndex + stepsMovedDown];
			var newNextRow = this.row.parentNode.rows[currentRowIndex + stepsMovedDown + 1];
			if(newRow && newNextRow) {
				if($(newRow.cells[editCellPosition.col + stepsMovedRight]).hasClass('behindFrame') || $(newRow.cells[editCellPosition.col + stepsMovedRight + 1]).hasClass('behindFrame') ||
					$(newNextRow.cells[editCellPosition.col + stepsMovedRight]).hasClass('behindFrame') || $(newNextRow.cells[editCellPosition.col + stepsMovedRight + 1]).hasClass('behindFrame') ||
					$(newNextRow.cells[editCellPosition.col + stepsMovedRight]).hasClass('invisibleCell') || $(newNextRow.cells[editCellPosition.col + stepsMovedRight + 1]).hasClass('invisibleCell')) {

					return {
						x: 0,
						y: 0
					};
				}
			}

			// Make sure we aren't running into other batches
			if(stepsMovedDown > 0) {
				for(let i = currentRowIndex; i <= (currentRowIndex + stepsMovedDown + 1) && this.row.parentNode.rows[i]; i++) {
					var classBreakCell = this.row.parentNode.rows[i].getClassBreakCell();
					if(classBreakCell && classBreakCell.user.classBreak != this.user.classBreak) {
						return {
							x: 0,
							y: 0
						};
					}
				}
			}
			// NOTE: Do not need to check reverse direction since negative numbers already handled above

			return {
				x: stepsMovedRight,
				y: stepsMovedDown
			};
		},
		getLargeCellPosition: function() {
			var page = this.parent.getPage();
			var largeCell = $.extend(true, {}, this.row.parentNode.largeCell);
			var largeCellPosition = $.extend(true, {
				col: largeCell.col,
				row: largeCell.row
			}, page.getLargeCellPosition());

			var primaryLargeCell = this.getPrimaryLargeCell();
			if(primaryLargeCell && primaryLargeCell.user && !primaryLargeCell.user.classBreak) {
				var cellIndex = this.row.cells.indexOf(primaryLargeCell);
				if(cellIndex !== -1 && largeCellPosition.col !== -1) {
					largeCellPosition.col = cellIndex;
				}
				var rowIndex = this.row.parentNode.rows.indexOf(primaryLargeCell.row);
				if(rowIndex !== -1) {
					largeCellPosition.row = rowIndex;
				}
			}

			return largeCellPosition;
		},
		getPrimaryLargeCell: function() {
			var primary = this;
			
			var previousCell = this;
			while(previousCell.previousSibling) {
				previousCell = previousCell.previousSibling;

				if($(previousCell).hasClass('largeCell')) {
					primary = previousCell;
				}
			}

			return primary;
		},
		getOverlapCells: function (colSpan, rowSpan) {
			var cells = $();

			// Hide everything on current row except first
			let nextCell = $(this);
			for (let i = 1; i < colSpan; i++) {
				nextCell = nextCell.next();
				if (nextCell) {
					cells = cells.add(nextCell);
				}
			}

			// Go through next rows and hide everything in the same range
			var nextRow = $(this).parent();
			for (let i = 1; i < rowSpan; i++) {
				nextRow = nextRow.next();

				nextCell = nextRow.find('.flowCell').eq($(this).index());
				for (let j = 0; j < colSpan; j++) {
					cells = cells.add(nextCell);
					nextCell = nextCell.next();
				}
			}

			return cells;
		},
		changeOverlapCells: function (hide) {
			// Only do something if it is a large cell
			var cells = $(), previousHidden = $();
			if (hide) {
				if (this.colSpan > 1) {
					var colSpan = this.colSpan;
					var rowSpan = this.rowSpan;
					if (this.hiddenSpan) {
						if (this.hiddenSpan.colSpan) {
							colSpan += this.hiddenSpan.colSpan;
						}
						if (this.hiddenSpan.rowSpan) {
							rowSpan += this.hiddenSpan.rowSpan;
						}
					}

					cells = this.getOverlapCells(colSpan, rowSpan);
				}

				if (this.overlappedCells) {
					previousHidden = this.overlappedCells.not(cells);
				}
				this.overlappedCells = cells;
			} else if (this.overlappedCells) {
				cells = this.overlappedCells;
				this.overlappedCells = $();
			}

			this.changeOverlapVisibility(hide, cells);
			if (previousHidden.length) {
				this.changeOverlapVisibility(false, previousHidden);
			}
		},
		changeOverlapVisibility: function (hide, cells) {
			// Go through each cell now that we have a list
			// Handle behindFrames logic so this plays nice with frames
			var me = this;
			cells.each(function () {
				// Don't try to do anything if we aren't on the DOM anymore anyways
				if (!$.contains(document.documentElement, this)) {
					return;
				}

				// Make sure to clear out users if we are hiding
				if (this.user && hide) {
					this.setUser(null);
				}

				var row = $(this).parent()[0];
				if (hide) {
					$(this).data('behindFrames').push(me);
					// Hide this sucker!
					$(this).addClass('hiddenCell');

					if (this.colSpan > 1) {
						this.setLargeCell(null);
					}

					// Stop centering a row we are in
					if(row.centered && !row.forceCentered && !this.wrapper.definition.cell.distributeSpacingOutsideLayout) {
						$(this).parent().addClass('startJustified');
					}
				} else {
					// Check if we need to unhide this
					if ($(this).hasClass('hiddenCell')) {
						var frames = $(this).data('behindFrames');
						for (let i = 0; i < frames.length; i++) {
							if (frames[i] == me) {
								frames.splice(i, 1);
								i--;
							}
						}

						// Yay, no one else has claimed this booty yet!
						if (frames.length == 0) {
							$(this).removeClass('hiddenCell');
						}

						// Reset centered after we leave
						if(row.centered && !row.forceCentered) {
							$(this).parent().removeClass('startJustified');
						}
					}
				}
			});
		},
		changeDefinition: function (definition) {
			this.definition = definition;
			this.inchWidth = definition.width;
			this.inchHeight = definition.height;

			// Reformat padding to be in px
			var padding = definition.padding * this.ratio;
			this.padding = padding;

			// Setup width/height
			this.width = definition.width * this.ratio - (padding * 2);
			this.height = definition.height * this.ratio - (padding * 2);
			if(this.height < 0) {
				this.height = 1;
				this.width = 0.8;
			}
			this.style.width = this.width + 'px';
			this.style.height = this.height + 'px';
			if(!this.largeCell) {
				this.setLargeCell(definition.largeCell);
			}
			this.setupPadding(definition);
			if(definition.studentLabelCSS && this.parentNode && this.parentNode.parentNode && !this.parentNode.parentNode.isMain()) {
				this.labelCSS = definition.studentLabelCSS;
			}
			if(!this.labelCSS.css) {
				$.fireErrorReport(null, 'this.labelCSS.css was null', {
					definition: JSON.stringify($.extend(true, {}, definition, {
						wrapper: null
					})),
					labelCSS: JSON.stringify(this.labelCSS),
					snapshot: this.parent.getSnapshot()
				});
			}

			var imgWrapperCSS = {
				marginBottom: ''
			};
			if(definition.primaryPose) {

				if(definition.primaryPose.bottomPadding) {
					var primaryPosePadding = definition.primaryPose.bottomPadding * this.ratio;
					imgWrapperCSS['marginBottom'] = primaryPosePadding;
					this.bottomPadding += primaryPosePadding;
				}
			}

			this.nameOrder = $.isInit(definition.nameOrder) ? definition.nameOrder : '%first% %last%';
			for(let i = 2; definition['nameOrder' + i]; i++) {
				this['nameOrder' + i] = definition['nameOrder' + i];
			}
			this.teacherPrefixOrder = $.isInit(definition.teacherPrefixOrder) ? definition.teacherPrefixOrder : '%prefix% %last name%';
			for(let i = 2; definition['teacherPrefixOrder' + i]; i++) {
				this['teacherPrefixOrder' + i] = definition['teacherPrefixOrder' + i];
			}
			this.overflowLabel = $.isInit(definition.overflowLabel) ? definition.overflowLabel : 'resize';
			this.autoTitleCase = $.isInit(definition.autoTitleCase) ? definition.autoTitleCase : false;
			if (definition.name && definition.name != 'none') {
				if(!this.labelCSS) {
					return;
				}
				if(!this.label) {
					this.label = this.cellLabel = this.createLabel();
				}

				var label = this.label;
				if (!definition.nameSize) {
					definition.nameSize = 12;
				}
				this.nameSize = definition.nameSize;
				this.nameHeightFix = definition.nameHeightFix;

				var lines = 1;
				if((this.nameOrder2 || this.teacherPrefixOrder2 || this.overflowLabel == 'wrap') && definition.name) {
					lines++;
				}
				for(let i = 3; this['nameOrder' + i] || this['teacherPrefixOrder' + i]; i++) {
					lines++;
				}

				if(this.overflowLabel && this.overflowLabel.indexOf('wrapLines') !== -1) {
					var wrapTillLines = parseInt(this.overflowLabel.replace('wrapLines', ''));
					lines = Math.max(lines, wrapTillLines);
				}

				var nameHeight = this.getNameHeight(definition, lines, true);
				var wrapperMargin = this.getNameHeight(definition, lines, definition.size == 'fit');
				if(this.bottomPadding) {
					if(definition.size == 'fit' && !this.nameHeightFix) {
						var minBottomPadding = 1;
						if(definition.distributeSpacingOutsideLayout) {
							minBottomPadding = 0;
						}
						wrapperMargin = this.bottomPadding + Math.max(minBottomPadding, this.padding * 3 * lines);
					} else {
						wrapperMargin += this.bottomPadding;
					}
				}

				var side = definition.name;
				if(side == 'inside') {
					if(this.wrapper.side == 'Right') {
						side = 'left';
					} else {
						side = 'right';
					}
				} else if(side == 'outside') {
					if(this.wrapper.side == 'Right') {
						side = 'right';
					} else {
						side = 'left';
					}
				}

				var align = definition.nameAlign;
				if(align == 'inside') {
					if(this.wrapper.side == 'Right') {
						align = 'right';
					} else {
						align = 'left';
					}
				} else if(align == 'outside') {
					if(this.wrapper.side == 'Right') {
						align = 'left';
					} else {
						align = 'right';
					}
				}

				switch (side) {
					case 'bottom':
						label.style.height = nameHeight + 'px';
						this.baseLabelHeight = nameHeight;
						this.style.marginBottom = wrapperMargin + 'px';
						this.appendChild(label);
						break;
					case 'top':
						label.style.height = nameHeight + 'px';
						this.baseLabelHeight = nameHeight;
						this.style.marginTop = wrapperMargin + 'px';
						$(this).prepend(label);
						break;
					case 'left':
						label.style.position = 'absolute';
						label.style.left = -(parseFloat(this.style['marginLeft'].replace('px', ''))) + 'px';
						label.style.top = this.padding + 'px';
						label.style.width = (parseFloat(this.style['marginLeft'].replace('px', '')) - this.padding) + 'px';
						label.style['text-align'] = align;
						label.style.height = nameHeight + 'px';
						this.appendChild(label);
						this.baseLabelHeight = nameHeight;
						break;
					case 'right':
						label.style.position = 'absolute';
						label.style.left = (this.innerWidth + this.padding) + 'px';
						label.style.top = this.padding + 'px';
						label.style.width = (parseFloat(this.style['marginRight'].replace('px', '')) - this.padding) + 'px';
						label.style['text-align'] = align;
						label.style.height = nameHeight + 'px';
						this.appendChild(label);
						this.baseLabelHeight = nameHeight;
						break;
					default:
						this.baseLabelHeight = 0;
				}

				if (this.overflowLabel == 'wrap') {
					$(this).addClass('wrapLabel');
				} else {
					$(this).removeClass('wrapLabel');
				}
			} else {
				this.baseLabelHeight = 0;
			}

			if(definition.texts) {
				wrapper.setupCellTexts(definition.texts);
			}
			if(this.user) {
				var refreshUserLabel = false;
				// If we are shrinking the multiplier we need to be able to re-do the overflow calculation
				if(this.overflowLabel && this.labelCSS.css['font-size-multiplier'] < this.lastFontSizeMultiplier) {
					refreshUserLabel = true;
				}

				if(refreshUserLabel) {
					this.setUserLabel(this.user);
				}
			}
			if(this.labelCSS && this.labelCSS.css['font-size-multiplier']) {
				this.lastFontSizeMultiplier = this.labelCSS.css['font-size-multiplier'];
			}

			if (definition.mask) {
				var mask = definition.mask;
				$(this).find('.imageInnerStyleWrapper').css(mask);
			}

			this.outerWidth = $(this).getFloatStyle('width') + $(this).getFloatStyle('marginLeft') + $(this).getFloatStyle('marginRight');
			this.outerHeight = $(this).getFloatStyle('height') + $(this).getFloatStyle('marginTop') + $(this).getFloatStyle('marginBottom');
			$(this.imgWrapper).css(imgWrapperCSS);

			this.setupZIndex(definition);

			if(definition.wave) {
				$(this).addClass('deferPointerEvents');
				this.checkOverlapBoundsAgainst = this.imgWrapper;
			} else {
				$(this).removeClass('deferPointerEvents');
				delete this.checkOverlapBoundsAgainst;
			}
		},
		getNameHeight: function(definition, lines, includeSizeMultiplier) {
			var minBottomPadding = 1;
			if(definition.distributeSpacingOutsideLayout) {
				minBottomPadding = 0;
			}

			var nameHeight;
			if(definition.size == 'fit' && !this.nameHeightFix) {
				// Leave fit definition alone for now since the calculations need to be adjusted for changes
				// Update the wrapperMargin fix below when we update this
				nameHeight = Math.max(minBottomPadding, this.padding * 3 * lines + this.padding);

				if(this.bottomPadding) {
					nameHeight += this.bottomPadding - this.padding;
				}
			} else {
				var fontSize = parseFloat((this.labelCSS.css['font-size'] || this.defaultFontSize).replace('pt', ''));
				var fontSizePx = $.convertToPx(fontSize, true) / $.PRODUCTION_RATIO * this.ratio;

				if(this.labelCSS.css['font-size-multiplier'] && includeSizeMultiplier) {
					fontSizePx = fontSizePx * (1 + this.labelCSS.css['font-size-multiplier']);
				}

				// For Elements we want it to always use 1.33 padding
				// Honestly not sure why we don't for Books, but at this point it would require another layout migration
				if((lines > 1 || definition.size == 'fit') && this.nameHeightFix) {
					nameHeight = (Math.max(minBottomPadding, fontSizePx * 1.33)) * lines;

					if(definition.size != 'fit') {
						nameHeight += this.padding;
					}
				} else {
					nameHeight = Math.max(minBottomPadding, fontSizePx + this.padding) * lines;
				}
			}

			return nameHeight;
		},
		setupPadding: function(definition) {
			// Setup universal padding first
			this.style.margin = this.padding + 'px';

			this.setupPaddingForSide(definition, 'left', {
				useOutsideHorizontalPadding: this.isFirstCell()
			});
			this.setupPaddingForSide(definition, 'right', {
				useOutsideHorizontalPadding: !this.isFirstCell()
			});

			this.setupPaddingForSide(definition, 'top', {
				useOutsideVerticalPadding: this.isFirstRow(),
				useInsideVerticalPadding: !this.isFirstRow()
			});
			this.setupPaddingForSide(definition, 'bottom', {
				useOutsideVerticalPadding: this.isLastRow(),
				useInsideVerticalPadding: !this.isLastRow()
			});
		},
		setupPaddingForSide: function(definition, side, options) {
			if(!options) {
				options = {};
			}

			definition = $.extend(true, {}, definition);
			// This is a terrible hack to get around using a different meaning of outside vs inside padding!
			// Names Under With Side Quotes uses outside to mean subjects in center and padding outside of it
			// Names With Columns Quotes uses inside to mean subjects to the outside of the book and padding towards the inside of the book
			// When you try to use align inside or align outside behaviors, they conflict because they are two different interpretations of the same words
			// Names Under With Side Quotes has a nameAlign = 'center' while Names Under With Side Quotes uses left/right alignment
			if(definition.nameAlign && definition.nameAlign !== 'center') {
				if(definition.insidePadding) {
					if(this.wrapper.side == 'Right') {
						definition.leftPadding = definition.insidePadding;
					} else {
						definition.rightPadding = definition.insidePadding;
					}
				}

				if(definition.outsidePadding) {
					if(this.wrapper.side != 'Right') {
						definition.leftPadding = definition.outsidePadding;
					} else {
						definition.rightPadding = definition.outsidePadding;
					}
				}

				options.useOutsideHorizontalPadding = false;
			}

			var sidePaddingName = side + 'Padding';
			if (definition[sidePaddingName]) {
				this[sidePaddingName] = definition[sidePaddingName] * this.ratio;

				if(definition[side + 'ExtraPadding']) {
					this[sidePaddingName] += definition[side + 'ExtraPadding'] * this.ratio;
				}
			} else if(options.useOutsideHorizontalPadding && definition.outsidePadding) {
				this[sidePaddingName] = definition.outsidePadding * this.ratio;
			} else if(options.useOutsideVerticalPadding && definition.outsideVerticalPadding && (side == 'top' || side == 'bottom')) {
				this[sidePaddingName] = definition.outsideVerticalPadding * this.ratio;
			} else if(options.useInsideVerticalPadding && definition.insideVerticalPadding && (side == 'top' || side == 'bottom')) {
				this[sidePaddingName] = definition.insideVerticalPadding * this.ratio;
			} else if(definition[side + 'ExtraPadding']) {
				if(side == 'bottom') {
					// For the bottom we have separate calculations which already include the padding
					this[sidePaddingName] = definition[side + 'ExtraPadding'] * this.ratio;
				} else {
					this[sidePaddingName] = this.padding + (definition[side + 'ExtraPadding'] * this.ratio);
				}
			} else {
				this[sidePaddingName] = 0;
			}

			if(this[sidePaddingName]) {
				this.style['margin' +  side.toTitleCase()] = this[sidePaddingName] + 'px';
			}
		},
		setupZIndex: function(definition) {
			if(!definition) {
				definition = this.definition;
			}

			var zIndex = '';
			if(definition.zIndex) {
				if(definition.zIndex == 'alternate') {
					if(this.isEvenCell()) {
						zIndex = 1;
					} else {
						zIndex = 0;
					}

					// Work around for Prince not respecting z-index across rows
					zIndex += this.row.getRowIndex() * 2;
				} else if(definition.zIndex == 'single-wave') {
					var cells = this.getFilledCells().length;
					var halfWayPoint = cells / 2;
					if(this.cellIndex <= halfWayPoint) {
						zIndex = this.cellIndex;
					} else {
						zIndex = Math.abs(this.cellIndex - cells + 1);
					}

					// Work around for Prince not respecting z-index across rows
					zIndex += this.row.getRowIndex() * this.getTotalCells();
				}
			}

			this.style.zIndex = zIndex;
		},
		isFirstRow: function() {
			return this.row.isFirstRow();
		},
		isLastRow: function() {
			return this.row.isLastRow();
		},
		isFirstCell: function() {
			return this.cellIndex % 2 == 0;
		},
		isEvenCell: function() {
			return this.cellIndex % 2 == 0;
		},
		isOddCell: function() {
			return this.cellIndex % 2 == 1;
		},
		getTotalCells: function() {
			return this.row.getTotalCells();
		},
		getFilledCells: function() {
			return this.row.getFilledCells();
		},
		getPreviousCell: function () {
			var prevCell = $(this).prev();
			if (prevCell.length == 0 || prevCell.hasClass('rowHeader')) {
				// Go to previous row
				var row = $(this).parent();
				var prevRow = row.prev();

				if (prevRow.length == 0) {
					return null;
				} else {
					prevCell = prevRow.find('.flowCell').last();
					if (prevCell.hasClass('hiddenCell')) {
						return prevCell[0].getPreviousCell();
					} else {
						return prevCell[0];
					}
				}
			} else if (prevCell.hasClass('hiddenCell')) {
				return prevCell[0].getPreviousCell();
			} else {
				return prevCell[0];
			}
		},
		getNextCell: function(allowNextRow) {
			if(allowNextRow === undefined) {
				allowNextRow = true;
			}

			var nextCell = $(this).next();
			if(nextCell.length == 0 && allowNextRow) {
				// Go to nextious row
				var row = $(this).parent();
				var nextRow = row.next();

				if (nextRow.length == 0) {
					return null;
				} else {
					var nextRowCells = nextRow.find('.flowCell');
					if (nextRowCells.eq(0).hasClass('rowHeader')) {
						return nextRowCells[1];
					} else {
						return nextRowCells[0];
					}
				}
			} else if(nextCell.hasClass('hiddenCell') || nextCell.hasClass('emptyCell')) {
				return nextCell[0].getNextCell(allowNextRow);
			} else {
				return nextCell[0];
			}
		},
		createLabel: function() {
			var editTools = [
				'styles',
				'font-family'
			];

			var page = this.parent.getPage();
			if(page && page.showSubjectLabelFontSizeMultiplier()) {
				var sizes = [];
				for(let i = -25; i <= 25; i++) {
					var size = i * 2;
					if(size < 0) {
						sizes.push(size + '%');
					} else {
						sizes.push('+' + size + '%');
					}
				}

				editTools.push({
					title: 'Font Size',
					dropdown: sizes,
					onChange: function(selection, change) {
						var value = parseInt(change.replace('%', '')) / 100;
						wrapper.wrapper.setLabelCSS('font-size-multiplier', value);

						wrapper.updateToolbarDisplay();
						return false;
					},
					updateLabel: function(selection, dropdown, label) {
						var size = selection['font-size-multiplier'] ? selection['font-size-multiplier'] : 0;
						
						if(size == 0) {
							dropdown.setSelectedValue('+0%');
							label.text('Auto Sized');
						} else {
							size = Math.round(size * 100);
							if(size > 0) {
								size = '+' + size;
							}

							dropdown.setSelectedValue(size + '%');
						}
					},
					popup: 'Long names will only be shrunk to fit at 0% or Auto Sized.  If too many names are being resized, try using -10% to lower the base font size.'
				});
			}
			editTools.push('text-colors');
			editTools.push({
				addClass: 'icon',
				group: ['stroke', 'drop shadow']
			});

			if(this.wrapper && this.wrapper.page && this.wrapper.page.canEditSubjectDetails()) {
				editTools.push({
					icon: 'tasks',
					popup: 'Edit name',
					closeOnClick: true,
					onClick: function() {
						wrapper.openEditSubjectNameDialog();
					}
				});
			}

			var pageSet = this.wrapper.getPageSet();
			if(pageSet && pageSet.getApplyToAllToolbar && this.wrapper.page) {
				var applyToAllItem = pageSet.getApplyToAllToolbar(this.wrapper.page, {
					popup: 'Apply label styles to all pages',
					candids: false,
					labelStyles: true,
					layout: false,
					margins: false,
					portraitEffects: false,
					texts: false,
					theme: false
				}, this.wrapper.parent);
				if(applyToAllItem) {
					editTools.push(applyToAllItem);
				}
			}

			var label = new $.FlowLayoutSVG({
				wrapper: this.parent,
				addClass: 'nameLabel',
				ratio: this.parent.ratio,
				editable: this.cellEditable,
				textEditable: false,
				editTools: editTools,
				onChangeSelectedText: function (selection, name, value) {
					// Needed for changes to drop shadow color to propogate correctly
					if($.isPlainObject(value)) {
						value = $.extend(true, {}, value);
					}
					
					wrapper.wrapper.setLabelCSS(name, value);
				},
				getUserSelectionId: function() {
					wrapper.initToolbar();
					return wrapper.getUserSelectionId();
				},
				getUserSelection: function() {
					return {
						id: this.getUserSelectionId(),
						selection: 'label'
					};
				},
				getDynamicFields: function() {
					return wrapper.wrapper.getDynamicFields();
				}
			});
			$(label).removeClass('flowContent');
			label.style.display = 'none';
			label.directlyOnCell = true;
			label.onFontLoadedReload = () => {
				this.updateLabelManualSize();
			};

			return label;
		},
		initDroppable: function() {
			if(this.definition.subjectSwappable && this.parent.editable && !$(this.imgWrapper).hasClass('ui-droppable')) {
				$(this.imgWrapper).droppable({
					accept: function(draggable) {
						if(draggable[0] != this && draggable.hasClass('imageWrapper') && !$(wrapper).hasClass('hiddenCell')) {
							if(!$(this).isAttached()) {
								return false;
							}

							var draggedCell = draggable.parent()[0];
							var droppedCell = $(this).parent()[0];
							var draggedUser = draggedCell.user;
							var droppedUser = droppedCell.user;

							var page = wrapper.parent.getPage();
							var batch = page.getClassForSubject(draggedUser);
							if (!batch || !batch.subjects) {
								return false;
							}
							// Get kids returns a copy of array
							var users = batch.subjects;

							// Make sure both users are in the same batch!
							if(users.indexOf(draggedUser) == -1 || users.indexOf(droppedUser) == -1) {
								return false;
							} else {
								return true;
							}
						} else {
							return false;
						}
					},
					drop: function(event, ui) {
						var draggedCell = ui.draggable.parent()[0];
						var droppedCell = $(this).parent()[0];

						if(draggedCell.user && droppedCell.user) {
							wrapper.parent.swapOrShift(draggedCell, droppedCell);
							ui.draggable.css({
								left: 0,
								top: 0
							});
							ui.draggable.data('revert', false);
						}
						wrapper.parent.removeDraggingOver();
					}
				}).addClass('isDroppable');
			}
		},
		setupCellTexts: function(texts) {
			this.removeCellTexts();

			for(let i = 0; i < texts.length; i++) {
				var text = texts[i];
				var svg = this.createCellText(text);
				this.texts.push(svg);
			}

			if(this.user) {
				this.setUserTexts(this.user);
			}
		},
		removeCellTexts: function() {
			while(this.texts.length) {
				var svg = this.texts.pop();
				svg.destroy();
				$(svg).remove();
			}
		},
		createCellText: function(text) {
			var svg = new $.FlowLayoutSVG({
				appendTo: this,
				wrapper: this.parent,
				ratio: this.parent.ratio,
				editable: this.parent.editable,
				editTools: [
					'align',
					'styles',
					'font-family',
					'font-size',
					'text-colors',
					{
						addClass: 'icon',
						group: ['stroke', 'drop shadow']
					},
					{
						icon: 'edit',
						popup: 'Apply same text to all subjects in this batch',
						singleSelection: true,
						onClick: function() {
							var page = wrapper.getPage();
							if(page.getRootPage) {
								page = page.getRootPage();
							}

							var lines = page.getSubjectCellDataValue(wrapper.user.id, this.cellId);
							var me = this;
							$.Confirm('Apply To All', 'Are you sure you want to apply same text to all subjects in this batch/class?', function() {
								var subjects = page.getSubjects();
								subjects.forEach(function(subject) {
									if(subject.id == wrapper.user.id) {
										return;
									}

									page.setSubjectCellDataValue(subject.id, me.cellId, lines);
								});

								page.setSubjectCellDataValue('global-default', me.cellId, lines);
								wrapper.wrapper.updateCellTextCSS(me.cellId, true);
							});
						}
					}
				],
				wordWrapText: true,
				onChangeMultipleSelections: function (selections, name, value) {
					var subSectionChanges = ['font-size', 'font-weight', 'font-style', 'text-decoration', 'color', 'background-color'];

					if(this.selectionLength && subSectionChanges.indexOf(name) !== -1) {
						// Allow changes to sub-selection
						var page = wrapper.getPage();
						var globalStyles = wrapper.getGlobalStyles(page, this.backupInstance);

						// If we already have a global default set, need to mark this as a special non-override
						if(globalStyles[name]) {
							selections.forEach(function(selection) {
								if(!selection.overrideStyles) {
									selection.overrideStyles = [];
								}

								if(!selection.overrideStyles.includes(name)) {
									selection.overrideStyles.push(name);
								}
							});
						}
					} else if(this.instance.applyStylesToAll) {
						wrapper.getParent().setCellTextCSS(this.cellId, name, value, true);
						this.ignoreNextInstancePropertyChange = true;
					}
				},
				onChangeInstanceProperty: function (name, value) {
					if(this.ignoreNextInstancePropertyChange) {
						this.ignoreNextInstancePropertyChange = false;

						// We need to make sure to detect line wrap changes and save them
						if(name == 'lines') {
							// If we have an object, count as 1 line
							var origInstanceLength = this.origInstance.lines.length || 1;
							var instanceLength = this.instance.lines.length || 1;
							if(origInstanceLength === instanceLength) {
								return;
							}
						} else {
							return;
						}
					}

					var page = wrapper.getPage();
					if(name == 'lines') {
						var lines = this.stripLinesFromGlobalStyles(page, this.instance.lines);
						page.setSubjectCellDataValue(wrapper.user.id, this.cellId, lines);
					}
				},
				stripLinesFromGlobalStyles: function(page, lines) {
					if(this.instance.applyStylesToAll) {
						var globalStyles = wrapper.getGlobalStyles(page, this.backupInstance);

						if($.isPlainObject(lines)) {
							lines = this.stripCommonProperties(lines, globalStyles);
						} else if($.isArray(lines)) {
							lines = $.merge([], lines);
							var me = this;
							$(lines).each(function(i, line) {
								line = lines[i] = me.stripCommonProperties(line, globalStyles);

								if(line.parts) {
									$(line.parts).each(function(j, part) {
										line.parts[j] = me.stripCommonProperties(part, globalStyles);
									});
								}
							});
						}
					}

					return lines;
				},
				stripCommonProperties: function(part, globalStyles) {
					part = $.extend(true, {}, part);

					for(var prop in part) {
						if(part[prop] === globalStyles[prop]) {
							delete part[prop];
							if(part.overrideStyles) {
								part.overrideStyles = part.overrideStyles.filter(function(styleName) {
									return styleName !== prop;
								});
							}
						}
					}

					return part;
				},
				getUserSelectionId: function() {
					wrapper.initToolbar();
					return wrapper.getUserSelectionId();
				},
				getUserSelection: function() {
					var selection = {
						id: this.getUserSelectionId(),
						selection: text.id
					};

					if(this.textEditable) {
						$.extend(selection, {
							selectionLine: this.selectionLine,
							selectionPosition: this.selectionPosition,
							selectionLength: this.selectionLength
						});
					}

					return selection;
				},
				getDynamicFields: function() {
					return wrapper.wrapper.getDynamicFields();
				},
				getDynamicFieldRep: function(field, subjectIndex, options) {
					return wrapper.wrapper.getDynamicFieldRep.call(wrapper.wrapper, field, wrapper.user, options);
				},
				cellId: text.id,
				backupInstance: text,
				preferManualSizeWrap: true
			});

			var _setInstance = svg.setInstance;
			$.extend(svg, {
				setInstance: function(instance) {
					_setInstance.apply(this, arguments);

					if(instance && instance.dataFromSubjectField) {
						this.recalculateWordWrapText();
						this.updateLines();
					}
				}
			})

			$(svg).removeClass('flowContent');
			svg.style.display = 'none';

			return svg;
		},
		setCellTextCSS: function(id, name, value) {
			var svg = this.getCellTextForId(id);
			if(svg && this.user) {
				if(name == 'align' && svg.backupInstance.position) {
					if(svg.backupInstance.position.outside == 'outsidePadding') {
						if(!this.isFirstCell()) {
							if(value == 'left') {
								value = 'right';
							} else if(value == 'right') {
								value = 'left';
							}
						}
					} else if(svg.backupInstance.position.left == 'cellInside') {
						if(this.wrapper.side == 'Right') {
							value = (value == 'right') ? 'left' : 'right';
						}
					}
				}

				let startRenderedWidth = svg.renderedWidth;
				svg.addStyleToEntireInstance(name, value);

				// Check word wrap here because normal uses of text nodes don't do text flow changes in addStyleToEntireInstance
				// This is a special case where we are really just re-rendering for display purposes but want to check for word wrap changes that should be saved at the same time
				svg.recalculateWordWrapText(null, false, true);
				let endRenderedWidth = svg.renderedWidth;
				if(startRenderedWidth > endRenderedWidth) {
					// Add a lastWordWrapPosition so we can get it only reverse the wraps that were up against the edge before
					let startLastWordWrapRightPosition = svg.lastWordWrapRightPosition;
					let wordWrapRightPosition = svg.lastWordWrapRightPosition || svg.getWordWrapRightPosition();
					svg.lastWordWrapRightPosition = wordWrapRightPosition - (startRenderedWidth - endRenderedWidth);
					try {
						svg.recalculateReverseWordWrap();
					} finally {
						svg.lastWordWrapRightPosition = startLastWordWrapRightPosition;
					}
				}
			}
		},
		updateCellTextCSS: function(id) {
			var svg = this.getCellTextForId(id);
			if(svg && this.user) {
				this.setUserText(svg, this.user);
			}
		},
		getCellTextForId: function(id) {
			for(let i = 0; i < this.texts.length; i++) {
				var text = this.texts[i];
				if(text.cellId == id) {
					return text;
				}
			}
		},
		setUserTexts: function(user) {
			for(let i = 0; i < this.texts.length; i++) {
				var svg = this.texts[i];
				this.setUserText(svg, user);
			}
		},
		setUserText: function(svg, user) {
			if(user) {
				svg.style.display = '';

				var page = this.getPage();
				var instance = this.getUserTextInstance(page, user, svg.backupInstance);
				svg.setInstance(instance, false);

				this.applyGlobalStyles(page, svg);
				svg.refreshInstance();
			} else {
				svg.style.display = 'none';
			}
		},
		getUserTextInstance: function(page, user, backupInstance) {
			var dataFromSubjectField = false;
			var subjectLines = page.getSubjectCellDataValue(user.id, backupInstance.id) || page.getSubjectCellDataValue('global-default', backupInstance.id);
			if(!subjectLines && backupInstance.id == 'quote') {
				['subject quote', 'subjects quote', 'senior quote', 'seniors quote'].forEach(function(checkField) {
					for(var prop in user) {
						if(prop.toLowerCase() == checkField || prop.toLowerCase() == (checkField + 's')) {
							subjectLines = user[prop];
							dataFromSubjectField = true;
						}
					}
				});
			}

			var instance = $.extend(true, {}, backupInstance);
			if(instance.manualSize) {
				if(instance.manualSize.width == 'outsidePadding') {
					instance.manualSize.width = this.definition.outsidePadding - this.definition.padding;
				} else if(instance.manualSize.width == 'insidePadding') {
					instance.manualSize.width = this.definition.insidePadding - (this.definition.padding * 2);
				} else if(instance.manualSize.width == 'leftPadding') {
					instance.manualSize.width = this.definition.leftPadding - (this.definition.padding * 2);
				} else if(instance.manualSize.width == 'rightPadding') {
					instance.manualSize.width = this.definition.rightPadding - (this.definition.padding * 2);
				}
			}
			if(instance.position) {
				if(instance.position.top == 'cellTop') {
					instance.position.top = -(this.definition.height + this.baseLabelHeight / this.ratio);
				} else if(instance.position.top == 'labelBottom') {
					instance.position.top = -(this.definition.height - (this.definition.padding * 4) - (this.baseLabelHeight / this.ratio));
				}
				
				var leftPosition = instance.position.left;
				if(leftPosition == 'cellInside') {
					if(this.wrapper.side == 'Right') {
						leftPosition = 'cellLeft';
					} else {
						leftPosition = 'cellRight';
					}
				} else if(leftPosition == 'cellOutside') {
					if(this.wrapper.side == 'Right') {
						leftPosition = 'cellRight';
					} else {
						leftPosition = 'cellLeft';
					}
				}

				if(leftPosition == 'cellRight') {
					instance.position.left = this.definition.width;
				} else if(leftPosition == 'cellLeft') {
					instance.position.left = -this.definition.padding;

					if(this.definition.insidePadding) {
						instance.position.left -= this.definition.insidePadding;
					} else if(this.definition.outsidePadding) {
						instance.position.left -= this.definition.outsidePadding;
					} else if(this.definition.leftPadding) {
						instance.position.left -= this.definition.leftPadding;
					}
				}
				
				if(instance.position.outside == 'outsidePadding') {
					if(this.isFirstCell()) {
						instance.position.left = -(this.definition.outsidePadding + this.definition.padding);
					} else {
						instance.position.left = this.definition.width;
					}
				}
			}

			if(instance.padding) {
				var leftPadding = instance.padding.left;
				var rightPadding = instance.padding.right;
				if(instance.padding.inside) {
					if(this.wrapper.side == 'Right') {
						rightPadding = instance.padding.inside;
					} else {
						leftPadding = instance.padding.inside;
					}
				}

				if(leftPadding) {
					if(instance.position && instance.position.left) {
						instance.position.left += leftPadding;
					}

					if(instance.manualSize && instance.manualSize.width) {
						instance.manualSize.width -= leftPadding;
					}
				}

				if(rightPadding) {
					if(instance.manualSize && instance.manualSize.width) {
						instance.manualSize.width -= rightPadding;
					}
				}
			}

			// Only allow label in padding if under and with no manual size
			if(!instance.manualSize && (!instance.position || !instance.position.left)) {
				instance.manualSize = {
					width: this.definition.width - (this.definition.padding * 2)
				};
			}

			if(subjectLines) {
				$.extend(true, instance, {
					lines: subjectLines,
					dataFromSubjectField: dataFromSubjectField
				});
			}

			return instance;
		},
		applyGlobalStyles: function(page, svg) {
			var globalStyles = this.getGlobalStyles(page, svg.backupInstance);
			if(globalStyles.align && svg.backupInstance.position && svg.backupInstance.position.outside == 'outsidePadding') {
				if(!this.isFirstCell()) {
					if(globalStyles.align == 'left') {
						globalStyles.align = 'right';
					} else if(globalStyles.align == 'right') {
						globalStyles.align = 'left';
					}
				}
			}

			svg.iterateInstanceLines(function(line) {
				if(globalStyles.align) {
					line.align = globalStyles.align;
				}
			});

			var globalPartStyles = $.extend(true, {}, globalStyles);
			if(globalPartStyles.align) {
				delete globalPartStyles.align;
			}
			if(globalPartStyles.postAlign) {
				var align = globalPartStyles.postAlign;
				if(this.wrapper.side == 'Right' && ['left', 'right'].indexOf(align) !== -1) {
					align = align === 'left' ? 'right' : 'left';
				}

				svg.iterateInstanceLines(function(line) {
					$.extend(true, line, {
						align: align
					});
					delete globalPartStyles.postAlign;
				})
			}
			svg.iterateInstanceParts(function(part) {
				var overrideGlobalStyles = $.extend(true, {}, globalPartStyles);
				if(part.overrideStyles) {
					part.overrideStyles.forEach(function(style) {
						delete overrideGlobalStyles[style];
					});
				}

				$.extend(true, part, overrideGlobalStyles);
			});
		},
		getGlobalStyles: function(page, backupInstance) {
			var globalStyles = page.getSubjectCellDataValue('global', backupInstance.id);

			globalStyles = $.extend(true, {}, backupInstance.lines, globalStyles);
			delete globalStyles.text;
			if(['center', 'left', 'right'].indexOf(globalStyles.align) !== -1 && ['inside', 'outside'].indexOf(backupInstance.lines.align) !== -1) {
				globalStyles.postAlign = globalStyles.align;
			}

			if(this.wrapper.side == 'Right') {
				if(backupInstance.lines.align == 'inside') {
					if(globalStyles.align == 'right') {
						globalStyles.align = 'left';
					} else {
						globalStyles.align = 'right';
					}
				} else if(backupInstance.lines.align == 'outside') {
					if(globalStyles.align == 'right') {
						globalStyles.align = 'right';
					} else {
						globalStyles.align = 'left';
					}
				}
			} else {
				if(backupInstance.lines.align == 'inside') {
					globalStyles.align = 'left';
				} else if(backupInstance.lines.align == 'outside') {
					if(globalStyles.align == 'left') {
						globalStyles.align = 'left';
					} else {
						globalStyles.align = 'right';
					}
				}
			}

			// Delete things like 'font-weight': '' so we can apply it only on parts of text
			for(var name in globalStyles) {
				if(!globalStyles[name] || (name === 'color' && globalStyles.color === 'rgb(0, 0, 0)')) {
					delete globalStyles[name];
				}
			}

			return globalStyles;
		},
		applyFilters: function(effects) {
			if(this.largeCell) {
				var page = this.wrapper.getPage();
				if(!page) {
					return;
				}

				effects = $.extend(true, {
					transformEffects: page.getExtraProperty('largeCellTransform') || ''
				}, effects);
			}

			this.instance = $.extend(true, {
				useGreenScreenBackground: 'project background'
			}, effects);
			$.FlowLayoutFrameUtils.applyFilters.apply(this, arguments);

			if($.isInit(effects.useGreenScreenBackground) && $.isInit(this.lastUseGreenScreenBackground) && this.lastUseGreenScreenBackground != effects.useGreenScreenBackground && this.user) {
				this.setupUserBackgroundImage(this.user);
			}
		},
		getParent: function() {
			return this.parent;
		},
		getPage: function() {
			return this.parent.getPage();
		},
		openSubjectPhotosDialog: function() {
			// If this page is a class page preview
			if(!this.user) {
				return;
			}

			var startCrop = this.getUserSelectedCrop(this.user);
			if(startCrop) {
				startCrop = $.extend(true, {}, startCrop);
			}

			var cropName = 'Yearbook 8 x 10';
			var page = this.wrapper.getPage();
			var pageSet = wrapper.wrapper.getPageSet();
			if(page && pageSet) {
				var cropSelection = this.getUserCropSelection();
				if(cropSelection) {
					cropSelection = pageSet.getSubjectPoseCropNames().find(function(fullCaseCropName) {
						return fullCaseCropName.toLowerCase() === cropSelection;
					});
					if(cropSelection) {
						cropName = cropSelection;
					}
				}
			}
			this.setFocused(false, false);
			var dialog = $.SubjectImageDialog($.extend(true, {
				subject: this.user,
				onBeforeSave: function() {
					wrapper.parent.startLoading();
				},
				onAfterSave: function() {
					wrapper.parent.stopLoading();
				},
				onSaveCrop: function() {
					wrapper.editUserCrop(startCrop);
				},
				aspectRatio: this.definition.ratio || 0.8,
				startCrop: startCrop,
				cropName: cropName
			}, this.subjectImageDialogOptions));
			dialog.show();

			return dialog;
		},
		editUserCrop: function(startCrop) {
			var user = this.user;
			this.setUserCrop(user);
			
			var pageSet = this.wrapper.getPageSet();
			(pageSet.getClasses() || []).forEach(function(batch) {
				batch.subjects.forEach(function(subject) {
					if(subject.id === user.id) {
						subject.yearbookPhoto = $.extend(true, {}, user.yearbookPhoto);
					}
				});
			});

			if($.userEvents) {
				var newCrop = this.getUserSelectedCrop(this.user);
				$.userEvents.addEvent({
					context: ['subjects', this.user.id, 'yearbookPhoto', 'yearbookCrop'],
					action: 'update',
					args: [
						startCrop,
						newCrop
					]
				});
			}
		},
		checkMinimumResolution: function() {
			// Always destroy the popup since it will always either be updated or removed
			if($(wrapper.imgWrapper).hasClass('hasPopup')) {
				$(wrapper.imgWrapper).removeClass('hasPopup').popup('destroy');
			}

			var ratio = this.definition.ratio || 0.8;
			if(this.definition.resizeWrongAspectRatio) {
				ratio = null;
			}
			var issuesHTML = $.SubjectManagement.getSubjectIssuesPopup(this.user, this.inchWidth, {
				ratio: ratio,
				crop: this.getUserSelectedCrop(this.user),
				ignoreMissingPhoto: true
			});
			if(issuesHTML) {
				$(this).addClass('lowResolutionPhoto');
				$(wrapper.imgWrapper).addClass('hasPopup').popup({
					html: issuesHTML,
					observeChanges: false
				});
			} else if($(this).hasClass('lowResolutionPhoto')) {
				$(this).removeClass('lowResolutionPhoto');
			}
		},
		getPhotoDimensions: function() {
			if(!this.user || !this.user.yearbookPhoto || !this.user.yearbookPhoto.width || !this.user.yearbookPhoto.height) {
				return null;
			}

			var photo = this.user.yearbookPhoto;
			var photoWidth = photo.width;
			var photoHeight = photo.height;
			if(photo.yearbookCrop) {
				photoWidth = photoWidth * photo.yearbookCrop.width;
				photoHeight = photoHeight * photo.yearbookCrop.height;
			}

			return {
				width: photoWidth,
				height: photoHeight
			};
		},
		initToolbar: function(recursive) {
			if(this.toolbarInitialized) {
				return;
			}

			var _tmpSetEditable = this.setEditable;

			$.FlowLayoutToolbar(this);
			var shouldIgnoreFocus = this.shouldIgnoreFocus;
			$.extend(this, {
				onChangeInstanceProperty: function (names, values) {
					this.wrapper.setSubjectEffects(names, values);
				},
				getToolByName: $.FlowLayoutFrameUtils.getToolByName,
				toolSettingProperties: $.FlowLayoutFrameUtils.toolSettingProperties,
				editTools: function() {
					var cropGroup = {
						addClass: 'icon',
						group: []
					};
					
					if(wrapper.user && wrapper.user.yearbookPhoto) {
						cropGroup.group.push({
							icon: 'crop',
							popup: 'Edit Crop',
							isActive: function(selection) {
								var crop = wrapper.getUserSelectedCrop(wrapper.user);
								if(crop) {
									return true;
								} else {
									return false;
								}
							},
							onClick: function(selection) {
								wrapper.openSubjectPhotosDialog();
							}
						});
					}
					if(wrapper.user && wrapper.user.yearbookPhoto && wrapper.user.yearbookPhoto.version_ids && wrapper.user.yearbookPhoto.version_ids.length > 1 && $.primarySubjectPhotoVersion !== 'original' && $.userPermissions.editPhotoVersions) {
						cropGroup.group.push('edit versions');
					}

					var filterGroup = [
						'invert',
						'grayscale',
						'blur',
						'opacity',
						'saturate',
						'contrast',
						'brightness',
						'hue'
					];
					if(this.wrapper && this.wrapper.page && this.wrapper.page.forceGrayscale()) {
						filterGroup.removeItem('grayscale');
					}
					// We want people to take blur back off, but otherwise hide it so that people stop making their portraits look bad
					if(!(this.wrapper && this.wrapper.page && this.wrapper.page.extras && this.wrapper.page.extras.subjectEffects && this.wrapper.page.extras.subjectEffects.blur)) {
						filterGroup.removeItem('blur');
					}
					if(this.wrapper && this.wrapper.page && this.wrapper.page.shouldShowGreenScreenOptions() && wrapper.user && wrapper.user.yearbookPhoto && wrapper.user.yearbookPhoto.chroma_key === 'processed') {
						filterGroup.push('green screen background');
					}
					if(this.wrapper && wrapper.user && this.wrapper.page && this.wrapper.page.canEditSubjectDetails()) {
						filterGroup.push({
							icon: 'tasks',
							popup: 'Edit name',
							closeOnClick: true,
							onClick: function() {
								wrapper.openEditSubjectNameDialog();
							}
						});
					}

					var groups = [
						{
							addClass: 'icon',
							group: [
								'stroke',
								'drop shadow'
							]
						},
						{
							addClass: 'icon',
							group: filterGroup
						}
					];

					if(this.definition && $.isInit(this.definition.horizontalOverlap)) {
						var overlapItems = [
							{
								icon: 'resize horizontal',
								popup: 'Horizontal Overlap',
								settingsPicker: {
									description: '%value%% overlap',
									type: 'inc/dec',
									range: [0, 200],
									inc: 10,
									displayMultiplier: 0.5,
									minDisplay: 'No',
									value: parseInt(this.definition.horizontalOverlap.replace('%', ''))
								},
								defaultValue: 0,
								onChange: function(selection, value) {
									wrapper.parent.saveLayoutChange({
										cell: {
											horizontalOverlap: value + '%'
										}
									});
								},
								isActive: function(selection) {
									return wrapper.definition.horizontalOverlap && wrapper.definition.horizontalOverlap != '0%';
								}
							}
						];

						if($.isInit(this.definition.verticalOverlap)) {
							overlapItems.push({
								icon: 'resize vertical',
								popup: 'Vertical Overlap',
								settingsPicker: {
									description: '%value%% overlap',
									type: 'inc/dec',
									range: [0, 100],
									inc: 5,
									minDisplay: 'No',
									value: parseInt(this.definition.verticalOverlap.replace('%', ''))
								},
								defaultValue: 0,
								onChange: function(selection, value) {
									wrapper.parent.saveLayoutChange({
										cell: {
											verticalOverlap: value + '%'
										}
									});
								},
								isActive: function(selection) {
									return wrapper.definition.verticalOverlap && wrapper.definition.verticalOverlap != '0%';
								}
							});
						}

						if($.isInit(this.wrapper.definition.rowsEditable)) {
							overlapItems.push({
								icon: 'list',
								popup: 'Edit number of lines',
								settingsPicker: {
									description: '%value% lines',
									type: 'inc/dec',
									range: [2, 10],
									value: this.wrapper.definition.rows,
									minDisplay: '2 lines',
									maxDisplay: '10 lines'
								},
								defaultValue: 2,
								onChange: function(selection, value) {
									wrapper.parent.saveLayoutChange({
										rows: value
									});
								}
							});
						}


						groups.push({
							addClass: 'icon',
							group: overlapItems
						});
					}

					if(this.definition && $.isInit(this.definition.wave)) {
						var waveItems = [
							{
								icon: 'resize vertical',
								popup: 'Wave height',
								settingsPicker: {
									description: '%value%%',
									type: 'inc/dec',
									range: [5, 80],
									inc: 5,
									value: parseInt(this.definition.wave.replace('%', ''))
								},
								defaultValue: 0,
								onChange: function(selection, value) {
									wrapper.parent.saveLayoutChange({
										cell: {
											wave: value + '%'
										}
									});
								}
							},
							{
								icon: 'resize horizontal',
								popup: 'Start/end wave cells',
								settingsPicker: {
									description: '%value% cells',
									type: 'inc/dec',
									range: [1, 4],
									value: parseInt(this.definition.waveEndCells),
									minDisplay: '1',
									maxDisplay: '4'
								},
								defaultValue: 0,
								onChange: function(selection, value) {
									wrapper.parent.saveLayoutChange({
										cell: {
											waveEndCells: value
										}
									});
								}
							}
						];

						groups.push({
							addClass: 'icon',
							group: waveItems
						});
					}

					if(this.wrapper && this.wrapper.page && this.wrapper.page.canHideCell()) {
						groups.push({
							addClass: 'icon',
							group: [
								{
									icon: 'eraser',
									popup: 'Toggle hidden',
									isActive: function() {
										return wrapper.isHiddenCell();
									},
									onClick: function() {
										wrapper.toggleCellHidden();
									}
								}
							]
						});
					}

					if(cropGroup.group.length) {
						groups.push(cropGroup);
					}

					var pageSet = wrapper.wrapper.getPageSet();
					if($.expectedSubjectCropName === null) {
						var crops = [
							'Primary Crop'
						];

						if(pageSet) {
							$.merge(crops, pageSet.getSubjectPoseCropNames());
						}
						groups.push({
							title: 'Crop Selection',
							dropdown: crops,
							onChange: function(selection, change) {
								wrapper.changeInstanceProperty('cropSelection', change);
								wrapper.flushDelayedSaveQueue();
								wrapper.updateToolbarDisplay();

								// Check if we should be updating aspect ratio
								var page = wrapper.wrapper.getPage();
								var subjectGrid = wrapper.getSubjectGrid();
								var layout = $.extend(true, {}, page.getLayout());
								if(subjectGrid && page && page.getKids && layout.cell.size === 'fit') {
									var users = page.getKids();
									var newRatio = subjectGrid.getCellRatio({
										ratio: '*'
									}, users);

									if(newRatio !== '*' && newRatio !== wrapper.definition.ratio) {
										layout.cell.ratio = newRatio;
										page.setLayout(layout, true);
										wrapper.wrapper.setLayout(layout);
									}

								}
							},
							updateLabel: function(selection, dropdown) {
								dropdown.setSelectedValue(selection.cropSelection || 'primary crop');
							}
						});
					}

					if(pageSet && pageSet.getApplyToAllToolbar && this.wrapper.page) {
						var applyToAllItem = pageSet.getApplyToAllToolbar(this.wrapper.page, {
							popup: 'Apply portrait effects to all',
							candids: false,
							labelStyles: false,
							layout: false,
							margins: false,
							portraitEffects: true,
							texts: false,
							theme: false
						}, this.wrapper.parent);
						if(applyToAllItem) {
							groups.push(applyToAllItem);
						}
					}

					return groups;
				},
				getUserSelection: function() {
					return {
						id: this.getUserSelectionId(),
						selection: 'image'
					};
				},
				getUserSelectionId: function() {
					return this.user ? this.user.id : null;
				},
				moveFromKeyboard: function(x, y, diff) {
					var newLeft = $(this.imgWrapper).getFloatStyle('left') + (diff.x * this.ratio);
					var newTop = $(this.imgWrapper).getFloatStyle('top') + (diff.y * this.ratio);

					$(this.imgWrapper).css({
						left: newLeft + 'px',
						top: newTop + 'px'
					});
					this.saveCurrentSubjectImagePosition();
				},
				getEditToolsDistance: function() {
					if(!this.imgWrapper) {
						return 0;
					}
					
					if(this.imgWrapper.rotatable && this.imgWrapper.getRotatableToolDistance) {
						this.imgWrapper.wrapper = this.wrapper;
						return this.imgWrapper.getRotatableToolDistance();
					} else {
						return -$(this.imgWrapper).getFloatStyle('top');
					}
				},
				shouldIgnoreFocus: function(e) {
					var parent = shouldIgnoreFocus.apply(this, arguments);

					if(parent) {
						return true;
					} else if(e.type !== 'click') {
						return false;
					}

					if(e.ctrlKey && this.selectElementUnderCell(e)) {
						return true;
					}

					var cellOverlap = -$(this).getFloatStyle('marginLeft');
					if(cellOverlap < 0 || !this.definition.horizontalOverlap) {
						// Not actually overlapping
						return false;
					}

					var cellImgWrappers = this.wrapper.getCells().not('.emptyCell, .hiddenCell').map(function() {
						return this.imgWrapper;
					});
					var mouseClickRect = this.getMouseClickRectArray(e);
					var overlaps = $([mouseClickRect]).overlaps(cellImgWrappers).map(function() {
						return this.parentNode;
					});
					let closestOverlap = this.getClosestOverlap(e, overlaps);
					if(closestOverlap && closestOverlap !== this) {
						document.body.dispatchEvent(new MouseEvent('click', {
							target: this
						}));
						closestOverlap.setFocused(true, false);
						e.preventDefault();
						e.stopPropagation();
						return true;
					}

					// For movable images, want to make sure we are actually clicking where the image is
					if(overlaps.is(this)) {
						return false;
					} else {
						// Fallback to another cell if we aren't over the actual cell's image, but are over another's
						if(overlaps.length && overlaps[0] && overlaps[0].setFocused) {
							document.body.dispatchEvent(new MouseEvent('click', {
								target: this
							}));
							overlaps[0].setFocused(true, false);
							e.preventDefault();
							e.stopPropagation();
						}

						return true;
					}
				},
				selectElementUnderCell: function(e) {
					var content = this.wrapper.getContent();
					var overlaps = $([this.getMouseClickRectArray(e)]).overlaps(content);
					if(overlaps.length) {
						overlaps = overlaps.toArray();
						overlaps.sort(function(a, b) {
							var aZIndex = $.isInit(a.instance.zIndex) ? a.instance.zIndex : 0;
							var bZIndex = $.isInit(b.instance.zIndex) ? b.instance.zIndex : 0;
			
							return bZIndex - aZIndex;
						});

						var newFocusIndex = -1;
						overlaps.forEach(function(elem, index) {
							if(elem.focused) {
								newFocusIndex = index;
							}
						});

						document.body.dispatchEvent(new MouseEvent('click', {
							target: this
						}));
						if(newFocusIndex >= 0 && overlaps.length > newFocusIndex + 1) {
							overlaps[newFocusIndex + 1].setFocused(true, false);
						} else {
							overlaps[0].setFocused(true, false);
						}
						e.preventDefault();
						e.stopPropagation();

						return true;
					} else {
						return false;
					}
				},

				getClosestOverlap: function(e, overlaps) {
					var mouseClickRect = this.getMouseClickRectArray(e);
					if(!overlaps) {
						var cellImgWrappers = this.wrapper.getCells().not('.emptyCell, .hiddenCell').map(function() {
							return this.imgWrapper;
						});
						overlaps = $([mouseClickRect]).overlaps(cellImgWrappers).map(function() {
							return this.parentNode;
						});
					}
					var closestOverlap = null;
					var closestOverlapDistance = 999999;
					overlaps.each(function() {
						var rect = this.imgWrapper.getBoundingClientRect();
						var centerX = rect.left + (rect.width / 2);
						var centerY = rect.top + (rect.height / 2);

						var distance = Math.sqrt(Math.pow((mouseClickRect[1] - centerX), 2) + Math.pow((mouseClickRect[0] - centerY), 2));
						if(distance < closestOverlapDistance) {
							closestOverlap = this;
							closestOverlapDistance = distance;
						}
					});

					return closestOverlap;
				},
				getMouseClickRectArray: function(e) {
					var rect = e.target.getBoundingClientRect();
					var offsetX = e.offsetX || 0;
					var offsetY = e.offsetY || 0;

					return [
						rect.top + offsetY,
						rect.left + offsetX,
						2,
						2
					];
				},
				allowSelectAll: false
			});

			this.initResizable();
			this.initRotatable();
			this.setEditable(this.cellEditable);
			if(this.cellEditable) {
				$(this).css('cursor', 'pointer');
			}

			// Swap out setEditable functions with our own
			_setEditable = this.setEditable;
			this.setEditable = _tmpSetEditable;

			this.toolbarInitialized = true;

			var overlappedCells = $(this).overlaps(this.row.parent.getCells());
			overlappedCells.each(function() {
				this.initToolbar();
			});
		},
		initResizable: function() {
			if(!this.definition.subjectResizable) {
				return;
			}

			$(this.imgWrapper).addClass('flowContent');
			$.FlowLayoutResizable(this.imgWrapper, {
				getSecondaryFocusedElements: function() {
					return [];
				},
				removeAlignmentLines: function() {
					
				}
			});

			this.imgWrapper.registerOnStartResize(function(e) {
				this.lockedAspectRatio = wrapper.innerWidth / wrapper.innerHeight;
			});
			this.imgWrapper.registerOnResize(function(e) {
				wrapper.saveCurrentSubjectImageSize();
			});
			this.imgWrapper.registerOnStopResize(function(e) {
				wrapper.saveCurrentSubjectImageSize();
			});

			var _setFocused = this.setFocused;
			$.extend(this, {
				setFocused: function(focused, userClicked) {
					_setFocused.call(this, focused, userClicked);
		
					if(focused) {
						if(this.resizable && userClicked) {
							this.imgWrapper.setResizable(true);
						}
					} else {
						if(this.resizable) {
							this.imgWrapper.setResizable(false);
						}
					}
				},
				resizable: true
			});
		},
		initRotatable: function() {
			if(!this.imgWrapper.rotatable) {
				return;
			}

			$.FlowLayoutRotatable(this.imgWrapper, {
				anchorRotationWithin: 1
			});
			this.imgWrapper.registerOnStartRotate(function(e) {
				
			});
			this.imgWrapper.registerOnRotate(function(e) {
				var page = wrapper.wrapper.getPage();
				page.setExtraProperty('largeCellTransform', this.style.transform);
				
				wrapper.applyRotation(true);
				wrapper.applyInnerDimensions();
			});

			var _setFocused = this.setFocused;
			$.extend(this, {
				setFocused: function(focused, userClicked) {
					_setFocused.call(this, focused, userClicked);
		
					if(focused) {
						this.imgWrapper.setRotable(true);
					} else {
						this.imgWrapper.setRotable(false);
					}
				},
				resizable: true
			});
		},
		applyRotation: function(isLargeCell) {
			if(isLargeCell) {
				var page = this.wrapper.getPage();
				if(!page) {
					return;
				}

				var largeCellTransform = page.getExtraProperty('largeCellTransform');
				this.imgWrapper.style.transform = this.imgWrapper.style['-webkit-transform'] = largeCellTransform || '';
				if(this.cellLabel) {
					this.cellLabel.style.transform = this.cellLabel.style['-webkit-transform'] = largeCellTransform || '';
					if(this.cellLabel.instance) {
						this.cellLabel.instance.transform = largeCellTransform || '';
					}
				}
			} else {
				this.imgWrapper.style.transform = this.imgWrapper.style['-webkit-transform'] = '';

				if(this.cellLabel) {
					this.cellLabel.style.transform = this.cellLabel.style['-webkit-transform'] = '';
					if(this.cellLabel.instance) {
						this.cellLabel.instance.transform = '';
					}
				}
			}
		},
		openVersionPicker: function() {
			this.setFocused(false);
			if(!this.user) {
				return;
			}

			var basePhoto = this.user.yearbookPhoto;
			var photos = basePhoto.version_ids.map(function(photoVersion) {
				return $.extend(true, {
					photoVersion: photoVersion
				}, basePhoto, {
					cdn_url: null
				});
			});

			var me = this;
			var modal = $.PhotoPickerDialog({
				title: 'Photo Versions',
				photoPicker: {
					selection: false,
					searchable: false,
					photos: photos,
					getTitle: function(photo) {
						var versionIndex = basePhoto.version_ids.indexOf(photo.photoVersion) + 1;
						return 'Version ' + versionIndex;
					}
				},
				onVisible: function() {
					$(this).find('.photoPicker > .ui.card').each(function() {
						if(this.photo.photoVersion == basePhoto.version_id) {
							$(this).addClass('blue').find('.label').removeClass('hidden');
						}
					}).click(function() {
						$(this).siblings('.ui.blue.card').removeClass('blue').find('.label').addClass('hidden');
						$(this).addClass('blue').find('.label').removeClass('hidden');
					});

					this.applyToAll = $('<div class="ui checkbox" style="margin-top: 1em"><input name="applyToAllSubject" type="checkbox"/><label>Apply the same change to all subjects</label></div>');
					$(this.content).append(this.applyToAll);
					this.applyToAll.checkbox();
				},
				actions: [
					{
						title: 'Cancel',
						classes: 'negative'
					},
					{
						title: 'OK',
						classes: 'positive'
					}
				],
				onApprove: function() {
					var selectedVersionCard = $(this).find('.photoPicker > .ui.card.blue')[0];
					if(!selectedVersionCard) {
						return;
					}

					var selectedPhotoVersion = selectedVersionCard.photo.photoVersion;
					if(basePhoto.version_id != selectedPhotoVersion) {
						var applyToAll = this.applyToAll.checkbox('is checked');
						if(applyToAll) {
							me.saveVersionChangesToAllSubjects(selectedPhotoVersion);
						} else {
							me.saveVersionChange(selectedPhotoVersion);
						}
					}
				}
			});
			modal.show();

			return modal;
		},
		saveVersionChange: function(newPhotoVersion) {
			this.startLoading();

			var params = {};
			if(newPhotoVersion) {
				params.version_id = newPhotoVersion;
			}

			var me = this;
			$.plicAPI({
				method: 'photos/' + this.user.yearbookPhoto.id + '/restore-version',
				params: params,
				type: 'PATCH',
				success: function(data) {
					me.stopLoading();

					me.refreshPhotoVersion = true;
					var previousCdnUrl = me.user.yearbookPhoto.cdn_url;
					var previousVersionId = me.user.yearbookPhoto.version_id;
					var previousVersionIds = me.user.yearbookPhoto.version_ids;
					me.user.yearbookPhoto.cdn_url = me.user.photoCdnUrl = null;
					me.user.yearbookPhoto.version_id = data.photo.version_id;
					$.extend(me.user.yearbookPhoto, {
						cdn_url: null,
						version_id: data.photo.version_id,
						version_ids: data.photo.version_ids
					});
					me.setUser(me.user, true);

					if($.userEvents) {
						$.userEvents.addEvent({
							context: ['subjects', wrapper.user.id, 'yearbookPhoto'],
							action: 'update',
							args: [
								{
									cdn_url: previousCdnUrl,
									version_id: previousVersionId,
									version_ids: previousVersionIds
								},
								{
									cdn_url: null,
									version_id: data.photo.version_id,
									version_ids: data.photo.version_ids
								}
							],
							permanent: true
						});
					}
				},
				error: function() {
					$.Alert('Error', 'Failed to change photo version');
					me.stopLoading();
				}
			});
		},
		saveVersionChangesToAllSubjects: function(newPhotoVersion) {
			var versionIndex = this.user.yearbookPhoto.version_ids.indexOf(newPhotoVersion);
			if(versionIndex == -1) {
				return;
			}

			var me = this;
			$.ConfirmCheckbox('Apply To All', 'Are you sure you want to rollback all subjects to Version ' + (versionIndex + 1) + '?', function() {
				me.saveVersionChangesToAllSubjectsCall(versionIndex);
			});
		},
		saveVersionChangesToAllSubjectsCall: function(newPhotoVersionIndex) {
			this.wrapper.startLoading();

			var me = this;
			var chain = new $.ExecutionChain(function() {
				me.wrapper.stopLoading();
			});

			var subjects = me.wrapper.getPageSet().getSubjects();
			subjects.forEach(function(subject) {
				if(!subject.yearbookPhoto || !subject.yearbookPhoto.version_ids || newPhotoVersionIndex >= subject.yearbookPhoto.version_ids.length) {
					return;
				}
				var newPhotoVersion = subject.yearbookPhoto.version_ids[newPhotoVersionIndex];

				var params = {};
				if(newPhotoVersion) {
					params.version_id = newPhotoVersion;
				}
				chain.add($.getPlicAPI({
					method: 'photos/' + subject.yearbookPhoto.id + '/restore-version',
					params: params,
					type: 'PATCH',
					success: function(data) {
						var previousCdnUrl = subject.yearbookPhoto.cdn_url;
						var previousVersionId = subject.yearbookPhoto.version_id;
						var previousVersionIds = subject.yearbookPhoto.version_ids;
						subject.yearbookPhoto.cdn_url = subject.photoCdnUrl = null;
						subject.yearbookPhoto.version_id = data.photo.version_id;
						$.extend(subject.yearbookPhoto, {
							cdn_url: null,
							version_id: data.photo.version_id,
							version_ids: data.photo.version_ids
						});

						if($.userEvents) {
							var event = $.userEvents.addEvent({
								context: ['subjects', subject.id, 'yearbookPhoto'],
								action: 'update',
								args: [
									{
										cdn_url: previousCdnUrl,
										version_id: previousVersionId,
										version_ids: previousVersionIds
									},
									{
										cdn_url: null,
										version_id: data.photo.version_id,
										version_ids: data.photo.version_ids
									}
								],
								permanent: true
							});

							me.wrapper.onConsumeSubjectEvent(event);
						}
					}
				}));
			});

			chain.done();
		},
		setMovable: function(movable) {
			if(movable) {
				if(definition.subjectSwappable) {
					this.subjectSwappable = true;
					$(this).addClass('subjectSwappable');
			
					$(this.imgWrapper).one('mouseenter', function() {
						if($(wrapper).hasClass('subjectSwappable')) {
							$(wrapper.imgWrapper).draggable({
								containment: definition.wrapper.container,
								start: function () {
									if ($(wrapper).hasClass('hiddenCell') || !wrapper.user) {
										return false;
									}
									wrapper.parent.initDroppable();
			
									$(this).data('revert', true);
									$(this).css('zIndex', 10);
								},
								stop: function () {
									$(this).css('zIndex', '');
								},
								revert: function () {
									return (this).data('revert');
								}
							});
						}
			
						wrapper.initToolbar();
					});
				} else if(definition.subjectMovable) {
					this.subjectMovable = this.movable = true;
					$(this).addClass('subjectMovable');
			
					$(this.imgWrapper).one('mouseenter', function() {
						if($(wrapper).hasClass('subjectMovable')) {
							$.extend(wrapper.imgWrapper, {
								saveCurrentPosition: function(options) {
									wrapper.saveCurrentSubjectImagePosition();
								}
							});

							$(wrapper.imgWrapper).draggable({
								containment: definition.wrapper.container,
								start: function () {
									if ($(wrapper).hasClass('hiddenCell') || !wrapper.user) {
										return false;
									}
									
									$(this).css('zIndex', 10);
									$(wrapper).addClass('flowContentDragging');
								},
								stop: function () {
									if(!wrapper.row.parentNode) {
										return;
									}

									wrapper.saveCurrentSubjectImagePosition();
									$(this).css('zIndex', '');
									$(wrapper).removeClass('flowContentDragging');
									if(wrapper.definition.horizontalOverlap) {
										$(wrapper).addClass('flowContentHovering');
									}
									wrapper.row.parentNode.setupCellWaveZIndex();
								}
							});
						}
			
						wrapper.initToolbar();
					});
				}
			} else {
				$(this.imgWrapper).off('mouseenter');
				if ($(this).hasClass('movable')) {
					$(this).removeClass('movable').draggable('destroy').css('cursor', '');
				}

				if(this.subjectSwappable) {
					this.subjectSwappable = false;
					$(this).removeClass('subjectSwappable');
				}

				if(this.subjectMovable) {
					this.subjectMovable = this.movable = false;
					$(this).removeClass('subjectMovable');
				}
			}
		},

		updateSubjectImagePosition: function() {
			var page = this.getPage();
			if(!this.user || !page || !this.definition.subjectMovable) {
				return;
			}

			var position = page.getSubjectCellDataValue(this.user.id, 'imgWrapperPosition');
			if(position) {
				$(this.imgWrapper).css({
					left: (position.left * this.ratio) + 'px',
					top: (position.top * this.ratio) + 'px'
				});
			} else {
				$(this.imgWrapper).css({
					left: 0,
					top: 0
				});
			}
		},
		saveCurrentSubjectImagePosition: function() {
			var page = this.getPage();
			page.setSubjectCellDataValue(this.user.id, 'imgWrapperPosition', {
				left: $(this.imgWrapper).getFloatStyle('left') / this.ratio,
				top: $(this.imgWrapper).getFloatStyle('top') / this.ratio
			});

			this.updateEditToolbar();
		},
		
		updateSubjectImageSize: function() {
			var page = this.getPage();
			if(!this.user || !page || !this.definition.subjectResizable) {
				return;
			}

			var size = page.getSubjectCellDataValue(this.user.id, 'imgWrapperSize');
			if(size) {
				$(this.imgWrapper).css({
					width: (this.innerWidth * size.width) + 'px',
					height: (this.innerHeight * size.height) + 'px'
				});
			} else {
				$(this.imgWrapper).css({
					width: this.innerWidth + 'px',
					height: this.innerHeight + 'px'
				});
			}
		},
		saveCurrentSubjectImageSize: function() {
			var outerWidth = $(this).getFloatStyle('width');
			var outerHeight = $(this).getFloatStyle('height');
			var innerWidth = $(this.imgWrapper).getFloatStyle('width');
			var innerHeight = $(this.imgWrapper).getFloatStyle('height');

			var page = this.getPage();
			page.setSubjectCellDataValue(this.user.id, 'imgWrapperSize', {
				width: innerWidth / outerWidth,
				height: innerHeight / outerHeight
			});
		},
		updateWavePosition: function() {
			if(!this.definition.wave || !$.isInit(this.cellIndex)) {
				return;
			}

			var wavePercentage = parseInt(this.definition.wave.replace('%', ''));
			var canvasHeight = this.row.parentNode.getBoundingClientRect().height;
			var waveHeight = (wavePercentage / 100) * canvasHeight;

			var cellIndex = this.cellIndex - (this.definition.waveEndCells - 1);
			var totalCells = this.getTotalCells() - 1;
			if(this.definition.waveEndCells > 1) {
				totalCells -= (this.definition.waveEndCells - 1) * 2;
			}
			var position = Math.min(1, Math.max(0, 1 - cellIndex / totalCells));
			$(this.imgWrapper).add(this.cellLabel).css({
				top: (waveHeight * position) + 'px'
			});
		},
		getSubjectGrid: function() {
			if(this.parentNode && this.parentNode.parentNode) {
				return this.parentNode.parentNode;
			} else {
				return null;
			}
		},

		openEditSubjectNameDialog: function() {
			var user = this.user;
			if(!user) {
				return;
			}

			$.SettingsBuilderDialog([
				{
					id: 'First Name',
					description: 'First Name',
					type: 'text',
					value: user['First Name']
				},
				{
					id: 'Last Name',
					description: 'Last Name',
					type: 'text',
					value: user['Last Name']
				}
			], {
				title: 'Edit subject name',
				onSettingsApplied: function(settings, changes) {
					var properties = {};
					changes.forEach(function(change) {
						properties[change.id] = change.value;
					});
					wrapper.saveSubjectProperties(properties);
				}
			})
		},
		saveSubjectProperties: function(properties) {
			var user = this.user;
			if(!user) {
				return;
			}
			var page = this.wrapper.getPage();

			var idMap = this.wrapper.getPageSet().getTemplateIdMap();
			var plicProperties = {};
			var saveSubject = $.extend(true, {}, user);
			for(var propName in properties) {
				var id = idMap[propName];
				plicProperties[id] = saveSubject[propName] = properties[propName];
				
			}

			var refreshPage = false;
			this.startLoading();
			var chain = new $.ExecutionChain(function() {
				wrapper.stopLoading();
				if(refreshPage) {
					wrapper.wrapper.refreshPage();
				} else {
					wrapper.refreshUser();
				}
			});

			chain.add($.getPlicAPI({
				method: 'projects/' + $.PlicProjectId + '/subjects/' + user.id,
				params: {
					subject: {
						properties: plicProperties,
						merge_properties: true
					}
				},
				type: 'PUT',
				accept: 'application/vnd.plic.io.v1+json',
				success: function() {
					var oldUser = $.extend(true, {}, user);
					for(var propName in properties) {
						user[propName] = properties[propName];
					}

					if($.userEvents) {
						$.userEvents.addEvent({
							context: ['subjects', user.id],
							action: 'update',
							args: [
								oldUser,
								user
							]
						});
					}
				},
				error: function() {
					$.Alert('Error', 'Failed to save name changes');
				}
			}));

			var subjects = page.getSubjects();
			if(subjects) {
				var sortBy = page.db.sortBy;
				var oldSortIndex = subjects.indexOfMatch(user, 'id');
				if(oldSortIndex !== -1) {
					var newSortIndex = $.SubjectManagement.getSubjectSortedPosition(subjects, saveSubject, sortBy);

					var compareNewIndex = newSortIndex;
					if(oldSortIndex < compareNewIndex) {
						compareNewIndex--;
					}
					if(oldSortIndex != compareNewIndex && newSortIndex !== -1) {
						page.moveSubjectToPosition(user, newSortIndex);
						refreshPage = true;
					}
				}
			}

			chain.done();
		},
		getRowIndex: function() {
			return this.row.getRowIndex();
		},
		getCellIndex: function() {
			return this.cellIndex;
		},
		getRowRelativeIndex: function() {
			var rows = this.row.getTotalRows();
			var rowIndex = this.getRowIndex();
			if(rowIndex >= rows * 2 / 3) {
				rowIndex = rowIndex - rows;
			}
			
			return rowIndex;
		},
		getCellRelativeIndex: function() {
			var cellIndex = this.getCellIndex();
			if(cellIndex >= this.row.cells.length * 2 / 3) {
				cellIndex = cellIndex - this.row.cells.length;
			}

			return cellIndex;
		},
		isHiddenCell: function() {
			var hiddenCells = this.getHiddenCells();
			var rowIndex = this.getRowRelativeIndex();
			var cellIndex = this.getCellRelativeIndex();
			return hiddenCells['row-' + rowIndex] && hiddenCells['row-' + rowIndex]['cell-' + cellIndex];
		},
		getHiddenCells: function() {
			var layout = $.extend(true, {}, this.parent.getLayout());
			return layout.hiddenCells || {};
		},
		toggleCellHidden: function() {
			var hiddenCells = this.getHiddenCells();
			var rowIndex = 'row-' + this.getRowRelativeIndex();
			var cellIndex = 'cell-' + this.getCellRelativeIndex();
			if(!hiddenCells[rowIndex]) {
				hiddenCells[rowIndex] = {};
			}

			if(hiddenCells[rowIndex][cellIndex]) {
				hiddenCells[rowIndex][cellIndex] = false;
				$(this).removeClass('hiddenCell allowSelectingCell');
			} else {
				hiddenCells[rowIndex][cellIndex] = true;
				$(this).addClass('hiddenCell allowSelectingCell');
			}

			wrapper.parent.saveLayoutChange({
				hiddenCells: hiddenCells
			});
		},
		updateCellHidden: function() {
			if(this.isHiddenCell()) {
				$(this).addClass('hiddenCell allowSelectingCell');
			} else if($(this).hasClass('allowSelectingCell')) {
				$(this).removeClass('hiddenCell allowSelectingCell');
			}
		},

		setEditable: function(editable) {
			if(this.cellEditable === editable) {
				return;
			}

			if(_setEditable) {
				_setEditable.apply(this, arguments);
			}

			this.cellEditable = editable;
			if(editable) {
				if(this.user && this.editable) {
					$(this).css('cursor', 'pointer');
				}

				this.setMovable(true);
			} else {
				if ($(this.imgWrapper).hasClass('ui-draggable')) {
					$(this.imgWrapper).draggable('destroy');
				}
				this.setMovable(false);
			}

			if(this.label && this.label.editable !== editable) {
				this.label.setEditable(editable);
			}

			this.texts.forEach(function(text) {
				if(text.editable !== editable) {
					text.setEditable(editable);
				}
			});
		},
		startLoading: function() {
			$(this).append('<div class="ui active inverted dimmer"><div class="ui text loader"></div></div>');
		},
		stopLoading: function() {
			$(this).children('.active.dimmer').remove();
		},
		isLoading: function() {
			return $(this).children('.dimmer').hasClass('active');
		},
		disable: function () {
			// For legacy reasons
			this.setEditable(false);
		},
		destroy: function () {
			if(this.label) {
				this.label.destroy();
			}
			for(let i = 0; i < this.texts.length; i++) {
				this.texts[i].destroy();
			}

			$(this.img).CancelImageLoad();

			try {
				if ($(this.imgWrapper).hasClass('isDroppable')) {
					$(this.imgWrapper).removeClass('isDroppable').droppable('destroy');
				}
			} catch(e) {
				console.error('Failed to destroy droppable', e);
			}

			if($(this.imgWrapper).hasClass('hasPopup')) {
				$(this.imgWrapper).removeClass('hasPopup').popup('destroy');
			}
			if(this.imgWrapper.destroyResizableHandlers) {
				this.imgWrapper.destroyResizableHandlers();
			}
		},
		defaultDropShadow: {
			color: 'rgb(0, 0, 0)',
			depth: 4,
			intensity: 2,
			rotation: 315
		},
		instance: {},
		texts: [],
		subjectImageDialogOptions: {},
		toolbarInitialized: false,
		// Cannot just be editable since we use Toolbar directly on cell object
		cellEditable: definition.wrapper.editable,
		requireCtrlForMovement: false,
		defaultFontSize: '10pt'
	}, extras);

	wrapper.labelCSS = definition.studentLabelCSS ? definition.studentLabelCSS : new $.CSSBundle();
	wrapper.ratio = ratio;

	wrapper.colSpan = definition.colSpan ? definition.colSpan : 1;
	wrapper.rowSpan = definition.rowSpan ? definition.rowSpan : 1;

	var outerWrapper = document.createElement('div');
	outerWrapper.className = 'imageOuterStyleWrapper imageWrapper';
	outerWrapper.rootNode = wrapper;

	var middleWrapper = document.createElement('div');
	middleWrapper.className = 'imageMiddleStyleWrapper';

	var innerWrapper = document.createElement('div');
	innerWrapper.className = 'imageInnerStyleWrapper';

	var img = document.createElement('img');
	img.style.display = 'none';
	// This is needed since a bug in FF stop the css properties from working correctly
	$(img).on('dragstart', function() {
		return false;
	});

	outerWrapper.appendChild(middleWrapper);
	middleWrapper.appendChild(innerWrapper);
	innerWrapper.appendChild(img);
	wrapper.appendChild(outerWrapper);
	wrapper.img = img;
	wrapper.imgWrapper = outerWrapper;

	if(extras.row && extras.row.parentNode) {
		wrapper.cellEditable = extras.row.parentNode.editable;
	} else {
		wrapper.cellEditable = definition.wrapper.editable;
	}

	if(wrapper.cellEditable) {
		wrapper.setMovable(true);
	}

	// Setup name location
	wrapper.parent = wrapper.wrapper = definition.wrapper;
	if(definition.name && definition.name != 'none') {
		wrapper.label = wrapper.cellLabel = wrapper.createLabel();
	}

	if(definition.hidden) {
		$(wrapper).data('behindFrames', [true]);
	} else {
		$(wrapper).data('behindFrames', []);
	}

	wrapper.changeDefinition(definition);
	wrapper.overlappedCells = $();
	wrapper.maskedBorder = true;

	return wrapper;
};