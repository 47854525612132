$.FlowVersionPage = function(settings) {
	var obj = new $.FlowPage();

	$.extend(obj, {
		type: settings.rootPage.type,
		changeScope: 'pageVersions'
	}, settings);

	for(var id in settings.rootPage) {
		if(typeof settings.rootPage[id] === 'function') {
			obj[id] = settings.rootPage[id];
		}
	}

	return obj;
};