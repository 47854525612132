$.FlowPageSubSet = function() {
	return function(pageSet, pages) {
		var obj = new Object;
		obj.wrapperPageSet = pageSet;
		for(var i in pageSet) {
			obj[i] = pageSet[i];
		}
		obj.pages = pages;

		return obj;
	};
} ();