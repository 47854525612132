$.CategoryAbstractSlider = function(name, options) {
	if(!name) {
		throw 'No name defined';
	}

	var wrapper = $('<div id="' + name + 'CategoryWrapper">')[0];
	wrapper.name = name;

	$.extend(wrapper, {
		loadCategories: function (url, options) {
			$(this).css('visibility', '');
			this.slider.startLoading();

			var posts = {jobId: $.getGETParams().jobId};
			if (options.posts) {
				for (let i in options.posts) {
					var post = options.posts[i];
					posts[i] = post;
				}
			}
			if (!options.posts) {
				options.posts = {};
			}

			var me = this;
			var success = function(data) {
				if (data.status && data.status == 'error') {
					me.error(true);
					return;
				}

				if (data.default && !options.defaultCategory) {
					if (data.default.name) {
						options.defaultCategory = data.default.name;
					} else {
						options.defaultCategory = data.default;
					}
				}

				var results = data.results ? data.results : data;
				if (options.setResultsDirectly) {
					me.setCategories(results, options.defaultCategory);
				} else {
					me.loadResults(results, options);
				}

				if (options.onFirstLoad) {
					options.onFirstLoad.call(wrapper, data, options.posts);
				}
			};

			var error = function() {
				me.error(true);
			};

			if (typeof url == 'string') {
				$.ajax({
					url: 'ajax/' + url,
					dataType: 'json',
					data: posts,
					type: 'POST',
					success: success,
					error: error
				});
			} else if (typeof url == 'function') {
				url(success);
			} else {
				$.plicAPI($.extend({}, url, {
					success: function (data) {
						if (url.parseData) {
							data = url.parseData(data);
						}

						success(data);
					},
					error: error
				}))
			}
		},
		loadResults: function (data, options) {
			var categories = [];
			for (let i = 0; i < data.length; i++) {
				categories.push(options.createCategory(data[i]));
			}

			if (options.appendCategories) {
				for (let i = 0; i < options.appendCategories.length; i++) {
					categories.push(options.appendCategories[i]);
				}
			}

			this.setCategories(categories, options.defaultCategory);

			if (options.onFinish) {
				options.onFinish(data, options.posts);
			}
		},

		setCategories: function (categories, defaultName) {
			if (!this.categories.setCategories(categories, defaultName)) {
				// If we failed to set default, make sure slider is not still loading
				this.slider.stopLoading();
			}
			$(this).css('visibility', '');
		},
		setCategory: function (category) {
			return this.categories.setCategory(category);
		},
		addCategoryAlphabetical: function (category, parent) {
			return this.categories.addCategoryAlphabetical(category, parent);
		},
		addCategory: function (category, beforeIndex, parent) {
			return this.categories.addCategory(category, beforeIndex, parent);
		},
		removeCategory: function (category) {
			this.categories.removeCategory(category);
		},
		editCategoryName: function (category, name) {
			this.categories.editCategoryName(category, name);
		},
		getCurrentCategory: function () {
			return this.categories.getCurrentCategory();
		},
		getCurrentCategories: function () {
			return this.categories.getCurrentCategories();
		},
		getSliderData: function (category, options) {
			var me = this;
			this.categories.startLoading();
			this.slider.load(category.load.url, $.extend(true, {}, category.load, {
				dontDisplayResults: true,
				onBeforeFirstLoad: null,
				onFirstLoad: null,
				onFinish: function (data) {
					me.categories.stopLoading();
					options.onFinish(data);
				},
				onError: function () {
					me.categories.stopLoading();
					options.onError();
				}
			}));
		},
		getCachedSliderData: function (category) {
			return this.slider.getCachedData(category.load.url, category.load);
		},
		addItemToCategoriesCache: function(category, item) {
			var cacheName = this.slider.getCachedName(category.load.url, category.load);
			this.slider.addItemToCache(cacheName, item);
		},
		error: function (passToSlider) {
			this.categories.error();

			if (passToSlider) {
				// Only set category to error if we failed to load categories
				if(this.button) {
					this.button.text('Error');
				}
				this.slider.error();
			}
		},
		isLoading: function () {
			return this.slider.isLoading();
		}
	});

	if(options) {
		if(options.hidden) {
			$(wrapper).css('visibility', 'hidden');
		}
		if(options.className) {
			$(wrapper).addClass(options.className);
		}
	}

	return wrapper;
};

$.CategoryVerticalSlider = function(name, options) {
	if(!options) {
		options = {};
	}

	var wrapper = new $.CategoryAbstractSlider(name, {
		className: 'categoryVerticalSlider',
		hidden: true
	});

	// Add categories
	wrapper.categories = new $.CategoryPicker(name, {
		categories: options ? options.categories : null,
		defaultCategory: options ? options.defaultCategory : null,
		search: options ? options.search : null,
		onCategoryChange: function(category) {
			if(category) {
				wrapper.slider.load(category.load.url, category.load);
			} else {
				wrapper.slider.clear();
				wrapper.slider.stopLoading();
			}

			if(options.onCategoryChange) {
				options.onCategoryChange(category);
			}
		},
		canSearch: function() {
			return !wrapper.isLoading() || !!wrapper.slider.currentSearch;
		},
		onSearch: function(value) {
			wrapper.slider.triggerSearch(options.search, value);
		},
		addConstantFilter: function(filterFunction) {
			wrapper.slider.addConstantFilter(options.search, filterFunction);
		},
		removeConstantFilter: function(filterFunction) {
			wrapper.slider.removeConstantFilter(options.search, filterFunction);
		},
		leftDropdown: (options && options.rightScrollbar) ? true : false,
		parent: wrapper,
		toggleNotInUse: options.toggleNotInUse,
		onToggleNotInUse: options.onToggleNotInUse
	});
	wrapper.dropdown = wrapper.categories.dropdown;
	wrapper.button = wrapper.categories.button;
	wrapper.options = wrapper.categories.options;
	$(wrapper).append(wrapper.categories);

	// Add slider
	var sliderWrapper = $('<div id="' + name + 'SliderWrapper" class="verticalSliderWrapper">');
	wrapper.sliderWrapper = sliderWrapper;
	var slider = new $.DynamicSlider(name, $.extend({
		onError: function() {
			wrapper.error();
		}
	}, options));
	wrapper.slider = slider;
	sliderWrapper.append(slider).appendTo(wrapper);

	if(options) {
		if(options.button) {
			var button = $('<div id="' + name + 'AddButton" class="ui button blue mini" style="width: 100%">');
			button.text(options.button.name);
			if(options.button.icon) {
				button.prepend('<i class="' + options.button.icon + ' icon">');
			}

			button.click(options.button.onClick);
			$(sliderWrapper).append(button);
			wrapper.alwaysButton = button;

			$(sliderWrapper).css('padding-bottom', '36px');
		}

		if(options.sliderBackground) {
			wrapper.slider.background = 'url(' + options.sliderBackground + ')';
		}
	}

	return wrapper;
};
$.CategoryVerticalSlider2 = function(name, options = {}) {
	var wrapper = new $.CategoryAbstractSlider(name, {
		className: 'categoryVerticalSlider',
		hidden: true
	});

	wrapper.categories = new $.CategoryPicker2(name, {
		categories: options ? options.categories : null,
		defaultCategory: options ? options.defaultCategory : null,
		search: options ? options.search : null,
		onCategoryChange: function(category) {
			if(!wrapper.slider) {
				return;
			}

			if(category) {
				wrapper.slider.load(category.load.url, category.load);
			} else {
				wrapper.slider.clear();
				wrapper.slider.stopLoading();
			}

			if(options.onCategoryChange) {
				options.onCategoryChange(category);
			}
		},
		canSearch: function() {
			return !wrapper.isLoading() || !!wrapper.slider.currentSearch;
		},
		onSearch: function(value) {
			wrapper.slider.triggerSearch(options.search, value);
		},
		addConstantFilter: function(filterFunction) {
			wrapper.slider.addConstantFilter(options.search, filterFunction);
		},
		removeConstantFilter: function(filterFunction) {
			wrapper.slider.removeConstantFilter(options.search, filterFunction);
		},
		leftDropdown: (options && options.rightScrollbar) ? true : false,
		parent: wrapper,
		toggleNotInUse: options.toggleNotInUse,
		onToggleNotInUse: options.onToggleNotInUse
	});
	$(wrapper).append(wrapper.categories);

	// Add slider
	var sliderWrapper = $('<div id="' + name + 'SliderWrapper" class="verticalSliderWrapper">');
	wrapper.sliderWrapper = sliderWrapper;
	var slider = new $.DynamicSlider(name, $.extend({
		onError: function() {
			wrapper.error();
		}
	}, options));
	wrapper.slider = slider;
	sliderWrapper.append(slider).appendTo(wrapper);

	if(options) {
		if(options.button) {
			var button = $('<div id="' + name + 'AddButton" class="ui button blue mini" style="width: 100%">');
			button.text(options.button.name);
			if(options.button.icon) {
				button.prepend('<i class="' + options.button.icon + ' icon">');
			}

			button.click(options.button.onClick);
			$(sliderWrapper).append(button);
			wrapper.alwaysButton = button;

			$(sliderWrapper).css('padding-bottom', '36px');
		}

		if(options.sliderBackground) {
			wrapper.slider.background = 'url(' + options.sliderBackground + ')';
		}
	}

	return wrapper;
};

$.CategoryHorizontalSlider = function(name, options) {
	var wrapper = new $.CategoryAbstractSlider(name, {
		className: 'categoryHorizontalSlider'
	});

	$.extend(wrapper, {
		canSearch: function () {
			return !this.isLoading();
		}
	});

	// Add categories
	wrapper.categories = new $.CategoryPicker(name, {
		categories: options ? options.categories : null,
		defaultCategory: options ? options.defaultCategory : null,
		fluid: false,
		onCategoryChange: function(category) {
			if(category) {
				wrapper.slider.load(category.load.url, category.load);
			} else {
				wrapper.slider.clear();
				wrapper.slider.stopLoading();
			}

			if(options && options.onCategoryChange) {
				options.onCategoryChange(category);
			}
			if(wrapper.searchBox) {
				wrapper.searchBox.find('input').val('');
			}
		}
	});
	wrapper.dropdown = wrapper.categories.dropdown;
	wrapper.button = wrapper.categories.button;
	wrapper.options = wrapper.categories.options;
	$(wrapper).append(wrapper.categories);

	// Add buttons
	if(options && options.buttons) {
		$(wrapper).append(' ');
		for(let i = 0; i < options.buttons.length; i++) {
			var definition = options.buttons[i];
			var button = $('<div class="ui icon button"></div>');
			if(definition.addClass) {
				button.addClass(definition.addClass);
			}
			if(definition.icon) {
				button.append('<i class="' + definition.icon + ' icon"></i>');
			}
			if(definition.title) {
				button.addClass('labeled');
				button.append(definition.title);
			}
			if(definition.popup) {
				button.attr('data-tooltip', definition.popup);
			}
			if(definition.onClick) {
				button.on('click', definition.onClick);
			}
			$(wrapper).append(button);
		}
	}

	// Add search box
	if(options && options.search) {
		wrapper.searchBox = $('<div class="ui icon input" style="float: right; text-align: right; margin-bottom: 14px;"><input type="search" class="" placeholder="Search..." style="width: auto;"><i class="search icon"></i></div>');
		wrapper.searchBox.find('input').on('keyup', function(e) {
			// On escape, cancel current search
			if (e.keyCode == 27) {
				this.value = '';
			} else if(e.keyCode < 46 && e.keyCode != 8) {
				// Don't do anything for weird keys
				return;
			} else if(!wrapper.canSearch()) {
				this.value = '';
				return;
			}
			var value = this.value;
			if(!value || e.keyCode == 27) {
				wrapper.searchBox.find('.icon').removeClass('link close').addClass('search').off('click');
			} else {
				wrapper.searchBox.find('.icon').removeClass('search').addClass('link close').on('click', function() {
					var e = $.Event('keyup');
					e.keyCode= 27; // escape
					wrapper.searchBox.find('input').trigger(e);
				});
			}

			wrapper.slider.triggerSearch(options.search, this.value);
		});
		$(wrapper).append(wrapper.searchBox);
	}

	// Add slider
	wrapper.slider = new $.DynamicSlider(name, $.extend({
		horizontal: true,
		onError: function() {
			wrapper.error();
		}
	}, options));
	$('<div class="ui segment" style="clear: both;">').append(wrapper.slider).appendTo(wrapper);

	if(options) {
		if(options.minHeight) {
			$(wrapper.slider).css('min-height', options.minHeight);
		}
		if(options.hideCategories) {
			$(wrapper.categories).css('visibility', 'hidden');
		}
	}

	return wrapper;
};

$.CategoryPicker = function(name, options) {
	if(!name) {
		name = 'random' + Math.random().toFixed(4).replace('.', '');
	}

	var wrapper = $('<div id="' + name + 'CategoryChoice" class="ui menu categoryChoice"><div class="ui fluid singleCategory dropdown item"><i class="dropdown icon"></i><span id="' + name + 'CategoryButton" class="categoryButton">&nbsp;</span><div id="' + name + 'CategoryOptions" class="menu categoryOptions"></div></div></div>')[0];
	wrapper.dropdown = $(wrapper).children('.dropdown');
	wrapper.dropdown.dropdown({
		action: 'nothing',
		showOnFocus: false,
		selector: {
			search: 'input.search'
		}
	});
	wrapper.button = $(wrapper).find('#' + name + 'CategoryButton');
	wrapper.options = $(wrapper).find('#' + name + 'CategoryOptions');
	wrapper.dropdownIcon = $(wrapper.dropdown).children('i.icon');

	$.extend(wrapper, {
		loadCategories: function(options) {
			this.startLoading();

			var posts = {};
			if (options.posts) {
				for (let i in options.posts) {
					var post = options.posts[i];
					posts[i] = post;
				}
			}

			$.ajax($.extend({
				dataType: 'json'
			}, options.ajax, {
				success: function(data) {
					var results = data;
					if(options.results) {
						results = data[options.results];
					} else if(data.results) {
						results = data.results;
					}

					wrapper.setCategories(results, options.default);

					if(options.success) {
						options.success.apply(this, arguments);
					}
					wrapper.stopLoading();
				},
				error: function() {
					wrapper.error();
					if(options.error) {
						options.error.apply(this, arguments);
					}
				}
			}));
		},
		setCategories: function (categories, defaultName) {
			var me = this;
			if (!categories) {
				categories = [];
			}

			this.options.empty();

			if (this.search) {
				var placeholder;
				if (this.search.placeholder) {
					placeholder = this.search.placeholder;
				} else {
					placeholder = 'Search...';
				}

				var searchBox = $('<div class="ui icon search fluid input"> <i class="search icon"></i><input name="search" type="text" autocomplete="off"></div>')
					.attr('placeholder', placeholder);
				searchBox.find('input').on('keyup', this.searchBoxHandler = function (e) {
					// On escape, cancel current search
					if(e.keyCode == 27) {
						this.value = '';
					} else if(e.keyCode < 46 && e.keyCode != 8) {
						// Don't do anything for weird keys
						return;
					} else if(!me.canSearch()) {
						this.value = '';
						return;
					}
					var value = this.value;
					if(!value || e.keyCode == 27) {
						searchBox.find('.icon').removeClass('link close').addClass('search').off('click');
					} else {
						searchBox.find('.icon').removeClass('search').addClass('link close').on('click', function () {
							var e = $.Event('keyup');
							e.keyCode = 27; // escape
							searchBox.find('input').trigger(e);
						});
					}

					if(me.onSearch) {
						me.onSearch(this.value);
					}
				}).on('paste', function(e) {
					var input = this;
					window.setTimeout(function() {
						me.searchBoxHandler.call(input, e);
					}, 0);
				});

				this.searchBox = searchBox;
				this.options.append(searchBox);
				this.options.append('<div class="divider"></div>');
			} else {
				this.searchBox = null;
			}

			if(this.toggleNotInUse) {
				var toggleBox = this.toggleBox = $('<div class="ui toggle checkbox" style="margin-left: 0.75em; margin-right: 0.75em;" data-tooltip="' + i18n.t('photoPicker.toggleNotInUseTooltip') + '" data-position="left center"> <input type="checkbox" name="toggleNotInUse"><label>' + i18n.t('photoPicker.toggleNotInUseLabel') + '</label></div>');
				toggleBox.checkbox({
					onChecked: function() {
						me.addConstantFilter(filterNotInUse);
						if(options.onToggleNotInUse) {
							options.onToggleNotInUse(true);
						}
						window.hideImageAlreadyInBook = true;
					},
					onUnchecked: function() {
						me.removeConstantFilter(filterNotInUse);
						if(options.onToggleNotInUse) {
							options.onToggleNotInUse(false);
						}
						window.hideImageAlreadyInBook = false;
					}
				});

				var filterNotInUse = function(photo) {
					return !photo.used || !photo.used.count;
				};

				if(this.searchBox) {
					this.toggleBox.insertAfter(this.searchBox);
				} else {
					this.options.append(this.toggleBox);
					this.options.append('<div class="divider"></div>');
				}
			}

			var defaultCategory = categories.length ? categories[0] : null;
			for (let i = 0; i < categories.length; i++) {
				var cat = categories[i];
				var option = this.addCategory(cat);

				if (cat.name == defaultName || (cat.id && cat.id == defaultName) || (defaultName && defaultName[0] == cat.name) || (!defaultName && i === 0)) {
					if (cat.subCategories) {
						let index = 0;
						for (let j = 0; j < cat.subCategories.length; j++) {
							let name = cat.subCategories[j].name;
							if (name == defaultName || (defaultName && name == defaultName[1])) {
								index = j;
								break;
							}
						}

						defaultCategory = cat.subCategories[index];
						option.find('.item').eq(index).addClass('active selected');
					} else if (cat.wrappedCategories) {
						let index = 0;
						for (let j = 0; j < cat.wrappedCategories.length; j++) {
							let name = cat.wrappedCategories[j].name;
							if (name == defaultName || (defaultName && name == defaultName[1])) {
								index = j;
								break;
							}
						}

						defaultCategory = cat.wrappedCategories[index];
						option.find('.item').eq(index).addClass('active selected');
					} else {
						defaultCategory = cat;
					}

					option.addClass('active selected');
				}
			}
			this.currentCategories = categories;
			if(this.currentCategories && this.onCategoriesChange) {
				this.onCategoriesChange(this.currentCategories);
			}

			if (defaultCategory) {
				if(defaultCategory.albums && defaultCategory.albums.length) {
					defaultCategory = defaultCategory.albums[0];
				}

				if (defaultCategory != this.currentCategory) {
					return this.setCategory(defaultCategory, true);
				} else {
					return true;
				}
			} else {
				this.setCategory(null);
				return true;
			}
		},
		setCategory: function (category, blockFunction) {
			if (category === this.currentCategory && !this.dropdown.hasClass('error')) {
				return false;
			}

			// Remove existing error conditions
			this.dropdown.removeClass('error').css('color', '');

			// Case where we set category to nothing
			if (!category) {
				this.button.html('&nbsp;');
				this.currentCategory = null;

				if (this.onCategoryChange) {
					this.onCategoryChange(null);
				}
				return false;
			}

			// If it is a function, let it override the default functionality
			if (typeof category.load == 'function') {
				if (!blockFunction) {
					category.load();
				}
				return false;
			}

			this.currentCategory = category;
			var icon = null;
			if (category.icon) {
				icon = $('<i class="icon">').addClass(category.icon);
			} else if (category.parent && category.parent.icon) {
				icon = $('<i class="icon">').addClass(category.parent.icon);
			}
			if (category.name === '') {
				if (icon) {
					this.button.html(icon).append('&nbsp;');
				} else {
					this.button.html('&nbsp;');
				}

			} else {
				if (icon) {
					this.button.empty().append(icon).append(' ').append(document.createTextNode(category.name));
				} else {
					this.button.text(category.name);
				}
			}

			// Remove existing buttons if one is defined
			if (this.currentButton) {
				$(this.currentButton).remove();
				this.currentButton = null;
			}

			// Add a button if it is defined to have one
			if (category.button && this.parent) {
				var button = $('<div id="' + category.name.replace(/'/ig, '') + 'AddButton" class="ui button blue mini" style="width: 100%">');
				button.text(category.button.name);
				if (category.button.icon) {
					button.prepend('<i class="' + category.button.icon + ' icon">');
				}

				button.click(category.button.onClick);
				$(this.parent.sliderWrapper).append(button);
				this.currentButton = button;

				$(this.parent.sliderWrapper).css('padding-bottom', '2.5em');
			} else if (this.parent && !this.parent.alwaysButton) {
				$(this.parent.sliderWrapper).css('padding-bottom', '');
			}

			if (this.searchBox) {
				this.searchBox.find('input').val('');
			}

			var categoryButton = this.options.find('#category' + category.buttonId);
			if (categoryButton.length) {
				this.dropdown.find('.active.selected').removeClass('active selected');
				categoryButton.addClass('active selected');
				var parent = categoryButton.parent().parent();
				if (parent.hasClass('parentCategory')) {
					parent.addClass('active selected');
				}
			}

			if (this.onCategoryChange) {
				this.onCategoryChange(category);
			}

			return true;
		},
		addCategoryAlphabetical: function (category, parent) {
			if (!parent) {
				parent = this.options;
			}

			var beforeIndex = null;
			try {
				beforeIndex = 0;
				parent.children('.item').each(function () {
					var childCategory = $(this).data('category');
					if(category.name.toLowerCase() > childCategory.name.toLowerCase() || childCategory.emphasize) {
						beforeIndex++;
					}
				});
			} catch (e) {
				console.error('Failed to get alphabetical index');
				beforeIndex = null;
			}
			this.addCategory(category, beforeIndex, parent);
		},
		addCategory: function (category, beforeIndex, parent) {
			if (!parent) {
				parent = this.options;
			}
			var items = parent.children('.item');
			if (beforeIndex < 0) {
				beforeIndex = 0;
			}

			var option = $('<div class="item">');
			// querySelector fails if starting with a number
			category.buttonId = 'B' + $.createRandomString(20);
			option.attr('id', 'category' + category.buttonId);

			if (category.subCategories) {
				option.addClass('parentCategory');
				this.dropdown.removeClass('singleCategory');
				option.append('<i class="' + (this.leftDropdown ? 'left' : '') + ' dropdown icon">');
				if (category.name === '') {
					option.append('&nbsp;');
				} else {
					option.append(document.createTextNode(category.name));
				}
				var menu = $('<div class="' + (this.leftDropdown ? 'left' : '') + ' menu transition hidden">').appendTo(option);

				for (let i = 0; i < category.subCategories.length; i++) {
					let subCat = category.subCategories[i];
					subCat.parent = category;
					this.addCategory(subCat, null, menu);
				}
			} else if (category.wrappedCategories) {
				option.removeClass('item').addClass('menuWrapper');
				for (let i = 0; i < category.wrappedCategories.length; i++) {
					let subCat = category.wrappedCategories[i];
					subCat.parent = category;
					this.addCategory(subCat, null, option);
				}
			} else {
				if (category.icon) {
					option.append('<i class="' + category.icon + ' icon"></i>');
				}
				if (category.rightIcon) {
					option.append('<i class="' + category.rightIcon + ' right floated icon"></i>');
				}
				if (category.emphasize) {
					option.css('font-style', 'italic');
				}
				if (category.name === '') {
					option.append('&nbsp;');
				} else {
					option.append(document.createTextNode(category.name));
				}

				if (category.popup) {
					var popupOptions = {
						position: 'left center'
					};

					if(typeof category.popup == 'string') {
						popupOptions.content = category.popup;
					} else {
						var popupHtml = '';
						if ($.isArray(category.popup)) {
							for (let i = 0; i < category.popup.length; i++) {
								var button = category.popup[i];
								var buttonDiv = $('<div class="ui compact button">');
								if (button.color) {
									buttonDiv.addClass(button.color);
								}
								if (button.name) {
									buttonDiv.text(button.name);
									buttonDiv.attr('name', button.name);
								}

								popupHtml += buttonDiv[0].outerHTML;
							}
						}

						$.extend(popupOptions, {
							html: popupHtml,
							hoverable: true,
							observeChanges: false,
							delay: {
								show: 0,
								hide: 100
							},
							onCreate: function () {
								for (let i = 0; i < category.popup.length; i++) {
									var button = category.popup[i];
									var buttonDiv = $(this).find('.button[name="' + button.name + '"]');
									buttonDiv.data('button', button);

									if (button.onClick) {
										buttonDiv.on('click', function () {
											option.popup('hide');
											var button = $(this).data('button');
											button.onClick.call(this, category, option);
										});
									}
								}
							}
						});
					}

					option.popup(popupOptions);
				}
			}

			option.data('category', category);
			if (typeof beforeIndex != 'undefined' && beforeIndex !== null) {
				if (beforeIndex >= items.length) {
					parent.append(option);
				} else {
					option.insertBefore(items.eq(beforeIndex));
				}
			} else {
				parent.append(option);
			}

			var categoryList = this.currentCategories;
			if (parent != this.options) {
				var parentCategory;
				if ($(parent).hasClass('menu')) {
					parentCategory = $(parent).parent().data('category');
				} else {
					parentCategory = $(parent).data('category');
				}

				if (parentCategory) {
					category.parent = parentCategory;
					if (parentCategory.subCategories) {
						categoryList = parentCategory.subCategories;
					} else if (parentCategory.wrappedCategories) {
						categoryList = parentCategory.wrappedCategories;
					}
				}
			}

			if (categoryList && categoryList.indexOf(category) == -1) {
				if (!$.isInit(beforeIndex) || beforeIndex >= categoryList.length) {
					categoryList.push(category);
				} else {
					categoryList.splice(beforeIndex, 0, category);
				}
			}

			if(categoryList && this.onCategoriesChange) {
				this.onCategoriesChange(categoryList);
			}

			return option;
		},
		moveCategoryToSubFolder: function(currentCategory, subFolder, root) {
			let categories = this.getCurrentCategories();
			if(root) {
				let rootCategory = root.data('category');
				if(rootCategory && rootCategory.wrappedCategories) {
					categories = rootCategory.wrappedCategories;
				}
			}

			let categoryItem = $(this).find('#category' + currentCategory.buttonId);
			let previousParentItem = categoryItem.parent().parent();
			categoryItem.detach();
			if(previousParentItem.hasClass('parentCategory')) {
				let previousParentCategory = previousParentItem.data('category');
				previousParentCategory.subCategories.removeItem(currentCategory);
				if(previousParentItem.find('.menu > .item').length === 0) {
					this.removeCategory(previousParentCategory);
				} else {
					previousParentItem.removeClass('active selected');
				}
			}

			let parentItem = null;
			let parentCategory = categories.find(cat => cat.name === subFolder && cat.subCategories);
			if(parentCategory) {
				parentCategory.subCategories.push(currentCategory);
				parentItem = $(this).find('#category' + parentCategory.buttonId);
			} else if(subFolder !== 'None') {
				parentCategory = {
					name: subFolder,
					subCategories: []
				};
				this.addCategoryAlphabetical(parentCategory, root);

				// Add to sub categories AFTER so we can keep this selected properly
				parentCategory.subCategories.push(currentCategory);
				parentItem = $(this).find('#category' + parentCategory.buttonId);
			} else {
				// Faking like the root is a sub category to keep the logic simpler
				parentCategory = {
					subCategories: categories
				};
				parentCategory.subCategories.push(currentCategory);
				if(root) {
					parentItem = root;
				} else {
					parentItem = $(this).children('.ui.dropdown.item');
				}
			}

			let inserted = false;
			let children;
			if(root) {
				children = $(parentItem).children('.item');
			} else {
				children = $(parentItem).children('.menu').children('.item');
			}
			for(let i = 0; i < children.length; i++) {
				let subItem = children.eq(i);
				let subCat = subItem.data('category');
				if(currentCategory.name.toLowerCase().localeCompare(subCat.name.toLowerCase()) < 0 && !subCat.emphasize) {
					let subItem = $(this).find('#category' + subCat.buttonId);
					$(categoryItem).insertBefore(subItem);
					inserted = true;
					break;
				}
			}

			parentItem.addClass('active selected');
			if(!inserted) {
				parentItem.children('.menu').append(categoryItem);
			}
		},
		removeCategory: function(category) {
			let index = -1;
			var currentCategories = this.currentCategories;
			for(let i = 0; i < this.currentCategories.length; i++) {
				let cat = this.currentCategories[i];
				if(category == cat) {
					index = i;
					break;
				} else if(cat.wrappedCategories) {
					for(let j = 0; j < cat.wrappedCategories.length; j++) {
						let subCat = cat.wrappedCategories[j];
						if(category == subCat) {
							index = j;
							currentCategories = cat.wrappedCategories;
							break;
						} else if(subCat.subCategories) {
							for(let j = 0; j < subCat.subCategories.length; j++) {
								let subSubCat = subCat.subCategories[j];
								if(category == subSubCat) {
									index = j;
									currentCategories = subCat.subCategories;
									break;
								}
							}
						}
					}
				} else if(cat.subCategories) {
					for(let j = 0; j < cat.subCategories.length; j++) {
						let subCat = cat.subCategories[j];
						if(category == subCat) {
							index = j;
							currentCategories = cat.subCategories;
							break;
						}
					}
				}
			}
			if(index == -1) {
				return;
			}
			var setCategory;
			if(category == this.getCurrentCategory()) {
				if((index + 1) >= currentCategories.length) {
					setCategory = currentCategories[index - 1];
				} else {
					setCategory = currentCategories[index + 1];
				}
				if(!setCategory && currentCategories !== this.currentCategories) {
					setCategory = this.currentCategories[0];
				}

				if(setCategory && setCategory.subCategories) {
					setCategory = setCategory.subCategories[0];
				}
			}

			currentCategories.splice(index, 1);

			var categoryButton = this.options.find('#category' + category.buttonId);
			if(categoryButton.length) {
				let parentItem = categoryButton.parent().parent();
				categoryButton.remove();

				if(parentItem.hasClass('parentCategory')) {
					let parentCategory = parentItem.data('category');
					parentCategory.subCategories.removeItem(category);
					if(parentItem.find('.menu > .item').length === 0) {
						this.removeCategory(parentCategory);
					}
				}
			}

			if(setCategory) {
				this.setCategory(setCategory);
			} else if(category == this.getCurrentCategory()) {
				// Removed last category
				this.setCategory(null);
			}

			if(this.currentCategories && this.onCategoriesChange) {
				this.onCategoriesChange(this.currentCategories);
			}
		},
		editCategoryName: function (category, name) {
			category.name = name;

			var categoryButton = this.options.find('#category' + category.buttonId);
			if (categoryButton.length) {
				categoryButton.text(name);
			}

			if (category == this.getCurrentCategory()) {
				this.button.text(name);
			}
		},
		getCurrentCategory: function () {
			return this.currentCategory;
		},
		getCurrentCategories: function () {
			return this.currentCategories;
		},
		getCurrentSubFolders: function() {
			let categories = this.getCurrentCategories() || [];
			return categories.reduce((categories, category) => {
				if(category.subCategories) {
					categories.push(category.name);
				} else if(category.wrappedCategories) {
					category.wrappedCategories.forEach(category => {
						if(category.subCategories) {
							categories.push(category.name);
						}
					});
				}

				return categories;
			}, []).filter(name => !['Ad Images', 'Personalized Pages Images'].includes(name));
		},
		getSubFolderOptions: function() {
			let subFolderOptions = this.getCurrentSubFolders();
			subFolderOptions.unshift('None');

			return subFolderOptions;
		},
		canSearch: function () {
			return true;
		},

		startLoading: function () {
			this.dropdownIcon.addClass('notched circle loading').removeClass('dropdown');
		},
		stopLoading: function () {
			this.dropdownIcon.removeClass('notched circle loading').addClass('dropdown');
		},
		error: function () {
			// Hardcode error color since menu item overrides it
			this.dropdown.addClass('error');
			this.stopLoading();
		}
	});

	$(wrapper.options).on('click', '.item:not(.parentCategory)', function() {
		wrapper.dropdown.dropdown('hide');
		wrapper.setCategory($(this).data('category'));
	});

	if(options) {
		if(options.onCategoryChange) {
			wrapper.onCategoryChange = options.onCategoryChange;
		}
		if(options.onCategoriesChange) {
			wrapper.onCategoriesChange = options.onCategoriesChange;
		}
		if(options.search) {
			wrapper.search = options.search;
		}
		if(options.canSearch) {
			wrapper.canSearch = options.canSearch;
		}
		if(options.onSearch) {
			wrapper.onSearch = options.onSearch;
		}

		if(options.categories) {
			wrapper.setCategories(options.categories, options.defaultCategory);
		}
		if(options.fluid === false) {
			wrapper.dropdown.removeClass('fluid');
			$(wrapper).addClass('compact');
			wrapper.dropdown.css('min-width', '10em');
		}
		if(options.leftDropdown) {
			wrapper.leftDropdown = true;
		}
		if(options.parent) {
			wrapper.parent = options.parent;
		}
		if(options.toggleNotInUse) {
			wrapper.toggleNotInUse = options.toggleNotInUse;
		}
		if(options.addConstantFilter) {
			wrapper.addConstantFilter = options.addConstantFilter;
		}
		if(options.removeConstantFilter) {
			wrapper.removeConstantFilter = options.removeConstantFilter;
		}

		if(options.autoLoad) {
			wrapper.loadCategories(options.autoLoad);
		}
	}

	return wrapper;
};
$.CategoryPicker2 = function(name, options) {
	const filterNotInUse = function(photo) {
		return !photo.used || !photo.used.count;
	};

	let mount = $('<div id="album-picker-dropdown">');
	let vm = new Vue({
		data: () => ({
			currentCategories: [],
			name: name,
			defaultName: options.defaultCategory
		}),
		methods: {
			setCategories(categories, defaultName) {
				this.currentCategories = categories ? categories : [];
				if(defaultName) {
					this.defaultName = defaultName;
				}

				if(options.onCategoriesChange) {
					options.onCategoriesChange(this.currentCategories);
				}
			}
		},
		render: function(createElement) {
			return createElement('category-dropdown', {
				props: {
					name: this.name,
					items: this.currentCategories || [],
					rootClasses: $.extend(true, {
						compact: options.fluid === false
					}, options.rootClasses),
					rootStyle: options.rootStyle,
					dropdownClasses: $.extend(true, {
						fluid: options.fluid !== false
					}, options.dropdownClasses),
					dropdownStyle: {
						'min-width': '10em'
					},
					defaultName: this.defaultName,
					leftDropdown: options.leftDropdown === true,
					search: options.search,
					canSearch: options.canSearch,
					onSearch: options.onSearch,
					toggleNotInUse: options.toggleNotInUse
				},
				on: {
					'select-item': (album) => {
						if(album && typeof album.load === 'function') {
							album.load();
						} else if(options.onCategoryChange) {
							options.onCategoryChange.call(this, album);
						}

						// Remove existing buttons if one is defined
						if(this.currentButton) {
							$(this.currentButton).remove();
							this.currentButton = null;
						}

						// Add a button if it is defined to have one
						if(album && album.button && options.parent) {
							var button = $('<div id="' + album.name.replace(/'/ig, '') + 'AddButton" class="ui button blue mini" style="width: 100%">');
							button.text(album.button.name);
							if(album.button.icon) {
								button.prepend('<i class="' + album.button.icon + ' icon">');
							}

							button.click(album.button.onClick);
							$(options.parent.sliderWrapper).append(button);
							this.currentButton = button;

							$(options.parent.sliderWrapper).css('padding-bottom', '2.5em');
						} else if(options.parent && !options.parent.alwaysButton) {
							$(options.parent.sliderWrapper).css('padding-bottom', '');
						}
					},
					'change-not-in-use': (value) => {
						if(value) {
							options.addConstantFilter(filterNotInUse);
						} else {
							options.removeConstantFilter(filterNotInUse);
						}

						if(options.onToggleNotInUse) {
							options.onToggleNotInUse(value);
						}
						window.hideImageAlreadyInBook = value;
					}
				}
			});
		}
	}).$mount(mount[0]);
	let picker = vm.$el;
	let vueComponent = vm.$children[0];

	$.extend(picker, {
		// Reproduce existing $.CategoryPicker API
		startLoading: function() {
			vueComponent.startLoading();
		},
		stopLoading: function() {
			vueComponent.stopLoading();
		},
		error: function () {
			vueComponent.error();
		},
		setCategories: function(categories, defaultName) {
			vm.setCategories(categories, defaultName);
		},
		setCategory: function(category) {
			vueComponent.selectItem(category);
		},
		getCurrentCategories: function() {
			return vueComponent.items;
		},
		getCurrentCategory: function() {
			return vueComponent.selectedItem;
		},

		editCategoryName: function(category, name) {
			vueComponent.editItemName(category, name);
		},
		addCategoryAlphabetical: function(category, subFolders) {
			vueComponent.addItemAlphabetical(category, subFolders);

			if(options.onCategoriesChange) {
				options.onCategoriesChange(vueComponent.items);
			}
		},
		addCategory: function(category, subFolders) {
			vueComponent.addItem(category, subFolders);

			if(options.onCategoriesChange) {
				options.onCategoriesChange(vueComponent.items);
			}
		},
		removeCategory: function(category) {
			vueComponent.removeItem(category);

			if(options.onCategoriesChange) {
				options.onCategoriesChange(vueComponent.items);
			}
		},

		editCategoryFolder: function(currentCategory, subFolders) {
			vueComponent.editItemFolder(currentCategory, subFolders);
		},
		getCurrentSubFolders: function() {
			let categories = this.getCurrentCategories() || [];
			return categories.reduce((categories, category) => {
				if(category.subCategories) {
					categories.push(category.name);
				} else if(category.wrappedCategories) {
					category.wrappedCategories.forEach(category => {
						if(category.subCategories) {
							categories.push(category.name);
						}
					});
				}

				return categories;
			}, []).filter(name => !['Ad Images', 'Personalized Pages Images'].includes(name));
		},
		getSubFolderOptions: function() {
			let subFolderOptions = this.getCurrentSubFolders();
			subFolderOptions.unshift('None');

			return subFolderOptions;
		},
		getLeafItems: function() {
			return vueComponent.getLeafItems();
		},

		vueComponent: vueComponent
	});

	Object.defineProperty(picker, 'currentCategory', {
		get() {
			return vueComponent.selectedItem;
		}
	});
	Object.defineProperty(picker, 'currentCategories', {
		get() {
			return vueComponent.items;
		}
	});

	return picker;
};

$.AlbumCategoryPicker = function(name, options) {
	let picker = new $.CategoryPicker2(name, options);

	$.extend(picker, {
		loadAlbums: function(options, onComplete, onError) {
			this.startLoading();
			var parent_type = options.projectId ? 'project' : 'organization';
			var parent_id;
			if(options.projectId) {
				parent_id = options.projectId;
			} else if(options.orgId) {
				parent_id = options.orgId;
			} else if(options.appId) {
				parent_type = 'plic-app';
				parent_id = options.appId;
			} else {
				parent_id = $.PlicOrgId;
			}

			this.setCategories(null);
			$.plicAPI({
				method: 'albums',
				params: {
					parent_resource_type: parent_type,
					parent_resource_id: parent_id,
					tag: options.albumTag
				},
				type: 'GET',
				success: function(data) {
					var albums = data.albums;
					if(options.autoSetAlbums !== false) {
						picker.setAlbums(albums, options);
					}
					onComplete.call(this, albums);
				},
				error: function() {
					picker.error();

					if(onError) {
						onError.call(this);
					}
				}
			});
		},
		setAlbums: function(albums, options) {
			this.stopLoading();

			var defaultAlbum = null;
			for(let i = 0; i < albums.length; i++) {
				this.createCategoryFromAlbum(albums[i], options);
			}

			// Look for default
			this.getRecursiveAlbums(albums).forEach(album => {
				if(album.tags) {
					if($.activeAlbumUpload && $.activeAlbumUpload.id === album.id) {
						defaultAlbum = album.name;
					}

					for(let j = 0; j < album.tags.length; j++) {
						if(album.tags[j] == 'Default Album') {
							defaultAlbum = album.name;
							break;
						}
					}
				}
			});

			albums.albumNameSort();
			picker.setCategories(albums, defaultAlbum);
		},
		getRecursiveAlbums: function(albums) {
			return albums.reduce((albums, album) => {
				if(album.albums) {
					albums.push(...this.getRecursiveAlbums(album.albums));
				} else {
					albums.push(album);
				}

				return albums;
			}, []);
		},
		setAlbumCategories: function(categories) {
			picker.setCategories(categories);
		},
		createCategoryFromAlbum: function(album, options) {
			if(album.albums) {
				album.subCategories = [];
				album.albums.forEach(subAlbum => {
					album.subCategories.push(this.createCategoryFromAlbum(subAlbum, options));
				});
			} else {
				// Rename and take off app prefix
				var appNameWithoutS = $.AppName.substr(0, $.AppName.length - 1);
				var checkPrefixes = [$.AppName + '_', appNameWithoutS + '_'];
				if(options.prefix) {
					checkPrefixes.push(options.prefix);

					if(options.prefix.indexOf($.AppName) == 0) {
						checkPrefixes.push(options.prefix.replace($.AppName, appNameWithoutS));
					}
				}

				for(let i = 0; i < checkPrefixes.length; i++) {
					var prefix = checkPrefixes[i];
					if(album.name.indexOf(prefix) === 0) {
						album.name = album.name.substr(prefix.length);
					}
				}

				let subFolderTag = (album.tags || []).find(tag => tag.includes('SubFolder-'));
				if(subFolderTag) {
					let subFolder = subFolderTag.replace('SubFolder-', '') + '/';
					if(album.name.startsWith(subFolder)) {
						album.name = album.name.replace(subFolder, '');
					}
				}
			}

			return album;
		},
		createNewAlbum: function(button, options) {
			$.SettingsBuilderDialog([
				{
					id: 'name',
					description: i18n.t('misc.name'),
					type: 'text'
				},
				{
					id: 'subFolder',
					description: i18n.t('photoPicker.subFolder'),
					help: i18n.t('photoPicker.subFolderTooltip'),
					type: 'dropdown',
					allowAdditions: true,
					search: true,
					value: 'None',
					options: this.getSubFolderOptions()
				}
			], {
				title: i18n.t('photoPicker.createAlbumButton'),
				onSettingsApplied: function(settings) {
					picker.insertNewAlbum(button, options, settings.name, settings.subFolder);
				}
			});
		},
		insertNewAlbum: function(button, options, name, subFolder) {
			if(button) {
				$(button).addClass('loading');
			}

			var saveName = name;
			if(subFolder && subFolder !== 'None') {
				saveName = subFolder + '/' + saveName;
			}
			if(options.prefix) {
				saveName = options.prefix + saveName;
			}

			var parent_type = options.projectId ? 'project' : 'organization';
			var parent_id;
			if(options.projectId) {
				parent_id = options.projectId;
			} else if(options.orgId) {
				parent_id = options.orgId;
			} else if(options.appId) {
				parent_type = 'plic-app';
				parent_id = options.appId;
			} else {
				parent_id = $.PlicOrgId;
			}

			let tags = [
				options.albumTag
			];
			if(subFolder && subFolder !== 'None') {
				tags.push('SubFolder-' + subFolder);
			}

			$.plicAPI({
				method: 'albums',
				params: {
					album: {
						name: saveName,
						tags: tags,
						viewable_by_suborganizations: true
					},
					parent_resource_type: parent_type,
					parent_resource_id: parent_id
				},
				success: function(data) {
					var cat = picker.createCategoryFromAlbum(data.album, options);
					let subFolders = null;
					if(subFolder && subFolder !== 'None') {
						subFolders = [subFolder];
					}

					picker.addCategoryAlphabetical(cat, subFolders);
					picker.setCategory(cat);

					if(button) {
						$(button).removeClass('loading');
					} else {
						picker.stopLoading();
					}
				},
				error: function(jqXHR) {
					picker.error(true);

					if(jqXHR.responseJSON && jqXHR.responseJSON.errors && jqXHR.responseJSON.errors.name && jqXHR.responseJSON.errors.name.indexOf('has already been taken') != -1) {
						$.Alert('Error', 'Album name is already taken');
					} else {
						$.Alert('Error', 'Failed to create album');
					}

					if(button) {
						$(button).removeClass('loading');
					} else {
						picker.stopLoading();
					}
				}
			});
		},
		deleteCurrentAlbum: function(button, photos) {
			var currentCategory = this.getCurrentCategory();

			var albumInUse = false;
			if(photos) {
				for(let i = 0; i < photos.length; i++) {
					var photo = photos[i];
					if(photo.used && photo.used.count) {
						albumInUse = true;
					}
				}
			}

			$.Confirm('Confirm Delete', 'Are you sure you want to delete this album?', function() {
				$(button).addClass('loading');

				if(albumInUse) {
					let album = picker.getCurrentCategory();

					$.plicAPI({
						method: 'albums/' + album.id,
						params: {
							album: {
								name: album.name + ' (Deleted ' + moment().format('Y/MM/DD') + ')',
								tags: ['Deleted']
							}
						},
						type: 'PUT',
						success: function () {
							$(button).removeClass('loading');
							picker.removeCategory(currentCategory);
						},
						error: function () {
							$.Alert('Error', 'Failed to delete album');
							$(button).removeClass('loading');
						}
					});
				} else {
					$.plicAPI({
						method: 'albums/' + picker.getCurrentAlbumId(),
						type: 'DELETE',
						success: function () {
							$(button).removeClass('loading');
							picker.removeCategory(currentCategory);
						},
						error: function () {
							$.Alert('Error', 'Failed to delete album');
							$(button).removeClass('loading');
						}
					});
				}
			});
		},
		editCurrentAlbumName: function(button, prefix) {
			var currentCategory = this.getCurrentCategory();
			let subFolderTag = currentCategory.tags.find(tag => tag.includes('SubFolder-'));
			let subFolder = 'None';
			if(subFolderTag) {
				subFolder = subFolderTag.replace('SubFolder-', '');
			}

			$.SettingsBuilderDialog([
				{
					id: 'name',
					description: i18n.t('misc.name'),
					type: 'text',
					value: currentCategory.name
				},
				{
					id: 'subFolder',
					description: i18n.t('photoPicker.subFolder'),
					help: i18n.t('photoPicker.subFolderTooltip'),
					type: 'dropdown',
					allowAdditions: true,
					search: true,
					value: subFolder,
					options: this.getSubFolderOptions()
				}
			], {
				title: i18n.t('photoPicker.editAlbumButton'),
				onSettingsApplied: function(settings) {
					$(button).addClass('loading');

					let saveName = settings.name;
					if(settings.subFolder && settings.subFolder !== 'None') {
						saveName = settings.subFolder + '/' + saveName;
					}
					if(prefix) {
						saveName = prefix + saveName;
					}
					let tags = (currentCategory.tags || []).filter(tag => !tag.includes('SubFolder-'));
					if(settings.subFolder && settings.subFolder !== 'None') {
						tags.push('SubFolder-' + settings.subFolder);
					}

					$.plicAPI({
						method: 'albums/' + picker.getCurrentAlbumId(),
						type: 'PUT',
						params: {
							album: {
								name: saveName,
								tags: tags
							}
						},
						success: function() {
							$(button).removeClass('loading');
							currentCategory.tags = tags;
							picker.editCategoryName(currentCategory, settings.name);

							let subFolders = null;
							if(settings.subFolder && settings.subFolder !== 'None') {
								subFolders = [settings.subFolder];
							}
							picker.editCategoryFolder(currentCategory, subFolders);
						},
						error: function(jqXHR) {
							if(jqXHR.responseJSON && jqXHR.responseJSON.errors && jqXHR.responseJSON.errors.name && jqXHR.responseJSON.errors.name.indexOf('has already been taken') != -1) {
								$.Alert('Error', 'Album name is already taken');
							} else {
								$.Alert('Error', 'Failed to change album name');
							}

							$(button).removeClass('loading');
						}
					});
				}
			});
		},
		setCurrentAlbumAsDefault: function(button) {
			var currentAlbum = picker.getCurrentCategory();
			if(currentAlbum.tags.indexOf('Default Album') !== -1) {
				// Already the default
				return;
			}

			$(button).addClass('loading');

			var chain = new $.ExecutionChain(function() {
				$(button).removeClass('loading').addClass('disabled');
			});

			this.getLeafItems().forEach(function(album) {
				if(album.tags.indexOf('Default Album') !== -1) {
					album.tags.removeItem('Default Album');

					chain.add($.getPlicAPI({
						method: 'albums/' + album.id,
						type: 'PUT',
						params: {
							album: {
								tags: album.tags
							}
						}
					}));
				}
			});

			if(currentAlbum.tags.indexOf('Default Album') === -1) {
				currentAlbum.tags.push('Default Album');
				chain.add($.getPlicAPI({
					method: 'albums/' + currentAlbum.id,
					type: 'PUT',
					params: {
						album: {
							tags: currentAlbum.tags
						}
					}
				}));
			}

			chain.done();
		},
		getCurrentAlbumId: function() {
			var currentCategory = this.getCurrentCategory();
			return currentCategory.id ? currentCategory.id : currentCategory.load.posts.album;
		}
	});

	return picker;
};

$.DynamicSlider = function(name, options) {
	if(!name) {
		throw 'No name defined';
	}

	var slider = $('<div id="' + name + 'Slider" class="mightySlider mightyslider_carouselSimple_skin"></div>')[0];

	if(options && options.rightScrollbar) {
		$(slider).addClass('rightScrollbar');
	}

	$.extend(slider, {
		recreate: function () {
			$(this.list).children().filter(':has(img), .candid').each(function () {
				if ($(this).data('module-popup') && $(this).popup('exists')) {
					$(this).popup('destroy');
				}
			});
			$(this.list).children('.manualDetach').each(function () {
				$(this).detach();
			});

			$(this).empty().removeClass(this.orientation);
			$(this).append('<div id="' + this.name + 'SliderFrame" class="frame"><div id="' + this.name + 'List" class="slideelement"></div></div><div class="scrollbar"><div class="handle"><div class="mousearea"></div></div></div>');
			this.list = $(this).find('#' + this.name + 'List');
			this.frame = $(this).find('#' + this.name + 'SliderFrame');
			this.loader = $('<div id="' + this.name + 'SliderLoading" class="ui inverted dimmer"><div class="ui text loader"></div></div>').appendTo(this);

			if (this.background) {
				$(this.list).css('background', this.background);
			}
		},
		addNode: function (node, index, data) {
			if (!node) {
				throw 'No node defined';
			}

			if (!$(this).hasClass(this.orientation)) {
				this.list.append(node);
				this.initMightySlider();
			} else {
				if ((!index && index !== 0) || index >= this.list.children().length) {
					this.frame.mightySlider('add', node);
				} else {
					this.frame.mightySlider('add', node, index);
				}
			}

			if (data) {
				if (this.cachedData && this.cachedData[this.currentType]) {
					var cachedData = this.cachedData[this.currentType];
					cachedData.push(data);
				}
			}
		},
		removeNode: function (node, data, updateCache) {
			if (!$(this).hasClass(this.orientation)) {
				$(node).remove();
			} else {
				this.frame.mightySlider('remove', node);
			}

			if (this.cachedData && this.cachedData[this.currentType] && updateCache !== false) {
				var cachedData = this.cachedData[this.currentType];
				let index = cachedData.indexOf(data);
				if (index != -1) {
					cachedData.splice(index, 1);
				}
			}
		},
		initMightySlider: function () {
			var me = this;

			var sliderOptions = {
				easing: 'easeOutExpo',
				viewport: 'fit',
				preloadMode: 'nearby',
				autoScale: 1,
				navigation: {
					horizontal: (this.orientation == 'horizontal'),
					navigationType: 'basic'
				},
				dragging: {
					mouseDragging: 0,
					touchDragging: 0
				},
				scrollBar: {
					scrollBarSource: '#' + this.name + 'Slider .scrollbar'
				},
				scrolling: {
					scrollSource: '#' + this.name + 'Slider',
					scrollBy: 1
				}
			};

			if (this.slideSize) {
				sliderOptions.navigation.slideSize = this.slideSize;
			}

			var refreshOnStop = false;
			var initialFrameHeight = null;
			var slider = this.frame.mightySlider(sliderOptions, {
				initialize: function() {
					initialFrameHeight = $(me).children('.frame.mSFrame').css('height');
				},
				coverInserted: function (event, elem) {
					var mask = $(elem).parent().data('apply-mask');
					if (mask) {
						var img = $(elem).find('img');
						img.css(mask);
					}

					// Only call reload after done loading a bunch since it is ~30 ms per cover loaded
					if (me.reloadTimeout) {
						window.clearTimeout(me.reloadTimeout);
					}

					me.reloadTimeout = window.setTimeout(function () {
						slider.mightySlider('reload');
						me.reloadTimeout = null;
						refreshOnStop = false;
					}, 100);
					refreshOnStop = false;
				},
				coverLoaded: function (event, index) {
					var elem = me.list.children('div').eq(index);
					elem.removeClass('coverLoading');
					elem.find('.dimmer').remove();

					$(elem).find('img').on('error', function () {
						if (this.src != 'css/images/error_placeholder.png') {
							this.src = 'css/images/error_placeholder.png';
						}
						$(this).off('error');
					});

					var onLoad = elem.data('onLoad');
					if (onLoad) {
						onLoad();
					}
				},
				beforeCoverLoad: function (event, index) {
					var elem = me.list.children('div').eq(index);

					elem.find('.mSLoader').remove();
					if (elem.attr('data-mightyslider') && !elem.hasClass('coverLoading') && !elem.children('.mSCover.coverLoader').length) {
						elem.append('<div class="ui active inverted dimmer"><div class="ui text loader"></div></div>');
					}
					elem.addClass('coverLoading');
					refreshOnStop = false;
				},
				moveStart: function() {
					if(me.orientation !== 'horizontal') {
						refreshOnStop = true;
					}
				},
				moveEnd: function() {
					if(refreshOnStop) {
						$.setSingleTimeout.call(me, 'manualReloadFromMoveEnd', function() {
							if(refreshOnStop) {
								slider.mightySlider('reload');
								refreshOnStop = false;
							}
						}, 100);
					}
				},
				resize: function() {
					if(me.useSimpleHeight && initialFrameHeight) {
						$(me).children('.frame.mSFrame').css('height', initialFrameHeight);
					}

					me.onResize();
				}
			});

			if (this.onSliderInit) {
				this.onSliderInit();
			}
		},

		load: function (url, options) {
			var cacheName = url;
			var posts = {jobId: $.getGETParams().jobId};
			if (options.posts) {
				for (let i in options.posts) {
					var post = options.posts[i];
					cacheName += post;
					posts[i] = post;
				}
			}
			if (!options.posts) {
				options.posts = {};
			}

			// Don't try to change if we are already setup properly
			if (this.currentType == cacheName && !this.errorMessage && !options.dontDisplayResults) {
				return false;
			}
			var previousAjax = this.currentAjax;

			// Check if we already have a cached copy of this
			var me = this;
			if (!options.dontDisplayResults) {
				this.currentType = cacheName;
				this.currentOptions = options;

				this.recreate();
				this.startLoading();
			}
			var data;
			if(options.cacheResults !== false) {
				data = this.cachedData[cacheName];
			}

			if (data) {
				window.setTimeout(function () {
					me.loadResults(data, options);
				}, 100);
			} else if(url == 'loadAlbum') {
				this.currentAjax = $.loadAlbum({
					albumId: posts.album,
					jobId: $.getGETParams().jobId,
					layoutId: $.getGETParams().layoutId,
					previewQuality: posts.previewQuality,
					success: function(data) {
						me.currentAjax = null;

						if (options.onBeforeFirstLoad) {
							var tmp = options.onBeforeFirstLoad(data, options.posts);
							if (tmp) {
								data = tmp;
							}
						}

						var results = data.results ? data.results : data;
						me.cachedData[cacheName] = results;
						me.loadResults(results, options);

						if (options.onFirstLoad) {
							options.onFirstLoad(data, options.posts);
						}
					},
					error: function () {
						if (me.currentType == cacheName || options.dontDisplayResults) {
							if (!options.dontDisplayResults) {
								me.error();
							}

							if (options.onError) {
								options.onError();
							}
						}
					}
				});
			} else if (typeof url == 'string') {
				var type = 'POST';
				if(options.type) {
					type = options.type;
				}

				this.currentAjax = $.ajax({
					url: 'ajax/' + url,
					data: posts,
					type: type,
					dataType: 'json',
					success: function (data) {
						if (data.status && data.status == 'error') {
							this.currentAjax = null;
							if (!options.dontDisplayResults) {
								me.error();
							}

							if (options.onError) {
								options.onError();
							}
							return;
						}
						this.currentAjax = null;

						if (options.onBeforeFirstLoad) {
							var tmp = options.onBeforeFirstLoad(data, options.posts);
							if (tmp) {
								data = tmp;
							}
						}

						var results = data;
						if(data.results) {
							results = data.results;
						} else if(data.data) {
							results = data.data;
						}
						me.cachedData[cacheName] = results;
						me.loadResults(results, options);

						if (options.onFirstLoad) {
							options.onFirstLoad(data, options.posts);
						}
					},
					error: function () {
						if (me.currentType == cacheName || options.dontDisplayResults) {
							if (!options.dontDisplayResults) {
								me.error();
							}

							if (options.onError) {
								options.onError();
							}
						}
					}
				});
			} else if (typeof url == 'function') {
				window.setTimeout(function () {
					var results = url.apply(this, posts);
					me.cacheResults(cacheName, results, options);
				}, 100);
			} else if ($.isArray(url)) {
				window.setTimeout(function () {
					var results = url;
					me.cacheResults(cacheName, results, options);
				}, 100);
			} else {
				throw 'url must be either a function, array, or a string url';
			}

			if (previousAjax) {
				previousAjax.abort();
			}

			return true;
		},
		cacheResults: function(cacheName, results, options) {
			if (options.onBeforeFirstLoad) {
				var tmp = options.onBeforeFirstLoad(results, options.posts);
				if (tmp) {
					results = tmp;
				}
			}

			this.cachedData[cacheName] = results;
			this.loadResults(results, options);

			if (options.onFirstLoad) {
				options.onFirstLoad.call(this, results, options.posts);
			}
		},
		addItemToCache: function(cacheName, item) {
			if(this.cachedData[cacheName]) {
				this.cachedData[cacheName].push(item);
			}
		},
		loadResults: function (data, options) {
			if (!options.dontDisplayResults) {
				this.currentLoadedData = data;
				if (!$(this).hasClass(this.orientation)) {
					var documentFragment = document.createDocumentFragment();
					for (let i = 0; i < data.length; i++) {
						var isValid = true;
						for(let j = 0; j < this.constantFilters.length; j++) {
							if(!this.constantFilters[j](data[i])) {
								isValid = false;
								break;
							}
						}

						if(isValid) {
							var node = options.createNode.call(this, data[i], options.posts);
							if(node instanceof jQuery) {
								node = node[0];
							}
							
							documentFragment.appendChild(node);
						}
					}
					this.list[0].appendChild(documentFragment);

					if (data.length) {
						this.initMightySlider();
					}
				} else {
					for (let i = 0; i < data.length; i++) {
						this.addNode(options.createNode.call(this, data[i], options.posts));
					}
				}
			}

			if (options.onFinish) {
				options.onFinish.call(this, data, options.posts);
			}

			if (!options.dontDisplayResults) {
				this.stopLoading();
			}
		},
		refresh: function() {
			this.frame.mightySlider('reload');
		},
		clear: function () {
			this.currentLoadedData = null;
			this.currentType = null;
			this.currentOptions = null;

			this.recreate();
			if (this.currentAjax) {
				this.currentAjax.abort();
				this.currentAjax = null;
			}
		},
		triggerSearch: function (filter, search, forceFilter) {
			if (this.pendingSearch) {
				window.clearTimeout(this.pendingSearch);
				this.pendingSearch = null;
			}

			var me = this;
			this.pendingSearch = window.setTimeout(function () {
				me.pendingSearch = null;
				me.filterResults(filter, search, forceFilter);
			}, 200);
		},
		filterResults: function (filter, search, forceFilter) {
			if(this.currentSearch == search && !forceFilter) {
				return;
			}

			var data = this.cachedData[this.currentType];
			if (!data) {
				return;
			}
			this.currentSearch = search;
			if(this.currentSearchChain) {
				this.currentSearchChain.cancel();
				this.currentSearchChain = null;
			}

			var filteredData = [];
			var chain = new $.ExecutionChain(function() {
				// Re-add with only these in there
				slider.stopLoading();

				slider.constantFilters.forEach(function(filterFunction) {
					filteredData = filteredData.filter(function(data) {
						return filterFunction(data);
					});
				});

				slider.filterResultsDisplay(filter, filteredData);

				slider.currentSearchChain = null;
			});

			if (search) {
				this.startLoading();
				if(filter.filter) {
					chain.add(function() {
						filteredData = slider.filterResultsWithFunction(filter.filter, search, data);
					});
				} else if(filter.filterChain) {
					filter.filterChain.call(this, filteredData, chain, search, data);
				}
			} else {
				filter = {};
				filteredData = data;
			}

			chain.done();

			this.currentSearchChain = chain;
		},
		filterResultsWithFunction: function(filter, search, data) {
			var filteredData = [];

			// Do lower case comparison
			search = search.toLowerCase();
			var searchParts = search.split(' ');

			for (let i = 0; i < data.length; i++) {
				var titles = filter.call(this, data[i]);
				if (!$.isArray(titles)) {
					titles = [titles];
				}

				for (var l = 0; l < titles.length; l++) {
					var title = titles[l].toLowerCase();

					// Check against normal and as if - or _ are spaces
					if (title.indexOf(search) != -1) {
						filteredData.push(data[i]);
					} else if (searchParts.length > 1) {
						// Try to break filter and title into parts and see if each piece is there
						var titleParts = title.replace(/[-_]/ig, ' ').split(' ');
						if (titleParts.length > 1) {
							var found = true;
							for (let j = 0; j < searchParts.length; j++) {
								// Need to find at least one match for each search piece
								var subFound = false;
								for (var k = 0; k < titleParts.length; k++) {
									if (titleParts[k].indexOf(searchParts[j]) != -1) {
										subFound = true;
										break;
									}
								}

								if (!subFound) {
									found = false;
									break;
								}
							}

							if (found) {
								filteredData.push(data[i]);
							}
						}
					}
				}
			}

			return filteredData;
		},
		filterResultsDisplay: function(filter, filteredData) {
			this.recreate();
			if (filteredData.length) {
				for (let i = 0; i < filteredData.length; i++) {
					var node = filteredData[i];
					if (node.cachedNode) {
						this.list.append(node.cachedNode);
					} else if(filter.createNode) {
						this.list.append(filter.createNode.call(this, node, filter.posts));
					} else {
						this.list.append(this.currentOptions.createNode.call(this, node, this.currentOptions.posts));
					}
				}
				this.initMightySlider();
			}
		},
		getCachedName: function(url, options) {
			var cacheName = url;
			if (options.posts) {
				for (let i in options.posts) {
					cacheName += options.posts[i];
				}
			}

			return cacheName;
		},
		getCachedData: function (url, options) {
			var cacheName = this.getCachedName(url, options);
			return this.cachedData[cacheName];
		},
		addConstantFilter: function(filter, filterFunction) {
			this.constantFilters.push(filterFunction);
			this.triggerSearch(filter, this.currentSearch, true);
		},
		removeConstantFilter: function(filter, filterFunction) {
			this.constantFilters.removeItem(filterFunction);
			this.triggerSearch(filter, this.currentSearch, true);
		},

		startLoading: function () {
			if (this.errorMessage) {
				this.errorMessage.remove();
				this.errorMessage = null;
			}
			$(this.loader).addClass('active');
		},
		stopLoading: function () {
			$(this.loader).removeClass('active');
		},
		isLoading: function () {
			return $(this.loader).hasClass('active');
		},
		error: function () {
			this.stopLoading();

			this.errorMessage = $('<div class="ui inverted active dimmer"><div class="content"><div class="center"><i class="red huge warning sign icon"></i></div></div></div>');
			$(this).append(this.errorMessage);

			if (this.onError) {
				this.onError();
			}
		},
		name: name,
		cachedData: {},
		currentSearch: '',
		constantFilters: []
	});

	if(options) {
		if (options.onError) {
			slider.onError = options.onError;
		}
		if (options.onSliderInit) {
			slider.onSliderInit = options.onSliderInit;
		}
		if(options.slideSize) {
			slider.slideSize = options.slideSize;
		}
		if(options.useSimpleHeight) {
			slider.useSimpleHeight = options.useSimpleHeight;
		}
	}
	if (options && options.horizontal) {
		slider.orientation = 'horizontal';
	} else {
		slider.orientation = 'vertical';
		$(slider).addClass('vertical-slider');
	}

	$.registerEvent(slider, 'resize');

	slider.recreate();
	return slider;
};

$.convertAlbumsToSubFolders = function(albums) {
	let subFolders = {};
	[...albums].forEach(album => {
		if(!album.tags) {
			return;
		}

		let subFolderTag = album.tags.find(tag => tag.indexOf('SubFolder-') === 0);
		if(subFolderTag) {
			let subFolderName = subFolderTag.replace('SubFolder-', '');
			if(!subFolders[subFolderName]) {
				subFolders[subFolderName] = [];
			}
			subFolders[subFolderName].push(album);
			albums.removeItem(album);
		} else if(album.tags && album.tags.find(tag => tag.includes('Ads-'))) {
			let subFolderName = 'Ad Images';
			if(!subFolders[subFolderName]) {
				subFolders[subFolderName] = [];
			}
			subFolders[subFolderName].push(album);
			albums.removeItem(album);
		} else if(album.tags && album.tags.find(tag => tag.includes('Personalized Pages-'))) {
			let subFolderName = 'Personalized Pages Images';
			if(!subFolders[subFolderName]) {
				subFolders[subFolderName] = [];
			}
			subFolders[subFolderName].push(album);
			albums.removeItem(album);
		}
	});

	Object.keys(subFolders).forEach(name => {
		let subFolder = {
			name: name,
			albums: subFolders[name],
			subCategories: subFolders[name]
		};
		subFolder.albums.caseInsensitiveSort('name');

		albums.push(subFolder);
	});
}