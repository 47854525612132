$.CSSBundle = function(onChange, init) {
	var wrapper = new Object;

	$.extend(wrapper, {
		setCSS: function (name, value, blockOnChange, forceChange) {
			if (!this.css) {
				this.css = {};
			}

			var oldValue = this.css[name];
			var change = oldValue != value;
			this.css[name] = value;

			if (this.onChange && (change || forceChange) && !blockOnChange) {
				this.onChange(name, value, oldValue);
			}
		},
		getCSS: function (name) {
			return this.css[name];
		},
		removeCSS: function (name) {
			delete this.css[name];
		},
		apply: function (elem, ratio) {
			// If we are including a ratio, apply it to font size
			var css = $.extend({}, this.css);
			if (ratio) {
				var fontSize = css['font-size'];
				if (fontSize) {
					fontSize = parseInt(fontSize.replace('pt', ''));
					fontSize = Math.ceil(fontSize * ratio / $.FONT_MULTIPLIER);
					css['font-size'] = fontSize + 'pt';
				}
			}

			for (var style in css) {
				if (style == 'effects') {
					continue;
				}

				if (elem instanceof $) {
					for (var i = 0; i < elem.length; i++) {
						elem[i].style[style] = css[style];
					}
				} else {
					elem.style[style] = css[style];
				}
			}
		},

		css: init ? init : {},
		onChange: onChange
	});

	return wrapper;
};