<template>
	<div :id="item.buttonId" class="item custom-item category-dropdown-item" :class="itemClass" :depth="item.subCategories ? depth + 1 : null" @click="selectItem" @mouseenter="hoverStart">
		<i class="dropdown icon" :class="iconClasses" v-if="item.subCategories" />
		<i class="icon" :class="{ [item.icon]: true }" v-if="item.icon" />
		{{ item.name }}
	</div>
</template>

<script>
export default {
	props: ['item', 'depth', 'leftDropdown', 'selectedId'],
	computed: {
		itemClass() {
			let classes = {};

			if(this.selectedId && this.isRecursiveSelection(this.item)) {
				classes.active = true;
				classes.selected = true;
			}

			if(this.item.emphasize) {
				classes.emphasize = true;
			}
			classes.sticky = !!this.item.sticky;

			return classes;
		},

		iconClasses() {
			if(this.leftDropdown) {
				return { left: true };
			} else {
				return {};
			}
		}
	},
	methods: {
		selectItem() {
			this.$emit('select-item', this.item);
		},
		hoverStart() {
			this.$emit('hover-start', this.item, this.depth + 1);
		},

		isRecursiveSelection(item) {
			if(item.id && item.id === this.selectedId || (item.subCategories && item.subCategories.find(cat => this.isRecursiveSelection(cat)))) {
				return true;
			} else {
				return false;
			}
		}
	},
	mounted() {
		if(this.item.popup) {
			let popupOptions = {
				position: 'left center'
			};
			if(typeof this.item.popup == 'string') {
				popupOptions.content = this.item.popup;
			} else {
				let popupHtml = '';
				if($.isArray(this.item.popup)) {
					for(let i = 0; i < this.item.popup.length; i++) {
						let button = this.item.popup[i];
						let buttonDiv = $('<div class="ui compact button">');
						if(button.color) {
							buttonDiv.addClass(button.color);
						}
						if(button.name) {
							buttonDiv.text(button.name);
							buttonDiv.attr('name', button.name);
						}

						popupHtml += buttonDiv[0].outerHTML;
					}
				}

				let me = this;
				$.extend(popupOptions, {
					html: popupHtml,
					hoverable: true,
					observeChanges: false,
					delay: {
						show: 0,
						hide: 100
					},
					onCreate: function() {
						for(let i = 0; i < me.item.popup.length; i++) {
							let button = me.item.popup[i];
							let buttonDiv = $(this).find('.button[name="' + button.name + '"]');
							buttonDiv.data('button', button);

							if(button.onClick) {
								buttonDiv.on('click', function() {
									window.$(me.$el).popup('hide');
									let button = $(this).data('button');
									button.onClick.call(me, me.item, {});
								});
							}
						}
					}
				});
			}

			window.$(this.$el).popup(popupOptions);
		}
	},
	destroyed() {
		if(this.item.popup) {
			window.$(this.$el).popup('destroy');
		}
	}
};
</script>

<style scoped>
.item.custom-item {
	white-space: normal;
}

.item.custom-item.sticky {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;

	background: white;
	z-index: 20;
}
</style>

<style>
.ui.menu .ui.dropdown .menu > .item.category-dropdown-item.sticky {
	background-color: white !important;
}
.ui.menu .ui.dropdown .menu > .item.category-dropdown-item.sticky:hover {
	background-color: rgb(240,240,240) !important;
}
</style>