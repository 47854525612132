$.FlowLayoutToolbar = function(me, options) {
	var _existingDestroy = me.destroy;

	$.extend(me, {
		changeInstanceProperty: function(name, value, propogate) {
			if($.isArray(name)) {
				for(var i = 0; i < name.length; i++) {
					this.changeInstanceProperty(name[i], value[i], propogate);
				}
			} else {
				if(value === null && this.dontAllowNullValues.indexOf(name) != -1) {
					$.fireErrorReport(null, 'Tried to null value', 'Tried to null out ' + name + ' which should never happen');
					return;
				}

				this.getInstance()[name] = value;

				// Make sure origInstance has a different obj/array instance
				if ($.isArray(value)) {
					value = $.merge([], value);
				} else if ($.isPlainObject(value)) {
					value = $.extend(true, {}, value);
				}

				if(propogate !== false) {
					this.changeSecondaryInstanceProperty(name, value);
				}

				if (this.debounceChanges > 0) {
					this.debouncedChanges[name] = value;
					$.setSingleTimeout.call(this, 'debounceTimeout', function () {
						var debouncedChangeNames = [], debouncedChangeValues = [];
						for (var name in me.debouncedChanges) {
							debouncedChangeNames.push(name);
							debouncedChangeValues.push(me.debouncedChanges[name]);
						}

						me.onChangeInstanceProperty(debouncedChangeNames, debouncedChangeValues);
						me.debouncedChanges = {};
					}, this.debounceChanges);
				} else {
					this.onChangeInstanceProperty(name, value);
				}
			}
		},
		changeSecondaryInstanceProperty: function(name, value) {
			this.secondaryFocusedElements.forEach(function(elem) {
				if(elem.multipleSelectProperties.indexOf(name) != -1) {
					elem.changeInstanceProperty(name, value, false);
					elem.refreshInstance();
				}
			});
		},
		runWithSavingDisabled: function(callback) {
			var instance = $.extend(true, {}, this.instance);
			var debouncedChanges = $.extend(true, {}, this.debouncedChanges);

			try {
				callback.call(this);
			} finally {
				this.instance = instance;
				this.debouncedChanges = debouncedChanges;

				if($.getObjectCount(this.debouncedChanges) == 0) {
					$.clearSingleTimeout.call(this, 'debounceTimeout');
				}
			}
		},
		flushDelayedSaveQueue: function(propogate) {
			$.flushSingleTimeout.call(this, 'debounceTimeout');
			// When we are moving a group, we want everything to have the same timestamp so it undoes together
			if(propogate !== false) {
				this.flushSecondaryElements();
			}
		},
		flushSecondaryElements: function() {
			this.getSecondaryFocusedElements().forEach(function(elem) {
				elem.flushDelayedSaveQueue(false);
			});
		},
		refreshFromOriginalInstance: function(origInstance) {
			this.setInstance(origInstance || this.origInstance);

			if(this.wrapper) {
				this.wrapper.onFlowChange(this, undefined, true);
			}

			this.updateEditTools();
		},
		getInstance: function() {
			return this.instance;
		},
		getCurrentSelection: function() {
			return this.instance;
		},
		getEditable: function() {
			return this.editable;
		},
		setEditable: function(editable) {
			this.editable = editable;

			if(this.editable) {
				if(!this.onClickHandler) {
					$(this).on('click', this.onClickHandler = function (e) {
						if(e.metaKey) {
							e.ctrlKey = true;
						}

						if(me.isSecondaryFocusEvent(e)) {
							me.toggleSecondaryFocused();
							return false;
						} else if (me.shouldIgnoreFocus(e)) {
							return true;
						} else if(this.secondaryFocused && this.shouldIgnoreClickOut(e)) {
							return false;
						} else {
							me.initEdit(true, e);
							if (e.originalEvent) {
								e.originalEvent.handledToolbarEvent = true;
							}
						}

						me.isTouch = false;
					}).on('touchend', function() {
						me.isTouch = true;
					}).on('dblclick', function () {
						return false;
					});
				}

				if(this.hoverTools && !this.onFirstHoverHandler) {
					$(this).one('mouseenter', this.onFirstHoverHandler = function() {
						me.initHoverTools(true);
						me.onFirstHoverHandler = null;
					});
				}
			} else {
				if(this.editToolbar) {
					$(this.getEditToolTarget()).popup('destroy');

					// Not actually being destroyed?
					if($(this.editToolbar).isAttached()) {
						$(this.editToolbar).parent().remove();
					}

					this.editToolbar = null;
				}

				if(this.editTools || this.editToolbar) {
					$(this).off('click touchend mousedown mousemove mouseup');
					this.onClickHandler = null;
				}

				if(this.hoverToolbar) {
					this.hoverToolbar.remove();
					this.hoverToolbar = null;
					$(this).off('mousedown click touchend mouseenter mouseleave');
				} else if(this.hoverTools) {
					$(this).off('mouseenter');
					this.onFirstHoverHandler = null;
				}
				this.editInitialized = false;
			}

			$(this).css('cursor', this.getFocusCursor());
		},
		setFocused: function(focused, userClicked) {
			this.focused = focused;
			if(focused) {
				if(this.editable) {
					if(this.editInitialized) {
						if(userClicked) {
							if(this.editToolbar) {
								this.updateToolbarDisplay();
							}

							this.focusHiddenInput();
						} else if(this.editToolbar) {
							$(this.getEditToolTarget()).popup('show');
						}
					} else {
						this.initEdit(true);
					}

					if(!this.bodyHideHandler && userClicked) {
						$('body').on('click', this.bodyHideHandler = function (e) {
							// Don't allow a click on myself to close
							// Clicked on anything directly attached to myself
							if($(e.target).isAttached(me) && (!e.target.rootNode || e.target.rootNode == me)) {
								return false;
							}
							// Clicking within toolbar button
							else if($(me.editToolbar).has(e.target).length || $(me.editToolbar).is(e.target)) {
								// Don't want spellcheck menu open after clicking menu bar
								if(me.contextMenuContext && $(me.contextMenuContext).hasClass('hasPopup') && $(me.contextMenuContext).popup('is visible')) {
									$(me.contextMenuContext).popup('hide');
								}

								return false;
							}
							// Clicking on toolbar's sub element (ie: window)
							else if($(me.editToolbarExtras).has(e.target).length || $(me.editToolbarExtras).is(e.target)) {
								return false;
							}
							// Is a sub element which gets deleted on selection
							else if(e.target.rootNode && e.target.rootNode == me) {
								return false;
							}
							// Clicking within context menu
							else if(me.openContextMenu && $(me.openContextMenu).has(e.target).length || $(me.openContextMenu).is(e.target)) {
								return false;
							}
							else if(me.ignoreNextClickOut) {
								me.ignoreNextClickOut = false;
								return false;
							} else if(me.shouldIgnoreClickOut()) {
								return false;
							} else {
								for(var i = 0; i < me.secondaryFocusedElements.length; i++) {
									var elem = me.secondaryFocusedElements[i];
									if($(e.target).isAttached(elem) && (!e.target.rootNode || e.target.rootNode == me) || elem.shouldIgnoreClickOut()) {
										return false;
									}
								}
							}

							if(me.editToolbar) {
								$(me.getEditToolTarget()).popup('hide');
							} else {
								me.setFocused(false, true);
							}
						});
					}
				}

				if(this.hoverTools) {
					this.disableHover = true;
					$(this).trigger('mouseleave');
				}
				$(this).addClass('flowContentFocused');

				// Copy other multi selection
				if(this.primaryFocusedElement) {
					var secondaryFocusedElements = $.merge([this.primaryFocusedElement], this.primaryFocusedElement.secondaryFocusedElements);
					secondaryFocusedElements.removeItem(this);

					var oldPrimary = this.primaryFocusedElement;
					$.merge([], oldPrimary.secondaryFocusedElements).forEach(function(elem) {
						oldPrimary.removeSecondaryFocused(elem);
					});

					secondaryFocusedElements.forEach(function(elem) {
						me.addSecondaryFocused(elem);
					});

					this.secondaryFocused = false;
					oldPrimary.secondaryFocused = true;
					$(oldPrimary).addClass('flowLayoutSecondaryFocused');
				}
				$(this).removeClass('flowLayoutSecondaryFocused');

				if(this.wrapper) {
					this.wrapper.updateUserSelection(this.getUserSelectionId(), this.getAllUserSelections());
				}
			} else {
				if(this.hoverTools) {
					this.disableHover = false;
				}

				if(this.editToolbar) {
					if (!userClicked) {
						$(this.getEditToolTarget()).popup('hide');
					}

					this.editToolbar.findSelf('.hasPopup').popup('hide');
				}
				if(this.bodyHideHandler) {
					$('body').off('click', this.bodyHideHandler);
					this.bodyHideHandler = null;
				}

				$(this).removeClass('flowContentFocused');

				if(this.wrapper) {
					this.wrapper.updateUserSelection(this.getUserSelectionId(), null);
				}
				$(this.hiddenInput).blur();
				if(this.stopPositionTooltip) {
					this.stopPositionTooltip();
				}
				if(this.stopResizeToolip) {
					this.stopResizeToolip();
				}

				secondaryFocusedElements = $.merge([], this.secondaryFocusedElements);
				secondaryFocusedElements.forEach(function(elem) {
					elem.setSecondaryFocused(false, {
						updateEditTools: false
					});
				});
				if(secondaryFocusedElements.length) {
					this.updateEditTools();
				}

				if(this.contextMenuContext && $(this.contextMenuContext).hasClass('hasPopup') && $(this.contextMenuContext).popup('is visible')) {
					$(this.contextMenuContext).popup('hide');
				}
			}

			this.onFocusChanged(focused);
			if(this.onSetFocused) {
				this.onSetFocused(focused);
			}

			if(this.withLinkedElem) {
				this.withLinkedElem(function(layout, otherElem) {
					otherElem.setLinkedInstanceFocused(focused);
				});
			}
		},
		isSecondaryFocusEvent: function(e) {
			if(this.multipleSelect && (e.shiftKey || e.ctrlKey)) {
				var selectedElement = this.getPrimarySelectedElement();
				if(selectedElement && selectedElement.multipleSelect) {
					// Shift clicking part of a group should be treated as a focus shift
					if(selectedElement.instance.groupedElements && selectedElement.instance.groupedElements.indexOf(this.instance.id) != -1) {
						return false;
					} else {
						return true;
					}
				} else {
					return false;
				}
			} else {
				return false;
			}
		},
		getPrimarySelectedElement: function() {
			var focusedElements = $(this.wrapper).find('.flowContent.flowContentFocused');

			// Need to select last so duplicating content selects correct primary element.  If we need to change this, need to find alt fix for frames.js -> multiple frames selected -> duplicate to current page
			return focusedElements[focusedElements.length - 1] || null;
		},
		toggleSecondaryFocused: function() {
			this.setSecondaryFocused(!this.secondaryFocused);
		},
		setSecondaryFocused: function(secondaryFocused, options) {
			options = $.extend(true, {
				updateEditTools: true
			}, options);
			this.secondaryFocused = secondaryFocused;

			var primaryElement;
			if(this.secondaryFocused) {
				primaryElement = this.getPrimarySelectedElement();
				if(!primaryElement) {
					this.setSecondaryFocused(false);
					return;
				}

				$(this).addClass('flowLayoutSecondaryFocused');

				primaryElement.addSecondaryFocused(this);
				if(primaryElement.hiddenInput) {
					primaryElement.focusHiddenInput();
				}
				if(options.updateEditTools) {
					primaryElement.updateEditTools();
				}
			} else {
				$(this).removeClass('flowLayoutSecondaryFocused');

				primaryElement = this.primaryFocusedElement;
				if(primaryElement) {
					primaryElement.removeSecondaryFocused(this);

					if(options.updateEditTools) {
						primaryElement.updateEditTools();
					}
				}
			}

			if(this.withLinkedElem) {
				this.withLinkedElem(function(layout, otherElem) {
					otherElem.setLinkedInstanceFocused(secondaryFocused);
				});
			}
			if(this.onSetSecondaryFocused) {
				this.onSetSecondaryFocused(secondaryFocused);
			}
		},
		addSecondaryFocused: function(elem) {
			elem.primaryFocusedElement = this;
			if(this.secondaryFocusedElements.indexOf(elem) == -1) {
				this.secondaryFocusedElements.push(elem);
			}
			elem.applyZIndex();

			if(this.wrapper) {
				this.wrapper.updateUserSelection(this.getUserSelectionId(), this.getAllUserSelections());
			}
			this.updateEditToolbar();
		},
		removeSecondaryFocused: function(elem) {
			elem.primaryFocusedElement = null;
			this.secondaryFocusedElements.removeItem(elem);
			elem.applyZIndex();

			if(this.wrapper) {
				this.wrapper.updateUserSelection(this.getUserSelectionId(), this.getAllUserSelections());
			}
			this.updateEditToolbar();
		},
		getSecondaryFocusedElements: function() {
			var secondaryElements = [];
			if(this.focused || this.secondaryFocused) {
				secondaryElements = $.merge([], this.secondaryFocusedElements);
				if(this.primaryFocusedElement) {
					if(secondaryElements.indexOf(this.primaryFocusedElement) == -1) {
						secondaryElements.push(this.primaryFocusedElement);
					}
	
					this.primaryFocusedElement.secondaryFocusedElements.forEach(function(secondaryElement) {
						if(secondaryElements.indexOf(secondaryElement) == -1 && secondaryElement != me) {
							secondaryElements.push(secondaryElement);
						}
					});
				}
				secondaryElements.removeItem(this);
			} else if(this.instance && this.instance.groupedElements && this.instance.groupedElements.length > 0) {
				secondaryElements = this.wrapper.getContentElementsByIds(this.instance.groupedElements);
				secondaryElements.removeItem(this);
			}

			return secondaryElements;
		},
		getAllFocusedElements: function() {
			var elements = $.merge([], this.secondaryFocusedElements);
			if(this.primaryFocusedElement) {
				elements.push(this.primaryFocusedElement);

				$.merge(elements, this.primaryFocusedElement.secondaryFocusedElements);
			} else {
				elements.push(this);
			}

			return elements;
		},
		setupGroupedFocus: function() {
			if(this.instance && this.instance.groupedElements) {
				this.instance.groupedElements.forEach(function(contentId) {
					var element = me.wrapper.getContentElementById(contentId);
					if(element) {
						$(element).addClass('flowLayoutSecondaryFocused');
						element.secondaryFocused = true;
						me.addSecondaryFocused(element);
					}
				});
			}
		},
		getUserSelectionId: function() {
			return this.instance.id;
		},
		getAllUserSelections: function() {
			var selections = [];
			selections.push(this.getUserSelection());
			this.secondaryFocusedElements.forEach(function(elem) {
				selections.push(elem.getUserSelection());
			});

			return selections;
		},
		getUserSelection: function() {
			return {
				id: this.getUserSelectionId()
			};
		},
		getOtherUsersFocused: function() {
			return this.otherUserSelections;
		},
		setOtherUsersFocused: function(otherUsers) {
			for(var userId in otherUsers) {
				var details = otherUsers[userId];
				this.setOtherUserFocused(details.user, details.currentSelection);
			}
		},
		setOtherUserFocused: function(user, currentSelection) {
			var selectionDetails = this.otherUserSelections[user.userId];
			if(currentSelection) {
				if(!user.currentSelectionDivs) {
					user.currentSelectionDivs = [];
				}
				user.currentSelectionDivs.push(this);
				$(this).css('border', '1px solid ' + this.getSemanticColor(user));
				if(!selectionDetails) {
					selectionDetails = this.otherUserSelections[user.userId] = {
						user: user,
						currentSelection: currentSelection
					};
				}

				if(!selectionDetails.nameLabel) {
					this.initNameLabelBar();
					selectionDetails.nameLabel = $('<div class="ui mini label otherUserLabel">').text(user.name).addClass(user.color).appendTo(this.otherNameLabelsBar);
				}
			} else if(selectionDetails) {
				if(selectionDetails.nameLabel) {
					selectionDetails.nameLabel.remove();
				}

				delete this.otherUserSelections[user.userId];
				user.currentSelectionDivs.removeItem(this);

				var otherUser;
				for(var userId in this.otherUserSelections) {
					otherUser = this.otherUserSelections[userId];
					break;
				}

				if(otherUser) {
					$(this).css('border', '1px solid ' + otherUser.color);
				} else {
					$(this).css('border', '');
				}
			}
		},
		getSemanticColor: function(user) {
			if(!user.semanticColor) {
				var tmpLabel = $('<div class="ui label">').appendTo('body');
				user.semanticColor = tmpLabel.addClass(user.color).css('background-color');
				tmpLabel.remove();
			}

			return user.semanticColor;
		},
		initNameLabelBar: function() {
			if(!this.otherNameLabelsBar) {
				this.otherNameLabelsBar = $('<div class="otherUserLabelsBar">').appendTo(this);
			}
		},
		getFocusCursor: function() {
			return 'default';
		},
		initEdit: function(startVisible, clickEvent) {
			if(this.editInitialized) {
				return;
			}

			this.setupGroupedFocus();
			if(!this.hiddenInput) {
				this.hiddenInput = $('<input type="text" class="hiddenInput">').on('keydown', function (e) {
					if(me.instance && me.instance.locked) {
						return false;
					}

					var errorExtras = me.getErrorReportExtras({
						event: e
					});

					// Old versions of Mac OS seems to register the ctrl key as metaKey
					if(e.metaKey) {
						e.ctrlKey = true;
					}

					try {
						me.isTyping = true;
						return me.onUserTypes(e);
					} catch(error) {
						me.fireErrorReport('Typing error', 'Error in onUserTypes', error, errorExtras);
						return false;
					} finally {
						me.isTyping = false;
					}
				}).on('keypress', function(e) {
					if(me.instance && me.instance.locked) {
						return false;
					}

					if(me.altKeyPressed) {
						return me.onUserTypes(e);
					}
				}).appendTo(this);

				if(this.onInitHiddenInput) {
					this.onInitHiddenInput();
				}
			}

			this.editInitialized = true;
			if(this.editTools) {
				this.initEditTools();
			}

			$(this).off('click').on('click', function(e) {
				if(me.shouldIgnoreFocus(e)) {
					return true;
				}

				if(!me.focused) {
					if(me.isSecondaryFocusEvent(e)) {
						me.toggleSecondaryFocused();
						return false;
					} else if(this.editToolbar) {
						this.setupGroupedFocus();
						this.updateEditTools();
						$(this.getEditToolTarget()).popup('show');
					} else if(this.secondaryFocused && this.shouldIgnoreClickOut(e)) {
						return false;
					} else {
						this.setFocused(true, true);
					}
				}

				if(this.onThisClicked) {
					this.onThisClicked(e);
				}

				if(this.focused) {
					this.focusHiddenInput();
				}

				if(e.originalEvent) {
					e.originalEvent.handledToolbarEvent = true;
				}
				me.isTouch = false;
			}).on('mouseup', function(e) {
				if(this.focused && this.hiddenInput) {
					this.focusHiddenInput();
				}
			}).on('contextmenu', function(e) {
				if(this.getContextMenu && (this.focused || this.secondaryFocused)) {
					var contextMenu = this.getContextMenu(e);
					if(contextMenu) {
						var contextMenuContext = contextMenu.popupContext;
						this.contextMenuContext = contextMenuContext;

						var menu = this.openContextMenu = $('<div class="ui secondary vertical menu"></div>');
						contextMenu.forEach(function(definition) {
							if(definition === 'divider') {
								menu.append('<div class="ui divider"></div>');
							} else {
								var item = $('<div class="link item"></div>');
								item.text(definition.text);
								item.on('click', function() {
									if(definition.onClick) {
										definition.onClick();
									}

									$(contextMenuContext).popup('hide');
								});

								menu.append(item);
							}
						});

						$(contextMenuContext).popup({
							html: menu,
							on: 'manual'
						}).popup('show').addClass('hasPopup');

						e.preventDefault();
						return false;
					}
				}
			});

			if(startVisible) {
				if(this.editToolbar) {
					$(this.getEditToolTarget()).popup('show');
				} else {
					this.setFocused(true, true);
				}
			}
		},
		focusHiddenInput: function() {
			if(!this.hiddenInput) {
				return;
			}

			if(!this.textEditable && this.isTouch) {
				this.hiddenInput.css('display', 'none');
			}
			this.hiddenInput.focus();
			if(!this.textEditable && this.isTouch) {
				this.hiddenInput.css('display', '');
			}
		},
		onUserTypes: function(e) {
			if(this.movable && (!this.requireCtrlForMovement || e.ctrlKey) && e.keyCode >= 37 && e.keyCode <= 40) {
				var amount = 10;
				if(e.shiftKey) {
					amount = 1;
				}

				var startX = $(this).getFloatStyle('left');
				var startY = $(this).getFloatStyle('top');
				var x = startX;
				var y = startY;

				var diff = {
					x: 0,
					y: 0
				};
				if(e.keyCode == 37) {
					x -= amount;
					diff.x -= amount / this.ratio;
				} else if(e.keyCode == 39) {
					x += amount;
					diff.x += amount / this.ratio;
				} else if(e.keyCode == 38) {
					y -= amount;
					diff.y -= amount / this.ratio;
				} else if(e.keyCode == 40) {
					y += amount;
					diff.y += amount / this.ratio;
				}

				if(this.parentNode) {
					var myStartRect = this.getBoundingClientRect();
					var myRect = {
						left: myStartRect.left + (diff.x * this.ratio),
						right: myStartRect.right + (diff.x * this.ratio),
						top: myStartRect.top + (diff.y * this.ratio),
						bottom: myStartRect.bottom + (diff.y * this.ratio)
					};
					var parentRect = this.getParentRect();

					if(this.containInParent && (
							(diff.y !== 0 && (myRect.bottom >= parentRect.bottom || myRect.top <= parentRect.top))
							||
							(diff.x !== 0 && (myRect.right >= parentRect.right || myRect.left <= parentRect.left))
						)) {

						var isBlocked = true;

						// We want to allow moving right to the edge before starting to block moving anymore
						var MIN_TO_MOVE = 2;
						if(myRect.bottom >= parentRect.bottom) {
							if((myStartRect.bottom + MIN_TO_MOVE) < parentRect.bottom) {
								let newDiff = parentRect.bottom - myStartRect.bottom;
								y = startY + newDiff;
								diff.y = newDiff;
								isBlocked = false;
							}
						} else if(myRect.top <= parentRect.top) {
							if((myStartRect.top - MIN_TO_MOVE) > parentRect.top) {
								let newDiff = parentRect.top - myStartRect.top;
								y = startY + newDiff;
								diff.y = newDiff;
								isBlocked = false;
							}
						}

						if(myRect.right >= parentRect.right) {
							if(this.containInRightParent === false && !this.dontAllowStopInMargins) {
								isBlocked = false;
							} else if((myStartRect.right + MIN_TO_MOVE) < parentRect.right) {
								let newDiff = parentRect.right - myStartRect.right;
								x = startX + newDiff;
								diff.x = newDiff;
								isBlocked = false;
							}
						} else if(myRect.left <= parentRect.left) {
							if(this.containInLeftParent === false && !this.dontAllowStopInMargins) {
								isBlocked = false;
							} else if((myStartRect.left - MIN_TO_MOVE) > parentRect.left) {
								let newDiff = parentRect.left - myStartRect.left;
								x = startX + newDiff;
								diff.x = newDiff;
								isBlocked = false;
							}
						}

						if(isBlocked) {
							if(window.alertify) {
								window.alertify.error('Can\'t move content off the edge of the page!');
							}

							return true;
						}
					} else if(myRect.right <= (parentRect.left + this.getMinContentVisible()) || myRect.left >= (parentRect.right - this.getMinContentVisible()) ||
						myRect.bottom <= (parentRect.top + this.getMinContentVisible()) || myRect.top >= (parentRect.bottom - this.getMinContentVisible())) {

						if(window.alertify) {
							window.alertify.error('Can\'t move content off the edge of the page!');
						}

						return true;
					}
				}

				this.moveFromKeyboard(x, y, diff);
				return true;
			} else if((e.keyCode == 90 || e.keyCode == 89) && e.ctrlKey) {
				// If there are any pending changes when we press undo, we need to add these to the event queue to be undone properly
				this.flushDelayedSaveQueue();
				return true;
			} else if((e.keyCode == 67 || e.keyCode == 88) && e.ctrlKey && !this.selectionLength) {
				// Ctrl + c/x => copy
				if($.userEvents && this.getInstanceCopy) {
					var page = me.wrapper.getPage();
					var event = {
						page: page.id,
						contents: [],
						duplicateElementMoveAmount: this.duplicateElementMoveAmount
					};
					if(e.keyCode === 88) {
						event.cut = true;
					}
					event.contents.push(this.getInstanceCopy());
					me.getSecondaryFocusedElements().forEach(function(elem) {
						event.contents.push(elem.getInstanceCopy());
					});

					$.userEvents.addToClipboard(event);
					
					e.preventDefault();
					return true;
				}
			} else if(e.keyCode == 65 && e.ctrlKey && this.multipleSelect && this.allowSelectAll) {
				// Ctrl + A => select all
				var movableContent = $(this.wrapper).find('.flowContent.flowLayoutMovable').not(this).not('.flowLayoutSecondaryFocused').filter(function() {
					return this.multipleSelect;
				});
				movableContent.each(function() {
					this.setSecondaryFocused(true);
				});
			}
			else if((e.keyCode === 46 || e.keyCode === 8) && this.allowDeleteKey) {
				// Delete key
				this.removeContent();
			}
		},
		moveFromKeyboard: function(x, y, diff) {
			if(this.wrapper) {
				this.wrapper.startGroupedEvents();
			}
			this.setPosition(x, y);
			this.addPositionDiffToSecondary(diff);
			if(diff && this.onUpdateCurrentPosition) {
				this.onUpdateCurrentPosition(diff);
			}

			if(this.linkedOverflowInstance) {
				this.flushDelayedSaveQueue();
				this.linkedOverflowInstance.elem.flushDelayedSaveQueue();
			}
			if(this.wrapper) {
				this.wrapper.stopGroupedEvents();
			}
		},
		saveCurrentPosition: function(options) {
			if(!options) {
				options = {};
			}

			var position = options.position || this.getPositionFromCurrent();
			var diff = this.getPositionDiffFromSaved(position);
			this.setPositionProperty(position.x, position.y);

			if(diff && options.propogate !== false) {
				this.getSecondaryFocusedElements().forEach(secondaryElement => {
					secondaryElement.saveCurrentPosition($.extend(true, {
						recursive: false
					}, options));
					secondaryElement.checkOverflowToLinkedLayouts();
				});
			}

			if(diff && this.onUpdateCurrentPosition) {
				this.onUpdateCurrentPosition(diff);
			}

			if(this.flushPositionChangeImmediately) {
				this.flushDelayedSaveQueue();
				if(this.linkedOverflowInstance) {
					this.linkedOverflowInstance.elem.flushDelayedSaveQueue();
				}
				this.flushPositionChangeImmediately = false;
			}

			this.applyGroupBorders();
		},
		getPositionDiffFromSaved: function(position) {
			if(!position) {
				position = this.getPositionFromCurrent();
			}
			var previousPosition = this.getPositionProperty();

			var diff = {
				x: position.x - previousPosition.x,
				y: position.y - previousPosition.y
			};

			// Make sure diff is a valid number and at least x OR y is not zero
			if(!isNaN(diff.x) && !isNaN(diff.y) && (diff.x || diff.y)) {
				return diff;
			} else {
				return null;
			}
		},
		addPositionDiffToSecondary: function(diff) {
			var secondaryElements = this.getSecondaryFocusedElements();

			secondaryElements.forEach(function(elem) {
				elem.addPositionDiff(diff);
			});
		},
		addPositionDiff: function(diff, options) {
			options = $.extend(true, {}, options);

			var elemPosition = this.getPositionProperty();
			elemPosition.x += diff.x;
			elemPosition.y += diff.y;

			if(!isNaN(elemPosition.x) && !isNaN(elemPosition.y)) {
				this.setPosition(elemPosition.x * this.ratio - 1 + (this.extraOffset || 0), elemPosition.y * this.ratio - 1 + (this.extraOffset || 0), options);
			}
			if(this.onUpdateCurrentPosition) {
				this.onUpdateCurrentPosition(diff);
			}
		},
		getPositionFromCurrent: function() {
			var x = $(this).getFloatStyle('left') + 1 - (this.extraOffset || 0);
			var y = $(this).getFloatStyle('top') + 1 - (this.extraOffset || 0);

			return {
				x: x / this.ratio,
				y: y / this.ratio
			};
		},
		getPositionFromCss: function(css) {
			var x = parseFloat(css.left) + 1 - (this.extraOffset || 0);
			var y = parseFloat(css.top) + 1 - (this.extraOffset || 0);

			return {
				x: x / this.ratio,
				y: y / this.ratio
			};
		},
		showPosition: function(x, y) {
			$(this).css({
				left: x,
				top: y
			});
		},
		setPosition: function(x, y, options) {
			options = $.extend(true, {}, options);

			this.showPosition(x, y);
			if(this.setPositionProperty) {
				this.setPositionProperty((x + 1 - (this.extraOffset || 0)) / this.ratio, (y + 1 - (this.extraOffset || 0)) / this.ratio);
			}

			this.updateEditToolbar();
			this.movingContent(null, null, {
				snapToLines: false,
				showTooltip: options.showTooltip !== false
			});
			if(options.completeMovement !== false) {
				this.stopMoving();
			}
			this.applyGroupBorders();
		},
		initEditTools: function() {
			let target = undefined;
			let jitter = 10;
			let distanceAway = this.getEditToolsDistance();
			let position = 'top center';
			let popupClasses = 'ui flowing popup flowEditToolbar';
			if(this.wrapper && $.getComposerMySetting) {
				if($.getComposerMySetting('toolbarLocation') === 'abovePage') {
					target = $(this.wrapper);
					jitter = 1000;
					distanceAway = 0;

					position = 'top left';
					if(this.wrapper.side === 'Right') {
						position = 'top right';
					}

					if(this.wrapper.zoom && this.wrapper.zoom > 100) {
						target = $(this.wrapper.parent.wrapper.parentNode);
						position = 'top center';
						distanceAway = 10;
					}
				} else if($.getComposerMySetting('toolbarLocation') === 'outsidePage') {
					target = $(this.wrapper);
					jitter = 10000;
					distanceAway = 0;

					position = 'left center';
					if(this.wrapper.side === 'Right') {
						position = 'right center';
					}
					popupClasses += ' verticalToolbar';
				}
			}

			var popup = this.createEditTools();
			this.editToolbar = popup;
			$(this.getEditToolTarget()).popup({
				html: popup,
				on: 'manual',
				preserve: true,
				position: position,
				className: {
					popup: popupClasses
				},
				distanceAway: distanceAway,
				lastResort: true,
				jitter: jitter,
				observeChanges: false,
				target: target,
				onShow: function() {
					if(me.ignoreNextClick) {
						me.ignoreNextClick = false;
						return false;
					} else if(!me.parentNode) {
						return false;
					}

					me.setFocused(true, true);
					// NOTE: Do not try to reposition here because it will result in text boxes in the top left going off the left edge of the page
					/*window.setTimeout(function() {
						try {
							$(me.getEditToolTarget()).popup('reposition');
						} catch(e) {}
					}, 1);*/

					if(popupClasses.includes('verticalToolbar')) {
						this.css('display', '');
						window.setTimeout(() => {
							this.css('display', '');
						}, 1);
					}
				},
				onHide: function() {
					me.setFocused(false, true);

					if(popupClasses.includes('verticalToolbar')) {
						this.css('display', '');
						window.setTimeout(() => {
							this.css('display', '');
						}, 1);
					}
				},
				onVisible: function() {
					if(me.wrapper) {
						me.wrapper.allowNextClickPropogation = true;
					}

					if(me.onEditToolbarVisible) {
						me.onEditToolbarVisible();
					}

					if(popupClasses.includes('verticalToolbar')) {
						this.css('display', '');
					}
				}
			});
		},
		createEditTools: function() {
			var popup = $();
			var editTools;
			if(typeof this.editTools == 'function') {
				editTools = this.editTools();
			} else {
				editTools = this.editTools;
			}

			for(var i = 0; i < editTools.length; i++) {
				var button = this.addEditTool(editTools[i]);
				if(button) {
					popup = popup.add(button);
					if(i < editTools.length - 1 && editTools[i] !== 'line-break' && editTools[i - 1] !== 'line-break' && editTools[i + 1] !== 'line-break') {
						popup = popup.add('<div class="groupDivider">');
					}
				}
			}

			return popup;
		},
		updateEditTools: function() {
			if(this.editToolbar) {
				var parentElement = $(this.editToolbar).first().parent();

				this.editToolbar.findSelf('.toolbarButton').each(function() {
					if(this.destroy) {
						this.destroy();
					}
				});
				this.editToolbar.remove();
				var popup = this.createEditTools();
				this.editToolbar = popup;

				parentElement.append(popup);
				this.lastToolbarPosition = null;
				this.updateToolbarDisplay();
			}
		},
		updateEditToolbar: function() {
			let toolbarLocation = $.getComposerMySetting ? $.getComposerMySetting('toolbarLocation') : 'floating';
			if(this.editToolbar && toolbarLocation !== 'abovePage' && toolbarLocation !== 'outsidePage') {
				let popupTarget = this.getEditToolTarget();
				$(popupTarget).popup('setting', 'distanceAway', this.getEditToolsDistance());
			}

			if(this.focused) {
				let popupTarget = this.getEditToolTarget();
				if($(popupTarget).popup('is visible')) {
					$(popupTarget).popup('reposition');
				}
			} else if(this.secondaryFocused && this.primaryFocusedElement) {
				let popupTarget = this.primaryFocusedElement.getEditToolTarget();
				if($(popupTarget).popup('is visible')) {
					$(popupTarget).popup('reposition');
				}
			}
		},
		forceUpdateToolbarPosition: function() {
			if(this.editToolbar) {
				let popupTarget = this.getEditToolTarget();
				$(popupTarget).popup('reposition');
			}
		},
		getEditToolTarget: function() {
			return this;
		},
		getEditToolsDistance: function() {
			return 0
		},
		addEditTool: function(definition) {
			var button = this.createButtonTool(definition);
			button.findSelf('.button').addClass('compact');

			return button;
		},
		initHoverTools: function(startVisible) {
			this.hoverToolbar = $();
			for(var i = 0; i < me.hoverTools.length; i++) {
				var hoverButton = me.addHoverTool(me.hoverTools[i]);
				if(hoverButton) {
					$(this).append(hoverButton);
					this.hoverToolbar = this.hoverToolbar.add(hoverButton);
				}
			}
			this.toolbarHoverHandler(me, this.hoverToolbar);

			if(startVisible) {
				$(this).trigger('mouseenter');
			}
		},
		addHoverTool: function(definition) {
			var button = me.createButtonTool($.extend({
				circular: true
			}, definition));
			var returnDiv = button;

			if(definition.position) {
				switch(definition.position) {
					case 'top left':
						button.css({
							'position': 'absolute',
							'left': '-2.5em',
							'top': '-2em'
						});
						break;
					case 'top center':case 'top middle':
						button.css({
							'position': 'absolute',
							'left': '-2.5em',
							'top': '-2em'
						});
						break;
					case 'top right':
						button.css({
							'position': 'absolute',
							'right': '-2.5em',
							'top': '-2em'
						});
						break;
					case 'bottom toolbar':
						button.removeClass('circular').addClass('compact');
						if(this.hoverBottomToolbar) {
							returnDiv = null;
						} else {
							this.hoverBottomToolbar = $('<div class="flowTextToolbar bottomToolbar">');
							returnDiv = this.hoverBottomToolbar;
						}

						this.hoverBottomToolbar.append(button);

						// Need to get width of button after toolbar added
						if(!returnDiv) {
							if(!this.hoverBottomToolbar.buttonWidth) {
								this.hoverBottomToolbar.removeClass('transition hidden');
								this.hoverBottomToolbar.buttonWidth = button[0].getBoundingClientRect().width + parseFloat(button.css('marginRight').replace('px', ''));
								this.hoverBottomToolbar.addClass('transition hidden');
							}

							if (this.hoverBottomToolbar.buttonWidth) {
								this.hoverBottomToolbar.css({
									'min-width': this.hoverBottomToolbar.buttonWidth * this.hoverBottomToolbar.find('.button').length
								});
							}
						}

						break;
				}
			}

			if(returnDiv) {
				returnDiv.addClass('transition hidden');

				return returnDiv;
			}
		},
		toolbarHoverHandler: function(elem, validButtons) {
			var timeout, buttonsHiding = false;
			// Need to reset timeout since outside of element

			validButtons.hover(function() {
				clearTimeout(timeout);
			}, function() {
				clearTimeout(timeout);
				timeout = setTimeout(function() {
					validButtons.transition({
						animation: 'drop out',
						onComplete: function() {
							buttonsHiding = false;
						}
					});
					buttonsHiding = true;
				}, 400);
			}).click(function() {
				elem.ignoreNextClick = true;
			}).on('mousedown', function() {
				elem.ignoreNextDrag = true;
			}).on('dblclick', function() {
				return false;
			});

			$(elem).hover(function() {
				if(elem.movementLocked || elem.disableHover) {
					return false;
				}

				clearTimeout(timeout);
				var isVisible = validButtons.transition('is visible');
				var isAnimating = validButtons.transition('is animating');
				if(isVisible === false || (isVisible && isVisible.indexOf && isVisible.indexOf(true) == -1)) {
					validButtons.transition('reset');
					validButtons.transition('drop in');
				} else if((isAnimating === true || (isAnimating && isAnimating.indexOf && isAnimating.indexOf(true) != -1)) && buttonsHiding) {
					validButtons.transition('reset');
				}
			}, function() {
				if(elem.movementLocked) {
					return false;
				} else if(elem.disableHover) {
					var isVisible = validButtons.transition('is visible');
					if(isVisible === true || (isVisible && isVisible.indexOf && isVisible.indexOf(true) != -1)) {
						validButtons.transition({
							animation: 'drop out',
							onComplete: function () {
								buttonsHiding = false;
							}
						});
						buttonsHiding = true;
					}

					return false;
				}

				clearTimeout(timeout);
				timeout = setTimeout(function() {
					validButtons.transition({
						animation: 'drop out',
						onComplete: function() {
							buttonsHiding = false;
						}
					});
					buttonsHiding = true;
				}, 400);
			});
		},
		createButtonGroup: function(group) {
			var div = $('<div class="ui buttons">');
			if(group.addClass) {
				div.addClass(group.addClass);
				group.groupDiv = div[0];
				group.groupDiv.updateDisplay = function() {
					for(var i = 0; i < group.group.length; i++) {
						var button = group.group[i];
						if(button && button.buttonDiv) {
							button.buttonDiv.updateDisplay.apply(button.buttonDiv, arguments);
						}
					}
				}
			}

			for(var i = 0; i < group.group.length; i++) {
				group.group[i] = this.getToolByName(group.group[i]);
				var buttonDefinition = group.group[i];
				var button = this.createButtonTool(buttonDefinition);
				if(button) {
					div.append(button);
					buttonDefinition.group = group;
				}
			}

			return div;
		},
		createButtonTool: function(definition) {
			if(definition === 'line-break') {
				return $('<br class="lineBreak">');
			}

			// Auto convert names to common tools
			definition = this.getToolByName(definition);

			if(definition.group) {
				return this.createButtonGroup($.extend(true, {}, definition));
			}

			var div = $('<div class="ui button toolbarButton">');
			if(definition.dropdown) {
				if(definition.iconButton) {
					div.addClass('icon dropdown iconButtonDropdown');
					div.append('<input type="hidden">');
					$('<i class="icon">').addClass(definition.iconButton).appendTo(div);
				} else {
					div.addClass('selection dropdown toolbarSelectionDropdown');
					div.append('<input type="hidden">');
					div.append('<i class="dropdown icon">');
					var label = $('<div class="default text">').text(definition.title).appendTo(div);
				}

				var menu = $('<div class="menu">');
				for(var i = 0; i < definition.dropdown.length; i++) {
					var option = definition.dropdown[i];

					var item = $('<div class="item">');
					if(typeof option == 'string') {
						item.text(option);
					} else {
						item.text(option.text);
						if(option.css) {
							item.css(option.css);
						}
					}

					menu.append(item);
				}
				div.append(menu);

				div.dropdown({
					allowReselection: true,
					onChange: function(value, text) {
						if(div.data('ignoreNextSelect')) {
							div.data('ignoreNextSelect', false);
						} else if(definition.onChange) {
							var selection = me.getCurrentSelection({
								criteria: definition.selection,
								singleSelection: !!definition.singleSelection,
								createPart: true
							});
							
							var ret = definition.onChange(selection, value);
							if(ret !== false) {
								me.refreshInstance();
								this.updateDisplay($.isArray(selection) ? selection[0] : selection);
							}
						}
					},
					onHide: function() {
						if (me.focused && me.hiddenInput) {
							window.setTimeout(function () {
								if (me.focused) {
									me.focusHiddenInput();
								}
							}, 1);
						}
					}
				});
				div.setSelectedValue = function(selectedValue) {
					div.data('ignoreNextSelect', true);
					div.dropdown('set selected', selectedValue);
					div.data('ignoreNextSelect', false);
				};
			} else if(definition.colorPicker) {
				div[0].colorPicker = $('<input type="text" style="display: none">');
				div[0].spectrumInitialized = false;
				div.popup({
					html: div[0].colorPicker,
					on: 'click',
					preserve: true,
					className: {
						popup: 'ui flowing popup flowEditColorPicker'
					},
					position: 'top center',
					onShow: function() {
						me.editToolbar.findSelf('.hasPopup').not(div).popup('hide');
						if(!div[0].spectrumInitialized) {
							div[0].colorPicker.spectrum({
								color: definition.allowEmpty ? 'transparent' : '000000',
								preferredFormat: 'rgb',
								flat: true,
								showInput: true,
								showButtons: false,
								showPalette: true,
								allowEmpty: definition.allowEmpty ? true : false,
								palette: $.DefaultColorPalette,
								move: function(color) {
									div[0].triggerOnColorChange(color);
								},
								change: function (color) {
									div[0].triggerOnColorChange(color);
								}
							});
							div[0].dropdown = div[0].colorPicker.parent();
							div[0].spectrumInitialized = true;
							me.editToolbarExtras = me.editToolbarExtras.add(this);

							if(definition.settingsPicker) {
								div[0].settingsPicker = $('<div class="ui form" style="text-align: center">').appendTo(this);
								div[0].settingsPicker.SettingsBuilder($.isArray(definition.settingsPicker) ? definition.settingsPicker : [definition.settingsPicker], {
									autoFormatColumns: true,
									onSettingChange: function(setting, value) {
										var selection = me.getCurrentSelection({
											criteria: definition.selection,
											singleSelection: !!definition.singleSelection,
											createPart: definition.selection !== 'line'
										});
										var ret = definition.onChangeSetting(selection, setting.name, value);
										if (ret !== false) {
											if(div[0].updateDisplay) {
												div[0].updateDisplay(selection);
											}
										}

										if (me.focused && !setting.ignoreFocusHiddenInput) {
											me.focusHiddenInput();
										}
									}
								});
							}
						}

						var selection = me.getCurrentSelection({
							criteria: definition.selection
						});
						div[0].colorPicker.spectrum('set', definition.getCurrentColor(div[0].getSingleSelection(selection)));
						div[0].updateDisplay(selection);

						if(definition.updateSettingsPicker && div[0].settingsPicker && div[0].settingsPicker.css('display') != 'none') {
							definition.updateSettingsPicker.call(me, div[0].settingsPicker, selection, div[0]);
						}
					},
					onHidden: function() {
						if (me.focused && me.hiddenInput) {
							window.setTimeout(function () {
								if (me.focused) {
									me.focusHiddenInput();
								}
							}, 1);
						}
					}
				});
				div.addClass('hasPopup');
			} else if(definition.settingsPicker) {
				var settingsPicker = $('<div class="ui form">');
				this.editToolbarExtras = this.editToolbarExtras.add(settingsPicker);

				div[0].settingsPickerInitialized = false;
				div.popup({
					html: settingsPicker,
					on: 'click',
					preserve: true,
					className: {
						popup: 'ui flowing popup flowSettingsPicker'
					},
					position: 'top center',
					onShow: function() {
						me.editToolbar.findSelf('.hasPopup').not(div).popup('hide');
						if(div[0].settingsPickerInitialized) {
							return;
						}

						settingsPicker.SettingsBuilder($.isArray(definition.settingsPicker) ? definition.settingsPicker : [definition.settingsPicker], $.extend(true, {
							autoFormatColumns: true,
							onSettingChange: function(setting, value) {
								if(definition.onChange) {
									var selection = me.getCurrentSelection({
										criteria: definition.selection,
										singleSelection: !!definition.singleSelection,
										createPart: true
									});
									var ret = definition.onChange.call(me, selection, value, this.currentSettings);
									if (ret !== false) {
										if(div[0].updateDisplay) {
											div[0].updateDisplay(selection);
										}
									}
								}
							}
						}, definition.settingsPickerOptions));

						div[0].settingsPickerInitialized = true;
					},
					onHidden: function() {
						if (me.focused && me.hiddenInput) {
							window.setTimeout(function () {
								if (me.focused) {
									me.focusHiddenInput();
								}
							}, 1);
						}
					}
				});
				div[0].settingsPicker = settingsPicker;
				div.addClass('hasPopup');
			}

			if(definition.icon) {
				div.addClass('icon');
				$('<i class="icon">').addClass(definition.icon).appendTo(div);
			}
			if(definition.circular) {
				$(div).addClass('circular');
			}
			if(definition.title && !definition.dropdown) {
				div.append(definition.title);
			}

			if(definition.color) {
				div.addClass(definition.color);
			}
			if(definition.onClick) {
				div.on('touchend', function() {
					me.isTouch = true;
				}).on('click', function (e) {
					var selection = me.getCurrentSelection({
						criteria: definition.selection,
						singleSelection: !!definition.singleSelection,
						createPart: true
					});
					var ret = definition.onClick.call(me, selection, div[0], e);
					if(ret !== false) {
						me.refreshInstance();

						var updateSelection = $.isArray(selection) ? selection[0] : selection;
						if(definition.group && definition.group.updateDisplayAll && definition.group.groupDiv) {
							definition.group.groupDiv.updateDisplay(updateSelection);
						} else {
							this.updateDisplay(updateSelection);
						}
					}

					if(me.focused && me.hiddenInput) {
						me.focusHiddenInput();
					}

					if(definition.closeOnClick) {
						me.setFocused(false, false);
					}
					me.isTouch = false;
				});
			}
			if(definition.onMouseDown) {
				div.on('mousedown', function(e) {
					definition.onMouseDown.call(me, e);
				});
			}
			if(definition.popup) {
				div.attr('data-tooltip', definition.popup);
			}

			if(definition.dropdown) {
				$.extend(div[0], {
					dropdown: div,
					updateDisplay: function (selection) {
						if(definition.updateLabel) {
							definition.updateLabel.call(me, selection, div, label);
						}
					},
					destroy: function() {
						// This stops us from getting "Element is no longer attached to DOM. Unable to animate.  Use silent setting to surpress this warning in production. slide down out" warnings after removed mid select
						$(this).dropdown('unbind intent');
					}
				});
			} else if(definition.colorPicker) {
				$.extend(div[0], {
					dropdown: div[0].colorPicker,
					triggerOnColorChange: function(color) {
						if (definition.onChange && me.focused) {
							var rgba;
							if(color) {
								rgba = color.toRgb();
								color = color.toRgbString();
							} else {
								color = 'transparent';
							}

							if(color == 'transparent' || color == 'rgba(0, 0, 0, 0)') {
								color = null;
							} else if(definition.allowTransparency === false && rgba) {
								color = 'rgb(' + rgba.r + ', ' + rgba.g + ', ' + rgba.b + ')';
							}

							var selection = me.getCurrentSelection({
								criteria: definition.selection,
								singleSelection: !!definition.singleSelection,
								createPart: definition.selection !== 'line'
							});
							var ret = definition.onChange(selection, color);
							if (ret !== false) {
								me.refreshInstance();

								if(this.updateDisplay) {
									this.updateDisplay($.isArray(selection) ? selection[0] : selection);
								}
							}

							if(me.focused) {
								me.focusHiddenInput();
							}
						}
					},
					updateDisplay: function (selection) {
						var color = definition.getCurrentColor(this.getSingleSelection(selection));
						if(definition.updateButtonColor) {
							definition.updateButtonColor.call(me, div, color);
						}

						if(this.settingsPicker) {
							if(color && color != 'transparent') {
								if(this.settingsPicker.css('display') == 'none') {
									this.settingsPicker.show();
								}
								this.colorPicker.spectrum('set', color);
								
								if(definition.updateSettingsPicker) {
									definition.updateSettingsPicker.call(me, div[0].settingsPicker, selection, div[0]);
								}
							} else {
								this.colorPicker.spectrum('set', 'transparent');
								this.settingsPicker.hide();
							}
						}
					},
					getSingleSelection: function(selection) {
						if($.isArray(selection)) {
							return selection[0];
						} else {
							return selection;
						}
					},
					destroy: function() {
						if(this.spectrumInitialized) {
							this.colorPicker.spectrum('destroy');
						}
						$(this).popup('destroy');

						// Not actually being destroyed?
						var popup = $(this.colorPicker).parent();
						if(popup.length && popup.isAttached()) {
							popup.remove();
						}
					}
				});
			} else {
				$.extend(div[0], {
					updateDisplay: function (selection) {
						if(definition.updateDisplay) {
							definition.updateDisplay.call(this, selection);
						} else {
							var active = false;
							if (definition.isActive) {
								active = definition.isActive(selection);
							}

							this.setActive(active);
						}
					},
					setActive: function (active) {
						if (active) {
							$(this).addClass('active');
						} else {
							$(this).removeClass('active');
						}
					},
					destroy: function() {
						if($(this).hasClass('hasPopup')) {
							$(this).popup('destroy');

							// Not actually being destroyed?
							if(this.settingsPicker) {
								var popup = $(this.settingsPicker).parent();
								if (popup.length && popup.isAttached()) {
									popup.remove();
								}
							}
						}
					}
				});
			}
			definition.buttonDiv = div[0];
			div[0].definition = definition;

			return div;
		},
		updateToolbarDisplay: function() {
			if(!this.editToolbar) {
				return;
			}

			var selection = this.instance;
			this.editToolbar.findSelf('.toolbarButton').each(function() {
				this.updateDisplay(selection);
			});

			var me = this;
			window.setTimeout(function() {
				me.updateToolbarTooltipPosition();
			}, 10);
		},
		updateToolbarTooltipPosition: function() {
			if(!this.editToolbar) {
				return;
			}

			var toolbarPosition = null;

			var outerToolbar = this.editToolbar.eq(0).parent()[0];
			if(!outerToolbar) {
				return;
			}
			var toolbarRect = outerToolbar.getBoundingClientRect();
			if(toolbarRect.top < 50) {
				toolbarPosition = 'bottom center';
			}

			var toolbarButtons = this.editToolbar.findSelf('.toolbarButton');
			if(this.lastToolbarPosition != toolbarPosition) {
				toolbarButtons.each(function() {
					if($(this).attr('data-tooltip')) {
						if(toolbarPosition) {
							$(this).attr('data-position', toolbarPosition);
						} else {
							$(this).removeAttr('data-position');
						}
					}
				});

				this.lastToolbarPosition = toolbarPosition;
			}

			// SVG toolbar needs to be shrunk on iPad sized screens but candid one does not yet.  Dropdowns take up several times the space of a regular button
			var toolbarWidth = toolbarButtons.length;
			toolbarWidth += (toolbarButtons.filter('.toolbarSelectionDropdown').length * 2);
			if(toolbarWidth > 25) {
				var addClass = 'largeToolbar';
				var toolbarButtonRect = toolbarButtons[0].getBoundingClientRect();
				if(toolbarRect.height > (toolbarButtonRect.height + 20)) {
					addClass = 'extraLargeToolbar';
				}

				$(this.editToolbar).eq(0).parent().removeClass('extraLargeToolbar largeToolbar').addClass(addClass);

				if(addClass === 'extraLargeToolbar' && !window.jasmine) {
					let popupTarget = this.getEditToolTarget();
					window.setTimeout(function() {
						if($(popupTarget).isAttached()) {
							$(popupTarget).popup('reposition');
						}
					}, 10);
				}
			} else {
				$(this.editToolbar).eq(0).parent().removeClass('extraLargeToolbar largeToolbar');
			}
		},
		getToolByName: function(name) {
			return name;
		},
		shouldIgnoreFocus: function(e) {
			var currentTime = new Date().getTime();
			if(this.lastDragTime && (currentTime - this.lastDragTime) < 50) {
				return true;
			}
			// Selected sub element of toolbar'd element not pointing to this
			else if(e.target.rootNode && e.target.rootNode != this) {
				return true;
			}
			// Selected direct element with it's own toolbar which is not this
			else if(e.target.editToolbar && e.target != this) {
				return true;
			}
			// Another toolbar already handled this event
			else if(e.originalEvent && e.originalEvent.handledToolbarEvent) {
				return true;
			}
			// Node no longer exists, nothing to focus on
			else if(!this.parentNode) {
				return true;
			}
			// This can happen when resizing cells in virtual groups where we are bringing the hovered cell to the front
			else if($(e.target).hasClass('resizableHandle')) {
				return true;
			}
			else {
				return false;
			}
		},
		shouldIgnoreClickOut: function(e) {
			var currentTime = new Date().getTime();
			if(this.lastDragTime && (currentTime - this.lastDragTime) < 100) {
				return true;
			} else if(this.duplicatedAtTime && (currentTime - this.duplicatedAtTime) < 200) {
				return true;
			} else if(this.lastResizeTime && (currentTime - this.lastResizeTime) < 100 || (this.imgWrapper && this.imgWrapper.lastResizeTime && (currentTime - this.imgWrapper.lastResizeTime) < 100)) {
				return true;
			} else if(this.lastRotateTime && (currentTime - this.lastRotateTime) < 100) {
				return true;
			} else if(this.createdAt && (currentTime - this.createdAt) < 100) {
				return true;
			} else if(this.focusedAt && (currentTime - this.focusedAt) < 100) {
				return true;
			} else {
				return false;
			}
		},
		getParentRect: function() {
			var checkAgainstNode = this.parentNode;
			if(this.allowCompletelyInBleed) {
				checkAgainstNode = this.wrapper;
			}

			return checkAgainstNode.getBoundingClientRect();
		},
		getMinContentVisible: function() {
			var minDimension = Math.min($(this).getFloatStyle('width'), $(this).getFloatStyle('height'));
			if(minDimension) {
				return Math.min(this.MIN_CONTENT_VISIBLE, minDimension + 0.5);
			} else {
				return this.MIN_CONTENT_VISIBLE;
			}
		},

		getErrorReportExtras: function(extras) {
			return $.extend({
				instance: JSON.stringify(this.instance)
			}, extras);
		},
		destroy: function() {
			if(_existingDestroy) {
				_existingDestroy.apply(this, arguments);
			}

			this.flushDelayedSaveQueue();

			if(this.editToolbar) {
				$(this.svgEditor).popup('destroy');

				// Not actually being destroyed?
				if($(this.editToolbar).isAttached()) {
					$(this.editToolbar).parent().remove();
				}

				this.editToolbar.findSelf('.toolbarButton').each(function() {
					if(this.destroy) {
						this.destroy();
					}
				});

				this.editToolbar = null;
			}

			// Removes global body handlers
			if(this.focused) {
				this.setFocused(false);
			}
			if(this.stopPositionTooltip) {
				this.stopPositionTooltip();
			}
			if(this.stopResizeToolip) {
				this.stopResizeToolip();
			}
			if(this.contextMenuContext && $(this.contextMenuContext).hasClass('hasPopup') && $(this.contextMenuContext).popup('is visible')) {
				$(this.contextMenuContext).popup('hide');
			}
			if(this.removeAlignmentLines) {
				this.removeAlignmentLines();
			}
			if(this.lockedTooltip) {
				$(this.lockedTooltip).popup('destroy').remove();
				this.lockedTooltip = null;
			}
			if(this.otherTooltip) {
				$(this.otherTooltip).popup('destroy').remove();
				this.otherTooltip = null;
			}
			if(this.removeGroupBorder) {
				this.removeGroupBorder();
			}
			this.destroyed = true;
		},
		refreshInstance: function() {},
		alignmentLines: $(),
		debounceChanges: 200,
		debouncedChanges: {},
		editToolbarExtras: $(),
		otherUserSelections: {},
		destroyed: false,
		focused: false,
		dontAllowNullValues: [],

		multipleSelect: false,
		multipleSelectProperties: [],
		secondaryFocusedElements: [],
		secondaryFocused: false,
		primaryFocusedElement: null,
		MIN_CONTENT_VISIBLE: 10,
		allowSelectAll: true,
		allowDeleteKey: false
	}, options);

	if(me.requireCtrlForMovement !== false) {
		me.requireCtrlForMovement = true;
	}

	$.registerEvents(me, [
		'focusChanged'
	]);
};