$.BookInsideCoverPage = function() {
	var obj = new $.BookCandidPage();
	var _setLocked = obj.setLocked;
	var updatePageLabel = obj.updatePageLabel;

	$.extend(true, obj, {
		getLayout: function() {
			var layout = {};

			// This is just for supporting dragging candid layouts onto inside cover page
			if(this.layout) {
				$.extend(layout, {
					texts: this.layout.texts,
					candids: this.layout.candids,
					images: this.layout.images,
					frames: this.layout.frames
				});
			}

			return layout;
		},
		setLayout: function(layout) {
			this.layout = layout;
		},
		setLocked: function (locked) {
			_setLocked.call(this, locked);
			this.updatePageLabel();
		},
		getLocked: function() {
			return !$.getProjectSetting('allowContentInInsideCovers', false) || (this.pageSet && this.pageSet.coverLocked) || false;
		},
		updatePageLabel: function () {
			if(this.getLocked()) {
				if($.getProjectSetting('allowContentInInsideCovers', false)) {
					this.pageLabel = {
						display: 'Inside Cover <span style="color: red">Locked</span>'
					};
				} else {
					this.pageLabel = {
						display: 'Inside Cover',
						popup: 'Intentionally left blank',
						forcePopup: true
					};
				}
			} else {
				updatePageLabel.apply(this, arguments);
			}
		},
		getThemePartName: function() {
			return null;
		},

		type: 'insideCover'
	});

	obj.updatePageLabel();
	return obj;
};