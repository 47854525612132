let acceptedFileExtensions = [
	
];
let photoConverters = {};

let canvas = document.createElement('canvas');
if(canvas && canvas.toBlob) {
	let heicModule;
	acceptedFileExtensions.push('heic');
		photoConverters.heic = {
			converter: (blob) => {
				return new Promise((resolve, reject) => {
					if(!heicModule) {
						heicModule = import(/* webpackChunkName: "heic" */ '../../../node_modules/heic2any/dist/heic2any.min.js');
					}

					heicModule.then(module => {
						const heic2any = module.default;
						heic2any({
							blob,
							toType: 'image/jpeg'
						}).then(resolve).catch(reject);
					}).catch(reject);
				});
			},
			signatures: [
				{
					3: 0x20,
					4: 0x66,
					5: 0x74,
					6: 0x79,
					7: 0x70,
					8: 0x68,
					9: 0x65,
					10: 0x69,
					11: 0x63
				},
				{
					3: 0x18,
					4: 0x66,
					5: 0x74,
					6: 0x79,
					7: 0x70,
					8: 0x68,
					9: 0x65,
					10: 0x69,
					11: 0x63
				}
			]
	};
}

export {
	acceptedFileExtensions,
	photoConverters
};