$.LayoutsDB = function(layouts, settings) {
	var obj = new $.DBQueue($.extend({
		loadUrl: 'ajax/getLayout.php',
		saveUrl: 'ajax/saveLayout.php',
		postData: {
			layoutId: layouts.layoutId,
			composer: true,
			fullCategory: true
		},
		pageSet: layouts,
		userEvents: $.userEvents,
		allowPartialUpdates: false,
		getSocket: function() {
			var url = 'wss?composer=' + layouts.layoutId + '&token=' + $.PlicToken + '&type=layout';
			if($.LoggedInAsUser) {
				url += '&originalToken=' + $.OriginalUserToken;
			}

			return url;
		},

		addPageFromDefinition: function(layout) {
			var page = new $.LayoutPage({
				layout: layout.definition,
				candids: layout.candids,
				texts: layout.texts
			});

			this.pageSet.pages.push(page);
			page.pageNumber = 1;
			page.pageSet = this.pageSet;
			page.db = this;
		}
	}, settings));

	var _load = obj.load;
	$.extend(obj, {
		load: function(events) {
			_load.call(this, $.extend({
				onReady: function(data) {
					$.ProjectName = data.layout.layoutName;
					$.layoutCategory = data.category;

					obj.addPageFromDefinition(data.layout);

					if (events.onReady) {
						events.onReady(data);
					}
				}
			}), events);
		}
	});

	$.UserActivityModule(obj, {
		showLastSeen: false,
		activitySubjectLabel: 'Layout'
	});
	return obj;
};