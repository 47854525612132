$.CommentFeed = function(comments, options) {
	var div = $('<div class="ui comments commentsFeed">')[0];

	$.extend(div, {
		setFocused: function(focused) {
			if(!this.form) {
				return;
			}

			this.focused = focused;

			if(focused) {
				this.form.find('textarea').focus();
			}
		},
		setComments: function (comments) {
			if(this.comments == comments) {
				return;
			}
			if($.isPlainObject(comments)) {
				comments = Object.values(comments);
				comments.sortByKey('time');
			}

			if(!this.options.editOriginal && comments) {
				comments = $.merge([], comments);
			}

			if(this.comments) {
				$(this.commentsList).children('.comment').remove();
			}
			this.comments = comments;

			for(var i = 0; i < comments.length; i++) {
				var comment = comments[i];
				if(comment.deleted) {
					continue;
				}
				
				try {
					this.addCommentDiv(new $.CommentMessage(comment, options));
				} catch(e) {
					if($.globalBugsnagInfo) {
						$.globalBugsnagInfo.comment = comment.original || comment;
					}
					throw e;
				}
			}
		},
		addCommentDiv: function(commentDiv) {
			$(this.commentsList).prepend(commentDiv);
		},
		submitNewComment: function() {
			if(!this.form.form('validate form')) {
				return false;
			}

			var field = this.form.find('textarea');
			var text = field.val();

			this.saveNewComment(text);
			window.setTimeout(function() {
				field.val('');
			}, 10);
		},
		saveNewComment: function(text) {
			var comment = {
				comment: text,
				author: $.CurrentUser,
				time: (new Date()).getTime()
			};
			div.comments.push(comment);

			var commentDiv = new $.CommentMessage(comment, options);
			this.addCommentDiv(commentDiv);
			$(this).parent().animate({
				scrollTop: 0
			});

			if (options.onCommentSubmit) {
				options.onCommentSubmit.call(div, comment, commentDiv);
			}
		},
		refreshComments: function() {
			if(this.comments) {
				$(this.commentsList).children('.comment').remove();

				for(var i = 0; i < this.comments.length; i++) {
					var comment = this.comments[i];
					if(comment.deleted) {
						continue;
					}
					
					this.addCommentDiv(new $.CommentMessage(comment, options));
				}
			}
		},
		clearComments: function () {
			if (this.comments) {
				$(this.commentsList).children('.comment').remove();
			}
			this.comments = null;
		},

		setEditable: function(editable) {
			if(editable) {
				if(!this.form) {
					var form = $('<form class="ui form">');
					form.append('<div class="field"><textarea name="commentBox"></textarea></div>');
					form.append('<div class="ui blue labeled submit icon button"><i class="icon edit"></i> ' + this.options.addCommentButton + '</div>');

					// Don't do html submit....
					form.submit(function () {
						return false;
					});
					form.form({
						fields: {
							comment: {
								identifier: 'commentBox',
								rules: [
									{type: 'empty'}
								]
							}
						}}
					);
					form.find('textarea').keypress(function(e) {
						if((e.keyCode == 13 && e.shiftKey) || (e.keyCode == 10 && e.ctrlKey)) {
							div.submitNewComment();
						}
					});
					form.find('.submit.button').click(function () {
						div.submitNewComment();
					});
					div.form = form;
					$(div).append(form);
				}
			} else {
				if(this.form) {
					this.form.remove();
					this.form = null;
				}
			}
		},
		setCanUseActions: function(canUseActions) {
			if(this.options.canUseActions === canUseActions) {
				return;
			}

			this.options.canUseActions = canUseActions;
			this.refreshComments();
		}
	});

	options = $.extend({}, {
		editable: true,
		editOriginal: true,
		canUseActions: true,
		focused: false,
		includeHeader: true,
		addCommentButton: 'Add Comment'
	}, options);
	div.options = options;

	if(!options.editOriginal && comments) {
		comments = $.merge([], comments);
	}

	if(options.includeHeader) {
		$(div).append('<h3 class="ui dividing header"><i class="outline comments icon"></i>Comments</h3>');
	}

	div.commentsList = $('<div class="commentsList"></div>').appendTo(div);

	if(comments) {
		div.setComments(comments);
	}
	div.setEditable(options.editable);

	return div;
};

$.CommentMessage = function(comment, options) {
	var div = $('<div class="comment">');
	var content = $('<div class="content">');
	var author = $('<div class="author">');
	if(comment.author.place) {
		author.append(comment.author.name + ' on <i>' + comment.author.place + '</i>');
	} else {
		author.text(comment.author.name);
	}
	var metadata = $('<div class="metadata"><span class="date">' + moment(comment.time).calendar() + '</span></div>');
	if(comment.author.userName) {
		metadata.append('by ');
		$('<a>').text(comment.author.userName).attr('href', 'mailto:' + comment.author.userName).appendTo(metadata);
	}
	var text = $('<div class="text">');

	var commentText = comment.comment || '';
	if($.SpecialWordsList) {
		if(!$.CommentMessage.badWordRegex) {
			$.CommentMessage.badWordRegex = new RegExp('(\\W|^)(' + $.SpecialWordsList.join('|') + ')(\\W|$)', 'ig');
		}

		commentText = commentText.replace($.CommentMessage.badWordRegex, '$1***$3');
	}
	
	var commentTextLines = commentText.split(/\r\n|\r\r|\r|\n/);
	if(commentTextLines.length > 1) {
		commentTextLines.forEach(function(textLine, index) {
			$('<span></span>').text(textLine).appendTo(text);
			text.append('<br/>');
		});
	} else {
		text.text(commentText);
	}

	if(comment.old) {
		content.addClass('oldComment');
		content.attr('data-tooltip', 'Old comment');
	}

	content.append(author).append(metadata).append(text);

	var actions = $('<div class="actions">');
	
	var rootComment = options.isReplyFor || comment;
	var commentReplies = Object.values(comment.replies || {}).filter(function(reply) {
		return !reply.deleted;
	});
	commentReplies.sortByKey('time');
	if(options.onReply && ((!commentReplies.length && !options.isReplyFor) || options.isLastReply) && options.canUseActions) {
		var replySpan = $('<span>Reply</span>');
		var replyElem = $('<a><i class="reply icon" /></a>').click(function() {
			$.SettingsBuilderDialog([
				{
					id: 'comment',
					description: 'Comment',
					type: 'largeText',
					defaultValue: '',
					customRule: 'empty'
				}
			], {
				title: 'Add comment',
				onSettingsApplied: function(settings) {
					if(!rootComment.replies) {
						rootComment.replies = {};
					}
		
					var reply = {
						id: $.getUniqueId(),
						comment: settings.comment,
						author: $.CurrentUser,
						time: (new Date()).getTime()
					};
					rootComment.replies[reply.id] = reply;
	
					var replyDiv = new $.CommentMessageReply(rootComment, reply, options, true);
	
					var rootDiv = div;
					if(options.isReplyFor) {
						rootDiv = $(div).parent().parent();
					}
					if(!rootDiv.find('.comments').length) {
						rootDiv.append('<div class="comments"></div>');
					}
					$(replyDiv).appendTo(rootDiv.find('.comments'));
	
					options.onReply(rootComment, reply);
					replyElem.remove();
				}
			});
		}).append(replySpan);

		replyElem.appendTo(actions);
	}
	if(comment.checked || options.onCheck) {
		var checkedSpan = $('<span>');
		var checkedElem = $('<a><i class="check icon" /> </a>').append(checkedSpan);

		updateCheckedElem();

		checkedElem.appendTo(actions);

		// eslint-disable-next-line
		function updateCheckedElem() {
			if(checkedElem.hasClass('hasPopup')) {
				checkedElem.removeClass('hasPopup').popup('destroy');
			}

			if(comment.checked) {
				checkedElem.off('click').addClass('commentChecked hasPopup').popup({
					content: 'Completed by ' + comment.checked.author.name + ' ' + moment(comment.checked.time).calendar()
				});
				checkedSpan.text('Completed');

				if(options.onCheck) {
					checkedElem.css('cursor', 'pointer').click(function() {
						comment.checked = null;

						options.onCheck.call(div, rootComment, comment);
						updateCheckedElem();
					});
				}
			} else {
				checkedElem.removeClass('commentChecked').off('click').click(function() {
					let checked = {
						author: $.CurrentUser,
						time: (new Date()).getTime()
					};
					if(comment && comment.__ob__) {
						Vue.set(comment, 'checked', checked);
					} else {
						comment.checked = checked;
					}
	
					options.onCheck.call(div, rootComment, comment);
					updateCheckedElem();
				});
				checkedSpan.text('Complete');
			}
		}
	}
	
	if(options.onDelete) {
		$('<div class="ui red tiny circular icon button deleteCommentButton"><i class="remove icon"></i></div>').popup({
			content: 'Delete comment'
		}).click(function() {
			$.ConfirmDelete('Delete comment', 'Are you sure you want to delete this commment?', function() {
				let deleted = {
					author: $.CurrentUser,
					time: (new Date()).getTime()
				};
				if(comment && comment.__ob__) {
					Vue.set(comment, 'deleted', deleted);
				} else {
					comment.deleted = deleted;
				}
				$(div).remove();

				options.onDelete.call(div, rootComment, comment);
			});
		}).appendTo(div);
	}

	if(actions.children().length) {
		content.append(actions);
	}

	div.append(content);

	if(commentReplies.length) {
		var replies = $('<div class="comments"></div>').appendTo(div);
		commentReplies.forEach(function(reply, replyIndex) {
			var replyDiv = new $.CommentMessageReply(comment, reply, options, replyIndex === commentReplies.length - 1);

			$(replyDiv).appendTo(replies);
		});
	}

	return div;
}
$.CommentMessageReply = function(comment, reply, options, isLastReply) {
	options = $.extend({}, options, {
		isLastReply: isLastReply
	});
	options.isReplyFor = comment;

	return $.CommentMessage(reply, options);
};