<template>
	<div>
		<template v-if="images.length > 0">
			<div>{{ imagesSelected }}</div>

			<v-data-iterator
				:items="images"
				:items-per-page="itemsPerPage"
				:footer-props="{ 'show-current-page': true, 'next-icon': 'chevron_right', 'prev-icon': 'chevron_left' }"
				class="image-grid"
				:hide-default-footer="disableImagePagination">

				<template v-slot:default="props">
					<v-row class="justify-center">
						<v-col lg="3" md="4" sm="6" v-for="image in props.items" :key="image.id" >
							<v-progress-circular v-if="image.converting" indeterminate />
							<v-progress-circular v-else-if="image.thumbnailError" color="red" indeterminate :size="120">Failed to load preview</v-progress-circular>
							<v-img v-else :src="imageFileSrc(image.file)" style="max-width: 100%" min-height="12em" max-height="30em" contain @error="failedLoadingImg(image)" />
						</v-col>
					</v-row>
				</template>
			</v-data-iterator>

			<component v-if="tagPickerComponent" :is="tagPickerComponent" v-model="customTags" :tag-label="tagLabel" :project-id="projectId" ref="tagPickerComponent" />
			<p v-else/>
		</template>

		<template v-if="rejectedUploads.length">
			<p v-if="images.length > 0"/>

			<rejected-files-table :rejected-uploads="rejectedUploads" />
		</template>

		<template v-if="images.length === 0">
			<p/>
			<v-alert type="error">{{ i18n('uploader.mustSelectImage') }}</v-alert>
			<v-btn color="primary" raised x-large @click="startOver">{{ i18n('uploader.startOver') }}</v-btn>
		</template>
		<template v-else-if="images.length > 0">
			<v-btn class="back" raised @click="startOver">{{ i18n('misc.back') }}</v-btn>
			<v-btn color="primary" raised x-large @click="startUpload">{{ i18n('uploader.startUploading') }}</v-btn>
		</template>
	</div>
</template>

<script>
import Vue from 'vue';
import processUploads from './mixins/process-uploads';
import RejectedFilesTable from './RejectedFilesTable.vue';

export default {
	name: 'ConfirmUpload',
	props: {
		uploads: null,
		imageRequirements: null,
		tagLabel: null,
		tagPickerComponent: null,
		photoConverters: null,
		projectId: null,
		isValidImage: null,
		uploadPhotoRequirements: null
	},
	data() {
		return {
			customTags: []
		};
	},
	mixins: [processUploads],
	computed: {
		itemsPerPage() {
			if(this.$vuetify.breakpoint.smAndDown) {
				return 2;
			} else if(this.$vuetify.breakpoint.md) {
				return 3;
			} else {
				return 4;
			}
		},
		rejectedUploads() {
			return (this.uploads || []).filter((upload) => {
				return !!upload.rejectReason;
			});
		},
		images() {
			if(this.uploads) {
				return this.uploads.filter((upload) => {
					return !upload.rejectReason;
				});
			} else {
				return [];
			}
		},
		imagesSelected() {
			return this.i18n('uploader.imagesSelected', {
				images: this.images.length, 
				smart_count: this.images.length
			});
		},
		disableImagePagination() {
			return this.images.length <= this.itemsPerPage;
		}
	},
	watch: {
		uploads() {
			this.processUploads();
		},
		customTags() {
			this.$emit('update-custom-tags', this.customTags);
		}
	},
	methods: {
		startUpload() {
			let error = null;
			let tagPicker = this.$refs.tagPickerComponent;
			if(tagPicker && tagPicker.$v) {
				tagPicker.$v.$touch();
				error = tagPicker.$v.$error;
			}

			if(!error) {
				this.$emit('next-step');
			}
		},
		startOver() {
			this.$emit('prev-step');
		},
		imageFileSrc(file) {
			if(file.viewUrl) {
				return file.viewUrl;
			} else if(file.downloadUrl) {
				return 'https://drive.google.com/thumbnail?authuser=0&sz=w320&id=' + file.id;
			} else {
				return URL.createObjectURL(file);
			}
		},

		failedLoadingImg(upload) {
			Vue.set(upload, 'thumbnailError', true);
		}
	},
	components: {
		RejectedFilesTable
	}
};
</script>

<style scoped>
.image-grid:deep(.v-data-footer__select) {
	display: none;
}

.back {
	margin-right: 1em;
}
</style>