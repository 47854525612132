$.FlowPageSet = function(settings) {
	var obj = $.DataModel();

	$.extend(obj, {
		getPages: function() {
			return this.pages.filter(page => !!page);
		},
		addPage: function(page, index, save) {
			page.db = this.db;
			if (page.getType() == 'class' && page.classObj) {
				page.classObj.active = true;
			}

			var pageLimit = this.getPageLimit();
			if(this.enforcePageLimit && pageLimit && this.pages.length >= pageLimit) {
				$.Alert('Warning', 'Already at the limit of ' + pageLimit + ' pages');
				return;
			}

			if(!$.isInit(index)) {
				this.pages.push(page);
				this.page = this.pages.length - 1;
				if (this.page % 2 == 1) {
					this.page--;
				}
				index = this.pages.length;
				page.setPageNumber(index);
			} else {
				this.pages.splice(index + 1, 0, page);
				page.setPageNumber(index + 2);
				for (var i = index + 2; i < this.pages.length; i++) {
					this.pages[i].setPageNumber(i + 1);
				}
			}
			page.pageSet = this;

			if(this.onPageChange) {
				this.onPageChange('add', page, index);
			}
			page.onAdd();

			if (this.db && save !== false) {
				var pageData = this.db.addEntirePage(page);

				if(this.userEvents) {
					var event = {
						context: [page],
						action: 'insert',
						args: [pageData, index]
					};
					if(page.getRootPage && page.getRootPage() && page.getRootPage() != page) {
						event.extras = {
							rootPage: page.getRootPage().id
						};
					}

					this.userEvents.addEvent(event);
				}
			}

			return true;
		},
		removePage: function() {
			this.removePageCascade.apply(this, arguments);
		},
		removePageCascade: function(page, save, options) {
			options = $.extend(true, {
				pagesToRemove: 10000000
			}, options);

			for(var i = 0; i < this.pages.length; i++) {
				if(this.pages[i] == page) {
					var overflowPage = page;
					var pagesRemoved = [];
					var pagesRemovedCount = 0;
					var blockRemovingOverflowPage = false;
					while(overflowPage != null) {
						this.pages.splice(i, 1);

						if(this.onPageChange) {
							this.onPageChange('removed', overflowPage, i);
						}
						if (this.db) {
							if(save !== false) {
								this.db.queueChange({
									scope: 'pages',
									name: overflowPage.getId(),
									value: {
										remove: 'true'
									}
								}, true);
							} else {
								this.db.removeChange({
									scope: 'pages',
									name: overflowPage.getId()
								});
							}
						}
						overflowPage.onRemove();

						pagesRemoved.push(overflowPage);
						if(overflowPage.getOverflowPage) {
							overflowPage = overflowPage.getOverflowPage();
						} else {
							overflowPage = null;
						}
						pagesRemovedCount++;
						if(overflowPage && pagesRemovedCount >= options.pagesToRemove && (page.getParentPage && page.getParentPage() && this.pages.indexOf(page.getParentPage()) !== -1)) {
							var newParent = page.getParentPage();
							newParent.overflowPage = overflowPage;
							overflowPage.parentPage = newParent;
							blockRemovingOverflowPage = true;
							break;
						}
					}

					if(this.pushPageRemoveImmediately) {
						this.db.pushChanges();
					}

					// setOverflowPage can be null if we consumed this event as part of a multi-step replacement event
					if(page.parentPage && page.parentPage.setOverflowPage && !blockRemovingOverflowPage) {
						page.parentPage.setOverflowPage(null, save);
					}

					if($.userEvents && save !== false && this.db) {
						// TODO: Once we are sure that arrays work fine, simplify this into single case
						var pageData;
						if(pagesRemoved.length > 1) {
							pageData = pagesRemoved.map(function(page) {
								return obj.db.getEntirePageData(page);
							});
						} else {
							pageData = this.db.getEntirePageData(page);
						}

						$.userEvents.addEvent({
							context: [page],
							action: 'remove',
							args: [pageData, i],
							extras: {
								pagesRemoved: pagesRemoved.length
							}
						});
					}

					// Mark class inactive
					if (page.setClassActive) {
						page.setClassActive(false);
					}

					// Update everyone's page numbers
					for (var j = page.getPageNumber() - 1; j < this.getTotalPages(); j++) {
						this.getPage(j).setPageNumber(j + 1);
					}

					if(this.onPageChange) {
						this.onPageChange('removed-cascade', page, i);
					}

					return i;
				}
			}

			if($.globalBugsnagInfo) {
				$.globalBugsnagInfo.failedDeletePage = {
					id: page.id,
					pageNumber: page.getPageNumber(),
					totalPages: this.pages.length
				};
			}
			throw new Error('Failed to remove page');
		},
		replacePage: function (oldPage, newPage) {
			var index = this.pages.indexOf(oldPage);
			if (index != -1) {
				this.pages.splice(index, 1, newPage);
				newPage.id = oldPage.id;
				newPage.db = this.db;
				newPage.pageSet = this;
				newPage.setPageNumber(oldPage.getPageNumber());
				var oldUserLabel = oldPage.getUserLabel(false);
				if(oldUserLabel) {
					newPage.setUserLabel(oldUserLabel, {
						save: false
					});
				}
				newPage.theme = oldPage.theme;
				['backgroundSettings'].forEach(function(extraName) {
					if(oldPage.extras && $.isInit(oldPage.extras[extraName])) {
						newPage.extras[extraName] = oldPage.extras[extraName];
					}
				});

				if(oldPage.type == 'cover') {
					newPage.type = 'cover';
				}
				oldPage.onRemove();
				newPage.onAdd();

				if (this.db) {
					var oldPageData = this.db.getEntirePageData(oldPage);
					delete oldPageData.pageNumber;

					this.db.addEntirePage(newPage, false, {
						// Page number can conflict with concurrent users so we don't want this to cause save errors
						stripPageNumber: true
					});

					if($.userEvents) {
						// Want to add it delayed since after replacePage we are going to be adding a bunch of info
						// Needs to add event immediately though so stuff like adding overflow pages is after
						var me = this;
						$.userEvents.addEventDelayed({
							context: [newPage],
							action: 'update',
							args: null,
							stripDuplicates: false
						}, {
							argsChanged: true,
							onComplete: function(event) {
								// Page number can conflict with concurrent users so we don't want this to cause save errors
								var pageData = me.db.getEntirePageData(newPage);
								delete pageData.pageNumber;

								event.args = [oldPageData, pageData];
							}
						});
					}
				}

				if(oldPage.getOverflowPage && oldPage.getOverflowPage()) {
					this.removePage(oldPage.getOverflowPage());
				}
				if(oldPage.setClassActive) {
					oldPage.setClassActive(false);
				}

				if(this.onPageChange) {
					this.onPageChange('replace', newPage, index);
				}
			}
		},
		movePage: function(page, newIndex, save) {
			var startIndex;

			// If we are going forward we need to move to last spot so next ones get pushed right behind it
			var overflowPage = page;
			var newIndexIncrement = -1;
			while (overflowPage != null) {
				newIndexIncrement++;

				if (overflowPage.getOverflowPage) {
					overflowPage = overflowPage.getOverflowPage();
				} else {
					overflowPage = null;
				}
			}

			var increment = 0;
			overflowPage = page;
			var finalNewIndex = newIndex;
			while (overflowPage != null) {
				startIndex = this.getPageIndex(overflowPage);
				var moveToIndex = newIndex;
				if(newIndex > startIndex) {
					moveToIndex += newIndexIncrement;
				}

				this.pages.move(startIndex, moveToIndex);
				overflowPage.setPageNumber(finalNewIndex + 1);

				// Fix moving multiple forward
				if(newIndex < startIndex) {
					newIndex++;
				}
				increment++;
				finalNewIndex++;

				if (overflowPage.getOverflowPage) {
					overflowPage = overflowPage.getOverflowPage();
				} else {
					overflowPage = null;
				}
			}

			// Move forward
			for(var i = startIndex; i < (finalNewIndex - increment); i++) {
				this.pages[i].incrementPageNumber(-increment);
			}
			// Move backward
			for(i = finalNewIndex; i <= startIndex; i++) {
				this.pages[i].incrementPageNumber(increment);
			}

			if(save !== false) {
				this.queueMovePageTo(page, finalNewIndex - startIndex);
			}

			if(this.onPageChange) {
				this.onPageChange('move', page, newIndex);
			}
		},
		queueMovePageTo: function (page, diff) {
			if (this.db) {
				// If we are moving forward they should all be moving to the same page number
				var overflowPage = page, lastPage;
				while (overflowPage != null) {
					lastPage = overflowPage;

					if (overflowPage.getOverflowPage) {
						overflowPage = overflowPage.getOverflowPage();
					} else {
						overflowPage = null;
					}
				}

				overflowPage = page;
				while (overflowPage != null) {
					var to;
					if(diff > 0) {
						to = lastPage.getPageNumber();
					} else {
						to = overflowPage.getPageNumber();
					}

					this.db.queueChange({
						scope: 'pages',
						name: overflowPage.getId(),
						value: {
							movedTo: to
						}
					});

					if (overflowPage.getOverflowPage) {
						overflowPage = overflowPage.getOverflowPage();
					} else {
						overflowPage = null;
					}
				}
			}
		},

		getPage: function (index) {
			return this.pages[index];
		},
		getPageIndex: function (page) {
			return this.pages.indexOf(page);
		},
		getTotalPages: function () {
			return this.pages.length;
		},
		getFirstPage: function() {
			var pages = this.pages.filter(function(page) {
				return !!page;
			});
			if(pages.length) {
				return pages[0];
			} else {
				return null;
			}
		},
		getFirstPageNumber: function() {
			var pages = this.pages.filter(function(page) {
				return !!page;
			});
			if(pages.length) {
				return pages[0].getPageNumber();
			} else {
				return 0;
			}
		},
		getLastPage: function() {
			var pages = this.pages.filter(function(page) {
				return !!page;
			});
			if(pages.length) {
				return pages[this.pages.length - 1];
			} else {
				return null;
			}
		},
		getLastPageNumber: function() {
			if(this.pages.length) {
				var lastPageIndex = this.pages.length - 1;
				while(lastPageIndex >= 0 && !this.pages[lastPageIndex]) {
					lastPageIndex--;
				}

				return this.pages[lastPageIndex].getPageNumber();
			} else {
				return 0;
			}
		},
		getHighestPageNumber: function() {
			var highestPageNumber = 1;

			this.pages.forEach(function(page) {
				if(!page) {
					return;
				}

				highestPageNumber = Math.max(highestPageNumber, page.getPageNumber());
			});

			return highestPageNumber;
		},
		getPagesOfType: function (type) {
			var count = 0;
			for(var i = 0; i < this.pages.length; i++) {
				if(this.pages[i] && this.pages[i].type == type) {
					count++;
				}
			}

			return count;
		},
		getPageIndexById: function(id) {
			for(var i = 0; i < this.pages.length; i++) {
				if(this.pages[i] && this.pages[i].id == id) {
					return i;
				}
			}

			return -1;
		},
		getPageById: function (id) {
			for(let i = 0; i < this.pages.length; i++) {
				let page = this.pages[i];
				if(page) {
					if(page.id == id) {
						return page;
					}

					let alternativeVersion = Object.values(page.alternativeVersions).find(altVersion => altVersion && altVersion.id === id);
					if(alternativeVersion) {
						return alternativeVersion;
					}
				}
			}

			return null;
		},
		getPageIndexByPageNumber: function(pageNumber) {
			for(var i = 0; i < this.pages.length; i++) {
				if(this.pages[i] && this.pages[i].getPageNumber() == pageNumber) {
					return i;
				}
			}

			return -1;
		},
		getPageByPageNumber: function(pageNumber) {
			for(var i = 0; i < this.pages.length; i++) {
				if(this.pages[i] && this.pages[i].getPageNumber() == pageNumber) {
					return this.pages[i];
				}
			}

			return null;
		},
		forEachPage: function(callback) {
			this.pages.forEach(function(page) {
				if(page) {
					callback.call(obj, page);
				}
			});
		},

		setTheme: function (theme) {
			// Don't allow bug setting theme to background to happen again
			if(theme && theme.type == 'backgrounds') {
				return false;
			}

			var oldTheme = this.theme;
			this.propertyChange('theme', theme, false, true);

			this.updateLockedPages(oldTheme);
			this.updateTextThemeStyles(oldTheme, theme);
		},
		updateLockedPages: function(oldTheme) {
			if(!oldTheme) {
				oldTheme = {};
			}

			let pages = this.getPages();

			// Check for locked cover pages to update their background to be the previous theme so it doesn't suddenly change
			pages.forEach(page => {
				if(!page.locked || !page.side || page.theme || (page.canThemePageWhileLocked && page.canThemePageWhileLocked())) {
					return;
				}

				let themeTypeName = page.getThemeStylePartName();
				let side = page.side === 'front' ? 'Left' : 'Right';
				let themePartName = themeTypeName + ' ' + side;

				let themePart = oldTheme[themePartName];
				if(themePart) {
					themePart = $.extend(true, {}, themePart);
					let themeSettings = {};
					Object.keys(themePart).forEach(themePartProp => {
						if(['id', 'cdnUrl', 'photo_name', 'photoWidth', 'photoHeight'].includes(themePartProp)) {
							return;
						}

						themeSettings[themePartProp] = themePart[themePartProp];
						delete themePart[themePartProp];
					});

					page.setTheme({
						type: 'backgrounds',
						Background: themePart,
						Preview: themePart
					});
					if(Object.keys(themeSettings).length) {
						page.setExtraProperty('backgroundSettings', themeSettings, true);
					}
				} else {
					page.setTheme({
						type: 'empty'
					});
				}
			});

			// Check for unlocked cover pages with a type of empty to go back to using the theme
			pages.forEach(page => {
				if(page.locked || !page.side || !page.theme || page.theme.type !== 'empty') {
					return;
				}

				page.setTheme(null);
			});
		},
		updateTextThemeStyles: function(oldTheme, newTheme) {
			if(oldTheme && oldTheme.extras) {
				oldTheme = oldTheme.extras;
			} else {
				oldTheme = {};
			}
			if(newTheme && newTheme.extras) {
				newTheme = newTheme.extras;
			} else {
				newTheme = {};
			}

			for(var i = 0; i < this.pages.length; i++) {
				var page = this.pages[i];
				if(!page || page.locked || page.getFreeMovementLocked()) {
					continue;
				}

				var themePartName = page.getThemeStylePartName();

				var oldThemePart = oldTheme[themePartName];
				if(!oldThemePart) {
					oldThemePart = {};
				}
				var newThemePart = newTheme[themePartName];
				if(!newThemePart) {
					newThemePart = {};
				}

				page.updateTextThemeStyles(oldThemePart, newThemePart);
			}

			if(this.pageNumberCSS) {
				this.updatePageNumberThemeStyles(oldTheme, newTheme);
			}
		},
		updatePageNumberThemeStyles: function(oldTheme, newTheme) {
			var oldThemePart, newThemePart;
			if(oldTheme.All && oldTheme.All.pageNumber) {
				oldThemePart = oldTheme.All.pageNumber;
			}
			if(newTheme.All && newTheme.All.pageNumber) {
				newThemePart = newTheme.All.pageNumber;
			}

			if((oldThemePart || newThemePart) && this.pages.length) {
				var page = this.pages[0];

				var newStyles = page.updateTextObjectThemeStyles(this.pageNumberCSS.css, oldThemePart, newThemePart);
				if(newStyles) {
					this.setPageNumberCSSStyles(newStyles);
				}
			}
		},
		getTheme: function () {
			return this.theme;
		},
		hasThemePart: function (part) {
			if (!this.theme) {
				return false;
			}

			for (var i in this.theme) {
				if (this.theme[i] && this.theme[i].id && i.indexOf(part) != -1) {
					return true;
				}
			}

			return false;
		},
		setOnPageChangeListener: function (listener) {
			this.onPageChange = listener;
		},

		sortPages: function () {
			this.pages.sort(function (x, y) {
				if (x.getPageNumber() < y.getPageNumber()) {
					return -1;
				} else if (x.getPageNumber() > y.getPageNumber()) {
					return 1;
				} else {
					return 0;
				}
			});
		},
		saveNow: function () {
			this.db.pushChanges();
		},
		setPageLimit: function (pageLimit) {
			this.pageLimit = pageLimit;
		},
		getPageLimit: function () {
			return this.pageLimit;
		},
		getPageMargins: function () {
			return this.pageMargins;
		},
		setProperty: function() {
			this.propertyChange.apply(this, arguments);
		},
		propertyChange: function(name, value, forceUpdate, sendEvent) {
			var startValue = this[name];
			this[name] = value;

			if (value != startValue) {
				if(this.db) {
					this.db.queueChange({
						scope: 'yearbook',
						name: name,
						value: value
					});
				}

				if(sendEvent && this.userEvents) {
					this.userEvents.addEvent({
						context: [this, name],
						action: 'update',
						args: [startValue, value]
					});
				}
			}
		},
		setSubjects: function(subjects) {
			this.subjects = subjects;
		},
		getSubjects: function() {
			return this.subjects;
		},
		setPlaceholderSubjects: function(subjects) {
			this.placeholderSubjects = subjects;
		},
		getPlaceholderSubjects: function() {
			return this.placeholderSubjects;
		},
		getAllSubjects: function() {
			var subjects = [];
			if(this.subjects) {
				$.merge(subjects, this.subjects);
			}
			if(this.placeholderSubjects) {
				$.merge(subjects, this.placeholderSubjects);
			}

			return subjects;
		},
		getSubjectPoseCropNames: function() {
			var subjects = this.getSubjects();
			if(!subjects) {
				return [];
			}

			var crops = [];
			subjects.forEach(function(subject) {
				subject.photos.forEach(function(photo) {
					photo.photo_crops.forEach(function(crop) {
						if(crops.indexOf(crop.name) === -1) {
							crops.push(crop.name);
						}
					});
				});
			});
			crops.caseInsensitiveSort();

			return crops;
		},
		getStatisticsForSubjects: function(options) {
			options = $.extend(true, {
				includePlaceholderSubjects: false,
				includePageUsage: true
			}, options);

			var stats = [];
			var subjectIds = {};
			for(var i = 0; i < this.subjects.length; i++) {
				var subject = this.subjects[i];
				// Don't repeat subject stat if duplicated in master list for any reason
				if(subjectIds[subject.id]) {
					continue;
				}

				stats.push(this.getStatisticsForSubject(subject, options));
				subjectIds[subject.id] = true;
			}

			if(options.includePlaceholderSubjects && this.placeholderSubjects) {
				for(i = 0; i < this.placeholderSubjects.length; i++) {
					subject = this.placeholderSubjects[i];
					if(subjectIds[subject.id]) {
						continue;
					}

					stats.push(this.getStatisticsForSubject(subject, options));
					subjectIds[subject.id] = true;
				}
			}

			stats.caseInsensitiveSort('name');
			return stats;
		},
		getStatisticsForSubject: function(subject, options) {
			options = $.extend(true, {
				includePlaceholderSubjects: false,
				includePageUsage: true
			}, options);

			var name = '';
			if(subject['Last Name']) {
				name = subject['Last Name'];

				if(subject['First Name']) {
					name += ', ' + subject['First Name'];
				}
			} else if(subject['First Name']) {
				name = subject['First Name'];
			}

			var stat = {
				id: subject.id,
				photo: subject.photo,
				photoCdnUrl: subject.photoCdnUrl,
				name: $.trim(name),
				firstName: subject['First Name'],
				lastName: subject['Last Name'],
				candids: [],
				classPages: [],
				subject: subject
			};

			if(options.includePageUsage) {
				for(var i = 0; i < this.pages.length; i++) {
					var page = this.pages[i];
					if(!page) {
						continue;
					}

					var statsForPage = page.getStatisticsForSubject(subject);
					for(var prop in statsForPage) {
						var value = statsForPage[prop];
						if($.isArray(value)) {
							stat[prop] = $.merge(stat[prop], value);
						}
						// Don't override in case of overflow pages detecting the same subject
						else if(!$.isInit(stat[prop])) {
							stat[prop] = value;
						}
					}
				}
			}

			return stat;
		},
		setLayoutDimensions: function(layoutDimensions) {
			this.layoutDimensions = layoutDimensions;
		},
		getLayoutDimensions: function() {
			if(this.layoutDimensions) {
				return $.sanitizeNumbersAsStrings(this.layoutDimensions);
			} else if(this.layout && this.layout.grid && $.isInit(this.layout.grid.width) && $.isInit(this.layout.grid.height)) {
				return $.sanitizeNumbersAsStrings({
					cropWidth: this.layout.grid.width,
					cropHeight: this.layout.grid.height,
					bleed: this.layout.grid.bleed
				});
			} else {
				return null;
			}
		},
		getLayoutDimensionProperty: function(name) {
			if(this.layoutDimensions) {
				return this.layoutDimensions[name];
			} else {
				return null;
			}
		},
		getBleedFromDimensions: function(side) {
			var dimensions = this.getLayoutDimensions();
			if(dimensions) {
				var baseBleed = $.PAGE_BLEED;
				if($.isInit(dimensions.bleed)) {
					baseBleed = dimensions.bleed;
				}
				var bleed = {
					left: baseBleed,
					right: baseBleed,
					top: baseBleed,
					bottom: baseBleed
				};

				if ($.isInit(dimensions.gutterBleed)) {
					if (side == 'Right') {
						bleed.left = dimensions.gutterBleed;
					} else {
						bleed.right = dimensions.gutterBleed;
					}
				}
				if ($.isInit(dimensions.outsideBleed)) {
					if (side == 'Right') {
						bleed.right = dimensions.outsideBleed;
					} else {
						bleed.left = dimensions.outsideBleed;
					}
				}
				if ($.isInit(dimensions.topBleed)) {
					bleed.top = dimensions.topBleed;
				}
				if ($.isInit(dimensions.bottomBleed)) {
					bleed.bottom = dimensions.bottomBleed;
				}

				return bleed;
			} else {
				return {
					left: $.PAGE_BLEED,
					right: $.PAGE_BLEED,
					top: $.PAGE_BLEED,
					bottom: $.PAGE_BLEED
				};
			}
		},
		getSafeSpace: function(side) {
			var safeSpace = {
				left: 0,
				right: 0,
				top: 0,
				bottom: 0
			};

			var dimensions = this.getLayoutDimensions();
			if(dimensions) {
				if ($.isInit(dimensions.gutterSafeSpace)) {
					if (side == 'Right') {
						safeSpace.left = dimensions.gutterSafeSpace;
					} else {
						safeSpace.right = dimensions.gutterSafeSpace;
					}
				}
				if ($.isInit(dimensions.outsideSafeSpace)) {
					if (side == 'Right') {
						safeSpace.right = dimensions.outsideSafeSpace;
					} else {
						safeSpace.left = dimensions.outsideSafeSpace;
					}
				}
				if ($.isInit(dimensions.topSafeSpace)) {
					safeSpace.top = dimensions.topSafeSpace;
				}
				if ($.isInit(dimensions.bottomSafeSpace)) {
					safeSpace.bottom = dimensions.bottomSafeSpace;
				}
			}

			return safeSpace;
		},
		getWhiteSpace: function(side) {
			var whiteSpace = {
				left: 0,
				right: 0,
				top: 0,
				bottom: 0
			};

			var dimensions = this.getLayoutDimensions();
			if(dimensions) {
				if ($.isInit(dimensions.gutterWhiteSpace)) {
					if (side == 'Right') {
						whiteSpace.left = dimensions.gutterWhiteSpace;
					} else {
						whiteSpace.right = dimensions.gutterWhiteSpace;
					}
				}
				if ($.isInit(dimensions.outsideWhiteSpace)) {
					if (side == 'Right') {
						whiteSpace.right = dimensions.outsideWhiteSpace;
					} else {
						whiteSpace.left = dimensions.outsideWhiteSpace;
					}
				}
				if ($.isInit(dimensions.topWhiteSpace)) {
					whiteSpace.top = dimensions.topWhiteSpace;
				}
				if ($.isInit(dimensions.bottomWhiteSpace)) {
					whiteSpace.bottom = dimensions.bottomWhiteSpace;
				}
			}

			return whiteSpace;
		},
		getOuterDimensions: function(side, options) {
			options = $.extend(true, {
				includeWhiteSpace: false
			}, options);

			var dimensions = this.getLayoutDimensions();
			var whiteSpace = {
				left: 0,
				right: 0,
				top: 0,
				bottom: 0
			};
			if(dimensions) {
				var bleed = this.getBleedFromDimensions(side);
				var width = dimensions.cropWidth + bleed.left + bleed.right;
				var height = dimensions.cropHeight + bleed.top + bleed.bottom;
				var safeSpace = this.getSafeSpace(side);
				
				bleed.left += safeSpace.left;
				bleed.right += safeSpace.right;
				bleed.top += safeSpace.top;
				bleed.bottom += safeSpace.bottom;

				if(options.includeWhiteSpace) {
					whiteSpace = this.getWhiteSpace(side);
					bleed.left += whiteSpace.left;
					bleed.right += whiteSpace.right;
					bleed.top += whiteSpace.top;
					bleed.bottom += whiteSpace.bottom;

					width += whiteSpace.left + whiteSpace.right;
					height += whiteSpace.top + whiteSpace.bottom;
				}

				return {
					width: width,
					height: height,
					bleed: bleed,
					safeSpace: safeSpace,
					whiteSpace: whiteSpace
				};
			} else {
				return {
					width: $.PAGE_WIDTH,
					height: $.PAGE_HEIGHT,
					bleed: this.getBleedFromDimensions(side),
					safeSpace: this.getSafeSpace(side),
					whiteSpace: whiteSpace
				};
			}
		},
		getInnerDimensions: function(side) {
			var dimensions = this.getLayoutDimensions();
			if(dimensions) {
				var width = dimensions.cropWidth;
				var height = dimensions.cropHeight;
				var bleed = this.getBleedFromDimensions(side);

				var safeSpace = this.getSafeSpace(side);
				width = width - (safeSpace.left + safeSpace.right);
				height = height - (safeSpace.top + safeSpace.bottom);
				bleed.left += safeSpace.left;
				bleed.right += safeSpace.right;
				bleed.top += safeSpace.top;
				bleed.bottom += safeSpace.bottom;

				return {
					width: width,
					height: height,
					bleed: bleed,
					safeSpace: safeSpace
				};
			} else {
				return {
					width: $.PAGE_WIDTH - ($.PAGE_BLEED * 2),
					height: $.PAGE_HEIGHT - ($.PAGE_BLEED * 2),
					bleed: this.getBleedFromDimensions(side),
					safeSpace: this.getSafeSpace(side)
				};
			}
		},
		getLayoutAspectRatio: function() {
			var dimensions = this.getOuterDimensions();
			return dimensions.width / dimensions.height;
		},
		doesContainTextInParent: function() {
			return this.containTextInParent;
		},
		doesContainImageInParent: function() {
			return this.containImageInParent;
		},
		setUserDefaultValue: function(name, value) {
			var oldValue = $.extend(true, {}, this.userDefaultValues);
			this.userDefaultValues[name] = value;

			if(this.db) {
				this.db.queueChange({
					scope: 'yearbook',
					name: 'userDefaultValues',
					value: this.userDefaultValues
				});

				if($.userEvents) {
					$.userEvents.addEvent({
						context: ['pageSet', 'userDefaultValues'],
						action: 'update',
						args: [oldValue, this.userDefaultValues]
					});
				}
			}
		},
		getUserDefaultValue: function(name, defaultValue) {
			if(this.userDefaultValues[name]) {
				return this.userDefaultValues[name];
			} else {
				return defaultValue;
			}
		},
		getMostCommonStyle: function(styleSets) {
			var styleCount = {};

			var maxStyleCount = 0;
			var maxStyle = {};
			styleSets.forEach(function(style) {
				var styleName = JSON.stringify(style);

				var count = 0;
				if(!styleCount[styleName]) {
					count = styleCount[styleName] = 1;
				} else {
					count = styleCount[styleName] = styleCount[styleName] + 1;
				}

				if(count > maxStyleCount) {
					maxStyle = style;
					maxStyleCount = count;
				}
			});

			return maxStyle;
		},
		validateProperty: function(prop) {
			if(prop == 'theme') {
				if(this.theme && !this.theme.type) {
					this.theme.type = 'themes';
				}
			}
		},
		showBleedMask: function() {
			return this.bleedMask;
		},
		forceGrayscale: function() {
			return false;
		},
		getMaxInsertIndex: function() {
			return 10000000;
		},
		isAllowedToAddPages: function() {
			return true;
		},

		copyTitleStylesToOthers: function(skipPage, copyProperties) {
			for(var i = 0; i < this.pages.length; i++) {
				var page = this.pages[i];
				if(!page) {
					continue;
				}

				if(page != skipPage) {
					if(page.copyTitleStyles) {
						page.copyTitleStyles(copyProperties);
					}
				} else {
					page.copyTitleStylesForExtraBatches(copyProperties);
				}
			}

			this.setUserDefaultValue('titleStyle', copyProperties);
		},
		getMostCommonTitleStyles: function() {
			var styles = [];
			this.pages.forEach(function(page) {
				if(page && page.title) {
					styles.push(page.getTextStyles(page.title));
				}
			});

			return this.getMostCommonStyle(styles);
		},
		// NOTE: This is for a sub-set to declare whether this should exist or not.  Be careful when changing to not return false for a just removed page from the set.
		shouldPageExistInSet: function() {
			return true;
		},

		pages: [],
		theme: '',
		pageMargins: {},
		contextAlias: 'pageSet',
		pageLimit: 0,
		enforcePageLimit: false,
		containTextInParent: true,
		containImageInParent: false,
		userDefaultValues: {},
		referencePagesBy: 'pageNumber',
		bleedMask: false
	}, settings);

	return obj;
};