$.FlowPage = function(settings) {
	var obj = $.DataModel();
	
	$.extend(obj, {
		getId: function () {
			return this.id;
		},
		getTitle: function () {
			return this.title;
		},
		getTitleText: function () {
			var title = this.getTitle();
			if(!title) {
				return '';
			} else if(title.lines) {
				return $.FlowLayoutSVG({
					renderable: false
				}).getInstanceText(title, {
					lineBreak: ' '
				});
			} else {
				return title;
			}
		},
		getTitleInchHeight: function() {
			var DEFAULT_TITLE_FONT_SIZE = 26;

			var title = this.getTitle();
			var height = 0.25;
			if(!title) {
				height = 0;
			} else if($.isPlainObject(title)) {
				var lines = title.lines;
				if(!$.isArray(lines)) {
					lines = [lines];
				}

				// 0.25 is top/bottom padding on title
				lines.forEach(function(line) {
					var titleFontSize = $.FlowLayoutSVGUtils.getMaxFontSize(line, DEFAULT_TITLE_FONT_SIZE);
					height += titleFontSize / 72 * 1.33;
				});
			} else {
				height += DEFAULT_TITLE_FONT_SIZE / 72 * 1.33;
			}

			return height;
		},
		setTitle: function (title) {
			this.propertyChange('title', title, false, true);
		},
		copyTitleStyles: function(copyProperties) {
			if($.isInit(this.title)) {
				var title = this.title;
				if(typeof title == 'string') {
					title = {
						lines: $.extend(true, {}, copyProperties, {
							text: title
						})
					};
				} else if(this.title && this.title.lines) {
					title = $.extend(true, {}, title);
					this.copyTextStylesToText(title, copyProperties);
				}

				this.propertyChange('title', title, true, true);
			} else if(typeof this.title == 'undefined') {
				title = {
					lines: $.extend(true, {}, copyProperties, {
						text: '%batch%'
					})
				};

				this.propertyChange('title', title, true, true);
			}
			this.copyTitleStylesForExtraBatches(copyProperties);
		},
		copyTitleStylesForExtraBatches: function(copyProperties) {
			if($.isInit(this.extraTitles) && $.isPlainObject(this.extraTitles) && $.getObjectCount(this.extraTitles) > 0) {
				for(var id in this.extraTitles) {
					var extraTitle = this.extraTitles[id];

					if(typeof extraTitle == 'string') {
						extraTitle = {
							lines: $.extend(true, {}, copyProperties, {
								text: extraTitle
							})
						};
					} else if(extraTitle && extraTitle.lines) {
						extraTitle = $.extend(true, {}, extraTitle);
						this.copyTextStylesToText(extraTitle, copyProperties);
					}

					this.jsonPropertyChange('extraTitles', id, extraTitle);
				}
			}
		},
		applyDefaultTitleStyle: function() {
			if(this.pageSet) {
				var titleStyles = this.pageSet.getUserDefaultValue('titleStyle');
				// If no explicit globals set, grab most common styles
				if(!titleStyles && this.pageSet.getMostCommonTitleStyles) {
					titleStyles = this.pageSet.getMostCommonTitleStyles();
				}

				if (titleStyles && $.getObjectCount(titleStyles) > 0) {
					this.copyTitleStyles(titleStyles);
				}
			}
		},

		getLayout: function () {
			return this.layout;
		},
		setLayout: function (layout, forceUpdate, sendEvent) {
			this.propertyChange('layout', layout, forceUpdate, sendEvent);
		},
		mergeLayoutOptions: function(layoutOptions) {
			var layout = $.extend(true, {}, this.layout, layoutOptions);

			if(layoutOptions.cell && layoutOptions.cell.alignCells) {
				var oldMainSide = this.layout.cell.alignCells;
				var oldFlippedSide;
				switch(oldMainSide) {
					case 'left':
						oldFlippedSide = 'right';
						break;
					case 'right':
						oldFlippedSide = 'left';
						break;
					case 'outside':
						oldFlippedSide = 'inside';
						break;
					case 'inside':
						oldFlippedSide = 'outside';
						break;
				}
				
				var mainSide = layoutOptions.cell.alignCells;
				var flippedSide;
				var nameAlign;
				switch(mainSide) {
					case 'left':
						flippedSide = 'right';
						nameAlign = 'left';
						break;
					case 'right':
						flippedSide = 'left';
						nameAlign = 'right';
						break;
					case 'outside':
						flippedSide = 'inside';
						nameAlign = 'inside';
						break;
					case 'inside':
						flippedSide = 'outside';
						nameAlign = 'outside';
						break;
				}

				// This should only match the column quotes layout
				if(flippedSide && layout.cell.name == oldFlippedSide && layout.cell[oldFlippedSide + 'Padding'] && layout.name && ['outside', 'inside', 'left', 'right'].indexOf(layout.name.align) != -1 &&
						layout.cellTexts && layout.cellTexts.length) {

					layout.cell.name = flippedSide;
					if((oldFlippedSide + 'Padding') != (flippedSide + 'Padding')) {
						layout.cell[flippedSide + 'Padding'] = layout.cell[oldFlippedSide + 'Padding'];
						delete layout.cell[oldFlippedSide + 'Padding'];

						for(var size in layout.cells) {
							var cell = layout.cells[size];
							cell[flippedSide + 'Padding'] = cell[oldFlippedSide + 'Padding'];
							delete cell[oldFlippedSide + 'Padding'];

							cell.name = flippedSide;
						}
					}
					layout.name.align = nameAlign;

					layout.cellTexts[0].manualSize.width = flippedSide + 'Padding';
					layout.cellTexts[0].position.left = 'cell' + flippedSide.toTitleCase();
					layout.cellTexts[0].lines.align = nameAlign;
				}
			}

			if($.isInit(layoutOptions.sidePadding)) {
				if(layout.row && $.isInit(layout.row.insidePadding)) {
					delete layout.sidePadding;

					if(layoutOptions.sidePadding) {
						delete layout.row.insidePadding;
						delete layout.row.outsidePadding;
					} else {
						layout.row.outsidePadding = 0;
						layout.row.insidePadding = 0;
					}
				}
			}

			// If we are dragging on a class behavior with no second line onto a second line with children, leave the children there
			if(this.layout.name && this.layout.name.order2 && this.layout.name.order2.indexOf('%child x%') !== -1) {
				if(layout.name && !layout.name.order2) {
					layout.name.order2 = this.layout.name.order2;
				}
			}

			if(layoutOptions.name && layout.cell && (layout.cell.name === 'none' || !$.isInit(layout.cell.name))) {
				if(layout.cells && layout.cells.Small && layout.cells.Small.name) {
					layout.cell.name = layout.cells.Small.name;
				} else {
					layout.cell.name = 'bottom';
				}
			}

			// If we are going from no name to having a name, add some defaults in here
			if(layoutOptions.name && !this.layout.name) {
				layout.name.nameHeightFix = true;
				layout.name.size = 10;
				layout.name.align = 'center';
				if(!layout.cell.nameSize) {
					layout.cell.nameSize = 6;
				}
			}

			this.setLayout(layout, true, true);
		},
		getScaledLayout: function(layout, options = {}) {
			if(!this.pageSet) {
				return layout;
			}

			var layoutDimensions = options.layoutDimensions || $.sanitizeNumbersAsStrings(layout.grid || {
				width: $.PAGE_WIDTH - ($.PAGE_BLEED * 2),
				height: $.PAGE_HEIGHT - ($.PAGE_BLEED * 2)
			});
			var pageSetDimensions = options.pageSetDimensions || this.pageSet.getInnerDimensions();

			var widthDifference = pageSetDimensions.width / layoutDimensions.width;
			if(!$.isWithinDiff(widthDifference, 1, 0.01)) {
				var images = layout.frames || layout.candids || layout.images;
				if(images) {
					for (var id in images) {
						var image = images[id];

						image.x *= widthDifference;
						image.width *= widthDifference;
					}
				}

				var texts = layout.texts;
				if(texts) {
					for(id in texts) {
						var text = texts[id];

						text.position.left *= widthDifference;
						if(text.manualSize) {
							text.manualSize.width *= widthDifference;
						}
					}
				}
			}

			var heightDifference = pageSetDimensions.height / layoutDimensions.height;
			if(!$.isWithinDiff(heightDifference, 1, 0.01)) {
				images = layout.frames || layout.candids || layout.images;
				if(images) {
					for (id in images) {
						image = images[id];

						image.y *= heightDifference;
						image.height *= heightDifference;
					}
				}

				texts = layout.texts;
				if(texts) {
					for(id in texts) {
						text = texts[id];

						text.position.top *= heightDifference;
						if(text.manualSize) {
							text.manualSize.height *= heightDifference;
						}
					}
				}
			}

			return layout;
		},
		getTheme: function () {
			return this.theme;
		},
		getThemeStylePart: function() {
			if(!this.pageSet) {
				return null;
			}

			var theme = this.pageSet.getTheme();
			if(!theme || !theme.extras) {
				return null;
			}

			var themePartName = this.getThemeStylePartName();
			return theme.extras[themePartName];
		},
		getThemePartName: function () {
			switch (this.getType()) {
				case 'cover':
					return 'Cover';
				case 'class':
				case 'classOverflow':
				case 'title':
					return 'Panel';
				case 'candid':
				case 'empty':
					return 'Candid';
				case 'autograph':
					return 'Autograph';
				case 'insideCover':
					return null;
				case 'index':
				case 'indexOverflow':
					return 'Index';
				default:
					return 'Preview';
			}
		},
		getThemeStylePartName: function() {
			return this.getThemePartName();
		},
		isThemeValid: function() {
			return !!this.theme && this.theme !== 'null';
		},
		setTheme: function (theme) {
			if (this.theme && this.theme.Background) {
				var id = this.getThemeId(this.theme);

				var backgroundNode = $('#backgroundNode-' + id);
				if (backgroundNode.length) {
					var removeBackgroundHandler = backgroundNode.data('removeBackground');
					if (removeBackgroundHandler) {
						removeBackgroundHandler(this);
					}
				}
			}

			if(theme && theme.Background && !theme.Background.id) {
				// If we have an invalid theme, we want to hide it instead of saving it null since the way this happens is usually an event consumption issue and NOT a saved theme issue
				this.theme = theme = null
			} else {
				this.propertyChange('theme', theme, false, true);
			}

			if(theme && theme.Background) {
				id = this.getThemeId(theme);

				backgroundNode = $('#backgroundNode-' + id);
				if(backgroundNode.length) {
					var addBackgroundHandler = backgroundNode.data('addBackground');
					if (addBackgroundHandler) {
						addBackgroundHandler(this);
					}
				}
			}
		},
		getThemeId: function(theme) {
			var id = theme.Background;
			if (id.id) {
				id = id.id;
			}

			return $.isPlainObject(id) ?  null : id;
		},
		updateTextThemeStyles: function(oldStyles, newStyles) {
			if(oldStyles.randomText || newStyles.randomText) {
				this.updateRandomTextThemeStyles(oldStyles.randomText, newStyles.randomText);
			}

			if(this.title && !this.title.style && (oldStyles.title || newStyles.title)) {
				var newTitle = this.updateTextObjectThemeStyles(this.title.lines ? this.title.lines : this.title, oldStyles.title, newStyles.title);
				if(newTitle) {
					this.setTitle({
						lines: newTitle
					});
				}
			}

			if(this.setPageMargins && (oldStyles.margins || newStyles.margins)) {
				this.updateMarginTheme(oldStyles.margins, newStyles.margins);
			}
		},
		updateRandomTextThemeStyles: function(oldStyles, newStyles) {
			for(var id in this.getTexts()) {
				var text = this.texts[id];

				if(text.lines) {
					var newLines = this.updateTextObjectThemeStyles(text.lines, oldStyles, newStyles);
					if (newLines) {
						this.setTextProperty(text.id, 'lines', newLines);
					}
				}
			}
		},
		updateTextObjectThemeStyles: function(origLines, oldStyles, newStyles) {
			if(!oldStyles) {
				oldStyles = {};
			}
			if(!newStyles) {
				newStyles = {};
			}

			if($.getObjectCount(oldStyles) === 0 && $.getObjectCount(newStyles) === 0) {
				return null;
			}

			if(typeof origLines === 'string') {
				if($.getObjectCount(newStyles)) {
					return $.extend({
						text: origLines
					}, newStyles);
				} else {
					// no new styles to apply
					return null;
				}
			} else {
				if(this.doTextObjectConflictWithTheme(origLines, oldStyles, newStyles)) {
					return null;
				} else {
					return this.updateTextObjectThemeStylesImpl(origLines, oldStyles, newStyles);
				}
			}
		},
		doTextObjectConflictWithTheme: function(origLines, oldStyles, newStyles) {
			// Make sure every part of every line matches this theme
			var lines = origLines;
			if(!lines) {
				return false;
			}

			if($.isPlainObject(lines)) {
				lines = [lines];
			}

			for(var i = 0; i < lines.length; i++) {
				var line = lines[i];
				var parts;
				if(line.parts) {
					parts = line.parts;
				} else {
					parts = [line];
				}
				for(var j = 0; j < parts.length; j++) {
					var part = parts[j];

					// Check if everything in oldStyle matches
					for (var prop in oldStyles) {
						if (oldStyles[prop] !== part[prop]) {
							return true;
						}
					}

					// Check to make sure newStyles doesn't override something from text but not in oldStyles
					for(prop in newStyles) {
						if(!oldStyles[prop] && part[prop] && part[prop] !== newStyles[prop]) {
							return true;
						}
					}
				}
			}

			return false;
		},
		updateTextObjectThemeStylesImpl: function(origLines, oldStyles, newStyles) {
			// We matched oldStyle, so update to newStyle
			var newLines, lines;
			if($.isPlainObject(origLines)) {
				newLines = $.extend(true, {}, origLines);
				lines = [newLines];
			} else {
				lines = newLines = $.extend(true, [], origLines);
			}

			for(var i = 0; i < lines.length; i++) {
				var line = lines[i];
				var parts;
				if(line.parts) {
					parts = line.parts;
				} else {
					parts = [line];
				}

				for(var j = 0; j < parts.length; j++) {
					var part = parts[j];

					// If they exist in newStyles, will be updated next
					for(var prop in oldStyles) {
						delete part[prop];
					}

					for(prop in newStyles) {
						part[prop] = newStyles[prop];
					}
				}
			}

			return newLines;
		},
		updateMarginTheme: function(oldMargins, newMargins) {
			if(!oldMargins) {
				oldMargins = {};
			}

			var margins = this.getExtraProperty('pageMargins', {}) || {};
			if($(margins).objectEquals(oldMargins)) {
				if(newMargins) {
					if (newMargins.horizontal) {
						newMargins.left = newMargins.horizontal;
						newMargins.right = newMargins.horizontal;
						delete newMargins.horizontal;
					}

					if (newMargins.vertical) {
						newMargins.top = newMargins.vertical;
						newMargins.bottom = newMargins.vertical;
						delete newMargins.vertical;
					}
				}
				
				this.setExtraProperty('pageMargins', newMargins);
			}
		},
		getType: function () {
			return this.type;
		},
		setPageNumber: function (pageNumber) {
			this.pageNumber = pageNumber;
		},
		incrementPageNumber: function(inc) {
			this.pageNumber += inc;
		},
		getPageNumber: function () {
			return this.pageNumber;
		},
		getPageNumberDisplay: function() {
			return 'page ' + (this.pageNumber - $.PAGE_OFFSET);
		},
		setLocked: function (locked) {
			this.locked = locked;
		},
		getLocked: function () {
			return this.locked;
		},
		setPageLabel: function (pageLabel) {
			this.pageLabel = pageLabel;
		},
		getPageLabel: function () {
			return this.pageLabel;
		},
		setFreeMovementLocked: function (locked) {
			if(locked) {
				locked = $.extend(true, {}, $.CurrentUser);
			}
			this.extraPropertyChange('freeMovementLocked', locked);
		},
		getLockedPopupMessage: function() {
			var message = '';
			var locked = this.getFreeMovementLocked();
			if(locked) {
				if($.isPlainObject(locked) && locked.name) {
					message = ' (Locked by ' + locked.name + ')';
				} else {
					message = ' (Locked)';
				}
			}

			return message;
		},
		getFreeMovementLocked: function () {
			return this.getExtraProperty('freeMovementLocked', false);
		},

		addText: function (id, position, extraStyle, extraSettings) {
			var newObj;
			// Allow passing just the instance as the only param, but keep backwards compat for all the existing code expecting the old format
			if($.isPlainObject(id)) {
				newObj = id;
				if(!newObj.id) {
					newObj.id = $.getGuid();
				}
				id = newObj.id;
			} else {
				newObj = $.extend({
					id: id,
					position: position,
					lines: {
						text: 'Type here'
					}
				}, extraSettings);
				if(extraStyle) {
					$.extend(newObj.lines, extraStyle);
				}
			}

			this.jsonPropertyChange('texts', id, newObj);
			return newObj;
		},
		setTextProperty: function (id, name, value) {
			var textObj = this.getText(id);
			if (textObj) {
				this.jsonSubPropertyChange('texts', id, name, value);
				return true;
			} else {
				return false;
			}
		},
		removeText: function (id) {
			this.jsonPropertyRemove('texts', id);
		},
		getTexts: function () {
			for(var id in this.texts) {
				if(!$.isInit(this.texts[id])) {
					delete this.texts[id];
				}
			}

			return this.texts;
		},
		getText: function (id, fireError) {
			if (this.texts[id]) {
				return this.texts[id];
			} else {
				// With multi users this now completely valid if removed while in the middle of typing
				return null;
			}
		},
		setTextArray: function(texts) {
			this.texts = {};
			for(var i = 0; i < texts.length; i++) {
				var text = texts[i];
				text.id = $.getUniqueId();
				this.texts[text.id] = text;
			}
		},
		copyTextStylesToOthers: function(id, copyProperties) {
			var texts = $.extend(true, {}, this.getTexts());

			for(var i in texts) {
				var text = texts[i];
				if(text.id == id) {
					continue;
				}

				this.copyTextStylesToText(text, copyProperties);
			}

			this.propertyChange('texts', texts, true, true);
		},
		copyTextStylesToText: function(text, copyProperties) {
			// If there is a bug don't let it screw up text objects!
			if($.isArray(copyProperties)) {
				return;
			}

			if(typeof text.lines == 'string') {
				text.lines = {
					text: text.lines
				};
			}

			var lines = text.lines;
			if(!$.isArray(lines)) {
				lines = [text.lines];
			}

			for(var j = 0; j < lines.length; j++) {
				var line = lines[j];

				var parts;
				if(line.parts) {
					parts = line.parts;
				} else {
					parts = [line];
				}

				for(var k = 0; k < parts.length; k++) {
					var part = parts[k];
					this.copyTextStylesToPart(part, copyProperties);
				}
			}
		},
		copyTextStylesToPart: function(part, copyProperties) {
			for(var prop in copyProperties) {
				if(prop == 'text') {
					continue;
				}

				var value = copyProperties[prop];
				part[prop] = value;
			}

			for(prop in part) {
				if(prop == 'text') {
					continue;
				}

				if(!$.isInit(copyProperties[prop])) {
					delete part[prop];
				}
			}
		},
		clearTexts: function() {
			this.jsonClear('texts');
		},
		hasDuplicateText: function(text) {
			if(text.text === '') {
				delete text.text;
			}

			var texts = this.getTexts();
			for(var id in texts) {
				var checkText = texts[id];

				if($(text).objectEquals(checkText, [
					'id', 'zIndex', 'groupedElements'
				], true)) {
					return true;
				}
			}

			return false;
		},
		getLockedTexts: function() {
			return [];
		},

		addCandid: function (candid) {
			if (!candid.id) {
				candid.id = $.getGuid();
			}

			this.jsonPropertyChange('candids', candid.id, candid);
		},
		removeCandid: function (id) {
			this.jsonPropertyRemove('candids', id);
		},
		setCandidProperty: function (id, name, value) {
			var candidObject = this.getCandid(id);
			if (candidObject) {
				var options = {};
				if($.isArray(name) && name.indexOf('photoVersion') !== -1 && name.indexOf('photo') === -1) {
					options.event = {
						permanent: true
					};
				}

				this.jsonSubPropertyChange('candids', id, name, value, options);
				return true;
			} else {
				return false;
			}
		},
		getCandids: function () {
			for(var id in this.candids) {
				if(!$.isInit(this.candids[id])) {
					delete this.candids[id];
				}
			}

			return this.candids;
		},
		getCandidsWithoutPhotos: function() {
			let candids = Object.values($.extend(true, {}, this.candids));
			candids = candids.map(candid => {
				['existingUrl', 'photo', 'photoWidth', 'photoHeight', 'photoVersion', 'photoVersions', 'photo_name', 'crop'].forEach(prop => delete candid[prop]);

				return candid;
			});

			let obj = {};
			candids.forEach(candid => obj[candid.id] = candid);
			return obj;
		},
		getCandid: function (id, fireError) {
			if (this.candids[id]) {
				return this.candids[id];
			} else {
				// With multi users this now completely valid if removed while in the middle of moving
				return null;
			}
		},
		setCandidArray: function(candids) {
			this.candids = {};
			for(var i = 0; i < candids.length; i++) {
				var candid = candids[i];
				candid.id = $.getUniqueId();
				this.candids[candid.id] = candid;
			}
		},
		clearCandids: function() {
			this.jsonClear('candids');
		},
		copyCandidEffectsToOthers: function(id, copyProperties) {
			let candids = $.extend(true, {}, this.candids);
			let candid = candids[id];
			if(candid) {
				let maxSize = Math.max(candid.width, candid.height);
				if(candid.border && candid.border.sizeScaling) {
					candid.border.sizeScaling = false;

					let startThickness = candid.border.thickness;
					candid.border.thickness = Math.max(1, Math.round(startThickness * 60 / 80 * maxSize));

					copyProperties.border = candid.border;
				}

				if(candid.dropShadow && candid.dropShadow.sizeScaling) {
					candid.dropShadow.sizeScaling = false;

					let startDepth = candid.dropShadow.depth;
					let startIntensity = candid.dropShadow.intensity;

					candid.dropShadow.depth = Math.max(2, Math.round(startDepth * 60 / 200 * maxSize));
					candid.dropShadow.intensity = Math.max(1, Math.round(startIntensity * 60 / 200 * maxSize));

					copyProperties.dropShadow = candid.dropShadow;
				}
			}

			for(let i in candids) {
				let candid = candids[i];
				if(!candid || candid.id == id) {
					continue;
				}

				this.copyCandidEffectsToCandid(candid, copyProperties);
			}

			// Special logic for groupBorder since it can apply across texts and candids and only adds one per group
			let texts = null;
			let saveTexts = false;
			if(candid && candid.groupedElements && candid.groupedElements.length) {
				texts = $.extend(true, {}, this.texts);
				let allContent = [...Object.values(candids), ...Object.values(texts)].filter(content => !!content);
				let groupedContent = allContent.filter(content => content.id === id || candid.groupedElements.includes(content.id));
				let groupBorder = groupedContent.reduce((groupBorder, content) => groupBorder || content.groupBorder, null);

				if(groupBorder) {
					let handledIds = [id, ...candid.groupedElements];
					allContent.forEach(content => {
						if(handledIds.includes(content.id) || !content.groupedElements || !content.groupedElements.length) {
							return;
						}

						content.groupBorder = groupBorder;
						if(texts[content.id]) {
							saveTexts = true;
						}

						handledIds.push(content.id);
						handledIds.push(...content.groupedElements);
					});
				} else {
					allContent.forEach(content => {
						if(content.groupBorder) {
							delete content.groupBorder;
							if(texts[content.id]) {
								saveTexts = true;
							}
						}
					});
				}
			}

			this.propertyChange('candids', candids, true, true);
			if(saveTexts && texts) {
				this.propertyChange('texts', texts, true, true);
			}
		},
		copyCandidEffectsToCandid: function(candid, copyProperties) {
			var properties = ['border', 'dropShadow', 'flipHorizontal', 'flipVertical', 'blur', 'brightness',
				'contrast', 'grayscale', 'hue', 'invert', 'opacity', 'saturate', 'sepia', 'mask'];

			for(var i = 0; i < properties.length; i++) {
				var prop = properties[i];
				var value = copyProperties[prop];
				if($.isInit(value)) {
					if($.isPlainObject(value)) {
						candid[prop] = $.extend(true, {}, value);
					} else {
						candid[prop] = value;
					}
				} else if($.isInit(candid[prop])) {
					delete candid[prop];
				}
			}
		},
		hasDuplicateCandid: function(candid) {
			var candids = this.getCandids();
			for(var id in candids) {
				var checkCandid = candids[id];

				if($(candid).objectEquals(checkCandid, [
					'id', 'zIndex', 'groupedElements'
				], true)) {
					return true;
				}
			}

			return false;
		},
		checkUpdateCandidProperties: function(candidId, properties) {
			var candids = this.getCandids();
			for(var id in candids) {
				var candid = candids[id];
				if(!candid || candid.photo !== candidId) {
					continue;
				}

				var names = Object.keys(properties).map(function(name) {
					if(name === 'version_id') {
						return 'photoVersion';
					} else if(name === 'version_ids') {
						return 'photoVersions';
					} else {
						return name;
					}
				});
				var values = Object.values(properties);
				if(names.includes('photoVersion')) {
					delete candid.existingUrl;
					
				}
				this.setCandidProperty(id, names, values);
			}
		},

		addComment: function (comment) {
			this.arrayPropertyPush('comments', comment, null, {
				permanent: true
			});
		},
		removeComment: function (comment) {
			var index = this.comments.indexOf(comment);
			if (index != -1) {
				this.comments.splice(index, 1);
				this.arrayPropertyChange('comments');
				return true;
			} else {
				return false;
			}
		},
		getComments: function () {
			return this.comments;
		},
		// For backwards compat
		isCommentsVisible: function() {
			return this.isCommentsEditable();
		},
		isCommentsEditable: function () {
			return this.commentsEditable;
		},

		getUserLabel: function(useDefault) {
			var label = this.getExtraProperty('userLabel');
			if(label) {
				return label;
			} else if(useDefault !== false) {
				return this.getDefaultUserLabel();
			}

			return label;
		},
		getDefaultUserLabel: function() {
			return (this.getPageNumber() - $.PAGE_OFFSET) + '';
		},
		setUserLabel: function(label, options) {
			this.extraPropertyChange('userLabel', label ? $.extend(true, {}, label) : null, options);
		},

		getStatisticsForSubject: function(subject) {
			var stats = {
				candids: []
			};

			var candidStatsForPhoto = this.getStatisticsForPhotos(subject.photos);
			for(var i = 0; i < candidStatsForPhoto.length; i++) {
				var candid = candidStatsForPhoto[i];
				stats.candids.push({
					page: this.pageNumber,
					photo: candid
				});
			}

			return stats;
		},
		getStatisticsForPhotos: function(photos) {
			var candids = [];
			if(this.candids && photos) {
				for(var id in this.candids) {
					var candid = this.candids[id];
					if(candid) {
						var index = photos.indexOfMatch(candid, 'id', candid.photo);
						if (index != -1) {
							var photo = photos[index];
							candids.push(photo);
						}
					}
				}
			}

			return candids;
		},
		isCandidAspectRatioEditable: function() {
			return true;
		},
		changeGridSize: function(grid) {
			if(this.layout) {
				var newLayout = $.extend(true, {}, this.layout, {
					grid: grid
				});

				if(newLayout.separateGrids) {
					var margins = this.getPageMargins() || {};
					var maxWidth = grid.width - ($.PAGE_BLEED || 0.125) * 2 - (margins.left || margins.gutter || 0) - (margins.right || margins.outside || 0);
					var maxHeight = grid.height - ($.PAGE_BLEED || 0.125) * 2 - (margins.top || 0) - (margins.bottom || 0);
					newLayout.separateGrids.forEach(function(otherGrid) {
						if(otherGrid.width > maxWidth) {
							otherGrid.width = maxWidth;
						}

						if(otherGrid.height > maxHeight) {
							otherGrid.height = maxHeight;
						}
					});
				}

				this.setLayout(newLayout, true, true);
			}

			var saveCandids = false;
			var candids = this.getCandids();
			if(candids) {
				for(var id in candids) {
					var candid = candids[id];

					// Reposition to be within page limits
					if (candid.x + candid.width > (grid.width - 0.25)) {
						candid.x = grid.width - candid.width - 0.25;
						saveCandids = true;
					}

					if (candid.y + candid.height > (grid.height - 0.25)) {
						candid.y = grid.height - candid.height - 0.25;
						saveCandids = true;
					}
				}
			}

			if(saveCandids) {
				this.propertyChange('candids', this.candids, true, true);
			}
		},

		copyPage: function() {
			this.copyFromPage.apply(this, arguments);
		},
		copyFromPage: function(page, contentToCopy) {
			if(!contentToCopy) {
				contentToCopy = {};
			}
			if(contentToCopy.layout !== false) {
				this.setLayout($.extend(true, {}, page.getLayout()), undefined, true);
			}

			if(contentToCopy.theme !== false) {
				var theme = page.getTheme();
				if (theme) {
					theme = $.extend(true, {}, theme);
				}
				this.setTheme(theme);
			}

			if(contentToCopy.candids !== false) {
				var candids = $.extend(true, {}, page.getCandids());
				this.jsonReplace('candids', candids);
			}

			if(contentToCopy.texts !== false) {
				var texts = $.extend(true, {}, page.getTexts());
				this.jsonReplace('texts', texts);
			}

			// Update label css variable directly if we have one
			if(contentToCopy.labelStyles !== false && page.getStudentLabelCSS && this.setStudentLabelCSS) {
				var studentLabelCSS = page.getStudentLabelCSSStyles();
				this.setStudentLabelCSSStyles(studentLabelCSS);
			}
			if(contentToCopy.portraitEffects !== false && page.getStudentMask && this.setStudentMask) {
				this.setStudentMask(page.getStudentMask());
			}

			var extras = $.extend(true, {}, this.extras);
			if(contentToCopy.margins !== false) {
				if(page.getPageMargins && this.setPageMargins) {
					var pageMargins = page.getPageMargins();
					if(pageMargins && $.getObjectCount(pageMargins) > 0) {
						extras.pageMargins = $.extend(true, {}, pageMargins);
					} else if(extras.pageMargins) {
						extras.pageMargins = null;
					}
				}
			}

			var copyExtras = ['subjectEffects', 'backgroundSettings'];
			if(contentToCopy.portraitEffects === false) {
				copyExtras.removeItem('subjectEffects');
			}
			if(contentToCopy.theme === false) {
				copyExtras.removeItem('backgroundSettings');
			}
			for(var i = 0; i < copyExtras.length; i++) {
				var name = copyExtras[i];

				var extraProperty = page.getExtraProperty(name);
				if(extraProperty) {
					if($.isPlainObject(extraProperty)) {
						extras[name] = $.extend(true, {}, extraProperty);
					} else {
						extras[name] = extraProperty;
					}
				}
			}
			if(contentToCopy.layout !== false) {
				delete extras.cachedGrid;
			}
			this.propertyChange('extras', extras, null, true);

			if(contentToCopy.layout !== false && this.getOverflowPage && this.getOverflowPage()) {
				let overflowPage = this.getOverflowPage();
				while(overflowPage) {
					if(overflowPage.extras.cachedGrid) {
						let extras = $.extend(true, {}, overflowPage.extras);
						delete extras.cachedGrid;

						overflowPage.propertyChange('extras', extras, null, true);
					}

					if(overflowPage.getOverflowPage) {
						overflowPage = overflowPage.getOverflowPage();
					} else {
						overflowPage = null;
					}
				}
			}
		},
		createLayoutTemplate: function(options) {
			options = $.extend({
				stripCandids: true,
				extraProperties: []
			}, options);

			var layout = {};

			layout.texts = $.extend(true, {}, this.getTexts());
			layout.images = $.extend(true, {}, this.getCandids());
			if(this.theme) {
				layout.theme = $.extend(true, {}, this.theme);
			}

			if(options.stripCandids) {
				var DELETE_CANDID_PROPERTIES = ['photo', 'existingUrl', 'photo_name', 'photoWidth', 'photoHeight', 'crop', 'photoVersion', 'photoVersions'];
				for(var id in layout.images) {
					var candid = layout.images[id];

					DELETE_CANDID_PROPERTIES.forEach(function(prop) {
						if(candid[prop] !== undefined) {
							delete candid[prop];
						}
					});
				}
			}

			if(this.pageSet) {
				layout.grid = this.pageSet.getInnerDimensions();
			}
			if(this.extras && $.getObjectCount(this.extras) > 0) {
				var extras = {};

				var COPY_EXTRA_PROPERTIES = ['backgroundSettings', ...options.extraProperties];
				var copyExtras = this.extras;
				COPY_EXTRA_PROPERTIES.forEach(function(name) {
					if(copyExtras[name]) {
						if($.isPlainObject(copyExtras[name])) {
							extras[name] = $.extend(true, {}, copyExtras[name]);
						} else {
							extras[name] = copyExtras[name];
						}
					}
				});

				if($.getObjectCount(extras) > 0) {
					layout.extras = extras;
				}
			}

			return layout;
		},
		mergeWithLayout: function(layout, options) {
			options = $.extend(true, {
				groupContent: false
			}, options);

			let existingZIndexes = [...Object.values(this.candids), ...Object.values(this.texts)].filter(instance => !!instance).map(instance => instance.zIndex || 0);

			var candids = layout.images || layout.candids || layout.frames || {};
			var texts = layout.texts || {};
			let newZIndexes = [...Object.values(candids), ...Object.values(texts)].filter(instance => !!instance).map(instance => instance.zIndex || 0);
			let hasDuplicateZIndex = newZIndexes.find(newZIndex => existingZIndexes.includes(newZIndex));
			let zIndexInc = 0;
			if($.isInit(hasDuplicateZIndex)) {
				let maxExistingZIndex = Math.max(...existingZIndexes);
				let minNewZIndex = Math.min(...newZIndexes);

				zIndexInc = maxExistingZIndex - minNewZIndex + 1;
				// Don't think this should be needed, but I've been wrong plenty of times...
				if(zIndexInc < 0) {
					zIndexInc = 0;
				}
			}

			var id;
			var newCandids = $.extend(true, {}, this.getCandids());
			var newIds = [];
			if($.isArray(candids)) {
				candids.forEach(function(candid) {
					var id = $.getUniqueId();
					newCandids[id] = $.extend(true, {
						id: id,
						zIndex: (candid.zIndex || 0) + zIndexInc
					}, candid);
					newIds.push(id);
				});
				candids = newCandids;
			} else {
				for(id in candids) {
					var candid = candids[id];
					id = $.getUniqueId();
					candid.id = id;
					candid.zIndex = (candid.zIndex || 0) + zIndexInc;
					newCandids[id] = candid;
					newIds.push(id);
				}
			}

			var newTexts = $.extend(true, {}, this.getTexts());
			if($.isArray(texts)) {
				texts.forEach(function(text) {
					var id = $.getUniqueId();
					newTexts[id] = $.extend(true, {
						id: id,
						zIndex: (text.zIndex || 0) + zIndexInc
					}, text);
					newIds.push(id);
				});
				texts = newTexts;
			} else {
				for(id in texts) {
					var text = texts[id];
					id = $.getUniqueId();
					text.id = id;
					text.zIndex = (text.zIndex || 0) + zIndexInc;
					newTexts[id] = text;
					newIds.push(id);
				}
			}

			// If we are grouping, update each new content to be grouped together
			if(options.groupContent) {
				for(id in newCandids) {
					if(newIds.indexOf(id) !== -1) {
						newCandids[id].groupedElements = newIds.filter(function(newId) {
							return newId != id;
						});
					}
				}
				for(id in newTexts) {
					if(newIds.indexOf(id) !== -1) {
						newTexts[id].groupedElements = newIds.filter(function(newId) {
							return newId != id;
						});
					}
				}
			}

			this.jsonReplace('candids', newCandids);
			this.jsonReplace('texts', newTexts);
		},
		getTextStyles: function(text) {
			if(typeof text == 'string') {
				return {};
			} else if(text && text.lines) {
				if(typeof text.lines == 'string') {
					return {};
				}

				var line;
				if($.isArray(text.lines)) {
					line = $.extend(true, {}, text.lines[0]);
				} else if($.isPlainObject(text.lines)) {
					line = $.extend(true, {}, text.lines);
				}

				var part;
				if(line.parts) {
					part = line.parts[0];
				} else {
					part = line;
				}

				if(part.text) {
					delete part.text;
				}

				return part;
			} else {
				return {};
			}
		},
		getCanHaveBarcodes: function() {
			return this.canHaveBarcodes;
		},
		getMaxZIndex: function() {
			var maxZIndex = 0;

			var candids = this.getCandids();
			for(var id in candids) {
				var candid = candids[id];

				if(candid.zIndex) {
					maxZIndex = Math.max(maxZIndex, candid.zIndex);
				}
			}

			var texts = this.getTexts();
			for(id in texts) {
				var text = texts[id];
				
				if(text.zIndex) {
					maxZIndex = Math.max(maxZIndex, text.zIndex);
				}
			}

			return maxZIndex;
		},
		showSubjectLabelFontSizeMultiplier: function() {
			return false;
		},
		getPageReference: function() {
			if(this.pageSet && this.pageSet.referencePagesBy != 'pageNumber') {
				if(typeof this[this.pageSet.referencePagesBy] === 'function') {
					return this[this.pageSet.referencePagesBy]();
				} else {
					return this[this.pageSet.referencePagesBy];
				}
			} else {
				return this.pageNumber - $.PAGE_OFFSET;
			}
		},
		setBackgroundSettings: function(backgroundSettings) {
			this.setExtraProperty('backgroundSettings', backgroundSettings);
		},
		forceGrayscale: function() {
			if(this.pageSet && this.pageSet.forceGrayscale()) {
				return true;
			}
			
			return this.getExtraProperty('grayscale', false);
		},
		shouldKeepGridSize: function() {
			return this.keepGridSize;
		},
		shouldKeepNameOrder: function() {
			return this.keepNameOrder;
		},

		mergePageOptions: function(options) {
			for(var name in options) {
				var value = options[name];

				// Direct property update
				if($.isInit(this[name])) {
					this.setProperty(name, value);
				}
				// Otherwise, add to extras
				else {
					this.setExtraProperty(name, value);
				}
			}
		},
		onAdd: function() {
			var candids = this.getCandids();
			for(var id in candids) {
				var candid = candids[id];

				if(candid.photo) {
					var candidNode = $('#candidNode-' + candid.photo);
					if (candidNode.length) {
						var addCandidHandler = candidNode.data('addCandid');
						if(addCandidHandler) {
							addCandidHandler(this);
						}
					}

					if($.candidsCategories && $.candidsCategories.updateCachedDataAdded) {
						$.candidsCategories.updateCachedDataAdded(candid.photo, this);
					}
				}
			}
		},
		onRemove: function() {
			var candids = this.getCandids();
			for(var id in candids) {
				var candid = candids[id];

				if(candid.photo) {
					var candidNode = $('#candidNode-' + candid.photo);
					if (candidNode.length) {
						var removeCandidHandler = candidNode.data('removeCandid');
						if(removeCandidHandler) {
							removeCandidHandler(this);
						}
					}

					if($.candidsCategories && $.candidsCategories.updateCachedDataRemoved) {
						$.candidsCategories.updateCachedDataRemoved(candid.photo, this);
					}
				}
			}
		},
		getFieldRep: function (field, primarySubjects) {
			var subjects;
			if(primarySubjects) {
				if($.isArray(primarySubjects)) {
					subjects = primarySubjects;
				} else {
					subjects = [primarySubjects];
				}
			} else if(this.getSubjects) {
				subjects = this.getSubjects();
			}
			
			if(!subjects) {
				return null;
			}

			field = field.replace(/%/ig, '');
			var options = {};
			for (var i = 0; i < subjects.length; i++) {
				var val = subjects[i][field];
				if ($.isInit(val)) {
					if (options[val]) {
						options[val]++;
					} else {
						options[val] = 1;
					}
				}
			}

			if ($.getObjectCount(options)) {
				return $.sortByValues(options, false)[0].key;
			} else {
				return null;
			}
		},
		getBatchExtras: function() {
			var grade = this.getFieldRep('Grade');
			var teacher = this.getFieldRep('Teacher');
			var homeRoom = this.getFieldRep('Home Room');

			var extras = [];
			if(teacher) {
				extras.push('Teacher: ' + teacher);
			} else if(homeRoom) {
				extras.push('Home Room: ' + homeRoom);
			}
			if(grade) {
				extras.push('Grade: ' + grade);
			}

			return extras;
		},

		setPageNumberCSS: function(css) {
			this.pageNumberCSS = this.createPageNumberCSSBundle(css);
			
			if(this.db) {
				this.db.queueChange({
					scope: 'pages',
					name: this.getId(),
					value: {
						pageNumberCSS: JSON.stringify(css)
					}
				});

				if(this.db.userEvents) {
					obj.db.userEvents.addEvent({
						context: [obj, 'pageNumberCSS', 'css'],
						action: 'update',
						args: [null, css]
					});
				}
			}
		},
		clearPageNumberCSS: function() {
			let oldPageNumberCSS = this.pageNumberCSS;
			if(!oldPageNumberCSS) {
				return;
			}

			this.pageNumberCSS = null;
			if(this.db) {
				this.db.queueChange({
					scope: 'pages',
					name: this.getId(),
					value: {
						pageNumberCSS: null
					}
				});

				if(this.db.userEvents) {
					obj.db.userEvents.addEvent({
						context: [obj, 'pageNumberCSS', 'css'],
						action: 'update',
						args: [oldPageNumberCSS.css, null]
					});
				}
			}
		},
		createPageNumberCSSBundle: function(css) {
			if(typeof css === 'string') {
				css = JSON.parse(css);
			}
			if(!css || $.isArray(css)) {
				css = {};
			}

			return new $.CSSBundle(function(name, value, oldValue) {
				if(obj.db) {
					obj.db.queueChange({
						scope: 'pages',
						name: obj.getId(),
						value: {
							pageNumberCSS: JSON.stringify(this.css)
						}
					});
	
					if(obj.db.userEvents) {
						var oldObj = {};
						oldObj[name] = oldValue;
						var newObj = {};
						newObj[name] = value;
	
						obj.db.userEvents.addEvent({
							context: [obj, 'pageNumberCSS', 'css'],
							action: 'update',
							args: [oldObj, newObj]
						});
					}
				}
			}, css);
		},

		getOldCandidsFromPage: function() {
			return Object.values(this.getCandids()).filter(image => !!image.photo);
		},
		populatePlaceholdersFromPage: function(definition) {
			let oldPageCandids = this.getOldCandidsFromPage();
			let newPageFrames = [];
			if(definition) {
				let definitionFrames = null;
				if(definition.frames) {
					definitionFrames = definition.frames;
				} else if (definition.candids) {
					definitionFrames = definition.candids;
				} else if (definition.images) {
					definitionFrames = definition.images;
				}

				if(definitionFrames) {
					if($.isArray(definitionFrames)) {
						newPageFrames = [...definitionFrames];
					} else if($.isPlainObject(definitionFrames)) {
						newPageFrames = Object.values(definitionFrames);
					}
				}
			}
			let newPagePlaceholders = newPageFrames.filter(image => !image.photo && !image.field && !image.photoFieldMap && !image.shape);
			newPagePlaceholders.forEach(newPagePlaceholder => {
				if(!oldPageCandids.length) {
					return;
				}

				let oldPageCandid = oldPageCandids.pop();
				['photo', 'photo_name', 'photoWidth', 'photoHeight', 'photoVersion', 'photoVersions', 'existingUrl'].forEach(prop => {
					if(oldPageCandid[prop]) {
						newPagePlaceholder[prop] = oldPageCandid[prop];
					}
				});

				// Auto crop images to fit in placeholder correctly
				if(newPagePlaceholder.photoWidth && newPagePlaceholder.photoHeight) {
					let frameWidth = newPagePlaceholder.width;
					let frameHeight = newPagePlaceholder.height;
					let frameRatio = frameWidth / frameHeight;
					let imgRatio = newPagePlaceholder.photoWidth / newPagePlaceholder.photoHeight;
					if(Math.abs(imgRatio - frameRatio) > 0.001) {
						// If wider frame, use width
						let width, height, left, top;
						if (frameRatio > imgRatio) {
							width = frameWidth;
							height = frameWidth / imgRatio;
							left = 0;
							top = (frameHeight - height) / 2;
						}
						// If taller frame, use height
						else {
							width = frameHeight * imgRatio;
							height = frameHeight;
							left = (frameWidth - width) / 2;
							top = 0;
						}

						newPagePlaceholder.crop = {
							width: width / newPagePlaceholder.width,
							height: height / newPagePlaceholder.height,
							top: top / newPagePlaceholder.height,
							left: left / newPagePlaceholder.width,
							percentage: true
						};
					}
				}
			});
		},

		doesContentOverlapSubjectLabels: function() {
			return this.contentOverlapSubjectLabels;
		},
		showPageNumber: function() {
			return this.getExtraProperty('showPageNumbers') !== false;
		},
		refreshLockedTextsOnMove: function() {
			return false;
		},
		shouldAddCaptionsAutomatically: function() {
			return false;
		},
		shouldHideInsidePageBorder: function() {
			return false;
		},
		shouldShowGreenScreenOptions: function() {
			return false;
		},
		canToggleContentLocks: function() {
			return true;
		},
		canEditSubjectDetails: function() {
			return false;
		},
		canHideCell: function() {
			return false;
		},
		isIndividualizedLayout: function() {
			return false;
		},
		shouldKeepSizeWhenChangingEffects: function() {
			return false;
		},
		shouldRemoveImageFromPlaceholder: function() {
			return false;
		},
		shouldSwapCandidsWhenDragging: function() {
			return false;
		},

		id: $.getUniqueId(),
		title: '',
		texts: {},
		candids: {},
		type: 'generic',
		locked: false,
		extras: {},
		comments: [],
		commentsEditable: false,
		contextAlias: 'page',
		canHaveBarcodes: false,
		keepGridSize: false,
		keepNameOrder: false,
		pageNumber: 1,
		contentOverlapSubjectLabels: true,

		alternativeVersions: {},
		viewingVersion: null,
		disableRotateSwapWidthAndHeight: true,
		openSpreadOnEditCenterContent: false
	}, settings);
	
	return obj;
};