<template>
	<div class="menu" :class="menuClasses" :style="menuStyles" :depth="depth">
		<div class="sticky" v-if="search">
			<div class="ui icon search fluid input search-input">
				<input name="search" type="text" autocomplete="off" :placeholder="searchPlaceholder" :value="searchInput" @keyup="onSearch" @paste="onSearch" ref="search">
				<i class="search icon" v-if="!searchInput"></i>
				<i class="link close icon" @click="clearSearch" v-else></i>
			</div>

			<div class="ui toggle checkbox toggle-not-in-use" ref="toggleNotInUse" v-if="toggleNotInUse">
				<input type="checkbox" name="toggleNotInUse">
				<label>{{ i18n('photoPicker.toggleNotInUseLabel') }}</label>
			</div>

			<div class="divider"></div>
		</div>
		<category-dropdown-item v-for="item in items" :key="item.buttonId"
			:item="item"
			:depth="depth"
			:left-dropdown="leftDropdown"
			:selected-id="selectedId"
			@select-item="selectItem"
			@hover-start="hoverStart" />
	</div>
</template>

<script>
import CategoryDropdownItem from './CategoryDropdownItem.vue';

export default {
	props: ['items', 'isVisible', 'depth', 'top', 'bottom', 'selectedId', 'leftDropdown', 'search', 'searchInput', 'toggleNotInUse'],
	computed: {
		menuClasses() {
			return {
				transition: true,
				animating: true,
				slide: true,
				down: true,
				visible: this.isVisible,
				in: this.isVisible,
				hidden: !this.isVisible,

				// TODO: Doesn't actually work because hidden gets added immediately
				out: !this.isVisible,
				depth0: this.depth === 0 && this.leftDropdown
			}
		},
		menuStyles() {
			if(this.depth > 0) {
				let props = {};
				if(this.bottom) {
					props.bottom = 'calc(-100% + ' + -this.bottom + 'px)';
					props.top = 'initial';
				} else if(this.top) {
					props.top = 'calc(100% + ' + this.top + 'px)';
				}

				// Left dropdown needs to limit first menu to width so it doesn't go off edge of the screen
				if(this.leftDropdown) {
					if(this.leftDropdown) {
						props.right = 'calc(100% + ' + (14 * (this.depth - 1)) + 'em - ' + (0.5 * this.depth) +'em)';
						props.left = 'initial';
					} else {
						props.left = 'calc(100% + ' + (14 * (this.depth - 1)) + 'em - ' + (0.8 * this.depth) +'em)';
					}
				} else {
					if(this.leftDropdown) {
						props.right = (14 * this.depth) + 'em';
						props.left = 'initial';
					} else {
						props.left = (13 * this.depth) + 'em';
					}
				}
				return props;
			} else {
				return {};
			}
		},

		searchPlaceholder() {
			return this.search?.placeholder ?? 'Search...';
		}
	},
	methods: {
		selectItem(item) {
			if(!item.subCategories) {
				this.$emit('select-item', item);
			}
		},

		hoverStart(item, depth) {
			this.$emit('hovered', item, depth);
		},
		onSearch(e) {
			this.$emit('on-search', e);
		},
		clearSearch() {
			this.$refs.search.dispatchEvent(new KeyboardEvent('keyup', {
				keyCode: 27,
			}));
		}
	},
	updated() {
		let toggle = this.$refs.toggleNotInUse;
		if(toggle && !window.$(toggle).hasClass('isSetup')) {
			window.$(toggle).checkbox({
				onChecked: () => {
					this.$emit('change-not-in-use', true);
				},
				onUnchecked: () => {
					this.$emit('change-not-in-use', false);
				}
			}).popup({
				content: this.i18n('photoPicker.toggleNotInUseTooltip')
			}).addClass('isSetup');
		}
	},
	components: {
		CategoryDropdownItem
	}
};
</script>

<style scoped>
.menu {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 30rem;
	max-height: min(30rem, calc(100vh - 12em));

	width: 14em;
}
.menu.depth0 {
	width: 100%;
}

.menu::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 10px;
}
.menu::-webkit-scrollbar-thumb {
	background: #BBB;
	box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.menu::-webkit-scrollbar-thumb:hover {
	background: #06F;
}

.emphasize {
	font-style: italic;
}

.toggle-not-in-use {
	margin-left: 0.75em;
	margin-right: 0.75em;
}

.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0;

	background: white;
	z-index: 20;
	padding-top: 1px;
}
.sticky .input {
	margin: 1.14285714rem .78571429rem;
}
.sticky .divider {
	border-top: 1px solid rgba(34,36,38,.1);
	height: 0;
	margin: 0.5em 0;
}
</style>