<template>
	<div>
		<div class="ui message" v-if="visible.length === 0">{{ emptyMessage }}</div>
		<slot v-bind:visible="visible" />
		<div class="pager" style="display: flex; align-items: center; justify-content: center; margin-top: 1em;" v-if="totalPages > 1">
			<div class="ui icon button" :class="{ disabled: page <= 1 }" @click="firstPage" v-if="hasManyPages"><i class="fast backward icon"/></div>
			<div class="ui icon button" :class="{ disabled: page <= 1 }" @click="previousPage" style="margin-right: 0.5em"><i class="step backward icon"/></div>
			<span>{{ pagingDisplay }}</span>
			<div class="ui icon button" :class="{ disabled: page >= totalPages }" @click="nextPage" style="margin-left: 0.5em"><i class="step forward icon"/></div>
			<div class="ui icon button" :class="{ disabled: page >= totalPages }" @click="lastPage" v-if="hasManyPages"><i class="fast forward icon"/></div>
		</div>
		<div class="ui active inverted dimmer" v-if="loading"><div class="ui text loader">Loading</div></div>
	</div>
</template>

<script>
export default {
	props: {
		data: {
			default: []
		},
		perPage: {
			default: 20
		},
		loading: {
			default: false
		},
		emptyMessage: {
			default: 'Nothing to display'
		}
	},
	data: function() {
		return {
			page: 1
		};
	},
	// If parent updates data, make sure we aren't on a bogus page
	watch: {
		data: function() {
			this.page = 1;
		}
	},
	computed: {
		visible: function() {
			var start = (this.page - 1) * this.perPage;
			return this.data.slice(start, start + this.perPage);
		},
		pagingDisplay: function() {
			return 'Page ' + this.page + ' of ' + this.totalPages;
		},
		totalPages: function() {
			return Math.ceil(this.data.length / this.perPage);
		},
		hasManyPages: function() {
			return this.totalPages > 5;
		}
	},
	methods: {
		firstPage: function() {
			this.page = 1;
		},
		previousPage: function() {
			this.page = Math.max(1, this.page - 1);
		},
		nextPage: function() {
			this.page = Math.min(this.totalPages, this.page + 1);
		},
		lastPage: function() {
			this.page = this.totalPages;
		}
	}
};
</script>