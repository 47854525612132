<template>
	<div>
		<v-list>
			<v-list-item v-for="word in visibleWords" :key="word.word">
				<v-list-item-content class="text">
					{{ word.word }}
					<v-list-item-subtitle>{{ formatCreatedAt(word.createdAt) }}</v-list-item-subtitle>
				</v-list-item-content>
				<v-btn color="red" dark small @click="removeWord(word)"><v-icon>clear</v-icon></v-btn>
			</v-list-item>

			<v-list-item>
				<v-list-item-content>
					<v-text-field v-model="newWord" placeholder="New word to add to the dictionary" class="new-word-box" v-on:keyup.enter="addWord" @input="checkValidWord" />
				</v-list-item-content>
				<v-btn color="green" class="add-word-button" small @click="addWord" :disabled="!newWord" :loading="savingNewWord"><v-icon>add</v-icon></v-btn>
			</v-list-item>
		</v-list>
	</div>
</template>

<script>
import { appClient } from '../utils/axios';
import moment from 'moment';

export default {
	props: ['orgId', 'dictionaryWords'],
	data() {
		return {
			newWord: '',
			savingNewWord: false
		};
	},
	computed: {
		visibleWords() {
			return this.dictionaryWords.filter(dictionaryWord => dictionaryWord.orgId == this.orgId);
		}
	},
	methods: {
		formatCreatedAt(date) {
			return 'Added ' + moment(date).fromNow();
		},
		checkValidWord(newValue) {
			this.newWord = newValue.replace(/\s/g, '');
		},
		removeWord(word) {
			this.dictionaryWords.removeItem(word);
			this.$emit('removed-word', word);

			appClient.post('ajax/deleteDictionaryWord.php', {
				orgId: this.orgId,
				word: word.word
			});
		},
		async addWord() {
			if(this.savingNewWord || !this.newWord) {
				return;
			}

			this.savingNewWord = true;
			let response = await appClient.post('ajax/saveDictionaryWord.php', {
				orgId: this.orgId,
				word: this.newWord.replace(/\s/g, '').toLowerCase()
			});

			this.savingNewWord = false;
			this.newWord = '';
			let newWord = response.data.word;
			this.dictionaryWords.push(newWord);
			this.$emit('added-word', newWord);
		}
	}
};
</script>

<style scoped>
.text {
	text-align: left;
}

.new-word-box {
	max-width: 30em;
}
.add-word-button {
	margin-left: 1em;
}
</style>