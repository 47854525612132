$.FlowErrorPage = function(settings) {
	var obj = new $.FlowPage();

	$.extend(obj, {
		type: 'error-page',
		getTexts: function() {
			throw new Error('Oh no!');
		}
	}, settings);

	return obj;
};

$.FlowErrorClassPage = function(settings) {
	var subjects = [];
	for(var i = 0; i < 100; i++) {
		subjects.push({
			id: $.getUniqueId(),
			'First Name': $.createRandomString(5),
			'Last Name': $.createRandomString(5),
			'Grade':'03',
			'Teacher':'Belote',
			photos: [
				{
					id: '220d589f-d9d1-49b0-8811-34d10cddd5ab',
					category: 'individual_category',
					cdn_url: 'css/images/image-placeholder.png',
					width: 800,
					height: 1000,
					tags: [],
					upload_verified_at: '2015-08-19T23:12:36Z',
					created_at: '2017-10-28T16:44:30.251Z'
				},
				{
					id: '220d589f-d9d1-49b0-8811-34d10cddd5ac',
					category: 'candid_category',
					cdn_url: 'css/images/image-placeholder.png',
					tags: [],
					upload_verified_at: '2015-08-19T23:12:36Z',
					created_at: '2017-10-28T16:44:30.251Z'
				}
			],
			createdAt: '2017-10-28T16:44:30.251Z',
			updatedAt: '2017-10-28T16:44:30.251Z'
		});
	}
	var classObj = {
		id: Math.round(Math.random() * 100000),
		name: $.createRandomString(5),
		subjects: subjects,
		subjectCount: subjects.length
	};
	var obj = new $.FlowPageClass(classObj);

	$.extend(obj, {
		type: 'error-class-page'
	}, settings);

	return obj;
};

