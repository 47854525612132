$.PreLoadImages = function(images) {
	var start = new Date().getTime();
	var chain;
	if($.PreLoadImagesUtils.executionChain) {
		chain = $.PreLoadImagesUtils.executionChain;
	} else {
		chain = new $.ExecutionChain(function () {
			var end = new Date().getTime();
			var seconds = (end - start) / 1000;

			$.PreLoadImagesUtils.executionChain = null;
			$.PreLoadImagesUtils.images = {};
			// eslint-disable-next-line
			console.info('Done preloading all images: ' + seconds.toFixed(1) + ' sec');
		});
	}

	for(var i = 0; i < images.length; i++) {
		var image = images[i];
		var img = new Image();
		chain.add(img, [image.url]);
		$.PreLoadImagesUtils.images[image.url] = img;
	}

	if(!$.PreLoadImagesUtils.executionChain) {
		chain.done();
		$.PreLoadImagesUtils.executionChain = chain;
	}
};
$.CancelPreLoadImage = function(url) {
	if($.PreLoadImagesUtils.executionChain && $.PreLoadImagesUtils.images[url]) {
		var img = $.PreLoadImagesUtils.images[url];
		$.PreLoadImagesUtils.executionChain.remove(img);
	}
};