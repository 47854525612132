import Vue from 'vue';

import CategoryDropdown from '../components/category-dropdown/CategoryDropdown.vue';
Vue.component('category-dropdown', CategoryDropdown);
import MoveToCategoryDropdown from '../components/category-dropdown/MoveToCategoryDropdown.vue';
Vue.component('move-to-category-dropdown', MoveToCategoryDropdown);

import '../common/legacy/pages';
import '../common/legacy/subject-management';
import '../common/legacy/data';
import '../common/legacy/load';

import '../../js/dynamic-slider';
import '../../css/dynamic-slider.css';
import '../../js/layout-preview.js';
import '../../css/layout-preview.css';
import '../../js/settings-builder.js';
import '../../css/settings-builder.css';
import '../../js/comments.js';
import '../../css/comments.css';
import '../../js/photo-picker.js';
import '../../css/photo-picker.css';
import '../../js/changelog.js';
import '../../css/changelog.css';
import '../../js/ext/modals.js';