$.FlowLayoutRotatable = function(me, options) {
	$.extend(me, {
		setRotable: function(enabled) {
			if(enabled) {
				$(this).addClass('flowLayoutRotatable');
				if(!this.rotationHandle) {
					this.rotationHandle = $('<div class="rotationHandle">').appendTo(this);
					this.rotationHandle.on('mousedown', function(event) {
						me.onStartRotate(event);

						me.startRotate(event, function() {
							me.onRotate(event);
						}, function() {
							me.onStopRotate();
						});

						event.preventDefault();
						return false;
					});
				}
				this.rotationHandle.show();
			} else {
				$(this).removeClass('flowLayoutRotatable');
				if(this.rotationHandle) {
					this.rotationHandle.hide();
				}
			}
		},
		startRotate: function (e, onChange, onStop) {
			var draggableEnabled = $(this).hasClass('ui-draggable') && !$(this).hasClass('ui-draggable-disabled');
			if(draggableEnabled) {
				$(this).draggable('disable').removeClass('ui-state-disabled');
			}

			let box = this.getBoundingClientRect();
			let startCenterX = box.left + box.width / 2;
			let startCenterY = box.top + box.height / 2;
			let startRotation = this.getRotation(false) || 0;
			let startAngle = Math.atan2(startCenterY - e.clientY, startCenterX - e.clientX);

			this.lastRotateTime = new Date().getTime();
			var mousemove = function (e) {
				let radChange = Math.atan2(startCenterY - e.clientY, startCenterX - e.clientX) - startAngle;
				let angleChange = radChange / (Math.PI / 180);
				let newAngle = startRotation + angleChange;
				if(newAngle < 0) {
					newAngle = 360 + newAngle;
				}
				newAngle = newAngle % 360;

				var rotation = $.anchorWithinDiff(newAngle, [0, 90, 180, 270, 360], me.anchorRotationWithin);
				$(me).css({transform: 'rotate(' + rotation + 'deg)'});
				if(me.updateResizableHandles) {
					me.updateResizableHandles(rotation);
				}
				me.lastRotateTime = new Date().getTime();
				if (onChange) {
					onChange.call(me);
				}
			};
			var mouseup = function () {
				me.lastRotateTime = new Date().getTime();
				if (draggableEnabled && $(me).isAttached()) {
					$(me).draggable('enable');
				}
				$(document).off('mousemove', mousemove);
				$(document).off('mouseup', mouseup);
				if (onChange) {
					onChange.call(me);
				}
				if (onStop) {
					onStop.call(me);
				}
			};

			$(document).on('mousemove', mousemove);
			$(document).on('mouseup', mouseup);
		},
		getRotation: function(radians) {
			if(this.instance && this.instance.transform) {
				return $.FlowLayoutRotatableUtils.getRotation(this.instance.transform, radians);
			} else if(this.style.transform) {
				return $.FlowLayoutRotatableUtils.getRotation(this.style.transform, radians);
			} else {
				return 0;
			}
		},
		rotatePoint: function(x, y, degrees, cx, cy) {
			if(!cx) {
				cx = 0;
			}
			if(!cy) {
				cy = 0;
			}

			var radians = (Math.PI / 180) * degrees;
			var cos = Math.cos(radians);
			var sin = Math.sin(radians);
			var nx = (cos * (x - cx)) + (sin * (y - cy)) + cx;
			var ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;

			return {
				x: nx,
				y: ny
			};
		},
		rotateRect: function(x, y, width, height, rotation) {
			var topLeft = this.rotatePoint(x, y, rotation, x + (width / 2), y + (height / 2));
			var topRight = this.rotatePoint(x + width, y, rotation, x + (width / 2), y + (height / 2));
			var bottomLeft = this.rotatePoint(x, y + height, rotation, x + (width / 2), y + (height / 2));
			var bottomRight = this.rotatePoint(x + width, y + height, rotation, x + (width / 2), y + (height / 2));

			var newLeft = Math.min(topLeft.x, topRight.x, bottomLeft.x, bottomRight.x);
			var newRight = Math.max(topLeft.x, topRight.x, bottomLeft.x, bottomRight.x);
			var newTop = Math.min(topLeft.y, topRight.y, bottomLeft.y, bottomRight.y);
			var newBottom = Math.max(topLeft.y, topRight.y, bottomLeft.y, bottomRight.y);

			return {
				x: newLeft,
				y: newTop,
				width: newRight - newLeft,
				height: newBottom - newTop
			};
		},

		getRotatableToolDistance: function() {
			var minDistance = 10;

			if(this.rotatable) {
				var myTop = this.getBoundingClientRect().top;
				var maxTopDiff = myTop;
				if(this.getSecondaryFocusedElements) {
					this.getSecondaryFocusedElements().forEach(function(secondaryElement) {
						maxTopDiff = Math.min(maxTopDiff, secondaryElement.getBoundingClientRect().top);
					});
				}

				var diff;
				var layoutTop = 0;
				if(this.wrapper && this.wrapper.getBoundingClientRect) {
					layoutTop = this.wrapper.getBoundingClientRect().top;
					diff = maxTopDiff - layoutTop;
				} else {
					diff = $(this).getFloatStyle('top');
				}

				if(diff < 40) {
					var topDiff = myTop - maxTopDiff;
					var distance = diff + minDistance + topDiff;
					if(distance < 0 && distance < -window.innerHeight) {
						return 0;
					} else {
						return distance;
					}
				} else if(maxTopDiff < myTop) {
					return (myTop - maxTopDiff) + minDistance;
				} else {
					return 40;
				}
			} else {
				return minDistance;
			}
		},

		anchorRotationWithin: 5
	}, options);

	$.registerEvents(me, [
		'startRotate',
		'rotate',
		'stopRotate'
	]);
};

$.FlowLayoutRotatableUtils = {
	getRotation: function(transform, radians) {
		var rotate = 0;

		if(transform) {
			var rotateText = 'rotate(';
			var startIndex = transform.indexOf(rotateText);
			if(startIndex != -1) {
				rotate = transform.substr(startIndex + rotateText.length);
				try {
					rotate = parseFloat(rotate);
				} catch (e) {
					rotate = 0;
					console.error(e);
				}
			}
		}

		if(rotate === 360) {
			rotate = 0;
		}

		if(radians) {
			return rotate * (Math.PI / 180);
		} else {
			return rotate;
		}
	}
};