$.cachePhotos = function(options, photos) {
	if(photos.length) {
		photos.caseInsensitiveSort('upload_file_name');
		var cachePhotos = photos.map(function (photo) {
			var forceVersionId = null;
			if(photo.version_ids && photo.version_ids.length > 1) {
				if($.primaryGraphicPhotoVersion === 'original') {
					forceVersionId = photo.version_ids[0];
				} else {
					forceVersionId = photo.version_ids[photo.version_ids.length - 1];
				}

				if(forceVersionId === null) {
					forceVersionId = 'null';
				}
			}

			return {
				id: photo.id,
				presigned_get: photo.presigned_get,
				upload_verified_at: photo.upload_verified_at,
				force_version_id: forceVersionId
			}
		});

		var ajax = {
			url: 'ajax/cacheAlbumPhotos.php',
			type: 'post',
			data: {
				photos: cachePhotos,
				jobId: options.jobId,
				layoutId: options.layoutId,
				previewQuality: (options.previewQuality || 300)
			},
			dataType: 'json',
			timeout: 30000,
			success: function (photoUpdates) {
				for (var i = 0; i < photoUpdates.length; i++) {
					var photo = photos[i];
					var photoData = photoUpdates[i];

					for (var prop in photoData) {
						if (prop == 'id') {
							if (photo.id != photoData.id) {
								$.fireErrorReport(null, 'Photo ids dont match', 'Photo ids dont match', {
									photo: photo,
									photoData: photoData
								});

								if (options.error) {
									options.error();
								}
								return
							}
							continue;
						}

						photo[prop] = photoData[prop];
					}

					if (photo.cdn_url) {
						delete photo.presigned_get;
					}
					if(!photo.used) {
						photo.used = {
							count: 0,
							pages: []
						}
					}
				}

				if (options.success) {
					options.success(photos);
				}
			},
			error: function () {
				if (options.error) {
					options.error();
				}
			}
		};

		if(options.chain) {
			options.chain.add(ajax);
		} else {
			$.proxyAjax(ajax);
		}
	} else {
		if (options.success) {
			options.success(photos);
		}
	}
};