$.SubjectManagementBatch = function(options) {
	var div = $('<div class="subjectManagementBatch">')[0];

	$.extend(div, {
		loadSubjects: function (batch) {
			if (!this.job) {
				throw new Error('Must set a job before loading subjects');
			} else if (!this.job.fieldValues) {
				throw new Error('Must load jobs fieldValues before loading subjects');
			}
			this.currentBatch = batch;
			if (!batch) {
				this.clear();
				return;
			}

			this.startLoading();
			this.currentBatch = batch;

			this.subjects = batch.subjects;
			let i = 0;
			var startCount = 20;
			if(this.editable && !this.searching && !this.errorMessage) {
				this.cardList.append(new $.SubjectManagementAddCard(this, this.job));
				startCount--;
			}

			for (; i < this.subjects.length && i < startCount; i++) {
				var subjectCard = new $.SubjectManagementCard(this.subjects[i], {
					job: this.job,
					batch: this.currentBatch,
					individualizedOption: this.individualizedOption,
					editable: this.editable,
					list: this
				});
				this.cardList.append(subjectCard);
			}
			this.subjectIndex = i;

			this.setupVisibility();
			this.setSortable(this.editable);
			if(this.parent) {
				this.parent.initializeSticky();
			}
			this.stopLoading();
		},
		setJob: function (job) {
			this.job = job;
		},
		setSortable: function (sortable) {
			if (sortable && this.currentBatch && this.currentBatch.id != -1) {
				if (!this.sortable) {
					var me = this;
					this.cardList.sortable({
						containment: 'parent',
						tolerance: 'pointer',
						helper: function(event, card) {
							var clone = $(card).clone();
							clone.css('position', 'absolute');
							return clone;
						},
						start: function (event, ui) {
							var next = ui.placeholder.next();
							if (next.position().left == ui.placeholder.position().left) {
								ui.placeholder.height(next.height() + 1);
							} else {
								ui.placeholder.height(0);
							}
						},
						change: function (event, ui) {
							var next = ui.placeholder.next();
							if (next.position().left == ui.placeholder.position().left) {
								ui.placeholder.height(next.height() + 1);
							} else {
								ui.placeholder.height(0);

								// If after we do this the positions are the same, then we should have mached after all
								if (next.position().left == ui.placeholder.position().left) {
									ui.placeholder.height(next.height() + 1);
								}
							}
						},
						update: function (event, ui) {
							if (ui.position.left != ui.originalPosition.left || ui.position.top != ui.originalPosition.top) {
								me.saveSubjectOrder();
							}
						},
						items: '.subjectCard',
						delay: 100
					});
					this.sortable = true;
				}
			} else {
				if (this.sortable) {
					this.cardList.sortable('destroy');
					this.sortable = false;
				}
			}
		},
		resortSubject: function (card, save) {
			var subject = card.subject;
			if(!subject) {
				console.error('no subject to resort', card);
				return;
			}

			var cards = this.cardList.find('.subjectCard');
			var subjects = [];
			for (let i = 0; i < cards.length; i++) {
				subjects.push(cards[i].subject);
			}
			// Add non-lazy loaded subjects onto the back
			var currentIndex = this.subjectIndex;
			for (; currentIndex < this.subjects.length; currentIndex++) {
				subjects.push(this.subjects[currentIndex]);
			}

			var newIndex = this.getSubjectSortedPosition(subjects, subject);

			var removed = false;
			if ($(card).isAttached(this)) {
				if ((newIndex - 1) == cards.index(card)) {
					return;
				}
				$(card).detach();
				removed = true;
			}

			if (newIndex == 0) {
				if(cards.length === 0) {
					$(card).appendTo(this.cardList);
				} else if(cards.eq(0).index() === 0) {
					$(card).prependTo(this.cardList);
				} else {
					$(card).insertBefore(cards.eq(0));
				}
			} else {
				var reLookupCards = false;
				while(newIndex > this.subjectIndex) {
					// Load subjects until we can see this card
					// Want to be careful to exit if we can't actually add anything anymore
					if(!this.loadMoreSubjects(true)) {
						break;
					}

					reLookupCards = true;
				}

				if(reLookupCards) {
					cards = this.cardList.find('.subjectCard');

					// Change index since we no longer are accounting for this card in it
					if(removed) {
						newIndex--;
					}
				}

				$(card).insertAfter(cards.eq(newIndex - 1));
			}

			if($(card).isAttached(this)) {
				// Show the card that we just added
				$('html, body').stop().animate({
					scrollTop: $(card).offset().top - 100
				}, 400);
				$(card).flashRed();
			}

			if (save) {
				this.saveSubjectOrder();
			}
		},
		getSubjectSortedPosition: function (compareSubjects, sortSubject) {
			var sortBy = this.parent ? this.parent.job.sortBy : null;
			return $.SubjectManagement.getSubjectSortedPosition(compareSubjects, sortSubject, sortBy);
		},
		updateBatchSubjects: function () {
			var subjects = [];
			var saveSubjects = [];
			this.cardList.find('.subjectCard').each(function () {
				var subject = $(this).data('subject');
				if (subject && $.isInit(subject.id)) {
					subjects.push(subject);

					saveSubjects.push($.SubjectManagement.getAppSpecificSubjectData(subject));
				}
			});
			// Add non-lazy loaded subjects onto the back
			let i = this.subjectIndex;
			for (; i < this.subjects.length; i++) {
				var subject = this.subjects[i];
				subjects.push(subject);
				saveSubjects.push($.SubjectManagement.getAppSpecificSubjectData(subject));
			}
			this.subjects = this.currentBatch.subjects = subjects;

			return saveSubjects;
		},
		saveSubjectOrder: function () {
			var startSubjects = this.getBatchSpecificSubjects();
			var subjects = this.updateBatchSubjects();

			if (this.currentSaveSubjectOrder) {
				this.ignoreNextError = true;
				this.currentSaveSubjectOrder.abort();
				this.currentSaveSubjectOrder = null;
			}

			var me = this;
			this.currentSaveSubjectOrder = $.ajax({
				url: 'ajax/saveBatch.php',
				dataType: 'json',
				data: {
					batches: JSON.stringify([
						{
							batchId: this.currentBatch.id,
							subjects: subjects
						}
					])
				},
				type: 'POST',
				success: function () {
					me.currentSaveSubjectOrder = null;
				},
				error: function () {
					if (me.ignoreNextError) {
						me.ignoreNextError = false;
						return;
					}

					me.currentSaveSubjectOrder = null;
					$.Alert('Error', 'Failed to save changes to subject order');
				}
			});

			var endSubjects = this.getBatchSpecificSubjects();
			this.parent.userEvents.addEvent({
				action: 'update',
				args: [startSubjects, endSubjects],
				context: ['batches', this.currentBatch.id, 'subjects']
			});
		},
		getBatchSpecificSubjects: function() {
			return this.subjects.map(function(subject) {
				return $.SubjectManagement.getAppSpecificSubjectData(subject);
			});
		},
		setSubjectFieldValues: function (fieldValues) {
			this.fieldValues = fieldValues;
		},
		getCommonFieldValue: function (name) {
			if (!this.subjects || this.subjects.length == 0) {
				return null;
			}

			// Get lists of counts
			var count = {};
			for (let i = 0; i < this.subjects.length; i++) {
				var subject = this.subjects[i];

				var val = subject[name];
				if (val) {
					if (count[val]) {
						count[val]++;
					} else {
						count[val] = 1;
					}
				}
			}

			// Get max count
			let i = null;
			var max = 0;
			for (var x in count) {
				if (count[x] > max) {
					i = x;
					max = count[x];
				}
			}

			// Make sure max is at least 80% of the batch
			if (max && max >= (this.subjects.length * 0.8)) {
				return i;
			} else {
				return null;
			}
		},
		setSubjectAlbum: function (album) {
			this.subjectAlbum = album;
		},
		getSubjectAlbum: function () {
			return this.subjectAlbum;
		},
		setEditable: function (editable) {
			this.editable = editable;
		},
		setupVisibility: function () {
			if($(this.cardList).hasClass('activeVisibility')) {
				return;
			}

			var div = this;
			// NOTE: Anything that breaks this probably breaks PhotoPicker as well!
			$(this.cardList).visibility({
				once: false,
				observeChanges: true,
				// Go to batch with < 20, then back to a batch with more while able to display everything on screen without scrollbar.  Will not ever call onBottomVisible without this
				continuous: true,
				context: window.visibilityScrollRelativeTo || $('body'),
				onBottomVisible: function () {
					div.loadMoreSubjects();
				}
			}).addClass('activeVisibility');
		},
		loadMoreSubjects: function(forceMore) {
			var currentTime = new Date().getTime();
			if (!this.subjects || (this.lastBottomVisible && (currentTime - this.lastBottomVisible) < 100 && forceMore !== true)) {
				return;
			}

			// Load another 20
			let i = this.subjectIndex;
			var documentFragment = document.createDocumentFragment();
			for (; i < this.subjects.length && i < (this.subjectIndex + 20); i++) {
				try {
					var subjectCard = new $.SubjectManagementCard(this.subjects[i], {
						job: this.job,
						batch: this.currentBatch,
						individualizedOption: this.individualizedOption,
						editable: this.editable,
						list: this
					});
					documentFragment.appendChild(subjectCard);
				} catch (e) {
					console.error('Failed to add subject card', e);
				}
			}
			this.cardList.append(documentFragment);

			var updated = false;
			if(this.subjectIndex != i) {
				this.subjectIndex = i;
				updated = true;

				if(this.parent) {
					this.parent.initializeSticky();
				}
			}
			this.lastBottomVisible = new Date().getTime();

			return updated;
		},
		refreshSizing: function() {
			this.cardList.removeClass('three four');
			var windowWidth = $(window).width();
			var cardAmount = 'four';
			if(windowWidth < 1500) {
				cardAmount = 'three';
			}

			this.cardList.addClass(cardAmount);
		},
		clear: function () {
			this.subjects = [];
			this.subjectIndex = 0;
			this.cardList.empty();

			if (this.currentAjax) {
				this.ignoreNextError = true;
				this.currentAjax.abort();
			}
			this.stopLoading();
		},
		startSearching: function () {
			this.searching = true;
		},
		stopSearching: function () {
			this.searching = false;
		},
		isSearching: function () {
			return this.searching;
		},

		onConsumeBatchEvent: function(event) {
			if(event.context[0] === 'batches') {
				if(this.currentBatch && this.currentBatch.id == event.context[1]) {
					if(event.context.length >= 3 && event.context[2] === 'subjects') {
						if(event.context.length === 3) {
							if(event.action === 'remove') {
								$(this).find('#subject' + event.args[0].id).remove();
							} else {
								// TODO: Handle this crap more gracefully...
								this.loadSubjects(this.currentBatch);
							}
						} else if(event.context.length === 4) {
							var card = $(this).find('#subject' + event.context[3])[0];
							if(card) {
								card.onConsumeSubjectEvent(event);
							}
						}
					}
				}
			}
		},

		startLoading: function (dontEmpty) {
			this.loading = true;
			if (this.errorMessage) {
				this.errorMessage.remove();
				this.errorMessage = null;
			}

			if (dontEmpty !== false) {
				this.cardList.find('.subjectCard').each(function () {
					this.destroy();
				});

				this.clear();
			}
			if (this.currentAjax) {
				this.ignoreNextError = true;
				this.currentAjax.abort();
				this.currentAjax = null;
			}
			this.loader.addClass('active');
		},
		stopLoading: function () {
			this.loading = false;
			this.loader.removeClass('active');
		},
		isLoading: function() {
			return this.loading;
		},
		error: function () {
			this.clear();
			this.stopLoading();

			this.errorMessage = $('<div class="ui inverted active dimmer"><div class="content"><div class="center"><i class="red huge warning sign icon"></i></div></div></div>');
			$(this).append(this.errorMessage);
		},
		isError: function() {
			return !!this.errorMessage && this.errorMessage.isAttached();
		},
		destroy: function() {
			if(!$(this.cardList).hasClass('activeVisibility')) {
				$(this.cardList).visibility('destroy');
			}

			this.cardList.find('.subjectCard').each(function () {
				this.destroy();
			});
		}
	});

	div.parent = options.parent;
	div.cardList = $('<div class="ui stackable doubling cards subjectCardList">');
	div.refreshSizing();
	$(div).append(div.cardList);
	$(div).click(function(e) {
		if($(e.target).hasClass('button')) {
			return;
		} else if(div.mostRecentCard && div.mostRecentCard.hasClass('blue') && div.mostRecentCard.hasClass('editing')) {
			// We are open for editing
			return;
		}

		var card = $(e.target).closest('.subjectCard');
		if(card.length && !card.hasClass('editing')) {
			if (div.editable) {
				if (e.shiftKey || e.ctrlKey) {
					var cards = $();
					if (div.mostRecentCard && e.shiftKey) {
						var startIndex = div.mostRecentCard.index();
						var endIndex = card.index();

						// We want this to be smaller -> larger so slice behaves correctly
						if (startIndex > endIndex) {
							var tmp = startIndex;
							startIndex = endIndex;
							endIndex = tmp;
						} else {
							startIndex++;
							endIndex++;
						}

						cards = $(div.cardList).find('.card').slice(startIndex, endIndex).filter('.subjectCard');
					} else if (e.ctrlKey && card.hasClass('blue')) {
						card.removeClass('blue');
					} else {
						cards = card;
					}

					cards.addClass('blue');
				} else {
					$(div.cardList).find('.subjectCard.blue').removeClass('blue');
					card.addClass('blue');
				}
				div.mostRecentCard = card;
			} else {
				$(div.cardList).find('.subjectCard.blue').removeClass('blue');
			}
		}
	});

	div.loader = $('<div class="ui inverted dimmer"><div class="ui loader"></div></div>');
	$(div).append(div.loader);

	div.errorMessage = null;
	div.loading = false;

	if(options && options.individualizedOption) {
		div.individualizedOption = true;
	}
	div.classes = [];
	div.classIndex = 0;

	$(window).resize(function() {
		div.refreshSizing();
	});

	return div;
};