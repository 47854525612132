$.Changelog = function(options) {
	var me = new Object();

	$.extend(me, {
		setChanges: function(changes) {
			var yearAgo = new Date();
			yearAgo.setMonth(yearAgo.getMonth() - 18);
			yearAgo = yearAgo.getTime();
			changes = changes.filter(function(change) {
				return new Date(change.date).getTime() > yearAgo;
			});

			changes.sort(function(a, b) {
				var aDate = new Date(a.date).getTime();
				var bDate = new Date(b.date).getTime();
				return bDate - aDate;
			});
	
			for(var i = 0; i < changes.length; i++) {
				var change = changes[i];
				var date = new Date(change.date);
				var now = new Date();
	
				if(date.getTime() > now.getTime()) {
					changes.splice(i, 1);
					i--;
				} else {
					break;
				}
			}

			this.changes = changes;
		},
		show: function(changesToShow, options) {
			if(!options) {
				options = {};
			}

			if(changesToShow === 0) {
				return;
			} else if(typeof changesToShow == 'undefined') {
				changesToShow = this.defaultChangesShown;
			}

			this.modal = $('<div class="ui modal">');
			$('<i class="close icon">').appendTo(this.modal);

			var header = $('<div class="header">');
			header.text('What\'s New');
			header.prepend('<i class="gift large icon"></i> ');
			this.modal.append(header);

			var content = $('<div class="ui content">');
			this.modal.append(content);

			this.list = $('<div class="ui divided list changelog">');
			this.createChangeList(Math.min(changesToShow, this.changes.length));
			if(options.showCloseButton) {
				this.closeButton = $('<div class="ui center aligned segment closeButton"><a class="ui item green button">Close</a></div>')
				this.modal.append(this.closeButton);
			} else if(changesToShow < this.changes.length) {
				this.showMoreButton = $('<div class="ui center aligned segment showbutton"><a class="ui item green button">Show All</a></div>')
				this.modal.append(this.showMoreButton);
			}
			content.append(this.list);

			$(this.list).find('img').each(function(i, img) {
				$(img).on('load', function() {
					me.modal.modal('refresh');
				});
			});
			this.modal.modal({
				onHidden: function () {
					$(this).remove();
				}
			}).modal('show');

			if(this.isNew()) {
				this.updateLastChangelog();
			}
			if(this.showMoreButton) {
				this.showMoreButton.on('click', function() {
					me.showMoreButton.remove();
					me.list.empty();
					me.createChangeList(me.changes.length);
					me.modal.modal('refresh');
				});
			} else if(this.closeButton) {
				this.closeButton.click(function() {
					me.modal.modal('hide');
				});
			}
		},
		createChangeList: function(changesToShow) {
			for(var i = 0; i < changesToShow; i++) {
				var change = this.changes[i];
				this.list.append(this.createChangeItem(change));
			}
		},
		shouldShow: function() {
			return this.changes && this.changes.length;
		},
		showNew: function() {
			var changeNum = 0;

			for(var i = 0; i < this.changes.length; i++) {
				if(this.isNew(this.changes[i])) {
					changeNum++;
				} else {
					break;
				}
			}
			me.show(changeNum, {
				showCloseButton: true
			});
		},
		isNew: function(change) {
			var current = $.userExtras.lastChangelog;
			if(!current) {
				this.updateLastChangelog();
				return false;
			}
			current = parseInt(current);

			if(!change) {
				change = this.changes[0];
			}
			var latest = new Date(change.date).getTime();
			
			return current < latest;
		},
		updateLastChangelog: function() {
			$.userExtras.lastChangelog = new Date().getTime();

			$.ajax({
				url: 'ajax/saveUserExtra.php',
				dataType: 'json',
				data: {
					name: 'lastChangelog',
					value: $.userExtras.lastChangelog
				},
				type: 'POST'
			});
		},
		createChangeItem: function(change) {
			var item = $('<div class="item">');

			var header = $('<div class="header">');
			var date = moment(new Date(change.date)).format('MMMM Do YYYY');
			var title = date;
			if(change.title) {
				title += ' - ' + change.title;
			}
			header.text(title);

			if(this.isNew(change)) {
				header.prepend('<i class="orange gift icon"/>');
			}

			if(change.content) {
				$('<div class="description">').html(change.content).appendTo(item);
			} else if(change.list) {
				item.append(this.createChangeItemList(change.list));
			} else if(change.image) {
				var img = $('<img>').attr('src', change.image);
				img.css({
					width: 400
				});
				item.append(img);
			}
			item.prepend(header);

			return item;
		},
		createChangeItemList: function(bullets) {
			var list = $('<div class="ui bulleted list">');

			for(var i = 0; i < bullets.length; i++) {
				var bullet = bullets[i];
				// We sometimes want to return null for permission based changelogs
				if(bullet) {
					$('<div class="item">').text(bullets[i]).appendTo(list);
				}
			}

			return list;
		},

		// Overridable stuff
		changes: [],
		defaultChangesShown: 3
	}, options);

	if(options.changes && $.isArray(options.changes)) {
		me.setChanges(options.changes);
	}

	return me;
};