$.FlowLayoutRow = function(definition, ratio, options) {
	var wrapper = document.createElement('div');
	wrapper.className = 'flowRow';

	$.extend(wrapper, {
		addCell: function (cell) {
			this.cells.push(cell);
			this.appendChild(cell);

			// If name container defined, add a label for it
			if(this.nameContainer && !(this.parent && this.parent.definition && this.parent.definition.cell && this.parent.definition.cell.name === 'none')) {
				var label = cell.createLabel();
				cell.label = label;
				label.rowLabel = true;
				cell.nameOrder = this.nameOrder;
				label.directlyOnCell = false;
				// Breaks more exotic fonts like Rock Salt
				// cell.label.applyCalculatedRenderedDimensions = true;
				$(label).appendTo(this.nameContainer);

				if(this.cells.length == 1) {
					this.nameContainer.style.marginTop = $(cell).getFloatStyle('margin') + 'px';
				}

				this.changeNameContainer();
			}
		},
		changeCellCount: function (count, cellDefinition) {
			var difference = this.cells.length != count;

			// Remove any extra cells
			while (this.cells.length > count) {
				let cell = this.cells.pop();
				cell.destroy();

				// Make sure to remove cell's attached label if we have side name container
				if(this.nameContainer && cell.label) {
					$(cell.label).remove();
				}

				$(cell).remove();
			}

			// Add new cells if this.cells.length != count
			var cellAdded = false;
			while (count > this.cells.length) {
				this.addCell(new $.FlowLayoutCell($.extend({wrapper: this.parent}, cellDefinition), this.ratio, {
					row: this,
					cellIndex: this.cells.length
				}));
				cellAdded = true;
			}

			if(this.nameContainer && !cellAdded) {
				this.changeNameContainer();
			}
			this.updateCellsHidden();

			return difference;
		},
		changeCells: function (count, cellDefinition) {
			// Remove any extra cells
			while (this.cells.length > count) {
				let cell = this.cells.pop();
				if(!cell) {
					continue;
				}
				cell.destroy();

				// Make sure to remove cell's attached label if we have side name container
				if(this.nameContainer && cell.label) {
					$(cell.label).remove();
				}

				$(cell).remove();
			}

			for(let i = 0; i < this.cells.length; i++) {
				this.cells[i].changeDefinition(cellDefinition);
			}

			// Add new cells if this.cells.length != count
			var cellAdded = false;
			while (count > this.cells.length) {
				this.addCell(new $.FlowLayoutCell($.extend({wrapper: this.parent}, cellDefinition), this.ratio, {
					row: this,
					cellIndex: this.cells.length
				}));
				cellAdded = true;
			}

			this.cells.forEach(function(cell) {
				cell.updateWavePosition();
			});
			this.updateCellsHidden();

			if(this.nameContainer && !cellAdded) {
				this.changeNameContainer();
			}
		},
		updateCellsHidden: function() {
			var layout = this.parent.getLayout();
			if(!layout || !layout.hiddenCells) {
				return;
			}

			this.cells.forEach(function(cell) {
				cell.updateCellHidden();
			});
		},
		changeNameContainer: function() {
			var resetDisplay;
			if(this.nameContainer.style.display != 'none') {
				this.nameContainer.style.display = 'none';
				resetDisplay = true;
			}

			// Don't ever let the container be larger then the row - other contents!
			var rowRect = this.getBoundingClientRect();
			var rowWidth = rowRect.width + $(this).getFloatStyle('marginRight') + $(this).getFloatStyle('marginLeft');
			var parentWidth = this.parent.canvasMargins.getBoundingClientRect().width;
			$(this.nameContainer).css('max-width', (parentWidth - rowWidth) - (this.leftNameContainerPadding + this.rightNameContainerPadding));

			// Don't let set of labels be bigger than cells
			var nameLabels = $(this.nameContainer).children('.nameLabel');
			var maxContainerHeight = rowRect.height - $(this.nameContainer).getFloatStyle('margin-top');
			nameLabels.css('max-height', maxContainerHeight / nameLabels.length);

			if(resetDisplay) {
				this.nameContainer.style.display = '';
			}
		},

		addUsers: function (users, options, returnParams) {
			var allowBreak = options && options.allowBreak;
			var allowHide = users.length > 0 && (!options || !options.blockHide);
			var count = 0, allowFill = true, start = 0, midBreak = -1, cell;
			this.groupByValue = null;

			// We are starting over, remove hidden cells that were only hidden because we couldn't fit a large in it
			// Only start over if we are not passing in startParams (meaning not a continuation of filling this stuff out)
			if(!options || !options.startParams) {
				$(this).find('.tempHiddenCell:not(.behindFrame)').removeClass('hiddenCell').removeClass('tempHiddenCell');
			}

			if(this.nameContainer) {
				if(users.length) {
					this.nameContainer.style.display = '';
				}

				$(this).css({
					position: '',
					width: ''
				});
				$(this.nameContainer).css({
					width: '',
					position: '',
					left: '',
					right: ''
				});
			}

			var largeCell = this.parentNode.largeCell;
			var largeCellCol = 0;
			if(largeCell) {
				if(largeCell.col == 'center') {
					largeCellCol = Math.floor(this.cells.length / 2) - 1;
				} else if(options && typeof options.largeCellCol != 'undefined') {
					largeCellCol = options.largeCellCol;
				} else {
					largeCellCol = largeCell.col;
				}
			}

			// See if there are any teacher which need special cells setup
			var lastUser = null;
			var largeCells = [];
			// If we passed in what this already found, use those instead
			if(options && options.startParams) {
				start = options.startParams.start;
				midBreak = options.startParams.midBreak;
			} else if(largeCell && options.largeCellUser !== -1) {
				if(largeCellCol == -1) {
					start = this.cells.length - largeCell.colSpan;
				} else if(largeCellCol < -1) {
					largeCellCol = 0;
				}

				while(count < users.length && start < this.cells.length && start >= 0) {
					var user;
					if(options && options.largeCellUser) {
						user = users[count + options.largeCellUser];
					} else {
						user = users[count];
					}

					// If requires a teacher and this isn't a teacher, stop
					if(largeCell.require == 'teacher' && user && !user['Teacher Priority']) {
						break;
					}
					// If a max is defined, only go until count is more than that
					else if(largeCell.max && options.largeCellCount && options.largeCellCount >= largeCell.max) {
						break;
					}
					// If starting in middle, set that up
					else if(start == 0 && largeCellCol > 0) {
						// If this is a teacher row, start from the col pos
						start = largeCellCol;
						if(start >= (this.cells.length - 1)) {
							var resetLargeCell = start == largeCellCol;
							start = this.cells.length - 2;

							// Reset largeCell position since it is obviously wrong
							if(resetLargeCell) {
								largeCell.col = start;
							}
						}

						var nextCellsInFrame = false;
						var nextCell = this.cells[start];
						while(nextCell) {
							nextCellsInFrame = $(nextCell).hasClass('behindFrame') || nextCell.getOverlapCells(largeCell.colSpan, largeCell.rowSpan).filter('.behindFrame').length > 0;
							nextCell = nextCell.nextSibling;
							if(!nextCellsInFrame) {
								break;
							}
						}
						if(nextCellsInFrame) {
							largeCellCol = -1;
							start = this.cells.length - largeCell.colSpan;
						} else {
							// We also need to fill in everything before this point later on
							midBreak = start;
						}
					}

					if(user && user.classBreak && allowBreak) {
						// Two teachers which are in two different batches in a row
						if(count > 0) {
							allowFill = false;
							break;
						}

						// If addTitleBreak returns true, this row was just removed
						// Don't try to add any users since they won't be viewable after!
						var classBreakRow = this;
						if(options && options.classBreakRow) {
							classBreakRow = options.classBreakRow;
						}

						options.isTitleBreak = true;
						var addTitleBreakVal = classBreakRow.addTitleBreak(user.classBreak);
						if(addTitleBreakVal) {
							if(addTitleBreakVal === 2) {
								for(let j = 0; j < classBreakRow.cells.length; j++) {
									classBreakRow.cells[j].setUser(null);
								}
							}
							return users;
						}
					}

					cell = this.cells[start];
					if(!cell) {
						$.fireErrorReport(null, 'Null cell', 'Null cell', {
							cellLength: this.cells.length,
							start: start,
							count: count,
							usersLength: users.length
						});
					}

					if(this.addUser(cell, user, allowFill, allowHide)) {
						// Don't try to put a large cell on the end!
						var isLargeCell = (largeCellCol >= 0 && $(cell).next().length) || largeCellCol == -1;

						// Check if one of the overlap cells for this is hidden behind a frame
						if(isLargeCell) {
							var futureOverlapCells = cell.getOverlapCells(largeCell.colSpan, largeCell.rowSpan);
							if(futureOverlapCells.filter('.behindFrame, .hiddenCell:not(.tempHiddenCell)').length > 0) {
								isLargeCell = false;
							}
						}

						if(isLargeCell) {
							cell.setLargeCell(largeCell);

							largeCells.push(cell);
							count++;
							if(largeCellCol == -1) {
								start -= largeCell.colSpan;
							} else {
								start += largeCell.colSpan;
							}

							if(options) {
								if(typeof options.largeCellCount != 'undefined') {
									options.largeCellCount++;
								}
								if(options.largeCellUser) {
									// Remove the user right away since later will cut out from start
									count--;
									start--;
									users.splice(count + options.largeCellUser, 1);
								}
							}
							lastUser = user;
						} else {
							cell.setUser(null);
							$(cell).addClass('hiddenCell tempHiddenCell');
							if(largeCellCol == -1) {
								start--;
							} else {
								start++;
							}

							if(midBreak >= 0 && largeCell.require != 'teacher') {
								midBreak++;
							}

							if(largeCell && largeCell.col == 'center') {
								cell.setLargeCell(null);
								$(cell).removeClass('tempHiddenCell');
								if(!$(cell).data('behindFrames').length) {
									$(cell).removeClass('hiddenCell');
								}
								return users.slice(count);
							}
						}
					} else {
						// If it failed, just increment by 1 column
						if(largeCellCol == -1) {
							start--;
						} else {
							start++;
						}

						// We only want to show the ones on the same row
						// This hacky logic is to allow single setp increments, while still keeping subsequent rows with no students
						var restoreSet = cell.overlappedCells.filter($(this).find('.flowCell'));
						cell.changeOverlapVisibility(false, restoreSet);

						// Everything not shown still needs to be in the list of overlapped cells
						cell.overlappedCells = cell.overlappedCells.not($(this).find('.flowCell'));

						if(largeCell && largeCell.col == 'center') {
							cell.setLargeCell(null);
							$(cell).removeClass('tempHiddenCell');
							if(!$(cell).data('behindFrames').length) {
								$(cell).removeClass('hiddenCell');
							}
							return users.slice(count);
						}
					}
				}
			}

			// If we are only looking to fill out large cell, exit now
			if(options && options.onlyFillLargeCell) {
				if(returnParams) {
					returnParams.start = start;
					returnParams.midBreak = midBreak;
				}

				return users.slice(count);
			}

			// if require is *, we want to fill in these blank spots
			if(largeCell && largeCell.require == '*') {
				$(this).find('.tempHiddenCell').removeClass('hiddenCell').removeClass('tempHiddenCell');
			}

			var jStart, jEnd, midBreakStart = 0, midBreakEnd = 0;
			if(largeCell && largeCellCol == -1) {
				jStart = 0;
				jEnd = start + largeCell.colSpan;
			} else {
				if(midBreak > 0) {
					jStart = 0;
					midBreakStart = midBreak;
					midBreakEnd = start;
				} else {
					jStart = start;
				}

				jEnd = this.cells.length;
			}

			// TODO: Break out into separate functions
			var breakUser = users[count];
			if(breakUser && breakUser.classBreak) {
				// Single teacher in batch so we have two class breaks in a row
				if(count > 0) {
					allowFill = false;
				} else {
					// We don't want to allow breaks on the first line
					if(allowBreak) {
						options.isTitleBreak = true;
						// If addTitleBreak returns true, this row was just removed
						// Don't try to add any users since they won't be viewable after!
						if(this.addTitleBreak(breakUser.classBreak)) {
							return users;
						}
					}
					// A class break on the first line needs to update the page's title
					else {
						var page = this.parent.getPage();
						if(page) {
							var classObj = breakUser.classBreak;
							// Don't update if that page already has custom text set
							if(!page.title) {
								var title = page.getExtraTitle(classObj.id);

								// If no title already set, set this as most recent break from previous page
								if(page.parentPage) {
									var previousPage = page.parentPage;
									previousPage.setLastClass(classObj);
									
								}
								if(!title) {
									title = '%batch%';
									page.setExtraTitle(classObj.id, title);

									if(page.parentPage) {
										previousPage.setLastTitle(title);
									}
								}

								// Update title no matter what we decide it is
								this.parent.setTitle(title);
							} else if(page.parentPage) {
								previousPage = page.parentPage;
								previousPage.setLastClass(classObj);
							}
						}
					}
				}
			}
			// If breakUser 0 is not a title break, make sure to remove any existing ones
			else if(count == 0 && this.titleRows.length > 0 && (!options || !options.blockRemoveTitleBreak)) {
				this.removeTitleBreak();
			}

			if(jStart === 0 && users.length && options.layout && options.layout.cell && options.layout.cell.alignCells &&
					(options.layout.cell.alignCells == 'right' || (options.layout.cell.alignCells == 'outside' && options.side == 'Right') || (options.layout.cell.alignCells == 'inside' && options.side == 'Left'))) {
				var startCol = this.getRightAlignedStartColumn(users, count, jEnd, midBreakStart, midBreakEnd);

				if(startCol > 0) {
					// Make sure to clear ones before it
					for(let j = 0; j < startCol; j++) {
						let cell = this.cells[j];

						cell.setUser(null);
						if(cell.colSpan > 1) {
							cell.setLargeCell(null);
						}
					}

					jStart = startCol;
					largeCellCol = null;
				}
			}

			// Go through the normal cells in this row and fill them in
			for(let j = jStart; j < jEnd && j < this.cells.length; j++) {
				// If we defined a mid break, skip this stuff
				if(midBreakStart && j >= midBreakStart && j < midBreakEnd) {
					continue;
				}

				// Don't try to redo cells already done in the large cell step
				// This is needed for when the cell is right aligned!
				if(largeCells.indexOf(this.cells[j]) != -1) {
					continue;
				}

				let user = users[count];
				// This teacher should be large but can't be here
				if(largeCell && largeCell.require == 'teacher' && user && user['Teacher Priority'] && count > 0 && users[count - 1] && users[count - 1]['Teacher Priority'] && !largeCell.max) {
					break;
				}
				cell = this.cells[j];
				// Make sure there is a way to reset large cells after
				if(cell && cell.colSpan > 1) {
					cell.setLargeCell(null);
				}

				// If count is 0, then we are on a new line.  Otherwise break until the next line
				if(user && user.classBreak && count > 0) {
					allowFill = false;
				} else if(user && this.definition.groupBy) {
					if(lastUser) {
						var currentUserVal = user[this.definition.groupBy];
						var lastUserVal = lastUser[this.definition.groupBy];
						// For Teacher Priority we really just care about there vs not
						if(this.definition.groupBy == 'Teacher Priority') {
							currentUserVal = !!currentUserVal;
							lastUserVal = !!lastUserVal;
						}

						if(currentUserVal != lastUserVal) {
							allowFill = false;
							if(largeCell) {
								options.skipNextRow = true;
							}
						}
					} else {
						currentUserVal = user[this.definition.groupBy];
						if(this.definition.groupBy == 'Teacher Priority') {
							currentUserVal = !!currentUserVal;
						}

						this.groupByValue = currentUserVal;
					}
				}

				count += this.addUser(cell, user, allowFill, allowHide);
				lastUser = user;
			}

			// Should only happen if we have a frame covering this entire row
			if(count == 0 && this.titleRows.length > 0) {
				this.removeTitleBreak();
			}

			if(this.nameContainer) {
				if(!count) {
					this.nameContainer.style.display = 'none';
				}

				// Last row - do some different logic to get centering work correctly
				if(users.length && count && count >= users.length && this.centered) {
					let nameContainerWidth = $(this.nameContainer).getFloatStyle('max-width');
					let rowMarginWidth = $(this).getFloatStyle('margin-left') + $(this).getFloatStyle('margin-right') + nameContainerWidth;
					$(this).css({
						position: 'relative',
						width: 'calc(100% - ' + rowMarginWidth + 'px)'
					});
					
					let left = '', right = '';
					if($(this.nameContainer).css('order') === '-1') {
						left = -nameContainerWidth + 'px';
					} else {
						right = -nameContainerWidth + 'px';
					}
					$(this.nameContainer).css({
						width: nameContainerWidth + 'px',
						position: 'absolute',
						left: left,
						right: right
					});
				}
			}

			return users.slice(count);
		},
		getRightAlignedStartColumn: function(users, count, jEnd, midBreakStart, midBreakEnd) {
			var allowedUsers = this.getUsersBeforeBreak(users);

			if(allowedUsers.length && allowedUsers.length < this.cells.length) {
				var usersNeeded = allowedUsers.length;
				var midBreakColValue = 10000000;
				if(midBreakStart) {
					midBreakColValue = jEnd - (midBreakEnd - midBreakStart) - 1;
				}

				// Work our way backward so we can account for hidden cells
				var startCol = Math.min(jEnd - 1 + count, this.cells.length - 1, midBreakColValue);
				for(; startCol > 0 && usersNeeded; startCol--) {
					if(!$(this.cells[startCol]).hasClass('hiddenCell')) {
						usersNeeded--;
					}

					if(!usersNeeded) {
						break;
					}
				}

				return startCol;
			}

			return null;
		},
		getUsersBeforeBreak: function(users) {
			var allowedUsers = [];
			for(let i = 0; i < users.length && i <= this.cells.length; i++) {
				let user = users[i];
				if(user.classBreak && i != 0) {
					break;
				} else {
					allowedUsers.push(user);
				}
			}

			return allowedUsers;
		},
		addUser: function(cell, user, allowFill, allowHide) {
			if(!cell) {
				return;
			}

			var inc = 0;

			if(allowFill && !$(cell).hasClass('hiddenCell')) {
				if(user) {
					cell.setUser(user);
					$(cell).removeClass('invisibleCell');
				} else {
					cell.setUser(null);

					if(this.centered && allowHide) {
						$(cell).addClass('invisibleCell');
					}
				}
				inc++;
			} else {
				cell.setUser(null);

				if(this.centered && allowHide && !$(cell).hasClass('hiddenCell')) {
					$(cell).addClass('invisibleCell');
				}
			}

			return inc;
		},
		addTitleBreak: function(classObj) {
			var parent = this.parent;
			var page = parent.getPage();

			var div, mainTitle = true;
			if(this.titleRows.length) {
				div = this.titleRows[0];
			} else {
				div = new $.FlowLayoutTitle(parent, function (title, isUserAction) {
					if(title !== null) {
						title = $.extend(true, {}, title);
					}

					// Update this sub text in the page
					page.setExtraTitle(classObj.id, title);

					// If we are the last title, update the pages lastText and possibly next page's text
					var lastTitle = $(parent).find('.flowTitle').last()[0];
					if(div == lastTitle) {
						page.setLastTitle(title);

						if(parent.childLayout) {
							var childPage = parent.childLayout.getPage();
							if(!childPage.hasTitle()) {
								parent.childLayout.setTitle(title);
							}
						}
					}
				});

				var isOverflow = false;
				var hiddenCells = $(this).find('.hiddenCell');
				hiddenCells.each(function () {
					var behindFrames = $(this).data('behindFrames');
					for(let i = 0; i < behindFrames.length; i++) {
						var frame = behindFrames[i];
						if($(frame).hasClass('flowCell')) {
							isOverflow = true;
						}
					}
				});

				if(isOverflow) {
					return 2;
				} else {
					$(div).insertBefore(this);
				}
				this.titleRows.push(div);
				mainTitle = false;
			}

			// Use last title if set, or class name is still as default
			var title = page.getExtraTitle(classObj.id);
			if(typeof title == 'undefined') {
				title = '%batch%';
				page.setExtraTitle(classObj.id, '%batch%');
			}

			// Update last title set
			page.setLastTitle(title);
			page.setLastClass(classObj);
			div.extraTitleId = classObj.id;
			div.extraBatch = classObj;
			div.setTitle(title);

			// Want to recalculate hidden cells
			if(!mainTitle) {
				this.parent.hideFrameOverflow();
			}

			// Make parent recalculate how many rows we should have
			var definition = $.extend(true, {}, parent.definition);
			var startRows = this.parentNode.rows.length;
			parent.calculateCells(definition);
			var endRows = definition.grid.vertical;

			// Save layout if there was a change
			if(startRows != endRows) {
				// Skip member set function since we don't want to save this change
				page.layout = definition;
				parent.definition = definition;

				// Ask parent to remove the last rows
				for(let i = endRows; i < startRows; i++) {
					var lastRow = this.parentNode.removeLastRow();
					if(lastRow == this) {
						return 1;
					}
				}

				// Add new rows
				for(let i = startRows; i < endRows; i++) {
					this.parentNode.addLastRow();
				}
			}

			return false;
		},
		removeTitleBreak: function() {
			var parent = this.parent;
			var page = parent.getPage();

			for(let i = 0; i < this.titleRows.length; i++) {
				this.titleRows[i].destroy();
				$(this.titleRows[i]).remove();
			}
			this.titleRows = [];

			var definition = $.extend(true, {}, parent.definition);
			parent.calculateCells(definition);

			// Save layout if there was a change
			// Skip member set function since we don't want to save this change
			page.layout = definition;
			parent.definition = definition;
			parent.addCellsToLayout(definition);
		},

		updateLabelCSS: function() {
			for(let i = 0; i < this.cells.length; i++) {
				this.cells[i].updateLabelCSS();
			}
		},
		setLabelCSS: function(name, value) {
			for(let i = 0; i < this.cells.length; i++) {
				this.cells[i].setLabelCSS(name, value);
			}
		},
		applyFilters: function (definition) {
			for(let i = 0; i < this.cells.length; i++) {
				this.cells[i].applyFilters(definition);
			}
		},
		setCellTextCSS: function(id, name, value) {
			for(let i = 0; i < this.cells.length; i++) {
				this.cells[i].setCellTextCSS(id, name, value);
			}
		},
		updateCellTextCSS: function(id) {
			for(let i = 0; i < this.cells.length; i++) {
				this.cells[i].updateCellTextCSS(id);
			}
		},
		isFirstRow: function() {
			return this.rowIndex === 0;
		},
		isLastRow: function() {
			var canvas = this.parentNode || this.parent.canvas;
			return this.rowIndex === (canvas.rows.length - 1);
		},
		getRowIndex: function() {
			return this.rowIndex;
		},
		getTotalRows: function() {
			var canvas = this.parentNode || this.parent.canvas;
			return canvas.rows.length;
		},
		getTotalCells: function() {
			return this.cells.length;
		},
		getFilledCells: function() {
			return this.cells.filter(function(cell) {
				return !!cell.user;
			});
		},
		getValidCells: function() {
			return $(this.cells).not('.hiddenCell').length;
		},
		getUsers: function() {
			return this.getFilledCells().map(function(cell) {
				return cell.user;
			});
		},
		getClassBreakCell: function() {
			return this.cells.filter(function(cell) {
				return cell && cell.user && cell.user.classBreak;
			})[0];
		},

		destroy: function() {
			for(let i = 0; i < this.cells.length; i++) {
				let cell = this.cells[i];
				if(cell) {
					cell.destroy();
				}
			}
			for(let i = 0; i < this.titleRows.length; i++) {
				var title = this.titleRows[i];
				if(title) {
					title.destroy();
					$(title).remove();
				}
			}
		}
	}, options);

	wrapper.cells = [];
	wrapper.titleRows = [];

	wrapper.definition = definition;
	if(definition && definition.position) {
		wrapper.labelCSS = definition.studentLabelCSS;
		var nameContainer = document.createElement('div');
		nameContainer.className = 'rowHeader flowCell';

		if(definition.position == 'right') {
			$(nameContainer).css('order', '5');
			$(wrapper).css('float', 'left');
		} else if(definition.position == 'left') {
			$(nameContainer).css('order', '-1');
			$(wrapper).css('float', 'right');
		} else if(definition.position == 'outside') {
			if(definition.wrapper.side == 'Right') {
				$(nameContainer).css('order', '5');
				$(wrapper).css('float', 'left');
			} else {
				$(nameContainer).css('order', '-1');
				$(wrapper).css('float', 'right');
			}
		}

		var padding = 0;
		if(definition.padding) {
			padding = definition.padding * ratio;
		}
		var leftPadding = padding;
		var rightPadding = padding;

		if(definition.nameInsidePadding) {
			if(definition.wrapper.side == 'Right') {
				leftPadding = definition.nameInsidePadding * ratio;
			} else {
				rightPadding = definition.nameInsidePadding * ratio;
			}
		}

		$(nameContainer).css('margin-left', leftPadding).css('margin-right', rightPadding).appendTo(wrapper);
		wrapper.leftNameContainerPadding = leftPadding;
		wrapper.rightNameContainerPadding = rightPadding;
		if(definition.width) {
			var width = definition.width * ratio - (leftPadding + rightPadding);
			$(nameContainer).width(width);
		}
		wrapper.nameContainer = nameContainer;
		wrapper.nameOrder = definition.nameOrder ? definition.nameOrder : '%first% %last%';
	}

	if(definition) {
		if(definition.float) {
			$(wrapper).css('float', definition.float);
		}
		if(definition.class) {
			$(wrapper).addClass(definition.class);
		}
		if(definition.nameAlign) {
			var align = definition.nameAlign;
			if(align == 'inside') {
				if(definition.wrapper.side == 'Right') {
					align = 'left';
				} else {
					align = 'right';
				}
			}
			$(wrapper).css('text-align', align);
			if(nameContainer) {
				$(nameContainer).css('text-align', align);
			}
		}
		if(definition.nameSize) {
			$(wrapper).css('font-size', definition.nameSize + 'pt');
		}

		if(definition.centered) {
			wrapper.centered = true;
		}
        if(definition.topRowCentered && wrapper.rowIndex === 0) {
			wrapper.centered = true;
			wrapper.forceCentered = true;
		}

		var marginLeft = '';
		var marginRight = '';

		if(definition.sidePadding) {
			marginLeft = marginRight = definition.sidePadding * ratio;
		}

		if(definition.insidePadding) {
			if(definition.wrapper.side == 'Right') {
				marginLeft = definition.insidePadding * ratio;
			} else {
				marginRight = definition.insidePadding * ratio;
			}
		}
		if(definition.outsidePadding) {
			if(definition.wrapper.side == 'Right') {
				marginRight = definition.outsidePadding * ratio;
			} else {
				marginLeft = definition.outsidePadding * ratio;
			}
		}

		$(wrapper).css({
			marginLeft: marginLeft,
			marginRight: marginRight
		});
	}
	wrapper.parent = definition.wrapper;
	wrapper.ratio = ratio;

	return wrapper;
};