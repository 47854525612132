$.searchAlbums = function(options) {
	if(!options.albumTag) {
		throw 'Require albumTag param';
	} else if(!$.isInit(options.search)) {
		throw 'Require search param';
	} else if(!options.resource_type) {
		throw 'Require resouce_type param';
	} else if(!options.resource_id) {
		throw 'Require resouce_id param';
	}

	options = $.extend({
		jobId: $.getGETParams().jobId,
		layoutId: $.getGETParams().layoutId,
	}, options);

	var ajax = $.getPlicAPI({
		method: 'photos/search',
		params: {
			parent_resource_type: options.resource_type,
			parent_resource_id: options.resource_id,
			filter: {
				album: {
					tags: [options.albumTag]
				},
				photo: {
					verified: true,
					upload_file_name: options.search,
					tags: [options.search]
				}
			}
		},
		type: 'GET',
		timeout: 30000,
		success: function (data) {
			$.cachePhotos(options, data.photos);
		},
		error: function() {
			if(options.error) {
				options.error();
			}
		}
	});

	if(options.chain) {
		options.chain.add(ajax);
	} else {
		return $.ajax(ajax);
	}
};