<template>
	<tr>
		<td class="text-left">{{ upload.description }}</td>
		<td>
			<v-progress-linear :value="upload.progress" :color="progressColor" height="25">
				<template>
					{{ progressPercent }} {{ upload.progressInfo }}
				</template>
			</v-progress-linear>
		</td>
	</tr>
</template>

<script>
import runUpload from './mixins/run-upload';

export default {
	name: 'UploadFileRow',
	props: ['upload', 'albumId', 'tags', 'photoCategory', 'albumImportId'],
	data() {
		return {
			progress: 0,
			errorMessage: null,
			uploaded: 0
		};
	},
	mixins: [runUpload],
	computed: {
		progressPercent() {
			if(this.errorMessage) {
				return this.errorMessage;
			} else {
				return this.upload.progress.toFixed(0) + '%';
			}
		},
		progressColor() {
			if(this.errorMessage) {
				return 'red';
			} else {
				return 'green';
			}
		}
	},
	methods: {
		onFinishUploadComplete(upload) {
			this.$emit('upload-finished', upload);
		},
		onFailedUpload(upload) {
			this.$emit('upload-finished', upload);
		}
	},
	mounted() {
		if(this.upload.converting) {
			this.upload.progress = 1;
			this.upload.progressInfo = ' - converting...';
			this.upload.converting.then(() => {
				this.upload.progressInfo = '';
				this.startUpload(this.upload);
			}).catch(e => {
				this.failedUpload(this.upload, this.i18n('uploader.errors.convertToJpg'), e);
			});
		} else if(!this.upload.error) {
			this.startUpload(this.upload);
		}
	}
};
</script>