import pdfjsLib from 'pdfjs-dist';
// NOTE: This is compiled in compileAssets.php from webpack source
pdfjsLib.GlobalWorkerOptions.workerSrc = '/js/pdf.worker.js';

export default function getDefaultFileExtensions() {
	let canvas = document.createElement('canvas');
	let acceptedFileExtensions = ['jpg', 'jpeg', 'png'];
	let photoConverters = {};
	if(canvas && canvas.toBlob) {
		acceptedFileExtensions.push('webp');
		photoConverters.webp = {
			converter: (blob) => {
				return new Promise((resolve, reject) => {
					const image = new Image();
					image.onload = () => {
						try {
							const canvas = document.createElement('canvas');
							canvas.width = image.naturalWidth;
							canvas.height = image.naturalHeight;
							canvas.getContext('2d').drawImage(image, 0, 0);
							canvas.toBlob((blob) => {
								resolve(blob);
							}, 'image/jpeg');
						} catch(e) {
							reject(e);
						}
					};
					image.onerror = () => {
						reject();
					};

					image.src = URL.createObjectURL(blob);
				});
			},
			signatures: [{
				0: 0x52,
				1: 0x49,
				2: 0x46,
				3: 0x46,
				8: 0x57,
				9: 0x45,
				10: 0x42,
				11: 0x50
			}]
		};

		acceptedFileExtensions.push('pdf');
		photoConverters.pdf = {
			converter: (blob) => {
				return new Promise((resolve, reject) => {
					blob.arrayBuffer().then((arrayBuffer) => {
						let typedArray = new Uint8Array(arrayBuffer);
						let loadingTask = pdfjsLib.getDocument(typedArray);
						return loadingTask.promise;
					}).then((pdf) => {
						return pdf.getPage(1);
					}).then((page) => {
						const scale = 3;
						let viewport = page.getViewport({scale: scale});
						const canvas = document.createElement('canvas');
						let context = canvas.getContext('2d');
						canvas.height = viewport.height;
						canvas.width = viewport.width;

						let renderTask = page.render({
							canvasContext: context,
							viewport: viewport
						});
						renderTask.promise.then(() => {
							canvas.toBlob((blob) => {
								resolve(blob);
							}, 'image/jpeg');
						}).catch(reject);
					}).catch(reject);
				});
			},
			signatures: [{
				0: 0x25,
				1: 0x50,
				2: 0x44,
				3: 0x46
			}]
		};

		acceptedFileExtensions.push('zip');
	}

	return {
		acceptedFileExtensions,
		photoConverters
	};
}