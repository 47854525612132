<template>
	<v-combobox
		v-model="customTags"
		:label="tagLabel"
		multiple
		chips
		:deletable-chips="true"
		class="tag-box"
		prepend-icon="tag_faces"
	></v-combobox>
</template>

<script>
export default {
	props: {
		value: null,
		tagLabel: null
	},
	computed: {
		customTags: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value);
			}
		}
	}
};
</script>