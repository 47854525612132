<template>
	<v-data-iterator
		:items="rejectedUploads"
		:items-per-page="6"
		:footer-props="{ 'show-current-page': true, 'next-icon': 'chevron_right', 'prev-icon': 'chevron_left' }"
		class="errors-table v-data-table v-data-table--has-bottom theme--light"
		:hide-default-footer="rejectedUploads.length <= 10">

		<template v-slot:default="props">
			<div class="v-data-table__wrapper">
				<table>
					<thead class="v-data-table-header">
						<tr>
							<th class="text-left">{{ i18n('uploader.rejectedFiles') }}</th>
							<th class="text-left">{{ i18n('uploader.reason') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr class="text-left" v-for="upload in props.items" :key="upload.id">
							<td class="red--text">{{ upload.file.name }}</td>
							<td class="red--text">{{ upload.rejectReason }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</template>
	</v-data-iterator>
</template>

<script>
export default {
	props: ['rejectedUploads']	
};
</script>

<style scoped>
.errors-table {
	margin-bottom: 1em;
}
.errors-table:deep(.v-data-footer__select) {
	display: none;
}
</style>