$.UserEvents = function(options) {
	var obj = new Object;

	$.extend(obj, {
		addEvents: function(events) {
			events.forEach(function(event) {
				obj.addEvent(event);
			});
		},
		addEvent: function(event) {
			this.addEventToStack(event);
			if (this.dbQueue) {
				var queuedEvent = this.getQueuedEvent(event);
				this.dbQueue.queueEvent(queuedEvent);
			}

			return event;
		},
		// Internal use only
		addEventToStack: function(event) {
			// If we have undone some actions, remove from stack now
			if(event.userId && $.CurrentUser && event.userId != $.CurrentUser.userId) {
				if(this.undoStack > 0) {
					this.undoStack++;
				}
			} else if(!event.private) {
				for (let i = 0; i < this.undoStack; i++) {
					this.events.pop();
				}
				this.undoStack = 0;
			}

			event.args = this.getQueuedEventArgs(event);

			if(!event.timestamp) {
				var currentTimestamp = new Date().getTime();
				if(this.groupedTimestamp && (Math.max(this.keepGroupedTimestamp || this.groupedTimestamp, this.groupedTimestamp) + 5000) > currentTimestamp) {
					event.timestamp = this.groupedTimestamp;
				} else {
					event.timestamp = currentTimestamp;
				}
			}

			if(!event.userId) {
				event.userId = $.CurrentUser.userId;
			}

			if(!event.private) {
				this.events.push(event);

				// If we are over max size, start removing from front
				if (this.events.length > this.maxEvents) {
					this.events.shift();
				}
			}

			var context = event.context;
			if($.isArray(context)) {
				if(typeof context[0] != 'string') {
					var alias = this.getContextAlias(context[0]);
					if($.isArray(alias)) {
						var args = [0, 1].concat(alias);
						Array.prototype.splice.apply(context, args);
					} else {
						context[0] = this.getContextAlias(context[0]);
					}
				}
			} else if(typeof context != 'string') {
				event.context = this.getContextAlias(context);
			}

			this.onEventAdded(event);
		},
		getQueuedEvent: function(event) {
			event = {
				context: event.context,
				action: event.action,
				args: event.args,
				extras: event.extras,
				stripDuplicates: event.stripDuplicates,
				permanent: event.permanent,
				userId: event.userId,
				timestamp: event.timestamp
			};

			for(var id in event) {
				if(event[id] === undefined) {
					delete event[id];
				}
			}

			return event;
		},
		getQueuedEventArgs: function(event) {
			if(event.args) {
				var args = this.stripArgsForLogs(event.args);
				if (event.action == 'update' && args.length == 2 && $.isPlainObject(args[0]) && $.isPlainObject(args[1]) && event.stripDuplicates !== false) {
					this.stripArgObjectDuplicates(args[0], args[1], event.context[event.context.length - 1], 1);
				}

				return args;
			} else {
				return event.args;
			}
		},
		addEventDelayed: function(event, settings) {
			this.addEventToStack(event);

			var queuedEvent;
			if (this.dbQueue) {
				queuedEvent = this.getQueuedEvent(event);
			}

			var me = this;
			window.setTimeout(function() {
				settings.onComplete(event);

				if(queuedEvent && settings.argsChanged) {
					event.args = queuedEvent.args = me.getQueuedEventArgs(event);
				}

			}, settings.timeout ? settings.timeout : 200);

			if(this.dbQueue) {
				this.dbQueue.queueEvent(queuedEvent);
			}
		},
		startGroupedEvents: function(timestamp) {
			if(timestamp) {
				this.groupedTimestamp = timestamp;
			} else {
				this.groupedTimestamp = new Date().getTime();
				this.keepGroupedTimestamp = null;
			}
		},
		keepEventsGroupedLonger: function() {
			if(this.groupedTimestamp) {
				this.keepGroupedTimestamp = new Date().getTime();
			}
		},
		stopGroupedEvents: function() {
			this.groupedTimestamp = null;
			this.keepGroupedTimestamp = null;
		},
		isGroupedEvents: function() {
			return !!this.groupedTimestamp;
		},
		getContextAlias: function (context) {
			if (context === null) {
				return 'null';
			} else if (typeof context == 'undefined') {
				return 'undefined';
			}

			if (context.contextAlias) {
				var alias = context.contextAlias;
				if (alias == 'page') {
					return ['page', context.id];
				} else {
					return alias;
				}
			} else {
				return context;
			}
		},
		undoLastEvent: function () {
			var timestamp;
			var undoneEvents = [];
			var undoEvents = [];
			var event;

			try {
				while (this.events.length - this.undoStack > 0) {
					event = this.getTopUndoEvent();
					this.undoStack++;
					if (timestamp && Math.abs(event.timestamp - timestamp) > this.groupEventsWithinTime) {
						this.undoStack--;
						break;
					}
					if(!this.isEventUndoable(event)) {
						continue;
					}
					timestamp = event.timestamp;

					let undoEvent = {
						action: event.action,
						context: event.context,
						extras: event.extras,
						stripDuplicates: event.stripDuplicates,
						userId: $.CurrentUser.userId
					};
					switch(event.action) {
						case 'update':
							undoEvent.args = [event.args[1], event.args[0]];
							this.sanitizeUndoEventArgs(undoEvent);
							break;
						case 'replace':
							undoEvent.args = [event.args[1], event.args[0]];

							// Make sure not to edit the original events
							if($.isPlainObject(undoEvent.args[0])) {
								undoEvent.args[0] = $.extend(true, {}, undoEvent.args[0]);
							}
							if($.isPlainObject(undoEvent.args[1])) {
								undoEvent.args[1] = $.extend(true, {}, undoEvent.args[1]);
							}

							break;
						case 'insert':
							undoEvent.action = 'remove';
							undoEvent.args = event.args;
							break;
						case 'remove':
							undoEvent.action = 'insert';
							undoEvent.args = [event.args[0], event.args[1] - 1];
							break;
						case 'clear':
							undoEvent.action = 'update';
							undoEvent.args = [{}, event.args];
							break;
						case 'swapWith':
							undoEvent.args = [event.args[1], event.args[0]];
							break;
						case 'moveBefore':
							if(event.args.length == 2) {
								undoEvent.args = [event.args[1], event.args[0]];
							} else {
								undoEvent.args = [event.args[0], event.args[2]];
							}

							if(event.extras && event.extras.idProp) {
								undoEvent.extras = {
									startIdProp: event.extras.idProp
								};
							}
							break;
						default:
							// Don't know what to do
							console.warn('Failed to undo event (unknown action)', event);
							undoEvent = null;
					}

					if (undoEvent) {
						undoEvents.push(undoEvent);
						undoneEvents.push(event);
					}
				}
				// Unsetting just so when we fire an error report we know if finished the loop
				event = null;

				var triggerHandlerEvents = [];
				for(let i = 0; i < undoEvents.length; i++) {
					let undoEvent = event = undoEvents[i];

					if (this.consumeEvent(undoEvent)) {
						triggerHandlerEvents.push(undoEvent);

						if (this.dbQueue) {
							this.dbQueue.queueChangeFromEvent(undoEvent);
						}
					}
				}
				this.postConsumeEvents(triggerHandlerEvents);

				for(let i = 0; i < triggerHandlerEvents.length; i++) {
					let undoEvent = triggerHandlerEvents[i];
					this.triggerOnConsumeEventHandler(undoEvent);
				}

				if(undoEvents.length) {
					this.onEventUndone(undoEvents);

					if(window.alertify) {
						window.alertify.success(i18n.t('composer.undo.undid'));
					}
				}
			} catch(e) {
				console.error(e);
				$.fireErrorReport(null, 'Error in undo', 'There was an error attempting to undo an action', {
					exception: e,
					undoEvents: JSON.stringify(undoEvents),
					undoneEvents: JSON.stringify(undoneEvents),
					lastUndoEvent: JSON.stringify(event)
				});
			}
		},
		getTopUndoEvent: function() {
			return this.events[this.events.length - this.undoStack - 1];
		},
		sanitizeUndoEventArgs: function(undoEvent) {
			// Make sure not to edit the original events
			if($.isPlainObject(undoEvent.args[0])) {
				undoEvent.args[0] = $.extend(true, {}, undoEvent.args[0]);
			}
			if($.isPlainObject(undoEvent.args[1])) {
				undoEvent.args[1] = $.extend(true, {}, undoEvent.args[1]);
			}

			var realContextLength = undoEvent.context.length;
			if(undoEvent.context[0] == 'page') {
				realContextLength--;
			}

			var undoContext = undoEvent.context[undoEvent.context.length - 1];
			// We want to make sure that undefined => true or undefined => {} is reversible
			if($.isPlainObject(undoEvent.args[0])) {
				if(realContextLength > 2) {
					if(typeof undoEvent.args[1] == 'undefined' && realContextLength >= 4) {
						undoEvent.args[1] = {};
					}

					this.sanitizeUndoEventArgSet(undoEvent.args[0], undoEvent.args[1]);
				} else if(realContextLength == 2) {
					if($.isInit(undoEvent.args[1])) {
						for(var name in undoEvent.args[0]) {
							var arg1 = undoEvent.args[0][name];
							var arg2 = undoEvent.args[1][name];

							if($.isPlainObject(arg1)) {
								if(typeof arg2 == 'undefined') {
									if(['layout'].indexOf(undoContext) != -1) {
										undoEvent.args[1][name] = null;
									} else if('theme' == undoContext && arg1.id) {
										undoEvent.args[1][name] = null;
									} else {
										arg2 = undoEvent.args[1][name] = {};
									}
								}
								this.sanitizeUndoEventArgSet(arg1, arg2);
							} else if($.isArray(arg1)) {
								if(typeof arg2 == 'undefined') {
									if(['layout'].indexOf(undoContext) != -1) {
										undoEvent.args[1][name] = null;
									}
								}
							} else if(['largeCellPosition'].indexOf(undoContext) != -1 && !$.isInit(arg2)) {
								undoEvent.args[1][name] = null;
							}
						}
					}
				}
			}
		},
		sanitizeUndoEventArgSet: function(arg1, arg2) {
			if(!arg2) {
				return;
			}

			for (var name in arg1) {
				if (typeof arg2[name] == 'undefined') {
					if (arg1[name] === true) {
						arg2[name] = false;
					} else if (arg1[name] === false) {
						arg2[name] = true;
					} else if($.isPlainObject(arg1[name]) && ['crop', 'border', 'stroke', 'dropShadow', 'groupBorder', 'locked'].indexOf(name) == -1) {
						arg2[name] = {};

						this.sanitizeUndoEventArgSet(arg1[name], arg2[name]);
					} else if((arg1[name] && name.startsWith('order') && name.length === 6) || (arg1[name] && name.startsWith('teacherPrefixOrder') && name.length === 19)) {
						arg2[name] = '';
					} else {
						arg2[name] = null;
					}
				} else if($.isPlainObject(arg1[name]) && $.isPlainObject(arg2[name])) {
					this.sanitizeUndoEventArgSet(arg1[name], arg2[name]);
				}
			}
		},
		redoLastEvent: function() {
			if(this.undoStack > 0) {
				var redoEvents = [];
				var timestamp;

				var event;
				// eslint-disable-next-line
				while(event = this.getTopRedoEvent()) {
					if(timestamp && Math.abs(event.timestamp - timestamp) > this.groupEventsWithinTime) {
						break;
					}
					timestamp = event.timestamp;

					this.undoStack--;
					if(!this.isEventUndoable(event)) {
						continue;
					}

					redoEvents.push(event);
				}

				for (let i = 0; i < redoEvents.length; i++) {
					var redoEvent = redoEvents[i];

					if (this.consumeEvent(redoEvent)) {
						this.triggerOnConsumeEventHandler(redoEvent);

						if (this.dbQueue) {
							this.dbQueue.queueChangeFromEvent(redoEvent);
						}
					}
				}

				if(redoEvents.length) {
					this.onEventRedone(redoEvents);

					if(window.alertify) {
						window.alertify.success(i18n.t('composer.undo.redid'));
					}
				}
			}
		},
		getTopRedoEvent: function() {
			if(this.undoStack > this.events.length) {
				this.undoStack = this.events.length;
			}

			return this.events[this.events.length - this.undoStack];
		},
		stripArgsForLogs: function (args) {
			if (!args) {
				return args;
			}

			if ($.isArray(args)) {
				args = $.merge([], args);
				for (let i = 0; i < args.length; i++) {
					args[i] = this.stripArgForLogs(this.stripArgsForLogs(args[i]));
				}
			} else if ($.isPlainObject(args)) {
				var newArgs = {};
				for (let i in args) {
					if (typeof args[i] != 'function') {
						newArgs[i] = this.stripArgsForLogs(args[i]);
					}
				}

				this.stripArgForLogs(newArgs);
				args = newArgs;
			}

			return args;
		},
		stripArgForLogs: function (arg) {
			if (!arg) {
				return arg;
			}

			if(arg.studentLabelCSS && arg.studentLabelCSS.css) {
				arg.studentLabelCSS = arg.studentLabelCSS.css;
			}
			if(arg.pageNumberCSS && arg.pageNumberCSS.css) {
				arg.pageNumberCSS = arg.pageNumberCSS.css;
			}

			var deleteArgs = ['nameOrder', 'nameAlign'];
			for (let i = 0; i < deleteArgs.length; i++) {
				var deleteArg = deleteArgs[i];
				if (typeof arg[deleteArg] != 'undefined') {
					delete arg[deleteArg];
				}
			}

			return arg;
		},
		stripArgObjectDuplicates: function (start, end, context, depth) {
			for (let i in start) {
				var startChild = start[i];
				var endChild = end[i];

				if (startChild === endChild) {
					// FIXME: If we only update one field in the name param, we don't want to remove it
					if(context != 'layout' || depth === 1) {
						delete start[i];
						delete end[i];
					}
				} else if ($.isPlainObject(startChild) && $.isPlainObject(endChild)) {
					if(context == 'layout') {
						// Still want to do null -> something/somthing -> undefined checks
						this.stripArgObjectDuplicates(startChild, endChild, context, depth + 1);

						if($(startChild).objectEquals(endChild, [], true)) {
							delete start[i];
							delete end[i];
						}
					} else {
						var startChildCount = $.getObjectCount(startChild, {
							includeUndefined: false
						});
						var endChildCount = $.getObjectCount(endChild, {
							includeUndefined: false
						});
						this.stripArgObjectDuplicates(startChild, endChild, context, depth + 1);

						// Want to make sure we didn't rule out the entire object
						if (startChildCount > 0 && $.getObjectCount(startChild, {includeUndefined: false}) === 0) {
							delete start[i];
						}
						if (endChildCount > 0 && $.getObjectCount(endChild, {includeUndefined: false}) === 0) {
							delete end[i];
						}
					}
				}
				// Null -> something is implied
				else if (!$.isInit(startChild)) {
					delete start[i];

					// undefined <-> null don't care
					if (!$.isInit(endChild)) {
						delete end[i];
					}
				}
				// Make sure we log if it is going from something to nothing!
				else if(typeof endChild == 'undefined') {
					end[i] = null;
				}
				// If we have exactly identical arrays, deduplicate them
				else if($.isArray(startChild) && $.isArray(endChild)) {
					if($.arrayEquals(startChild, endChild, null, true)) {
						delete start[i];
						delete end[i];
					}
				}
			}
		},
		consumeEvents: function(events) {
			if(events.length && events[0].timestamp) {
				this.startGroupedEvents(events[0].timestamp);
			}
			var consumedEvents = [];
			for(let i = 0; i < events.length; i++) {
				let event = events[i];
				if(this.consumeEvent(event)) {
					consumedEvents.push(event);
				}
			}
			this.postConsumeEvents(consumedEvents);

			for(let i = 0; i < consumedEvents.length; i++) {
				let event = consumedEvents[i];
				this.addEventToStack(event);
				this.triggerOnConsumeEventHandler(event);
			}
			this.stopGroupedEvents();

			return consumedEvents.length;
		},
		postConsumeEvents: function(events) {
			var pageSet = this.pageSet;
			if(!pageSet) {
				return;
			}

			// Check for stranded overflow pages
			events.forEach(function(event) {
				if(event.action === 'insert' && event.context.length === 2 && event.context[0] === 'page') {
					var pagesData = event.args[0];
					if(!$.isArray(pagesData)) {
						pagesData = [pagesData];
					}

					pagesData.forEach(function(pageData) {
						if(!pageData.id) {
							return;
						}

						var page = pageSet.getPageById(pageData.id);
						if(!page) {
							return;
						}

						if(page.type.toLowerCase().includes('overflow')) {
							var parentPage = page.getParentPage();
							if(!parentPage || !parentPage.setOverflowPage) {
								console.warn('Removing stranded overflow page after page insert event: ', event, {
									parentPage: parentPage ? { id: parentPage.id, type: parentPage.type } : null
								});
								pageSet.removePage(page);

								if(parentPage) {
									parentPage.overflowPage = null;
								}
							}
						}
					});
				}
			});
		},
		consumeEvent: function(event) {
			if(!$.isArray(event.context)) {
				event.context = [event.context];
			}

			if(this.consumeEventContext.indexOf(event.context[0]) === -1) {
				console.warn('Ignoring event for unknown context ' + event.context[0], event);
				this.triggerOnIgnoreEventHandler(event);
				return false;
			}

			if(this.isEventConflicting(event)) {
				console.warn('Ignoring event due to local change overriding it', event);
				return false;
			}

			if(event.context[0] === 'snapshots') {
				if(window.alertify) {
					window.alertify.error('Project has been rolled back to snapshot.  Refreshing.');
				}

				window.location.reload();
				return false;
			}

			var context, nextContext = 1;
			var subContext = event.context[event.context.length - 1];
			switch(event.context[0]) {
				case 'pageSet':
					context = this.pageSet;
					break;
				case 'page':
					context = this.pageSet;
					if(event.context.length > 2) {
						context = context.getPageById(event.context[nextContext]);
						if(context == null) {
							console.warn('Failed to consume event (invalid page)', event);
							return false;
						}
						nextContext++;

						// Might need to look up and replace batches
						if(event.context.length == 3 && ['extraClasses', 'batchId', 'classObj'].indexOf(event.context[2]) != -1 && $.isInit(event.args)) {
							return this.consumeClassObjEvents(event);
						}
					} else {
						// Updating/deleting/whatever the page itself
						return this.consumePageEvent(event);
					}
					break;
				case 'subjects':
					context = this.pageSet.subjects;
					if(event.context.length > 2) {
						context = context.getMatch({}, 'id', event.context[1]);
						nextContext++;
					} else if(event.context.length === 2) {
						subContext = context.indexOfMatch({}, 'id', event.context[1]);
					}
					break;
				case 'albums':
					// NOTE: Really shouldn't be doing updates in the check stage...
					if(this.pageSet && event.context.length === 3 && event.action === 'update') {
						this.pageSet.pages.forEach(function(page) {
							page.checkUpdateCandidProperties(event.context[2], event.args[1]);
						});
					}

					if($.candidsCategories) {
						context = $.candidsCategories.slider.cachedData['loadAlbum' + event.context[1]];
						nextContext++;
						if(!context) {
							// Still want to execute in case of search
							let candidNode = $('#candidNode-' + subContext);
							if(candidNode.length) {
								context = [candidNode.data('photo')];
							} else {
								return false;
							}
						}

						subContext = context.indexOfMatch({id: subContext}, 'id');
						if(subContext === -1) {
							return false;
						}

						break;
					} else {
						return false;
					}
				case 'batches':
					context = this.getClassObjForId(event.context[1]);
					nextContext++;
					break;
				case 'projectSettings':
					context = $.projectSettings;
					break;
				case 'users':
					if($.CurrentUser && event.context[1] == $.CurrentUser.userId) {
						context = $.CurrentUser;
						nextContext++;
					} else {
						console.warn('Ignoring change to user that is not me: ', event);
						return false;
					}
					break;
				case 'customDictionary':
					if(!$.CustomDictionary) {
						$.CustomDictionary = [];
					}

					context = $.CustomDictionary;
					break;
				default:
					// Don't know what to do
					console.warn('Failed to consume event (unknown context)', event);
					return false;
			}

			var initNullObjects = false;
			var initCSSBundle = false;
			// If we get something like largeCellPosition.extras.100 = {}, then needs to create missing parts of the object
			if(event.context.length > 3 && event.context[2] === 'largeCellPosition') {
				initNullObjects = true;
			} else if(event.context.length === 4 && event.context[2].includes('CSS') && event.context[3] === 'css') {
				initCSSBundle = true;
			}

			for(let i = nextContext; i < event.context.length - 1; i++) {
				if(!$.isInit(context[event.context[i]]) && initNullObjects) {
					context[event.context[i]] = {};
				} else if(!$.isInit(context[event.context[i]]) && initCSSBundle) {
					if(event.context[i] === 'pageNumberCSS') {
						context[event.context[i]] = context.createPageNumberCSSBundle();
					} else {
						console.error('We do not know how to initialize this css bundle: ', event.context);
					}
				}
				
				let previousContext = context;
				context = context[event.context[i]];
				// Want to still pass context so next step updates it, but removing css bundle from list
				if($.isInit(context) && initCSSBundle && event.args.length === 2 && !$.isInit(event.args[1])) {
					previousContext[event.context[i]] = null;
				}
			}

			switch(event.action) {
				case 'update': {
					if($.isArray(context)) {
						if(event.extras && event.extras.idProp) {
							subContext = context.indexOfMatch({}, event.extras.idProp, subContext);
						}
					}

					let newVal = $.isArray(event.args) ? event.args[1] : event.args;
					if(event.context[0] === 'batches' && subContext === 'subjects' && $.isArray(newVal) && $.isArray(context[subContext])) {
						var jobSubjects = this.pageSet.subjects;
						newVal = newVal.map(function(subjectProperties) {
							// Merge batch properties into a clone of the subject
							return $.extend(true, {}, jobSubjects.getMatch(subjectProperties, 'id'), subjectProperties.batchProperties);
						});

						context[subContext] = newVal;
					} else if(event.context.length === 3 && event.context[2] === 'pageAssignments') {
						context[subContext] = newVal;
					} else if(event.context.length === 2 && event.context[1] === 'layoutDimensions') {
						if(newVal && newVal.definition) {
							context[subContext] = newVal.definition;
						} else {
							context[subContext] = newVal;
						}
					} else if($.isInit(context[subContext]) && typeof context[subContext] == 'object' && typeof newVal == 'object') {
						if(newVal === null) {
							if(event.context.length >= 3 && ['title', 'extraTitles'].indexOf(event.context[2]) !== -1) {
								context[subContext] = null;
							} else {
								if(context.__ob__) {
									Vue.delete(context, subContext);
								} else {
									delete context[subContext];
								}
							}
						} else {
							$.extend(true, context[subContext], newVal);
							this.recursivelyCheckForArrays(context, subContext, newVal);
						}
					} else if(event.context.length === 3 && event.context[1] === 'comments' && !context[subContext] && (!event.args[1] || !event.args[1].id)) {
						console.warn('Cannot consume comment update when missing original', event);
					} else {
						if(context.__ob__) {
							Vue.set(context, subContext, newVal);
						} else {
							context[subContext] = newVal;
						}
					}

					if(event.context[0] == 'subjects' && this.pageSet && this.pageSet.getClasses) {
						var classes = this.pageSet.getClasses();
						if(classes) {
							classes.forEach(function(classObj) {
								var matchedSubject = classObj.subjects.getMatch({id: event.context[1]}, 'id');
								if(matchedSubject) {
									if(event.context.length > 2) {
										var matchedContext = matchedSubject;
										if(event.context.length > 3) {
											matchedContext = matchedContext[event.context[2]];
										}

										if($.isPlainObject(context[subContext])) {
											matchedContext[subContext] = $.extend(true, {}, context[subContext]);
										} else {
											matchedContext[subContext] = context[subContext];
										}
									} else if($.isPlainObject(newVal)) {
										$.extend(true, matchedSubject, newVal);
									} else {
										console.warn('Not sure what to do here.  newVal: ', newVal);
									}
								}
							});
						}
					} else if(event.context[0] === 'albums' && event.context.length === 3) {
						if(newVal && newVal.version_id) {
							context[subContext].force_version_id = context[subContext].photoVersion = newVal.version_id;
							delete context[subContext].cdn_url;
							delete context[subContext].cached_url;

							let candidNode = $('#candidNode-' + context[subContext].id);
							if(candidNode.length) {
								candidNode.data('refreshCandidUrl')();
							}
						}
					}

					if(context.validateProperty) {
						context.validateProperty(subContext);
					}
					break;
				}
				case 'replace': {
					let newVal = $.isArray(event.args) ? event.args[1] : event.args;
					context[subContext] = newVal;

					if(context.validateProperty) {
						context.validateProperty(subContext);
					}
					break;
				}
				case 'remove': {
					let index = event.args;

					if(event.context[0] === 'batches' && event.context.length === 1) {
						context = this.dbQueue.classes;
					} else if(event.context.length > 1) {
						context = context[subContext];
					}
					if($.isArray(index) && index.length === 2 && !isNaN(index[1])) {
						if(event.extras && event.extras.idProp) {
							index = context.indexOfMatch(event.args[0], event.extras.idProp);
						} else {
							index = index[1];
						}
					} else if(isNaN(index)) {
						index = context.indexOf(index);
					}

					if(index >= 0 && index < context.length) {
						context.splice(index, 1);
					}

					if(event.context[0] === 'customDictionary' && $.FlowLayoutSVGUtils) {
						$.FlowLayoutSVGUtils.ignoreSpellingWords.removeItem(event.args[0].word);
					}
					break;
				}
				case 'clear': {
					if($.isArray(context[subContext])) {
						context[subContext] = [];
					} else {
						context[subContext] = {};
					}
					break;
				}
				case 'moveBefore': case 'swapWith': {
					context = context[subContext];

					var startId = event.args[0];
					var endId = event.args[1];
					var startIndex = -1, endIndex = -1;
					if($.isArray(context)) {
						if(event.extras && event.extras.idProp) {
							startIndex = context.indexOfMatch({}, event.extras.idProp, startId);
						} else if(event.extras && event.extras.startIdProp) {
							startIndex = context.indexOfMatch({}, event.extras.startIdProp, startId);
						} else {
							startIndex = startId;
						}

						if(event.extras && event.extras.idProp) {
							endIndex = context.indexOfMatch({}, event.extras.idProp, endId);
						} else if(event.extras && event.extras.endIdProp) {
							endIndex = context.indexOfMatch({}, event.extras.endIdProp, endId);
						} else {
							endIndex = endId;
						}

						if(startIndex !== -1 && endIndex === -1 && (endId === null || endId === -1)) {
							var subject = context[startIndex];
							context.splice(startIndex, 1);
							context.push(subject);
						} else if(startIndex != -1 && endIndex != -1) {
							if(event.action == 'moveBefore') {
								if (startIndex < endIndex && event.extras && event.extras.idProp) {
									endIndex--;
								}

								// Prevent trying to insert this at the end of the array
								if(endIndex >= context.length) {
									endIndex = context.length - 1;
								}

								context.move(startIndex, endIndex);
							} else if(event.action == 'swapWith') {
								context.swap(startIndex, endIndex);
							}
						} else {
							console.warn('Failed to consume event (startId or endId not found)', event);
							return false;
						}
					} else {
						console.warn('Failed to consume event (moveBefore only implemented for arrays)', event);
						return false;
					}
					break;
				}
				case 'insert': {
					if(event.context[0] === 'batches' && event.context.length === 1) {
						context = this.dbQueue.classes;
					} else if(event.context.length > 1) {
						context = context[subContext];
					}
					
					var data = event.args[0];
					let index = event.args[1];
					context.splice(index + 1, 0, data);

					if(event.context[0] === 'customDictionary' && $.FlowLayoutSVGUtils) {
						$.FlowLayoutSVGUtils.ignoreSpellingWords.push(event.args[0].word);
					}
					break;
				}
				default: {
					// Don't know what to do
					console.warn('Failed to consume event (unknown action)', event);
					return false;
				}
			}

			return true;
		},
		isEventConflicting: function(event) {
			// Only handle conflicting updates for now
			if(['update', 'moveBefore'].indexOf(event.action) === -1 || $.CurrentUser.userId === event.userId) {
				return false;
			}

			// Only check the last 10 events for a conflict
			var endingCount = Math.max(0, this.events.length - 10);
			for(let i = this.events.length - 1; i >= endingCount; i--) {
				var conflictingEvent = this.events[i];

				if(conflictingEvent.action !== event.action || !$.arrayEquals(event.context, conflictingEvent.context)) {
					continue;
				}
				
				if(event.timestamp < conflictingEvent.timestamp && (event.timestamp + 1800000) > conflictingEvent.timestamp) {
					return true;
				}
			}

			return false;
		},
		recursivelyCheckForArrays: function(context, subContext, newVal) {
			// We don't want to merge array properties together (ie: lines of text)
			for(var prop in newVal) {
				var propVal = newVal[prop];
				if($.isArray(propVal)) {
					context[subContext][prop] = propVal;
				} else if($.isPlainObject(context[subContext][prop]) && $.isPlainObject(propVal)) {
					this.recursivelyCheckForArrays(context[subContext], prop, propVal);
				}
			}
		},
		consumePageEvent: function(event) {
			var pageIndex = this.pageSet.getPageIndexById(event.context[1]);
			var page = this.pageSet.getPage(pageIndex);
			var db = this.pageSet.db;

			var maxEndIndex;
			switch(event.action) {
				case 'update': {
					let newVal = $.isArray(event.args) ? event.args[1] : event.args;
					if(newVal) {
						if(page.getClass && page.getClass()) {
							page.setClassActive(false);
						}

						let newPage = db.loadSinglePage(newVal);
						// Let the pageNumber be inherited
						if(!newPage.pageNumber) {
							newPage.pageNumber = page.pageNumber;
						}
						this.pageSet.pages[pageIndex] = newPage;
						if(page.getOverflowPage && page.getOverflowPage() && !newPage.getOverflowPage) {
							this.pageSet.removePage(page.getOverflowPage());
						}

						if(newPage.setOverflowPage && page.overflowPage) {
							newPage.overflowPage = page.overflowPage;
							newPage.overflowPage.parentPage = newPage;
						}

						if(newPage.getClass) {
							let batch = newPage.getClass();
							newPage.setClassObjActive(batch, true);
						}

						if(this.pageSet.onPageChange) {
							this.pageSet.onPageChange('replace', newPage, pageIndex);
						}
					} else {
						console.warn('Failed to consume event (update page with null?)', event);
						return false;
					}
					break;
				}
				case 'remove': {
					// We are OK with a concurrent deletion of a page
					if(page) {
						var pagesToRemove = 10000000;
						if(event.extras && event.extras.pagesRemoved) {
							pagesToRemove = event.extras.pagesRemoved;
						}
						this.pageSet.removePageCascade(page, false, {
							pagesToRemove: pagesToRemove
						});
					}
					break;
				}
				case 'moveBefore': {
					var endIndex = event.args[1];
					maxEndIndex = this.pageSet.getMaxInsertIndex();
					if(endIndex > maxEndIndex) {
						event.args[1] = endIndex = maxEndIndex;
					}

					this.pageSet.movePage(page, endIndex, false);
					break;
				}
				case 'insert': {
					var rootNewPage = null;
					var previousPage = this.pageSet.getPage(event.args[1]);
					var newIndex = event.args[1];
					maxEndIndex = this.pageSet.getMaxInsertIndex();
					if(newIndex > maxEndIndex) {
						event.args[1] = newIndex = maxEndIndex;
					}

					var existingOverflowPage;
					if(previousPage) {
						existingOverflowPage = previousPage.overflowPage;
					}
					var lastNewPage;
					if($.isArray(event.args[0])) {
						var pageSet = this.pageSet;
						event.args[0].forEach(function(pageData) {
							let newPage = db.loadSinglePage(pageData, previousPage);
							pageSet.addPage(newPage, newIndex, false);

							previousPage = newPage;
							if(rootNewPage == null) {
								rootNewPage = newPage;
							}
							lastNewPage = newPage;
							newIndex++;
						});
					} else {
						// Check if we are trying to insert overflow page into wrong spot due to stale cache
						if(event.args[0].type && event.args[0].type.toLowerCase().indexOf('overflow') !== -1 && event.extras && event.extras.rootPage && previousPage) {
							if(previousPage.id != event.extras.rootPage && (!previousPage.getRootPage || !previousPage.getRootPage() || previousPage.getRootPage().id != event.extras.rootPage)) {
								var matchingRootPage = this.pageSet.getPageById(event.extras.rootPage);
								if(matchingRootPage) {
									var newPreviousPage = matchingRootPage;
									while(newPreviousPage.overflowPage) {
										newPreviousPage = newPreviousPage.overflowPage;
									}

									var newOverflowIndex = this.pageSet.pages.indexOf(newPreviousPage);
									if(newOverflowIndex !== -1) {
										newIndex = newOverflowIndex;
										previousPage = newPreviousPage;
									}
								}
							}
						}

						let newPage = lastNewPage = rootNewPage = db.loadSinglePage(event.args[0], previousPage);
						this.pageSet.addPage(newPage, newIndex, false);
					}

					if(existingOverflowPage && lastNewPage.setOverflowPage) {
						lastNewPage.overflowPage = existingOverflowPage;
						existingOverflowPage.parentPage = lastNewPage;
					}

					if(rootNewPage.getClass && !rootNewPage.parentPage) {
						let batch = rootNewPage.getClass();
						rootNewPage.setClassObjActive(batch, true);
					}
					break;
				}
				default: {
					// Don't know what to do
					console.warn('Failed to consume event (unknown action)', event);
					return false;
				}
			}

			return true;
		},
		consumeClassObjEvents: function(event) {
			var page = this.pageSet.getPageById(event.context[1]);
			if(!page.setClass) {
				console.error('Failed to consume event (page of wrong type: ' + page.type + ')', event);
				return;
			}
			var context = page[event.context[2]];

			switch(event.action) {
				case 'insert': {
					let classObj = this.getClassObjForId(event.args[0]);

					if(classObj) {
						let index = event.args[1];
						context.splice(index + 1, 0, classObj);
						page.setClassObjActive(classObj, true);
					} else {
						throw 'No classObj for ' + event.args[0];
					}
					break;
				}
				case 'remove': {
					let index = event.args;
					if($.isArray(index) && index.length === 2 && !isNaN(index[1])) {
						index = index[1];
					} else if(isNaN(index)) {
						index = context.indexOf(index);
					}

					let classObj = context.splice(index, 1)[0];
					page.setClassObjActive(classObj, false);
					break;
				}
				case 'update': {
					var oldClassObj = this.getClassObjForId(event.args[0]);
					var newClassObj = this.getClassObjForId(event.args[1]);

					page.setClass(newClassObj);

					if(oldClassObj) {
						page.setClassObjActive(oldClassObj, false);
					}
					if(newClassObj) {
						page.setClassObjActive(newClassObj, true);
					}
					break;
				}
				default: {
					// Don't know what to do
					console.warn('Failed to consume event (unknown action)', event);
					return false;
				}
			}

			return true;
		},
		getClassObjForId: function(id) {
			// If we pass in the full object
			if(id && id.id) {
				id = id.id;
			}

			for (var j = 0; j < this.dbQueue.classes.length; j++) {
				let batch = this.dbQueue.classes[j];
				if (batch.id == id) {
					return batch;
				}
			}

			return null;
		},
		triggerOnConsumeEventHandler: function(event) {
			for(let i = 0; i < this.consumeEventHandlers.length; i++) {
				this.consumeEventHandlers[i].call(this, event);
			}
		},
		addOnConsumeEventHandler: function(handler) {
			this.consumeEventHandlers.push(handler);
		},
		removeOnConsumeEventHandler: function(handler) {
			this.consumeEventHandlers.removeItem(handler);
		},
		triggerOnIgnoreEventHandler: function(event) {
			for(let i = 0; i < this.ignoreEventHandlers.length; i++) {
				this.ignoreEventHandlers[i].call(this, event);
			}
		},
		addOnIgnoreEventHandler: function(handler) {
			this.ignoreEventHandlers.push(handler);
		},
		removeOnIgnoreEventHandler: function(handler) {
			this.ignoreEventHandlers.removeItem(handler);
		},

		registerForGlobalUndo: function() {
			if(!this.handleGlobalUndo) {
				return;
			}

			$('body').on('keydown', this.bodyKeyDownHandler = function (e) {
				if(e.metaKey) {
					e.ctrlKey = true;
				}

				// Ctrl + z => undo
				if(e.keyCode == 90 && e.ctrlKey) {
					if(e.shiftKey) {
						obj.redoLastEvent();
					} else {
						obj.undoLastEvent();
					}

					e.preventDefault();
					return false;
				}
				// Ctrl + y => redo
				else if(e.keyCode == 89 && e.ctrlKey) {
					obj.redoLastEvent();

					e.preventDefault();
					return false;
				}
				// Ctrl + v => paste
				else if(e.keyCode == 86 && e.ctrlKey) {
					return obj.pasteClipboard();
				}
			});
		},
		unregisterForGlobalUndo: function() {
			if(this.bodyKeyDownHandler) {
				$('body').off('keydown', this.bodyKeyDownHandler);
				this.bodyKeyDownHandler = null;
			}

			if(this.dbQueue && this.dbQueue.unregister) {
				this.dbQueue.unregister();
			}
		},
		isEventUndoable: function(event) {
			if(event.permanent || ($.CurrentUser && $.CurrentUser.userId != event.userId)) {
				return false;
			} else {
				if(event.context && this.consumeEventContext.indexOf(event.context[0]) === -1) {
					return false;
				}

				for(let index = this.events.indexOf(event) + 1; index < this.events.length && index !== 0; index++) {
					var nextEvent = this.events[index];
					if(nextEvent.userId === event.userId || nextEvent.context.length >= event.context.length) {
						continue;
					}

					for(let i = 0; i < nextEvent.context.length; i++) {
						if(event.context[i] !== nextEvent.context[i]) {
							break;
						}

						// Other user event updated the root of the object so this is no longer undoable
						if(i === (nextEvent.context.length - 1)) {
							return false;
						}
					}					
				}

				if(!$.isInit(event.args) && ['update'].indexOf(event.action) !== -1) {
					return false;
				} else {
					return true;
				}
			}
		},
		hasEventsToUndo: function() {
			for(let i = this.events.length - 1 - this.undoStack; i>= 0; i--) {
				if(this.isEventUndoable(this.events[i])) {
					return true;
				}
			}
			
			return false;
		},
		hasEventsToRedo: function() {
			for(let i = this.events.length - this.undoStack; i < this.events.length && i >= 0; i++) {
				if(this.isEventUndoable(this.events[i])) {
					return true;
				}
			}

			return false;
		},

		getClipboard: function() {
			return this.clipboard;
		},
		addToClipboard: function(content) {
			this.clipboard = content;
		},
		pasteClipboard: function() {
			if(!this.clipboard) {
				return true;
			}
			var duplicateElementMoveAmount = this.clipboard.duplicateElementMoveAmount;

			var flowLayout = ($.flowLayoutSet || $.flowLayout).getFocusedLayout();
			var destinationPage = flowLayout.getPage();
			if(destinationPage.getFreeMovementLocked() || destinationPage.getLocked()) {
				if(window.alertify) {
					window.alertify.error('Page ' + destinationPage.getPageReference() + ' is locked and cannot be pasted to');
				}

				return true;
			}

			var idMap = {};
			var contents = this.clipboard.contents.map(function(originalContent, index) {
				var content = $.extend(true, {}, originalContent);
				var oldId = content.instance.id;
				content.instance.id = $.getUniqueId();
				idMap[oldId] = content.instance.id;
				content.instance.zIndex = destinationPage.getMaxZIndex() + 1 + index;

				if(content.type == 'frame') {
					while(destinationPage.hasDuplicateCandid(content.instance)) {
						content.instance.x += duplicateElementMoveAmount;
						content.instance.y += duplicateElementMoveAmount;
					}
				} else {
					if(!content.instance.position) {
						content.instance.position = {
							left: 0,
							top: 0
						};
					}
					while(destinationPage.hasDuplicateText(content.instance)) {
						content.instance.position.left += duplicateElementMoveAmount;
						content.instance.position.top += duplicateElementMoveAmount;
					}
				}

				return content;
			});

			contents.forEach(function(content) {
				if(content.instance.groupedElements) {
					var groupedElements = $.merge([],content. instance.groupedElements);
					
					for(var oldId in idMap) {
						var newId = idMap[oldId];
						
						let index = groupedElements.indexOf(oldId);
						if(index !== -1) {
							groupedElements.splice(index, 1, newId);
						}
					}

					content.instance.groupedElements = groupedElements;
				}
			});

			contents.forEach(function(content) {
				if(content.type == 'frame') {
					flowLayout.addFrame(content.instance, true);
				} else {
					flowLayout.addText(content.instance, true);
				}
			});

			if(this.clipboard.cut) {
				var page = this.pageSet.getPageById(this.clipboard.page);
				if(!page) {
					return false;
				}

				this.clipboard.contents.forEach(function(content) {
					if(content.type == 'frame') {
						page.removeCandid(content.instance.id);
						flowLayout.parent.removeFrameByInstance(content.instance, false);
					} else {
						page.removeText(content.instance.id);
						flowLayout.parent.removeTextByInstance(content.instance, false);
					}
				});
			}

			return false;
		},
		clear: function() {
			this.events = [];
		},

		maxEvents: 1000,
		events: [],
		consumeEventHandlers: [],
		ignoreEventHandlers: [],
		handleGlobalUndo: true,
		undoStack: 0,
		groupEventsWithinTime: 300,
		clipboard: null,
		consumeEventContext: ['pageSet', 'page', 'subjects', 'albums', 'batches', 'projectSettings', 'users', 'snapshots', 'customDictionary']
	}, options);

	if(obj.pageSet) {
		if(obj.pageSet.db) {
			obj.dbQueue = obj.pageSet.db;
		}
	}
	if(obj.dbQueue) {
		obj.dbQueue.userEvents = obj;
		if(obj.dbQueue.pageSet) {
			obj.pageSet = obj.dbQueue.pageSet;
			obj.pageSet.userEvents = obj;
		}
	}
	obj.registerForGlobalUndo();
	$.registerEvents(obj, ['eventAdded', 'eventUndone', 'eventRedone']);

	return obj;
};
